import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { editTags } from 'reducers/employees/actions';
import { getTags, getSortedTags } from 'reducers/tags/selectors';
import TagDropdown from './dropdown/tags';
import AppliedTags from './appliedTags/tags';

const failCb = verb => () => {
  toast(`There was a problem ${verb} this tag.`);
};

function TagManager({
  id,
  selectedTags,
  sortedTags,
  tags,
  setTags,
  render,
  creating,
  updateTags,
  disabled,
}) {
  function toggleTag(tag) {
    return () => {
      let newTags;
      let verb;
      if (selectedTags.indexOf(tag.id) >= 0) {
        newTags = selectedTags.filter(tagId => tagId !== tag.id);
        verb = 'removing';
      } else {
        newTags = selectedTags.concat([tag.id]);
        verb = 'adding';
      }
      if (creating) {
        updateTags(newTags);
      } else {
        setTags(id, newTags)
          .catch(failCb(verb));
      }
    };
  }

  const tagDropdown = (
    <TagDropdown
      tags={sortedTags}
      selectedTags={selectedTags}
      toggleTag={toggleTag}
      disabled={disabled}
    />
  );

  const appliedTags = (
    <AppliedTags
      tags={tags}
      selectedTags={selectedTags}
      setTags={tag => toggleTag(tag)}
      removable={!disabled}
    />
  );

  return render({
    tagDropdown,
    appliedTags,
  });
}

TagManager.propTypes = {
  id: PropTypes.number,
  selectedTags: PropTypes.array,
  sortedTags: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  setTags: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  creating: PropTypes.bool,
  updateTags: PropTypes.func, // to update search object being created
};

TagManager.defaultProps = {
  id: null,
  selectedTags: [],
  creating: false,
  updateTags: () => {},
};

const mapStateToProps = state => ({
  sortedTags: getSortedTags(state),
  tags: getTags(state),
});

export default connect(mapStateToProps, { setTags: editTags })(TagManager);
