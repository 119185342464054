import React from 'react';
import { useParams } from 'react-router-dom';
import api from 'api';
import Loading from 'components/loading';
import { PATH_NAMES, PATHS } from 'utils/constants';
import { RECURRING, SCHEDULE_TYPES } from '../constants';
import { CertificationForm } from './CertificationForm';
import useCertificationTemplate from '../SingleCertification/useCertificationTemplate';

// NOTES on schedule field differences btw BE and form
// on BE:
// schedule: {
//   is_recurring: true or false,
//   at_date: 'YYYY/MM/DD',
//   frequency: ONCE, QUARTERLY, YEARLY,
// }
// on form:
// frequency: ONCE or RECURRING
// is_recurring: calculated from frequency field
// at_date: 'YYYY/MM/DD'
// schedule: QUARTERLY, YEARLY

const updateCertification = template => api.put(`/attestations/templates/${template.id}`, template);

const getInitialValues = (template, emailTemplates) => {
  const {
    id, name, schedule, start_date, end_date, status_filter, employee_selector,
    tag_list, employee_list, email_template, email_subject, email_body, form,
  } = template;

  const { subject, body } = email_template ? emailTemplates[email_template] : {
    subject: email_subject,
    body: email_body,
  };

  return {
    id,
    name,
    form,
    frequency: schedule.is_recurring ? RECURRING : schedule.frequency,
    schedule: schedule.is_recurring ? schedule.frequency : SCHEDULE_TYPES[0].value,
    at_date: schedule.at_date,
    start_date,
    end_date,
    status_filter,
    employee_selector,
    // NOTE for next 2 lines: formik breaks if checkbox values are numeric
    tag_list: tag_list.map(tagId => tagId.toString()),
    employee_list: employee_list.map(empId => empId.toString()),
    email_template,
    email_subject: subject,
    email_body: body,
  };
};

const EditCertification = () => {
  const { id } = useParams();
  const { template, loading } = useCertificationTemplate(id);

  return (
    loading ?
      <div className="flex-load-wrapper">
        <Loading />
      </div> :
      <CertificationForm
        edit
        getInitialValues={emailTemplates => getInitialValues(template, emailTemplates)}
        submitFunc={updateCertification}
        cancelUrl={`${PATHS[PATH_NAMES.certification_templates]}/${template.id}`}
      />
  );
};

export { EditCertification };
