import uiReducer from './reducer';
import modalReducer from './modal/reducer';
import dashboardReducer from './dashboard/reducer';

const initialState = {
  dateFilter: {
    to: null,
    from: null,
  },
  selectedContribs: new Set(),
  resultsLoading: false,
  modal: {
    modalType: '',
    modalProps: {},
  },
  dashboard: {
    filter: null,
    animatingItems: null,
    sourceCategoryFilter: null,
  },
  sessionExpired: false,
};

export default (state = initialState, action) => ({
  ...uiReducer(state, action),
  modal: modalReducer(state.modal, action),
  dashboard: dashboardReducer(state.dashboard, action),
});

