import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './checkbox';


const SourceToggleItem = ({ source, checked, onToggleSource }) => (
  <div className="source">
    <Checkbox
      id={`${source.id}-source`}
      checked={checked}
      onChange={e => onToggleSource([source.id], e.target.checked)}
    >
      <span>{source.label}</span>
    </Checkbox>
  </div>
);

SourceToggleItem.propTypes = {
  source: PropTypes.object.isRequired,
  checked: PropTypes.bool.isRequired,
  onToggleSource: PropTypes.func.isRequired,
};

export default SourceToggleItem;
