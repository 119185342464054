import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/shared/forms/input';
import ObjectDropdown from 'components/shared/objectDropdown';

const textFilterFields = {
  name: {
    label: 'name',
    required: false, // todo
    type: 'text',
  },
  recipient: {
    label: 'recipient',
    required: false,
    type: 'text',
  },
};

// for now -- disabling filters while fetching new rows but we can reconsider this
const Filters = ({ filters, onFieldChange, onFilter, loading, yearOptions }) => (
  <div className="preclearance-match-filters">
    {Object.keys(textFilterFields).map(field => (
      <Input
        key={field}
        // error={errors[field]} // todo
        label={textFilterFields[field].label}
        value={filters[field]}
        onChange={(e) => { onFieldChange(field, e.target.value); }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !loading) {
            onFilter();
          }
        }}
      />
    ),
    )}
    <div className="input-wrapper">
      <label htmlFor="year">
        Year
      </label>
      <ObjectDropdown
        dropdownText={filters.year || 'All'}
        selected={yearOptions.find(y => (filters.year === y.id))}
        options={yearOptions}
        handleDropdownSelection={(e) => { onFieldChange('year', e.id); }}
        field={'year'}
      />
    </div>
    <button
      className="orange-button"
      onClick={onFilter}
      disabled={loading}
    >
      Filter
    </button>
  </div>
);

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  yearOptions: PropTypes.array.isRequired,
};

export default Filters;
