import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Formik, Form as FormikForm } from 'formik';
import isEqual from 'lodash/isEqual';
import api from 'api';
import { PATHS, PATH_NAMES } from 'utils/constants';
import useCancellablePromise from 'hooks/useCancellablePromise';
import { sortBy } from 'lodash';
import ErrorBoundary from 'components/errorBoundary';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import Loading from 'components/loading';
import ModalUI from 'components/modal/modalUI';
import FormNameForm from 'components/FormBuilder/NameForm/FormNameForm';
import Header from './Header';
import Form from './Form/Form';
import { SCHEMA_INPUT_MAP } from './consts';
import { blankForm } from './Form/consts';
import { serializeForm } from './formSerializer';
import { useAutosave } from './useAutosave';
import { FormSchema } from './formSchema';
import { FormPreview } from './FormPreview';


const getInitialValues = (form) => {
  const fieldOrder = sortBy(form.fields, 'order').map((field) => field.id);
  const fields = form.fields.reduce((acc, field) => {
    acc[field.id] = field;
    return acc;
  }, {});
  return {
    ...form,
    fields,
    fieldOrder,
  };
};
const editForm = (form, id) => api.put(`/forms/form/${id}/`, form);

function FormBuilder({ organization }) {
  const [form, setForm] = useState({});
  const [schema, setSchema] = useState({});
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [revision, setRevision] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const {
    autoSavedState,
    persistState,
    isInitialized,
    isLoading,
  } = useAutosave({ formId: id });
  const { cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    let didCancel = false;
    api.get(`/forms/form/${id}/`).then(({ data }) => {
      if (!didCancel) {
        setForm(data);
        const { revisions } = data;
        const activeRevision = revisions.find((rev) => rev.is_active);
        const initForm = getInitialValues(activeRevision || { ...blankForm, title: data.name });
        setRevision(initForm);
      }
    }).catch((err) => {
      history.push(`${PATHS[PATH_NAMES.certification_settings]}/`);
      toast('Something went wrong.');
      throw (err);
    }).finally(() => {
      if (!didCancel) {
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, [history, id]);

  const handleValuesChange = (values) => {
    persistState(values);
    const newSchema = {
      header: values.header,
      footer: values.footer,
      name: values.title,
      start_date: moment().subtract(Math.round(Math.random() * 100), 'd').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      organization: organization.name,
    };
    const orderedFields = values.fieldOrder.map((fieldId) => values.fields[fieldId]);
    newSchema.fields = orderedFields.map((field) => {
      let dependencies = null;
      if (field.dependencies.length) {
        const dependency = field.dependencies[0];
        dependencies = {
          fieldId: dependency.prerequisite_field,
          values: dependency.prerequisite_values,
        };
      }
      return {
        id: field.id,
        label: field.label,
        element: field.input_type === 'TextArea' ? 'textarea' : null,
        input_type: SCHEMA_INPUT_MAP[field.input_type],
        required: field.is_required,
        hidden: field.is_hidden,
        internal_name: '',
        choices: field.choices ? field.choices.map((choice) => ([choice.value, choice.label])) : null,
        dependsOn: dependencies,
        tooltip: null,
      };
    });
    setSchema(newSchema);
  };

  const handlePreview = () => {
    setIsPreviewing(true);
  };

  const handleClosePreview = () => {
    setIsPreviewing(false);
  };

  const onPublish = (values, { setSubmitting, resetForm }) => {
    const payload = serializeForm(values);
    api.post(`forms/form/${id}/revisions/`, payload).then(({ data }) => {
      toast('Form published!');
      const newRevision = getInitialValues(data);
      setRevision(newRevision);
      resetForm({ values: newRevision });
      setSubmitting(false);
    }).catch((err) => {
      toast('Something went wrong.');
      setSubmitting(false);
      throw err;
    });
  };

  const handleRename = (values, actions) => {
    cancellablePromise(editForm(values, id)).then(({ data }) => {
      actions.setSubmitting(false);
      setForm(data);
      setModalOpen(false);
      toast('Form name updated!');
    }).catch((err) => {
      let error = '';
      if (err.response) {
        const { status } = err.response;
        if (status === 409) {
          error = 'A form with this name already exists.';
        }
      }
      if (!err.isCanceled) {
        // eslint-disable-next-line no-unused-expressions
        error && actions.setErrors({ name: error });
        toast('Something went wrong.');
        actions.setSubmitting(false);
      }
    });
  };


  if (loading || !isInitialized) {
    return (
      <div className="flex-load-wrapper">
        <Loading />
      </div>
    );
  }

  if (isPreviewing) {
    return (
      <FormPreview onClose={handleClosePreview} payload={schema} />
    );
  }

  return (
    <ErrorBoundary>
      <ScrollToTopOnMount />
      <Formik
        initialValues={{ ...revision, ...autoSavedState }}
        validationSchema={FormSchema}
        onSubmit={onPublish}
      >
        {
            ({
              isSubmitting, values, isValid, handleSubmit,
            }) => (
              <FormikForm
                autoComplete="off"
                noValidate
              >
                <Header
                  isLoading={isLoading || !isEqual(autoSavedState, values)}
                  formName={form.name}
                  onPublish={handleSubmit}
                  isSubmitting={isSubmitting}
                  onPreview={handlePreview}
                  isValid={isValid}
                  editName={() => { setModalOpen(true); }}
                  isPublished={revision.is_active && isEqual(revision, values)}
                />
                <Form
                  form={values}
                  onValuesChange={handleValuesChange}
                />
              </FormikForm>
            )
          }
      </Formik>
      {
        modalOpen && (
          <ModalUI
            open
            close={() => setModalOpen(false)}
            className="small center form-modal"
          >
            <FormNameForm
              handleSubmit={handleRename}
              initialValues={{ name: form.name }}
              formHeading="Rename this form"
              helpText=""
              onClose={() => setModalOpen(false)}
              submitButtonText="Update"
            />
          </ModalUI>
        )
      }
    </ErrorBoundary>
  );
}

FormBuilder.propTypes = {
  organization: PropTypes.object.isRequired,
};

const mapStateToProps = ({ organization }) => ({
  organization,
});

export default connect(mapStateToProps)(FormBuilder);
