import PropTypes from 'prop-types';

export const FIELD_TYPES = new Set([
    'TextField',
    'DecimalField',
    'IntegerField',
    'DateField',
    'EnumField',
    'SelectField',
    'AutocompleteField',
    'CheckboxField',
    'CheckboxGroupField',
    'ArrayField',
    'SearchableSelectField',
    'PreclearanceTable',
  ]),
  FORM_ELEMENT = {
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    element: PropTypes.string,
    fieldType: PropTypes.oneOf(Array.from(FIELD_TYPES)),
    required: PropTypes.bool,
    hidden: PropTypes.bool,
    choices: PropTypes.arrayOf(PropTypes.array),
    dependsOn: PropTypes.shape({
      fieldId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      values: PropTypes.array,
    }),
    readOnly: PropTypes.oneOf([true, false, 'readonly']),
    tooltip: PropTypes.string,
    validate: PropTypes.func,
  };
