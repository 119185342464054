import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { SUPPORT_EMAIL } from 'utils/constants';

export const ErrorComponent = ({ className, apologyText, errorBody }) => (
  <div className={className || 'error-bounds'}>
    <div>
      <h2>{apologyText || 'We are sorry, but something has gone wrong.'}</h2>
      { errorBody || <div>Our team has been notified and is working to resolve the issue, you can also contact <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer">{SUPPORT_EMAIL}</a></div>
      }
      {/* <div><a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</a><div> */}
      {/* <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details> */}
    </div>
  </div>
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.errorInfo) {
      return <ErrorComponent />;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
