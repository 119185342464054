import axios from 'axios';
import api from 'api';
import { toast } from 'react-toastify';
import { SORT_MAP } from 'components/shared/matching/consts';
import { MATCHING_RECORD_LIMIT } from 'utils/constants';

const CancelToken = axios.CancelToken;
export const canceler = CancelToken.source();

// eslint-disable-next-line no-underscore-dangle
export const _addUpload = (fileObj, signedAwsPost, updatePercentage) => {
  // signedAwsPost: obj with aws keys and url
  // updatePercentage: cb that takes the upload percentage of the file(s)
  const formData = new FormData();
  Object.keys(signedAwsPost.fields).forEach((key) => {
    formData.append(key, signedAwsPost.fields[key]);
  });
  formData.append('file', fileObj);
  const config = {
    onUploadProgress: (progEvent) => {
      if (progEvent.lengthComputable) {
        const percentage = Math.min((progEvent.loaded / progEvent.total) * 100, 99);
        updatePercentage(percentage, false);
      }
    },
    cancelToken: canceler.token,
  };

  return axios.post(signedAwsPost.url, formData, config)
    .catch((e) => {
      if (e.response.status === 412) {
        toast('Invalid file type'); // precondition failed
      } else if (e.response.status === 405) {
        toast('There was a problem uploading this file'); // method not allowed
      } else {
        toast(e.response.data); // todo: test once pc uploads working
      }
      updatePercentage(0, true);
      throw e;
    });
};


// for multiple requests (used in jurs)
// todo use RequestTrackerCanceler for jurs instead
let cancels = [];
export const MultiRequestCanceler = {
  newCancel: () => new CancelToken((cancel) => { cancels.push(cancel); }),
  cancelAllPending: () => {
    cancels.forEach(cancel => cancel && cancel());
    cancels = [];
  },
  cancelAllRequests: () => {
    cancels.forEach(cancel => cancel());
    cancels = [];
  },
};


// store cancelObjs by either search id or request type to allow to only cancel requests related to a single search obj or request
// using same token for all requests for a since we will always want to
// cancel all requests related to one search or one request at the same time
// (ex. when editing a search we want to cancel all requests for the old results)
// cancelTracker = {
//   searchId: {token: token, cancel: cancelFunc},
//   searchId: {token: token, cancel: cancelFunc},
// }
// or on dashboard
// cancelTracker = {
//   [CONTRIBUTIONS_REQ_ID]: {token: token, cancel: cancelFunc},
// }

// request type keys
export const CONTRIBUTIONS_REQ_ID = 'CONTRIBUTIONS';
export const EMPLOYEES_REQ_ID = 'EMPLOYEES';

let cancelTracker = {};

export const RequestTrackerCanceler = {
  getCancelToken: (requestId) => {
    if (cancelTracker[requestId]) {
      return cancelTracker[requestId].token;
    }
    cancelTracker[requestId] = CancelToken.source();
    return cancelTracker[requestId].token;
  },
  cancelByRequestId: (requestId) => {
    cancelTracker[requestId] && cancelTracker[requestId].cancel();
    delete cancelTracker[requestId];
  },
  cancelAllRequests: () => {
    Object.values(cancelTracker).forEach(cancelObj => cancelObj.cancel());
    cancelTracker = {};
  },
};


export const buildQuery = query => (
  Object.keys(query).map((k) => {
    let value = query[k];
    if (Array.isArray(value)) {
      return value.map(val => `${k}=${val}`).join('&');
    } else if (typeof value === 'string') {
      value = value.trim();
    }
    return (value && `${k}=${value}`);
  }).filter(Boolean).join('&')
);

// some fields need to be mapped for BE ordering
const orderMapPre = {
  status: 'status_label',
  jurisdiction: 'jurisdiction_name',
};

const orderMap = {
  status: 'status_label',
  jurisdiction: 'jurisdiction_name',
  contributor: 'name',
  address: 'address_x',
  employer: 'employer_x',
  occupation: 'occupation_x',
  recipient: 'recipient_x',
};

export const getPreclearanceMatches = (filters, recordId, sort, offset) => {
  const { sortDirection, sortBy } = sort;
  const query = {
    ...filters,
    record_id: recordId, // to get automatches
    ordering: `${SORT_MAP[sortDirection]}${orderMapPre[sortBy] || sortBy}`,
    limit: MATCHING_RECORD_LIMIT,
    offset,
  };
  return api.get(`/matches/preclearance/?${buildQuery(query)}`);
};

export const getRecordMatches = (filters, requestId, sort, offset) => {
  const { sortDirection, sortBy } = sort;
  const query = {
    ...filters,
    request_id: requestId, // to get automatches
    ordering: `${SORT_MAP[sortDirection]}${orderMap[sortBy] || sortBy}`,
    limit: MATCHING_RECORD_LIMIT,
    offset,
  };
  return api.get(`/matches/records/?${buildQuery(query)}`);
};
