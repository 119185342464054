import api from '../../../api';
import * as types from '../types';

const loadAutoActions = data => ({
  data,
  type: types.LOAD_AUTO_ACTIONS,
});

const addAutoAction = data => ({
  data,
  type: types.ADD_AUTO_ACTION,
});

const updateAutoAction = data => ({
  data,
  type: types.UPDATE_AUTO_ACTION,
});

const deleteAutoAction = id => ({
  id,
  type: types.DELETE_AUTO_ACTION,
});

// for receiving a batch of updated actions
export const receiveActions = data => ({
  data,
  type: types.RECEIVE_AUTO_ACTIONS,
});

export const fetchAutoActions = () => dispatch =>
  api.get('/pre/actions/')
    .then(({ data }) => {
      dispatch(loadAutoActions(data));
      return data;
    });

export const createAutoAction = newAction => dispatch =>
  api.post('/pre/actions/', newAction)
    .then(({ data }) => {
      dispatch(addAutoAction(data));
      return data;
    });

export const editAutoAction = newAction => dispatch =>
  api.put(`/pre/actions/${newAction.id}/`, newAction)
    .then(({ data }) => {
      dispatch(updateAutoAction(data));
      return data;
    });

export const removeAutoAction = id => dispatch =>
  api.delete(`/pre/actions/${id}`)
    .then(({ data }) => {
      dispatch(deleteAutoAction(id));
      return data;
    });
