import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EMPLOYEE_PATHS, PATH_NAMES, PEOPLE, BASE_MONITORING_URL } from 'utils/constants';
import { CONTRIBUTOR } from './consts';

function EmployeeListItem({ item, nameKey }) {
  const { id, employeeId } = item;
  return (
    <li className={'chart-list-item'}>
      <Link
        to={`/app/${BASE_MONITORING_URL}/${PEOPLE}/${employeeId}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}/${id}`}
        className="name"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{item[nameKey]}</span>
      </Link>
      <span className="count">{item.count.overrideLocaleString()}</span>
    </li>
  );
}

EmployeeListItem.propTypes = {
  item: PropTypes.object.isRequired,
  nameKey: PropTypes.string.isRequired,
};

function ListItem({ item, nameKey }) {
  return (
    <li className={'chart-list-item'}>
      <span className="name">{item[nameKey]}</span>
      <span className="count">{item.count.overrideLocaleString()}</span>
    </li>
  );
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  nameKey: PropTypes.string.isRequired,
};


function ListChart({ data, nameKey }) {
  const ListComponent = nameKey === CONTRIBUTOR ? EmployeeListItem : ListItem;
  return (
    <ul className="chart-list">
      {data.map((item, i) => (
        <ListComponent
          key={i}
          item={item}
          nameKey={nameKey}
        />
      ))}
    </ul>
  );
}

ListChart.propTypes = {
  data: PropTypes.array.isRequired,
  nameKey: PropTypes.string.isRequired,
};

export default ListChart;
