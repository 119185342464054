
import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import {
  CELL,
} from '../constants';


function DeleteConfirmation({
  onDelete,
  onCancel,
}) {
  return (
    <div
      className="preclearance-popover-body preclearance-popover"
      role="dialog"
    >
      <div className="header">
        <span>Delete Row</span>
      </div>
      <div className="body">
        <p>Are you sure you would like to delete this row?</p>
        <div className="buttons">
          <button
            className="text-button uppercase"
            onClick={onCancel}
            type="button"
          >
            Cancel
          </button>
          <button
            className="save-button orange-button"
            onClick={onDelete}
            type="button"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

DeleteConfirmation.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};


export function DeleteCell({
  isOpen,
  openDeleteConfirmation,
  closeDeleteConfirmation,
  deleteRow,
  style,
}) {
  return (
    <div
      className={`${CELL} delete-cell`}
      style={style}
    >
      <Tippy
        content={(
          isOpen
            ? (
              <DeleteConfirmation
                onDelete={deleteRow}
                onCancel={closeDeleteConfirmation}
              />
            )
            : null
        )}
        visible={isOpen}
        onClickOutside={closeDeleteConfirmation}
        placement="bottom"
        interactive
        theme="light"
        animation={false}
      >
        <button
          type="button"
          className="text-button"
          onClick={openDeleteConfirmation}
        >
          <i className="material-icons">
            delete
          </i>
        </button>
      </Tippy>
    </div>
  );
}

DeleteCell.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openDeleteConfirmation: PropTypes.func.isRequired,
  closeDeleteConfirmation: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  style: PropTypes.object,
};

DeleteCell.defaultProps = {
  style: {},
};
