import * as types from '../results/types';


const defaultState = {};


export default (state = defaultState, action) => {
  switch (action.type) {
    case types.CLEAR_RESULTS:
      return defaultState;
    case types.LOAD_JURISDICTION_RESULTS:
    case types.LOAD_RESULTS: {
      const sourceResultIds = action.results.map(sourceResult => sourceResult.id);
      return {
        ...state,
        [action.id]: {
          sourceResults: sourceResultIds,
        },
      };
    }
    case types.LOAD_INDIVIDUAL_RESULT: {
      const existingSourceResults = (state[action.id] || {}).sourceResults || [];
      const sourceResultsCopy = [...existingSourceResults];
      if (sourceResultsCopy.indexOf(action.data.id) < 0) {
        sourceResultsCopy.push(action.data.id);
      }
      return {
        ...state,
        [action.id]: {
          sourceResults: sourceResultsCopy,
        },
      };
    }
    case types.REMOVE_SEARCH_OBJ_RESULTS:
    case types.REMOVE_SEARCH: {
      const nextState = { ...state };
      delete nextState[action.id];
      return {
        ...nextState,
      };
    }
    default:
      return state;
  }
};

