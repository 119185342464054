import FileSaver from 'file-saver';
import canvg from 'canvg';
import { LOGO_ID } from 'components/svgs/logo';

const LOGO_MARGIN = 80;

function setAttributes(elem, attributeObj) {
  Object.entries(attributeObj).forEach(([key, value]) => {
    elem.setAttribute(key, value);
  });
}


function exportSVGAsPNG(svg, fileName, width, height) {
  const canvas = document.createElement('canvas');
  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(svg);

  canvg(canvas, svgString, { scaleWidth: width, scaleHeight: height });

  canvas.toBlob((blob) => {
    FileSaver.saveAs(blob, `${fileName}.png`);
  });
}


function createBlankSvg(width, height) {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const svgAttrs = {
    width,
    height,
    viewBox: `0 0 ${width} ${height}`,
  };
  setAttributes(svg, svgAttrs);
  return svg;
}


function appendChartToSvg(chart, svg, yPlacement) {
  const chartCopy = chart.cloneNode(true);
  const chartAttrs = { y: yPlacement };
  setAttributes(chartCopy, chartAttrs);
  svg.appendChild(chartCopy);
}


function appendLogoToSvg(svg) {
  const logoEl = document.getElementById(LOGO_ID);
  const logo = logoEl.cloneNode(true);
  const logoAttrs = { width: 100, height: 30, y: 20, x: 20 };
  setAttributes(logo, logoAttrs);
  svg.appendChild(logo);
}


function appendLegendToSvg(svg, legend, marginTop, width) {
  const legendItems = legend.getElementsByClassName('recharts-legend-item');

  Array.from(legendItems).map((legendItem, i) => {
    const item = legendItem.cloneNode(true);
    const square = item.getElementsByClassName('recharts-surface')[0];
    const count = item.getElementsByClassName('legend-count')[0];
    const name = item.getElementsByClassName('legend-name')[0];

    const horizontalPlacement = width;
    const verticalPlacement = ((48 * i) + marginTop + 42);
    const squareSize = 12;
    const horizontalTextPlacement = horizontalPlacement + (squareSize * 2);

    const squareAttrs = {
      viewBox: `0 0 ${squareSize} ${squareSize}`,
      y: (verticalPlacement - 10),
      x: horizontalPlacement,
      width: squareSize,
      height: squareSize,
    };

    const countAttrs = {
      y: verticalPlacement,
      x: horizontalTextPlacement,
    };

    const nameAttrs = {
      y: (verticalPlacement + 16),
      x: horizontalTextPlacement,
    };

    setAttributes(square, squareAttrs);
    setAttributes(count, countAttrs);
    setAttributes(name, nameAttrs);
    [square, count, name].map(elem => svg.appendChild(elem));
  });
}


export function exportChart(chartRef, fileName) {
  const chart = chartRef.container.children[0];
  const { height, width } = chart.getBoundingClientRect();

  const scaleWidth = width * 2;
  const scaleHeight = ((height + LOGO_MARGIN) * 2);

  const svg = createBlankSvg(scaleWidth, scaleHeight);

  appendLogoToSvg(svg);
  appendChartToSvg(chart, svg, LOGO_MARGIN);

  exportSVGAsPNG(svg, fileName, scaleWidth * 2, scaleHeight * 2);
}


export function exportChartWithLegend(chartRef, fileName) {
  const chart = chartRef.container.children[0];
  const { height, width } = chart.getBoundingClientRect();

  const legendWrapper = chartRef.container.children[1];
  const legend = legendWrapper.getElementsByClassName('recharts-default-legend')[0];
  const legendHeight = legend.getBoundingClientRect().height;
  const legendWidth = legend.getBoundingClientRect().width;


  const minHeight = legendHeight > height ? legendHeight : height;
  const scaleWidth = width * 2;
  const scaleHeight = ((minHeight + LOGO_MARGIN) * 2);

  const svg = createBlankSvg(scaleWidth, scaleHeight);

  appendLogoToSvg(svg);
  appendChartToSvg(chart, svg, LOGO_MARGIN);
  appendLegendToSvg(svg, legendWrapper, LOGO_MARGIN, width - legendWidth);

  exportSVGAsPNG(svg, fileName, scaleWidth * 2, scaleHeight * 2);
}
