import React from 'react';
import PropTypes from 'prop-types';

const Triangle = ({ position, color, style }) => (
  <div className={`svg-container ${navigator.userAgent.indexOf('Safari') >= 0 ? '' : 'uses-svg'}`}>
    {
      (navigator.userAgent.indexOf('Safari') >= 0) ? (
        <div
          className={`arrow ${position}`}
          style={{
            ...style,
            [`border${position[0].toUpperCase() + position.slice(1)}Color`]: color,
          }}
        />
      ) : (
        <svg style={style} className={position} width={30} height={34} viewBox="0 0 250 143" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <polygon id="path-1" points="132.5 5 258 128 7 128" />
          </defs>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Pixel-2">
              <g id="Triangle">
                <use fill="#999999" fillOpacity={0.3} filter="url(#filter-2)" href="#path-1" />
                <use fill={color} fillRule="evenodd" href="#path-1" />
              </g>
            </g>
          </g>
        </svg>
      )
    }
  </div>
);

Triangle.propTypes = {
  position: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

Triangle.defaultProps = {
  position: 'top',
  color: '#FFFFFF',
  style: {},
};

export default Triangle;
