import * as types from './types';

const initState = {};

const emailTemplates = (state = initState, action) => {
  switch (action.type) {
    case types.LOAD_TEMPLATES: {
      const templateState = {};
      action.templates.reduce((_allTemplates, template) => {
        templateState[template.id] = template;
        return templateState;
      }, {});
      return templateState;
    }
    case types.EDIT_TEMPLATE:
    case types.ADD_TEMPLATE: {
      const nextState = { ...state };
      nextState[action.template.id] = action.template;
      return nextState;
    }
    case types.REMOVE_TEMPLATE: {
      const nextState = { ...state };
      delete nextState[action.id];
      return nextState;
    }
    default: {
      return state;
    }
  }
};

export default emailTemplates;
