import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import fileSize from 'filesize';
import isEqual from 'lodash/isEqual'
import * as Sentry from '@sentry/react';
import Tippy from '@tippyjs/react';

const FileUpload = ({
    onUpload,
    maxItems,
    maxSize,
    buttonText,
    initialFiles,
    accept,
    showTooltip,
    fileType,
    buttonColor,
}) => {
    const [files, setFiles] = useState(initialFiles),
        isDisabled = files.length >= maxItems;

    const handleUpload = (event) => {
            // Ignore request if disabled
            if (isDisabled) {
                return null;
            }

            // Create new files array and check against quota
            const newFiles = [...files, ...event.target.files];
            if (maxSize) {
                const quota = newFiles
                    .map((file) => file.size)
                    .reduce((a, b) => a + b, 0);

                if (quota > maxSize) {
                    const message = `Combined total file size limit is ${fileSize(
                        maxSize
                    )}.`;
                    toast(message);
                    Sentry.captureMessage(message, {
                        extra: {
                            totalFileSize: quota,
                            maxSize,
                        },
                    });
                    return null;
                }
            }
            setFiles(newFiles);
        },
        handleDelete = (index) => {
            const newFiles = [...files];
            newFiles.splice(index, 1);
            setFiles(newFiles);
        };

    useEffect(() => {
        if (!isEqual(files, initialFiles)) {
            onUpload(files);
        }
    }, [files]);

    return (
        <div className="file-container">
            <div className="file-list" id="file-list">
                {files.map((file, i) => (
                    <div className="file-item" key={i}>
                        <a className="file-url">
                            <i className="material-icons">attach_file</i>
                            <span className="file-name">{file.name}</span>
                        </a>
                        <i
                            className="material-icons remove-file"
                            onClick={() => handleDelete(i)}
                        >
                            clear
                        </i>
                    </div>
                ))}
            </div>
            <Tippy
              content={`You have reached the maximum number of ${fileType}`}
              placement="top-start"
              arrow={false}
              delay={[250, 0]}
              disabled={!isDisabled || !showTooltip}
            >
                <label id="file-label" className={isDisabled ? 'disabled' : null}>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleUpload}
                        disabled={isDisabled}
                        accept={accept}
                    />
                    <span
                        className="attach-button"
                        id="attach-button"
                        style={{
                            display: 'block',
                            color: buttonColor,
                        }}
                    >
                        {buttonText}
                    </span>
                </label>
            </Tippy>
        </div>
    );
};

FileUpload.propTypes = {
    onUpload: PropTypes.func,
    maxItems: PropTypes.number,
    maxSize: PropTypes.number,
    buttonText: PropTypes.string,
    initialFiles: PropTypes.array,
    accept: PropTypes.string,
    showTooltip: PropTypes.bool,
    fileType: PropTypes.string,
    buttonColor: PropTypes.string,
};

FileUpload.defaultProps = {
    onUpload: () => null,
    maxItems: 3,
    buttonText: '+ attach files',
    initialFiles: [],
    accept: undefined,
    showTooltip: false,
    fileType: 'attachments',
    buttonColor: '',
};

export { FileUpload };
