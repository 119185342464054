import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PATHS, PATH_NAMES } from 'utils/constants';

function SubmissionHeader({ name, date, certificationId }) {
  return (
    <div className="submission-header">
      <div className="submission-header-name">
        <Link
          className="close"
          to={`${PATHS[PATH_NAMES.certifications]}/${certificationId}`}
        >
          <i className="material-icons">close</i>
        </Link>
        <h2>{ name }</h2>
      </div>
      <div>
        <div className={`uppercase submission-header-status ${date ? '' : 'submission-status red'}`}>
          {date ? 'Completed on' : 'Not submitted'}
        </div>
        <div>
          {date ? moment(date).format('ll') : ''}
        </div>
      </div>
    </div>
  );
}

SubmissionHeader.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  date: PropTypes.string,
  certificationId: PropTypes.string.isRequired,
};

SubmissionHeader.defaultProps = {
  date: null,
};

export default SubmissionHeader;
