import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { scrollToElem } from '../../../helpers/helpers';

class SubcategoryRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    if (this.props.defaultOpen) {
      this.toggleOpen();
    }
  }

  toggleOpen() {
    if (!this.state.open) {
      const target = this.subCatHeader.getBoundingClientRect().top;
      scrollToElem(target);
    }
    this.setState({ open: !this.state.open });
  }


  render() {
    const RecordGroup = this.props.recordGroupComponent;
    const { sources, sourceResultsMap, name, recordCount, showContribSchema, searchId, searchType } = this.props;
    const defaultOpen = sources.length === 1;
    return (
      <div className={this.state.open ? 'subcategory-container open' : 'subcategory-container'}>
        <button
          className="subcategory-row"
          onClick={this.toggleOpen}
          ref={(el) => { this.subCatHeader = el; }}
          name={name}
        >
          <h2 className="subcategory-header">
            {name}
            <span className="count-wrapper">{recordCount.overrideLocaleString()}</span>
          </h2>

          <div className="subcategory-details">
            <span>
              {this.state.open ?
                <i className="material-icons">expand_less</i> :
                <i className="material-icons">expand_more</i>
              }
            </span>
          </div>
        </button>
        {
          this.state.open ? (
            sources.map((source, idx) => (
              <RecordGroup
                showContribSchema={showContribSchema}
                key={source.label}
                source={source}
                sourceResult={sourceResultsMap[source.id]}
                tableTitle={source.label}
                defaultOpen={defaultOpen}
                isLast={this.props.isLast && idx === (sources.length - 1)}
                searchId={searchId}
                searchType={searchType}
              />
            ))
          ) : (
            null
          )
        }
      </div>
    );
  }
}

SubcategoryRow.propTypes = {
  name: PropTypes.string.isRequired, // subcategory name
  sources: PropTypes.array.isRequired, // array of source objects (NOT sourceResults)
  sourceResultsMap: PropTypes.object.isRequired, // map of sourceId to sourceResult
  defaultOpen: PropTypes.bool.isRequired, // open by default (if this is the only subcategory)
  showContribSchema: PropTypes.bool.isRequired, // determines whether to render checkboxes, notes, and statuses
  isLast: PropTypes.bool,
  recordCount: PropTypes.number.isRequired,
  recordGroupComponent: PropTypes.func.isRequired,
  searchId: PropTypes.number.isRequired,
  searchType: PropTypes.string.isRequired,
};

SubcategoryRow.defaultProps = {
  isLast: false,
};

export default SubcategoryRow;
