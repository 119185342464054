// person search types
export const [SELF, SPOUSE, DEPENDENT] = ['SELF', 'SPOUSE', 'DEPENDENT'];

export const headingMap = {
  SELF: 'Filters',
  SPOUSE: 'Spouse',
  DEPENDENT: 'Dependent',
};

// limit for the number of dependents and spouses one employee can have
// we are doing this because the results page will be hard to use if they try to add more
export const NON_SELF_SEARCH_LIMIT = 10;


export const getSelfSearch = searches =>
  (searches || []).find(search => search.search_type === SELF);

export const nonSelfSearches = searches =>
  (searches || []).filter(search => search.search_type !== SELF);

export const sortSearches = searches =>
  (searches || []).sort((a, b) => {
    if (a.search_type === SPOUSE && b.search_type === DEPENDENT) {
      return -1;
    }
    if (a.search_type === DEPENDENT && b.search_type === SPOUSE) {
      return 1;
    }
    return 0;
  });
