import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useField, ErrorMessage } from 'formik';
import Fuse from 'fuse.js/dist/fuse.min';

import { getUnarchivedEmployees } from 'reducers/employees/selectors';
import { sortData, ASC } from 'hooks/useSort';
import { SidebarSearch } from 'components/sidebar/search';
import Table from 'components/shared/SimpleVirtualTable';
import { CheckboxGroup } from 'components/shared/SchemaForm/components/CheckboxGroup';
import { OptionGroup } from '../../components';


const Individuals = ({ fieldName, employees, sortedEmployeeIds }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [field, meta, helpers] = useField({ name: fieldName });

  const selectedEmployees = field.value.map(id =>
    employees[id],
  );

  const fuse = new Fuse(Object.values(employees), {
    keys: ['label'],
    threshold: 0.3,
  });

  const availableSelection = (searchQuery ? searchResult : sortedEmployeeIds)
    .map(id => employees[id]);

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
  };
  const deselectAll = () => {
    helpers.setValue([]);
  };

  useEffect(() => {
    if (searchQuery) {
      const result = fuse.search(searchQuery);
      setSearchResult(result.map(match => match.item.value));
    }
  }, [searchQuery]);

  useEffect(() => {
    setSearchQuery('');
  }, [field.value.length]);

  return (
    <div className="individual-form">
      <div className="selected-section">
        <div className="flex-header">
          <div className="flex-header">
            <span className="uppercase selected-header">selected</span>
            <span className="badge">{field.value.length}</span>
          </div>
          {!!selectedEmployees.length && (
            <button className="icon-text-button" type="button" onClick={deselectAll}>
              <i className="material-icons">close</i>
              <span>Deselect all</span>
            </button>
          )}
        </div>
        {!!selectedEmployees.length && (
          <div className="selection-container">
            <OptionGroup
              name={fieldName}
              choices={selectedEmployees}
            />
          </div>
        )}
      </div>
      <div id="separator" />
      <div className="option-container">
        <div className="sidebar-search-container">
          <SidebarSearch
            onSearchFilter={handleSearchInput}
            filterValue={searchQuery}
            searchPlaceholder={'search employees...'}
          />
        </div>
        <div className="options">
          <div role="group" className="multi-checkbox">
            <Table
              rows={availableSelection}
              rowHeight={36}
              rowRenderer={({
                index,
                key,
                style,
              }) => {
                const choice = availableSelection[index];
                return (
                  <div style={style} key={key}>
                    <CheckboxGroup
                      name={fieldName}
                      label={choice.label}
                      value={choice.value}
                    />
                  </div>
                );
              }}
              noRowsRenderer={() => (
                <div className="no-results-container">
                  <span>No results</span>
                </div>
              )}
            />
          </div>
        </div>
        <ErrorMessage
          name={field.name}
          component="div"
          className="error-text"
        />
      </div>
    </div>
  );
};

Individuals.propTypes = {
  employees: PropTypes.objectOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired, // map of employee id to employee info
  sortedEmployeeIds: PropTypes.array.isRequired, // employee ids sorted by last name
  fieldName: PropTypes.string.isRequired,
};


const mapStateToProps = (state) => {
  const data = {};
  const employees = getUnarchivedEmployees(state);
  employees.forEach((employee) => {
    data[employee.id] = {
      value: employee.id,
      label: `${employee.last_name}, ${employee.first_name}`,
    };
  });
  const sorted = sortData(Object.values(data), 'label', ASC).map(employee => (employee.value));
  return {
    employees: data,
    sortedEmployeeIds: sorted,
  };
};

const ConnectedIndividuals = connect(mapStateToProps)(Individuals);
export { ConnectedIndividuals as Individuals };
