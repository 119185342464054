import React from 'react';
import PropTypes from 'prop-types';

const LoadingBar = ({ totalSourceCount, completedSourceCount }) => {
  const progressWidth = {
    width: `${(completedSourceCount / totalSourceCount) * 100}%`,
  };
  if (progressWidth.width === '100%' || isNaN(completedSourceCount)) {
    return null;
  }
  return (
    <div className="loading-bar">
      <div className="loading-bar-outer">
        <div className="loading-bar-progress" style={progressWidth} />
      </div>
      <span>{completedSourceCount} / {totalSourceCount}</span>
    </div>
  );
};


LoadingBar.propTypes = {
  totalSourceCount: PropTypes.number.isRequired,
  completedSourceCount: PropTypes.number.isRequired,
};

export default LoadingBar;
