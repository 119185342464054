import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EMPLOYEES, ENTITIES, PEOPLE, COMPANIES } from 'utils/constants';
import CandidateTooltipBody from 'components/shared/candidateTooltipBody';
import { JurisdictionHeader } from 'components/shared/searchObjectHeader/jurisdictionHeaderContainer';
import JurisdictionCategory from 'components/jurisdictions/jurisdictionCategory';
import { getJurisdictionSearches } from 'reducers/searchObjects/actions';
import { getSearchesByType } from 'reducers/searchObjects/selectors';
import Loading from '../loading';
import NoResults from '../shared/searchResults/noresults';


class SearchObjectView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.fetchInitialData();
  }


  componentDidUpdate(prevProps) {
    const dfMismatch = this.props.dateFilter.from !== prevProps.dateFilter.from || this.props.dateFilter.to !== prevProps.dateFilter.to;
    const objMismatch = this.props.search.id !== prevProps.search.id;
    if (dfMismatch || objMismatch) {
      window.scrollTo(0, 0);
      this.fetchInitialData();
    }
  }

  fetchInitialData() {
    const jurId = this.props.search.id;
    this.props.getJurisdictionSearches(jurId);
  }

  render() {
    const { hasEmployeeResults, hasEntityResults, dateFilter, search, noResults, loading } = this.props;

    return (
      <div className="results">
        <div className="results-body">
          <JurisdictionHeader
            selectedJur={search}
          />
          {
            loading ?
              <div className="load-wrapper">
                <Loading />
              </div>
              : null
          }
          {noResults ?
            <NoResults
              dateFilter={dateFilter}
              render={({ dateFilterText }) => (<span>
                  There were no person or company records found within {search.name}{dateFilterText}.
              </span>)}
            /> : null
          }
          { // todo: better render logic
            !loading ?
              <CandidateTooltipBody>
                {
                  hasEntityResults &&
                  <JurisdictionCategory
                    key={COMPANIES}
                    name={COMPANIES}
                    entitySlice={ENTITIES}
                    isLast={!hasEmployeeResults}
                    defaultOpen={!hasEmployeeResults}
                    jurId={this.props.search.id}
                  />
                }
                {
                  hasEmployeeResults &&
                  <JurisdictionCategory
                    key={PEOPLE}
                    name={PEOPLE}
                    entitySlice={EMPLOYEES}
                    isLast
                    defaultOpen={!hasEntityResults}
                    jurId={this.props.search.id}
                  />
                }
              </CandidateTooltipBody>
              : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { search } = props;
  const searchRef = state.jurisdictions.find(jur => jur.id === search.id);

  const hasEmployeeResults = Boolean(getSearchesByType(state, EMPLOYEES).length);
  const hasEntityResults = Boolean(getSearchesByType(state, ENTITIES).length);

  return {
    loading: state.ui.resultsLoading,
    search: searchRef,
    dateFilter: state.ui.dateFilter,
    hasEmployeeResults,
    hasEntityResults,
    noResults: !state.ui.resultsLoading && !hasEmployeeResults && !hasEntityResults,
  };
};

export default connect(
  mapStateToProps,
  {
    getJurisdictionSearches,
  },
)(SearchObjectView);


SearchObjectView.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  getJurisdictionSearches: PropTypes.func.isRequired,
  hasEmployeeResults: PropTypes.bool.isRequired,
  hasEntityResults: PropTypes.bool.isRequired,
  noResults: PropTypes.bool.isRequired,
  search: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
