import api from 'api';
import * as types from './types';

const loadFields = data => ({
  data,
  type: types.LOAD_FIELDS,
});

const addField = data => ({
  data,
  type: types.ADD_FIELD,
});

const updateField = data => ({
  data,
  type: types.UPDATE_FIELD,
});

const deleteField = id => ({
  id,
  type: types.DELETE_FIELD,
});


export const fetchCustomFields = () => dispatch =>
  api.get('/fields/')
    .then(({ data }) => {
      dispatch(loadFields(data));
      return data;
    });

export const createField = newField => dispatch =>
  api.post('/fields/', newField)
    .then(({ data }) => {
      dispatch(addField(data));
      return data;
    });

export const editField = field => dispatch =>
  api.put(`/fields/${field.id}/`, field)
    .then(({ data }) => {
      dispatch(updateField(data));
      return data;
    });

export const removeField = id => dispatch =>
  api.delete(`/fields/${id}/`)
    .then(({ data }) => {
      dispatch(deleteField(id));
      return data;
    });
