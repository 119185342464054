import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { List } from 'react-virtualized';
import { isCancel } from 'axios';
import { debounce, isEqual } from 'lodash';
import { nonSelfSearches } from 'components/employeePage/consts';
import {
  getFlattenedTree, getParentNodeIds, getParentNodeId, buildTree, ARCHIVE,
} from './treeHelpers';
import { SidebarSearch } from './search';
import AlphaSelector from './alphaSelector';
import SearchItemTab from './searchItemTab';
import { SidebarEmpty, SidebarNoSearchQuery } from './sidebarEmpty';
import Loading from '../loading';
import Section from './Section';


function shouldGetEmployees(oldTagFilters, tagFilters) {
  const tagFilterMismatch = tagFilters.filter((tag) => !oldTagFilters.includes(tag))
    .concat(oldTagFilters.filter((tag) => !tagFilters.includes(tag))).length;
  if (tagFilterMismatch) {
    return true;
  }
  return false;
}


export default class EmployeeSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      loading: false,
      virtualList: [], // flat array of "nodes" to render for easy getRow access
    };
    this.expanded = new Set(); // set of expanded node ids
    this.allNodes = [];

    this.onSearchFilter = this.onSearchFilter.bind(this);
    this.toggleAccordian = this.toggleAccordian.bind(this);
    this.toggleAll = this.toggleAll.bind(this);

    this.getRow = this.getRow.bind(this);
    this.calcRowHeight = this.calcRowHeight.bind(this);
    this.noRowsRenderer = this.noRowsRenderer.bind(this);

    this.getEmployees = this.getEmployees.bind(this);
    this.onDebounceSearch = debounce(this.onDebounceSearch, 500);
  }


  componentDidMount() {
    this.regenRows();
    // auto open letter of selected employee
    const { selected, onEmployeeTab } = this.props;
    if (selected && onEmployeeTab) {
      const parentNodeId = getParentNodeId(selected);
      selected.archived && this.toggleAccordian(ARCHIVE);
      this.toggleAccordian(parentNodeId);
    }
  }


  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.employees, prevProps.employees)) {
      this.regenRows();
    }
    const { selected } = this.props;
    if (this.props.onEmployeeTab && selected) {
      const isDiff = !prevProps.selected || prevProps.selected.id !== selected.id;
      if (isDiff) {
        const parentNodeId = getParentNodeId(selected);
        if (selected.archived && !this.expanded.has(ARCHIVE)) {
          this.toggleAccordian(ARCHIVE);
        }
        if (!this.expanded.has(parentNodeId)) {
          this.toggleAccordian(parentNodeId);
        }
      }
    }

    if (shouldGetEmployees(prevProps.tagFilters, this.props.tagFilters)) {
      this.setState({ loading: true });
      this.getEmployees();
    }
  }


  componentWillUnmount() {
    this.onDebounceSearch.cancel();
  }


  onSearchFilter(e) {
    const prevFilter = this.state.filter;
    this.setState({ filter: e.target.value }, () => {
      const { filter } = this.state;
      if (prevFilter.trim().length || filter.trim().length) {
        this.onDebounceSearch();
      }
    });
  }


  onDebounceSearch() {
    this.getEmployees();
  }


  getEmployees() {
    const { tagFilters, fetchEmployees } = this.props;
    const { filter } = this.state;
    fetchEmployees(tagFilters, filter).then(() => {
    }).catch((err) => {
      if (!isCancel(err)) {
        toast('Something went wrong.');
        throw err;
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }


  getRow({ index, key, style }) {
    const { virtualList } = this.state;
    const isFirst = this.isLetter(index - 1);
    const isLast = this.isLetter(index + 1) || index === virtualList.length - 1;

    const renderObj = virtualList[index];
    const isLetter = this.isLetter(index);
    const { uiSearchType } = this.props;

    return (
      <div
        key={key}
        style={style}
        className={isLetter ? 'alpha-row' : `emp-row${isFirst ? ' first' : ''}${isLast ? ' last' : ''}`}
      >
        {
          isLetter ? (
            <>
              {renderObj.rowType === 'SECTION' ? (
                <Section
                  label={renderObj.letter}
                  toggleExpanded={() => (this.toggleAccordian(renderObj.id))}
                  expanded={this.expanded.has(renderObj.id)}
                  icon={renderObj.icon}
                />
              )
                : (
                  <AlphaSelector
                    letter={renderObj.letter}
                    toggleExpanded={() => (this.toggleAccordian(renderObj.id))}
                    expanded={this.expanded.has(renderObj.id)}
                  />
                )}
            </>
          ) : (
            <SearchItemTab
              search={renderObj}
              uiSearchType={uiSearchType}
            />
          )
        }
      </div>
    );
  }


  isLetter(index) {
    const { virtualList } = this.state;
    return Boolean(virtualList[index] && virtualList[index].letter);
  }


  calcRowHeight({ index }) {
    let itemHeight = window.innerWidth <= 1025 ? 58 : 68;
    const searchHeight = window.innerWidth <= 1025 ? 34 : 44;
    const letterHeight = window.innerWidth <= 1025 ? 38 : 48;

    const item = this.state.virtualList[index];
    if (item && item.search_objects) {
      const searches = nonSelfSearches(item.search_objects);
      itemHeight += searches.length * searchHeight;
    }
    const isLetter = this.isLetter(index);
    return isLetter ? letterHeight : itemHeight;
  }


  regenRows() {
    const { employees } = this.props;
    this.allNodes = buildTree(employees);
    this.buildVirtualList();
  }


  toggleAccordian(id) {
    // todo: update virtual list faster (do some splicey stuff instead of just calling build)
    const updated = new Set(this.expanded);
    if (updated.has(id)) {
      updated.delete(id);
    } else {
      updated.add(id);
    }
    this.expanded = updated;
    this.buildVirtualList();
  }


  toggleAll(expanded) {
    return () => {
      if (!expanded) {
        this.expanded = new Set();
      } else {
        const parentIds = getParentNodeIds(this.allNodes);
        this.expanded = new Set(parentIds);
      }
      this.buildVirtualList();
    };
  }


  buildVirtualList() {
    const newList = getFlattenedTree(this.allNodes, this.expanded);
    this.setState({ virtualList: newList });
    if (this.list) {
      this.list.recomputeRowHeights();
    }
  }


  noRowsRenderer(filter) {
    const { uiSearchType, employees, tagFilters } = this.props;
    if (employees.length === 0 && !tagFilters.length && !filter) {
      return <SidebarEmpty searchType={uiSearchType} />;
    }
    return (
      <SidebarNoSearchQuery
        searchType={uiSearchType}
        query={filter}
        tags={tagFilters.length}
      />
    );
  }

  render() {
    const {
      uiSearchType, scrollableHeight, sidebarWidth, onEmployeeTab,
    } = this.props;
    const { filter, loading, virtualList } = this.state;

    const allCollapsed = !this.expanded.size;
    const totalRows = virtualList.length;

    // doing this check here because we want to save filter & expanded letters when switching tabs
    if (!onEmployeeTab) {
      return null;
    }

    return (
      <div>
        <div className="sidebar-search-container">
          <SidebarSearch
            onSearchFilter={this.onSearchFilter}
            filterValue={filter}
            searchPlaceholder={`search ${uiSearchType}...`}
          />
          <span>
            <button
              className="expander text-button"
              onClick={allCollapsed ? this.toggleAll(true) : this.toggleAll(false)}
              type="button"
            >
              <i className="material-icons">{allCollapsed ? 'unfold_less' : 'unfold_more'}</i>
            </button>
          </span>
        </div>
        { loading
          ? (
            <div className="load-wrapper">
              <Loading />
            </div>
          )
          : (
            <List
              height={scrollableHeight}
              rowCount={totalRows}
              rowHeight={this.calcRowHeight}
              rowRenderer={this.getRow}
              width={sidebarWidth}
              ref={(el) => { this.list = el; }}
              noRowsRenderer={() => this.noRowsRenderer(filter)}
            />
          )}
      </div>
    );
  }
}


EmployeeSidebar.propTypes = {
  employees: PropTypes.array.isRequired,
  selected: PropTypes.object,
  uiSearchType: PropTypes.string.isRequired,
  onEmployeeTab: PropTypes.bool.isRequired,
  fetchEmployees: PropTypes.func.isRequired,
  scrollableHeight: PropTypes.number.isRequired,
  sidebarWidth: PropTypes.number.isRequired,
  tagFilters: PropTypes.array.isRequired,
};

EmployeeSidebar.defaultProps = {
  selected: null,
};
