import React from 'react';
import DOMPurify from 'dompurify';
import { Field, useField } from 'formik';

import { FORM_ELEMENT } from '../constants';

const CheckboxField = ({ label, ...props }) => {
  const [field] = useField({ ...props, type: 'checkbox' });
  const cleanLabel = DOMPurify.sanitize(label);
  return (
    <div className="checkbox">
      <Field type="checkbox" {...field} {...props} />
      <label
        htmlFor={field.name}
      >
        <span
          dangerouslySetInnerHTML={{ __html: cleanLabel }}
        />
        {props.required && <span className="required-asterisk">&ensp;*</span>}
      </label>
    </div>
  );
};

CheckboxField.propTypes = {
  ...FORM_ELEMENT,
};

export { CheckboxField };
