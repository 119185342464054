import {
  DECIMAL,
  DATE,
  NA,
  STATUS,
  CHECKBOX,
  ENUM,
  SELECT,
} from '../constants';


const filterRange = {
  from: 'gte',
  to: 'lte',
};


const createRangeFilter = (columnId, filter) => {
  const filterStrings = Object.keys(filter).reduce((prev, curr) => {
    const filterString = `${columnId}__${filterRange[curr]}=${filter[curr]}`;
    return filter[curr] ? [...prev, filterString] : prev;
  }, []);
  return filterStrings.join('&');
};


export const createFilterString = (filters) => {
  const filterStrings = filters.map(({ columnId, value, type }) => {
    let filter = `${columnId}__icontains=${value}`;
    switch (type) {
      case DECIMAL:
      case DATE:
        filter = createRangeFilter(columnId, value);
        break;
      case CHECKBOX:
        filter = `${columnId}__bool=${value}`;
        break;
      case SELECT:
      case ENUM: {
        if (value === NA) {
          filter = `${columnId}__isnull=True`;
        }
        break;
      }
      default:
    }
    if (columnId === STATUS) {
      filter = `${columnId}__exact=${value}`;
    }
    return filter;
  });
  return filterStrings.join('&');
};


export const makeJurisdictionMap = (jurisdictions) => {
  const jdMap = Object.values(jurisdictions).reduce((allJds, jurisdiction) => {
    const jdCopy = Object.assign({}, jurisdiction);
    jdCopy.name = jdCopy.name.charAt(0).toUpperCase() + jdCopy.name.slice(1);
    allJds.byId[jdCopy.id] = jdCopy;
    allJds.byName[jdCopy.name] = jdCopy;
    return allJds;
  }, { byId: {}, byName: {} });
  return jdMap;
};


// not currently handling 500 when sending email to invalid email address
// (though does properly handle 400 when trying to set an email field)
// this should be fixed by sending more unified errors from BE
export const handleInvalidEmailError = (err) => {
  if (err.response.data && err.response.data.email) {
    return 'Please enter a valid email address.';
  }
};
