import React from 'react';
import PropTypes from 'prop-types';
import { formatAmount, formatDate } from 'helpers/formatFieldHelpers';
import {
  CHECKBOX,
  DATE,
  DECIMAL,
  ENUM,
  SELECT,
} from './constants';


// filters: array of filter objects
//  { field, value }
export default function Filters({ filters, removeFilter }) {
  if (!filters.length) {
    return <div />;
  }
  return (
    <div className="preclearance-filters">
      <span>FILTERS:</span>
      {filters.map(({
        field,
        columnId,
        value,
        type,
        choices,
      }, idx) => (
        <FilterItem
          field={field}
          value={value}
          type={type}
          choices={choices}
          idx={idx}
          removeFilter={removeFilter}
          key={columnId}
        />
      ))}
    </div>
  );
}

Filters.propTypes = {
  filters: PropTypes.array.isRequired,
  removeFilter: PropTypes.func.isRequired,
};


const filterHelpers = {
  [DECIMAL]: {
    from: 'Greater than ',
    to: 'Less than ',
  },
  [DATE]: {
    from: 'After ',
    to: 'Before ',
  },
};

const formatRange = (from, to, type) => {
  const filterText = filterHelpers[type];
  if (from && to) {
    return `${from} - ${to}`;
  }
  return from ? `${filterText.from} ${from}` : `${filterText.to} ${to}`;
};

const FilterItem = ({
  field,
  value,
  type,
  choices,
  idx,
  removeFilter,
}) => {
  let filterText = value;
  let { from, to } = value || '';

  switch (type) {
    case DECIMAL: {
      from = from ? formatAmount(from) : '';
      to = to ? formatAmount(to) : '';
      filterText = formatRange(from, to, type);
      break;
    }
    case DATE: {
      from = formatDate(from);
      to = formatDate(to);
      filterText = formatRange(from, to, type);
      break;
    }
    case CHECKBOX:
      filterText = `${value}`;
      break;
    case SELECT:
    case ENUM:
      filterText = new Map(choices).get(value) || value;
      break;
    default:
  }

  return (
    <li>
      <div className="preclearance-filter-content">
        <span>
          {field}
          :
          {' '}
        </span>
        <span>
          { filterText }
        </span>
      </div>
      <button
        type="button"
        onClick={removeFilter(idx)}
        className="text-button"
      >
        <i className="material-icons">
          close
        </i>
      </button>
    </li>
  );
};

FilterItem.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  choices: PropTypes.array,
  idx: PropTypes.number.isRequired,
  removeFilter: PropTypes.func.isRequired,
};

FilterItem.defaultProps = {
  choices: [],
};
