import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PATHS, PATH_NAMES } from 'utils/constants';
import { isBeforeDay } from '../../helpers/date_helpers';
import RadioButton from '../shared/radioButton';
import CustomDateInput from './customDateInput';

const RadioGroup = ({ options, handleSelect, selectedOption, disabledOptions }) => (
  options.map((option, i) => (
    <div key={i}>
      <RadioButton
        id={option}
        checked={option === selectedOption}
        value={option}
        onChange={handleSelect}
        disabled={disabledOptions.includes(option)}
      >
        <span>{option}</span>
      </RadioButton>
    </div>
  ))
);

export function getDatesOutsideGDF(dateOptions, globalDateFilter) {
  return Object.keys(dateOptions).filter((key) => {
    const date = dateOptions[key];
    const fromDate = date.from ? moment(date.from, 'X').utc() : null;
    return globalDateFilter && isBeforeDay(fromDate, moment(globalDateFilter));
  });
}

const DateFilters = ({ globalDateFilter, dateFilter, handleSelect,
  handleFieldChange, dateOptions, customDateInput }) => {
  const disabledDateOptions = getDatesOutsideGDF(dateOptions, globalDateFilter);
  return (
    <div>
      { globalDateFilter ? (
        <div className="global-date-text">
          You are currently excluding all records before {globalDateFilter}, this can be updated on the <Link className="plain-link" to={`${PATHS[PATH_NAMES.platform_settings]}`}>Settings</Link> page.
        </div>
      ) : '' }
      <RadioGroup
        options={Object.keys(dateOptions)}
        handleSelect={(e) => { handleSelect('dateFilter', e.target.value); }}
        selectedOption={dateFilter}
        disabledOptions={disabledDateOptions}
      />
      <CustomDateInput
        handleSelect={handleSelect}
        handleFieldChange={handleFieldChange}
        customDate={customDateInput}
      />
    </div>
  );
};

DateFilters.propTypes = {
  globalDateFilter: PropTypes.string,
  dateFilter: PropTypes.string.isRequired,
  dateOptions: PropTypes.object.isRequired,
  customDateInput: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
};

DateFilters.defaultProps = {
  globalDateFilter: null,
};

export default DateFilters;
