import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BodyWithSidebar from 'components/shared/BodyWithSidebar';
import EditStatusesWrapper from 'components/settings/platformSettings/editStatusesWrapper';
import CustomFieldsManager from 'components/settings/platformSettings/customFieldsManager';
import TagsManager from 'components/settings/platformSettings/tagsManager';
import GlobalDateFilter from 'components/settings/platformSettings/globalDateFilter';
import AlertsEmailManager from 'components/settings/platformSettings/AlertEmails/AlertsEmailManager';
import AutomatchSettings from 'components/settings/platformSettings/automatchSettings';
import ArchiveSettings from 'components/settings/platformSettings/ArchiveSettings/ArchiveSettings';
import DeleteSettings from 'components/settings/platformSettings/DeleteSettings/DeleteSettings';


const [
  TAGS,
  STATUS_LABELS,
  CUSTOM_FIELDS,
  DATE_FILTER,
  ALERT_EMAILS,
  AUTOMATED_MATCHING,
  ARCHIVE_ACTIONS,
  DELETE_ACTIONS,
] = [
  'TAGS',
  'STATUS_LABELS',
  'CUSTOM_FIELDS',
  'DATE_FILTER',
  'ALERT_EMAILS',
  'AUTOMATED_MATCHING',
  'ARCHIVE_ACTIONS',
  'DELETE_ACTIONS',
];

const tabMap = {
  [TAGS]: 'Tags',
  [STATUS_LABELS]: 'Status Labels',
  [CUSTOM_FIELDS]: 'Custom Fields',
  [DATE_FILTER]: 'Date Filter',
  [ALERT_EMAILS]: 'Alert Emails',
  [AUTOMATED_MATCHING]: 'Automated matching',
  [ARCHIVE_ACTIONS]: 'Archive actions',
  [DELETE_ACTIONS]: 'Delete actions'
};

const bodyMap = {
  [TAGS]: TagsManager,
  [STATUS_LABELS]: EditStatusesWrapper,
  [CUSTOM_FIELDS]: CustomFieldsManager,
  [DATE_FILTER]: GlobalDateFilter,
  [ALERT_EMAILS]: AlertsEmailManager,
  [AUTOMATED_MATCHING]: AutomatchSettings,
  [ARCHIVE_ACTIONS]: ArchiveSettings,
  [DELETE_ACTIONS]: DeleteSettings,
};

const Settings = ({ isManager }) => {
  const tabs = [TAGS, STATUS_LABELS, DATE_FILTER, ALERT_EMAILS, AUTOMATED_MATCHING, ARCHIVE_ACTIONS, DELETE_ACTIONS];
  if (isManager) {
    tabs.splice(2, 0, CUSTOM_FIELDS);
  }
  return (
    <BodyWithSidebar
      title={'Platform Settings'}
      tabs={tabs}
      tabMap={tabMap}
      bodyMap={bodyMap}
      className={'settings'}
    />
  );
};

const mapStateToProps = state => ({
  isManager: state.user.is_manager,
});

Settings.propTypes = {
  isManager: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {})(Settings);
