import React from 'react';
import Tippy from '@tippyjs/react';
import { Field, useField } from 'formik';

const strictHelpText = "Enabling this will limit searches to exact last name matches. For example, when strict is enabled, a search for 'Beyoncé Knowles' will filter out records where the contributor name is 'Beyoncé Knowles-Carter'.";

export const StrictLastName = ({ ...props }) => {
  const [field] = useField({ ...props, type: 'checkbox' });

  return (
    <div className="strict-checkbox">
      <div className="checkbox">
        <Field type="checkbox" {...field} {...props} />
        <label htmlFor={field.name}>
          <span>
            {' '}
            <span className="input-title">Strict</span>
            {' '}
            <span className="tooltip-wrapper">
              <Tippy content={strictHelpText}>
                <i className="material-icons">info</i>
              </Tippy>
            </span>
          </span>
        </label>
      </div>
    </div>
  );
};
