import { createSelector } from 'reselect';

export const getAllPlatforms = (state) => state.platforms;


// platforms user has access to
export const getSwitchToPlatforms = createSelector(
  [getAllPlatforms],
  (platforms) => platforms.filter((platform) => platform.platformUserID),
);


// platforms user does not have access to
export const getDiscoverPlatforms = createSelector(
  [getAllPlatforms],
  (platforms) => platforms.filter((platform) => !platform.platformUserID),
);


export const getPlatform = (state, id) => state.platforms.find(
  // eslint-disable-next-line eqeqeq
  (platform) => platform.platformID == id,
);
