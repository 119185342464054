import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { CUSTOM_DATE_RANGE } from 'utils/constants';
import { exportChart } from 'utils/exportChart';
import { getValueFromLocalStorage, saveValueInLocalStorage } from 'utils/localStorageUtil';
import { isBeforeDay } from 'helpers/date_helpers';
import { getSearch } from 'reducers/searchObjects/actions';
import { getChartData } from 'reducers/aggregations/actions';
import { makeGetSerializedAggData } from 'reducers/aggregations/selectors';
import { formatDate } from 'components/preclearance/table/cellValidator';
import { CONTRIBUTOR, DATE_OPTIONS_MAP, getDefaultDateOption } from './consts';
import { ChartItem } from './chartItem';


function getDatesOutsideGDF(dateOptions, globalDateFilter) {
  return Object.keys(dateOptions).filter((key) => {
    const date = dateOptions[key];
    const fromDate = date.from ? moment(date.from) : null;
    return globalDateFilter && isBeforeDay(fromDate, moment(globalDateFilter));
  });
}

const errorMessage = 'We were unable to fetch the data for this chart.';


class ChartItemContainer extends React.Component {
  constructor(props) {
    super(props);
    const { globalDateFilter, nameKey, defaultDate } = props;
    this.noDateOption = getDefaultDateOption(globalDateFilter);
    this.dateOptions = {
      [this.noDateOption]: { from: null, to: null },
      ...DATE_OPTIONS_MAP,
    };

    this.localStorageDateKey = `${nameKey}_chart_date_filter`;
    this.localStorageCustomDateKey = `${nameKey}_chart_custom_date_filter`;

    const savedDateFilter = getValueFromLocalStorage(
      this.localStorageDateKey, (defaultDate || this.noDateOption),
    );
    const dateFilter = (!this.dateOptions[savedDateFilter] && savedDateFilter !== CUSTOM_DATE_RANGE) ? this.noDateOption : savedDateFilter;
    const customDate = getValueFromLocalStorage(this.localStorageCustomDateKey, { to: '', from: '' });

    this.state = {
      loading: true,
      dateFilter,
      customDate,
      error: '',
    };

    this.getData = this.getData.bind(this);
    this.selectDate = this.selectDate.bind(this);

    this.disabledDateOptions = getDatesOutsideGDF(this.dateOptions, globalDateFilter);
  }

  componentDidMount() {
    this.getData();
  }


  getData() {
    const { nameKey, limit, order, sortByDim } = this.props;
    const { dateFilter, customDate } = this.state;
    let { from, to } = dateFilter === CUSTOM_DATE_RANGE ? customDate : this.dateOptions[dateFilter];
    if (dateFilter === CUSTOM_DATE_RANGE) {
      from = from ? formatDate(from) : null;
      to = to ? formatDate(to) : null;
    }
    let error = '';
    this.props.getChartData(nameKey, limit, from, to, order, sortByDim).then((data) => {
      if (nameKey === CONTRIBUTOR) {
        return Promise.all(data.map(agg => this.props.getSearch(agg.contributor)));
      }
    }).catch(() => {
      error = errorMessage;
    }).finally(() => {
      this.setState({
        loading: false,
        error,
      });
    });
  }

  selectDate(date, newCustomDate) {
    const customDate = newCustomDate || { to: '', from: '' };
    this.setState({
      dateFilter: date,
      customDate,
      loading: true,
    }, () => {
      this.getData();
      saveValueInLocalStorage(this.localStorageDateKey, date);
      saveValueInLocalStorage(this.localStorageCustomDateKey, customDate);
    });
  }


  render() {
    const { title, nameKey, chartType, allowExport, exportFunc, globalDateFilter, data } = this.props;
    const { loading, dateFilter, customDate, error } = this.state;

    return (
      <ChartItem
        title={title}
        nameKey={nameKey}
        chartType={chartType}
        loading={loading}
        allowExport={allowExport}
        exportFunc={() => { exportFunc(this.chartEl, `illumis-chart-${title.toLowerCase()}`); }}
        dateFilter={dateFilter}
        globalDateFilter={globalDateFilter}
        dateFilterSet={dateFilter !== this.noDateOption}
        dateOptions={Object.keys(this.dateOptions)}
        disabledDateOptions={this.disabledDateOptions}
        data={data}
        selectDate={this.selectDate}
        customDate={customDate}
        chartRef={(el) => { this.chartEl = el; }}
        error={error}
      />
    );
  }
}

ChartItemContainer.propTypes = {
  title: PropTypes.string,
  nameKey: PropTypes.string.isRequired,
  limit: PropTypes.number,
  order: PropTypes.string,
  sortByDim: PropTypes.bool,
  chartType: PropTypes.func.isRequired,
  allowExport: PropTypes.bool.isRequired,
  defaultDate: PropTypes.string,
  exportFunc: PropTypes.func,
  getChartData: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  globalDateFilter: PropTypes.string,
  getSearch: PropTypes.func,
};

ChartItemContainer.defaultProps = {
  title: '',
  limit: null,
  order: null,
  sortByDim: false,
  exportFunc: exportChart,
  globalDateFilter: null,
  defaultDate: null,
  getSearch: () => {},
};

const makeMapStateToProps = () => {
  const getSerializedAggData = makeGetSerializedAggData();
  const mapStateToProps = (state, ownProps) => {
    const { nameKey } = ownProps;
    return {
      data: getSerializedAggData(state, nameKey),
      globalDateFilter: state.user.global_date_filter,
    };
  };
  return mapStateToProps;
};


export default connect(makeMapStateToProps, {
  getChartData,
  getSearch,
})(ChartItemContainer);
