import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { stateNames } from '../../stateNames';

export default class StateDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      filter: '',
      active: 0,
    };

    this.toggleStateDropDown = this.toggleStateDropDown.bind(this);
    this.openStateDropDown = this.openStateDropDown.bind(this);
    this.closeStateDropDown = this.closeStateDropDown.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.filterStates = this.filterStates.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  onSearchFilter(e) {
    e.stopPropagation();
    if (!this.state.expanded) {
      this.openStateDropDown();
    }
    this.setState({
      filter: e.target.value,
    });
  }

  toggleStateDropDown() {
    if (this.state.expanded) {
      this.closeStateDropDown();
    } else {
      this.openStateDropDown();
    }
  }

  openStateDropDown() {
    const stateInput = document.getElementById('state-input');
    const searchState = document.getElementById('state-dropdown');
    if (stateInput) {
      stateInput.focus();
      searchState.style.borderColor = '#505050';
    }
    if (!this.state.expanded) {
      document.addEventListener('click', this.handleOutsideClick, false);
      this.setState({ expanded: true });
    }
  }

  closeStateDropDown() {
    const stateInput = document.getElementById('state-input');
    const searchState = document.getElementById('state-dropdown');
    if (stateInput) {
      stateInput.value = '';
      searchState.style.borderColor = '#d1d1d1';
    }
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({ expanded: false });
  }

  handleOutsideClick(e) {
    const stateDropDown = document.getElementById('state-dropdown');
    if (stateDropDown && (e.target === stateDropDown || stateDropDown.contains(e.target))) {
      return;
    }
    this.closeStateDropDown();
  }

  filterStates() {
    const filterInUse = !(this.state.filter.length === 0 || !this.state.filter.trim());
    const { selectedStates } = this.props;

    const states = Object.keys(stateNames).filter(stateName =>
      selectedStates.indexOf(stateName) === -1 && selectedStates.indexOf(stateNames[stateName]) === -1)
      .sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

    if (filterInUse) {
      return states.filter((s) => {
        const filterLowerCase = this.state.filter.toLowerCase();
        return (s.toLowerCase()).indexOf(filterLowerCase.trim()) === 0 ||
         (stateNames[s].toLowerCase()).indexOf(filterLowerCase.trim()) === 0;
      });
    }
    return states;
  }

  handleKeyPress(e) {
    e.stopPropagation();
    const { selectedStates } = this.props;
    const { active } = this.state;
    const filteredStates = this.filterStates();
    // if (e.key === 'ArrowUp' && active > 0) {
    //   this.setState(prevState => ({
    //     active: prevState.active - 1,
    //   }));
    // } else if (e.key === 'ArrowDown' && active < filteredStates.length - 1) {
    //   this.setState(prevState => ({
    //     active: prevState.active + 1,
    //   }));
    // }
    if (e.key === 'Enter' && selectedStates.length < 5) {
      e.preventDefault();
      if (filteredStates[active]) {
        this.props.handleSelectState(e, filteredStates[active]);
        this.setState({ filter: '' });
      }
    }
    if (e.key === 'Backspace' && this.state.filter.length === 0) {
      this.props.handleUnselectState(e, selectedStates[selectedStates.length - 1]);
    }
  }

  render() {
    const icon = this.state.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down';

    const filteredStates = this.filterStates();
    const filterInUse = !(this.state.filter.length === 0 || !this.state.filter.trim());
    const { disabled } = this.props;


    return (
      <div
        id="state-dropdown"
        role="menu"
        className={`search-states dropdown ${disabled ? 'disabled' : ''}`}
        tabIndex="0"
        onClick={this.openStateDropDown}
      >
        <div>
          { this.props.selectedStates.map(selectedState =>
            (<button
              type="button"
              onClick={e => this.props.handleUnselectState(e, selectedState)}
              className="selected-states"
              key={selectedState}
              disabled={disabled}
            >
              { selectedState }
              <i className="material-icons close">close</i>
            </button>),
          )
          }

          <input
            id="state-input"
            type="text"
            value={this.state.filter}
            onChange={(e) => { this.onSearchFilter(e); }}
            onFocus={this.openStateDropDown}
            onClick={this.openStateDropDown}
            onKeyDown={(e) => { this.handleKeyPress(e); }}
            autoComplete="placeholderValue"
            placeholder={this.props.placeholder}
          />
        </div>
        <button className="toggle text-button" type="button" onClick={this.toggleStateDropDown}>
          <i className="material-icons" >
            {icon}
          </i>
        </button>

        { this.state.expanded && this.props.selectedStates.length < 5 ?
          <ul className="dropdown-container">
            { filteredStates.length === 0 && filterInUse ? <div className="no-states">No results found.</div> : ''}
            { filteredStates.map((unselectedState, i) =>
              (<li
                key={unselectedState}
              >
                <button
                  className="option"
                  type={'button'}
                  onClick={(e) => {
                    this.props.handleSelectState(e, unselectedState);
                    this.setState({ filter: '' });
                  }}
                >
                  {unselectedState}
                </button>
              </li>),
            )
            }
          </ul>
          : ''
        } { this.state.expanded && this.props.selectedStates.length >= 5 ?
          <ul className="no-states dropdown-container">
            You have reached the maximum number of states allowed.
          </ul> : ''
        }
      </div>
    );
  }
}

StateDropdown.propTypes = {
  handleSelectState: PropTypes.func.isRequired,
  handleUnselectState: PropTypes.func.isRequired,
  selectedStates: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

StateDropdown.defaultProps = {
  placeholder: '',
  disabled: false,
};
