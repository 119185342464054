import React from 'react';
import PropTypes from 'prop-types';

export const SidebarSearch = props => (
  <span>
    <input
      type="text"
      placeholder={props.searchPlaceholder}
      onChange={(e) => { props.onSearchFilter(e); }}
      value={props.filterValue}
      onKeyDown={props.handleKeyPress}
    />
    <div className="icon-input-overlay">
      <i className="material-icons">search</i>
    </div>
  </span>

);

SidebarSearch.propTypes = {
  onSearchFilter: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  handleKeyPress: PropTypes.func,
};

SidebarSearch.defaultProps = {
  handleKeyPress: () => {},
};
