import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { SPOUSE, DEPENDENT } from './consts';
import SpouseDependentItem from './spouseDependentItem';


function SearchesList({ searches, searchType }) {
  return (
    searches.length ?
      <span className="custom-field-item-wrapper">
        <span className="employee-field-header">
          {pluralize(searchType, searches.length)}:
        </span>
        {searches.map(search => (
          <SpouseDependentItem
            key={search.search_object_id}
            id={search.search_object_id}
          />
        ))}
      </span>
      : null
  );
}

SearchesList.propTypes = {
  searches: PropTypes.array.isRequired,
  searchType: PropTypes.string.isRequired,
};


function SpouseDependentsList({ empSearchObjs }) {
  const spouses = empSearchObjs.filter(search => search.search_type === SPOUSE);
  const dependents = empSearchObjs.filter(search => search.search_type === DEPENDENT);
  return (
    <React.Fragment>
      <SearchesList
        searches={spouses}
        searchType={'spouse'}
      />
      <SearchesList
        searches={dependents}
        searchType={'dependent'}
      />
    </React.Fragment>
  );
}

SpouseDependentsList.propTypes = {
  empSearchObjs: PropTypes.array.isRequired,
};

export default SpouseDependentsList;
