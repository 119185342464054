import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {
  FieldArray, Field, useField, ErrorMessage,
} from 'formik';
import Tippy from '@tippyjs/react';
import { FORM_ELEMENT } from '../constants';


const ArrayField = ({
  addButtonText, minFields, limit, limitReachedText, dataPrivate, ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const limitReached = field.value.length >= limit;

  return (
    <FieldArray {...field}>
      {({ remove, push }) => (
        <div>
          {field.value.length > 0
                  && field.value.map((_val, index) => (
                    <div className={index >= minFields ? 'input-wrapper' : ''} key={index}>
                      <Field
                        name={`${field.name}.${index}`}
                        className={props.className}
                        autoComplete="nope"
                        onChange={(e) => {
                          field.onChange(e);
                          props.onChangeCallback && props.onChangeCallback(e, index);
                        }}
                        data-private={dataPrivate}
                      />
                      <ErrorMessage
                        name={`${field.name}.${index}`}
                        component="div"
                        className="error-text"
                      />
                      <div className="helptext">
                        { props.warnings && props.warnings[index] }
                      </div>
                      {
                        index >= minFields
                          ? (
                            <button
                              type="button"
                              className="text-button"
                              onClick={() => {
                                remove(index);
                                props.onRemoveCallback && props.onRemoveCallback(index);
                              }}
                            >
                              <i className="material-icons">close</i>
                            </button>
                          ) : null
                      }
                    </div>
                  ))}
          {
            limit > minFields
              ? (
                <Tippy
                  content={`There is a limit of ${limit} ${limitReachedText || pluralize.plural(addButtonText)}`}
                  disabled={!limitReached}
                >
                  <div className="button-wrapper">
                    <button
                      type="button"
                      className="text-button uppercase add-field-button"
                      onClick={() => push('')}
                      disabled={limitReached}
                    >
                      + Add
                      {' '}
                      {addButtonText}
                    </button>
                  </div>
                </Tippy>
              )
              : null
          }
        </div>
      )}
    </FieldArray>
  );
};

ArrayField.propTypes = {
  ...FORM_ELEMENT,
  addButtonText: PropTypes.string,
  limit: PropTypes.number,
  minFields: PropTypes.number,
  limitReachedText: PropTypes.string,
  dataPrivate: PropTypes.bool, // exclude data from logRocket
};

ArrayField.defaultProps = {
  addButtonText: '',
  limit: null,
  minFields: 0,
  limitReachedText: '',
  dataPrivate: false,
};

export { ArrayField };
