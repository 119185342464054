/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { stateNames } from 'stateNames';
import { formatNames } from 'components/shared/searchObjectHeader/header';
import CustomFieldsManager from 'components/shared/customFields/customFieldsManager';
import TagsManager from 'components/shared/tags/tagsManager';
import LinkButton from 'components/shared/LinkButton';
import SpouseDependentsList from './spouseDependentsList';
import { NON_SELF_SEARCH_LIMIT, nonSelfSearches } from './consts';


function formatAddress(address) {
  const {
    address1, address2, city, state, postal_code, country,
  } = address;
  const line1 = [address1, address2].filter(Boolean).join(', ');
  const stateAbbrev = stateNames[state] || state;
  const stateAndPostalCode = [stateAbbrev, postal_code].filter(Boolean).join(' ');
  const line2 = [city, stateAndPostalCode].filter(Boolean).join(', ');
  return [line1, line2, country].filter(Boolean).join(', ');
}


function resultDetailsGen(key, ary, joint = ', ') {
  if (!ary || !ary.length) {
    return null;
  }
  return [
    (
      <span className="employee-field-header" key={`${key}_head`}>
        {key}
        :
      </span>
    ),
    (
      <span
        data-private
        className="employee-field-content"
        key={`${key}_body`}
      >
        {ary.join(joint)}
      </span>
    ),
  ];
}


export const disabledTooltip = `There is a limit of ${NON_SELF_SEARCH_LIMIT} spouses and dependents per employee.`;


function EmployeeDetails({ employee }) {
  const {
    id, email, alt_emails, external_id, addresses, archived, search_objects: searches,
  } = employee;
  const { mainName, aliases } = formatNames(employee);
  const { url } = useRouteMatch();
  const atNonSelfSearchLimit = nonSelfSearches(searches).length >= NON_SELF_SEARCH_LIMIT;

  return (
    <TagsManager
      key={employee.id}
      id={employee.id}
      selectedTags={employee.tags}
      disabled={archived}
      render={({ tagDropdown, appliedTags }) => (
        <div className="employee-details-header">
          <div className="results-name-row">
            <h4>
              <div className="header-wrapper">
                <div className="name-wrapper">
                  <strong>{mainName}</strong>
                </div>
                {appliedTags}
              </div>
            </h4>
            <div className="results-actions">
              {tagDropdown}
              <LinkButton
                url={`${url}/edit`}
                linkText="Edit person"
                icon="edit"
              />
              <LinkButton
                disabled={archived || atNonSelfSearchLimit}
                url={`${url}/new/spouse`}
                linkText="Add spouse"
                icon="person_add"
                tooltip={archived ? 'Spouses cannot be added to archived employees.' : disabledTooltip}
              />
              <LinkButton
                disabled={archived || atNonSelfSearchLimit}
                url={`${url}/new/dependent`}
                linkText="Add dependent"
                icon="person_add"
                tooltip={archived ? 'Dependents cannot be added to archived employees.' : disabledTooltip}
              />
            </div>
          </div>
          <div className="results-details-row">
            <div className="filters-span">
              <SpouseDependentsList
                empSearchObjs={employee.search_objects}
              />
              {
                [
                  ['Alternate Names', aliases],
                  ['Primary email', email ? [email] : []],
                  ['Alternate emails', alt_emails],
                  ['Employee id', external_id ? [external_id] : []],
                ].map((details) => resultDetailsGen(...details))
              }
              { addresses && addresses.length > 0
            && (
            <>
              <span className="employee-field-header">
                Addresses:
              </span>
              {[...addresses].map((address, i) => (
                <span
                  className="employee-field-content"
                  key={`emp-detail-${i}`}
                  data-private
                >
                  {formatAddress(address)}
                </span>
              ))}
            </>
            )}
              {/* to do improve formatting */}
              {
                <CustomFieldsManager
                  customData={employee.custom_data || {}}
                  empId={employee.id}
                  key={id}
                  readOnly={archived}
                />
            }
            </div>
          </div>
        </div>
      )}
    />
  );
}

EmployeeDetails.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default EmployeeDetails;
