import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AutoReports extends Component {
  constructor(props) {
    super(props);
    const scheduledReport = this.props.scheduledReports.length > 0 ?
      this.props.scheduledReports[0] : {};

    let emails = [''];
    if (scheduledReport.recipient_list && scheduledReport.recipient_list.length > 0
      && scheduledReport.is_active) {
      emails = [...scheduledReport.recipient_list];
    } else if (this.props.userEmail) {
      emails = [this.props.userEmail];
    }
    const frequency = scheduledReport.report_time && scheduledReport.is_active ? scheduledReport.report_time : '';
    const id = scheduledReport.id ? scheduledReport.id : '';
    this.state = {
      id,
      frequency,
      emails,
      dropdownOpen: false,
      errors: {},
      confirmation: '',
      submitting: false,
    };

    this.frequencies = scheduledReport.is_active ? ['monthly', 'quarterly', 'Stop Reports'] : ['monthly', 'quarterly'];

    this.handleDropdownSelection = this.handleDropdownSelection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.addField = this.addField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  resetState(report) {
    let emails = [''];
    if (report.recipient_list && report.recipient_list.length > 0
      && report.is_active) {
      emails = [...report.recipient_list];
    } else if (this.props.userEmail) {
      emails = [this.props.userEmail];
    }
    const frequency = report.report_time && report.is_active ? report.report_time : '';
    const id = report.id ? report.id : '';
    this.setState({
      id,
      frequency,
      emails,
    });
    this.frequencies = report.is_active ? ['monthly', 'quarterly', 'Stop Reports'] : ['monthly', 'quarterly'];
  }

  handleDropdownSelection(e) {
    e.preventDefault();
    this.setState({
      frequency: e.target.value,
      errors: { ...this.state.errors, frequency: false },
      confirmation: '',
    });
    this.closeDropdown();
  }

  handleOutsideClick(e) {
    if (this.dropdownEl && this.dropdownEl.contains(e.target)) {
      return;
    }
    this.closeDropdown();
  }

  openDropdown(e) {
    e.preventDefault();
    this.setState({ dropdownOpen: true });
    document.addEventListener('click', this.handleOutsideClick, false);
  }

  closeDropdown() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({ dropdownOpen: false });
  }

  handleFieldChange(i, e, field) {
    if (e.target.value.indexOf('@') !== -1) {
      this.setState({ errors: { ...this.state.errors, [field]: false } });
    }
    const updateArray = this.state[field];
    updateArray[i] = e.target.value;
    this.setState({ [field]: updateArray, confirmation: '' });
  }

  addField(e, field) {
    e.preventDefault();
    this.setState({
      [field]: [...this.state[field], ''],
    });
  }

  removeField(e, i, field) {
    e.preventDefault();
    this.setState(prevState => ({
      [field]: prevState[field].filter((n, index) =>
        i !== index),
    }));
  }

  handleSubmit(e) {
    e.preventDefault();
    const { emails, frequency } = this.state;
    if (frequency === 'Stop Reports') {
      this.handleDelete();
      return;
    }
    const filteredEmails = emails.filter(email => email.trim().length > 0);
    let errors = {};
    if (frequency.trim().length === 0) {
      errors = { ...errors, frequency: 'Please select frequency' };
    }
    if (filteredEmails.length < 1 || filteredEmails.filter(email => email.indexOf('@') === -1).length > 0) {
      errors = { ...errors, emails: 'Please enter a vaild email address' };
    }
    if (Object.values(errors).some(err => err)) {
      this.setState({ errors });
      return;
    }

    this.setState({ submitting: true });
    if (this.state.id) {
      this.props.updateAutoReports({
        id: this.state.id,
        report_time: frequency,
        recipient_list: filteredEmails,
        is_active: true,
      }).then((record) => {
        this.resetState(record);
        this.setState({
          errors: {},
          submitting: false,
          confirmation: 'Your report schedule has been updated',
        });
      }).catch((err) => {
        const { data, status } = err.response;
        if (status) {
          let errorMessage = data;
          if (typeof data === 'object') {
            if (data.recipient_list) {
              errorMessage = Object.values(data.recipient_list).join('');
              errorMessage = {
                emails: errorMessage,
              };
            } else if (data.report_time) {
              errorMessage = Object.values(data.report_time).join('');
              errorMessage = {
                frequency: errorMessage,
              };
            } else {
              errorMessage = {
                errorMessage,
              };
            }
          }
          this.setState({
            errors: errorMessage,
            submitting: false,
          });
        }
        throw err;
      });
    } else {
      this.props.createAutoReports({
        report_time: frequency,
        recipient_list: filteredEmails,
        is_active: true,
      }).then((record) => {
        this.resetState(record);
        this.setState({
          errors: {},
          submitting: false,
          confirmation: 'Your report schedule has been created',
        });
      }).catch((err) => {
        const { data, status } = err.response;
        if (status) {
          let errorMessage = data;
          if (typeof data === 'object') {
            if (data.recipient_list) {
              errorMessage = Object.values(data.recipient_list).join('');
              errorMessage = {
                emails: errorMessage,
              };
            } else if (data.report_time) {
              errorMessage = Object.values(data.report_time).join('');
              errorMessage = {
                frequency: errorMessage,
              };
            } else {
              errorMessage = {
                errorMessage,
              };
            }
          }
          this.setState({
            errors: errorMessage,
            submitting: false,
          });
        }
        throw err;
      });
    }
  }

  handleDelete() {
    this.setState({ submitting: true });
    this.props.updateAutoReports({
      is_active: false,
      id: this.state.id,
    }).then(() => {
      this.setState({
        errors: {},
        submitting: false,
        confirmation: 'You will no longer receive automated reports.',
        frequency: '',
        emails: this.props.userEmail ? [this.props.userEmail] : [''],
      });
    }).catch((err) => {
      const { data, status } = err.response;
      if (status) {
        let errorMessage = data;
        if (typeof data === 'object') {
          if (data.recipient_list) {
            errorMessage = Object.values(data.recipient_list).join('');
            errorMessage = {
              emails: errorMessage,
            };
          } else if (data.report_time) {
            errorMessage = Object.values(data.report_time).join('');
            errorMessage = {
              frequency: errorMessage,
            };
          } else {
            errorMessage = {
              errorMessage,
            };
          }
        }
        this.setState({
          errors: errorMessage,
          submitting: false,
        });
      }
      throw err;
    });
  }

  render() {
    let helperText = '';
    switch (this.state.frequency) {
      case 'quarterly':
        helperText = (
          <p>
            You will receive an emailed report on the first of the quarter
             with the records found in the previous quarter.
          </p>
        );
        break;
      case 'monthly':
        helperText = (<p>You will receive an emailed report on the first
           of the month with the records found in the previous month.</p>);
        break;
      case 'Stop Reports':
        helperText = (<p>This will stop automatic reports from sending.</p>);
        break;
      default:
        helperText = '';
    }

    const formInfo = this.props.scheduledReports.length > 0 && this.props.scheduledReports[0].is_active ? `You currently receive ${this.props.scheduledReports[0].report_time} reports to the following email${this.props.scheduledReports[0].recipient_list.length > 1 ? 's' : ''}: ${this.props.scheduledReports[0].recipient_list.join(', ')}. Use the form below to change the frequency, the email addresses that receive reports, or to stop receiving automated reports altogether.` : 'This will automatically send an exported report at the frequency you specify, to the email addresses you specify.';

    return (
      <div id="autoreports">
        <h1>Set Up Automatic Reporting</h1>
        <div className="form-info">
          { formInfo }
        </div>
        <form className="" onSubmit={this.handleSubmit} ref={(form) => { this.form = form; }}>
          <div className="focusable">
            <label htmlFor="frequency">How often would you like to be sent a report?</label>
            { helperText }
            <div className="dropdown-wrapper">
              <div className="dropdown">
                <button className={this.state.errors.frequency ? 'dropdownButton textButton error' : 'dropdownButton textButton'} onClick={this.openDropdown}>
                  <span>{ this.state.frequency || 'Select frequency' }</span>
                  <i className="material-icons">
                    {this.state.dropdownOpen ? 'expand_less' : 'expand_more'}
                  </i>
                </button>
                { this.state.dropdownOpen ?
                  <div className="dropdown-container" ref={(el) => { this.dropdownEl = el; }}>
                    {this.frequencies.filter(frequency => frequency !== this.state.frequency).map((frequency, i) =>
                      (<button
                        className="textButton option"
                        value={frequency}
                        key={i}
                        onClick={e => this.handleDropdownSelection(e)}
                      >
                        {frequency}
                      </button>),
                    )}
                  </div> : ''
                }
              </div>
              <div className="error-text">
                { this.state.errors.frequency ? this.state.errors.frequency : ''}
              </div>
            </div>
          </div>

          <div className="focusable">
            <label htmlFor="frequency">Where would you like the report to be sent?</label>
            <div>
              { this.state.emails.map((email, i) => (
                <div className="input-wrapper" key={i}>
                  <input
                    className={this.state.errors.emails ? 'error' : ''}
                    type="text"
                    value={email}
                    onChange={(e) => { this.handleFieldChange(i, e, 'emails'); }}
                  />
                  { i > 0 ?
                    <button
                      className="remove text-button"
                      onClick={(e) => { this.removeField(e, i, 'emails'); }}
                    >
                      <i className="material-icons">close</i>
                    </button>
                    : ''}
                </div>
              ))}
            </div>
            <div className="error-text">
              { this.state.errors.emails ? this.state.errors.emails : ''}
            </div>
            { this.state.emails.length < 5 ?
              <button className="text-button uppercase" onClick={(e) => { this.addField(e, 'emails'); }}>+ Add additional email</button>
              : ''}
            <div className="error-text">{this.state.errors.errorMessage}</div>
            <div className="text-center">
              <input
                type="submit"
                value="Submit"
                disabled={this.state.submitting}
                className="btn btn-primary"
              />
            </div>
          </div>

        </form>
        <div className="success-message">{ this.state.confirmation }</div>
      </div>
    );
  }
}


AutoReports.propTypes = {
  userEmail: PropTypes.string.isRequired,
  scheduledReports: PropTypes.array.isRequired,
  createAutoReports: PropTypes.func.isRequired,
  updateAutoReports: PropTypes.func.isRequired,
};


export default AutoReports;
