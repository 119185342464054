import { connect } from 'react-redux';
import JurisdictionDropdown from '../jurisdictionDropdown';

const mapStateToProps = ({ jurisdictions }, { jurisdiction }) => ({
  jurisdictions,
  jurisdiction,
  dropdownText: jurisdiction ? jurisdiction.name : 'select a jurisdiction',
});

export default connect(
  mapStateToProps,
  {},
)(JurisdictionDropdown);
