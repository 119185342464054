import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { POLITICAL_CONTRIBUTIONS, CONTRIB_SCHEMA, JUR_SCHEMA } from 'utils/constants';
import { CategoryIcon } from 'components/shared/recordItem/categoryIcon';
import RecordWrapper from '../shared/recordItem/recordWrapper';
import ObjectDropdown from '../shared/objectDropdown';
import Schema from '../shared/sortableSchema';
import RecordCell from '../shared/recordItem/recordCell';
import Checkbox from '../monitoringForm/sourceSelection/checkbox';


class RecordRow extends PureComponent {
  render() {
    const {
      selected,
      isLast,
      data,
      meta,
      sourceSchema,
      statuses,
      sourceId,
      sourceCat,
      jurisdictionName,
      setSectionStatus,
      createComment,
      toggleContribution,
      indeterminate,
    } = this.props;
    return (
      <RecordWrapper
        sourceId={sourceId}
        data={data}
        meta={meta}
        noteIdParam={meta.id}
        toggleContribution={toggleContribution}
        setSectionStatus={setSectionStatus}
        createComment={createComment}
        disableMatching={sourceCat !== POLITICAL_CONTRIBUTIONS}
        render={({
          onRecipientEnter,
          onRecipientExit,
          hasFields,
          showCcd,
          setStatus,
          renderComments,
          renderSendRecord,
          renderMatchButton,
        }) => {
          const currentStatus = statuses[meta.status];
          return (
            <div className={`dashboard-record-container ${isLast ? 'last ' : ''}`}>
              <div className="flex-wrapper">
                <CategoryIcon sourceCat={sourceCat} />
                <div className="dashboard-record-wrapper">
                  <div className="schema">
                    <div className="dashboard-record">
                      <div className="record-checkbox empty">
                        <span />
                      </div>
                      <Schema
                        schema={[...sourceSchema, JUR_SCHEMA, ...CONTRIB_SCHEMA]}
                      />
                    </div>
                  </div>
                  <div className="dashboard-record">
                    <div className="record-checkbox">
                      <Checkbox
                        id={`contrib-${meta.id}`}
                        checked={selected}
                        onChange={(e) => {
                          toggleContribution([meta.id], e.target.checked);
                        }}
                        indeterminate={indeterminate}
                      >
                        <span />
                      </Checkbox>
                    </div>
                    {
                      sourceSchema.map(s => (
                        <RecordCell
                          key={s.name}
                          schema={s}
                          onRecipientEnter={onRecipientEnter}
                          onRecipientExit={onRecipientExit}
                          data={data}
                          hasFields={hasFields}
                          showCcd={showCcd}
                        />
                      ))
                    }
                    <div
                      className="dashboard-cell fixedWidth jurisdiction"
                    >
                      <span>
                        <span>
                          {jurisdictionName || 'N/A'}
                        </span>
                      </span>
                    </div>
                    <div className="dashboard-cell fixedWidth status">
                      <ObjectDropdown
                        dropdownText={currentStatus ? currentStatus.label : ''}
                        selected={currentStatus}
                        options={Object.values(statuses)}
                        handleDropdownSelection={setStatus}
                        height={isLast ? '122px' : null}
                        field={'label'}
                      />
                    </div>
                    <div className="dashboard-cell fixedWidth match-cell">
                      {renderMatchButton()}
                    </div>
                    <div className="dashboard-cell fixedWidth notes">
                      {renderComments()}
                    </div>
                    <div className="dashboard-cell fixedWidth notes">
                      {renderSendRecord()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

RecordRow.propTypes = {
  data: PropTypes.object.isRequired,
  meta: PropTypes.object,
  selected: PropTypes.bool,
  toggleContribution: PropTypes.func.isRequired,
  setSectionStatus: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
  isLast: PropTypes.bool.isRequired,
  createComment: PropTypes.func.isRequired,
  sourceSchema: PropTypes.array.isRequired,
  jurisdictionName: PropTypes.string,
  statuses: PropTypes.object.isRequired,
  sourceId: PropTypes.number,
  sourceCat: PropTypes.string,
};

RecordRow.defaultProps = {
  meta: {},
  indeterminate: false,
  selected: false,
  jurisdictionName: '',
  sourceId: 0, // na for header
  sourceCat: '',
};

export default RecordRow;
