import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import api from 'api';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { requiredError } from 'utils/errorHelpers';
import useCancellablePromise from 'hooks/useCancellablePromise';
import Loading from 'components/loading';
import { TextField } from '../../CertificationForm/forms/components';


export const validationSchema = Yup.object().shape({
  subject: Yup.string()
    .trim()
    .required(requiredError),
  body: Yup.string()
    .trim()
    .required(requiredError),
});


const getEmail = (id, submissionId) => api.get(`attestations/certifications/${id}/attestations/${submissionId}/email`);

const sendEmail = (id, submissionId, email) => api.post(`attestations/certifications/${id}/attestations/${submissionId}/resend_mail`, email);


const ResendCertificationEmail = ({ id, submissionId, closeForm }) => {
  const [email, setEmail] = useState({});
  const [loading, setLoading] = useState(true);
  const { cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    let didCancel = false;
    getEmail(id, submissionId).then(({ data }) => {
      if (!didCancel) {
        setEmail(data);
        setLoading(false);
      }
    })
      .catch((err) => {
        if (!didCancel) {
          setLoading(false);
          toast('Something went wrong.');
          throw err;
        }
      });
    return () => {
      didCancel = true;
    };
  }, []);

  const onSubmit = (values, actions) => {
    cancellablePromise(sendEmail(id, submissionId, values)).then(() => {
      toast('Certification resent!');
      actions.setSubmitting(false);
      closeForm();
    }).catch((err) => {
      if (!err.isCanceled) {
        toast('Something went wrong and we were unable to resend this certification.');
        actions.setSubmitting(false);
      }
    });
  };

  return (
    <div className="form-modal">
      {
        loading ?
          <Loading />
          :
          <div>
            <h2>Resend Certification Email</h2>
            <div className="message">
              <Formik
                initialValues={Object.assign({}, email)}
                onSubmit={onSubmit}
                validateOnChange={false}
                validationSchema={validationSchema}
              >
                {
                  ({ isSubmitting }) => (
                    <Form
                      autoComplete="off"
                      noValidate
                      className="create-cert-form"
                    >
                      <TextField
                        name={'to'}
                        label={'to'}
                        disabled
                      />
                      <TextField
                        name={'subject'}
                        label={'Subject'}
                        required
                      />
                      <TextField
                        name={'body'}
                        label={'Body'}
                        inputType="textarea"
                        required
                      />
                      <div className="button-wrapper">
                        <button
                          type="button"
                          className="uppercase text-button"
                          onClick={closeForm}
                        >
                          Cancel
                        </button>
                        <button
                          className="orange-button"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Send
                        </button>
                      </div>
                    </Form>
                  )
                }
              </Formik>
            </div>
          </div>
      }
    </div>
  );
};

ResendCertificationEmail.propTypes = {
  id: PropTypes.string.isRequired,
  submissionId: PropTypes.number.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default ResendCertificationEmail;
