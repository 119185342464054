import React from 'react';
import PropTypes from 'prop-types';
import {
  DATE,
  TEXT,
  DECIMAL,
} from '../constants';

export const [MENU, EDIT, DELETE, FILTER] = ['menu', 'edit', 'delete', 'filter'];

const DEFAULT = 'DEFAULT';

const sortTextMap = {
  [DATE]: ['sort low to high', 'sort high to low'],
  [DECIMAL]: ['sort low to high', 'sort high to low'],
  [TEXT]: ['sort a to z', 'sort z to a'],
  [DEFAULT]: ['sort a to z', 'sort z to a'],
};


const MenuItem = ({
  onClick,
  disabled,
  icon,
  label,
}) => (
  <button
    type="button"
    onClick={onClick}
    className="column-menu-item"
    disabled={disabled}
  >
    <i className="material-icons">
      { icon }
    </i>
    <span>
      { label }
    </span>
  </button>
);

MenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

MenuItem.defaultProps = {
  disabled: false,
};


const Menu = ({
  column,
  setMenuType,
  onSort,
  disableDropdown,
  canEdit,
}) => {
  const { internal, id, type } = column;
  const sortText = sortTextMap[type] || sortTextMap[DEFAULT];

  return (
    <div role="menu">
      {
        (!internal && canEdit) && (
          <MenuItem
            onClick={() => setMenuType(EDIT)}
            icon="edit"
            label="edit"
          />
        )
      }
      <MenuItem
        onClick={() => setMenuType(FILTER)}
        icon="filter_alt"
        label="filter"
        disabled={disableDropdown}
      />
      <MenuItem
        onClick={() => onSort(id, '')}
        icon="arrow_upward"
        label={sortText[0]}
        disabled={disableDropdown}
      />
      <MenuItem
        onClick={() => onSort(id, '-')}
        icon="arrow_downward"
        label={sortText[1]}
        disabled={disableDropdown}
      />
      {
          (!internal && canEdit) && (
            <MenuItem
              onClick={() => setMenuType(DELETE)}
              icon="delete"
              label="delete"
            />
          )
        }
    </div>
  );
};

Menu.propTypes = {
  column: PropTypes.object.isRequired,
  setMenuType: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  disableDropdown: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default Menu;
