import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { getSearch } from 'reducers/searchObjects/actions';
import { updateEntityAlert } from 'reducers/entities/actions';
import { updateEmployeeAlert } from 'reducers/employees/actions';
import Loading from 'components/loading';
import { EMPLOYEES, ENTITIES } from 'utils/constants';
import { ErrorComponent } from 'components/errorBoundary';
import Accordion, { AccordionToggle } from 'components/shared/accordion';
import SearchObjectView from './searchObjectViewContainer';
import ResultsHeader, { HeaderSubRowContainer } from '../shared/searchObjectHeader/monitoringHeaderContainer';
import RecordGroup from './recordGroupContainer';
import Subcategory from './sourceSubcategoryContainer';
import SliderButton from '../shared/SliderButton';


function MonitoringSearchObjectView({
  searchType,
  personType,
  searchObjId,
  fetchSearch,
  linkToEdit,
  shouldDefaultOpen,
  alertsActive,
  toggleEntityAlert,
  toggleEmployeeAlert,
  isArchived,
  id,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    let didCancel = false;
    fetchSearch(searchObjId)
      .then(() => {
      }).catch((err) => {
        if (!didCancel) {
          setError(true);
          toast('There was a problem getting the details for this search.');
          throw err;
        }
      }).finally(() => {
        if (!didCancel) {
          setLoading(false);
        }
      });
    return () => {
      didCancel = true;
    };
  }, [fetchSearch, searchObjId]);

  const toggleAlert = searchType === ENTITIES ? toggleEntityAlert : toggleEmployeeAlert;

  return (
    <>
      {
        loading
          ? (
            <div className="result-section-container">
              <div className="results-container">
                <div className="result-loader-container">
                  <Loading />
                </div>
              </div>
            </div>
          )
          : (
            <>
              { error
                ? (
                  <ErrorComponent
                    apologyText=""
                    className="error-bounds noResults"
                    errorBody="We were unable to get the details for this search."
                  />
                )
                : (
                  <Accordion
                    defaultExpanded={shouldDefaultOpen}
                    className="search-toggle-item"
                    canToggle={searchType !== ENTITIES}
                  >
                    <AccordionToggle
                      expandIcon="add"
                      collapseIcon="remove"
                      className="results-header"
                    >
                      <ResultsHeader
                        personType={personType}
                        editButton={(
                          <Link
                            to={`${linkToEdit}`}
                          >
                            <div className="text-button uppercase">
                              <i className="material-icons">edit</i>
                              edit
                              {' '}
                              {searchType === EMPLOYEES ? 'filters' : ''}
                            </div>
                          </Link>
                        )}
                        alertButton={(
                          <SliderButton
                            sliderText="Alerts"
                            active={alertsActive}
                            onToggle={(active) => (toggleAlert(id, searchObjId, active))}
                            disabled={isArchived}
                            tooltip={isArchived ? 'Alerts cannot be enabled for archived people.' : null}
                          />
                        )}
                        searchId={searchObjId}
                        searchType={searchType}
                      />
                    </AccordionToggle>
                    <SearchObjectView
                      showContribSchema
                      subcategoryComponent={Subcategory}
                      recordGroupComponent={RecordGroup}
                      searchId={searchObjId}
                      searchType={searchType}
                      shouldDefaultOpen={shouldDefaultOpen}
                    >
                      <HeaderSubRowContainer
                        searchId={searchObjId}
                      />
                    </SearchObjectView>
                  </Accordion>
                )}
            </>
          )
      }
    </>
  );
}


MonitoringSearchObjectView.propTypes = {
  searchType: PropTypes.string.isRequired,
  fetchSearch: PropTypes.func.isRequired,
  searchObjId: PropTypes.number.isRequired,
  linkToEdit: PropTypes.string.isRequired,
  personType: PropTypes.string,
  shouldDefaultOpen: PropTypes.bool,
  alertsActive: PropTypes.bool.isRequired,
  toggleEntityAlert: PropTypes.func.isRequired,
  toggleEmployeeAlert: PropTypes.func.isRequired,
  isArchived: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

MonitoringSearchObjectView.defaultProps = {
  personType: '',
  shouldDefaultOpen: true,
  isArchived: false,
};


export default connect(null, {
  fetchSearch: getSearch,
  toggleEntityAlert: updateEntityAlert,
  toggleEmployeeAlert: updateEmployeeAlert,
})(MonitoringSearchObjectView);
