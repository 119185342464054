import { combineReducers } from 'redux';
import emailTemplates from './emailTemplates/emailTemplatesReducer';
import macros from './macros/macrosReducer';
import forms from './forms/formsReducer';

export default combineReducers({
  emailTemplates,
  macros,
  forms,
});
