import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { byId } from 'reducers/employees/selectors';
import {
  createEmployee, updateEmployee, restoreEmployee,
} from 'reducers/employees/actions';
import { openModal } from 'reducers/ui/modal/actions';
import { ARCHIVE_EMPLOYEE, DELETE_EMPLOYEE } from 'utils/constants';
import EmployeeForm from './employeeForm';
import {
  firstName,
  lastName,
  altFirsts,
  altLasts,
  email,
  altEmails,
  externalId,
  primaryAddress,
  addresses,
  initAddressFields,
} from './consts';


function buildAddressObj(addressData) {
  return Object.keys(initAddressFields).reduce((acc, key) => {
    acc[key] = (addressData && addressData[key]) || '';
    return acc;
  }, {});
}

const editMapStateToProps = (state, { match }) => {
  const employee = byId(state, match.params.id);
  const customFields = Object.values(state.customFields);
  const initCustomData = customFields.reduce((acc, field) => {
    acc[field.label] = employee.custom_data[field.label] || '';
    return acc;
  }, {});

  const employeeCopy = cloneDeep(employee);
  const initPrimaryAddress = buildAddressObj(employeeCopy[primaryAddress]);

  const addressesCopy = employeeCopy[addresses].filter(address => !address.is_primary)
    .map(address => buildAddressObj(address));

  const initForm = {
    [email]: employeeCopy[email] || '',
    [externalId]: employeeCopy[externalId] || '',
    [addresses]: addressesCopy,
    [primaryAddress]: initPrimaryAddress,
    custom_data: initCustomData,
  };
  ['id', firstName, lastName, altFirsts, altLasts, altEmails, 'archived'].forEach((field) => {
    initForm[field] = employeeCopy[field];
  });
  return {
    customFields,
    initForm,
    edit: true,
    employee,
  };
};

export const EditEmployeeForm = withRouter(connect(editMapStateToProps, {
  submitFunc: updateEmployee,
  toggleDeleteModal: () => openModal({ modalType: DELETE_EMPLOYEE }),
  openArchiveModal: () => openModal({ modalType: ARCHIVE_EMPLOYEE }),
  restoreEmployee,
})(EmployeeForm));


const mapStateToProps = (state) => {
  const customFields = Object.values(state.customFields);
  const initCustomData = customFields.reduce((acc, field) => {
    acc[field.label] = '';
    return acc;
  }, {});

  const initForm = {
    [firstName]: '',
    [lastName]: '',
    [altFirsts]: [],
    [altLasts]: [],
    [email]: '',
    [altEmails]: [],
    [primaryAddress]: Object.assign({}, initAddressFields),
    [addresses]: [],
    [externalId]: '',
    custom_data: initCustomData,
    archived: false,
  };

  return {
    customFields,
    initForm,
    edit: false,
  };
};

export default connect(mapStateToProps, {
  submitFunc: createEmployee,
})(EmployeeForm);
