import * as types from '../types';

const defaultState = {
  modalType: '',
  selectedRow: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.OPEN_PRE_MODAL:
      return {
        ...state,
        modalType: action.modalType,
      };
    case types.CLOSE_PRE_MODAL:
      return {
        modalType: '',
        selectedRow: {},
      };
    case types.SELECT_ROW:
      return {
        ...state,
        selectedRow: action.selectedRow,
      };
    default:
      return state;
  }
};
