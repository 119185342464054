import React from 'react';
import PropTypes from 'prop-types';

export default function ProgressBar({ style, percentage, width }) {
  const combinedStyle = { ...style };
  if (width) {
    combinedStyle.width = width;
  }
  return (
    <div className="progress-container" style={combinedStyle}>
      <div className="progress-bar" style={{ width: `${percentage}%` }} />
    </div>
  );
}

ProgressBar.propTypes = {
  style: PropTypes.object,
  percentage: PropTypes.number,
  width: PropTypes.number,
};

ProgressBar.defaultProps = {
  style: {},
  percentage: 0,
  width: 0,
};
