/* eslint-disable camelcase */
import { cloneDeep, uniq } from 'lodash';
import { getSourceIds } from 'reducers/sources/selectors';
import { byId } from 'reducers/searchObjects/selectors';
import { SELF, getSelfSearch } from 'components/employeePage/consts';
import { createEmployeeSearch, updateEmployeeSearch } from 'reducers/employees/actions';
import { getSearch } from 'reducers/searchObjects/actions';


// builds a search based on an employee
export function buildSearch(employee, sources) {
  const sourceIds = getSourceIds(sources);
  const {
    first_name: firstName,
    last_name: lastName,
    alt_firsts: altFirsts,
    alt_lasts: altLasts,
    addresses,
  } = employee;

  const states = (addresses || []).reduce((acc, address) => {
    if (address.state) {
      acc.push(address.state);
    }
    return acc;
  }, []);

  return {
    search_type: SELF,
    sources: [...sourceIds],
    all_sources: true,
    first_name: firstName,
    last_name: lastName,
    alt_firsts: [...altFirsts],
    alt_lasts: [...altLasts],
    states: uniq(states),
  };
}


// builds an updated search with name based on employee
function buildUpdatedSearch(search, { firstName, lastName }) {
  const searchCopy = cloneDeep(search);
  return {
    ...searchCopy,
    first_name: firstName,
    last_name: lastName,
    id: search.id,
    search_type: SELF,
  };
}


export const shouldRunSearch = (prevEmployee, employee) => {
  const { first_name, last_name } = employee;
  const [firstName, lastName, prevFirst, prevLast] =
  [first_name, last_name, prevEmployee.first_name, prevEmployee.last_name]
    .map((name) => name.toLowerCase());
  const nameChanged = prevFirst !== firstName || prevLast !== lastName;
  if (nameChanged) {
    return true;
  }
  return false;
};


export const runEmployeeSearch = (employee) => (dispatch, getState) => {
  const state = getState();
  const selfSearch = getSelfSearch(employee.search_objects);

  const { first_name: firstName, last_name: lastName, id } = employee;

  if (selfSearch) {
    const empData = {
      firstName,
      lastName,
    };
    const searchId = selfSearch.search_object_id;
    const search = byId(state, searchId);
    // search may be in state but not have the full search settings
    if (search && search.sources) {
      const searchInfo = buildUpdatedSearch(search, empData);
      return dispatch(updateEmployeeSearch(id, searchInfo));
    }
    return dispatch(getSearch(searchId)).then((data) => {
      const searchInfo = buildUpdatedSearch(data, empData);
      return dispatch(updateEmployeeSearch(id, searchInfo));
    });
  }
  const { sources } = state.sources;
  return dispatch(createEmployeeSearch(id, buildSearch(employee, sources)));
};
