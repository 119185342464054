import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import api from 'api';
import { PATHS, PATH_NAMES } from 'utils/constants';
import useCancellablePromise from 'hooks/useCancellablePromise';
import FormNameForm from './FormNameForm';


const createForm = (form) => api.post('/forms/form/', form);
const CreateInitForm = ({ onClose }) => {
  const { cancellablePromise } = useCancellablePromise();
  const history = useHistory();

  const handleSubmit = (values, actions) => {
    cancellablePromise(createForm(values)).then(({ data }) => {
      actions.setSubmitting(false);
      history.push(`${PATHS[PATH_NAMES.forms]}/${data.id}/edit`);
    }).catch((err) => {
      let error = '';
      if (err.response) {
        const { status } = err.response;
        if (status === 409) {
          error = 'A form with this name already exists.';
        }
      }
      if (!err.isCanceled) {
        // eslint-disable-next-line no-unused-expressions
        error && actions.setErrors({ name: error });
        toast('Something went wrong.');
        actions.setSubmitting(false);
      }
    });
  };

  return (
    <FormNameForm
      handleSubmit={handleSubmit}
      initialValues={{ name: '' }}
      formHeading="Create a new form"
      helpText="Start by giving your form a name."
      onClose={onClose}
    />
  );
};

CreateInitForm.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreateInitForm;
