import * as types from "../types";

const initState = {};

export default (state = initState, action) => {
  const nextState = { ...state };
  switch (action.type) {
    case types.ADD_UPLOAD: {
      const appendableObj = {
        name: action.name,
        progress: 0,
        loading: true,
      }
      nextState[action.rowId] = [...nextState[action.rowId], appendableObj]
      return nextState;
    }
    case types.REMOVE_UPLOAD: {
      const fileList = nextState[action.rowId];
      nextState[action.rowId] = fileList.filter(file => file.name !== action.fileName)
      return nextState;
    }
    case types.UPDATE_PROGRESS: {
      const fileList = nextState[action.rowId] || [];
      const matchingFileIndex = fileList.findIndex(({ name }) => name === action.fileName);
      if (matchingFileIndex < 0) {
        return nextState;
      }
      nextState[action.rowId] = fileList.slice();
      ['progress', 'hasError', 'loading'].forEach(field => {
        nextState[action.rowId][matchingFileIndex][field] = action[field];
      });
      return nextState;
    }
    case types.RECEIVE_ROW: {
      return {
        ...state,
        [action.row.id]: action.row.files.map(file => ({ name: file, hasError: false, progress: 100 })),
      };
    }
    case types.RECEIVE_ROWS: {
      return action.rows.reduce((newState, row) => {
        newState[row.id] = row.files.map(file => ({ name: file, hasError: false, progress: 100 }));
        return newState;
      }, {});
    }
    default: {
      return state;
    }
  }
}
