import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import { SingleDatePicker } from 'react-dates';
import { dateFormat as defaultDateFormat } from 'components/Certifications/constants';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

const DateField = ({
  label,
  dateFormat,
  isOutsideRange,
  placeholder,
  helpText,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const handleDateChange = (date) => {
    if (date) {
      helpers.setValue(date.format(dateFormat));
    } else {
      helpers.setValue(null);
    }
  };

  return (
    <div className="form-field">
      <label>{label}</label>
      <SingleDatePicker
        date={field.value ? moment(field.value) : null}
        onDateChange={handleDateChange}
        focused={isFocused}
        onFocusChange={({ focused }) => setIsFocused(focused)}
        id={field.name}
        hideKeyboardShortcutsPanel
        isOutsideRange={isOutsideRange}
        placeholder={placeholder}
        block
        noBorder
      />
      { helpText &&
        <div className="help-text">
          { helpText }
        </div>
      }
      <ErrorMessage
        name={field.name}
        component="div"
        className="error-text"
      />
    </div>
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  isOutsideRange: PropTypes.func,
  helpText: PropTypes.string,
};

DateField.defaultProps = {
  dateFormat: defaultDateFormat,
  placeholder: '',
  isOutsideRange: () => false,
  helpText: '',
};

export { DateField };
