import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Column } from './Column';
import { Row } from './Row';
import Loading from '../../loading';
import numeral from 'numeral';
import moment from 'moment';

const typeRenderers = {
  default: value => value,
  date: value => moment(value).format('MM/DD/YYYY'),
  currency: value => numeral(value).format('$0,0'),
  number: value => numeral(value).format('$0,0.00'),
};

const ColumnType = PropTypes.shape({
  type: PropTypes.oneOf([Column]),
});

const Table = ({ children, data, isLoading, style }) => {
  const [renderers, setRenderers] = useState({});
  const dataKeys = children.map(child => child.props.dataKey);

  useEffect(() => {
    const newRenderers = {};
    children.forEach(child => {
      newRenderers[child.props.dataKey] =
        child.props.renderer || typeRenderers[child.props.type];
    });
    setRenderers(newRenderers);
  }, [children]);

  if (isLoading) {
    return (
      <div className="load-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="SimpleTable simple-table-wrapper">
      <table style={style}>
        <thead>
          <tr>{children}</tr>
        </thead>
        <tbody>
          {data.map((rowData, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={i} dataKeys={dataKeys} renderers={renderers}>
              {rowData}
            </Row>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  children: PropTypes.arrayOf(ColumnType).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  style: PropTypes.object,
};

Table.defaultProps = {
  isLoading: false,
  style: {},
};

export { Table };
