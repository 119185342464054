import * as types from './types';

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.RECEIVE_COLUMNS:
    case types.RECEIVE_INITIAL_DATA:
      return action.columns;
    case types.RECEIVE_COLUMN:
      // todo
    case types.REMOVE_COLUMN:
      // todo
    default:
      return state;
  }
};