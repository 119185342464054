import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SettingsPage from 'components/shared/settingsPage/settingsPage';
import Enable from './enable';
import BackupTokens from './backups';
import Disable from './disable';


function TwoFactor({ twoFactorEnabled }) {
  const sections = twoFactorEnabled ? [BackupTokens, Disable] : [Enable];
  return (
    <SettingsPage
      header={'Two-Factor Authentication'}
      sections={sections}
    />
  );
}

TwoFactor.propTypes = {
  twoFactorEnabled: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ user: { two_factor_enabled } }) => ({
  twoFactorEnabled: two_factor_enabled,
});

export default connect(mapStateToProps)(TwoFactor);
