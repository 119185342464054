import React from 'react';
import PropTypes from 'prop-types';
import ColumnForm from './ColumnForm';


const AddColumn = ({
  createColumn,
  onCancel,
}) => (
  <div className="preclearance-popover add-column-form">
    <ColumnForm
      label=""
      onSubmit={createColumn}
      onCancel={onCancel}
      headerText="Add Field"
    />
  </div>
);

AddColumn.propTypes = {
  createColumn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddColumn;
