/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import { formatAmount } from '../../../helpers/formatFieldHelpers';
import { CUSTOMIZE_EMAIL_MODAL } from '../table/constants';
import {
  to,
  validationSchema,
} from './consts';


const ActionConfirmation = ({
  rowData,
  initFormData,
  status,
  templateName,
  closeModal,
  openModal,
  onSubmit,
}) => {
  const {
    first_name,
    last_name,
    amount,
    recipient,
    email,
  } = rowData;

  const fullName = `${first_name} ${last_name}`;
  const recordString = `${fullName}'s ${formatAmount(amount)} contribution to ${recipient} has been marked as "${status}".`;
  const message = email
    ? (
      <div>
        Would you like to send email template named
        {' '}
        <strong>{`"${templateName}"`}</strong>
        {' '}
        to
        {' '}
        <strong>{email}</strong>
        ?
      </div>
    )
    : (
      <div>
        Please enter
        {' '}
        <strong>{`${fullName}'s`}</strong>
        {' '}
        email address if you would like to send email template named
        {' '}
        <strong>{`"${templateName}"`}</strong>
        .
      </div>
    );

  return (
    <div>
      <h2>Send Email</h2>
      <Formik
        initialValues={({ ...initFormData })}
        onSubmit={onSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off" noValidate>
            <div className="message">
              <div>
                {recordString}
              </div>
              <div>
                {message}
              </div>
              <div>
                {!email
                  ? (
                    <SchemaField
                      name={to}
                      id={to}
                      label="to"
                      fieldType="TextField"
                      className="form-input"
                      required
                    />
                  ) : ''}
              </div>
            </div>
            <div className="modal-button-wrapper">
              <button
                onClick={closeModal}
                className="icon text-button uppercase"
                type="button"
                disabled={isSubmitting}
              >
                <i className="material-icons">
                  not_interested
                </i>
                Don't Send
              </button>
              <div className="right-buttons">
                <button
                  onClick={() => { openModal({ modalType: CUSTOMIZE_EMAIL_MODAL }); }}
                  className="icon text-button uppercase"
                  type="button"
                  disabled={isSubmitting}
                >
                  <i className="material-icons">
                    edit
                  </i>
                  Customize
                </button>
                <button
                  className="orange-button"
                  disabled={isSubmitting}
                  type="submit"
                >
                  Send
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};


export default ActionConfirmation;

ActionConfirmation.propTypes = {
  rowData: PropTypes.object.isRequired,
  initFormData: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
