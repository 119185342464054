import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';


const EmailRecordButton = ({ openEmailDialog, disabled, tooltip }) => (
  <Tippy
    content={tooltip}
    disabled={!tooltip}
  >
    <div>
      <button
        type="button"
        className="text-button send-button"
        onClick={openEmailDialog}
        disabled={disabled}
      >
        <i className="icon-send" />
      </button>
    </div>
  </Tippy>
);

EmailRecordButton.propTypes = {
  openEmailDialog: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

EmailRecordButton.defaultProps = {
  disabled: false,
  tooltip: '',
};

export default EmailRecordButton;
