import { connect } from 'react-redux';
import moment from 'moment';
import { getPreclearanceMatches } from 'utils/apiUtil';
import { closeModal } from 'reducers/ui/actions';
import { STATUS_SCHEMA, JUR_SCHEMA } from 'utils/constants';
import { makeMatch, markAsNoMatch, undoNoMatch } from 'reducers/matches/actions';
import MatchingWrapper from './matchingWrapper';
import { MONITORING } from './consts';

const mapStateToProps = (state) => {
  const { recordId } = state.ui.modal.modalProps;
  const record = state.contributions.byId[recordId];
  const recordStatus = state.statuses[record.meta.contribution_status || record.meta.status];
  const recordSchema = state.sources.sources.find(source => source.id === record.sourceId).person_schema;
  const { date, contributor } = record.data;
  const { matches, automatches, no_match: noMatch } = record.meta;

  return ({
    serializedRecord: {
      ...record.data,
      contribution_status: recordStatus.label,
      jurisdiction: record.meta.jurisdiction || '',
    },
    recordSchema: [...recordSchema, JUR_SCHEMA, STATUS_SCHEMA],
    recordToBeMatched: record,
    defaultNameFilter: contributor,
    defaultYearFilter: date ? moment(date, 'YYYY-MM-DD').format('YYYY') : '',
    matches: [...matches],
    automatches: state.user.show_automatches && automatches ? [...automatches] : [],
    noMatch,
    getPotentialMatches: getPreclearanceMatches,
    viewType: MONITORING,
    automatchId: record.meta.id,
  });
};
export default connect(
  mapStateToProps,
  {
    makeMatch,
    markAsNoMatch,
    undoNoMatch,
    closeModal,
  },
)(MatchingWrapper);
