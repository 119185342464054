import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrgUsers } from 'reducers/organization/actions';
import SettingsPage from 'components/shared/settingsPage/settingsPage';
import Loading from 'components/loading';
import ManageUsers from './manageUsers';
import { OrgPasswordPolicy } from '../accountSettings/passwordPolicy';
import { hasManagePasswordPermissions } from '../../../helpers/permissions';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';


class OrganizationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }
  componentDidMount() {
    this.props.getOrgUsers().then(() => this.setState({ loading: false }));
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }

    const sections = [ManageUsers];
    this.props.canManagePassword && sections.push(OrgPasswordPolicy);

    return (
      <div className="siteContent account-settings">
        <ScrollToTopOnMount />
        <div className="container organization-page">
          <div className="panel">
            <SettingsPage sections={sections} />
          </div>
        </div>
      </div>
    );
  }
}

OrganizationSettings.propTypes = {
  getOrgUsers: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user }) => ({
  canManagePassword: hasManagePasswordPermissions(user),
});

export default connect(mapStateToProps, { getOrgUsers })(OrganizationSettings);
