import React from 'react';

import SettingsPage from '../../../shared/settingsPage/settingsPage';
import NotificationEmailsSection from './sections/notificationEmails';
import FromEmailSection from './sections/fromEmail';

const sections = [FromEmailSection, NotificationEmailsSection];

export default function Notifications() {
  return <SettingsPage sections={sections} />;
}
