import api from 'api';
import * as types from '../types';

const loadMatchActions = data => ({
  type: types.LOAD_MATCH_ACTIONS,
  data,
});

const addMatchAction = data => ({
  type: types.ADD_MATCH_ACTION,
  data,
});

const updateMatchAction = data => ({
  type: types.UPDATE_MATCH_ACTION,
  data,
});

const deleteMatchAction = id => ({
  type: types.DELETE_MATCH_ACTION,
  id,
});

export const fetchMatchActions = () => dispatch =>
  api.get('/pre/match_actions/')
    .then(({ data }) => {
      dispatch(loadMatchActions(data));
      return data;
    });

export const createMatchAction = newAction => dispatch =>
  api.post('/pre/match_actions/', newAction).then(({ data }) => {
    dispatch(addMatchAction(data));
    return data;
  });

export const editMatchAction = updatedAction => dispatch =>
  api.put(`/pre/match_actions/${updatedAction.id}/`, updatedAction).then(({ data }) => {
    dispatch(updateMatchAction(data));
    return data;
  });

export const removeMatchAction = id => dispatch =>
  api.delete(`/pre/match_actions/${id}/`).then(({ data }) => {
    dispatch(deleteMatchAction(id));
    return data;
  });
