import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SCROLLABLE_CONTAINER } from 'utils/constants';
import { CustomDropdown } from 'components/dropdown';
import { COMMENT } from './actionLog';
import Note from './notes';

const CommentButton = ({ count, hasFiles, clickHandler }) => {
  const noteIcon = count ? (
    hasFiles ? 'icon-note_attachment' : 'icon-note_text'
  ) : (
    hasFiles ? 'attach_file' : 'icon-note_add');

  return (
    <button
      className="has-note note-button wrapper text-button uppercase"
      onClick={clickHandler}
    >
      {
        noteIcon === 'attach_file' ? (
          <i className={`note-button material-icons ${noteIcon}`}>
            { noteIcon }
          </i>
        ) : (
          <i className={`${noteIcon} note-button`} />
        )
      }
      {count ? <span className="badge">{count}</span> : ''}
    </button>
  );
};

CommentButton.propTypes = {
  count: PropTypes.number,
  hasFiles: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  clickHandler: PropTypes.func.isRequired,
};

CommentButton.defaultProps = {
  count: 0,
  hasFiles: false,
};


const CommentCell = ({ id, files, fileCount, notes, firstSeen, createComment }) => {
  const [rectangle, setRectangle] = useState({});

  return (
    <CustomDropdown
      render={({
        isOpen,
        openDropdown,
        closeDropdown,
        dropdownRef,
      }) => (
        <div>
          <CommentButton
            count={notes.filter(note => note.action[COMMENT]).length}
            hasFiles={fileCount}
            clickHandler={(e) => {
              const rect = e.target.parentElement.getBoundingClientRect();
              const windowLoc = SCROLLABLE_CONTAINER instanceof Window
                ? (window.scrollY || window.pageYOffset) : SCROLLABLE_CONTAINER.scrollTop;
              setRectangle({
                left: rect.left,
                top: rect.top + windowLoc,
                height: rect.height,
                width: rect.width,
              });
              return isOpen ? closeDropdown(e) : openDropdown(e);
            }}
          />
          {
            isOpen && (
              <Note
                noteRef={dropdownRef}
                createComment={createComment}
                files={files || []}
                fileCount={fileCount}
                contributionId={id}
                notes={notes}
                firstSeen={firstSeen}
                position={rectangle}
              />
            )
          }
        </div>
      )}
    />
  );
};

CommentCell.propTypes = {
  id: PropTypes.number.isRequired,
  files: PropTypes.array,
  fileCount: PropTypes.number.isRequired,
  notes: PropTypes.array,
  createComment: PropTypes.func.isRequired,
  firstSeen: PropTypes.string,
};

CommentCell.defaultProps = {
  files: [],
  notes: [],
  firstSeen: null,
};

export default CommentCell;
