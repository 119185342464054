import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Tippy from '@tippyjs/react';
import { stripHTML } from 'helpers/helpers';


const ConditionalHeading = ({ dependencies }) => {
  const [fields] = useField('fields');
  const tooltip = (
    <div className="conditional-field-tooltip">
      This field will show when
      {' '}
      {
        dependencies.map((item, index) => {
          const dependentField = fields.value[item.prerequisite_field];
          const label = stripHTML(dependentField.label);
          const choices = dependentField.choices.filter((choice) => (
            item.prerequisite_values.includes(choice.value)));
          return (
            <span key={index}>
              {/* assuming we join by 'or' for now */}
              { index ? ' or ' : '' }
              <strong>
                {label}
              </strong>
              {' '}
              is
              {' '}
              { choices.map((choice, idx) => (
                <span key={`choice-${choice.value}`}>
                  {/* assuming we join by 'or' for now */}
                  { idx ? ' or ' : '' }
                  <strong>
                    {choice.label}
                  </strong>
                </span>
              ))}
            </span>
          );
        })
      }
    </div>
  );
  return (
    <div className="conditional-notice">
      <Tippy
        content={tooltip}
      >
        <i className="material-icons">
          info
        </i>
      </Tippy>
      Conditional field
    </div>
  );
};

ConditionalHeading.propTypes = {
  dependencies: PropTypes.array.isRequired,
};

export default ConditionalHeading;
