import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PATHS, PATH_NAMES } from 'utils/constants';
import { getSwitchToPlatforms, getDiscoverPlatforms } from 'reducers/platforms/selectors';
import FilterSection from '../filtersDropdown/filterSection';
import PlatformLink from './PlatformLink';
import PlatformLoginLink from './PlatformLoginLink';


const AppSwitcherMenu = ({
  switchToPlatforms,
  discoverPlatforms,
}) => (
  <div className="dropdown-container">
    {
      switchToPlatforms.length ? (
        <FilterSection filterTitle="Switch to">
          {
          switchToPlatforms.map(({
            platformID,
            platformName,
            platformIconUrl,
          }) => (
            <PlatformLoginLink
              key={platformID}
              name={platformName}
              iconUrl={platformIconUrl}
              url={`${PATHS[PATH_NAMES.platform_access]}/${platformID}`}
            />
          ))
        }
        </FilterSection>
      ) : null
    }
    {
      discoverPlatforms.length ? (
        <FilterSection filterTitle="Discover">
          {
          discoverPlatforms.map(({
            platformID,
            brandName,
            marketingText,
            platformIconUrl,
            marketingUrl,
          }) => (
            <PlatformLink
              key={platformID}
              name={brandName}
              description={marketingText}
              iconUrl={platformIconUrl}
              url={marketingUrl}
            />
          ))
        }
        </FilterSection>
      ) : null
    }

  </div>
);

AppSwitcherMenu.propTypes = {
  switchToPlatforms: PropTypes.array.isRequired,
  discoverPlatforms: PropTypes.array.isRequired,
};


const mapStateToProps = (state) => ({
  switchToPlatforms: getSwitchToPlatforms(state),
  discoverPlatforms: getDiscoverPlatforms(state),
});

export default connect(
  mapStateToProps,
  {},
)(AppSwitcherMenu);
