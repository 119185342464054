import { batchActions } from 'redux-batched-actions';
import { RequestTrackerCanceler } from 'utils/apiUtil';
import { serializeResearchSearch } from 'utils/serializers/researchSearchSerializer';
import { serializeDate } from 'utils/serializers/dateSerializer';
import api from 'api';
import * as types from './types';
import { setRemainingResearches } from '../user/actions';


const setSearch = searchInfo => ({
  type: types.SET_SEARCH,
  searchInfo,
});

const setDateFilter = dateFilter => ({
  type: types.SET_DATE_FILTER,
  dateFilter,
});

const toggleLoading = loading => ({
  loading,
  type: types.TOGGLE_LOADING,
});

const receiveResults = data => ({
  type: types.RECEIVE_RESEARCH_RESULTS,
  data,
});

const receiveSourceResults = (data, sourceId) => ({
  type: types.RECEIVE_SOURCE_RESULTS,
  data,
  sourceId,
});

const clearResults = () => ({
  type: types.CLEAR_RESULTS,
});

export const toggleEditing = edit => ({
  type: types.TOGGLE_EDITING,
  edit,
});

export const resetState = () => ({
  type: types.RESET_STATE,
});


export const createSearch = searchInfo => (dispatch) => {
  RequestTrackerCanceler.cancelAllRequests();
  dispatch(clearResults());
  return api.post('/research/search/', serializeResearchSearch(searchInfo))
    .then(({ data: { search_info, remaining_researches } }) => {
      const { from, to } = searchInfo;
      const fromDate = from ? serializeDate(from) : null;
      const toDate = to ? serializeDate(to) : null;
      dispatch(batchActions([
        setDateFilter({ from: fromDate, to: toDate }),
        setSearch(search_info),
        setRemainingResearches(remaining_researches),
        toggleLoading(false),
      ]));
      return search_info;
    });
};

export const fetchResults = id => (dispatch, getState) => {
  const { research } = getState();
  const { dateFilter } = research.ui;

  const queryString = Object.keys(dateFilter).filter(k => !!dateFilter[k]).map(k => `${k}=${dateFilter[k]}`).join('&');
  const url = `/research/${id}/results/?${queryString}`;
  return api.get(url, {
    cancelToken: RequestTrackerCanceler.getCancelToken(id),
  })
    .then(({ data }) => {
      if (data.length > 0) {
        dispatch(receiveResults(data));
      }
      return data;
    });
};

export const fetchResult = (id, resultId) => (dispatch, getState) => {
  const { research } = getState();
  const { dateFilter } = research.ui;

  const args = { ...dateFilter };
  args.id = resultId;

  const queryString = Object.keys(args).filter(k => !!args[k]).map(k => `${k}=${args[k]}`).join('&');
  const url = `/research/${id}/results/?${queryString}`;

  return api.get(url, {
    cancelToken: RequestTrackerCanceler.getCancelToken(id),
  }).then(({ data }) => {
    if (data.completed) {
      dispatch(receiveSourceResults(data));
    }
    return data;
  });
};
