import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllPlatforms } from 'reducers/platforms/selectors';
import { CustomDropdown } from '../dropdown';
import AppSwitcherIcon from '../svgs/appSwitcherIcon';
import AppSwitcherMenu from './AppSwitcherMenu';


const AppSwitcher = ({ shouldRender }) => (
  shouldRender
    ? (
      <CustomDropdown
        render={({
          isOpen,
          openDropdown,
          closeDropdown,
        }) => (
          <div className="app-switcher">
            <button
              onClick={isOpen ? closeDropdown : openDropdown}
              type="button"
              className="text-button app-switcher-button"
            >
              <AppSwitcherIcon />
            </button>
            {isOpen
          && (
          <AppSwitcherMenu />
          )}
          </div>
        )}
      />
    )
    : null
);

AppSwitcher.propTypes = {
  shouldRender: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => {
  const platforms = getAllPlatforms(state);
  return {
    shouldRender: Boolean(platforms.length),
  };
};

export default connect(
  mapStateToProps,
  {},
)(AppSwitcher);

