import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PATHS, PATH_NAMES } from 'utils/constants';
import ModalUI from 'components/modal/modalUI';
import CreateInitForm from 'components/FormBuilder/NameForm/CreateInitForm';
import CertificationFormsTable from './CertificationFormsTable';


const helpText = (
  <div>
    Create different forms to include in a certification,
    {' '}
    you will be able to select one of these forms to include in a certification on the
    {' '}
    <Link
      className="orange-link"
      to={PATHS[PATH_NAMES.create_certification]}
    >
      Create Certification
    </Link>
    {' '}
    screen.
  </div>
);

const CertificationForms = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <div className="settings view-certification-forms">
        <div className="view-certification-forms-header">
          <div className="flex-wrapper">
            <h1>Certification Forms</h1>
            <button
              className="orange-button"
              onClick={() => { setModalOpen(true); }}
              type="button"
            >
              + New Form
            </button>
          </div>

          <div className="help-text">
            { helpText }
          </div>
        </div>
        <CertificationFormsTable />
      </div>
      {
      modalOpen
        ? (
          <ModalUI
            open={modalOpen}
            close={() => setModalOpen(false)}
            className="small center form-modal"
          >
            <CreateInitForm
              onClose={() => setModalOpen(false)}
            />
          </ModalUI>
        )
        : null
    }
    </>
  );
};

export default CertificationForms;
