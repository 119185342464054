import * as Yup from 'yup';
import { requiredError, invalidEmailError } from 'utils/errorHelpers';


export const [
  name,
  subject,
  ccEmails,
  body,
  banner,
] = [
  'name',
  'subject',
  'cc_emails',
  'body',
  'banner',
];

export const validationSchema = Yup.object().shape({
  [name]: Yup.string()
    .trim()
    .required(requiredError),
  [subject]: Yup.string()
    .trim()
    .required(requiredError),
  [body]: Yup.string()
    .trim()
    .required(requiredError),
  [ccEmails]: Yup.array()
    .of(
      Yup.string().email(invalidEmailError),
    ),
});
