/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import { DATE, NAME } from 'utils/dashboardQueryUtil';

const dateSortStates = [
  'down',
  'down',
  'up',
];

const nameSortStates = [
  'down',
  'down',
  'up',
];

class SortButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filterState: 0 };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const nextState = (this.state.filterState + 1) % 3;
    this.setState({ filterState: nextState });
    this.props.toggleSort();
  }

  render() {
    const { filterState } = this.state;
    const { sortStates, sortIcon } = this.props;
    return (
      <div>
        <button className={filterState ? 'active' : ''} onClick={this.onClick}>
          {sortIcon}
          <i className={`icon-arrow_${sortStates[filterState]}`} />
        </button>
      </div>
    );
  }
}

SortButton.propTypes = {
  toggleSort: PropTypes.func.isRequired,
  sortIcon: PropTypes.object.isRequired,
  sortStates: PropTypes.array.isRequired,
};

function SortButtons({ toggleOrder }) {
  return (
    <div className="sort-button-container">
      <SortButton
        toggleSort={() => { toggleOrder(NAME); }}
        sortIcon={<i className="icon-az" />}
        sortStates={nameSortStates}
      />
      <SortButton
        toggleSort={() => { toggleOrder(DATE); }}
        sortIcon={<i className="material-icons">date_range</i>}
        sortStates={dateSortStates}
      />
    </div>
  );
}

SortButtons.propTypes = {
  toggleOrder: PropTypes.func.isRequired,
};

export default SortButtons;
