import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import { FORM_ELEMENT } from './constants';
import { SchemaField } from './SchemaField';
import { ErrorListener } from './ErrorListener';


const SchemaForm = ({
  formElements,
  title,
  header,
  footer,
  formProps,
  submitProps,
  initialValues,
  onSubmit,
  fieldTypeKey,
  labelClass,
  scrollContainer,
  preclearanceModule,
}) => {
  const _initialValues = Object.assign({}, initialValues);
  formElements.forEach(element => {
    if (!(element.id in _initialValues)) {
      _initialValues[element.id] =
        element.fieldType === 'CheckboxField' ? false : '';
    }
  });

  const handleSubmit = async (values, { setErrors }) => {
    const errors = await onSubmit(values);
    if (errors) {
      setErrors(errors);
    }
  };

  const handleErrors = () => {
    toast('Some fields had errors.');
    if (scrollContainer) {
      // ie 11 does not support scrollTo when called on an html elem
      scrollContainer.scrollTop = 0;
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <React.Fragment>
      {title}
      <Formik
        initialValues={_initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <Form {...formProps} noValidate>
          {header}
          {formElements.map(element => (
            <SchemaField
              key={element.id}
              {...element}
              fieldType={element[fieldTypeKey]}
              labelClass={labelClass}
              preclearanceModule={preclearanceModule}
            />
          ))}
          {footer}
          <button type="submit" {...submitProps}>
            Submit
          </button>
          <ErrorListener onError={handleErrors} />
        </Form>
      </Formik>
    </React.Fragment>
  );
};

SchemaForm.propTypes = {
  formElements: PropTypes.arrayOf(PropTypes.shape(FORM_ELEMENT)).isRequired,
  initialValues: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  formProps: PropTypes.object,
  submitProps: PropTypes.object,
  onSubmit: PropTypes.func,
  fieldTypeKey: PropTypes.string.isRequired, // not a huge fan of this but BE structures data different between certification form and preclearance form
  labelClass: PropTypes.string,
  scrollContainer: PropTypes.object, // for the pc form in modal
  preclearanceModule: PropTypes.func,
};

SchemaForm.defaultProps = {
  header: null,
  footer: null,
  title: null,
  formProps: {},
  submitProps: {},
  initialValues: {},
  onSubmit: () => null,
  labelClass: 'input-title',
  scrollContainer: null,
  preclearanceModule: null,
};

export { SchemaForm };
