import React from 'react';
import PropTypes from 'prop-types';
import { formatNames } from './header';


const resultDetailsGen = (key, ary) => {
  if (!ary || !ary.length) {
    return null;
  }
  return [
    (
      <span className="results-details-label" key={`${key}_head`}>
        {key}:
      </span>
    ),
    (ary.map((item, i) =>
      (<span className="results-details-filter" key={`${i}_${item}_body`}>
        { item }
      </span>),
    )
    ),
  ];
};

const includeFilters = ['states', 'zips', 'employers'];
const excludeFilters = ['exclude', 'excluded_addresses', 'excluded_employers', 'excluded_occupations'];

const SearchFilters = ({ search, uiDateFilter }) => {
  const searchDates = !uiDateFilter.from && !uiDateFilter.to ? [] : [`${uiDateFilter.from || 'Any'} - ${uiDateFilter.to || 'Any'}`]; // only applicable in research view
  const includedFilters = [...searchDates].concat(...includeFilters.map(filter => search[filter] || []));
  const excludedFilters = [].concat(...excludeFilters.map(filter => search[filter] || []));
  const { aliases } = formatNames(search);

  return (
    <span className="filters-span">
      {
        [
          ['Alt Names', aliases],
          ['Strict Last Name', search.strict_last_name ? ['Enabled'] : []],
          ['Including', includedFilters],
          ['Excluding', excludedFilters],
        ].map(details => resultDetailsGen(...details))
      }
    </span>
  );
};

SearchFilters.propTypes = {
  search: PropTypes.object.isRequired,
  uiDateFilter: PropTypes.object, // only in research view
};

SearchFilters.defaultProps = {
  aliases: [],
  uiDateFilter: {},
};

export default SearchFilters;
