import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchMatchActions } from 'reducers/preclearance/matchActions/actions';
import { fetchPreStatuses } from 'reducers/preclearance/statuses/actions';
import { createStatus, editStatus, removeStatus } from 'reducers/statuses/actions';
import Loading from 'components/loading';
import { ActionsList } from 'components/preclearance/settings/relatedDataConfirmations';
import EditStatuses from './editStatuses';


const EditStatusesWrapper = ({
  statuses,
  createStatus,
  editStatus,
  removeStatus,
  fetchMatchActions,
  fetchPreStatuses,
  disableEditing,
  matchActions,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      fetchMatchActions(),
      fetchPreStatuses(),
    ]).then(() => {
      setLoading(false);
    });
  }, []);

  const getRelatedMatchActions = id => Object.values(matchActions)
    .filter(action => action.active && (action.record_status === id));

  const renderRelatedData = (id, isDeleted) => {
    const relatedMatchActions = getRelatedMatchActions(id);
    return (
      <ActionsList
        isDeleted={isDeleted}
        relatedMatchActions={relatedMatchActions}
      />
    );
  };

  return (
    loading ?
      <div className="load-wrapper"><Loading /></div>
      :
      <EditStatuses
        statuses={statuses}
        createStatus={createStatus}
        editStatus={editStatus}
        removeStatus={removeStatus}
        mainHeader={'Edit Status Labels'}
        defaultStatusHelpText={'All incoming records will be set to this status by default. Changing this label will update all records currently marked with this status.'}
        additionalStatusesHelpText={'Renaming an existing status will also update the label on all records currently marked with that status.'}
        allowExclusion
        disableEditing={disableEditing}
        renderRelatedData={renderRelatedData}
      />
  );
};

const mapStateToProps = ({ preclearance, statuses, user }) => {
  const { matchActions } = preclearance;
  return ({
    matchActions,
    statuses,
    disableEditing: !user.is_manager,
  });
};


export default connect(
  mapStateToProps,
  {
    createStatus,
    editStatus,
    removeStatus,
    fetchMatchActions,
    fetchPreStatuses,
  },
)(EditStatusesWrapper);

EditStatusesWrapper.propTypes = {
  statuses: PropTypes.object.isRequired,
  createStatus: PropTypes.func.isRequired,
  editStatus: PropTypes.func.isRequired,
  removeStatus: PropTypes.func.isRequired,
  fetchMatchActions: PropTypes.func.isRequired,
  fetchPreStatuses: PropTypes.func.isRequired,
  disableEditing: PropTypes.bool.isRequired,
  matchActions: PropTypes.object.isRequired,
};
