import api from 'api';
import { RequestTrackerCanceler, CONTRIBUTIONS_REQ_ID } from 'utils/apiUtil';
import { DASHBOARD_RECORD_LIMIT } from 'utils/constants';
import { setAnimatingContributions, unsetAnimatingContributions } from 'reducers/ui/dashboard/actions';
import * as types from './types';
import { byMetaIds } from './selectors';


export const setBulkStatus = data => ({
  data,
  type: types.SET_SECTION_STATUS,
});

const setContributions = data => ({
  contributions: data,
  type: types.SET_CONTRIBUTIONS,
});

const updateNote = data => ({
  data,
  type: types.UPDATE_NOTE,
});

const appendContributions = contribs => ({
  contributions: contribs,
  type: types.APPEND_CONTRIBUTIONS,
});

export const setSectionStatus = (ids, status) => (dispatch, getState) => api.post('/contributions/bulk/', { ids, status }).then(({ data }) => {
  const statusFilter = getState().ui.dashboard.filter;
  if (!statusFilter) {
    dispatch(setBulkStatus(data));
  } else {
    const contribs = byMetaIds(getState().contributions, ids);
    const nonmatching = contribs.filter(c => c.meta.status !== status).map(c => c.meta.id);
    // of the contribs that come back only keep the ones that have status different from status we are changing to
    // in the case that there is a filter applied and we are changing the status of a record to its current status we don't want to animate it b/c it should not be removed from view
    // todo this case should be prevented before we get here
    const nextRecords = data.filter(d => nonmatching.indexOf(d.meta.id) >= 0);
    dispatch(setAnimatingContributions(nextRecords));
  }
  return data;
});

export const clearAnimatingContributions = () => {
  return unsetAnimatingContributions();
};

export const updateContributionStatus = (ids, status) => dispatch => (
  api.post('/contributions/bulk/', { ids, status }).then(({ data }) => {
    dispatch(setBulkStatus(data));
    return data;
  }));

/*
  -------------- NOTICE --------------

  The action flag here should be one of [ "append", "set" ].
  Could be anything other than "append", and behavior would be the same as "set".
  Though we'd probably want to keep convention to prevent future headache.

*/

export const getContributions = (action, filter, sourceFilter, dateFilter, ordering, offset = 0) => (dispatch, getState) => {
  // cancel any pending request to avoid new one loading before old one, like in the case of date filter change
  RequestTrackerCanceler.cancelByRequestId(CONTRIBUTIONS_REQ_ID);

  const status = filter ? filter.id : '';
  const query = {};
  const contribs = getState().contributions.allIds;
  if (status) {
    query.status = status;
  }
  if (sourceFilter) {
    query.category = sourceFilter;
  }
  if (dateFilter) {
    Object.keys(dateFilter).forEach((key) => {
      if (dateFilter[key]) {
        query[key] = dateFilter[key];
      }
    });
  }
  if (contribs.length && action === 'append') {
    const lastId = contribs[contribs.length - 1];
    query.after_record = lastId;
  }

  query.limit = DASHBOARD_RECORD_LIMIT;

  if (ordering) {
    query.limit = DASHBOARD_RECORD_LIMIT + offset;
    query.offset = offset;
    query.ordering = ordering;
    delete query.after_record;
  }


  // Query params:
  // status=<STATUS ID> (optional)
  // category=<CATEGORY ID> (optional, can be multiple but we are just doing one for now, if none is provided, all categories will be returned)
  // to=<DATE> (optional)
  // from=<DATE> (optional)
  // after_record=<RECORD ID> (optional)
  // limit=<integer> (optional, defaults to 100)
  // offset=<integer> (optional, defaults to 0)
  // ordering=<order> (optional)
  // Sample query: /contributions/?status=322&category=1&category=2&after_record=bfe6897a17270e3dea36bad701098064&limit=100

  const queryString = Object.keys(query).map((k) => {
    // for the category field since we allow multiples, possible we will have others in the future?
    if (Array.isArray(query[k])) {
      return query[k].map(val => `${k}=${val}`).join('&');
    }
    return `${k}=${query[k]}`;
  }).join('&');

  return api.get(`/contributions/?${queryString}`, {
    cancelToken: RequestTrackerCanceler.getCancelToken(CONTRIBUTIONS_REQ_ID),
  })
    .then(({ data }) => {
      if (['append', 'set'].indexOf(action) < 0) {
        console.warn("Using unexpected action behavior. Defaulting to 'set' behavior.");
      }
      if (action === 'append') {
        dispatch(appendContributions(data));
      } else {
        dispatch(setContributions(data));
      }
      return data;
    });
};

// todo this is broken in jur view for records where the source is not selected
export const createComment = (contribId, comment) => dispatch =>
  api.post(`/contributions/${contribId}/notes/`, { action: { comment } }).then(({ data }) => {
    dispatch(updateNote(data));
    return data;
  });

  // todo broke in jur view if no contribId then call createContrib first, and then this function.
export const sendRecord = (contribId, emailInfo) => dispatch => (
  api.post(`/contributions/${contribId}/share/`, emailInfo).then(({ data }) => {
    dispatch(updateNote(data));
  })
);


// For records that are do not have a source selected in juriscdiction view and therefore have no contrib id
export const createContrib = (source_result_item, note) => (dispatch) => {
  return api.post('/contributions/', { source_result_item, note }).then(({ data }) => {
    dispatch(updateNote(data));
    return data;
  });
};
