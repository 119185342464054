import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parseField } from 'helpers/formatFieldHelpers';
import { PRE_REQUEST } from 'components/shared/matching/consts';
import IconTextButton from 'components/monitoringForm/iconTextButton';


const RowCard = ({ record, schema, removeMatch, viewOnly, userId, shareSearches, matchType }) => (
  <div className="row-card">
    <div className="row-card__heading">
      <div>
        <span>{record.name || record.contributor}</span>
        <span className="status">{record.status}</span>
      </div>
      {
        !viewOnly ?
          <IconTextButton
            buttonHandler={() => { removeMatch(record.id); }}
            buttonText={`Unmatch ${matchType === PRE_REQUEST ? 'request' : 'record'}`}
            icon={'link_off'}
            className={'orange-button'}
          /> : ''
      }
    </div>
    <div className="dashboard-record-wrapper">
      <div className="row-card__schema">
        {schema.map(field => (
          <div key={field.id} className="dashboard-cell">{field.label}</div>
        ))}
      </div>
      <div className="row-card__row">
        {schema.map(field => (
          <div key={`val-${field.id}`} className="dashboard-cell">
            {parseField(record[field.id], field.id)}
          </div>))}
      </div>
      { record.user_id && record.user_id !== userId && !shareSearches ?
        <div className="dashboard-cell note">
          NOTE: This record was matched from a search belonging to {record.username}.
        </div> : ''
      }
    </div>
  </div>
);

const mapStateToProps = state => ({
  userId: state.user.id,
  shareSearches: state.organization.share_searches,
});

export default connect(mapStateToProps)(RowCard);

RowCard.propTypes = {
  userId: PropTypes.number.isRequired,
  shareSearches: PropTypes.bool.isRequired,
  record: PropTypes.object.isRequired,
  schema: PropTypes.array.isRequired,
  removeMatch: PropTypes.func,
  viewOnly: PropTypes.bool,
  matchType: PropTypes.string,
};

RowCard.defaultProps = {
  viewOnly: false,
  removeMatch: () => {},
  matchType: '',
};

