const [CCD, PRECLEARANCE, JURISDICTIONS, CERTIFICATIONS, MANAGE_ORG, MANAGE_PASSWORD, CREATE_USER, DISABLE_SEND_EMAILS] = ['ccd', 'preclearance', 'jurisdictions', 'certifications', 'manage_org', 'manage_password', 'create_user', 'disable_sending_emails'];

const hasFeature = (user, feature, value) => (user.features ? user.features[feature] === value : false);
const hasPermissions = (user, feature) => (user.permissions[feature]);

export const hasCcdPermissions = user => hasFeature(user, CCD, true);
export const hasJurisdictionPermissions = user => hasFeature(user, JURISDICTIONS, true);
export const hasPreclearancePermissions = user => hasFeature(user, PRECLEARANCE, true);
export const hasCertificationsPermissions = user => hasFeature(user, CERTIFICATIONS, true);
export const hasManageOrgPermissions = user => hasPermissions(user, MANAGE_ORG);
export const hasManagePasswordPermissions = user => hasPermissions(user, MANAGE_PASSWORD);
export const hasCreateUserPermissions = user => hasPermissions(user, CREATE_USER);
export const hasExportPermissions = user => !user.is_trial;
// customer needed sending emails from within platform turned off because of company policy
export const disableSendEmailPermissions = user => hasFeature(user, DISABLE_SEND_EMAILS, true);
