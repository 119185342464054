import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import OutsideClickHandler from 'react-outside-click-handler';
import { FieldArray } from 'formik';
import { FORM_BODY_DROPPABLE, FORM_ELEMENT, SPECIAL } from '../../consts';
import EditableQuestion from './EditableQuestion';
import DraggableWrapper from './DraggableWrapper';
import PreclearanceModule from './PreclearanceModule';


function Fields({
  formFields,
  fieldOrder,
  openField,
  setOpenField,
  removeDependency,
  removeDependencyChoice,
}) {
  useEffect(() => {
    const closeField = (event) => {
      if (event.key === 'Escape') {
        setOpenField(null);
      }
    };
    document.addEventListener('keydown', closeField);
    return () => document.removeEventListener('keydown', closeField);
  }, [setOpenField]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => setOpenField(null)}
    >
      <div>
        <Droppable
          droppableId={FORM_BODY_DROPPABLE}
          type={FORM_ELEMENT}
        >
          {(provided, snapshot) => (
            <div
              className={`${snapshot.isDraggingOver ? 'active ' : ''}form-body-droppable`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {
              fieldOrder.length
                ? (
                  <FieldArray
                    name="fieldOrder"
                  >
                    {({ remove }) => (
                      fieldOrder.map((fieldId, index) => {
                        const field = formFields[fieldId];
                        const inEditMode = openField === fieldId;
                        const removeField = () => {
                          setOpenField(null);
                          removeDependency(fieldId);
                          remove(index);
                        };
                        return (
                          <DraggableWrapper
                            key={fieldId}
                            id={field.id}
                            index={index}
                            inEditMode={inEditMode}
                            openEditMode={() => setOpenField(fieldId)}
                          >
                            {
                              field.input_type === SPECIAL ? (
                                <PreclearanceModule
                                  inEditMode={inEditMode}
                                  removeField={removeField}
                                />
                              )
                                : (
                                  <EditableQuestion
                                    field={field}
                                    index={index}
                                    removeDependencyChoice={removeDependencyChoice}
                                    removeDependency={removeDependency}
                                    removeField={removeField}
                                    inEditMode={inEditMode}
                                  />
                                )
                            }
                          </DraggableWrapper>
                        );
                      })
                    )}
                  </FieldArray>
                )
                : (
                  <div className="form-body-empty">
                    Start by dragging in a question.
                  </div>
                )
            }
              { provided.placeholder }
            </div>
          )}
        </Droppable>
      </div>
    </OutsideClickHandler>
  );
}

Fields.propTypes = {
  formFields: PropTypes.object.isRequired,
  fieldOrder: PropTypes.array.isRequired,
  openField: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  setOpenField: PropTypes.func.isRequired,
  removeDependency: PropTypes.func.isRequired,
  removeDependencyChoice: PropTypes.func.isRequired,
};

Fields.defaultProps = {
  openField: null,
};

export default Fields;
