import { connect } from 'react-redux';

import SearchObjectView from '../shared/searchResults/searchObjectView';
import { fetchResult, fetchResults } from '../../reducers/research/actions';
import { withData, completed } from '../../reducers/sourceResults/selectors';
import { byId, subcategories } from '../../reducers/sources/selectors';

const mapStateToProps = (state) => {
  const { research } = state;
  const selectedSearch = research.currentSearch;
  const searchRef = selectedSearch;
  const searchResultIds = research.results; // sourceResultIds

  const completedSources = completed(research.sourceResults, searchResultIds);
  const sourceIds = withData(research.sourceResults, searchResultIds)
    .map(sourceResult => sourceResult.source);
  const sources = byId(state.sources.sources, sourceIds);
  const subcategoriesWithData = subcategories(sources);

  const searchCompleted = completedSources.length === searchRef.sources.length;
  return {
    loading: !completedSources.length,
    searchCompleted,
    allSourceResultsFetched: false,
    search: searchRef,
    noResults: searchCompleted && sourceIds.length === 0,
    subcategories: subcategoriesWithData,
    sources,
    dateFilter: state.research.ui.dateFilter,
  };
};

export default connect(mapStateToProps, {
  fetchResult,
  fetchResults,
})(SearchObjectView);
