import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="notFound">
    <div>
      <i className="material-icons build">build</i>
      <div className="message">
        <span className="number">
          404
        </span>
        <span className="divider" />
        <span className="description">
          Page Not Found
        </span>
      </div>

      <Link to="/app/dashboard" className="back-button">
        <i className="material-icons">arrow_back</i>
        Back to dashboard
      </Link>
    </div>
  </div>
);

export default NotFound;
