import React from 'react';
import PropTypes from 'prop-types';


const ErrorCellWrapper = ({
  children,
  width,
}) => (
  <div
    style={{
      width,
      background: 'inherit',
    }}
  >
    <div
      className="error-cell"
      style={{
        width,
        position: 'absolute',
        zIndex: 1,
        top: -1,
        background: 'inherit',
      }}
    >
      { children }
    </div>
  </div>
);


ErrorCellWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
};


export default ErrorCellWrapper;
