import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from 'components/loading';
import { EmailTemplates } from 'components/shared/emailTemplates/emailTemplates';
import {
  name,
  subject,
  body,
} from 'components/shared/emailTemplates/consts';
import {
  fetchEmailTemplates, createEmailTemplate, updateEmailTemplate, deleteEmailTemplate,
} from 'reducers/certifications/emailTemplates/actions';
import { fetchMacros } from 'reducers/certifications/macros/actions';
import { RelatedCertificationsList } from 'components/preclearance/settings/relatedDataConfirmations';
import useCertifications from '../CertificationsList/useCertifications';
import { MACRO_LINK_HELP_TEXT } from '../constants';


const EmailTemplatesWrapper = ({
  templates,
  macros,
  fetchTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  getMacros,
}) => {
  const [loading, setLoading] = useState(true);
  const { templates: certificationTemplates, loading: certificationsLoading } = useCertifications();

  useEffect(() => {
    let didCancel = false;
    Promise.all([
      fetchTemplates(),
      getMacros(),
    ]).then(() => {
      if (!didCancel) {
        setLoading(false);
      }
    }).catch((err) => {
      if (!didCancel) {
        setLoading(false);
      }
      toast('Something went wrong.');
      throw err;
    });
    return () => {
      didCancel = true;
    };
  }, [fetchTemplates, getMacros]);

  const getRelatedCertifications = (id) => certificationTemplates.filter((cert) => (cert.email_template && cert.email_template === id));

  const renderRelatedData = (id, isDeleted) => {
    const relatedCertifications = getRelatedCertifications(id);
    const message = isDeleted ? 'Before this email template is deleted, the contents will be copied over to the following certifications so that they can still be sent:' : '';
    return (
      <RelatedCertificationsList
        relatedCertifications={relatedCertifications}
        isDeleted={isDeleted}
        message={message}
      />
    );
  };

  return (
    loading || certificationsLoading
      ? (
        <div className="load-wrapper">
          <Loading />
        </div>
      )
      : (
        <EmailTemplates
          macros={macros.variables}
          macroLinks={macros.links}
          templates={templates}
          createTemplate={createTemplate}
          updateTemplate={updateTemplate}
          deleteTemplate={deleteTemplate}
          helpText="Configure the email templates that are sent as part of a certification."
          defaultTemplate={{
            [name]: '',
            [subject]: '',
            [body]: '',
          }}
          macroLinkHelpText={MACRO_LINK_HELP_TEXT}
          renderRelatedData={renderRelatedData}
          getRelatedData={getRelatedCertifications}
        />
      )
  );
};

const mapStateToProps = ({ certifications: { emailTemplates, macros } }) => ({
  templates: emailTemplates,
  macros,
});

EmailTemplatesWrapper.propTypes = {
  templates: PropTypes.object.isRequired,
  macros: PropTypes.object.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  createTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  getMacros: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  fetchTemplates: fetchEmailTemplates,
  createTemplate: createEmailTemplate,
  updateTemplate: updateEmailTemplate,
  deleteTemplate: deleteEmailTemplate,
  getMacros: fetchMacros,
})(EmailTemplatesWrapper);
