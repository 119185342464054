import * as types from './types';

export const changeFilter = filter => ({
  filter,
  type: types.SET_FILTER,
});

export const setCatFilter = (filter) => ({
  filter,
  type: types.SET_CATEGORY_FILTER,
});


export const setAnimatingContributions = ids => ({
  type: types.SET_ANIMATING_IDS,
  ids,
});

export const unsetAnimatingContributions = () => ({
  type: types.CLEAR_ANIMATING_IDS,
});
