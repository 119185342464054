import React, { Component } from "react";

export default class TransitionContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeState: props.initialState || 0,
      fadeState: false,
    };
    this.timer = null;
    this.timeout = this.timeout.bind(this);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  timeout(nextState) {
    clearTimeout(this.timer);
    return setTimeout(_ => {
      const next = nextState;
      this.setState({
        activeState: next % this.props.children.length,
        fadeState: false
      })
    }, 1000);
  }



  advanceState(nextState) {
    if (nextState === this.state.activeState) {
      return;
    }
    this.setState({
      fadeState: true,
    }, () => {
      this.timer = this.timeout(nextState);
    });
  }

  reset() {
    this.setState({
      activeState: 0,
      fadeState: false,
    });
  }

  getState() {
    return this.state.activeState;
  }

  render() {
    return (
      <div className="transition-container" style={this.props.style || {}}>
        {
          this.props.children.map((c, i) => (
            this.state.activeState === i ?
              <div key={i} className={`transition-item ${this.state.activeState === i ? "active" : ""} ${this.state.fadeState ? "fading" : ""}`}>
                { c }
              </div>
              : null
          ))
        }
      </div>
    )
  }
}


export class StaticTransitionContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeState: props.initialState || 0,
      fadeState: false,
    };
  }

  advanceState(nextState) {
    if (nextState === this.state.activeState) {
      return;
    }
    this.setState({
      fadeState: true,
    }, () => {
        this.setState({
          activeState: nextState % this.props.children.length,
          fadeState: false
        });
    });
  }

  reset() {
    this.setState({
      activeState: 0,
      fadeState: false,
    });
  }

  getState() {
    return this.state.activeState;
  }

  render() {
    return (
      <div className="transition-container" style={this.props.style || {}}>
        {
          this.props.children.map((c, i) => (
            this.state.activeState === i ?
              <div key={i} className={`transition-item ${this.state.activeState === i ? "active" : ""} ${this.state.fadeState ? "fading" : ""}`}>
                {c}
              </div>
              : null
          ))
        }
      </div>
    )
  }
}