import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BodyWithSidebar from 'components/shared/BodyWithSidebar';
import EditProfile from 'components/settings/accountSettings/profile';
import ChangePassword from 'components/settings/accountSettings/password';
import PasswordPolicy from 'components/settings/accountSettings/passwordPolicy';
import TwoFactorAuth from 'components/settings/accountSettings/twoFactor/twoFactor';
import OrganizationSettings from 'components/settings/organizationSettings/organizationSettings';
import { hasManagePasswordPermissions } from '../helpers/permissions';


const [PROFILE, PASSWORD, PASSWORD_POLICY, TWO_FACTOR, ORG_SETTINGS] = ['PROFILE', 'PASSWORD', 'PASSWORD_POLICY', 'TWO_FACTOR', 'ORG_SETTINGS'];

const accountSettingsTabMap = {
  [PROFILE]: 'Edit Profile',
  [PASSWORD]: 'Change Password',
  [PASSWORD_POLICY]: 'Password Policy',
  [TWO_FACTOR]: 'Two-Factor Authentication',
  [ORG_SETTINGS]: 'Manage Organization',
};

const accountSettingsBodyMap = {
  [PROFILE]: EditProfile,
  [PASSWORD]: ChangePassword,
  [PASSWORD_POLICY]: PasswordPolicy,
  [TWO_FACTOR]: TwoFactorAuth,
  [ORG_SETTINGS]: OrganizationSettings,
};

const generalSettings = [PROFILE];
const passwordSettings = [PASSWORD, PASSWORD_POLICY, TWO_FACTOR];

const AccountSettings = ({ canManagePassword }) => {
  const allowedSettings = generalSettings
    .concat(canManagePassword ? passwordSettings : []);

  const tabMap = allowedSettings.reduce((allSettings, setting) => {
    allSettings[setting] = accountSettingsTabMap[setting];
    return allSettings;
  }, {});

  const bodyMap = allowedSettings.reduce((allBody, setting) => {
    allBody[setting] = accountSettingsBodyMap[setting];
    return allBody;
  }, {});

  return (
    <BodyWithSidebar
      title={'Manage Account'}
      tabs={Object.keys(tabMap)}
      tabMap={tabMap}
      bodyMap={bodyMap}
      className={'settings account-settings'}
    />
  );
};

AccountSettings.propTypes = {
  canManagePassword: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ user }) => ({
  canManagePassword: hasManagePasswordPermissions(user),
});

export default connect(mapStateToProps)(AccountSettings);
