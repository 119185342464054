import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { byId } from 'reducers/sources/selectors';
import { hasCcdPermissions, disableSendEmailPermissions } from 'helpers/permissions';
import { CCD_FIELDS, DELETE_MATCH, PRE_MATCHING_MODAL, SEND_RECORD } from 'utils/constants';
import { PRE_REQUEST } from 'components/shared/matching/consts';
import { openModal } from 'reducers/ui/actions';
import MatchButtonRenderer from 'components/shared/matching/matchButtonRenderer';
import EmailRecordButton from 'components/shared/recordItem/emailRecordButton';
import CommentCell from 'components/shared/recordItem//commentCell';


class ResultRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

    this.setStatus = this.setStatus.bind(this);
    this.onRecipientEnter = this.onRecipientEnter.bind(this);
    this.onRecipientExit = this.onRecipientExit.bind(this);
    this.hasFields = this.hasFields.bind(this);
    this.hasCcdData = byId(this.props.sources, [this.props.sourceId])[0].metadata.has_ccd === true;

    this.renderComments = this.renderComments.bind(this);
    this.renderSendRecord = this.renderSendRecord.bind(this);
    this.renderMatchButton = this.renderMatchButton.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sourceId !== this.props.sourceId) {
      this.hasCcdData = !this.props.isSchema && byId(this.props.sources, [this.props.sourceId])[0].metadata.has_ccd === true;
    }
  }

  onRecipientEnter(e) {
    e.stopPropagation();
    if (!this.hasFields() || !this.props.showCcd) {
      return;
    }
    let target = e.target;
    while (!target.classList.contains('dashboard-cell') && target.parentNode) {
      target = target.parentNode;
    }
    target = target.querySelector('.record-cell');
    const targetBounds = target.getBoundingClientRect();
    const toFire = new CustomEvent('tooltip.position', {
      detail: {
        recipient: this.props.data,
        boundingBox: targetBounds,
      },
    });
    document.body.dispatchEvent(toFire);
  }

  onRecipientExit() {
    const toFire = new CustomEvent('tooltip.position', {
      detail: {
        recipient: null,
      },
    });
    document.body.dispatchEvent(toFire);
  }

  setStatus(status) {
    // meta is serialized differently at dashboard and monitoring :\
    if (status.id === (this.props.meta.contribution_status || this.props.meta.status)) {
      return;
    }
    const ids = [this.props.meta.id];
    const count = ids.length.overrideLocaleString();
    this.props.setSectionStatus(ids, status.id).then((data) => {
      const label = status.label || '';
      const toastContent = (<div>Marked <strong>{count}</strong> record{count > 1 ? 's' : ''} as <strong>{`"${label}"`}</strong></div>);
      toast(toastContent);
      this.props.toggleContribution([data[0].meta.id]);
    })
      .catch((err) => {
        toast('There was a problem changing this status.');
        throw err;
      });
  }


  hasFields() {
    return CCD_FIELDS.some(field => !!this.props.data[field]) && this.hasCcdData;
  }

  renderMatchButton() {
    const { meta, data, viewOnly, showAutomatches } = this.props;
    return (
      <MatchButtonRenderer
        noMatch={meta.no_match}
        matches={meta.matches}
        automatches={meta.automatches}
        showAutomatches={showAutomatches && !viewOnly}
        openMatchingDialog={() => {
          this.props.openModal({
            modalType: PRE_MATCHING_MODAL,
            modalProps: { recordId: data.id } });
        }}
        disableMatching={this.props.disableMatching}
        viewOnly={viewOnly}
        matchType={PRE_REQUEST}
        removeMatch={(requestId) => {
          this.props.openModal({
            modalType: DELETE_MATCH,
            modalProps: { requestId, recordMetaId: meta.id, recordId: data.id } });
        }}
      />
    );
  }

  renderComments() {
    const { meta, data, noteIdParam, files } = this.props;
    const notes = meta.notes || meta.contribution_notes || [];
    return (
      <React.Fragment>
        <CommentCell
          id={meta.id || noteIdParam}
          createComment={this.props.createComment}
          files={files || []}
          fileCount={files ? files.length : meta.contribution_file_count}
          notes={notes}
          firstSeen={meta.first_seen}
        />
      </React.Fragment>
    );
  }

  renderSendRecord() {
    const { disableSendEmail } = this.props;
    return (
      <EmailRecordButton
        openEmailDialog={() => {
          this.props.openModal({
            modalType: SEND_RECORD,
            modalProps: {
              recordId: this.props.data.id,
            },
          });
        }}
        disabled={disableSendEmail}
        tooltip={disableSendEmail ? 'Sending records is not allowed for your organization.' : null}
      />
    );
  }


  render() {
    return this.props.render({
      onRecipientEnter: this.onRecipientEnter,
      onRecipientExit: this.onRecipientExit,
      hasFields: this.hasFields,
      showCcd: this.props.showCcd,
      setStatus: this.setStatus,
      renderMatchButton: this.renderMatchButton,
      renderComments: this.renderComments,
      renderSendRecord: this.renderSendRecord,
    });
  }
}

ResultRow.propTypes = {
  data: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  sources: PropTypes.array.isRequired, // for determining if the source has ccd data. finding the source here instead of passing down bc jurisdiction view
  sourceId: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired,
  showCcd: PropTypes.bool.isRequired,
  showAutomatches: PropTypes.bool.isRequired,
  noteIdParam: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  createComment: PropTypes.func.isRequired,
  setSectionStatus: PropTypes.func.isRequired,
  toggleContribution: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  disableMatching: PropTypes.bool,
  viewOnly: PropTypes.bool,
  openModal: PropTypes.func,
  disableSendEmail: PropTypes.bool.isRequired,
};

ResultRow.defaultProps = {
  disableMatching: false,
  viewOnly: false,
  openModal: () => {},
};

const mapStateToProps = (state, props) => {
  const files = state.uploads[props.meta.id] || [];
  const { user } = state;
  return {
    showCcd: hasCcdPermissions(state.user),
    showAutomatches: state.user.show_automatches,
    sources: state.sources.sources,
    files,
    disableSendEmail: disableSendEmailPermissions(user),
  };
};

export default connect(mapStateToProps, { openModal })(ResultRow);
