import api from 'api';
import * as types from './types';


const loadMacros = macros => ({
  macros,
  type: types.LOAD_MACROS,
});


export const fetchMacros = () => (dispatch) => {
  return api.get('/attestations/email/macros/').then(({ data }) => {
    dispatch(loadMacros(data));
  });
};
