import React from 'react';
import PropTypes from 'prop-types';


function SVG({
  style,
  pathStyle,
  width,
  height,
  version,
  className,
  viewBox,
  xmlns,
  pathD,
}) {
  return (
    <svg
      style={style}
      height={height}
      width={width}
      version={version}
      viewBox={viewBox}
      xmlns={xmlns}
      className={`svg-icon ${className || ''}`}
    >
      <path
        style={pathStyle}
        d={pathD}
      />
    </svg>
  );
}

SVG.propTypes = {
  style: PropTypes.object.isRequired,
  pathStyle: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  version: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
  xmlns: PropTypes.string.isRequired,
  pathD: PropTypes.string.isRequired,
};

SVG.defaultProps = {
  style: {},
  pathStyle: { fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' },
  width: 200,
  height: 200,
  version: 1.1,
  className: '',
  viewBox: '0 0 57 57',
  xmlns: 'http://www.w3.org/2000/svg',
  pathD: '',
};

export default SVG;
