import React from 'react';
import PropTypes from 'prop-types';


const Section = ({
  label, icon, expanded, toggleExpanded,
}) => (
  <div
    className={`sidebar-section alpha-selector-toggle ${expanded ? 'expanded' : ''}`}
    onClick={toggleExpanded}
    role="button"
    tabIndex="0"
  >
    <span className="sidebar-section-label">
      <span className="material-icons sidebar-section-icon">
        { icon }
      </span>
      <span>
        { label }
      </span>
    </span>

    <span className="alpha-selector-icon">
      {expanded
        ? <i className="material-icons">remove</i>
        : <i className="material-icons">add</i>}
    </span>
  </div>
);


Section.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};

export default Section;
