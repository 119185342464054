import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, ErrorMessage } from 'formik';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import ZipcodeFields from 'components/monitoringForm/personForm/zipcodes';
import SourceSelectionWrapper from '../../monitoringForm/sourceSelection/formikSourceSelectionWrapper';
import AbstractDropdown from '../../abstractDropdown';
import SearchButton from './searchButton';
import StateDropdownWrapper from '../../monitoringForm/personForm/formikStateDropdownWrapper';
import {
  firstName,
  lastName,
  states,
  zipcodes,
  to,
  from,
  researchLimitTooltip,
} from './consts';
import { makeCountHeader } from './contributorSearchFormWrapper';

const HeaderForm = ({
  sources,
  limitReached,
  isSubmitting,
  values,
  edit,
  resetForm,
}) => {
  const zipCount = values[zipcodes].filter(z => z.trim()).length;
  const dateCount = values[from].length > 0 || values[to].length > 0 ? 1 : 0;

  useEffect(() => {
    if (edit) {
      resetForm();
      window.scrollTo(0, 0);
    }
  }, [edit]);

  return (
    <div>
      <Form autoComplete="off" noValidate>
        <div className="row name-row search-header">
          <div className="col">
            <div className="focusable">
              <SchemaField
                name={firstName}
                id={firstName}
                label={'First name'}
                fieldType={'TextField'}
                className={'form-input'}
                required
              />
            </div>
          </div>
          <div className="col">
            <div className="focusable">
              <SchemaField
                name={lastName}
                id={lastName}
                label={'Last name'}
                fieldType={'TextField'}
                className={'form-input'}
                required
              />
            </div>
          </div>
          <div className="col no-flex">
            <div className="search-button-container">
              <SearchButton
                tooltipMessage={limitReached ? researchLimitTooltip : ''}
                disabled={limitReached || isSubmitting}
              />
            </div>
          </div>
        </div>

        <div className="row">

          <div className="focusable filter-item">
            <StateDropdownWrapper
              field={states}
              selected={values[states]}
              placeholder={'States'}
            />
          </div>

          <div className="filter-item">
            <AbstractDropdown
              dropdownText={makeCountHeader('Postal Codes', zipCount)}
            >
              <div className="dropdown-inner-wrapper">
                <ZipcodeFields />
              </div>
            </AbstractDropdown>
            <ErrorMessage
              name={zipcodes}
              component="div"
              className="error-text"
            />
          </div>

          <div className="filter-item" >
            <AbstractDropdown
              dropdownText={makeCountHeader('Dates', dateCount)}
            >
              <div className="dropdown-inner-wrapper">
                <div className="input-title">Record Dates</div>
                <div className="range-input-container">
                  <SchemaField
                    name={from}
                    id={from}
                    label={'from'}
                    fieldType={'TextField'}
                    className={'form-input range-input'}
                    placeholder={'mm/dd/yyyy'}
                  />
                  <span>to</span>
                  <SchemaField
                    name={to}
                    id={to}
                    label={'to'}
                    fieldType={'TextField'}
                    className={'form-input range-input'}
                    placeholder={'mm/dd/yyyy'}
                  />
                </div>
              </div>
            </AbstractDropdown>
            <ErrorMessage
              name={to}
              component="div"
              className="error-text"
            />
            <ErrorMessage
              name={from}
              component="div"
              className="error-text"
            />
          </div>

          <div className="source-selection filter-item">
            <AbstractDropdown
              dropdownText={makeCountHeader('Sources', values.sources.size)}
            >
              <div className="dropdown-inner-wrapper">
                <SourceSelectionWrapper
                  sources={sources}
                  selected={values.sources}
                  field={'sources'}
                />
              </div>
            </AbstractDropdown>
          </div>
        </div>

      </Form>
    </div>
  );
};


HeaderForm.propTypes = {
  sources: PropTypes.array.isRequired,
  limitReached: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default HeaderForm;
