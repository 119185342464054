import { connect } from 'react-redux';
import SearchObjectView from 'components/shared/searchResults/searchObjectView';
import { byId as searchObjById } from 'reducers/searchObjects/selectors';
import { fetchResults, fetchResult } from 'reducers/results/actions';
import { getResultsById } from 'reducers/results/selectors';
import { withData, completed } from 'reducers/sourceResults/selectors';
import { byId, allIds, subcategories } from 'reducers/sources/selectors';


const mapStateToProps = (state, props) => {
  const { searchId } = props;
  const searchRef = searchObjById(state, searchId);
  const searchResult = getResultsById(state, searchRef.id);
  if (!searchResult) {
    return {
      loading: true,
      noResults: false,
      allSourceResultsFetched: false,
      search: searchRef,
      searchCompleted: searchRef.completed,
      subcategories: [],
      sources: [],
      dateFilter: state.ui.dateFilter,
    };
  }
  const allSourceIds = allIds(state.sources.sources);
  const completedSources = completed(state.sourceResults, searchResult.sourceResults);
  const sourceIds = withData(state.sourceResults, searchResult.sourceResults).map(sourceResult => sourceResult.source);
  const sources = byId(state.sources.sources, sourceIds);
  const subcategoriesWithData = subcategories(sources);

    // it's possible the selected source from search settings was removed from platform
    const totalSourceCount = (searchRef.sources || []).filter((sourceId) => allSourceIds.includes(sourceId)).length;

  const allSourceResultsFetched = completedSources.length === totalSourceCount;
  const searchCompleted = allSourceResultsFetched || searchRef.completed;

  // doing the check this way because it was causing a mismatch and the no results component was showing when there were actually results
  const noResults = (allSourceResultsFetched && sourceIds.length === 0) || (searchRef.completed && searchRef.last_count === 0);

  return {
    loading: state.ui.resultsLoading || !completedSources.length,
    searchCompleted,
    allSourceResultsFetched,
    search: searchRef,
    noResults,
    subcategories: subcategoriesWithData,
    sources,
    dateFilter: state.ui.dateFilter,
  };
};

export default connect(mapStateToProps, {
  fetchResults,
  fetchResult,
})(SearchObjectView);
