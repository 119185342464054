import api from 'api';

export function validateZipcode(zip) {
  const warning = 'Warning: This is not a valid U.S. zip code and will be treated as a foreign postal code.';
  return api.get(`postalcodes/${encodeURIComponent(zip.trim())}/`).then(({ data }) => {
    if (data.error) {
      return warning;
    }
    return null;
  }).catch((err) => {
    const status = err && err.response ? err.response.status : null;
    if (status && (status === 400 || status === 404)) {
      return warning;
    }
    throw err;
  });
}
