import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Menu, {
  MENU,
  EDIT,
  DELETE,
  FILTER,
} from './Menu';
import EditColumn from './EditColumn';
import DeleteColumnConfirmation from './DeleteColumnConfirmation';
import FilterColumn from './FilterColumn';


const MenuWrapper = ({
  column,
  updateColumn,
  deleteColumn,
  addFilter,
  onSort,
  disableDropdown,
  canEdit,
  closeMenu,
}) => {
  const [menuType, setMenuType] = useState(MENU);
  const menuOptionMap = {
    [MENU]: Menu,
    [EDIT]: EditColumn,
    [DELETE]: DeleteColumnConfirmation,
    [FILTER]: FilterColumn,
  };

  const MenuComponent = menuOptionMap[menuType];

  const { width } = column;
  const widths = {
    [MENU]: width,
    [EDIT]: Math.max(358, width),
    [DELETE]: Math.max(256, width),
    [FILTER]: Math.max(300, width),
  };

  return (
    <div
      className="preclearance-popover"
      style={{ width: widths[menuType] }}
    >
      <MenuComponent
        column={column}
        setMenuType={setMenuType}
        updateColumn={updateColumn}
        deleteColumn={deleteColumn}
        addFilter={addFilter}
        onSort={onSort}
        disableDropdown={disableDropdown}
        canEdit={canEdit}
        onCancel={closeMenu}
      />
    </div>
  );
};


MenuWrapper.propTypes = {
  column: PropTypes.object.isRequired,
  updateColumn: PropTypes.func.isRequired,
  deleteColumn: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  disableDropdown: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default MenuWrapper;
