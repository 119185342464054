import React from 'react';
import PropTypes from 'prop-types';
import Confirmation from '../../confirmationShared';

const GenConfirmation = ({
  close,
  onSubmit,
  disableConfirm,
  onCheck,
}) => (
  <Confirmation
    open
    close={close}
    confirmText={'disable'}
    dismissText={'cancel'}
    onSubmit={onSubmit}
    disableConfirm={disableConfirm}
  >
    <div>
      <h2>Disable Two-Factor Authentication</h2>
      <div className="message">
        You are about to disable two-factor authentication. This compromises your account security, are you sure?
        <div className="confirm-check">
          <input type="checkbox" onChange={onCheck} />
          Yes, I am sure
        </div>
      </div>
    </div>
  </Confirmation>
);

GenConfirmation.propTypes = {
  onCheck: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool.isRequired,
};

GenConfirmation.defaultProps = {
  isDefault: false,
  defaultLabel: '',
};

export default GenConfirmation;
