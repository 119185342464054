
const [BOLD, ITALIC, UNDERLINE, UNORDERED_LIST, ORDERED_LIST] = ['BOLD', 'ITALIC', 'UNDERLINE', 'unordered-list-item', 'ordered-list-item'];

export const STYLE_TYPES = [BOLD, ITALIC, UNDERLINE, UNORDERED_LIST, ORDERED_LIST];

export const INLINE_STYLES = [
  {
    icon: 'format_bold',
    style: BOLD,
  },
  {
    icon: 'format_italic',
    style: ITALIC,
  },
  {
    icon: 'format_underline',
    style: UNDERLINE,
  },
];

export const BLOCK_TYPES = [
  {
    icon: 'format_list_bulleted',
    style: UNORDERED_LIST,
  },
  {
    icon: 'format_list_numbered',
    style: ORDERED_LIST,
  },
];
