import * as types from '../types';

const defaultState = {
  modalType: '',
  modalProps: {},
};

export default (state, action) => {
  switch (action.type) {
    case types.OPEN_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case types.RESET_UI:
    case types.CLOSE_MODAL:
      return defaultState;
    default:
      return state;
  }
};
