import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { hasExportPermissions } from 'helpers/permissions';
import { byId as searchObjById } from 'reducers/searchObjects/selectors';
import { withErrors, completed } from 'reducers/sourceResults/selectors';
import { getResultsById } from 'reducers/results/selectors';
import { byId, allIds } from 'reducers/sources/selectors';
import HeaderMainRow from './headerMainRow';
import HeaderSubRow from './headerSubRow';

const mapStateToProps = (state, props) => {
  const canExport = hasExportPermissions(state.user);
  const { searchId, searchType } = props;

  const searchRef = searchObjById(state, searchId);
  const searchResult = getResultsById(state, searchId);
  const allSourceIds = allIds(state.sources.sources);

  const { dateFilter } = state.ui;
  // it's possible the selected source from search settings was removed from platform
  const totalSourceCount = (searchRef.sources || []).filter(sourceId =>
    allSourceIds.includes(sourceId)).length;

  if (!searchResult) {
    return {
      searchCompleted: searchRef.completed,
      completedSourceCount: 0,
      totalSourceCount,
      search: searchRef,
      dateFilter,
      resultCount: searchRef.last_count,
      exportType: searchType,
      canExport,
    };
  }

  const completedSources = completed(state.sourceResults, searchResult.sourceResults);


  const allSourceResultsFetched = completedSources.length === totalSourceCount;
  const searchCompleted = allSourceResultsFetched || searchRef.completed;
  // could these ever not both be true?

  const resCount = searchResult.sourceResults.map(id => state.sourceResults[id].count).reduce((acc, val) => acc + val, 0);
  const resultCount = !searchRef.completed ? resCount : ((dateFilter.from || dateFilter.to) ? resCount : searchRef.last_count);

  return {
    searchCompleted,
    search: searchRef,
    resultCount,
    totalSourceCount,
    completedSourceCount: completedSources.length,
    dateFilter,
    exportType: searchType,
    canExport,
  };
};

export default withRouter(connect(mapStateToProps)(HeaderMainRow));


const filtersMapStateToProps = (state, props) => {
  const { searchId } = props;
  const search = searchObjById(state, searchId);
  const searchResult = getResultsById(state, searchId);

  if (!searchResult) {
    return {
      search,
      errorsObj: {},
    };
  }

  const sourceResultsWithErrors = withErrors(state.sourceResults, searchResult.sourceResults);
  const sourceIdsWithErrors = sourceResultsWithErrors.map(sourceResult => sourceResult.source);
  const sourcesWithErrors = byId(state.sources.sources, sourceIdsWithErrors);

  const errorsObj = sourceResultsWithErrors.reduce((errors, sourceRes) => {
    const key = sourcesWithErrors.find(source => source.id === sourceRes.source).label;
    errors[key] = sourceRes.error_message;
    return errors;
  }, {});

  return {
    search,
    errorsObj,
  };
};

export const HeaderSubRowContainer = connect(filtersMapStateToProps)(HeaderSubRow);
