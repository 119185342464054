import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createField, editField, removeField } from 'reducers/customFields/actions';
import Section from 'components/shared/settingsPage/section';
import OpenItemWrapper, { CONFIRMATION_TYPES } from 'components/shared/openItemWrapper';
import SettingsTable from 'components/shared/settingsTable';
import EditableItem from 'components/shared/editableItem';
import EditItem from 'components/shared/editItem';
import Confirmation from 'components/settings/confirmationShared';

// eslint-disable-next-line no-shadow
const CustomFieldsManager = ({ fields, createField, editField, removeField }) => {
  const charLimit = 24;
  const findErrors = (field) => {
    const { label } = field;
    if (!label.trim()) {
      return 'Please enter a label.';
    }
    if (label.length > charLimit) {
      return `Labels must be less than ${charLimit} characters long.`;
    }
    const notUnique = fields.some(f => f.id !== field.id && f.label === label);
    if (notUnique) {
      return 'A field with this label already exists.';
    }
  };
  // to handle max field errors
  const errorCb = (err) => {
    if (err.response && err.response.data && typeof err.response.data === 'string') {
      return err.response.data;
    }
    return null;
  };

  return (
    <Section
      header="Manage Custom Fields"
      helpText={<p>Define custom fields for monitored individuals. Custom fields can be used to assign unique values to the individuals you’re monitoring in the platform.</p>}
    >
      <OpenItemWrapper
        findErrors={findErrors}
        createFunc={createField}
        updateFunc={editField}
        deleteFunc={removeField}
        entityName={'field'}
        errorCb={errorCb}
        render={({
          openField,
          toggleEditing,
          onFieldChange,
          onCreate,
          onUpdate,
          onDelete,
          errors,
          showConfirmation,
          toggleConfirmationType,
          toggleConfirmation,
          confirmationType,
        }) => (
          <div>
            {
              fields.length || openField ?
                <SettingsTable
                  leftColumn={'Label'}
                  rightColumns={['Edit']}
                >
                  {
                    fields.map(field => (
                      <EditableItem
                        key={field.id}
                        editing={openField && openField.id === field.id}
                        value={openField && openField.id === field.id ? openField.label : field.label}
                        toggleEditing={() => toggleEditing(field)}
                        onClose={() => toggleEditing(null)}
                        onFieldChange={(e) => { onFieldChange(e.target.value, 'label'); }}
                        onSubmit={() => { toggleConfirmationType(CONFIRMATION_TYPES.update)(field); }}
                        onDelete={() => { toggleConfirmationType(CONFIRMATION_TYPES.delete, false)(field); }}
                        errors={errors || ''}
                        confirmationShowing={Boolean(showConfirmation)}
                      />
                    ))
                  }
                </SettingsTable>
                : ''
            }
            <div className="table-wrapper">
              <div className="table-container adding">
                { openField && !openField.id ?
                  <div className="indent">
                    <EditItem
                      value={openField.label}
                      buttonText={'Add'}
                      showDelete={false}
                      onFieldChange={(e) => { onFieldChange(e.target.value, 'label'); }}
                      onClose={() => toggleEditing(null)}
                      errors={errors || ''}
                      onSubmit={onCreate}
                      confirmationShowing={Boolean(showConfirmation)}
                    />
                  </div>
                  :
                  <span className="button-wrapper indent">
                    <button
                      onClick={() => { toggleEditing({ label: '' }); }}
                      className="text-button uppercase add-field-button"
                    >
                      + Add custom field
                    </button>
                  </span>
                }
              </div>
            </div>
            {showConfirmation ?
              <Confirmation
                open
                close={() => { toggleConfirmation(null); }}
                confirmText={confirmationType}
                dismissText={'Cancel'}
                onSubmit={confirmationType === CONFIRMATION_TYPES.delete ?
                  onDelete(showConfirmation.id)
                  : onUpdate
                }
              >
                <div>
                  <h2>{confirmationType} Field</h2>
                  <div className="message">
                    {
                      confirmationType === CONFIRMATION_TYPES.delete ?
                        `This will remove the field "${showConfirmation.label}" and its data from all monitored individuals. ` :
                        `All of the data currently labeled "${showConfirmation.label}" will now be labeled "${openField.label}". `
                    }
                    <span>
                      Are you sure you would like to {confirmationType.toLowerCase()} the field <strong>{`"${showConfirmation.label}"`}</strong>?
                    </span>
                  </div>
                </div>
              </Confirmation> : ''
            }
          </div>
        )
        }
      />
    </Section>
  );
};

CustomFieldsManager.propTypes = {
  fields: PropTypes.array.isRequired,
  createField: PropTypes.func.isRequired,
  editField: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  fields: Object.values(state.customFields),
});

export default connect(
  mapStateToProps,
  {
    createField,
    editField,
    removeField,
  })(CustomFieldsManager);
