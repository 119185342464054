import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MultiRequestCanceler } from 'utils/apiUtil';
import { resetUI, toggleContribution } from 'reducers/ui/actions';
import { clearResults } from 'reducers/results/actions';
import JurisdictionResults from './jurisdictionView';
import JurisdictionForm from './jurisdictionSearchForm';
import WorkFlowUI from '../workFlowUI';

class JurisdictionTabBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedJur: null,
    };
    this.onJurisdictionSelect = this.onJurisdictionSelect.bind(this);
  }

  componentWillUnmount() {
    MultiRequestCanceler.cancelAllRequests();
    this.props.resetUI();
    this.props.clearResults();
  }

  onJurisdictionSelect(jurisdiction) {
    this.props.toggleContribution(this.props.selectedContribs, false);
    this.setState({ selectedJur: jurisdiction });
  }


  render() {
    const jurisdiction = this.state.selectedJur;
    return (
      <React.Fragment>
        <JurisdictionForm
          jurisdiction={jurisdiction}
          onJurisdictionSelect={this.onJurisdictionSelect}
        />
        <WorkFlowUI>
          { jurisdiction && <JurisdictionResults
            search={jurisdiction}
          /> }
          { !jurisdiction ? <div className="default"><div>Select a jurisdiction from the dropdown to see records for all monitored people and companies within that jurisdiction.</div></div> : ''}
        </WorkFlowUI>
      </React.Fragment>

    );
  }
}

const mapStateToProps = ({ ui: { selectedContribs } }) => ({
  selectedContribs,
});


JurisdictionTabBody.propTypes = {
  clearResults: PropTypes.func.isRequired,
  toggleContribution: PropTypes.func.isRequired,
  resetUI: PropTypes.func.isRequired,
  selectedContribs: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  {
    clearResults,
    toggleContribution,
    resetUI,
  },
)(JurisdictionTabBody);

