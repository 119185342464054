import React from 'react';
import PropTypes from 'prop-types';
import ToolTip from 'components/toolTip';
import { CATEGORY_ICON_MAP } from 'utils/constants';

export const CategoryIcon = ({ sourceCat }) => (
  <div className="record-icon">
    <div>
      <i className={`icon-${CATEGORY_ICON_MAP[sourceCat]}`} />
      <ToolTip position="right">
        {sourceCat}
      </ToolTip>
    </div>
  </div>
);

CategoryIcon.propTypes = {
  sourceCat: PropTypes.string.isRequired,
};
