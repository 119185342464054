import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';


const IconTextButton = ({
  disabled, icon, buttonHandler, buttonText, toolTipText, className,
}) => (
  <Tippy
    content={toolTipText}
    disabled={!toolTipText}
  >
    <div className="button-wrapper">
      <button className={`icon-text-button ${className}`} type="button" onClick={buttonHandler} disabled={disabled}>
        <i className="material-icons">{icon}</i>
        <span>{buttonText}</span>
      </button>
    </div>
  </Tippy>
);

IconTextButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  buttonHandler: PropTypes.func,
  buttonText: PropTypes.string,
  toolTipText: PropTypes.string,
  className: PropTypes.string,
};

IconTextButton.defaultProps = {
  disabled: false,
  icon: '',
  toolTipText: null,
  className: '',
  buttonText: '',
  buttonHandler: () => {},
};

export default IconTextButton;
