import api from '../../api';
import * as types from './types';

const loadTags = data => ({
  data,
  type: types.LOAD_TAGS,
});

const addTag = data => ({
  data,
  type: types.ADD_TAG,
});

const updateTag = data => ({
  data,
  type: types.UPDATE_TAG,
});

const deleteTag = id => ({
  id,
  type: types.DELETE_TAG,
});


export const fetchTags = () => dispatch =>
  api.get('/tags/')
    .then(({ data }) => {
      dispatch(loadTags(data));
      return data;
    });

export const createTag = newTag => dispatch =>
  api.post('/tags/', newTag)
    .then(({ data }) => {
      dispatch(addTag(data));
      return data;
    });

export const editTag = newTag => dispatch =>
  api.put(`/tags/${newTag.id}/`, newTag)
    .then(({ data }) => {
      dispatch(updateTag(data));
      return data;
    });

export const removeTag = id => dispatch =>
  api.delete(`/tags/${id}/`)
    .then(({ data }) => {
      dispatch(deleteTag(id));
      return data;
    });

