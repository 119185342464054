import { useState, useEffect } from 'react';
import api from 'api';


const useAutosave = ({ formId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [autoSavedState, setAutoSavedState] = useState({});
  const apiEndpoint = `/forms/form/${formId}/autosave/`;

  useEffect(() => {
    api.get(apiEndpoint, {
      // Accept status 200 or 404
      validateStatus: status => status === 200 || status === 404
    }).then(resp => {
      setAutoSavedState(resp.data.state || {});
      setIsInitialized(true);
    });
  }, [apiEndpoint])

  const persistState = async (state) => {
    if (isInitialized) {
      setIsLoading(true);
      try {
        const resp = await api.post(apiEndpoint, { state });
        setAutoSavedState(resp.data.state);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const purgeSaves = async () => {
    setIsLoading(true);
    try {
      await api.delete(apiEndpoint);
    } finally {
      setIsLoading(false);
    }
  }

  return { autoSavedState, persistState, isInitialized, isLoading, purgeSaves }
}

export { useAutosave }
