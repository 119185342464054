import React from 'react';
import moment from 'moment';
import api from 'api';
import { PATH_NAMES, PATHS } from 'utils/constants';
import {
  FREQUENCY_TYPES,
  RECIPIENT_TYPES,
  SCHEDULE_TYPES,
  BLANK_TEMPLATE,
} from '../constants';
import { CertificationForm } from './CertificationForm';


const createCertification = template => api.post('/attestations/templates', template);

const getInitialValues = (emailTemplates, forms) => {
  const template = Object.values(emailTemplates)[0] || BLANK_TEMPLATE;
  const form = forms[0];

  return {
    name: '',
    form: form ? form.id : null,
    frequency: FREQUENCY_TYPES[0].value,
    schedule: SCHEDULE_TYPES[0].value,
    at_date: moment().add(1, 'days').format('YYYY-MM-DD'),
    start_date: '',
    end_date: '',
    status_filter: [],
    employee_selector: RECIPIENT_TYPES[0].value,
    tag_list: [],
    employee_list: [],
    email_template: template.id,
    email_subject: template.subject,
    email_body: template.body,
  };
};


const CreateCertification = () => (
  <CertificationForm
    getInitialValues={getInitialValues}
    submitFunc={createCertification}
    cancelUrl={PATHS[PATH_NAMES.certifications]}
  />
);

export { CreateCertification };
