import React from 'react';
import PropTypes from 'prop-types';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import EditItem from './editItem';

const EditableItem = ({
  value,
  editing,
  toggleEditing,
  onClose,
  onSubmit,
  onDelete,
  showDelete,
  onFieldChange,
  errors,
  confirmationShowing,
  preEditCells,
  postEditCells,
  buttonText,
  disabled,
  disabledText,
}) => (
  editing
    ? (
      <EditItem
        value={value}
        showDelete={showDelete}
        buttonText={buttonText}
        onClose={onClose}
        onFieldChange={onFieldChange}
        onSubmit={onSubmit}
        onDelete={onDelete}
        errors={errors}
        confirmationShowing={confirmationShowing}
        extraCells={postEditCells}
        extraChildren={preEditCells}
      />
    )
    : (
      <div className={`table-row${disabled ? ' disabled' : ''}`} role="row">
        <div className="table-cell">
          <span>
            {value}
          </span>
        </div>
        { preEditCells }
        <div className="text-center table-cell">
          <IconTextButton
            icon="edit"
            buttonHandler={toggleEditing}
            buttonText=""
            toolTipText={disabled ? disabledText : null}
            className="edit-button"
            disabled={disabled}
          />
        </div>
        { postEditCells }
      </div>
    )
);

EditableItem.propTypes = {
  value: PropTypes.string.isRequired,
  editing: PropTypes.bool,
  toggleEditing: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
  errors: PropTypes.string,
  confirmationShowing: PropTypes.bool,
  preEditCells: PropTypes.object,
  postEditCells: PropTypes.object,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
};

EditableItem.defaultProps = {
  onDelete: () => { },
  showDelete: true,
  editing: false,
  confirmationShowing: false,
  preEditCells: null,
  postEditCells: null,
  buttonText: 'Update',
  errors: '',
  disabled: false,
  disabledText: '',
};

export default EditableItem;
