import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import CertificationPreclearanceRequests from 'components/Certifications/CertificationSurvey/CertificationPreclearanceRequests';
import { SAMPLE_DATA } from 'components/Certifications/CertificationSurvey/consts';
import { UNATTESTED_PC_COLS } from 'components/Certifications/constants';
import IconTextButton from 'components/monitoringForm/iconTextButton';


const PlaceholderTable = () => (
  <CertificationPreclearanceRequests
    startDate={moment().format('YYYY-MM-DD')}
    endDate={moment().format('YYYY-MM-DD')}
    pcRequests={SAMPLE_DATA}
    columns={UNATTESTED_PC_COLS}
  />
);

const PreclearanceModule = ({ inEditMode, removeField }) => (
  inEditMode
    ? (
      <div>
        <div className="edit-question-toolbar-wrapper">
          <div className="flex-header edit-question-toolbar">
            <div className="toolbar-right">
              <IconTextButton
                icon="delete"
                buttonText="delete"
                buttonHandler={removeField}
              />
            </div>
          </div>
        </div>
        <PlaceholderTable />
      </div>
    )
    : <PlaceholderTable />
);

PreclearanceModule.propTypes = {
  inEditMode: PropTypes.bool.isRequired,
  removeField: PropTypes.func.isRequired,
};

export default PreclearanceModule;
