import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createMatchAction, editMatchAction, removeMatchAction } from 'reducers/preclearance/matchActions/actions';
import Section from 'components/shared/settingsPage/section';
import OpenItemWrapper from 'components/shared/openItemWrapper';
import Confirmation from 'components/settings/confirmationShared';
import { ActionItem, ActionEditItem } from '../actionItems/actionMatchItem';
import { MatchesStatusSentence } from '../actionItems/actionSentence';

const findErrors = (action) => {
  const requiredFields = ['record_status', 'request_status'];
  const missingFields = requiredFields.filter(f => !action[f]);
  return missingFields.length > 0 ? 'Please fill out all fields' : null;
};

function MatchedStatusActions({
  matchActions, preStatuses, statuses, createMatchAction, editMatchAction, removeMatchAction }) {
  return (
    <Section
      subHeader={'Automated Matching Actions'}
      helpText={<p>Assign a preclearance status to a corresponding monitored status. When a preclearance request and a monitored record are matched, the status of the monitored record will be updated based on rules below.</p>}
    >
      <OpenItemWrapper
        findErrors={findErrors}
        createFunc={createMatchAction}
        updateFunc={editMatchAction}
        deleteFunc={removeMatchAction}
        entityName={'action'}
        render={({
          toggleEditing,
          onFieldChange,
          openField,
          showConfirmation,
          toggleConfirmation,
          errors,
          onCreate,
          onDelete,
          onUpdate,
          onToggle,
        }) => {
          return (
            <div className="auto-actions-wrapper">
              {Object.values(matchActions).map((action) => {
                const editing = Boolean(openField && openField.id === action.id);
                const ActionComponent = editing ? ActionEditItem : ActionItem;
                return (
                  <ActionComponent
                    key={action.id}
                    action={editing ? openField : action}
                    recordStatuses={statuses}
                    requestStatuses={preStatuses}
                    toggleEditing={toggleEditing}
                    onToggleActive={() => onToggle(action, 'active')}
                    onDelete={toggleConfirmation}
                    onSubmit={onUpdate}
                    onFieldChange={onFieldChange}
                    errors={errors}
                    buttonText={'Update'}
                    autoActions={matchActions}
                    onClose={() => { toggleEditing(null); }}
                  />);
              })}
              {openField && !openField.id ?
                <ActionEditItem
                  action={openField}
                  recordStatuses={statuses}
                  requestStatuses={preStatuses}
                  autoActions={matchActions}
                  buttonText={'Add'}
                  onSubmit={onCreate}
                  onFieldChange={onFieldChange}
                  onClose={() => { toggleEditing(null); }}
                  errors={errors}
                />
                :
                <span className="button-wrapper">
                  <button
                    onClick={() => { toggleEditing({ record_status: null, request_status: null, active: true }); }}
                    className="text-button uppercase add-field-button"
                  >
                    + Add action
                  </button>
                </span>
              }
              {showConfirmation ?
                <Confirmation
                  open
                  close={() => { toggleConfirmation(null); }}
                  confirmText={'Delete'}
                  dismissText={'Cancel'}
                  onSubmit={onDelete(matchActions[showConfirmation].id)}
                >
                  <div>
                    <h2>Delete Action</h2>
                    <div className="message">
                      Are you sure you would like to delete this automated action?
                      <ul>
                        <li>
                          <MatchesStatusSentence
                            recordStatus={statuses[matchActions[showConfirmation].record_status] ? statuses[matchActions[showConfirmation].record_status].label : null}
                            requestStatus={preStatuses[matchActions[showConfirmation].request_status] ? preStatuses[matchActions[showConfirmation].request_status].label : null}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Confirmation> : ''
              }
            </div>
          );
        }}
      />

    </Section>
  );
}

const mapStateToProps = ({ preclearance, statuses }) => ({
  preStatuses: preclearance.statuses,
  statuses,
  matchActions: preclearance.matchActions,
});

export default connect(
  mapStateToProps,
  {
    createMatchAction,
    editMatchAction,
    removeMatchAction,
  })(MatchedStatusActions);

MatchedStatusActions.propTypes = {
  preStatuses: PropTypes.object.isRequired,
  statuses: PropTypes.object.isRequired,
  matchActions: PropTypes.object.isRequired,
  createMatchAction: PropTypes.func.isRequired,
  editMatchAction: PropTypes.func.isRequired,
  removeMatchAction: PropTypes.func.isRequired,
};
