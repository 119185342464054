import React from 'react';
import PropTypes from 'prop-types';

export default function SettingsPage({ sections, header }) {
  return (
    <React.Fragment>
      {header && <h1>{header}</h1>}
      {sections.map((Section, i) => (
        <React.Fragment key={i}>
          <Section />
          {i !== sections.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

SettingsPage.propTypes = {
  sections: PropTypes.array.isRequired,
  header: PropTypes.string,
};

SettingsPage.defaultProps = {
  header: '',
};
