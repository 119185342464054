import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {
  FIELD_TYPES,
  TEXT_AREA,
  RADIO,
  CHECKBOX,
} from '../../consts';

import {
  TextField,
  TextAreaField,
  RadioField,
  CheckboxField,
} from '../Fields';


const SchemaQuestion = ({
  id,
  label,
  inputType,
  required,
  ...props
}) => {
  let Field;

  switch (inputType) {
    case TEXT_AREA:
      Field = TextAreaField;
      break;
    case RADIO:
      Field = RadioField;
      break;
    case CHECKBOX:
      Field = CheckboxField;
      break;
    default:
      Field = TextField;
  }


  const labelString = `${label} ${required ? '<span class="required-asterisk">*</span>' : ''}`;
  const cleanLabel = DOMPurify.sanitize(labelString);

  return (
    <div>
      <div className="md-question">
        <div
          htmlFor={id}
          dangerouslySetInnerHTML={{
            __html: cleanLabel,
          }}
        />
      </div>
      <Field
        className="preview-input"
        {...props}
      />
    </div>
  );
};

SchemaQuestion.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  label: PropTypes.string,
  inputType: PropTypes.oneOf(Object.keys(FIELD_TYPES)).isRequired,
  required: PropTypes.bool,
};

SchemaQuestion.defaultProps = {
  required: false,
  label: '',
};

export default SchemaQuestion;
