import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { PATHS, PATH_NAMES } from 'utils/constants';
import Loading from 'components/loading';
import EmptyState from 'components/shared/emptyState';
import Table from 'components/shared/SimpleVirtualTable';
import CertificationTableHeader from './CertificationTableHeader';
import { SENT, SCHEDULED, RECURRING } from '../constants';
import useCertifications from './useCertifications';


const columns = [
  {
    label: 'name',
  },
  {
    label: 'certification period',
  },
  {
    label: 'status',
  },
  {
    label: 'recipients',
  },
  {
    label: 'not completed',
  },
  {
    label: 'percent complete',
  },
];

function calcPercentComplete(total, notComplete) {
  if (!total) {
    return '--';
  }
  const complete = total - notComplete;
  return Math.floor((complete / total) * 100);
}

export const ErrorTooltip = () => (
  <div>
    <Tippy
      content="This certification cannot be sent because the form associated with it has been deleted."
      interactive
    >
      <div className="missing-data">
        <i className="material-icons">
          error_outline
        </i>
      </div>
    </Tippy>
  </div>
);

function rowRenderer(key, style, rowData) {
  const {
    id,
    name,
    recipient_count: recipientCount,
    incomplete_count: incompleteCount,
    start_date: startDate,
    end_date: endDate,
    form,
  } = rowData;

  let status;
  let date;
  let frequency;
  if (!rowData.schedule) {
    status = SENT;
    date = rowData.sent_at;
  } else if (rowData.schedule.is_recurring) {
    status = RECURRING;
    frequency = rowData.schedule.frequency;
  } else {
    status = SCHEDULED;
    date = rowData.schedule.at_date;
  }

  const link = !rowData.schedule ? `${PATHS[PATH_NAMES.certifications]}/${id}` : `${PATHS[PATH_NAMES.certification_templates]}/${id}`;

  const cannotSend = !form && rowData.schedule;

  return (
    <div key={key} style={style} className="certification-wrapper-row">
      <div className="certification-row">
        <div className="certification-cell horizontal-cell">
          {(
          cannotSend
          && (
          <ErrorTooltip />
          )
        )}
          <Link to={link} className="name-link">
            <span>
              {name}
            </span>
          </Link>
        </div>
        <div className="certification-cell">
          { startDate && endDate && `${moment(startDate).format('ll')} - ${moment(endDate).format('ll')}`}
        </div>
        <div className="certification-cell">
          <div className="horizontal-cell certification-schedule">
            {(
              cannotSend
              && (
              <ErrorTooltip />
              )
            )}
            <div className="uppercase certification-status">
              {status}
            </div>
          </div>
          {frequency || (date ? moment(date).format('ll') : '--')}
        </div>
        <div className="certification-cell">
          { (recipientCount && recipientCount.overrideLocaleString()) || '--' }
        </div>
        <div className="certification-cell">
          { incompleteCount !== undefined ? incompleteCount : '--' }
        </div>
        <div className="certification-cell">
          { incompleteCount !== undefined ? `${calcPercentComplete(recipientCount, incompleteCount)}%` : '--' }
        </div>
      </div>
    </div>
  );
}


function noRowsRenderer() {
  return (
    <EmptyState
      messageHeading="No certifications yet."
      message=""
    />
  );
}


function CertificationsTable() {
  const { templates, certifications, loading } = useCertifications();
  const rows = [...certifications, ...templates];

  return (
    <div className="dashboard-card">
      {
        loading
          ? (
            <div className="flex-load-wrapper">
              <Loading />
            </div>
          )
          : (
            <div>
              <CertificationTableHeader
                columns={columns}
              />
              <Table
                rows={rows}
                rowRenderer={({ index, key, style }) => (rowRenderer(key, style, rows[index]))}
                noRowsRenderer={noRowsRenderer}
                rowHeight={66}
              />
            </div>
          )
      }
    </div>
  );
}

export default CertificationsTable;
