import * as fromById from './byId';
import * as fromAllIds from './allIds';


export const getEntities = (state) => {
  const ids = fromAllIds.getIds(state.entities.allIds);
  return ids.map(id => fromById.getEntity(state.entities.byId, id));
};

export const byId = (state, id) => (state.entities.byId[id]);
