import React from 'react';
import PropTypes from 'prop-types';


export const CheckboxValue = ({ value }) => {
  const icon = value ? 'check_box' : 'check_box_outline_blank';
  return (
    <span className="center-align">
      <i className="material-icons checkbox-cell-icon">
        {icon}
      </i>
    </span>
  );
};


CheckboxValue.propTypes = {
  value: PropTypes.bool.isRequired,
};
