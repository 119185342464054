import React from 'react';
import PropTypes from 'prop-types';

export default function FilterSection({ filterTitle, children }) {
  return (
    <div className="filter-section">
      <div className="filter-title">{filterTitle}</div>
      {children}
    </div>
  );
}

FilterSection.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};
