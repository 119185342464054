import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalUI from '../modal/modalUI';

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onEnterKey = this.onEnterKey.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onEnterKey);
  }


  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEnterKey);
  }

  onEnterKey(e) {
    e.preventDefault();
    if (e.key === 'Enter') {
      this.props.onSubmit();
    }
  }


  render() {
    const { open, close, children, dismissText, onSubmit, confirmText, disableConfirm, maxWidth } = this.props;
    return (
      <ModalUI
        open={open}
        close={close}
        center
        maxWidth={maxWidth}
      >
        <div>
          { children }
          <div className="button-wrapper">
            <button
              onClick={close}
              className="text-button uppercase"
              type="button"
            >
              {dismissText}
            </button>
            <button
              disabled={disableConfirm}
              className="orange-button"
              onClick={onSubmit}
              type="button"
            >
              {confirmText}
            </button>
          </div>
        </div>
      </ModalUI>
    );
  }
}

Confirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  dismissText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool,
  maxWidth: PropTypes.string,
};

Confirmation.defaultProps = {
  disableConfirm: false,
  maxWidth: null,
};

export default Confirmation;
