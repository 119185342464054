import React from 'react';
import PropTypes from 'prop-types';
import { CustomDropdown } from '../../dropdown';
import {
  DROPDOWN_HEIGHT,
} from './constants';


const Dropdown = ({
  onOptionClick, options, className, value,
}) => (
  <CustomDropdown render={({ isOpen, openDropdown, closeDropdown }) => (
    <div
      className={`preclearance-dropdown ${className} ${isOpen ? 'open' : ''}`}
    >
      <div
        className="dropdown-value"
        onClick={isOpen ? closeDropdown : openDropdown}
      >
        <span>{value}</span>
        <i
          className="material-icons"
        >
          {isOpen ? 'expand_less' : 'expand_more'}
        </i>
      </div>
      {
        isOpen
          ? (
            <div className="dropdown-options" style={{ maxHeight: DROPDOWN_HEIGHT }}>
              {
              options.map(([val, label], idx) => (
                <li
                  key={idx}
                  onClick={() => { onOptionClick(val); closeDropdown(); }}
                >
                  {label}
                </li>
              ))
            }
            </div>
          )
          : null
      }
    </div>
  )}
  />
);

Dropdown.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  className: '',
};

export default Dropdown;
