import '@babel/polyfill';
import 'core-js/modules/es.promise.all-settled';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from "@sentry/tracing";
import 'react-dates/initialize';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import configureStore, { history } from 'store';
import App from 'App';
import NotAuthorized from 'containers/notauthorized';
import NotFound from 'containers/notfound';
import Confirmation from 'components/dashboard/confirmation';
import { PreclearanceSurvey } from './components/PreclearanceSurvey';
import { CertificationSurvey } from './components/Certifications';
import extendBasePrototype from './helpers/extensions';
import './App.scss';
import version from './version';
import { PATHS, PATH_NAMES, APP_BASE_PATH } from './utils/constants';
import { LogRocketWrapper } from './components/LogRocketWrapper';


extendBasePrototype(window);

function startApp() {
  const sentryDsn =
    process.env.NODE_ENV === 'production' ? window.REACT_APP_SENTRY_DSN : '';
  const sentryEnv = window.REACT_APP_SENTRY_ENV || 'dev';

  Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnv.toUpperCase(),
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
  });

  ['REACT_APP_SENTRY_DSN', 'REACT_APP_SENTRY_ENV'].forEach(sentryVar => {
    delete window[sentryVar];
  });

  const rootEl = document.getElementById('react-root');

  if (rootEl) {
    const store = configureStore();
    ReactDOM.render(
      <Provider store={store} history={history}>
        <Router history={history}>
          <Switch>
            <Route path={APP_BASE_PATH}>
              <App />
            </Route>
            <Route path={PATHS[PATH_NAMES.preclearance_survey]}>
              <LogRocketWrapper>
                <PreclearanceSurvey />
              </LogRocketWrapper>
            </Route>
            <Route path={PATHS[PATH_NAMES.certification_survey]}>
              <LogRocketWrapper>
                <CertificationSurvey />
              </LogRocketWrapper>
            </Route>
            <Route path={PATHS[PATH_NAMES.not_authorized]}>
              <LogRocketWrapper>
                <NotAuthorized />
              </LogRocketWrapper>
            </Route>
            <Route path={PATHS[PATH_NAMES.not_found]}>
              <LogRocketWrapper>
                <NotFound />
              </LogRocketWrapper>
            </Route>
          </Switch>
          <Confirmation />
        </Router>
      </Provider>,
      rootEl,
    );
    if (module.hot) {
      module.hot.accept();
    }
  }
}

window.P2P = {
  version,
};

document.addEventListener('DOMContentLoaded', startApp);
