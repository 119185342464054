import * as types from '../types';

const initialState = {};

const templates = (state = initialState, action) => {
  switch (action.type) {
    case types.RECEIVE_INITIAL_SETTINGS_DATA: {
      return action.templates;
    }
    case types.LOAD_TEMPLATES: {
      const templateState = {};
      action.data.reduce((_allTemplates, template) => {
        templateState[template.id] = template;
        return templateState;
      }, {});
      return templateState;
    }
    case types.RECEIVE_TEMPLATE: {
      const templatesCopy = { ...state };
      templatesCopy[action.data.id] = action.data;
      return templatesCopy;
    }
    case types.DELETE_TEMPLATE: {
      const templatesCopy = { ...state };
      delete templatesCopy[action.id];
      return templatesCopy;
    }
    default: {
      return state;
    }
  }
};

export default templates;
