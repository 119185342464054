import React, { useEffect, useState, useReducer } from 'react';
import { toast } from 'react-toastify';
import api from 'api';
import useCancellablePromise from 'hooks/useCancellablePromise';
import Loading from 'components/loading';
import EmptyState from 'components/shared/emptyState';
import useCertifications from 'components/Certifications/CertificationsList/useCertifications';
import CertificationTableHeader from '../../CertificationsList/CertificationTableHeader';
import TableRow from './TableRow';
import DeleteConfirmation from './DeleteForm';
import reducer, {
  LOAD_FORMS, ADD_FORM, DELETE_FORM, getForms, getForm,
} from './formsReducer';

const columns = [
  { label: 'Name' },
  { label: 'Modified' },
  { label: 'Edit' },
  { label: 'Delete' },
];

const fetchForms = () => api.get('/forms/form/');
const deleteForm = (id) => api.delete(`/forms/form/${id}/`);

const initState = {
  loading: true,
  allForms: [],
  formsById: {},
};

const CertificationFormsTable = () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [formToDelete, setFormToDelete] = useState(null); // id of form being deleted
  const { templates: certificationTemplates, loading: certificationsLoading } = useCertifications();
  const { cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    let didCancel = false;
    fetchForms().then(({ data }) => {
      if (!didCancel) {
        dispatch({ type: LOAD_FORMS, forms: data });
      }
    }).catch((err) => {
      toast('Something went wrong.');
      throw err;
    });
    return () => {
      didCancel = true;
    };
  }, []);

  const handleDelete = () => {
    setFormToDelete(null);
    const form = getForm(state, formToDelete);
    dispatch({ type: DELETE_FORM, id: formToDelete });
    cancellablePromise(deleteForm(formToDelete)).then(() => {
      toast('Form deleted.');
    }).catch((err) => {
      if (!err.isCanceled) {
        // if something went wrong put the form back
        dispatch({ type: ADD_FORM, form });
        toast('Something went wrong.');
        throw err;
      }
    });
  };


  const forms = getForms(state);

  return (
    <div className="dashboard-card">
      {
        state.loading || certificationsLoading
          ? (
            <div className="flex-load-wrapper">
              <Loading />
            </div>
          )
          : (
            <div>
              <CertificationTableHeader
                columns={columns}
              />
              {
                forms.length
                  ? forms.map(({ name, id, modified }) => (
                    <TableRow
                      key={id}
                      name={name}
                      id={id}
                      handleDelete={() => (setFormToDelete(id))}
                      modified={modified}
                    />
                  ))
                  : (
                    <EmptyState
                      messageHeading="No forms yet."
                      message=""
                    />
                  )
              }
              {
                formToDelete && (
                  <DeleteConfirmation
                    onDelete={handleDelete}
                    onCancel={() => (setFormToDelete(null))}
                    form={getForm(state, formToDelete)}
                    certificationTemplates={certificationTemplates}
                  />
                )
              }
            </div>
          )
        }

    </div>
  );
};

export default CertificationFormsTable;
