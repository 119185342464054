import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { SingleDatePicker } from 'react-dates';
import PropTypes from 'prop-types';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

import { FORM_ELEMENT } from '../../constants';

const DateField = ({ dateFormat, ...props }) => {
    const [field, meta, helpers] = useField(props),
        [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (!field.value) {
            helpers.setValue(moment().format(dateFormat));
        }
    }, [dateFormat]);

    const handleDateChange = (date) => {
        if (date) {
            helpers.setValue(date.format(dateFormat));
        } else {
            helpers.setValue(null);
        }
    };

    return (
        <SingleDatePicker
            date={field.value ? moment(field.value) : null}
            onDateChange={handleDateChange}
            focused={isFocused}
            onFocusChange={({ focused }) => setIsFocused(focused)}
            id={`${field.name}`}
            hideKeyboardShortcutsPanel={true}
            isOutsideRange={() => false}
            readOnly={props.readOnly}
            placeholder="MM/DD/YYYY"
            block
            noBorder
        />
    );
};

DateField.propTypes = {
    ...FORM_ELEMENT,
    dateFormat: PropTypes.string,
};

DateField.defaultProps = {
    dateFormat: 'YYYY-MM-DD',
};

export { DateField };
