import React from 'react';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import useWarnings from 'components/shared/SchemaForm/hooks/useWarnings';
import { validateZipcode } from 'utils/zipcodeValidation';
import { zipcodes } from './consts';


function ZipcodeFields() {
  const { warnings, validateFunc, onRemoveCallback } = useWarnings(validateZipcode, 500);

  return (
    <SchemaField
      name={zipcodes}
      id={zipcodes}
      label={'postal code'}
      addButtonText={'postal code'}
      className={'form-input'}
      limit={5}
      fieldType={'ArrayField'}
      minFields={1}
      onChangeCallback={validateFunc}
      warnings={warnings}
      onRemoveCallback={onRemoveCallback}
    />
  );
}

export default ZipcodeFields;
