import { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

function useWarnings(validationFunc, debounceTime) {
  const [warnings, setWarnings] = useState([]);

  const validateFunc = (e, index) => {
    const val = e.target.value;
    const warningsCopy = [...warnings];
    validationFunc(val).then((resp) => {
      warningsCopy[index] = resp;
      setWarnings(warningsCopy);
    });
  };

  const onRemoveCallback = (index) => {
    setWarnings(prevWarnings => prevWarnings.filter((_w, i) => i !== index));
  };

  const deboucedValidation = useCallback(debounce(validateFunc, debounceTime), [warnings]);

  return ({
    warnings,
    validateFunc: deboucedValidation,
    onRemoveCallback,
  });
}

export default useWarnings;
