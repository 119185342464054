import React from 'react';
import PropTypes from 'prop-types';
import { SelectValue } from './SelectValue';


export const StatusValue = ({ value, choices }) => (
  <div>
    <SelectValue
      value={value}
      choices={choices}
    />
    <i className="material-icons">expand_more</i>
  </div>
);

StatusValue.propTypes = {
  value: PropTypes.number.isRequired,
  choices: PropTypes.array.isRequired,
};
