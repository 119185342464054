import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TagsManager from 'components/shared/tags/tagsManager';
import IconTextButton from 'components/monitoringForm/iconTextButton';


export const NewEmployeeHeading = ({
  headerType, updateTags, selectedTags, closeUrl,
}) => (
  <TagsManager
    key="new-form"
    creating
    updateTags={updateTags}
    selectedTags={selectedTags}
    render={({ tagDropdown, appliedTags }) => (
      <div className="form-heading">
        <div className="results-name-row results-header">
          <div className="header-wrapper">
            <Link className="text-button" to={closeUrl}>
              <i className="material-icons">close</i>
            </Link>
            <span>
              Add New
              {' '}
              {headerType}
            </span>
            {appliedTags}
          </div>
          <div className="results-actions">
            {tagDropdown}
          </div>
        </div>
      </div>
    )}
  />
);


NewEmployeeHeading.propTypes = {
  headerType: PropTypes.string.isRequired,
  updateTags: PropTypes.func,
  selectedTags: PropTypes.array,
  closeUrl: PropTypes.string.isRequired,
};

NewEmployeeHeading.defaultProps = {
  updateTags: () => {},
  selectedTags: [],
};


export const EditEmployeeHeading = ({
  headerType,
  closeUrl,
  employeeObj,
  toggleConfirmDelete,
  openArchiveModal,
  restoreEmployee,
}) => {
  const {
    id,
    tags,
    archived,
    pending_archival_date: pendingArchiveDate,
    pending_delete_date: pendingDeleteDate,
  } = employeeObj;
  return (
    <TagsManager
      key={`edit-${id}`}
      selectedTags={tags}
      id={id}
      disabled={archived}
      render={({ tagDropdown, appliedTags }) => (
        <div className="form-heading">
          <div className="results-name-row results-header">
            <div className="header-wrapper">
              <Link className="text-button" to={closeUrl}>
                <i className="material-icons">close</i>
              </Link>
              <span>
                Edit
                {' '}
                {headerType}
              </span>
              {appliedTags}
            </div>
            <div className="results-actions">
              {tagDropdown}
              {
                <IconTextButton
                  icon={`${archived ? 'replay' : 'inventory'}`}
                  buttonHandler={archived ? restoreEmployee : openArchiveModal}
                  buttonText={`${archived ? 'Restore' : 'Archive'} ${headerType}`}
                  className="text-button"
                  disabled={pendingArchiveDate && !archived}
                  toolTipText={pendingArchiveDate && !archived ? 'This employee is already scheduled to be archived.' : null}
                />
              }
              {
                <IconTextButton
                  icon="delete"
                  buttonHandler={toggleConfirmDelete}
                  buttonText={`Delete ${headerType}`}
                  className="text-button"
                  disabled={pendingDeleteDate}
                  toolTipText={pendingDeleteDate ? 'This employee is already scheduled to be deleted.' : null}
                />
              }
            </div>
          </div>
        </div>
      )}
    />
  );
};


EditEmployeeHeading.propTypes = {
  headerType: PropTypes.string.isRequired,
  closeUrl: PropTypes.string.isRequired,
  employeeObj: PropTypes.object.isRequired,
  toggleConfirmDelete: PropTypes.func.isRequired,
  openArchiveModal: PropTypes.func.isRequired,
  restoreEmployee: PropTypes.func.isRequired,
};
