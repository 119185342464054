import moment from 'moment';

export const SUPPORT_EMAIL = 'support@illumis.com';
export const CONTACT_EMAIL = 'solutions@illumis.com';

export const [PEOPLE, COMPANIES, EMPLOYEES, ENTITIES, JURISDICTIONS] = [
  'people',
  'companies',
  'employees',
  'entities',
  'jurisdictions',
];
export const MONITORING_ROUTES = [PEOPLE, COMPANIES];
export const DASHBOARD_ROUTE = 'dashboard';

export const BASE_MONITORING_URL = 'all-records';

// map of ui search types to internal search types (used for api calls and such)
export const SEARCH_TYPE_MAP = {
  [PEOPLE]: EMPLOYEES,
  [COMPANIES]: ENTITIES,
};

export const PATH_NAMES = {
  not_found: 'NOT_FOUND',
  not_authorized: 'NOT_AUTHORIZED',
  dashboard: 'DASHBOARD',
  monitoring: 'MONITORING',
  contributions: 'CONTRIBUTIONS',
  research: 'RESEARCH',
  preclearance: 'PRECLEARANCE',
  preclearance_settings: 'PRECLEARANCE_SETTINGS',
  preclearance_survey: 'PRECLEARANCE_SURVEY',
  certification_survey: 'CERTIFICATION_SURVEY',
  certification_settings: 'CERTIFICATION_SETTINGS',
  certification_templates: 'CERTFICATION_TEMPLATES',
  certifications: 'CERTIFICATIONS',
  create_certification: 'CREATE_CERTIFICATION',
  forms: 'FORMS',
  platform_settings: 'PLATFORM_SETTINGS',
  manage_account: 'MANAGE_ACCOUNT',
  manage_organization: 'MANAGE_ORGANIZATION',
  support: 'SUPPORT',
  reports: 'REPORTS',
  bulk_exports: 'BULK_EXPORTS',
  platform_access: 'PLATFORM_ACCESS',
};

export const EMPLOYEE_PATHS = {
  [PATH_NAMES.contributions]: '/contributions',
};


export const APP_BASE_PATH = '/app';
export const PATHS = {
  [PATH_NAMES.preclearance_survey]: '/preclearance_survey/:tableId',
  [PATH_NAMES.certification_survey]: '/certification_survey/:surveyId',
  [PATH_NAMES.not_found]: '/not-found',
  [PATH_NAMES.not_authorized]: '/not-authorized',
  [PATH_NAMES.dashboard]: `${APP_BASE_PATH}/dashboard`,
  [PATH_NAMES.monitoring]: `${APP_BASE_PATH}/all-records`,
  [PATH_NAMES.research]: `${APP_BASE_PATH}/research`,
  [PATH_NAMES.preclearance]: `${APP_BASE_PATH}/preclearance`,
  [PATH_NAMES.preclearance_settings]: `${APP_BASE_PATH}/preclearance/settings`,
  [PATH_NAMES.platform_settings]: `${APP_BASE_PATH}/platform-settings`,
  [PATH_NAMES.manage_account]: `${APP_BASE_PATH}/manage-account`,
  [PATH_NAMES.support]: `${APP_BASE_PATH}/support`,
  [PATH_NAMES.reports]: `${APP_BASE_PATH}/reports`,
  [PATH_NAMES.bulk_exports]: `${APP_BASE_PATH}/bulk-exports`,
  [PATH_NAMES.manage_organization]: `${APP_BASE_PATH}/manage-organization`,
  [PATH_NAMES.certifications]: `${APP_BASE_PATH}/certifications`,
  [PATH_NAMES.certification_settings]: `${APP_BASE_PATH}/certifications/settings`,
  [PATH_NAMES.certification_templates]: `${APP_BASE_PATH}/certifications/templates`,
  [PATH_NAMES.create_certification]: `${APP_BASE_PATH}/certifications/new`,
  [PATH_NAMES.forms]: `${APP_BASE_PATH}/forms`,
  [PATH_NAMES.platform_access]: `${APP_BASE_PATH}/platform-access`,
};

export const RESEARCH_EXPORT_LIMIT = 25000;

// limit for fetching dashboard records
export const DASHBOARD_RECORD_LIMIT = 100;

// limit for fetching matching records (preclearance requests + monitored records)
export const MATCHING_RECORD_LIMIT = 100;

// for record statuses (not preclearance statuses)
export const STATUS_LIMIT = 15;

export const CCD_FIELDS = [
  'candidate',
  'committee',
  'office',
  'jurisdiction',
  'party',
  'election_year',
  'is_incumbent',
];

export const [
  POLITICAL_CONTRIBUTIONS,
  CAMPAIGN_EXPENDITURES,
  LOBBYING,
  BUSINESS_REGISTRATIONS,
] = [
  'Political Contributions',
  'Campaign Expenditures',
  'Lobbying',
  'Business Registrations',
];

// map of category name to the icon name
export const CATEGORY_ICON_MAP = {
  [POLITICAL_CONTRIBUTIONS]: 'political-contributions',
  [CAMPAIGN_EXPENDITURES]: 'expenditures',
  [LOBBYING]: 'lobbying',
  [BUSINESS_REGISTRATIONS]: 'business-regs',
};

export const TODAY = moment().utc().endOf('day').unix();
export const TWO_YEARS_AGO = moment()
  .subtract(2, 'years')
  .utc()
  .startOf('day')
  .unix();
export const SIX_MONTHS_AGO = moment()
  .subtract(6, 'months')
  .utc()
  .startOf('day')
  .unix();

export const [PAST_TWO_YEARS, PAST_SIX_MONTHS, CUSTOM_DATE_RANGE] = [
  'Past two years',
  'Past six months',
  'Custom date range',
];

export const IS_IE_TEN = window.navigator.appVersion.indexOf('MSIE 10') !== -1;
export const SCROLLABLE_CONTAINER = (IS_IE_TEN && document.body) || window;

export const [JURISDICTION, STATUS, PRE_REQUEST, NOTES, EMAIL_RECORD] = [
  'jurisdiction',
  'status',
  'preclearance request',
  'notes',
  'send',
];

export const STATUS_SCHEMA = {
  label: STATUS,
  name: 'contribution_status',
  type: 'text',
};

export const JUR_CONTRIB_SCHEMA = [
  STATUS_SCHEMA,
  {
    label: PRE_REQUEST,
    name: 'matches',
    type: 'text',
  },
];

export const CONTRIB_SCHEMA = [
  STATUS_SCHEMA,
  {
    label: PRE_REQUEST,
    name: 'matches',
    type: 'text',
  },
  {
    label: NOTES,
    name: 'contribution_notes',
    type: 'text',
  },
  {
    label: EMAIL_RECORD,
    name: 'email_record',
    type: 'text',
  },
];

export const JUR_SCHEMA = {
  label: JURISDICTION,
  name: 'jurisdiction',
  type: 'text',
};

export const ALLOWED_CURRENCIES = ['USD', 'GBP', 'EUR', 'TWD'];
export const CURRENCY_CHOICES = ALLOWED_CURRENCIES.map((curr) => ([curr, curr]));
export const DEFAULT_CURRENCY = 'USD';

// used in viewMatch to position to tooltip when in a scrollable table / modal
export const [SCROLLABLE_GRID_CLASS, MODAL_CONTAINER, MAIN_TABLE_CONTAINER] = [
  'scrollable-grid',
  'modal-container',
  'main-table-container',
];

// modalTypes
export const [
  ARCHIVE_EMPLOYEE,
  DELETE_EMPLOYEE,
  DELETE_ENTITY,
  DELETE_EMP_SEARCH,
  DELETE_MATCH,
  PRE_MATCHING_MODAL,
  SEND_RECORD,
] = ['archiveEmployee', 'deleteEmployee', 'deleteEntity', 'deleteEmpSearch', 'deleteMatch', 'preMatchingModal', 'sendRecord'];
