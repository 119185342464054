import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DateRangePicker } from 'react-dates'; // https://github.com/airbnb/react-dates
import 'react-dates/lib/css/_datepicker.css';
import { isInclusivelyBeforeDay } from 'helpers/date_helpers';

class DatePickerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      initialVisibleMonth: null,
    };
    this.setCalPos = this.setCalPos.bind(this);
  }

  setCalPos() {
    const calendarEl = document.querySelector('.DateRangePicker_picker');
    const firstInputEl = document.getElementById('startDate');
    const inputEl = document.getElementById(this.state.focusedInput);
    if (calendarEl && inputEl) {
      const inputPos = inputEl.getBoundingClientRect();
      const firstInputPos = firstInputEl.getBoundingClientRect();
      calendarEl.setAttribute(
        'style',
        `left: ${inputPos.left - firstInputPos.left}px; top: 66px;`,
      );
    }
  }

  render() {
    const {
      startDate,
      endDate,
      startDateLabel,
      endDateLabel,
      errors,
      startDatePlaceholder,
      endDatePlaceholder,
      numberOfMonths,
      isOutsideRange,
    } = this.props;
    return (
      <div className="reports-row">
        <div
          className={errors.endDate ? 'focusable error' : 'focusable'}
        >
          <div className="wrapper">
            <label className="input-title" htmlFor="from">{startDateLabel}</label>
            <label className="input-title" htmlFor="to">{endDateLabel}</label>
          </div>
          <DateRangePicker
            startDate={startDate}
            startDateId="startDate"
            endDate={endDate}
            endDateId="endDate"
            onDatesChange={this.props.onDatesChange}
            focusedInput={this.state.focusedInput}
            initialVisibleMonth={
              this.state.initialVisibleMonth ? () => this.state.initialVisibleMonth : null
            }
            onFocusChange={(focusedInput) => {
              let initialVisibleMonth;
              if (focusedInput === 'endDate') {
                initialVisibleMonth = endDate || startDate;
              } else {
                initialVisibleMonth = startDate;
              }
              this.setState({ focusedInput, initialVisibleMonth }, () => {
                this.setCalPos();
              });
            }}
            isOutsideRange={isOutsideRange}
            numberOfMonths={numberOfMonths}
            noBorder
            hideKeyboardShortcutsPanel
            screenReaderInputMessage="Select date range for your report"
            startDatePlaceholderText={startDatePlaceholder}
            endDatePlaceholderText={endDatePlaceholder}
            anchorDirection="right"
          />
          <div className="wrapper">
            <span className="error-text">
              {errors.startDate ? errors.startDate : ''}
            </span>
            <span className="error-text">
              {errors.endDate ? this.props.errors.endDate : ''}
            </span>
          </div>
        </div>
      </div>
    );
  }
}


DatePickerWrapper.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  onDatesChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  numberOfMonths: PropTypes.number,
  isOutsideRange: PropTypes.func,
};

DatePickerWrapper.defaultProps = {
  startDate: null,
  endDate: null,
  startDateLabel: 'Start Date',
  endDateLabel: 'End Date',
  startDatePlaceholder: 'mm/dd/yyyy',
  endDatePlaceholder: 'mm/dd/yyyy',
  errors: {},
  numberOfMonths: 1,
  isOutsideRange: day => !isInclusivelyBeforeDay(day, moment()),
};

export default DatePickerWrapper;
