import pluralize from 'pluralize';

export const requiredError = 'This field is required.';
export const invalidEmailError = 'Please enter a valid email.';

export function minLengthError(minLength) {
  return `This field must be at least ${minLength} ${pluralize('charater', minLength)} long.`;
}

export function maxLengthError(maxLength) {
  return `This field must be less than ${maxLength} ${pluralize('charater', maxLength)} long.`;
}

export const dateError = 'Please enter a valid date in the form mm/dd/yyyy';
