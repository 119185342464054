import api from 'api';
import { batchActions } from 'redux-batched-actions';
import { RequestTrackerCanceler } from 'utils/apiUtil';
import { ENTITIES } from 'utils/constants';
import { serializeEntity } from 'utils/serializers/entitySerializer';
import { getSearch, removeSearch } from 'reducers/searchObjects/actions';
import { removeSearchObjResults } from '../results/actions';
import * as types from './types';

const receiveEntities = data => ({
  entities: data,
  type: types.RECEIVE_ENTITIES,
});

const addEntity = data => ({
  entity: data,
  type: types.ADD_ENTITY,
});

const editEntity = data => ({
  entity: data,
  type: types.EDIT_ENTITY,
});

const removeEntity = id => ({
  id,
  type: types.REMOVE_ENTITY,
});


export const fetchEntities = () => dispatch =>
  api.get('compliance/entity').then(({ data }) => {
    dispatch(receiveEntities(data));
    return data;
  });


export const createEntity = entityInfo => dispatch =>
  api.post('compliance/entity', serializeEntity(entityInfo)).then(({ data }) => {
    dispatch(addEntity(data));
    return data;
  });


export const updateEntity = entityInfo => (dispatch) => {
  RequestTrackerCanceler.cancelByRequestId(entityInfo.search_object_id);
  return api.put(`compliance/entity/${entityInfo.id}`, serializeEntity(entityInfo)).then(({ data }) => {
    const searchId = data.search_object_id;
    dispatch(batchActions([
      editEntity(data),
      removeSearchObjResults(searchId),
    ]));
    // refresh search object state after entity was updated
    // fetching here in case user closes out of form before response from BE
    dispatch(getSearch(searchId));
    return data;
  });
};


export const updateEntityAlert = (id, _searchId, alert) => dispatch =>
  api.put(`compliance/entity/${id}`, { alerts_active: alert }).then(({ data }) => {
    dispatch(batchActions([
      editEntity(data),
    ]));
    return data;
  });


export const deleteEntity = (id, searchId) => (dispatch) => {
  RequestTrackerCanceler.cancelByRequestId(searchId);
  return api.delete(`compliance/entity/${id}`).then(() => {
    dispatch(batchActions([
      removeEntity(id),
      removeSearch(ENTITIES, searchId),
    ]));
  }).catch((err) => {
    throw err;
  });
};
