import React from 'react';
import PropTypes from 'prop-types';

import HeaderSubRow from './headerSubRow';
import HeaderMainRow from './headerMainRow';


export function formatNames(search) {
  let mainName = '';
  const aliases = [];
  if (search.first_name) {
    const first = search.first_name;
    const last = search.last_name;
    mainName = `${first} ${last}`;
    const altFirsts = search.alt_firsts;
    const firsts = [...altFirsts, first];
    const altLasts = search.alt_lasts;
    const lasts = [...altLasts, last];
    for (let i = 0; i < firsts.length; i++) {
      for (let j = 0; j < lasts.length; j++) {
        const alias = `${firsts[i]} ${lasts[j]}`;
        if (alias !== mainName) {
          aliases.push(`${alias}`);
        }
      }
    }
  } else if (search.entity_name) {
    mainName = search.entity_name;
  } else {
    mainName = search.name;
  }

  return {
    mainName,
    aliases,
  };
}


function ResultsHeader({
  personType,
  search,
  searchCompleted,
  resultCount,
  completedSourceCount,
  totalSourceCount,
  showProgressBar,
  editButton,
  dateFilter,
  alertButton,
  exportType,
  canExport,
  disableExports,
  exportDisabledMessage,
  uiDateFilter,
  errorsObj,
  isJurisdiction,
}) {
  return (
    <div>
      <div className="results-header">
        <HeaderMainRow
          personType={personType}
          search={search}
          searchCompleted={searchCompleted}
          resultCount={resultCount}
          completedSourceCount={completedSourceCount}
          totalSourceCount={totalSourceCount}
          showProgressBar={showProgressBar}
          editButton={editButton}
          dateFilter={dateFilter}
          alertButton={alertButton}
          exportType={exportType}
          canExport={canExport}
          disableExports={disableExports}
          exportDisabledMessage={exportDisabledMessage}
        />
      </div>
      <HeaderSubRow
        search={search}
        uiDateFilter={uiDateFilter}
        errorsObj={errorsObj}
        isJurisdiction={isJurisdiction}
      />
    </div>
  );
}


ResultsHeader.propTypes = {
  search: PropTypes.object.isRequired,
  searchCompleted: PropTypes.bool.isRequired,
  resultCount: PropTypes.number.isRequired,
  completedSourceCount: PropTypes.number,
  totalSourceCount: PropTypes.number,
  showProgressBar: PropTypes.bool,
  editButton: PropTypes.object,
  dateFilter: PropTypes.object.isRequired,
  alertButton: PropTypes.object,
  exportType: PropTypes.string.isRequired, // 'jurisdictions', 'research', 'employees', 'entities'
  canExport: PropTypes.bool.isRequired, // whether or not to show export button
  disableExports: PropTypes.bool, // whether or not to disable export button
  exportDisabledMessage: PropTypes.string,
  uiDateFilter: PropTypes.object, // used to show the contrib dates with other filters in research view
  errorsObj: PropTypes.object.isRequired, // mapping of source labels to error messages
  personType: PropTypes.string, // only for employee searches (SPOUSE / DEPENDENT)
  isJurisdiction: PropTypes.bool,
};

ResultsHeader.defaultProps = {
  alertButton: null,
  disableExports: false,
  exportDisabledMessage: '',
  totalSourceCount: 0,
  completedSourceCount: 0, // total/completed not needed from jurisdiction view bc it doesnt use loading bar
  uiDateFilter: {},
  personType: '',
  showProgressBar: true,
  editButton: null,
  isJurisdiction: false,
};

export default ResultsHeader;
