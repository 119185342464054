import React from 'react';
import PropTypes from 'prop-types';

const Tab = ({ children, badge, activeTab, onClick, tabKey }) => {
  const classes = `${activeTab === tabKey ? 'active ' : ''}tab`;
  return (
    <button className={classes} type="button" onClick={() => onClick(tabKey)}>
      <span>{children}</span>
      {
        badge ?
          <div className="badge">{badge}</div> :
          ''
      }
    </button>
  );
};

Tab.propTypes = {
  children: PropTypes.string.isRequired,
  badge: PropTypes.number,
  activeTab: PropTypes.number,
  onClick: PropTypes.func,
  tabKey: PropTypes.number.isRequired,
};

Tab.defaultProps = {
  badge: 0,
  activeTab: 0,
  onClick: () => {},
};

const TabContent = ({ children, activeTab, tabKey }) => {
  const classes = `${activeTab === tabKey ? 'active ' : ''}tab-content`;
  return (
    <div className={classes}>
      {children}
    </div>
  );
};

TabContent.propTypes = {
  children: PropTypes.element.isRequired,
  activeTab: PropTypes.number.isRequired,
  tabKey: PropTypes.number.isRequired,
};

const TabSet = ({ children, activeTab, onTabClick }) => {
  const tabs = React.Children.map(children, (child, i) => (
    React.cloneElement(child, {
      tabKey: i,
      active: (activeTab === i),
      onClick: onTabClick,
    })),
  );

  return (
    <div className="tab-header">{ tabs }</div>
  );
};

TabSet.propTypes = {
  children: PropTypes.array.isRequired,
  activeTab: PropTypes.number,
  onTabClick: PropTypes.func,
};

TabSet.defaultProps = {
  activeTab: 0,
  onTabClick: () => {},
};


class TabPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
    this.setActiveTab = this.setActiveTab.bind(this);
  }

  setActiveTab(tabIndex) {
    this.setState({ activeTab: tabIndex });
  }

  render() {
    const { activeTab } = this.state;
    return (
      <div className="tab-panel">
        {this.props.render({ activeTab, setActiveTab: this.setActiveTab })}
      </div>
    );
  }
}

TabPanel.propTypes = {
  render: PropTypes.func.isRequired,
};

export { Tab, TabSet, TabPanel, TabContent };
