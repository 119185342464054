import api from '../../api';
import * as types from './types';

const receiveInitialSettingsData = ({
  table, email, PreAction, PreMatchAction, PreEmailTemplate, PreNotificationEmail, PreStatus, macros, jurisdictions,
}) => ({
  type: types.RECEIVE_INITIAL_SETTINGS_DATA,
  table,
  email,
  autoMatchActions: PreMatchAction,
  autoActions: PreAction,
  templates: PreEmailTemplate,
  notifications: PreNotificationEmail,
  statuses: PreStatus,
  macros,
  jurisdictions,
});

export const fetchPreclearanceSettings = () => dispatch =>
  api.get('pre/settings/')
    .then(({ data }) => {
      dispatch(receiveInitialSettingsData(data));
      return data;
    });
