import React from 'react';
import PropTypes from 'prop-types';


const FormTitle = ({ title, organization, logoImage }) => (
  <>
    <div>
      <div className="survey-form-section survey-title-header">
        <>
          {
            logoImage
              ? (
                <div
                  className="survey-form-logo"
                  style={{
                    backgroundImage: `url(${logoImage})`,
                  }}
                />
              )
              : organization
                && <h2 id="subtitle">{ organization }</h2>
          }
        </>
        <h1 id="title">{title}</h1>
      </div>
    </div>
    <div className="survey-form-section" id="required-detail">
      <span>* indicates required field</span>
    </div>
  </>
);

FormTitle.propTypes = {
  title: PropTypes.string,
  organization: PropTypes.string,
  logoImage: PropTypes.string,
};

FormTitle.defaultProps = {
  title: 'Certification Form',
  organization: '',
  logoImage: null,
};

export default FormTitle;
