import React from 'react';
import { toast } from 'react-toastify';
import * as types from './types';
import api from '../../api';
import ProgressToast from '../../components/bulkExports/progressToast';
import awaitExport from '../../components/bulkExports/exportPoller';


const setLoaded = data => ({
  exports: data,
  type: types.SET_LOADED,
});

const addExport = data => ({
  export: data,
  type: types.ADD_EXPORT,
});

const updateExportProgress = data => ({
  total: data.total,
  current: data.current,
  id: data.id,
  errored: data.errored,
  ready: data.ready,
  type: types.UPDATE_EXPORT_PROGRESS,
});


const deleteExport = id => ({
  id,
  type: types.DELETE_EXPORT,
});

export const loadExports = () => dispatch => api.get('/bulk_exports/')
  .then(({ data }) => {
    dispatch(setLoaded(data));
  });

export const createExport = exportData => dispatch => api.post('/bulk_exports/', exportData)
  .then(({ data }) => {
    const toastId = data.id;
    const { errored, ready } = data;
    toast(<ProgressToast id={data.id} complete={ready} hasError={errored} />, {
      toastId,
      autoClose: false,
      className: 'progress-toast',
    });
    dispatch(addExport(data));
    return data;
  });

export const getUpdatedCounts = id => (dispatch) => {
  return api.get(`/bulk_exports/${id}/progress/`).then(({ data }) => {
    dispatch(updateExportProgress({ ...data, id }));
    return awaitExport({
      id,
      data,
      pollExport: () => api.get(`/bulk_exports/${id}/progress/`),
      updateCount: data => dispatch(updateExportProgress(data)),
    }).then((data) => {
      dispatch(updateExportProgress({ ...data, id }));
    });
  });
};

export const removeExport = id => dispatch =>
  api.delete(`/bulk_exports/${id}/`)
    .then(() => {
      dispatch(deleteExport(id));
      clearInterval(window[`bulkExportInterval${id}`]);
      if (toast.isActive(id)) {
        toast.dismiss(id);
      }
    });
