import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import useCancellablePromise from 'hooks/useCancellablePromise';
import { restoreEmployee } from 'reducers/employees/actions';
import IconTextButton from '../monitoringForm/iconTextButton';


export const ArchivedSearchBanner = () => (
  <div className="archived-banner">
    <div className="flex-header">
      <i className="material-icons">
        inventory
      </i>
      <span>
        This person has been archived, archived persons retain their existing records from the time they are archived but are not monitored for new records.
      </span>
    </div>
  </div>
);


const ArchivedBanner = ({ archivedDate, id, handleRestoreEmployee }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { cancellablePromise } = useCancellablePromise();

  const handleRestore = () => {
    setSubmitting(true);
    toast('Restoring employee...');
    cancellablePromise(handleRestoreEmployee(id)).then(() => {
      setSubmitting(false);
    }).catch((err) => {
      if (!err.isCanceled) {
        const errors = err && err.response && err.response.data;
        let errorMessage = 'Something went wrong.';
        if (Array.isArray(errors)) {
          // max employees / monitored persons error
          [errorMessage] = errors;
        }
        toast(errorMessage);
        setSubmitting(false);
        throw err;
      }
    });
  };

  return (
    <div className="archived-banner" data-testid="archive-banner">
      <div className="flex-header">
        <i className="material-icons">
          inventory
        </i>
        <span>
          This person was
          {' '}
          <Tippy
            content="Archived persons retain their existing records from the time they are archived but are not monitored for new records."
          >
            <span className="tooltip-target">
              archived
            </span>
          </Tippy>
          {' '}
          on
          {' '}
          { moment(archivedDate).format('ll') }
        </span>
      </div>
      <IconTextButton
        buttonHandler={handleRestore}
        icon="replay"
        buttonText="restore"
        disabled={isSubmitting}
        className="text-button"
      />
    </div>
  );
};


ArchivedBanner.propTypes = {
  archivedDate: PropTypes.string,
  id: PropTypes.number.isRequired,
  handleRestoreEmployee: PropTypes.func.isRequired,
};

ArchivedBanner.defaultProps = {
  archivedDate: '',
};

export default connect(null, {
  handleRestoreEmployee: restoreEmployee,
})(ArchivedBanner);
