import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createPreStatus, editPreStatus, removePreStatus } from 'reducers/preclearance/statuses/actions';
import EditStatuses from 'components/settings/platformSettings/editStatuses';
import useCertifications from 'components/Certifications/CertificationsList/useCertifications';
import Loading from 'components/loading';
import { ActionsList, RelatedCertificationsList } from './relatedDataConfirmations';


const PreclearanceStatusLabels = ({
  statuses,
  createPreStatus,
  editPreStatus,
  removePreStatus,
  autoActions,
  matchActions,
}) => {
  const { templates, loading } = useCertifications();
  const getLinkedCertifications = id => templates.filter(template =>
    template.status_filter && template.status_filter.includes(id));

  const getRelatedActions = id => Object.values(autoActions)
    .filter(action => action.active && (action.status === id));

  const getRelatedMatchActions = id => Object.values(matchActions)
    .filter(action => action.active && (action.request_status === id));

  const renderRelatedData = (id, isDeleted) => {
    const relatedActions = getRelatedActions(id);
    const relatedMatchActions = getRelatedMatchActions(id);
    const relatedCertifications = getLinkedCertifications(id);
    return (
      <React.Fragment>
        <ActionsList
          isDeleted={isDeleted}
          relatedActions={relatedActions}
          relatedMatchActions={relatedMatchActions}
        />
        <RelatedCertificationsList
          isDeleted={isDeleted}
          relatedCertifications={relatedCertifications}
        />
      </React.Fragment>
    );
  };


  return (
    loading ?
      <div className="load-wrapper">
        <Loading />
      </div>
      :
      <div className="settings">
        <EditStatuses
          statuses={statuses}
          createStatus={createPreStatus}
          editStatus={editPreStatus}
          removeStatus={removePreStatus}
          mainHeader={'Edit Preclearance Status Labels'}
          defaultStatusHelpText={'All incoming preclearance records will be set to this status by default. Changing this label will update all preclearance records currently marked with this status.'}
          additionalStatusesHelpText={'Renaming an existing status will also update the label on all preclearance records currently marked with that status as well as updating any actions associated with that status.'}
          recordType={'preclearance'}
          renderRelatedData={renderRelatedData}
        />
      </div>
  );
};

const mapStateToProps = ({ preclearance }) => {
  const matchActions = preclearance.matchActions;
  const autoActions = preclearance.autoActions;

  return ({
    statuses: preclearance.statuses,
    matchActions,
    autoActions,
  });
};

export default connect(
  mapStateToProps,
  {
    createPreStatus,
    editPreStatus,
    removePreStatus,
  })(PreclearanceStatusLabels);

PreclearanceStatusLabels.propTypes = {
  statuses: PropTypes.object.isRequired,
  createPreStatus: PropTypes.func.isRequired,
  editPreStatus: PropTypes.func.isRequired,
  removePreStatus: PropTypes.func.isRequired,
  matchActions: PropTypes.object.isRequired,
  autoActions: PropTypes.object.isRequired,
};
