import * as types from './types';


const initialState = {};

const aggregations = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AGGREGATION: {
      return {
        ...state,
        [action.aggType]: action.data,
      };
    }
    default: {
      return state;
    }
  }
};


export default aggregations;
