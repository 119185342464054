import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import Tippy from '@tippyjs/react';

const Option = ({ name, label, value, removeable, removeOption }) => (
  <div>
    <Tippy
      disabled={removeable}
      content={'This option is required'}
    >
      <div>
        <Field
          className="chip"
          id={`selected-${value}`}
          name={name}
          as="button"
          type="button"
          value={value}
          onClick={removeable ? removeOption : null}
        >
          <span>{label}</span>
          { removeable && <span className="material-icons">close</span>}
        </Field>
      </div>
    </Tippy>
  </div>
);

Option.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  label: PropTypes.string.isRequired,
  removeOption: PropTypes.func.isRequired,
  removeable: PropTypes.bool,
};

Option.defaultProps = {
  removeable: true,
};


const OptionGroup = ({ name, choices }) => (
  <FieldArray
    name={name}
    render={arrayHelpers => (
      <div role="group" className="chip-container">
        {choices && choices.length > 0 ? (
          choices.map((choice, index) => (
            <Option
              key={`selected-option-${choice.value}`}
              label={choice.label}
              name={name}
              value={choice.value}
              removeable={!choice.required}
              removeOption={() => { arrayHelpers.remove(index); }}
            />
          ))
        ) :
          null}
      </div>
    )}
  />
);

OptionGroup.propTypes = {
  name: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

OptionGroup.defaultProps = {
  choices: [],
};

export { OptionGroup };
