import React from 'react';
import PropTypes from 'prop-types';
import { sortObjects } from 'helpers/helpers';
import { CustomDropdown } from './dropdown';
import { SidebarSearch } from './sidebar/search';
import { selectors as fromJurisdictions } from '../reducers/jurisdictions';
import { DropdownOption, DropdownButton } from './shared/objectDropdown';


export const JurisdictionOptions = ({ jurisdictions, parent, handleSelection, jurisdiction }) => {
  const jurisdictionsInCategory = fromJurisdictions.byParent(jurisdictions, parent.id)
    .filter(j => !j.hidden);
  const sortedJurs = sortObjects(jurisdictionsInCategory, 'name');

  return (
    jurisdictionsInCategory.length ? (
      <div key={parent.id}>
        <div>
          <div className="jurisdiction-header">{parent.name}</div>
          { sortedJurs.map((j, i) => (
            <DropdownOption
              key={`${j.id}-${i}`}
              value={j.name}
              onClick={() => { handleSelection(j); }}
              selected={jurisdiction && jurisdiction.id === j.id}
            >
              {j.name}
            </DropdownOption>
          ))}
        </div>
      </div>
    ) : null
  );
};

JurisdictionOptions.propTypes = {
  jurisdictions: PropTypes.array.isRequired,
  parent: PropTypes.object.isRequired,
  handleSelection: PropTypes.func.isRequired,
  jurisdiction: PropTypes.object,
};

JurisdictionOptions.defaultProps = {
  jurisdiction: null,
};


export class JurisdictionDropdownBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };
    this.jurisdictions = props.jurisdictions;
    this.onSearchFilter = this.onSearchFilter.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  onSearchFilter(e) {
    this.setState({ filter: e.target.value });
  }

  filterJurisdictions() {
    const { filter } = this.state;
    if (filter.trim()) {
      const RegExpEscape = str => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      const regTest = new RegExp(RegExpEscape(filter), 'gi');
      return this.jurisdictions.filter((j) => {
        const names = [j.name];
        return names.some(n => regTest.test(n)) || names.some(n => regTest.test(n));
      });
    }
    return this.jurisdictions;
  }

  handleKeyPress(e) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      const jurs = this.filterJurisdictions();
      if (jurs[0]) {
        this.props.handleDropdownSelection(jurs[0]);
        this.setState({ filter: '' });
      }
    }
  }

  render() {
    const filteredJurisdictions = this.filterJurisdictions();
    const filterInUse = this.state.filter.trim();
    const parentJurisdictions = fromJurisdictions.parentJurisdictions(this.props.jurisdictions);
    const sortedParents = sortObjects(parentJurisdictions, 'name');


    return (
      <div>
        <div className="search-wrapper">
          <div className="sidebar-search-container">
            <SidebarSearch
              onSearchFilter={this.onSearchFilter}
              filterValue={this.state.filter}
              searchPlaceholder="search jurisdictions..."
              handleKeyPress={this.handleKeyPress}
            />
          </div>
        </div>
        <div className="options-wrapper">
          { filteredJurisdictions.length === 0 && filterInUse ? <div className="empty">No jurisdictions found with the name {`"${this.state.filter}"`}</div> : ''}
          {sortedParents.map(j => (
            <JurisdictionOptions
              key={j.id}
              jurisdictions={filteredJurisdictions}
              parent={j}
              handleSelection={(jur) => {
                this.props.handleDropdownSelection(jur);
                this.setState({ filter: '' });
              }}
              jurisdiction={this.props.jurisdiction}
            />
          ))}
        </div>
      </div>
    );
  }
}

JurisdictionDropdownBody.propTypes = {
  jurisdiction: PropTypes.object,
  jurisdictions: PropTypes.array.isRequired,
  handleDropdownSelection: PropTypes.func.isRequired,
};

JurisdictionDropdownBody.defaultProps = {
  jurisdiction: null,
};


const JurisdictionDropdown = ({
  jurisdictions, jurisdiction, dropdownText, onJurisdictionSelect,
}) => (
  <CustomDropdown render={({ isOpen, openDropdown, closeDropdown, dropdownRef }) => (
    <div id="jurisdiction-dropdown" className="dropdown">
      <div className={`drop-wrapper ${jurisdiction ? 'active' : ''}`}>
        <DropdownButton
          dropdownText={dropdownText}
          isOpen={isOpen}
          onClick={openDropdown}
        />
      </div>
      {
        isOpen ?
          <div className="dropdown-container" ref={dropdownRef}>
            <JurisdictionDropdownBody
              jurisdictions={jurisdictions}
              handleDropdownSelection={(jur) => {
                onJurisdictionSelect(jur);
                closeDropdown();
              }}
              jurisdiction={jurisdiction}
            />
          </div>
          : null
      }
    </div>
  )}
  />
);

JurisdictionDropdown.propTypes = {
  dropdownText: PropTypes.string.isRequired,
  onJurisdictionSelect: PropTypes.func.isRequired,
  jurisdiction: PropTypes.object,
  jurisdictions: PropTypes.array.isRequired,
};

JurisdictionDropdown.defaultProps = {
  jurisdiction: null,
};

export default JurisdictionDropdown;
