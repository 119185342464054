import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import SimpleForm from '../../../shared/forms/simpleForm';
import { sendEmail } from '../../../../reducers/preclearance/actions';

const EmailLinkForm = (props) => {
  function onSendEmail(email) {
    if (!email.to.trim().length) {
      toast("Please enter the recipient's email address");
      return;
    }
    props.onCancel();
    // eslint-disable-next-line consistent-return
    return sendEmail({ ...email, from_email: props.fromEmail }).then(() => {
      toast('Email sent.');
    }).catch((err) => {
      toast('There was a problem sending this email.');
      throw err;
    });
  }

  const defaultState = {
    body: `Hello,\n\nPlease complete the political contribution pre-clearance request form using the link below:\n\n${props.formUrl}\n\nFollowing your submission, Compliance will review and notify you.\n\nThank you,\n\n${props.userFullName}`,
    to: '',
    subject: 'Political Contribution Pre-clearance Request Form',
  };
  const fieldMap = {
    to: {
      label: 'to',
      required: true,
      type: 'text',
    },
    subject: {
      label: 'subject',
      type: 'text',
    },
    body: {
      label: 'body',
      textArea: true,
      type: 'text',
    },
  };

  return (
    <SimpleForm
      onSubmit={onSendEmail}
      onCancel={props.onCancel}
      cancelText={'Cancel'}
      confirmText={'Send'}
      defaultState={defaultState}
      fieldMap={fieldMap}
    />
  );
};


EmailLinkForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
  fromEmail: PropTypes.string.isRequired,
};

export default EmailLinkForm;
