import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import Dropdown from './Dropdown';
import { OptionWithIcon, ValueWithIcon } from './DropdownComponents';
import ToggleField from './ToggleField';
import { fields, defaultChoice } from '../consts';
import {
  RADIO,
  FIELD_TYPES,
  CHOICE_FIELDS,
  CHOICE_ICON_MAP,
} from '../../consts';
import QuestionInput from './EditQuestionInput';
import DependentToolbar from './DependentToolbar';
import TextEditor from './TextEditor/TextEditor';


const EditQuestion = ({
  field,
  index,
  removeField,
  removeDependency,
  removeDependencyChoice,
}) => {
  const {
    id,
    input_type: inputType,
    is_required: isRequired,
    choices,
    dependencies,
  } = field;
  const [_field, meta] = useField(`${fields}.${id}`);
  const [___field, __meta, { setValue: setChoices }] = useField(`${fields}.${id}.choices`);

  const handleFieldTypeChange = (fieldType) => {
    if (!CHOICE_FIELDS.includes(fieldType) && choices.length) {
      setChoices([]);
    } else if (CHOICE_FIELDS.includes(fieldType) && !choices.length) {
      setChoices([{ ...defaultChoice() }]);
    }
    if (inputType === RADIO && fieldType !== RADIO) {
      removeDependency(id);
    }
  };

  return (
    <div>
      <div className="edit-question-toolbar-wrapper">
        <div className="flex-header edit-question-toolbar">
          <div className="question-dropdown">
            <Dropdown
              name={`${fields}.${id}.input_type`}
              options={Object.values(FIELD_TYPES)}
              onChangeCb={handleFieldTypeChange}
              components={{
                Option: OptionWithIcon,
                SingleValue: ValueWithIcon,
              }}
            />
          </div>
          <div className="toolbar-right">
            <ToggleField
              active={isRequired}
              fieldName={`${fields}.${id}.is_required`}
              toggleText="required"
            />
            <IconTextButton
              icon="delete"
              buttonText="delete"
              buttonHandler={removeField}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="editor-wrapper">
          <TextEditor
            placeholder="enter a question"
            name={`${fields}.${id}.label`}
          />
          {meta.error && meta.error.label && (
            <p className="error-text">{meta.error.label}</p>
          )}
        </div>
        <div>
          <QuestionInput
            fieldId={field.id}
            name={`${fields}.${id}.label`}
            choicesFieldName={`${fields}.${id}.choices`}
            choices={field.choices}
            inputType={inputType}
            placeholder={FIELD_TYPES[inputType].label}
            removeDependencyChoice={removeDependencyChoice}
            choiceIcon={CHOICE_ICON_MAP[inputType]}
          />
        </div>
      </div>
      <div className="bottom-toolbar-wrapper">
        <div className="edit-question-toolbar">
          <DependentToolbar
            fieldName={`${fields}.${id}.dependencies`}
            dependencies={dependencies}
            order={index}
          />
        </div>
      </div>
    </div>
  );
};

EditQuestion.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  removeField: PropTypes.func.isRequired,
  removeDependency: PropTypes.func.isRequired,
  removeDependencyChoice: PropTypes.func.isRequired,
};

export default EditQuestion;
