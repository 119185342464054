import { connect } from 'react-redux';
import SourceSubcategory from '../shared/searchResults/sourceSubcategory';
import { bySubcategory } from '../../reducers/sources/selectors';
import { bySourceIds } from '../../reducers/sourceResults/selectors';

const mapStateToProps = (state, props) => {
  const sources = bySubcategory(props.sources, props.name).sort((a, b) => (a.label < b.label ? -1 : 1));
  const sourceResults = bySourceIds(state.sourceResults, sources.map(source => source.id), props.searchId);
  const sourceResultsMap = sourceResults.reduce((resultMap, sourceResult) => {
    resultMap[sourceResult.source] = sourceResult;
    return resultMap;
  }, {});
  return {
    sources,
    sourceResultsMap,
    recordCount: sourceResults.reduce((total, sourceResult) => total + sourceResult.data.length, 0),
  };
};

export default connect(mapStateToProps)(SourceSubcategory);
