import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Section from 'components/shared/settingsPage/section';
import SettingsPage from 'components/shared/settingsPage/settingsPage';
import SimpleForm from 'components/shared/forms/simpleForm';
import { changePassword } from 'reducers/user/actions';

const passwordReqs = [
  "Your password must not have been used recently on this service.",
  "Your password can't be too similar to your other personal information.",
  "Your password must contain at least 8 characters.",
  "Your password can't be a commonly used password.",
  "Your password can't be entirely numeric.",
].map((el, i) => <li key={i}>{el}</li>);

const fieldMap = {
  old_password: {
    label: 'old password',
    required: true,
    type: 'password',
  },
  new_password: {
    label: 'new password',
    required: true,
    type: 'password',
    info: passwordReqs,
  },
  new_password_confirmation: {
    label: 'new password confirmation',
    required: true,
    type: 'password',
  },
};


function ChangePassword({ changePassword }) {
  const defaultState = {
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  };
  const section = () => (
    <Section>
      <SimpleForm
        submitOnEnter
        fieldMap={fieldMap}
        onSubmit={changePassword}
        defaultState={defaultState}
        confirmText={'save'}
        genericError={'There was a problem changing your password.'}
        successToast={'Password changed successfully!'}
      />
    </Section>
  );
  return (
    <SettingsPage
      header={'Change Password'}
      sections={[section]}
    />
  );
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
};

export default connect(null, { changePassword })(ChangePassword);

