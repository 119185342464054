import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from 'api';
import { toast } from 'react-toastify';
import { PATH_NAMES, PATHS } from 'utils/constants';


function useCertificationTemplate(id) {
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    let didCancel = false;
    api.get(`/attestations/templates/${id}`).then(({ data }) => {
      if (!didCancel) {
        setTemplate(data);
      }
    }).catch((err) => {
      if (!didCancel) {
        if (err && err.response && err.response.status === 404) {
          toast('Certification not found.');
          history.push(`${PATHS[PATH_NAMES.certifications]}`);
          return;
        }
        toast('Something went wrong and we were unable to get the details for this certification.');
        throw (err);
      }
    }).finally(() => {
      if (!didCancel) {
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, []);


  return ({
    template,
    loading,
  }
  );
}


export default useCertificationTemplate;
