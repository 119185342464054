import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../dropdown';


export const SelectField = ({
  value,
  onSelect,
  choices,
  dropdownTop,
}) => {
  const selected = new Map(choices).get(value) || value;
  return (
    <Dropdown
      className={`${dropdownTop ? 'top' : ''} cell`}
      value={selected}
      options={choices}
      onOptionClick={onSelect}
    />
  );
};


SelectField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
  choices: PropTypes.array.isRequired,
  dropdownTop: PropTypes.bool.isRequired,
};
