import api from 'api';
import { batchActions } from 'redux-batched-actions';
import { MultiRequestCanceler, RequestTrackerCanceler, buildQuery } from 'utils/apiUtil';
import { EMPLOYEES, ENTITIES } from 'utils/constants';
import * as types from './types';
import { clearResults, toggleJurisdictionLoading } from '../results/actions';


const receiveSearchObjs = (searchType, data) => ({
  searchType,
  searches: data,
  type: types.RECEIVE_SEARCH_OBJS,
});

const receiveSearch = data => ({
  search: data,
  type: types.RECEIVE_SEARCH,
});

export const addSearch = (searchType, data) => ({
  searchType,
  search: data,
  type: types.ADD_SEARCH,
});

export const editSearch = data => ({
  search: data,
  type: types.EDIT_SEARCH,
});

export const removeSearch = (searchType, id) => ({
  searchType,
  id,
  type: types.REMOVE_SEARCH,
});


export const getSearch = (id, from, to) => (dispatch) => {
  const query = {
    from,
    to,
  };
  return api.get(`/compliance/search_object/${id}?${buildQuery(query)}`, {
    cancelToken: RequestTrackerCanceler.getCancelToken(id),
  })
    .then(({ data }) => {
      dispatch(receiveSearch(data));
      return data;
    });
};


// ***** jurisdiction view *****
export const getJurisdictionSearches = id => (dispatch, getState) => {
  // cancel all pending jur count and result requests to avoid a race condition in which incorrect records / searchObjs could be displayed
  MultiRequestCanceler.cancelAllPending();
  dispatch(toggleJurisdictionLoading(true));
  const { ui } = getState();
  const { dateFilter } = ui;
  const args = { ...dateFilter };
  const queryString = Object.keys(args).filter(k => !!args[k]).map(k => `${k}=${args[k]}`).join('&');

  return Promise.all([
    api.get(`/jurisdictions/${id}/employees/?${queryString}`, { cancelToken: MultiRequestCanceler.newCancel() }),
    api.get(`/jurisdictions/${id}/entities/?${queryString}`, { cancelToken: MultiRequestCanceler.newCancel() }),
  ]).then((responses) => {
    dispatch(batchActions([
      clearResults(),
      receiveSearchObjs(EMPLOYEES, responses[0].data),
      receiveSearchObjs(ENTITIES, responses[1].data),
      toggleJurisdictionLoading(false),
    ]));
    return responses;
  });
};
