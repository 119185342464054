import * as types from './types';
import api from '../../api';

const addManualReport = data => ({
  report: data,
  type: types.ADD_MANUAL_REPORT,
});

const setLoaded = data => ({
  reports: data,
  type: types.SET_LOADED,
});

const setScheduled = data => ({
  scheduledReports: data,
  type: types.SET_SCHEDULED,
});

const addAutoReport = data => ({
  report: data,
  type: types.ADD_AUTO_REPORT,
});

const editAutoReport = data => ({
  report: data,
  type: types.EDIT_AUTO_REPORT,
});

const updateReportProgress = data => ({
  total: data.total,
  current: data.current,
  id: data.id,
  errored: data.errored,
  ready: data.ready,
  type: types.UPDATE_REPORT_PROGRESS,
});

const deleteReport = id => ({
  id,
  type: types.DELETE_REPORT,
});


export const loadReports = () => dispatch =>
  api.get('/reports/')
    .then(({ data }) => {
      dispatch(setLoaded(data));
    });

export const loadScheduledReports = () => dispatch =>
  api.get('/report_scheduler/')
    .then(({ data }) => {
      dispatch(setScheduled(data));
    });

export const createManualReport = reportData => dispatch =>
  api.post('/reports/', reportData)
    .then(({ data }) => {
      dispatch(addManualReport(data));
      return data;
    });


export const createAutoReports = reportData => dispatch =>
  api.post('/report_scheduler/', reportData)
    .then(({ data }) => {
      dispatch(addAutoReport(data));
      return data;
    });

export const updateAutoReports = reportData => dispatch =>
  api.put(`/report_scheduler/${reportData.id}/`, reportData)
    .then(({ data }) => {
      dispatch(editAutoReport(data));
      return data;
    });

export const getUpdatedCounts = id => dispatch =>
  api.get(`/reports/${id}/progress/`)
    .then(({ data }) => {
      dispatch(updateReportProgress({ ...data, id }));
      return data;
    });

export const removeReport = id => dispatch =>
  api.delete(`/reports/${id}/`)
    .then(() => {
      dispatch(deleteReport(id));
    });
