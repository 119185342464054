import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Checkbox extends Component {
  componentDidMount() {
    this.checkbox.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate(prevProps) {
    if (this.props.indeterminate !== prevProps.indeterminate) {
      this.checkbox.indeterminate = this.props.indeterminate;
    }
  }


  render() {
    return (
      <div className="checkbox">
        <input
          {...this.props.indeterminate}
          id={this.props.id}
          type="checkbox"
          checked={this.props.checked}
          ref={(input) => { this.checkbox = input; }}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
        />
        <label htmlFor={this.props.id}>{this.props.children}</label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  indeterminate: false,
  disabled: false,
};
