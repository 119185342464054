import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { useParams } from 'react-router';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import ModalUI from 'components/modal/modalUI';
import Portal from 'components/shared/portal';


const ResendCertification = ({ renderForm, disabled, disabledText }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { id } = useParams();

  const closeModal = () => {
    setModalOpen(false);
  };


  return (
    <div>
      <Tippy
        content={disabledText}
        disabled={!disabled}
        interactive
      >
        <div>
          <IconTextButton
            icon={'send'}
            buttonHandler={() => {
              setModalOpen(true);
            }}
            buttonText={'Resend'}
            disabled={disabled}
          />
        </div>
      </Tippy>
      {
        modalOpen ?
          <Portal>
            <ModalUI
              open={modalOpen}
              close={closeModal}
              maxWidth={'850px'}
              className="form-modal"
            >
              {
                renderForm({
                  id,
                  closeForm: closeModal,
                })
              }
            </ModalUI>
          </Portal>
          :
          null
      }

    </div>
  );
};

ResendCertification.propTypes = {
  renderForm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledText: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

ResendCertification.defaultProps = {
  disabled: false,
  disabledText: '',
};

export default ResendCertification;
