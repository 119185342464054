import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/loading';


function SubmitButton({ isSubmitting, edit, type, icon }) {
  return (
    <button
      className="orange-button loading-button"
      disabled={isSubmitting}
      type="submit"
    >
      { isSubmitting ? <Loading /> : icon && <i className={`icon-add-${icon}`} />}
      <span>
        { edit ? 'Update ' : 'Add ' }
        { type }
      </span>
    </button>
  );
}

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

SubmitButton.defaultProps = {
  icon: '',
};

export default SubmitButton;
