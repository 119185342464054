import React from 'react';
import { Field, useField } from 'formik';
import { FORM_ELEMENT } from '../constants';

const CheckboxGroup = ({ ...props }) => {
  const [field, _meta, helpers] = useField(props);
  const choices = props.choices || [];
  const groupId = `checkbox-group-${props.id}`;

  return (
    <ul className="radio-ul" role="group" aria-labelledby={groupId}>
      {choices.map(([value, name]) => (
        <li key={value} className="checkbox">
          <Field
            id={value}
            name={field.name}
            type="checkbox"
            value={value.toString()}
            className="radio-ul"
          />
          <label htmlFor={value}>
            <span>{name}</span>
          </label>
        </li>
      ))}
    </ul>
  );
};

CheckboxGroup.propTypes = {
  ...FORM_ELEMENT,
};
export { CheckboxGroup };
