import { batchActions } from 'redux-batched-actions';
import { receiveActions } from '../autoActions/actions';
import api from '../../../api';
import * as types from '../types';

const loadTemplates = data => ({
  data,
  type: types.LOAD_TEMPLATES,
});

const receiveTemplate = data => ({
  data,
  type: types.RECEIVE_TEMPLATE,
});

const removeTemplate = id => ({
  id,
  type: types.DELETE_TEMPLATE,
});

export const fetchTemplates = () => dispatch =>
  api.get('/pre/templates/')
    .then(({ data }) => {
      dispatch(loadTemplates(data));
      return data;
    });

export const createTemplate = template => dispatch =>
  api.post('/pre/templates/', template)
    .then(({ data }) => dispatch(receiveTemplate(data)));

export const updateTemplate = template => dispatch =>
  api.put(`/pre/templates/${template.id}/`, template)
    .then(({ data }) => dispatch(receiveTemplate(data)));

export const deleteTemplate = id => dispatch =>
  api.delete(`/pre/templates/${id}`)
    .then(({ data }) => dispatch(batchActions([
      removeTemplate(id),
      receiveActions(data),
    ])));
