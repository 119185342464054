import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { EMPLOYEE_PATHS, PATH_NAMES } from 'utils/constants';

function EmployeeNav() {
  const { url } = useRouteMatch();
  return (
    <div className="employee-nav">
      <NavLink
        className="employee-nav-link"
        activeClassName="active"
        to={`${url}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`}
      >
        Political contributions
      </NavLink>
    </div>
  );
}

export default EmployeeNav;
