import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/react';
// import { WebSocketBridge } from 'django-channels';
import thunk from 'middleware/thunk';
import { createBrowserHistory as createHistory } from 'history';
import mainReducer from 'reducers';
import { enableBatching } from 'redux-batched-actions';
import handleWebSocketMessage from 'utils/websocketUtil';

export const history = createHistory();
const initState = {};
const middleware = [thunk];

const configureStore = () => {
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
            : compose;
    /* eslint-enable */

    const enhancer = composeEnhancers(applyMiddleware(...middleware));

    const store = createStore(enableBatching(mainReducer), initState, enhancer);

    // const reduxBridge = new WebSocketBridge();
    // reduxBridge.connect('/ws');

    // reduxBridge.socket.addEventListener('message', (event) => {
    //     handleWebSocketMessage(event, store);
    // });

    // reduxBridge.socket.addEventListener('error', (error) => {
    //     Sentry.captureException(error);
    // });

    return store;
};

export default configureStore;
