import api from '../../api';
import * as types from './types';

const loadStatuses = data => ({
  data,
  type: types.LOAD_STATUSES,
});

const addStatus = data => ({
  data,
  type: types.ADD_STATUS,
});

const updateStatus = data => ({
  data,
  type: types.UPDATE_STATUS,
});

const deleteStatus = id => ({
  id,
  type: types.DELETE_STATUS,
});


export const fetchStatuses = () => dispatch =>
  api.get('/statuses/')
    .then(({ data }) => {
      dispatch(loadStatuses(data));
      return data;
    });

export const createStatus = newStatus => dispatch =>
  api.post('/statuses/', newStatus)
    .then(({ data }) => {
      dispatch(addStatus(data));
      return data;
    });

export const editStatus = newStatus => dispatch =>
  api.put(`/statuses/${newStatus.id}/`, newStatus)
    .then(({ data }) => {
      dispatch(updateStatus(data));
      return data;
    });

export const removeStatus = id => dispatch =>
  api.delete(`/statuses/${id}/`)
    .then(({ data }) => {
      dispatch(deleteStatus(id));
      return data;
    });
