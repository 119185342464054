'use strict';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}


// for exporting charts in IE
require('canvas-toBlob');

// for IE -- the package 'django-channels' (for websockets) uses the MessageEvent constructor & IE 10 & 11 do not support it (based on this polyfill from MDN https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent)

(function () {
  if (typeof window.MessageEvent === 'function') return false;
  function MessageEvent (event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent('MessageEvent');
    evt.initMessageEvent(event, params.bubbles, params.cancelable, params.detail, params.origin, params.lastEventId, params.source, params.ports);
    return evt;
  }
  window.MessageEvent = MessageEvent;
}());

// this is for IE 10 specifically -- the package 'event-target-shim' (required by 'django-channels' for websockets) uses Object.setPrototypeOf(), which is not supported in IE 10
require('set-prototype-of');
