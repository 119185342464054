import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { closeModal } from 'reducers/ui/actions';
import { archiveEmployee, setFieldData } from 'reducers/employees/actions';
import { byId } from 'reducers/employees/selectors';
import {
  BASE_MONITORING_URL, EMPLOYEE_PATHS, PATHS, PATH_NAMES, PEOPLE,
} from 'utils/constants';


const ArchiveConfirmation = ({
  name,
  id,
  exitModal,
  archiveFunc,
  history,
  redirectUrl,
  archiveDelay,
  archiveDelaySetting,
  updateEmployeeField,
}) => {
  const handleArchive = () => {
    history.push(`${redirectUrl}`);
    exitModal();
    if (archiveDelay) {
      updateEmployeeField(id, { delay_archive: true }).catch((err) => {
        toast('There was a problem archiving this employee.');
        throw err;
      });
    } else {
      archiveFunc(id).catch((err) => {
        toast('There was a problem archiving this employee.');
        throw err;
      });
    }
  };

  return (
    <div className="form-wrapper search-form">
      <div className="delete-confirmation">
        <div>
          Archived employees retain their existing records from the time they are archived but are not monitored for new records.
          Are you sure you want to archive
          {' '}
          <strong>
            { name }
          </strong>
          ?
          {
            archiveDelay ? (
              <div className="confirmation-note">
                Note that the archive delay setting of
                {' '}
                <Tippy
                  interactive
                  content={(
                    <div>
                      The archive delay setting can be changed in the archive actions tab on the
                      {' '}
                      <Link
                        to={`${PATHS[PATH_NAMES.platform_settings]}`}
                        className="orange-link"
                      >
                        platform settings page
                      </Link>
                      .
                    </div>
                  )}
                >
                  <span className="tooltip-target">
                    { archiveDelaySetting }
                  </span>
                </Tippy>
                {' '}
                will be applied by default.
              </div>
            )
              : null
          }
          <div className="buttons">
            <button
              className="text-button uppercase"
              onClick={exitModal}
              type="button"
            >
              Cancel
            </button>
            <button
              className="orange-button"
              type="button"
              onClick={handleArchive}
            >
              Archive
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


ArchiveConfirmation.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  archiveFunc: PropTypes.func.isRequired,
  exitModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  archiveDelay: PropTypes.number,
  archiveDelaySetting: PropTypes.string,
  updateEmployeeField: PropTypes.func.isRequired,
};

ArchiveConfirmation.defaultProps = {
  archiveDelay: null,
  archiveDelaySetting: null,
};


const mapStateToProps = (state, props) => {
  const { match: { params } } = props;
  const selected = byId(state, params.id) || {};
  const { first_name: firstName, last_name: lastName, id } = selected;
  const name = `${firstName} ${lastName}`;
  const archiveDelaySetting = state.user.archive_delay_setting_data;
  return {
    id,
    name,
    redirectUrl: `/app/${BASE_MONITORING_URL}/${PEOPLE}/${id}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`,
    archiveDelay: state.user.archive_delay_setting,
    archiveDelaySetting: archiveDelaySetting ? archiveDelaySetting.label : null,
  };
};

export default withRouter(connect(mapStateToProps, {
  exitModal: closeModal,
  archiveFunc: archiveEmployee,
  updateEmployeeField: setFieldData,
})(ArchiveConfirmation));

