import React from 'react';
import PropTypes from 'prop-types';
import { STYLE_TYPES } from './consts';


const StyleButton = ({
  onToggle, style, icon, active,
}) => (
  <button
    key={style}
    type="button"
    // this prevents the editor from losing focus
    onMouseDown={(e) => { e.preventDefault(); }}
    onClick={() => onToggle(style)}
    className={`${active ? 'active ' : ''}style-button`}
  >
    <i className="material-icons">
      { icon }
    </i>
  </button>
);

StyleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  style: PropTypes.oneOf(STYLE_TYPES).isRequired,
  icon: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default StyleButton;
