import { combineReducers } from 'redux';
import * as types from '../results/types';
import { SET_CONTRIBUTIONS, APPEND_CONTRIBUTIONS, SET_SECTION_STATUS, UPDATE_NOTE } from './types';
import { ADD_MATCHED_REQUEST, TOGGLE_NO_MATCH, DELETE_MATCH } from '../matches/types';
import { MATCH_ACTION } from '../preclearance/types';
import { toast } from 'react-toastify';

// import { merge } from 'lodash';

// ----state shape byId----
// {
//   contribId: { data, meta },
//   contribId: { data, meta },
//   contribId: { data, meta },
// }

// ----state shape allIds----
// [ contribId, contribId ]

// deep copy an array, pojo, or primitive
function deepCopy(obj) {
  let newObj;
  if (obj instanceof Array) {
    newObj = [];
    obj.forEach(el => newObj.push(deepCopy(el)));
    return newObj;
  } else if (obj && typeof obj === 'object') {
    newObj = {};
    Object.keys(obj).forEach((key) => {
      newObj[key] = deepCopy(obj[key]);
    });
    return newObj;
  }
  return obj;
}

const metaFieldArrays = [
  ['status', 'contribution_status'],
  ['files', 'contribution_files'],
  ['notes', 'contribution_notes'],
  ['id', 'contribution_id'],
];

function normalizeContribution(contribution, mergeContrib) {
  // inconsistency in results serialized in BE in dashboard vs search obj result
  // so just put all the meta fields on the contribution to avoid conditional checking elsewhere
  // TODO: change this in the backend for more consistency up here
  return metaFieldArrays.reduce((newContrib, metaKeys) => {
    metaKeys.forEach((key) => {
      newContrib.meta[key] = contribution.meta[metaKeys[0]] || contribution.meta[metaKeys[1]];
    });
    return newContrib;
  }, deepCopy(mergeContrib || contribution));
}

const defaultState = {};

const byId = (state = defaultState, action) => {
  switch (action.type) {
    case types.CLEAR_RESULTS:
      return {};
    case SET_CONTRIBUTIONS:
      return action.contributions.reduce((allContribs, contribution) => {
        contribution.sourceId = contribution.meta.source_id;
        allContribs[contribution.data.id] = contribution;
        return allContribs;
      }, {});
    case APPEND_CONTRIBUTIONS:
      return action.contributions.reduce((allContribs, contribution) => {
        contribution.sourceId = contribution.meta.source_id
        allContribs[contribution.data.id] = contribution;
        return allContribs;
      }, Object.assign({}, state));
    case ADD_MATCHED_REQUEST: {
      const record = state[action.recordId];
      return {
        ...state,
        [action.recordId]: {
          ...record,
          meta: {
            ...record.meta,
            matches: [action.request, ...record.meta.matches],
            no_match: false,
          },
        },
      };
    }
    case TOGGLE_NO_MATCH: {
      const { recordId, noMatch } = action;
      const record = state[recordId];
      const matches = noMatch ? [] : [...record.meta.matches];
      return {
        ...state,
        [recordId]: {
          ...record,
          meta: {
            ...record.meta,
            no_match: noMatch,
            matches,
          },
        },
      };
    }
    case DELETE_MATCH: {
      const record = state[action.recordId];
      if (!record) {
        return state;
      }
      return {
        ...state,
        [action.recordId]: {
          ...record,
          meta: {
            ...record.meta,
            matches: record.meta.matches.filter(match =>
              match.id !== action.requestId,
            ),
          },
        },
      };
    }
    case UPDATE_NOTE:
      state[action.data.data.id] = normalizeContribution(action.data, state[action.data.data.id]);
      return state;
    case MATCH_ACTION: {
      const { record } = action.data;
      if (!state[record.data.id]) {
        return state;
      }
      const nextState = { ...state };
      nextState[record.data.id] = normalizeContribution(record, state[record.data.id]);
      // todo -- not call this here.
      toast('Record status updated.');
      return nextState;
    }
    case SET_SECTION_STATUS:
      return action.data.reduce((newState, contribution) => {
        const updatedContrib = normalizeContribution(contribution, newState[contribution.data.id]);
        newState[contribution.data.id] = { ...contribution, ...updatedContrib };
        return newState;
      }, state);
    case types.LOAD_JURISDICTION_RESULTS:
      return action.results.reduce((allContribs, sourceResult) => {
        sourceResult.data.forEach((contribution) => {
          // if multiple searchObjs share this contribution then store the sourceResIds of all contribs where has_jur is true
          if (allContribs[contribution.data.id]) {
            if (sourceResult.has_jur_selected) {
              allContribs[contribution.data.id].has_jur_selected = sourceResult.has_jur_selected;
              allContribs[contribution.data.id].sourceResults.push(sourceResult.id);
            }
          } else {
            allContribs[contribution.data.id] = normalizeContribution(contribution);
            allContribs[contribution.data.id].sourceId = sourceResult.source;
            allContribs[contribution.data.id].sourceResults = sourceResult.has_jur_selected ? [sourceResult.id] : [];
            allContribs[contribution.data.id].has_jur_selected = sourceResult.has_jur_selected;
          }
        });
        return allContribs;
      }, Object.assign({}, state));
    case types.LOAD_RESULTS:
      return action.results.reduce((allContribs, sourceResult) => {
        sourceResult.data.forEach((contribution) => {
          allContribs[contribution.data.id] = normalizeContribution(contribution);
          allContribs[contribution.data.id].sourceId = sourceResult.source;
          allContribs[contribution.data.id].sourceResults = [sourceResult.id];
        });
        return allContribs;
      }, Object.assign({}, state));
    case types.LOAD_INDIVIDUAL_RESULT:
      const sourceResult = action.data;
      return action.data.data.reduce((allContribs, contribution) => {
        allContribs[contribution.data.id] = normalizeContribution(contribution);
        allContribs[contribution.data.id].sourceId = sourceResult.source;
        allContribs[contribution.data.id].sourceResults = [sourceResult.id]; // todo: need to keep track of all of these if repeated, or just in jurisdiction?
        return allContribs;
      }, Object.assign({}, state));
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case types.CLEAR_RESULTS:
      return [];
    case SET_CONTRIBUTIONS: {
      return action.contributions.map(contrib => contrib.data.id);
    }
    case APPEND_CONTRIBUTIONS: {
      const contribsToAppend = action.contributions.map(contrib => contrib.data.id);
      return [...state, ...contribsToAppend];
    }
    default:
      return state;
  }
};

const contributions = combineReducers({
  byId,
  allIds,
});

export default contributions;
