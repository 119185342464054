import * as types from './types';
import * as modalActions from './modal/actions';


export const expireSession = () => ({
  type: types.EXPIRE_SESSION,
});

export const setDateFilter = dateFilter => ({
  type: types.SET_DATE_FILTER,
  dateFilter: dateFilter || { to: null, from: null },
});

export const toggleContribution = (ids, active) => ({
  active,
  contribIds: ids,
  type: types.TOGGLE_CONTRIB,
});

export const { openModal, closeModal } = modalActions;

export const resetUI = () => ({
  type: types.RESET_UI,
});
