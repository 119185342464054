import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField, ErrorMessage } from 'formik';
import MacroTextAreaWrapper from 'components/shared/emailTemplates/MacroTextAreaWrapper';


const TemplateArea = ({
  label, variables, links, readOnly, macroLinkHelpText, ...props
}) => {
  const [field, meta, helpers] = useField(props);

  return (
    <div className="form-field template-wrapper">
      <div
        htmlFor={field.name}
        className="input-title"
      >
        {label}
      </div>
      <MacroTextAreaWrapper
        textAreaValue={field.value}
        onBodyChange={(val) => { helpers.setValue(val); }}
        render={({
          onMouseUp,
          onKeyUp,
          onKeyDown,
          textAreaRef,
          insertMacro,
        }) => (
          <>
            <div className="form-field">
              <Field
                {...field}
                as="textarea"
                rows={10}
                id="template-body"
                onMouseUp={onMouseUp}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                innerRef={textAreaRef}
                disabled={readOnly}
              />
              <ErrorMessage
                name={field.name}
                component="div"
                className="error-text"
              />
            </div>
            <div className="form-field sub-field">
              <div className="input-title">
                Insert Variables
              </div>
              <div className="macro-list">
                {variables.map((variable) => (
                  <button
                    key={variable}
                    type="button"
                    className="macro-item"
                    onClick={() => { insertMacro(variable); }}
                    disabled={readOnly}
                  >
                    {variable}
                  </button>
                ))}
              </div>
            </div>
            {links && links.length ? (
              <div className="form-field">
                <div className="input-title">
                  Insert Links
                </div>
                <div className="help-text">
                  {macroLinkHelpText}
                </div>
                <div className="macro-list">
                  {links.map((link) => (
                    <button
                      key={link}
                      type="button"
                      className="macro-item"
                      onClick={() => insertMacro(link)}
                      disabled={readOnly}
                    >
                      {link}
                    </button>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        )}
      />

    </div>
  );
};

TemplateArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  variables: PropTypes.arrayOf(PropTypes.string).isRequired,
  links: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool,
  macroLinkHelpText: PropTypes.string,
};

TemplateArea.defaultProps = {
  readOnly: false,
  macroLinkHelpText: '',
};

export { TemplateArea };
