import { toast } from 'react-toastify';
import { _addUpload, canceler } from '../../../utils/apiUtil';
import * as types from '../types';
import api from '../../../api';

const trackUploadInStore = (fileObj, rowId) => ({
  type: types.ADD_UPLOAD,
  name: fileObj.name,
  rowId,
});

const untrackUploadInStore = (rowId, fileName) => ({
  type: types.REMOVE_UPLOAD,
  rowId,
  fileName,
});

const updateUploadInStore = (fileName, rowId, progress, hasError, loading) => ({
  type: types.UPDATE_PROGRESS,
  rowId,
  fileName,
  progress,
  hasError: !!hasError,
  loading,
});

export const removeUpload = (fileName, rowId) => (dispatch, getState) => {

  const uploadsForContrib = getState().preclearance.uploads[rowId];
  if (uploadsForContrib) {
    const fileIndex = uploadsForContrib.findIndex(storedFile => storedFile.name === fileName);
    if (fileIndex < 0) {
      toast.error('File was not found for this record.');
      return new Promise((res) => { res(); });
    }
    if (fileIndex >= 0 && uploadsForContrib[fileIndex].progress < 100) {
      canceler.cancel();
      dispatch(untrackUploadInStore(rowId, fileName));
      return new Promise((res) => { res(); });
    } else if (uploadsForContrib[fileIndex].hasError) {
      dispatch(untrackUploadInStore(rowId, fileName));
      return new Promise((res) => { res(); });
    }
  }
  return api.delete(`/custom/rows/${rowId}/delete_attachment/`, {
    params: { filename: fileName }
  }).then(() => {
    dispatch(untrackUploadInStore(rowId, fileName));
  }).catch((err) => {
    toast(err.response.data);
    throw err;
  });
};

const getUploadUrl = (fileName, rowId) => {
  return api.post(`/custom/rows/${rowId}/get_upload_permissions/`, {
    filename: fileName,
  }).catch((err) => {
    toast(err.response.data);
    throw err;
  });
};

export const retryUpload = (file, rowId) => (dispatch, getState) => {
  const filesForContribution = getState().uploads[rowId] || [];
  const fileToRetry = filesForContribution.find(contribFile => file.name === contribFile.name);
  if (!fileToRetry) {
    return;
  }
  dispatch(updateUploadInStore(file.name, rowId, 0, false, true));
  setTimeout(() => {
    getUploadUrl(file.name, rowId).then(({ data }) => {
      fileToRetry.uploadObj.open('POST', data.url);
      const formData = new FormData();
      Object.keys(data.fields).forEach((key) => {
        formData.append(key, data.fields[key]);
      });
      formData.append('file', file);
      fileToRetry.uploadObj.send(formData);
    });
  }, 1000);
};

export const getUpload = (fileName, rowId) => () => {
  return api.post(`/custom/rows/${rowId}/get_download_permissions/`, {
    filename: fileName,
  }).catch((err) => {
    toast(err.response.data);
    throw err;
  });
};

export const addUpload = (fileObj, objId) => (dispatch) => {
  return getUploadUrl(fileObj.name, objId).then(({ data }) => {

    dispatch(trackUploadInStore(fileObj, objId));
    const updatePercentage = (percentage, hasError) => dispatch(updateUploadInStore(fileObj.name, objId, percentage, hasError, true));

    return _addUpload(fileObj, data, updatePercentage).then(() => {
      return api.patch(`/custom/rows/${objId}/check_file/`, {
        filename: fileObj.name,
      }).then(() => {
        return dispatch(updateUploadInStore(fileObj.name, objId, 100, false, false));
      });
    });
  });
};
