import { cloneDeep } from 'lodash';
import { filterBlanks } from './searchSerializer';


export const serializeResearchSearch = (searchInfo) => {
  const searchRecord = cloneDeep(searchInfo);

  const {
    first_name,
    last_name,
    strict_last_name,
    alt_firsts,
    alt_lasts,
    zips,
    states,
    employers,
    excluded_addresses,
    excluded_employers,
    excluded_occupations,
    all_sources,
  } = searchRecord;


  // MUST filter out blank entries for altFirsts,
  // altLasts, and zipcodes otherwise an error will be thrown!
  const toReturn = {
    first_name,
    last_name,
    strict_last_name: strict_last_name || false,
    alt_firsts: filterBlanks(alt_firsts),
    alt_lasts: filterBlanks(alt_lasts),
    zips: filterBlanks(zips),
    employers: filterBlanks(employers),
    sources: Array.from(searchRecord.sources),
    states: states || [],
    excluded_addresses: filterBlanks(excluded_addresses),
    excluded_employers: filterBlanks(excluded_employers),
    excluded_occupations: filterBlanks(excluded_occupations),
    settings: {},
    all_sources,
  };


  return toReturn;
};
