import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField, ErrorMessage } from 'formik';

const RadioField = ({ label, choices, ...props }) => {
  const [field] = useField(props);
  const chosen = choices.filter(choice => choice.value === field.value)[0];

  return (
    <div className="horizontal-radio-buttons">
      {label && <label>{label}</label>}
      <ul className="radio-ul" role="group">
        {choices.map(choice => (
          <li key={choice.value}>
            <label>
              <Field
                id="radio-button"
                type="radio"
                name={field.name}
                value={choice.value}
                className="radio-ul"
              />
              {choice.label}
            </label>
          </li>
        ))}
      </ul>
      {chosen && chosen.description && (
        <div className="description">
          <span>{chosen.description}</span>
        </div>
      )}
      <ErrorMessage
        name={field.name}
        component="div"
        className="error-text"
      />
    </div>
  );
};

RadioField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
      ]).isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
  validate: PropTypes.func,
};

RadioField.defaultProps = {
  validate: () => undefined,
};

export { RadioField };
