import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import StatusItem from 'components/shared/editableItem';
import EditItem from 'components/shared/editItem';
import SettingsTable from 'components/shared/settingsTable';
import Confirmation from 'components/preclearance/settings/changeConfirmation';
import { STATUS_LIMIT } from 'utils/constants';
import Checkbox from 'components/monitoringForm/sourceSelection/checkbox';
import OpenItemWrapper, { CONFIRMATION_TYPES } from 'components/shared/openItemWrapper';

const excludeHeader = (
  <span className="flex-wrapper">
    Excluded
    <span className="tooltip-wrapper flex-wrapper">
      <Tippy content='Records with a status marked as "excluded" will not be included in dashboard charts metrics.'>
        <i className="material-icons">info</i>
      </Tippy>
    </span>
  </span>
);

function ExcludeCheckbox({ checked, onFieldChange }) {
  return (
    <div className="table-cell exclude-status">
      <Checkbox
        checked={checked}
        id={'status-exclude'}
        onChange={(e) => { onFieldChange(e.target.checked, 'exclude'); }}
      >
        <span className="input-title">Excluded</span>
      </Checkbox>
    </div>
  );
}

ExcludeCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onFieldChange: PropTypes.func.isRequired,
};


function errorCb(err) {
  const { data, status } = err.response;
  let error = 'There was a problem updating this status';
  if (status) {
    if (data && data.detail) {
      error = data.detail;
    } else if (data && data.label) {
      error = Array.isArray(data.label) ? data.label[0] : data.label;
    } else if (data && data.non_field_errors) {
      error = Array.isArray(data.non_field_errors) ? data.non_field_errors[0] : data.non_field_errors;
    } else if (data && Array.isArray(data)) {
      error = data[0];
    }
  }
  return error;
}

const maxCharLimit = 15;

function EditStatuses({ mainHeader, defaultStatusHelpText, additionalStatusesHelpText,
  recordType, statuses, allowExclusion, createStatus, editStatus, removeStatus,
  disableEditing, renderRelatedData }) {
  const nonDefaultStatuses = Object.values(statuses).filter(s => s.default === false);
  const defaultStatus = Object.values(statuses).find(status => status.default);
  const additionalLabels = nonDefaultStatuses.length > 0;
  const excludeCol = allowExclusion ? excludeHeader : '';
  const disabledText = 'Only managers may edit status labels';

  const findErrors = (status) => {
    const { label } = status;
    if (!label.trim()) {
      return 'Status labels must be a minimum of 1 character';
    }
    if (label.length > maxCharLimit) {
      return `Status labels must be less than ${maxCharLimit} characters long.`;
    }
    const notUnique = Object.values(statuses).some(s =>
      s.id !== status.id && status.label === s.label,
    );
    if (notUnique) {
      return 'This status already exists.';
    }
  };

  return (
    <div>
      <h1>
        { mainHeader }
      </h1>
      <p className="subheader">Default Status</p>
      <div className="help-text">
        { defaultStatusHelpText }
      </div>
      <OpenItemWrapper
        findErrors={findErrors}
        errorCb={errorCb}
        createFunc={createStatus}
        updateFunc={editStatus}
        deleteFunc={removeStatus}
        entityName={'status'}
        render={({
          openField,
          toggleEditing,
          onFieldChange,
          onCreate,
          onUpdate,
          onDelete,
          errors,
          showConfirmation,
          toggleConfirmationType,
          toggleConfirmation,
          confirmationType,
        }) => {
          const prevStatus = openField && openField.id ? statuses[openField.id] : '';
          const prevLabel = prevStatus.label || '';
          const nextLabel = openField ? openField.label : '';
          const isDefault = openField ? openField.default : false;

          return (
            <div>
              <SettingsTable
                wrapperClass={'default-status'}
                leftColumn={'Status'}
                rightColumns={['Edit']}
              >
                <StatusItem
                  showDelete={false}
                  value={(openField && openField.id === defaultStatus.id) ? openField.label : defaultStatus.label}
                  onFieldChange={(e) => { onFieldChange(e.target.value, 'label'); }}
                  toggleEditing={() => toggleEditing(defaultStatus)}
                  onClose={() => toggleEditing(null)}
                  onSubmit={() => { toggleConfirmationType(CONFIRMATION_TYPES.update)(defaultStatus); }}
                  editing={(openField && openField.id === defaultStatus.id)}
                  errors={errors}
                  disabled={disableEditing}
                  disabledText={disabledText}
                />
              </SettingsTable>
              { additionalLabels ?
                <div className="additional-labels">
                  <p className="subheader">Additional Statuses</p>
                  <div className="help-text">
                    { additionalStatusesHelpText }
                  </div>
                  <SettingsTable
                    leftColumn={'Status'}
                    rightColumns={[excludeCol, 'Edit']}
                  >
                    {nonDefaultStatuses.map((s) => {
                      const editing = openField && openField.id === s.id;
                      return (
                        <StatusItem
                          key={s.id}
                          editing={editing}
                          value={editing ? openField.label : s.label}
                          onFieldChange={(e) => { onFieldChange(e.target.value, 'label'); }}
                          toggleEditing={() => toggleEditing(s)}
                          onClose={() => toggleEditing(null)}
                          onSubmit={() => { toggleConfirmationType(CONFIRMATION_TYPES.update)(s); }}
                          onDelete={() => { toggleConfirmationType(CONFIRMATION_TYPES.delete, false)(s.id); }}
                          errors={errors}
                          confirmationShowing={Boolean(showConfirmation)}
                          preEditCells={editing && allowExclusion ?
                            <ExcludeCheckbox
                              checked={openField.exclude}
                              onFieldChange={onFieldChange}
                            />
                            :
                            <div className="table-cell exclude-status">
                              {s.exclude && allowExclusion ?
                                <div className="flex-wrapper"><i className="material-icons">check</i><span>Excluded</span></div>
                                : ''}
                            </div>}
                          disabled={disableEditing}
                          disabledText={disabledText}
                        />
                      );
                    })}
                  </SettingsTable>
                </div>
                : ''}
              <div className="table-wrapper">
                <div className="table-container adding">
                  { openField && !openField.id ?
                    <div className={`${additionalLabels ? 'indent' : 'no-padding'}`}>
                      {additionalLabels ? '' : <p className="subheader">Additional Statuses</p>}
                      <EditItem
                        value={openField.label}
                        buttonText={'Add'}
                        showDelete={false}
                        onFieldChange={(e) => { onFieldChange(e.target.value, 'label'); }}
                        onClose={() => toggleEditing(null)}
                        errors={errors}
                        onSubmit={onCreate}
                        confirmationShowing={Boolean(showConfirmation)}
                        extraChildren={allowExclusion ?
                          <ExcludeCheckbox
                            checked={openField.exclude}
                            onFieldChange={onFieldChange}
                          /> : null
                        }
                      />
                    </div>
                    : (
                      <Tippy
                        disabled={!(disableEditing || Object.values(statuses).length >= STATUS_LIMIT)}
                        content={disableEditing ? disabledText :
                          `There is a limit of ${STATUS_LIMIT} status labels.`}
                      >
                        <span className={`button-wrapper ${additionalLabels ? 'indent' : ''}`}>
                          <button
                            type="button"
                            onClick={() => { toggleEditing({ label: '', exclude: false }); }}
                            className="text-button uppercase add-field-button"
                            disabled={disableEditing || Object.values(statuses).length >= STATUS_LIMIT}
                          >
                            + Add custom status
                          </button>
                        </span>
                      </Tippy>
                    )}
                </div>
              </div>
              { showConfirmation && confirmationType === CONFIRMATION_TYPES.delete ?
                <Confirmation
                  onSubmit={onDelete(showConfirmation)}
                  onCancel={() => { toggleConfirmation(null); }}
                  confirmText={confirmationType}
                  actionText={'disabled'}
                  headerText={'Delete Status'}
                  confirmSentence={(<span>Are you sure you would like to delete the label <strong>{`"${prevLabel}"`}</strong>?</span>)}
                  showConfirmation
                  editedStatus={openField}
                >
                  <React.Fragment>
                    <div className="message">
                      This will revert all {recordType} records marked as <strong>{`"${prevLabel}"`}</strong> to the default status of <strong>{`"${defaultStatus.label}"`}</strong>?
                    </div>
                    {renderRelatedData(openField.id, true)}
                  </React.Fragment>

                </Confirmation> : ''
              }
              { showConfirmation && confirmationType === CONFIRMATION_TYPES.update ?
                <Confirmation
                  onSubmit={onUpdate}
                  onCancel={() => { toggleConfirmation(null); }}
                  confirmText={confirmationType}
                  actionText={'changed'}
                  headerText={'Update Status'}
                  confirmSentence={(<span>Are you sure you would like to change the {isDefault ? 'default' : ''} label <strong>{`"${prevLabel}"`}</strong> to <strong>{`"${nextLabel}"`}</strong>?</span>)}
                  editedStatus={openField}
                >
                  <React.Fragment>
                    <div className="message">
                      This will change any {recordType} records currently marked as <strong>{`"${prevLabel}"`}</strong> to <strong>{`"${nextLabel}"`}</strong>{isDefault ? ', as well as any new records that are added to the platform' : ''}.
                      {
                        openField && openField.exclude ? ' Marking this status as "excluded" means that any record with this status will be will not be included in dashboard charts metrics.' : ''
                      }
                    </div>
                    {renderRelatedData(openField.id, false)}
                  </React.Fragment>
                </Confirmation> : ''
              }
            </div>

          );
        }}
      />
    </div>
  );
}


EditStatuses.propTypes = {
  statuses: PropTypes.object.isRequired,
  createStatus: PropTypes.func.isRequired,
  editStatus: PropTypes.func.isRequired,
  removeStatus: PropTypes.func.isRequired,
  mainHeader: PropTypes.string,
  defaultStatusHelpText: PropTypes.string,
  additionalStatusesHelpText: PropTypes.string,
  recordType: PropTypes.string,
  allowExclusion: PropTypes.bool,
  disableEditing: PropTypes.bool,
  renderRelatedData: PropTypes.func.isRequired,
};

EditStatuses.defaultProps = {
  mainHeader: '',
  defaultStatusHelpText: '',
  additionalStatusesHelpText: '',
  recordType: '',
  allowExclusion: false,
  disableEditing: false,
};

export default EditStatuses;
