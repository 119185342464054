import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import SourceSelection from './sourceSelection';


function SourceSelectionWrapper({ field, sources, selected }) {
  const { setFieldValue } = useFormikContext();

  function toggleSource(toggledSources, active, indeterminate) {
    const updatedSources = new Set(selected);
    if (active && !indeterminate) {
      toggledSources.forEach(s => updatedSources.add(s));
    } else {
      toggledSources.forEach(s => updatedSources.delete(s));
    }
    setFieldValue(field, updatedSources);
  }

  return (
    <SourceSelection
      onToggleSource={toggleSource}
      sources={sources}
      selected={selected}
    />
  );
}

SourceSelectionWrapper.propTypes = {
  sources: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};

export default SourceSelectionWrapper;
