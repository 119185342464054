import React from 'react';
import PropTypes from 'prop-types';
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import { SCROLLABLE_CONTAINER } from 'utils/constants';


function Table({ rows, rowRenderer, noRowsRenderer, rowHeight }) {
  return (
    <WindowScroller scrollElement={SCROLLABLE_CONTAINER}>
      {
        ({ height, isScrolling, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight>
            {
              ({ width }) => (
                <List
                  height={height}
                  autoHeight
                  rowCount={rows.length}
                  rowRenderer={rowRenderer}
                  noRowsRenderer={noRowsRenderer}
                  rowHeight={rowHeight}
                  width={width}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  scrollTop={scrollTop}
                />
              )
            }
          </AutoSizer>
        )
      }
    </WindowScroller>
  );
}


Table.propTypes = {
  rows: PropTypes.array.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  noRowsRenderer: PropTypes.func.isRequired,
  rowHeight: PropTypes.number.isRequired,
};

export default Table;
