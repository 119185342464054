import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Section from 'components/shared/settingsPage/section';
import { updateOrgUser, deleteOrgUser } from 'reducers/organization/actions';
import UserTable from './userTable';
import { NewUserForm, EditUserForm } from './userForm';
import DeleteConfirmation from './deleteConfirmation';
import { hasManagePasswordPermissions, hasCreateUserPermissions } from '../../../helpers/permissions';


class ManageUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: null,
      deleting: null,
      adding: false,
    };
  }

  renderAddUser() {
    const { remainingUsers, canCreateUser } = this.props;
    return (
      <div className="add-user-div">
        <span>{`${remainingUsers} new user${remainingUsers === 1 ? '' : 's'} remaining`}</span>
        {canCreateUser && <button disabled={remainingUsers <= 0} onClick={() => this.setState({ adding: true })}>add user</button>}
      </div>
    );
  }

  renderDeleteConf() {
    if (!this.state.deleting) return null;
    return (
      <DeleteConfirmation
        userName={this.state.deleting.full_name.trim() || this.state.deleting.email}
        onSubmit={() => this.props.deleteOrgUser(this.state.deleting.id).then(() => this.setState({ deleting: null }))}
        close={() => this.setState({ deleting: null })}
      />
    );
  }

  render() {
    if (this.state.adding || this.state.editing) {
      const Form = this.state.adding ? NewUserForm : EditUserForm;
      return (
        <Form
          user={this.state.editing}
          submitCb={() => { this.setState({ editing: null, adding: false }); }}
          onCancel={() => { this.setState({ editing: null, adding: false }); }}
        />
      );
    }

    const { orgName, users, showTwoFactor } = this.props;
    return (
      <Section
        header={orgName}
        helpText={this.renderAddUser()}
        containerClass={'organizationPage'}
      >
        <UserTable
          rows={Object.values(users)}
          headers={this.headers}
          showTwoFactor={showTwoFactor}
          onEdit={user => () => this.setState({ editing: user })}
          onDelete={user => () => this.setState({ deleting: user })}
        />
        {this.renderDeleteConf()}
      </Section>
    );
  }
}

ManageUsers.propTypes = {
  remainingUsers: PropTypes.number.isRequired,
  orgName: PropTypes.string.isRequired,
  canCreateUser: PropTypes.bool.isRequired,
  showTwoFactor: PropTypes.bool.isRequired,
  users: PropTypes.object.isRequired,
  deleteOrgUser: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user, organization: { name, users, remaining_users } }) => ({
  orgName: name,
  users,
  remainingUsers: remaining_users,
  canCreateUser: hasCreateUserPermissions(user),
  showTwoFactor: hasManagePasswordPermissions(user),
});

export default connect(mapStateToProps, {
  updateOrgUser,
  deleteOrgUser,
})(ManageUsers);
