import { useState } from 'react';


function useModal(defaultOpen) {
  const [isOpen, setModalOpen] = useState(defaultOpen);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  return [isOpen, openModal, closeModal];
}

export default useModal;
