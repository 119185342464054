import React from 'react';
import PropTypes from 'prop-types';


const ListItem = ({
  item,
  icon,
  direction,
  handleSelect,
  searchType,
  archived,
}) => (
  <div className={`${direction} list-item`}>
    <button
      className={`${direction}`}
      onClick={handleSelect}
      type="button"
    >
      <div>
        { item }
        {' '}
        {searchType && `(${searchType.toLowerCase()})`}
        <i
          className="archived"
        >
          { archived ? ' (archived)' : ''}
        </i>
      </div>
      <i className="material-icons">{ icon }</i>
    </button>
  </div>
);

ListItem.propTypes = {
  item: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  searchType: PropTypes.string,
  archived: PropTypes.bool,
};

ListItem.defaultProps = {
  searchType: '',
  archived: false,
};

export default ListItem;
