import LogRocket from 'logrocket';
import { useEffect } from 'react';

export const LogRocketWrapper = ({ children }) => {
  useEffect(() => {
    if (window.FULLSTORY_ENABLED) {
      LogRocket.init('gwtdzo/illumis', {
        network: {
          isEnabled: false,
        },
      });
    }
  }, []);
  return (
    children
  );
};
