import React from 'react';
import PropTypes from 'prop-types';
import Confirmation from '../confirmationShared';

const GenConfirmation = ({
  close,
  onSubmit,
  userName,
}) => (
  <Confirmation
    open
    close={close}
    confirmText={'Delete'}
    dismissText={'Cancel'}
    onSubmit={onSubmit}
  >
    <div>
      <h2>Delete User</h2>
      <div className="message">
          Are you sure you want to delete {userName}?
      </div>
    </div>
  </Confirmation>
);

GenConfirmation.propTypes = {
  userName: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

GenConfirmation.defaultProps = {
  isDefault: false,
  defaultLabel: '',
};

export default GenConfirmation;
