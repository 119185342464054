import * as fromById from './byId';
import * as fromList from './createList';

export const getSearchObjs = (state => state.searchObjects.byId);

export const byId = ((state, id) => (state.searchObjects.byId[id]));

export const getSearchesByType = (state, searchType) => {
  const ids = fromList.getIds(state.searchObjects.listByType[searchType]);
  return ids.map(id => fromById.getSearch(state.searchObjects.byId, id));
};
