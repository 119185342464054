import React from 'react';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';


export const DateFilter = () => (
  <>
    <span className="input-title">
      Filter
    </span>
    <div className="range-filter-wrapper">
      <SchemaField
        id="filter.from"
        label=""
        fieldType="TextField"
        className="form-input"
        placeholder="mm/dd/yyyy"
        autoFocus
      />
      <span>
        to
      </span>
      <SchemaField
        id="filter.to"
        label=""
        fieldType="TextField"
        className="form-input"
        placeholder="mm/dd/yyyy"
      />
    </div>
  </>
);
