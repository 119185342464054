import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  List, WindowScroller, AutoSizer, CellMeasurer, CellMeasurerCache,
} from 'react-virtualized';
import Schema from 'components/shared/sortableSchema';
import Checkbox from '../../monitoringForm/sourceSelection/checkbox';
import ResultRow from '../recordItem/record';
import ToolTip from '../../toolTip';
import { SCROLLABLE_CONTAINER, CONTRIB_SCHEMA, JUR_CONTRIB_SCHEMA } from '../../../utils/constants';

import { toggleContribution } from '../../../reducers/ui/actions';
import { updateContributionStatus, createComment, createContrib } from '../../../reducers/contributions/actions';

const [DSC, ASC] = ['dsc', 'asc']; // these coorespond to the sort field in a source schema

class RecordTable extends Component {
  constructor(props) {
    super(props);
    const schema = Object.values(this.props.schemas)[0];
    let sortIndex = schema.findIndex(s => !!s.sort);
    if (sortIndex < 0) {
      sortIndex = 0;
    }
    this.state = {
      sortBy: schema[sortIndex].name,
      sortDirection: schema[sortIndex].sort || DSC,
      data: [],
    };

    // this.contribSchema = this.props.showContribSchema ? CONTRIB_SCHEMA : [];
    this.contribSchema = this.props.isJurisdiction ? JUR_CONTRIB_SCHEMA : (this.props.showContribSchema ? CONTRIB_SCHEMA : []);
    this.sortData = this.sortData.bind(this);
    this.onLabelClick = this.onLabelClick.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.onPageResize = this.onPageResize.bind(this);
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      keyMapper: rowIndex => (this.sorted[rowIndex].data.es_id), // id in research is null
    });
  }

  componentWillMount() {
    this.sortData(this.state.sortBy, this.state.sortDirection);
  }

  componentWillReceiveProps({ results }) {
    // todo statusMismatch is always true, this needs to be looked at and refactored in a correct way
    const lenMismatch = this.props.results.length !== results.length;
    const resultIdMismatch = false; // this.props.results.some((r, i) => r.id !== results[i].id); //todo??
    const statusMismatch = this.props.results.some((record, index) =>
      record.meta.contribution_status !== results[index].contribution_status ||
      record.meta.contribution_notes !== results[index].contribution_notes,
    );
    if (lenMismatch || resultIdMismatch || statusMismatch) {
      this.sortData(this.state.sortBy, this.state.sortDirection, results);
    }
  }

  onPageResize() {
    setTimeout((_) => {
      if (this.cache && this.list) {
        this.cache.clearAll();
        this.list.recomputeRowHeights();
      }
    }, 0);
  }

  onLabelClick(column) {
    this.props.scrollToTableTop((_) => {
      const sortDirection = this.state.sortBy === column ? (this.state.sortDirection === DSC ? ASC : DSC) : DSC;
      const sortBy = column;
      this.sortData(sortBy, sortDirection);
      this.setState({
        sortDirection,
        sortBy,
      });
    });
  }

  sortData(sortBy, sortDirection, data) {
    if (!data) {
      data = this.props.results || [];
    }
    if (sortBy === 'contribution_status') {
      this.sorted = data.sort((a, b) => {
        let [strA, strB] = [a, b].map(s => (s.meta[sortBy] || ''));
        strA = this.props.statuses[strA];
        strA = strA ? strA.label.toLowerCase() : '';
        strB = this.props.statuses[strB];
        strB = strB ? strB.label.toLowerCase() : '';
        if (strA === strB) {
          return (a.data.id > b.data.id ? 1 : -1) * (sortDirection === ASC ? 1 : -1);
        }
        return (strA > strB ? 1 : -1) * (sortDirection === ASC ? 1 : -1);
      });
    } else if (sortBy === 'contribution_notes' || sortBy === 'matches') {
      this.sorted = data.sort((a, b) => {
        let [strA, strB] = [a, b].map(s => (s.meta[sortBy] || ''));
        strA = typeof (strA) === 'string' ? strA.toLowerCase() : strA;
        strB = typeof (strB) === 'string' ? strB.toLowerCase() : strB;
        if (strA === strB) {
          return (a.data.id > b.data.id ? 1 : -1) * (sortDirection === ASC ? 1 : -1);
        }
        return (strA > strB ? 1 : -1) * (sortDirection === ASC ? 1 : -1);
      });
    } else if (sortBy === 'date') {
      this.sorted = data.sort((a, b) => {
        let [strA, strB] = [a, b].map(s => (s.data[sortBy] || ''));
        strA = typeof (strA) === 'string' ? strA.toLowerCase() : strA;
        strB = typeof (strB) === 'string' ? strB.toLowerCase() : strB;

        // break ties using id
        if (strA === strB) {
          return (a.data.id > b.data.id ? 1 : -1) * (sortDirection === ASC ? 1 : -1);
        }

        // empty strings always go last
        if (strA === '') {
          return 1;
        }
        if (strB === '') {
          return -1;
        }

        return (strA > strB ? 1 : -1) * (sortDirection === ASC ? 1 : -1);
      });
    } else {
      this.sorted = data.sort((a, b) => {
        let [strA, strB] = [a, b].map(s => (s.data[sortBy] || ''));
        strA = typeof (strA) === 'string' ? strA.toLowerCase() : strA;
        strB = typeof (strB) === 'string' ? strB.toLowerCase() : strB;

        if (strA === strB) {
          return (a.data.id > b.data.id ? 1 : -1) * (sortDirection === ASC ? 1 : -1);
        }
        return (strA > strB ? 1 : -1) * (sortDirection === ASC ? 1 : -1);
      });
    }
    this.cache.clearAll();
  }


  rowRenderer({ key, index, style, parent }) {
    const contribution = this.sorted[index];
    const hasJurSelected = !this.props.isJurisdiction || contribution.sourceResults.some(resId => this.props.sourceResultIds.indexOf(resId) >= 0);
    const isLast = this.props.isLast && (index === (this.sorted.length - 1));
    return (
      <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <div
          className={`dashboard-record-wrapper ${isLast ? 'last ' : ''}`}
          style={style}
        >
          <ResultRow
            showContribSchema={this.props.showContribSchema}
            sortBy={this.state.sortBy}
            schema={this.props.schemas[contribution.sourceId]}
            contribSchema={this.contribSchema}
            data={contribution.data}
            meta={contribution.meta}
            selectedContribs={this.props.selectedContribs}
            toggleContribution={this.props.toggleContribution}
            setSectionStatus={this.props.setSectionStatus}
            createComment={hasJurSelected ? this.props.createComment : this.props.createContrib}
            hasJurSelected={hasJurSelected}
            statuses={this.props.statuses}
            sourceId={contribution.sourceId}
            sourceCat={contribution.meta.category}
            isJurisdiction={this.props.isJurisdiction}
            objId={this.props.objId}
          />
        </div>
      </CellMeasurer>
    );
  }


  renderSchema(schema, isFixed = false) {
    const { selectedContribs, sourceResultIds, showContribSchema, isJurisdiction } = this.props;
    const { sortBy, sortDirection } = this.state;

    const contribIds = this.sorted.map(d => (!isJurisdiction || d.has_jur_selected) && d.meta.contribution_id).filter(Boolean);

    const checked = contribIds.reduce((prev, c) => prev && selectedContribs.has(c), true) && contribIds.length > 0;
    const hasSelected = contribIds.filter(c => selectedContribs.has(c)).length > 0;
    const indeterminate = !checked && hasSelected;

    const isDisabled = contribIds.length === 0;

    // Should probably move this copy to somewhere importable.
    const disabledTooltipText = 'No records in this section were explicitly requested via the sources selected on this search.';
    return (
      <div className="dashboard-record">
        { showContribSchema ?
          <div
            className="record-checkbox"
          >
            <Checkbox
              id={`source-${this.props.id}-${sourceResultIds.join()}${isFixed ? '-fixed' : ''}`}
              checked={checked}
              onChange={(e) => {
                if (contribIds.length) {
                  this.props.toggleContribution(contribIds, e.target.checked);
                  this.list.forceUpdate();
                }
              }}
              indeterminate={indeterminate}
              disabled={isDisabled}
            >
              <span />
            </Checkbox>
            {isDisabled ?
              <ToolTip position={'right'}><span>{disabledTooltipText}</span></ToolTip> : ''
            }
          </div>
          : null
        }
        <Schema
          schema={[...schema, ...this.contribSchema]}
          onLabelClick={this.onLabelClick}
          sortBy={sortBy}
          sortDirection={sortDirection}
          ascendingSort={ASC}
        />
      </div>
    );
  }

  render() {
    const schema = Object.values(this.props.schemas)[0];
    const data = this.sorted;
    return (
      <div className="source-table" data-length={data.length}>
        <div className="schema-container">
          <div className="schema-header">
            {this.renderSchema(schema)}
          </div>
          <div className="fixed-header">
            {
              this.renderSchema(schema, true)
            }
          </div>
        </div>
        <WindowScroller onResize={this.onPageResize} scrollElement={SCROLLABLE_CONTAINER}>
          {
            ({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => (
              <AutoSizer disableHeight>
                {
                  ({ width }) => (
                    <div style={{ width: width - 2 }} ref={(el) => { registerChild(el); }}>
                      <List
                        ref={(el) => { this.list = el; }}
                        height={height || 500}
                        autoHeight
                        rowCount={this.sorted.length}
                        rowRenderer={this.rowRenderer}
                        rowHeight={({ index }) => this.cache.rowHeight({ index })}
                        deferredMeasurementCache={this.cache}
                        width={width - 2}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        scrollTop={scrollTop}
                      />
                    </div>
                  )
                }
              </AutoSizer>
            )
          }
        </WindowScroller>
      </div>
    );
  }
}

// jd view:
  // id: jdId
  // objId: current searchObj id
// searchObj view
  // id: searchObj id
  // objId: sourceid

RecordTable.propTypes = {
  results: PropTypes.array.isRequired,
  scrollToTableTop: PropTypes.func.isRequired,
  selectedContribs: PropTypes.object.isRequired,
  toggleContribution: PropTypes.func.isRequired,
  setSectionStatus: PropTypes.func,
  createComment: PropTypes.func.isRequired,
  createContrib: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  schemas: PropTypes.object.isRequired,
  statuses: PropTypes.object.isRequired,
  sourceResultIds: PropTypes.array.isRequired,
  isJurisdiction: PropTypes.bool.isRequired,
  showContribSchema: PropTypes.bool, // determines whether to render checkboxes, notes, and statuses.
  objId: PropTypes.number,
};

RecordTable.defaultProps = {
  setSectionStatus: () => { },
  isLast: false,
  sourceResultIds: [],
  showContribSchema: true,
  objId: 0,
};

export default connect(
  null,
  {
    toggleContribution,
    setSectionStatus: updateContributionStatus,
    createComment,
    createContrib,
  },
)(RecordTable);
