import { connect } from 'react-redux';
import RecordGroup from '../shared/searchResults/recordGroup';

const mapStateToProps = (state, props) => {
  const sourceResult = Object.values(state.research.sourceResults).find(res => res.source === props.source.id); // todo: selector
  const schemas = { [props.source.id]: props.source.person_schema };
  const results = sourceResult.data.map(result => ({ ...result, sourceId: props.source.id })); // normally this happens in reducer. todo: do that there
  return {
    selectedContribs: state.ui.selectedContribs,
    statuses: state.statuses,
    results,
    schemas,
    sourceResultIds: [sourceResult.id],
    isJurisdiction: false,
    url: props.source.url,
    resultCount: results.length,
  };
};

export default connect(mapStateToProps)(RecordGroup);
