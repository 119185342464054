import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { IS_IE_TEN } from 'utils/constants';
import { CHART_HEIGHT, LABEL_STYLES } from './consts';

export function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className="recharts-default-tooltip">
        <p className="recharts-tooltip-label">
          {label}: {payload && payload[0].value.overrideLocaleString()}
        </p>
      </div>
    );
  }
  return null;
}

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  label: PropTypes.string,
  active: PropTypes.bool,
};

CustomTooltip.defaultProps = {
  payload: [],
  label: '',
  active: false,
};

function CustomizedAxisTick({ x, y, payload, textAnchor }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={12}
        textAnchor={textAnchor}
        style={LABEL_STYLES}
      >
        <tspan>{payload.value}</tspan>
      </text>
    </g>
  );
}

CustomizedAxisTick.propTypes = {
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
  textAnchor: PropTypes.string,
};

CustomizedAxisTick.defaultProps = {
  payload: {},
};

const gridColor = '#ECECEC';
const barColor = '#FF6159';

function SimpleBarChart({ data, nameKey, chartRef }) {
  return (
    <ResponsiveContainer width={'99%'} height={CHART_HEIGHT}>
      <BarChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        barSize={25}
        ref={chartRef}
      >
        <CartesianGrid vertical={false} stroke={gridColor} />
        <XAxis
          stroke={gridColor}
          dataKey={nameKey}
          style={LABEL_STYLES}
          tick={<CustomizedAxisTick />}
        />
        <YAxis
          stroke={gridColor}
          style={LABEL_STYLES}
          tick={IS_IE_TEN ? <CustomizedAxisTick /> : true}
        />
        <Tooltip
          cursor={{ fill: 'rgba(206, 206, 206, 0.2)' }}
          content={<CustomTooltip />}
        />
        <Bar
          dataKey="count"
          fill={barColor}
          isAnimationActive={!IS_IE_TEN}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

SimpleBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  nameKey: PropTypes.string.isRequired,
  chartRef: PropTypes.func,
};

SimpleBarChart.defaultProps = {
  chartRef: () => {},
};

export default SimpleBarChart;
