import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Input, { valueNormalizers } from './input';


const allowedFields = ['label', 'type', 'info', 'required', 'textArea', 'disableAutoComplete', 'inputClass'];
const allowedValues = {
  type: ['text', 'password', 'checkbox', 'hidden'],
};
const allowedTypes = {
  label: ['string'],
  info: ['string', 'object'],
  type: ['string'],
  required: ['boolean'],
  textarea: ['boolean'],
  disableAutoComplete: ['boolean'],
};

const validateField = (field) => {
  const fieldKeys = Object.keys(field);
  if (fieldKeys.some(f => allowedFields.indexOf(f) < 0)) {
    console.warn(`Form warning: only the following keys are expected in form field objects: ${allowedFields}.`);
  }
  fieldKeys.forEach((f) => {
    if (f in allowedTypes && allowedTypes[f].indexOf(typeof field[f]) < 0) {
      console.warn(`Form warning: expected type '${allowedTypes[f]}' for '${f}' but got '${typeof field[f]}'.`);
    }
  });
  Object.keys(allowedValues).forEach((f) => {
    if (allowedValues[f].indexOf(field[f]) < 0) {
      console.warn(`Form warning: '${field[f]}' is an unexpected value for '${f}'. Expected values are ${allowedValues[f]}.`);
    }
  });
};


class SimpleForm extends Component {
  constructor(props) {
    super(props);
    this.fields = Object.keys(props.fieldMap);
    Object.values(props.fieldMap).forEach((field) => { validateField(field); });
    this.state = {
      form: {
        ...props.defaultState,
      },
      errors: { },
    };
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onEnterKey = this.onEnterKey.bind(this);
  }


  componentDidMount() {
    this.mounted = true;
    if (this.props.submitOnEnter) {
      document.addEventListener('keydown', this.onEnterKey);
    }
  }

  componentDidUpdate(prevProps) {
    const newDefault = prevProps.defaultState !== this.props.defaultState;
    if (newDefault) {
      this.setState({ form: this.props.defaultState });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    document.removeEventListener('keydown', this.onEnterKey);
  }

  onEnterKey(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.onSubmit();
    }
  }

  onFieldChange(field, type) {
    return (e) => {
      this.setState({
        form: {
          ...this.state.form,
          [field]: valueNormalizers[type](e.target),
        } });
    };
  }

  onSubmit() {
    if (this.submitting) { return; }
    if (this.disabled()) { return; }
    this.submitting = true;
    this.props.onSubmit(this.state.form).then(() => {
      if (this.mounted) {
        this.setState({
          form: this.props.defaultState,
          errors: {},
        });
      }
      this.props.successToast && toast(this.props.successToast);
    }).catch((err) => {
      if (!this.mounted) { return; }
      if (err.response && this.fields.some(field => field in err.response.data)) {
        this.setState({ errors: err.response.data });
      } else {
        this.props.genericError && this.setState({ errors: { generic: this.props.genericError } });
      }
    }).finally(() => { this.submitting = false; });
  }

  disabled() {
    return (this.props.submitting || Object.keys(this.state.form)
      .filter(k => this.props.fieldMap[k].required && !this.state.form[k].trim().length).length > 0
    );
  }

  render() {
    const { fieldMap } = this.props;
    return (
      <div className="simple-form">
        {Object.keys(fieldMap).map(field => (
          // eslint-disable-next-line react/no-array-index-key
          <Input
            key={field}
            error={this.state.errors[field]}
            label={fieldMap[field].label}
            value={this.state.form[field]}
            onChange={this.onFieldChange(field, fieldMap[field].type)}
            info={fieldMap[field].info}
            textArea={fieldMap[field].textArea}
            type={fieldMap[field].type}
            inputClass={fieldMap[field].inputClass}
            placeholder={fieldMap[field].placeholder}
          />
        ),
        )}
        {this.state.errors.generic && <p className="error-message generic">{this.state.errors.generic}</p>}
        <div className="button-wrapper">
          <button className="uppercase text-button" onClick={this.props.onCancel}>
            {this.props.cancelText}
          </button>
          <button
            disabled={this.disabled()}
            className="orange-button"
            onClick={this.onSubmit}
          >
            {this.props.confirmText}
          </button>
        </div>
      </div>
    );
  }
}

SimpleForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  defaultState: PropTypes.object.isRequired,
  fieldMap: PropTypes.object.isRequired,
  submitOnEnter: PropTypes.bool,
  submitting: PropTypes.bool,
  genericError: PropTypes.string,
  successToast: PropTypes.string,
};

SimpleForm.defaultProps = {
  confirmText: '',
  cancelText: '',
  submitOnEnter: false,
  submitting: false,
  genericError: '',
  successToast: '',
  onCancel: () => {},
};

export default SimpleForm;
