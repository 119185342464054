import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';


const LinkButton = ({
  disabled,
  url,
  linkText,
  icon,
  tooltip,
}) => (
  <Tippy
    disabled={!disabled}
    content={tooltip}
  >
    <div>
      <Link
        to={url}
        className={`text-button icon-text-button ${disabled ? 'disabled' : ''}`}
      >
        <i className="material-icons">
          { icon }
        </i>
        { linkText }
      </Link>
    </div>
  </Tippy>
);


LinkButton.propTypes = {
  disabled: PropTypes.bool,
  url: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
};

LinkButton.defaultProps = {
  disabled: false,
  icon: '',
  tooltip: '',
};

export default LinkButton;
