import React from 'react';
import PropTypes from 'prop-types';
import DateFilters from './dateFilters';
import TagFilters from './tagFilters';
import IconTextButton from '../monitoringForm/iconTextButton';
import FilterSection from './filterSection';

class FiltersPanel extends React.Component {
  componentDidMount() {
    this.props.openCallback();
  }
  render() {
    const { globalDateFilter, dateFilter, dateOptions, customDateInput, tagFilters,
      handleRadioSelect, handleFieldChange, toggleCheckbox, appliedFilters, showTagFilters,
      tags, clearFilters, applyFilters, errors } = this.props;

    return (
      <div>
        <div className="options-wrapper">
          <FilterSection filterTitle={'Date'}>
            <DateFilters
              globalDateFilter={globalDateFilter}
              dateFilter={dateFilter}
              dateOptions={dateOptions}
              customDateInput={customDateInput}
              handleSelect={handleRadioSelect}
              handleFieldChange={handleFieldChange}
            />
          </FilterSection>
          { showTagFilters ?
            <FilterSection filterTitle={'Tags'}>
              <TagFilters
                tagOptions={tags}
                tagFilters={tagFilters}
                toggleCheckbox={toggleCheckbox}
              />
            </FilterSection> : ''
          }
        </div>
        <div className="fixed-section">
          { Object.values(errors).filter(err => err).length ?
            <div className="error-text">{Object.values(errors).map(err => err)}</div>
            : ''
          }
          <div className="buttons">
            { appliedFilters ?
              <IconTextButton
                icon={'close'}
                buttonHandler={clearFilters}
                buttonText={'Clear'}
              /> : <div />
            }
            <button
              className="orange-button"
              onClick={applyFilters}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  }
}

FiltersPanel.propTypes = {
  globalDateFilter: PropTypes.string,
  dateFilter: PropTypes.string.isRequired,
  dateOptions: PropTypes.object.isRequired,
  customDateInput: PropTypes.object.isRequired,
  tagFilters: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  appliedFilters: PropTypes.bool.isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
  handleRadioSelect: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  showTagFilters: PropTypes.bool,
  openCallback: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

FiltersPanel.defaultProps = {
  globalDateFilter: null,
  showTagFilters: false,
  errors: {},
};

export default FiltersPanel;
