import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useField } from 'formik';

import { FORM_ELEMENT } from '../constants';

const TextAreaField = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <TextareaAutosize
      {...field}
      className={props.className}
      id={props.id}
      maxRows={props.maxRows}
      minRows={props.minRows}
      placeholder={props.placeholder}
      readOnly={props.readOnly}
      required={props.required}
      style={props.style}
      autoFocus={props.autoFocus}
    />
  );
};

TextAreaField.propTypes = {
  ...FORM_ELEMENT,
};

export { TextAreaField };
