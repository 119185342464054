import React from 'react';
import PropTypes from 'prop-types';
import TagItem from '../shared/tags/dropdown/tagItem';
import NoTags from '../shared/tags/dropdown/noTags';


const TagFilters = ({ tagOptions, tagFilters, toggleCheckbox }) => (
  <div>
    {tagOptions.length ?
      tagOptions.map(tag => (
        <TagItem
          key={tag.id}
          tag={tag.name}
          onClick={(e) => { toggleCheckbox('tagFilters', [tag.id], e.target.checked); }}
          selected={tagFilters.has(tag.id)}
        />
      ))
      :
      <NoTags />
    }
  </div>
);

TagFilters.propTypes = {
  tagOptions: PropTypes.array.isRequired,
  tagFilters: PropTypes.object.isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
};


export default TagFilters;
