import * as Yup from 'yup';
import { stateNames } from 'stateNames';
import { requiredError, invalidEmailError, minLengthError, maxLengthError } from 'utils/errorHelpers';


const stateOptions = Object.keys(stateNames).map(state => ({
  label: state,
  value: state,
}));

export const [firstName, lastName, altFirsts, altLasts, primaryAddress, addresses] = ['first_name', 'last_name', 'alt_firsts', 'alt_lasts', 'primary_address', 'addresses'];

export const [email, altEmails, externalId] = ['email', 'alt_emails', 'external_id'];

const [address1, address2, postalCode, city, state, country] = ['address1', 'address2', 'postal_code', 'city', 'state', 'country'];

export const initAddressFields = {
  [address1]: '',
  [address2]: '',
  [city]: '',
  [state]: '',
  [postalCode]: '',
  [country]: '',
};

export const addressFieldsObj = {
  [address1]: {
    id: address1,
    label: 'Address 1',
    fieldType: 'TextField',
  },
  [address2]: {
    id: address2,
    label: 'Address 2',
    fieldType: 'TextField',
  },
  [city]: {
    id: city,
    label: 'City',
    fieldType: 'TextField',
  },
  [state]: {
    id: state,
    label: 'State',
    fieldType: 'SearchableSelectField',
    options: stateOptions,
    clearable: true,
    title: 'State',
  },
  [postalCode]: {
    id: postalCode,
    label: 'Postal code',
    fieldType: 'TextField',
  },
  [country]: {
    id: country,
    label: 'country',
    fieldType: 'TextField',
  },
};


export const employeeValidationSchema = Yup.object().shape({
  [firstName]: Yup.string()
    .trim()
    .min(2, minLengthError(2))
    .max(100, maxLengthError(100))
    .required(requiredError),
  [lastName]: Yup.string()
    .trim()
    .min(2, minLengthError(2))
    .max(100, maxLengthError(100))
    .required(requiredError),
  [altFirsts]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .min(2, minLengthError(2))
        .max(100, maxLengthError(100)),
    ),
  [altLasts]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .min(2, minLengthError(2))
        .max(100, maxLengthError(100)),
    ),
  [email]: Yup.string().email(invalidEmailError),
  [altEmails]: Yup.array()
    .of(
      Yup.string().email(invalidEmailError),
    ),
  [primaryAddress]: Yup.object().shape({
    [address1]: Yup.string().max(255, maxLengthError(255)),
    [address2]: Yup.string().max(255, maxLengthError(255)),
    [postalCode]: Yup.string().max(100, maxLengthError(100)),
    [city]: Yup.string().max(255, maxLengthError(255)),
    [state]: Yup.string().max(20, maxLengthError(20)),
    [country]: Yup.string().max(255, maxLengthError(255)),
  }),
  [addresses]: Yup.array()
    .of(
      Yup.object().shape({
        [address1]: Yup.string().max(255, maxLengthError(255)),
        [address2]: Yup.string().max(255, maxLengthError(255)),
        [postalCode]: Yup.string().max(100, maxLengthError(100)),
        [city]: Yup.string().max(255, maxLengthError(255)),
        [state]: Yup.string().max(20, maxLengthError(20)),
        [country]: Yup.string().max(255, maxLengthError(255)),
      }),
    ),
});
