import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EmailTemplateItem from './emailTemplateItem';
import EmailTemplateForm from './emailTemplateForm';


export function EmailTemplates({
  macros,
  macroLinks,
  templates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  helpText,
  defaultTemplate,
  macroLinkHelpText,
  renderRelatedData,
  getRelatedData,
}) {
  const [openField, setOpenField] = useState(null);
  return (
    <div className="settings email-templates">
      <h1>Email Templates</h1>
      <div className="help-text">
        { helpText }
      </div>
      <ul>
        {
          Object.values(templates).map((template) => {
            const editing = openField && openField.id === template.id;
            return (
              <EmailTemplateItem
                key={template.id}
                editing={editing}
                template={template}
                macros={macros}
                macroLinks={macroLinks}
                onSave={updateTemplate}
                onDelete={deleteTemplate}
                toggleOpen={setOpenField}
                macroLinkHelpText={macroLinkHelpText}
                renderRelatedData={renderRelatedData}
                showSubmissionConfirmation={Boolean(getRelatedData(template.id).length)}
              />
            );
          })
        }
      </ul>
      {
        openField && !openField.id
          ? (
            <li className="action-item">
              <EmailTemplateForm
                creating
                template={openField}
                macros={macros}
                macroLinks={macroLinks}
                onSave={createTemplate}
                toggleOpen={setOpenField}
                macroLinkHelpText={macroLinkHelpText}
              />
            </li>
          )
          : (
            <span className="button-wrapper">
              <button
                onClick={() => { setOpenField({ ...defaultTemplate }); }}
                className="text-button uppercase add-field-button"
                type="button"
              >
                + Add template
              </button>
            </span>
          )
      }
    </div>
  );
}


EmailTemplates.propTypes = {
  templates: PropTypes.object.isRequired,
  macros: PropTypes.array.isRequired,
  macroLinks: PropTypes.array,
  createTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  helpText: PropTypes.string.isRequired,
  defaultTemplate: PropTypes.object.isRequired,
  macroLinkHelpText: PropTypes.string,
  renderRelatedData: PropTypes.func.isRequired,
  getRelatedData: PropTypes.func.isRequired,
};


EmailTemplates.defaultProps = {
  macroLinks: [],
  macroLinkHelpText: '',
};
