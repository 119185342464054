import * as types from './types';

const initState = [];

export default (state = initState, action) => {
  switch (action.type) {
    case types.SET_JURISDICTIONS: {
      return action.jurisdictions;
    }
    default: return state;
  }
};
