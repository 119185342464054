import React from 'react';
import { connect } from 'react-redux';
import { EmailTemplates } from 'components/shared/emailTemplates/emailTemplates';
import { createTemplate, updateTemplate, deleteTemplate } from 'reducers/preclearance/templates/actions';
import {
  name,
  subject,
  ccEmails,
  body,
  banner,
} from 'components/shared/emailTemplates/consts';
import { ActionsList } from '../relatedDataConfirmations';


const mapStateToProps = ({
  preclearance: {
    templates, autoActions, macros,
  },
}) => {
  const getRelatedActions = (id) => (
    Object.values(autoActions)
      .filter((action) => action.active && action.template === id && action.status)
  );
  const renderRelatedData = (id, isDeleted) => {
    const relatedActions = getRelatedActions(id);
    return (
      <ActionsList
        isDeleted={isDeleted}
        relatedActions={relatedActions}
      />
    );
  };

  return {
    templates,
    macros,
    helpText: 'Configure the email templates that are sent via the automated actions here.',
    defaultTemplate: {
      [name]: '',
      [subject]: '',
      [body]: '',
      [banner]: {},
      [ccEmails]: [''],
    },
    renderRelatedData,
    getRelatedData: getRelatedActions,
  };
};

export default connect(mapStateToProps, {
  createTemplate,
  updateTemplate,
  deleteTemplate,
})(EmailTemplates);
