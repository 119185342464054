/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  useHistory, useParams, useRouteMatch, Link,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import api from 'api';
import { toast } from 'react-toastify';
import { PATH_NAMES, PATHS } from 'utils/constants';
import { fetchPreStatuses } from 'reducers/preclearance/statuses/actions';
import { fetchForms } from 'reducers/certifications/forms/actions';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import ErrorBoundary from 'components/errorBoundary';
import Loading from 'components/loading';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import DeleteConfirmation from './DeleteConfirmation';
import CertificationTemplateBody from './CertificationTemplateBody';
import SingleCertificationHeader from './SingleCertificationHeader';
import {
  RECURRING, SCHEDULED, ALL, LIST, TAGS, frequencyPeriod,
} from '../constants';
import useCertificationTemplate from './useCertificationTemplate';
import { ErrorTooltip } from '../CertificationsList/CertificationsTable';

export const getStatusText = (statusFilter, statuses) => {
  const labels = statusFilter.map((statusId) => statuses[statusId] && statuses[statusId].label);
  return statusFilter.length ? labels : ['All'];
};

const getRecipientText = (selector, tags, list) => {
  let recipients;
  switch (selector) {
    case ALL:
      recipients = 'All employees';
      break;
    case TAGS:
      recipients = (
        <div className="tag-container">
          {
            tags.map((t, i) => (
              <span key={i} className="tag-item">{t}</span>
            ))
          }
        </div>
      );
      break;
    case LIST:
      recipients = list && list.length;
      break;
    default:
      recipients = '';
  }
  return recipients;
};

const getFormText = (form) => (form
  ? (
    <Link
      to={`${PATHS[PATH_NAMES.forms]}/${form.id}/edit`}
      className="name-link"
    >
      <span>{ form.name }</span>
    </Link>
  )
  : (
    <span className="flex-header">
      <ErrorTooltip />
      <i>
        missing form
      </i>
    </span>
  ));

function CertificationTemplate({
  tags, statuses, getStatuses, forms, getForms,
}) {
  const { id } = useParams();
  const { template, loading } = useCertificationTemplate(id);
  const [statusesLoading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();
  const { url } = useRouteMatch();

  useEffect(() => {
    let didCancel = false;
    Promise.all([
      getStatuses(),
      getForms(),
    ]).finally(() => {
      if (!didCancel) {
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, [getStatuses, getForms]);

  const deleteTemplate = () => {
    api.delete(`/attestations/templates/${id}`).then(() => {
      toast('Certification deleted.');
      history.push(`${PATHS[PATH_NAMES.certifications]}`);
    }).catch((err) => {
      toast('Something went wrong and we were unable to delete this certification.');
      throw (err);
    });
  };

  const onEdit = () => {
    history.push(`${url}/edit`);
  };

  const onDelete = () => {
    setIsModalOpen(true);
  };

  const {
    name,
    start_date,
    end_date,
    schedule,
    employee_selector,
    employee_list,
    tag_list,
    status_filter: statusFilter,
    form,
  } = template;

  const certificationPeriod = `${moment(start_date).format('ll')} - ${moment(end_date).format('ll')}`;
  const recurring = schedule && schedule.is_recurring;
  const sendDate = schedule && schedule.at_date ? moment(schedule.at_date).format('ll') : '';
  const { frequency } = schedule || {};

  const tagLabels = tag_list && tag_list.map((tagId) => tags[tagId].name);
  const recipients = getRecipientText(employee_selector, tagLabels, employee_list);

  return (
    <ErrorBoundary>
      <ScrollToTopOnMount />
      {
        loading || statusesLoading
          ? (
            <div className="flex-load-wrapper">
              <Loading />
            </div>
          )
          : (
            <div className="certifications-container">
              <SingleCertificationHeader
                name={name}
                status={recurring ? RECURRING : SCHEDULED}
                date={recurring ? frequency : sendDate}
                certificationPeriod={recurring ? frequency : certificationPeriod}
                recipients={recipients}
                statusFilter={getStatusText(statusFilter, statuses)}
                form={getFormText(forms[form])}
                actions={(
                  <>
                    <IconTextButton
                      icon="edit"
                      buttonHandler={onEdit}
                      buttonText="Edit"
                    />
                    <IconTextButton
                      icon="delete"
                      buttonHandler={onDelete}
                      buttonText="Delete"
                    />
                  </>
              )}
              />
              <div className="dashboard-card">
                { recurring
                  ? (
                    <CertificationTemplateBody
                      icon="loop"
                      heading="Recurring Certification"
                      message={`This certification will be sent each ${frequencyPeriod[frequency]} on the day after the ${frequencyPeriod[frequency]} ends`}
                      onDelete={onDelete}
                      onEdit={onEdit}
                    />
                  )
                  : (
                    <CertificationTemplateBody
                      icon="schedule"
                      heading="Certification scheduled"
                      message={(
                        <div>
                          This certification is scheduled to be sent on
                          {' '}
                          <strong>{sendDate}</strong>
                        </div>
                      )}
                      onDelete={onDelete}
                      onEdit={onEdit}
                    />
                  )}
              </div>
            </div>
          )
      }
      {
        isModalOpen
          ? (
            <DeleteConfirmation
              close={() => { setIsModalOpen(false); }}
              onSubmit={deleteTemplate}
            />
          )
          : null
      }
    </ErrorBoundary>
  );
}

const mapStateToProps = ({
  tags,
  preclearance: { statuses },
  certifications: { forms },
}) => ({
  tags,
  statuses,
  forms,
});

CertificationTemplate.propTypes = {
  tags: PropTypes.object.isRequired,
  statuses: PropTypes.object.isRequired,
  getStatuses: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  getForms: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    getStatuses: fetchPreStatuses,
    getForms: fetchForms,
  },
)(CertificationTemplate);
