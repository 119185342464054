import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField, ErrorMessage } from 'formik';

const TextField = ({ label, inputType, ...props }) => {
  const [field] = useField(props);
  return (
    <div className="form-field">
      <label htmlFor={field.name}>{label}</label>
      <Field as={inputType} rows={10} {...field} {...props} />
      <ErrorMessage
        name={field.name}
        component="div"
        className="error-text"
      />
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputType: PropTypes.oneOf(['input', 'textarea']),
};

TextField.defaultProps = {
  inputType: 'input',
};

export { TextField };
