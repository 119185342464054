import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { sticky } from 'tippy.js';


const HeaderCell = ({
  column,
  selected,
  activeResize,
  toggleColumnSelect,
  addingRow,
  menu,
  style,
  className,
}) => {
  const { id, label } = column;
  return (
    <Tippy
      className="column-header-tippy"
      content={selected ? menu : null}
      visible={selected}
      onClickOutside={toggleColumnSelect(null)}
      placement="bottom-start"
      interactive
      theme="light"
      animation={false}
      appendTo={document.body}
      arrow={false}
      offset={[0, -2]}
      maxWidth="none"
      sticky
      plugins={[sticky]}
      popperOptions={{
        modifiers: [{
          name: 'flip',
          enabled: false,
        }],
      }}
    >
      <div>
        <button
          type="button"
          className={`preclearance-header-cell ${addingRow ? '' : 'after'} ${selected ? 'selected' : ''} ${className} `}
          style={style}
          onClick={menu ? toggleColumnSelect(id) : () => {}}
        >
          <span>{label}</span>
          {
            menu
            && (
              <div className="menu-caret">
                <i
                  className="material-icons"
                >
                  {selected ? 'expand_less' : 'expand_more'}
                </i>
              </div>
            )
          }

        </button>
        { activeResize ? <div className="resize-border-div" /> : null}
      </div>
    </Tippy>
  );
};

HeaderCell.propTypes = {
  column: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  activeResize: PropTypes.bool,
  toggleColumnSelect: PropTypes.func.isRequired,
  addingRow: PropTypes.bool.isRequired,
  menu: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
};

HeaderCell.defaultProps = {
  menu: null,
  activeResize: false,
  style: {},
  className: '',
};

export default HeaderCell;
