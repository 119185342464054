import React from 'react';
import PropTypes from 'prop-types';

const OnOffToggle = ({ active, onToggle, disabled }) => (
  <button
    className={`${active ? 'on ' : ''}text-button toggle-button`}
    onClick={onToggle}
    disabled={disabled}
    aria-pressed={active}
  >
    <span className="circle" />
    <span>{ active ? 'On' : 'Off'}</span>
  </button>
);

OnOffToggle.propTypes = {
  active: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

OnOffToggle.defaultProps = {
  disabled: false,
};

export default OnOffToggle;
