import React from 'react';
import PropTypes from 'prop-types';
import { CurrencySymbol } from './CurrencySymbol';
import { TextField } from './TextField';


export const AmountField = ({
  currency,
  ...props
}) => (
  <>
    <CurrencySymbol
      currency={currency}
    />
    <TextField
      {...props}
      style={{ textAlign: 'right' }}
    />
  </>
);


AmountField.propTypes = {
  currency: PropTypes.string,
};

AmountField.defaultProps = {
  currency: null,
};
