import { connect } from 'react-redux';
import { RESEARCH_EXPORT_LIMIT } from 'utils/constants';
import { deserializeDate } from 'utils/serializers/dateSerializer';
import { hasExportPermissions } from 'helpers/permissions';
import { withErrors, completed } from 'reducers/sourceResults/selectors';
import { byId } from 'reducers/sources/selectors';
import ResultsHeader from './header';

const exportDisabledMessage = `Exports over ${RESEARCH_EXPORT_LIMIT} records are not allowed. Please narrow your search in order to export the results`;

const researchMapStateToProps = (state) => {
  const canExport = hasExportPermissions(state.user);
  const { research } = state;
  const searchRef = research.currentSearch;
  const sourceResultIds = research.results;

  const { dateFilter } = state.research.ui;

  const completedSources = completed(research.sourceResults, sourceResultIds);
  const sourceResultsWithErrors = withErrors(research.sourceResults, sourceResultIds);
  const sourceIdsWithErrors = sourceResultsWithErrors.map(sourceResult => sourceResult.source);
  const sourcesWithErrors = byId(state.sources.sources, sourceIdsWithErrors);

  const errorsObj = sourceResultsWithErrors.reduce((errors, sourceRes) => {
    const key = sourcesWithErrors.find(source => source.id === sourceRes.source).label;
    errors[key] = sourceRes.error_message;
    return errors;
  }, {});

  const totalSourceCount = searchRef.sources.length;
  const completedSourceCount = completedSources.length;
  const allSourceResultsFetched = completedSourceCount === totalSourceCount;
  const searchCompleted = allSourceResultsFetched || searchRef.last_completed;

  const resultCount = Object.values(research.sourceResults).map(sourceRes => sourceRes.count).reduce((acc, val) => acc + val, 0);

  const { from, to } = dateFilter;
  // this is to show the date filter under the name with the other filters
  const uiDateFilter = {
    from: from ? deserializeDate(from) : null,
    to: to ? deserializeDate(to) : null,
  };

  return {
    searchCompleted,
    search: searchRef,
    resultCount,
    totalSourceCount,
    completedSourceCount,
    errorsObj,
    dateFilter,
    uiDateFilter,
    canExport,
    disableExports: resultCount > RESEARCH_EXPORT_LIMIT,
    exportDisabledMessage,
  };
};

export default connect(researchMapStateToProps)(ResultsHeader);
