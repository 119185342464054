export const SAMPLE_DATA = [
  {
    first_name: 'Sample contributor',
    last_name: 'Sample contributor',
    date: new Date().toISOString(),
    amount: 100,
    recipient: 'Sample recipient',
    office_sought: 'Sample office',
    jurisdiction: 'Sample jurisdiction',
    status: 'Preview',
  },
];

export const INITIAL_DATA = {
  fields: [],
  start_date: new Date().toISOString(),
  end_date: new Date().toISOString(),
  table: '',
  footer: '',
  organization: 'Sample Company',
};
