import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_CURRENCY } from 'utils/constants';

import { getCurrencySymbol } from 'helpers/formatFieldHelpers';


export function CurrencySymbol({ currency }) {
  return (
    <span className="dollar">{getCurrencySymbol(currency)}</span>
  );
}


CurrencySymbol.propTypes = {
  currency: PropTypes.string,
};

CurrencySymbol.defaultProps = {
  currency: DEFAULT_CURRENCY,
};
