import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { BulkExportProgress, ReportProgress, PreclearanceReportProgress } from '../bulkExports/exportProgress';


const formatDate = (start, end) => {
  const from = start ? moment(start, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;
  const to = end ? moment(end, 'YYYY-MM-DD').format('MM/DD/YYYY') : null;
  if (!from && !to) {
    return 'Any record date';
  } else if (from && !to) {
    return `After ${from}`;
  } else if (to && !from) {
    return `Prior to ${to}`;
  }
  return `${from} - ${to}`;
};

const ReportEntry = ({ startDate, endDate, id, removeReport, link, onNewReportGeneration, exportType }) => (
  <tr key={id}>
    <td>{formatDate(startDate, endDate)}</td>
    <td>
      {exportType === 'report' ?
        <ReportProgress
          id={id}
          link={link}
          onNewReportGeneration={onNewReportGeneration}
          exportType={exportType}
        />
        : exportType === 'preclearance report' ? (
          <PreclearanceReportProgress
            id={id}
            link={link}
            onNewReportGeneration={onNewReportGeneration}
            exportType={exportType}
          />
        ) : (
          <BulkExportProgress
            id={id}
            link={link}
            onNewReportGeneration={onNewReportGeneration}
            exportType={exportType}
          />
        )
      }
    </td>
    <td>
      <button className="text-button" onClick={() => { removeReport(id).catch(() => toast('There was a problem deleting this report.')); }}>
        <i className="icon-trash" />
      </button>
    </td>
  </tr>
);


ReportEntry.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  id: PropTypes.number.isRequired,
  removeReport: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
  onNewReportGeneration: PropTypes.func.isRequired,
  exportType: PropTypes.string.isRequired,
};

ReportEntry.defaultProps = {
  hasError: false,
  current: 0,
  total: 0,
  startDate: null,
  endDate: null,
  searchObjects: [],
};

export default ReportEntry;
