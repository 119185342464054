import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Table, Column, ScrollSync, AutoSizer,
} from 'react-virtualized';
import EmptyState from 'components/shared/emptyState';
import {
  SCROLLABLE_GRID_CLASS, MAIN_TABLE_CONTAINER, DELETE_MATCH, SUPPORT_EMAIL,
} from 'utils/constants';
import { MONITORED_RECORD } from 'components/shared/matching/consts';
import MatchButtonRenderer from 'components/shared/matching/matchButtonRenderer';
import {
  createFilterString, makeJurisdictionMap, handleInvalidEmailError,
} from './helpers/utils';

import FixedLeft from './fixedLeftColumn';
import HeaderRow from './headerComponents/HeaderRow';
import { validateCell, serializeRow, serializeCell } from './cellValidator';
import FiltersHeader from './filtersHeader';
import { PreclearanceTableHelper } from './helpers/grid';
import Filters from './filters';
import Loading from '../../loading';

import AttachmentCell, { DisconnectedAttachmentCell } from './attachmentCell';
import Cell from './Cell';
import CellWrapper from './CellWrapper';
import ErrorCellWrapper from './ErrorCellWrapper';
import { DeleteCell } from './cellComponents';
import {
  ROW,
  START_WIDTH,
  ROW_HEIGHT,
  INPUT_ROW_HEIGHT,
  HEADER_ROW_HEIGHT,
  SCROLLBAR_OFFSET,
  ADD_ROW,
  ADD_COLUMN,
  ADD_ATTACHMENT,
  DROPDOWN_HEIGHT,
  STATUS,
  MATCHES,
  AMOUNT,
  FIXED_LEFT_COLUMN_IDS,
  FIXED_RIGHT_COLUMN_IDS,
  MATCHING_MODAL,
} from './constants';
import LoadingRow from './LoadingRow';


// [colIdx, rowIdx]
const deltas = {
  9: [1, 0], // right
  37: [-1, 0], // left
  38: [0, -1], // up
  39: [1, 0], // right
  40: [0, 1], // down
};

//  STATE DOCUMENTATION
// ---------------------------------------------------------
// colIdMap
//   mapping of colIds to column objects
//
//   {
//     colId1: { colId: 'col 1', label: 'col 1', width: 140 },
//     colId2: { colId: 'col 2', label: 'col 2', width: 140 }
//     ...
//   }
// ---------------------------------------------------------
// colIdOrd
//   columns are reorderable, so this represents column order
//   and is used to look up actual columns in colIdMap
//
//   [colId1, colId3, colId2, ...]
// ---------------------------------------------------------
// cell
//   an object for simplified access to its corresponding info and active state
//
//   { value, colId, isActive }
// ---------------------------------------------------------
// rows
//   an array of objects representing rows
//   each row object contains a mapping of colIds to cell objects
//   this is an array because rows are not re-orderable.
//
//   [
//     {
//       colId1: cell,
//       colId2: cell,
//       ...
//     },
//     {
//       colId1: cell,
//       colId2: cell,
//       ...
//     }
//   ]
// ---------------------------------------------------------
// activeCellCoords
//
//   [colId, rowIdx]


class TableWithSortableColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCellCoords: null,
      deleteRowIdx: null,
      activeColumn: null,
      newRow: null,
      loadingRows: true, // todo: more clear naming
      loading: true,
      filters: [],
      inputCellHeight: INPUT_ROW_HEIGHT,
      showSidebarShadow: false,
      resizingCol: null,
      statusFilter: null,
      exitingRecords: new Set(),
    };
    this.minWidth = START_WIDTH;
    this.handleScroll = this.handleScroll.bind(this);

    [
      'onSortEnd',
      'renderHeaderRow',
      'onResize',
      'onResizeStop',
      'rowRenderer',
      'updateCellVal',
      'updateColumn',
      'createColumn',
      'toggleAddRow',
      'editNewRow',
      'saveRow',
      'toggleColumnSelect',
      'deleteColumn',
      'deleteRow',
      'addFilter',
      'removeFilter',
      'sort',
      'moveDir',
      'onKeyDown',
      'clearActiveCell',
      'clearFreezeFocus',
      'storeRef',
      'cellRenderer',
      'showDeleteBox',
      'noRowsRenderer',
      'updateCell',
      'updateInputCellHeight',
      'setupGridHelper',
      'renderInputRow',
      'onCellClick',
      'updateDropdownCell',
      'onOutsideClick',
      'setFiles',
    ].forEach((bindFunc) => this[bindFunc] = this[bindFunc].bind(this));
  }

  setupGridHelper() {
    const {
      rows,
      columns,
      leftColumnIds,
      showAutomatches,
      statuses,
    } = this.props;
    this.grid = new PreclearanceTableHelper({
      rows,
      columns,
      leftColumnIds,
      showAutomatches,
      jurisdictions: this.jurisdictions,
      statuses,
    });
    [
      'getRow',
      'rowHeight',
      'rows',
      'getColumn',
      'getCell',

      'orderedColumns', // array of all columns (not used in table rn)
      'originalOrderedColumns', // array of all columns (used in header renderer)
      'rightColumns', // array of right column objs (loading rows, totalwidth, render input row, columns)
      'leftColumns', // array of left column objs (fixedleft, render input row, columns)
      'columnIdsWithData', // array of colids from row.data (serialize row, saveRow)
      'columnsWithData', // array of column objs (id, label, width, order, type) (create column to set order)

      'calcSpaceBelow',
      'makeBlankRow',
    ].forEach((shiftMethod) => this[shiftMethod] = this.grid[shiftMethod].bind(this.grid));
  }


  componentDidMount() {
    const defaultStatus = Object.values(this.props.statuses).find((status) => (status.default));
    Promise.all([
      this.props.fetchColumns(),
      this.props.filter(`${STATUS}__exact=${defaultStatus.id}`),
    ]).then(() => {
      const { rows, jurisdictions } = this.props;
      this.jurisdictions = makeJurisdictionMap(jurisdictions);
      this.serializeRow = (row) => serializeRow(row, this.jurisdictions, this.grid.colMap(), this.grid.columnIdsWithData());
      this.serializeCell = (cellVal, colId) => serializeCell(cellVal, colId, this.jurisdictions, this.grid.colMap());
      this.setupGridHelper();

      const statusFilter = {
        columnId: STATUS,
        value: defaultStatus.id,
      };

      this.setState({
        colIdMap: this.grid.colMap(),
        loading: false,
        loadingRows: false,
        statusFilter,
        newRow: !rows.length ? this.makeBlankRow() : null,
      });
      document.addEventListener('keydown', this.onKeyDown);
      document.addEventListener('click', this.onOutsideClick);
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    if (!prevState.exitingRecords.size && this.state.exitingRecords.size) {
      this.playAnimation();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('click', this.onOutsideClick);
  }

  getActiveCell() {
    if (this.state.activeCellCoords) return this.getCell(...this.state.activeCellCoords);
  }

  handleScroll(e) {
    if (!this.state.showSidebarShadow && e.currentTarget.scrollLeft > 0) {
      this.setState({ showSidebarShadow: true });
    } else if (this.state.showSidebarShadow && e.currentTarget.scrollLeft <= 0) {
      this.setState({ showSidebarShadow: false });
    }
  }

  clearFreezeFocus() {
    const activeCell = this.getActiveCell();
    if (this.freezeFocus && this.focusedCellPrevVal) {
      activeCell.value = this.focusedCellPrevVal;
      activeCell.error = null;
      this.freezeFocus = false;
    }
  }

  clearActiveCell(keepVal) {
    if (this.freezeFocus) return;
    const prevActive = this.getActiveCell();
    if (!prevActive) return;
    prevActive.edited = false;
    if (!keepVal) {
      prevActive.value = this.focusedCellPrevVal;
      this.focusedCellPrevVal = null;
    }
    prevActive.active = false;
    this.setState({ activeCellCoords: null });
  }

  onCellClick(colId, rowIdx) {
    return () => {
      if (this.getCell(colId, rowIdx).active) return;
      if (this.saveCurrentCell()) {
        this.activateCell(colId, rowIdx);
      }
    };
  }

  // update activecell coords and focus the cell's input
  activateCell(colId, rowIdx) {
    if (this.freezeFocus) return;

    const prevActive = this.getActiveCell();
    const cell = this.getCell(colId, rowIdx);

    if (prevActive
      && !(prevActive.rowId === cell.rowId && prevActive.colId === cell.colId)
    ) prevActive.active = false;
    if (cell.active) return;

    cell.active = true;
    this.focusedCellPrevVal = cell.value;

    // the input can only be focused when displayed, so wait until after setState to focus it.
    this.setState({ activeCellCoords: [colId, rowIdx] },
      () => {
        if (this.inputRef && this.inputRef.type === 'text') {
          this.inputRef.focus();
        }
      });
  }

  moveDir(deltaDir) {
    const newCellCoords = this.grid.calcNewCellCoords(this.state.activeCellCoords, deltaDir);
    if (newCellCoords) {
      this.clearActiveCell(true);
      this.activateCell(...newCellCoords);
    }
  }

  toggleColumnSelect(colId) {
    return () => {
      if (this.freezeFocus) return;
      if (this.state.activeColumn === colId) {
        this.setState({ activeColumn: null });
      } else {
        this.setState({
          activeColumn: colId,
        });
      }
    };
  }

  toggleAddRow() {
    if (this.freezeFocus) return;
    let newRow = null;
    if (!this.state.newRow) {
      newRow = this.makeBlankRow();
      this.clearActiveCell();
    }
    this.setState({
      inputCellHeight: INPUT_ROW_HEIGHT,
      newRow,
    }, () => {
      if (this.lastLeftInputRow) {
        this.lastLeftInputRow.focus();
      }
    });
  }


  editNewRow(colId) {
    return (val) => {
      const updatedNewRow = this.state.newRow;
      updatedNewRow.data[colId].value = val;
      this.setState({ newRow: updatedNewRow });
    };
  }


  // sets files for the row being added
  setFiles(files) {
    this.setState({ newRow: { ...this.state.newRow, files } });
  }

  saveRow() {
    const { newRow } = this.state;
    Object.values(newRow.data).forEach((cell) => cell.error = '');

    let foundErrors = false;
    this.columnIdsWithData().forEach((colId) => {
      const cell = newRow.data[colId];
      cell.error = validateCell(cell.value, this.getColumn(colId));
      if (cell.error) foundErrors = true;
    });
    if (foundErrors) {
      this.setState({ newRow });
      toast('Some fields had errors. Scroll to the side to see the errors.');
    } else {
      const serializedRow = this.serializeRow(newRow);
      this.props.createRow(serializedRow).then((data) => {
        const { row } = data;
        this.grid.addRow(row);
        this.setState({
          newRow: null,
        });
        this.props.handleActionConfirmation(row);
      }).catch((err) => {
        toast(handleInvalidEmailError(err) || 'There was a problem adding this row');
        throw err;
      });
    }
  }

  updateCellVal(e) {
    const cell = this.getActiveCell();
    cell.value = e.target.value;
    cell.edited = true;
    this.forceUpdate();
  }

  updateDropdownCell(val) {
    this.getActiveCell().value = val;
    this.updateCell(); // todo: prevent 2 patch requests
  }

  deleteRow() {
    const { deleteRowIdx } = this.state;
    const row = this.getRow({ index: deleteRowIdx });
    this.props.deleteRow(row.id).then((data) => {
      this.grid.deleteRow(deleteRowIdx);
      this.setState({ deleteRowIdx: null });
    }).catch((err) => {
      toast('There was a problem deleting this row');
      throw err;
    });
  }

  createColumn(label) {
    const order = this.grid.columnsWithData().length;
    return this.props.createColumn({ label, order }).then((data) => {
      const { column } = data;
      this.grid.addColumn(column);
      if (this.state.newRow) {
        this.state.newRow.data[column.id] = { value: '', colId: column.id };
      }
      this.setState({ colIdMap: this.grid.colMap(), activeColumn: null });
    }).catch((err) => {
      toast('There was a problem adding a new field');
      throw err;
    });
  }


  updateColumn(val, colId) {
    const payload = {
      ...this.getColumn(colId),
      label: val,
    };
    return this.props.updateColumn(colId, payload).then(() => {
      const column = this.getColumn(colId);
      column.label = val;
      this.setState({ colIdMap: this.grid.colMap(), activeColumn: null });
    }).catch((err) => {
      toast('There was a problem editing this field');
      throw err;
    });
  }

  deleteColumn(colId) {
    return this.props.deleteColumn(colId).then(() => {
      this.grid.deleteColumn(colId);
      const { newRow } = this.state;
      if (newRow) {
        delete newRow.data[colId];
      }
      this.setState({ colIdMap: this.grid.colMap(), activeColumn: null });
    }).catch((err) => {
      toast('There was a problem deleting this field');
      throw err;
    });
  }

  onSortEnd(colIdOrd) {
    // avoid a bunch of requests if the user is just moving one column multiple spots over
    if (this.sortEndDebounce) {
      clearTimeout(this.sortEndDebounce);
    }
    this.sortEndDebounce = setTimeout(() => {
      this.props.updateOrd(this.grid.orderedInternalIds());
      this.sortEndDebounce = null;
    }, 500);
    this.grid.updateColOrd(colIdOrd);
    this.setState({ colIdOrd: this.grid.colOrd() });
  }

  onResizeStop(_, colId) {
    const resizedCol = this.getColumn(colId);
    this.props.updateColumn(resizedCol._id, { width: resizedCol.width });
    this.startWidth = null;
    this.setState({ resizingCol: null });
  }

  onResize(event, colId, axis, element, { width: deltaX }) {
    const { colIdMap } = this.state;
    const col = this.getColumn(colId);
    this.startWidth = this.startWidth || col.width;
    const newWidth = this.startWidth + deltaX;
    if (newWidth >= this.minWidth) {
      col.width = newWidth;
      this.setState({ colIdMap });
    } if (this.state.resizingCol !== colId) {
      this.setState({ resizingCol: colId });
    }
  }


  addFilter(field, columnId, type, value, choices) {
    const newFilter = {
      field, columnId, type, value, choices,
    };
    const { filters, statusFilter } = this.state;
    // not supporting multiple filters per column for now
    const nextFilters = filters.filter((f) => f.columnId !== columnId);
    if (columnId === STATUS) {
      this.filter(nextFilters, newFilter);
    } else {
      this.filter(nextFilters.concat([newFilter]), statusFilter);
    }
  }

  removeFilter(filterIdx) {
    return () => {
      const newFilters = Object.assign([], this.state.filters);
      newFilters.splice(filterIdx, 1);
      this.filter(newFilters, this.state.statusFilter);
    };
  }

  filter(filters, statusFilter) {
    this.setState({
      loadingRows: true,
      activeColumn: null,
    });
    const allFilters = statusFilter ? filters.concat([statusFilter]) : filters;
    const qString = createFilterString(allFilters);
    this.props.filter(qString).then(({ rows }) => {
      this.grid.replaceRows(rows);
      this.setState({ filters, statusFilter, loadingRows: false });
    });
    // TO DO test this
    this.clearFreezeFocus();
    this.clearActiveCell();
  }

  sort(colId, dir) {
    this.setState({ loadingRows: true, activeColumn: null });
    const { filters, statusFilter } = this.state;
    const allFilters = statusFilter ? filters.concat([statusFilter]) : filters;
    let filtersQuery = createFilterString(allFilters);
    filtersQuery = filtersQuery ? `${filtersQuery}&` : '';
    const fullQuery = `${filtersQuery}sort=${dir}${colId}`;
    this.props.filter(fullQuery).then(({ rows }) => {
      this.grid.replaceRows(rows);
      this.setState({ loadingRows: false });
    });
  }


  updateCell() {
    const [colId, currentRowIdx] = this.state.activeCellCoords;
    const currentRow = this.getRow({ index: currentRowIdx });
    const cell = this.getActiveCell();
    const prevVal = this.focusedCellPrevVal;
    const serializedCell = this.serializeCell(cell.value, colId);

    this.props.updateRow(currentRow.id, serializedCell).then(({ row }) => {
      if (colId === STATUS) {
        const { statusFilter } = this.state;
        // remove from view if it no longer matches the status no longer matches the status filter
        if (statusFilter && row.data.status !== statusFilter.value) {
          this.setState({ exitingRecords: new Set([...this.state.exitingRecords, row.id]) });
          this.clearActiveCell();
          this.grid.deleteRow(currentRowIdx);
        }
        this.props.handleActionConfirmation(row);
      }
    }).catch((err) => {
      cell.value = prevVal;
      this.forceUpdate();
      toast(handleInvalidEmailError(err) || 'There was a problem editing this row');
      throw err;
    });
  }

  // moving happens onKeyDown and onClick
  // do this before focusing the next cell in both cases
  saveCurrentCell() {
    const activeCell = this.getActiveCell();

    if (!activeCell) return true;
    if (!activeCell.edited) return true;

    const col = this.getColumn(activeCell.colId);
    activeCell.error = validateCell(activeCell.value, col);
    if (activeCell.error) {
      this.freezeFocus = true;
      // this.setState({ rows: this.rows() });
      this.forceUpdate();
      return false;
    }
    this.freezeFocus = false;
    activeCell.error = '';
    this.updateCell();
    return true;
  }

  // todo: implement actual navigation to tab through dropdowns too
  moveAddRowDir(e) {
    if (document.activeElement === this.lastLeftInputRow) {
      e.preventDefault();
      this.firstRightInputRow.focus();
    }
  }

  onKeyDown(e) {
    const { keyCode, target: { selectionEnd, type }, shiftKey } = e;
    if ([9, 13, 27, 37, 38, 39, 40].indexOf(keyCode) < 0) return;
    e.stopPropagation();
    const isText = type === 'text';
    const activeCell = this.getActiveCell();
    if (this.props.modalOpen) {
      return;
    }

    if (this.state.newRow && !activeCell) {
      switch (keyCode) {
        case 9:
          this.moveAddRowDir(e);
          break;
        case 27:
          this.setState({ newRow: null });
          break;
        case 13:
          this.saveRow();
          break;
        default:
      }
    } else {
      if (!activeCell) return;
      if (keyCode === 27) {
        this.clearFreezeFocus();
        this.clearActiveCell();
        return;
      }

      switch (keyCode) {
        case 13:
          if (!this.saveCurrentCell()) return;
          this.clearActiveCell(true);
          break;
        case 9:
          e.preventDefault();
          if (shiftKey) {
            if (!this.saveCurrentCell()) return;
            this.moveDir([-1, 0]);
            break;
          }
        case 38:
        case 40:
          if (!this.saveCurrentCell()) return;
          e.preventDefault();
          this.moveDir(deltas[keyCode]);
          break;
        // left arrow
        case 37:
          if (!isText || selectionEnd === 0) {
            if (!this.saveCurrentCell()) return;
            e.preventDefault();
            this.moveDir(deltas[keyCode]);
          }
          break;
        // right arrow
        case 39:
          if (!isText || selectionEnd === String(activeCell.value).length) {
            if (!this.saveCurrentCell()) return;
            this.moveDir(deltas[keyCode]);
          }
          break;
        default:
      }
    }
  }

  storeRef(ref) {
    if (!this.freezeFocus) {
      this.inputRef = ref;
    }
  }


  onOutsideClick(e) {
    const activeCell = this.getActiveCell();
    if (!activeCell) {
      return;
    }
    // todo this is still broken in IE 10 & 11
    const path = e.path || e.composedPath();
    const targetElem = path.filter((el) => el.classList && el.classList.contains('preclearance-table-container'));
    if (targetElem.length === 0) {
      if (this.saveCurrentCell()) {
        this.clearActiveCell(true);
      }
    }
  }

  showDeleteBox(idx) {
    return () => {
      if (this.freezeFocus) return;
      this.clearActiveCell();
      this.setState({ deleteRowIdx: idx });
    };
  }

  cellRenderer({
    columnData: column,
    rowData,
    rowIndex,
  }) {
    const colId = column.id;
    const { resizingCol } = this.state;
    const activeResize = resizingCol && colId === resizingCol ? 'resize' : '';
    const cell = rowData.data[colId];

    if (rowData.type === ADD_ROW) {
      return (
        <CellWrapper
          key={`row${rowIndex}-${colId}`}
          className={`add-row-placeholder ${activeResize}`}
          width={column.width}
        />
      );
    }
    const editing = cell && cell.active ? 'editing' : '';

    if (colId === ADD_ATTACHMENT) {
      return (
        <AttachmentCell
          key="attachment-cell"
          onClick={this.onCellClick(column.id, rowIndex)}
          rowIdx={rowIndex}
          editing={editing}
          rowId={rowData.id}
        />
      );
    } if (colId === ADD_COLUMN) {
      const { deleteRowIdx } = this.state;
      return (
        <DeleteCell
          key="delete-cell"
          isOpen={rowIndex === deleteRowIdx}
          openDeleteConfirmation={this.showDeleteBox(rowIndex)}
          closeDeleteConfirmation={() => this.setState({ deleteRowIdx: null })}
          deleteRow={this.deleteRow}
          style={{ width: column.width, paddingLeft: SCROLLBAR_OFFSET }}
        />
      );
    } if (colId === MATCHES) {
      // this is reading from redux, whereas other rowData is not
      const row = this.props.rows.find((r) => r.id === rowData.id);
      return (
        <CellWrapper
          key={`row${rowIndex}-match-cell`}
          className={`match ${editing}`}
          width={column.width}
          onCellClick={this.onCellClick(column.id, rowIndex)}
        >
          <MatchButtonRenderer
            noMatch={row && row.no_match}
            matches={row && row.matches}
            automatches={row && row.automatches}
            showAutomatches={this.props.showAutomatches}
            matchType={MONITORED_RECORD}
            openMatchingDialog={() => {
              if (this.saveCurrentCell()) {
                this.props.openPreModal({ modalType: MATCHING_MODAL });
                this.props.selectRow({ selectedRow: { ...rowData } });
              }
            }}
            removeMatch={(recordMetaId) => {
              this.props.openModal({
                modalType: DELETE_MATCH,
                modalProps: { requestId: rowData.id, recordMetaId },
              });
            }}
          />
        </CellWrapper>
      );
    }
    const { value, error, active } = cell;
    const dropdownTop = this.calcSpaceBelow(rowIndex) < DROPDOWN_HEIGHT;
    const currency = colId === AMOUNT ? this.grid.getCurrency(rowData) : null;
    const statusClass = colId === STATUS ? 'status-cell' : '';
    const defaultStatusClass = colId === STATUS && this.props.statuses[value].default ? 'default-status' : '';
    const Wrapper = error ? ErrorCellWrapper : CellWrapper;
    return (
      <Wrapper
        key={`row${rowIndex}-${colId}`}
        className={`${activeResize} ${statusClass} ${defaultStatusClass} ${column.readOnly ? 'disabled' : editing}`}
        width={column.width}
        onCellClick={this.onCellClick(column.id, rowIndex)}
      >
        <Cell
          value={value}
          error={error}
          editing={active}
          column={column}
          updateCellVal={this.updateCellVal}
          updateDropdownCell={this.updateDropdownCell}
          updateCheckboxCell={this.updateDropdownCell}
          currency={currency}
          dropdownTop={dropdownTop} // todo use library instead
          refCb={this.storeRef} // todo figure out a better way
        />
      </Wrapper>
    );
  }

  rowRenderer({
    index,
    rowData,
    style,
    key,
    columns,
  }) {
    const { exitingRecords, deleteRowIdx } = this.state;
    const isExiting = exitingRecords.has(rowData.id);
    const parity = index % 2 === 1 ? 'odd' : 'even';
    const deleteRow = deleteRowIdx === index ? 'selected' : '';

    let { top } = style;
    if (!isExiting) {
      const nextOffset = Array.from(exitingRecords)
        .map((recordId) => this.grid.rows().findIndex((item) => item.id === recordId))
        .filter((rowIndex) => rowIndex < index)
        .reduce((acc) => acc + ROW_HEIGHT, 0);
      top -= nextOffset;
    }

    return (
      <div
        className={`${ROW} ${parity} ${deleteRow} row-transitor ${exitingRecords.size > 0 ? 'active' : ''}`}
        key={key}
        style={{
          ...style,
          overflow: '',
          height: isExiting ? 0 : style.height,
          top,
        }}
        role="row"
      >
        { columns }
      </div>
    );
  }

  loadingRowsRenderer(height) {
    const numRows = Math.floor((height - this.getHeaderHeight()) / ROW_HEIGHT);
    return [...Array(numRows).keys()].map((_, index) => (
      <LoadingRow
        key={`preclearance-row-${index}`}
        className={index % 2 === 1 ? 'odd' : 'even'}
        columns={this.rightColumns()}
      />
    ));
  }

  noRowsRenderer(tableHeight) {
    const { filters, statusFilter, loadingRows } = this.state;
    if (loadingRows) {
      return this.loadingRowsRenderer(tableHeight);
    }
    if (!filters.length && !statusFilter) {
      return (
        <div className="empty-placeholder">
          <EmptyState
            icon="search"
            messageHeading="No preclearance data yet."
            message={(
              <div>
                Start by entering a precleared contribution. Alternatively, if you would like to bulk import data, please contact
                {' '}
                <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
              </div>
            )}
          />
        </div>
      );
    }
    const filterText = [];
    if (filters.length) { filterText.push('these filters'); }
    if (statusFilter) { filterText.push('this status'); }
    return (
      <div className="empty-placeholder">
        <EmptyState
          icon="search"
          messageHeading="No results found."
          message={`No records found with ${filterText.join(' and ')}.`}
        />
      </div>
    );
  }

  getTotalWidth() {
    return this.rightColumns().map((col) => col.width).reduce((acc, num) => acc + num) + SCROLLBAR_OFFSET;
  }

  getHeaderHeight() {
    return this.state.newRow ? HEADER_ROW_HEIGHT + this.state.inputCellHeight : HEADER_ROW_HEIGHT;
  }


  playAnimation() {
    this.forceUpdate((_) => {
      setTimeout((_) => {
        this.setState({ exitingRecords: new Set() });
      }, 300);
    });
  }

  // re-render input-row if errors on either side increase max cell height
  updateInputCellHeight(height) {
    // plus 1 to account for border (it was off by 1px in IE)
    if (height > this.state.inputCellHeight + 1) {
      this.setState({ inputCellHeight: height });
    }
  }


  renderInputRow(left) {
    // warning: passing unordered columns from header row component will break the order
    const columns = left ? this.leftColumns() : this.rightColumns();
    const cols = columns.map((col, idx) => {
      const { resizingCol, newRow, inputCellHeight } = this.state;
      const activeResize = resizingCol && col.id === resizingCol ? 'resize' : '';
      const cell = newRow.data[col.id];
      const cellClass = `preclearance-input-cell ${cell && cell.error ? 'preclearance-error' : ''}  ${col.readOnly ? 'disabled' : ''} ${activeResize}`;
      const cellStyle = {
        width: col.width,
        minHeight: inputCellHeight + 1,
        height: 'auto',
      };
      if (col.id === ADD_COLUMN) {
        return (
          <div
            className={cellClass}
            key={ADD_COLUMN}
            style={{ width: col.width + SCROLLBAR_OFFSET }}
          >
            <button
              onClick={this.toggleAddRow}
              type="button"
              className="text-button"
            >
              <i className="material-icons">
                close
              </i>
            </button>
          </div>
        );
      }
      if (col.id === ADD_ATTACHMENT) {
        return (
          <DisconnectedAttachmentCell
            key={ADD_ATTACHMENT}
            setUploads={this.setFiles}
            files={newRow.files}
            cellClass={cellClass}
            cellStyle={cellStyle}
          />
        );
      } if (col.id === MATCHES) {
        return (
          <div
            className={`${cellClass} match`}
            key={MATCHES}
            style={cellStyle}
          >
            <MatchButtonRenderer
              matchType={MONITORED_RECORD}
              disableMatching
              toolTipText="Please save this row first, then you will be able to match it"
            />
          </div>
        );
      }
      const lastFixedLeft = left && idx === columns.length - 1;
      let refCb = () => {};
      // todo this assumes that the first focusable input in the left column is also the last
      if (lastFixedLeft) {
        refCb = (r) => { this.lastLeftInputRow = r; };
      } else if (idx === 0 && !left) {
        refCb = (r) => { this.firstRightInputRow = r; };
      }
      return (
        <div
          className={cellClass}
          key={`new-row-${col.id}`}
          style={cellStyle}
          ref={(r) => r && this.updateInputCellHeight(r.offsetHeight)}
        >
          <Cell
            value={cell.value}
            error={cell.error}
            editing
            column={col}
            updateCellVal={(e) => this.editNewRow(col.id)(e.target.value)}
            updateDropdownCell={this.editNewRow(col.id)}
            updateCheckboxCell={(checked) => this.editNewRow(col.id)(checked)}
            currency={this.grid.getCurrency(newRow)}
            refCb={refCb}
          />
        </div>
      );
    });
    return (
      <div
        onClick={this.clearActiveCell}
        className="input-row"
      >
        {cols}
      </div>
    );
  }

  renderHeaderRow({ style }) {
    const rightOrdered = this.originalOrderedColumns().filter((col) => (FIXED_LEFT_COLUMN_IDS.indexOf(col.id) < 0));
    const sortableRightOrdered = rightOrdered.filter((col) => (FIXED_RIGHT_COLUMN_IDS).indexOf(col.id) < 0);
    return (
      <HeaderRow
        style={style}
        columns={rightOrdered} // need to pass down unordered columns, bc SortablePane library handles ordering based on its ord
        sortableCols={sortableRightOrdered}
        resizingCol={this.state.resizingCol}
        onResize={this.onResize}
        onResizeStop={this.onResizeStop}
        onSortEnd={this.onSortEnd}
        createColumn={this.createColumn}
        updateColumn={this.updateColumn}
        deleteColumn={this.deleteColumn}
        addFilter={this.addFilter}
        sort={this.sort}
        selectedCol={this.getColumn(this.state.activeColumn)}
        toggleColumnSelect={this.toggleColumnSelect}
        disableDropdown={this.grid.rowsToRender().length === 0}
        canEditColumns={this.props.canEditColumns}
        renderInputRow={this.renderInputRow}
        addingRow={Boolean(this.state.newRow)}
        hasShadow={this.scrollSync ? this.scrollSync.state.scrollTop > 0 : false}
      />
    );
  }

  render() {
    if (this.state.loading) return <div className="load-wrapper"><Loading /></div>;
    const rows = this.state.loadingRows ? [] : this.grid.rowsToRender();
    const width = this.getTotalWidth();

    const columns = [...this.leftColumns(), ...this.rightColumns()].map((col) => (
      <Column
        dataKey={col.id}
        key={col.id}
        id={col.id}
        cellRenderer={this.cellRenderer}
        columnData={col}
        style={{
          margin: 0,
          overflow: 'visible',
          whiteSpace: 'normal',
        }}
        width={col.width}
        className="cell-outer"
      />
    ));
    const splitIdx = FIXED_LEFT_COLUMN_IDS.length;
    const leftColumns = columns.slice(0, splitIdx);
    const rightColumns = columns.slice(splitIdx);
    const { statusFilter, filters } = this.state;
    const statusObj = statusFilter ? this.props.statuses[statusFilter.value] : null;

    return (
      <div className="preclearance-inner">
        {/* to do probably move this to preclearanceBody */}
        <div className="preclearance-header-wrapper">
          <FiltersHeader
            options={Object.values(this.props.statuses)}
            setFilter={(o) => { this.addFilter('Status', 'status', 'TextField', o.id); }}
            removeFilter={() => { this.filter(filters, null); }}
            filter={statusObj}
          />
          <div className="nav-container">
            <a
              className="text-button uppercase export-button"
              onClick={() => this.grid.toCsvDownload(this.props.rows)}
            >
              <i className="material-icons">
                open_in_new
              </i>
              Export
            </a>
            {
              this.props.showPreclearanceSettings
                ? (
                  <Link className="text-button uppercase" to="/app/preclearance/settings">
                    <i className="material-icons">settings</i>
                    Preclearance Settings
                  </Link>
                )
                : null
            }
          </div>
        </div>
        <Filters
          filters={filters}
          removeFilter={this.removeFilter}
        />

        <ScrollSync ref={(el) => { this.scrollSync = el; }}>
          {({
            onScroll,
            scrollTop,
          }) => (
            <div className="preclearance-table-container">
              {this.state.loadingRows && (
                <div className="table-load-screen">
                  <Loading />
                </div>
              )}
              <div className={`${this.state.showSidebarShadow ? 'shadow ' : ''}fixed-left`}>
                <AutoSizer disableWidth>
                  {({ height }) => (
                    <FixedLeft
                      height={height - SCROLLBAR_OFFSET}
                      rowCount={rows.length}
                      rowHeight={this.rowHeight}
                      getRow={this.getRow}
                      rowRenderer={this.rowRenderer}
                      headerHeight={this.getHeaderHeight()}
                      scrollTop={scrollTop}
                      onScroll={onScroll}
                      renderedColumns={leftColumns}
                      columns={this.leftColumns()}
                      resizingCol={this.state.resizingCol}
                      onResize={this.onResize}
                      onResizeStop={this.onResizeStop}
                      updateColumn={this.updateColumn}
                      addFilter={this.addFilter}
                      sort={this.sort}
                      selectedCol={this.getColumn(this.state.activeColumn)}
                      toggleColumnSelect={this.toggleColumnSelect}
                      disableDropdown={this.grid.rowsToRender().length === 0}
                      renderInputRow={this.renderInputRow}
                      addingRow={Boolean(this.state.newRow)}
                      toggleAddRow={this.toggleAddRow}
                      saveRow={this.saveRow}
                      loadingRows={this.state.loadingRows}
                    />
                  )}
                </AutoSizer>
              </div>
              <div className={MAIN_TABLE_CONTAINER} onScroll={(e) => { this.handleScroll(e); }}>
                <AutoSizer disableWidth>
                  {({ height }) => (
                    <Table
                      width={width}
                      height={height - SCROLLBAR_OFFSET}
                      headerHeight={this.getHeaderHeight()}
                      rowHeight={this.rowHeight}
                      rowCount={rows.length}
                      rowGetter={this.getRow}
                      rowRenderer={this.rowRenderer}
                      headerRowRenderer={this.renderHeaderRow}
                      gridClassName={`${SCROLLABLE_GRID_CLASS} ${this.props.rows.length === 0 ? ' no-rows' : ''}`}
                      noRowsRenderer={() => this.noRowsRenderer(height - SCROLLBAR_OFFSET)}
                      onScroll={onScroll}
                      scrollTop={scrollTop}
                    >
                      {rightColumns}
                    </Table>
                  )}
                </AutoSizer>
              </div>
            </div>
          )}
        </ScrollSync>
      </div>
    );
  }
}

export default TableWithSortableColumns;
