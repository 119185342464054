import { connect } from 'react-redux';
import { getSortedTags } from '../../reducers/tags/selectors';
import { setDateFilter } from '../../reducers/ui/actions';
import { clearResults } from '../../reducers/results/actions';
import FiltersDropdown from './filtersDropdown';

const mapStateToProps = state => ({
  dateFilter: state.ui.dateFilter,
  tags: getSortedTags(state),
  globalDateFilter: state.user.global_date_filter || null,
});

export default connect(mapStateToProps, {
  setDateFilter,
  clearResults,
})(FiltersDropdown);
