const serializeFieldChoices = (choices) => choices.map(({ label, value }, order) => ({
  label,
  value,
  order,
}));


const serializeFieldDependencies = (dependencies, fieldOrder) => dependencies.map((item) => {
  const order = fieldOrder.findIndex((fieldId) => (
    // must use == because reordering field changes id from number to string
    fieldId == item.prerequisite_field
  ));
  return ({
    prerequisite_field_order: order,
    prerequisite_values: [...item.prerequisite_values],
  });
});

export const serializeForm = (values) => {
  const { fieldOrder } = values;
  // make order field correct before sending
  const fields = fieldOrder.map((fieldId, index) => {
    const field = values.fields[fieldId];
    const choices = serializeFieldChoices(field.choices);
    const dependencies = serializeFieldDependencies(field.dependencies, fieldOrder);
    const newField = {
      ...field,
      choices,
      dependencies,
      order: index,
    };
    return newField;
  });
  const payload = {
    ...values,
    fields,
    is_active: true,
  };
  delete payload.fieldOrder;
  return payload;
};
