import * as types from '../results/types';


// ----state shape----
// {
//   6627: {
//     id: 6627, <--- sourceResultId
//     source: 76,
//     count: 71,
//     completed: true,
//     has_errors: false,
//     error_message: "",
//     max_results: false,
//     has_jur_selected: true,
//     data: [contributionId, contributionId, ...],
//   },
//   6626: {
//     id: 6626,
//     source: 76,
//     count: 71,
//     completed: true,
//     has_errors: false,
//     error_message: "",
//     max_results: false,
//     has_jur_selected: true,
//     data: [contributionId, contributionId, ...],
//   }, ...
// }

const defaultState = {};

// given an array of sourceResults, grab everything but transform the data
// into an array of contribution IDs, since the actual contribution data will go in its own slice of state
function formatSourceResults(sourceResults, searchId) {
  return sourceResults.reduce((formattedSourceResults, sourceResult) => {
    const formatted = Object.keys(sourceResult).reduce((formattedResult, key) => {
      if (key === 'data') {
        formattedResult[key] = sourceResult.data.map(contribution => contribution.data.id);
      } else {
        formattedResult[key] = sourceResult[key];
      }
      if (searchId) formattedResult.searchId = searchId;
      return formattedResult
    }, {});
    formattedSourceResults[sourceResult.id] = formatted;
    return formattedSourceResults;
  }, {});
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.REMOVE_SEARCH_OBJ_RESULTS: {
      const searchResIds = Object.keys(state).filter(sourceResId => state[sourceResId].searchId === action.id);
      const nextState = { ...state };
      searchResIds.map(id => delete nextState[id]);
      return nextState;
    }
    case types.CLEAR_RESULTS:
      return {};
    case types.LOAD_JURISDICTION_RESULTS:
    case types.LOAD_RESULTS:
      return Object.assign(state, formatSourceResults(action.results, action.id));
    case types.LOAD_INDIVIDUAL_RESULT:
      return Object.assign(state, formatSourceResults([action.data], action.id));
    default:
      return state;
  }
};
