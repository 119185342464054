import React from 'react';
import PropTypes from 'prop-types';

const Column = ({ dataKey, label }) => {
  const _label = label || dataKey;

  return <th>{_label}</th>;
};

Column.propTypes = {
  dataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf(['default', 'date', 'currency', 'number']),
  renderer: PropTypes.func,
};

Column.defaultProps = {
  type: 'default',
  renderer: null,
  label: null,
};

export { Column };
