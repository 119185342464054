import { connect } from 'react-redux';
import Research from '../components/research/research';
import { resetState } from '../reducers/research/actions';
import { hasJurisdictionPermissions } from '../helpers/permissions';


const mapStateToProps = state => ({
  showJurisdictions: hasJurisdictionPermissions(state.user),
});


export default connect(
  mapStateToProps,
  { resetState },
)(Research);
