import React from 'react';
import PropTypes from 'prop-types';
import { formatAmount, formatDate } from 'helpers/formatFieldHelpers';
import { AMOUNT, DATE, MATCHES, MONITORED_RECORD } from '../consts';
import MatchButtonRenderer from '../matchButtonRenderer';


const Cell = ({ value, col, noMatch, matchType }) => {
  let formattedVal;
  switch (col) {
    case AMOUNT:
      // because of currency column on preclearance only add $ if a monitored record (if matchtype is monitored record than the record itself is a preclearance request since match type refers to the match not record)
      if (matchType !== MONITORED_RECORD) {
        formattedVal = formatAmount(value);
      } else {
        formattedVal = value;
      }
      break;
    case DATE:
      formattedVal = formatDate(value);
      break;
    case MATCHES:
      formattedVal = (
        <MatchButtonRenderer
          noMatch={noMatch}
          matches={value}
          matchType={matchType}
          viewOnly
        />);
      break;
    default:
      formattedVal = value;
  }
  return (
    <span>
      { formattedVal }
    </span>
  );
};

Cell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  col: PropTypes.string,
  noMatch: PropTypes.bool,
  matchType: PropTypes.string,
};

Cell.defaultProps = {
  value: '',
  col: '',
  noMatch: false,
  matchType: '',
};

export default Cell;
