import React from 'react';
import PropTypes from 'prop-types';

const EmptyState = ({ messageHeading, icon, message, children }) => (
  <div className="noResults">
    <div className="noResultsHeading">
      <i className="material-icons">{icon}</i>
      {messageHeading}
    </div>
    <div>
      {message}
    </div>
    {children}
  </div>
);

EmptyState.propTypes = {
  messageHeading: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

EmptyState.defaultProps = {
  messageHeading: '',
  message: '',
  icon: '',
  children: null,
};

export default EmptyState;
