import React from 'react';
import PropTypes from 'prop-types';
import {
  Route, Switch, Redirect, useRouteMatch,
} from 'react-router-dom';
import { EMPLOYEE_PATHS, PATH_NAMES } from 'utils/constants';
import {
  DEPENDENT,
  SPOUSE,
  NON_SELF_SEARCH_LIMIT,
  nonSelfSearches,
} from 'components/employeePage/consts';
import MonitoringForm from 'components/monitoringForm/monitoringForm';
import EmployeePage from './employeePage';


function EmployeeRouter({ employee }) {
  const { path, url } = useRouteMatch();
  const { archived, search_objects: searches } = employee;
  const atNonSelfSearchLimit = nonSelfSearches(searches).length >= NON_SELF_SEARCH_LIMIT;

  return (
    <Switch>
      <Route
        path={`${path}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}/:searchId/edit`}
        render={() => (<MonitoringForm edit />)}
      />
      <Route
        path={`${path}/new/spouse`}
        render={() => {
          if (archived || atNonSelfSearchLimit) {
            return <Redirect to={`${url}`} />;
          }
          return (
            <MonitoringForm searchType={SPOUSE} />
          );
        }}
      />
      <Route
        path={`${path}/new/dependent`}
        render={() => {
          if (archived || atNonSelfSearchLimit) {
            return <Redirect to={`${url}`} />;
          }
          return (
            <MonitoringForm searchType={DEPENDENT} />
          );
        }}
      />
      <Route
        path={`${path}`}
        render={() => (<EmployeePage employee={employee} />)}
      />
    </Switch>
  );
}

EmployeeRouter.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default EmployeeRouter;
