import * as types from './types';

const initialState = {};

const macros = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_MACROS: {
      return action.macros;
    }
    default: {
      return state;
    }
  }
};

export default macros;
