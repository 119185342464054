import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

const Autofill = ({ value, field, fieldMap, children }) => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        const source = children.filter((item) => item[field] === value);
        if (source.length) {
            const sourceItem = source[0];
            Object.entries(fieldMap).forEach(([sourceKey, targetkey]) => {
                const sourceValue = get(sourceItem, sourceKey);
                setFieldValue(targetkey, sourceValue);
            });
        }
    }, [value, field, fieldMap, children]);
    return null;
};

Autofill.propTypes = {
    value: PropTypes.any,
    field: PropTypes.string,
    fieldMap: PropTypes.object.isRequired,
    children: PropTypes.arrayOf(PropTypes.object),
};

Autofill.defaultProps = {
    field: 'id',
};

export { Autofill };
