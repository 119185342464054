export default (root) => {
  Number.prototype.overrideLocaleString = function () {
    const isIE = root.navigator.userAgent.indexOf('MSIE') >= 0;
    if (!isIE) {
      return this.toLocaleString();
    }
    const str = this.toLocaleString();
    const lastDot = str.lastIndexOf('.');
    if (lastDot === str.length - 3) {
      return str.slice(0, lastDot);
    }
    return str;
  };

  // Apply the Custom Event polyfill.
  (function () {
    if ( typeof window.CustomEvent === "function" ) return;
    function CustomEvent ( event, params ) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };
      var evt = document.createEvent( 'CustomEvent' );
      evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
      return evt;
     }
    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
  })();
  
  // Polyfill for .remove in IE
  // from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
  (function (arr) {
    arr.forEach(function (item) {
      if (item.hasOwnProperty('remove')) {
        return;
      }
      Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          if (this.parentNode !== null)
            this.parentNode.removeChild(this);
        }
      });
    });
  }([Element.prototype, CharacterData.prototype, DocumentType.prototype]));
  
};
