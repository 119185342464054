import React from 'react';
import PropTypes from 'prop-types';


const TextField = ({ placeholder, disabled }) => (
  <input
    type="text"
    placeholder={placeholder}
    className="preview-input"
    disabled={disabled}
  />
);

TextField.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

TextField.defaultProps = {
  placeholder: '',
  disabled: false,
};

export { TextField };
