import React from 'react';
import PropTypes from 'prop-types';
import { useField, FieldArray, ErrorMessage } from 'formik';
import { CustomDropdown } from 'components/dropdown';
import { DropdownButton, DropdownOption } from 'components/shared/objectDropdown';
import { OptionGroup } from './OptionGroup';


const MultiSelectDropdown = ({ label, choices, choiceMap, nonSelectedValue, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const requiredChoices = choices.filter(choice => choice.required);

  return (
    <CustomDropdown render={({ isOpen, openDropdown, closeDropdown, dropdownRef }) => (
      <div className="form-field">
        <label>{label}</label>
        <div className="dropdown multiselect">
          <div className="drop-wrapper">
            <OptionGroup
              name={field.name}
              choices={field.value.map(val => choiceMap[val])}
            />
            <DropdownButton
              dropdownText={field.value && field.value.length ?
                '' : nonSelectedValue
              }
              isOpen={isOpen}
              onClick={openDropdown}
            />
          </div>
          {
            isOpen ?
              <div className="dropdown-container" ref={dropdownRef}>
                <FieldArray
                  name={field.name}
                  render={arrayHelpers => (
                    <div role="group" className="chip-container">
                      {choices && choices.length > 0 ? (
                        choices.map((choice) => {
                          const { value } = choice;
                          const selected = field.value.includes(value);
                          return (
                            <DropdownOption
                              key={value}
                              onClick={() => {
                                if (!selected) {
                                  arrayHelpers.push(value);
                                  requiredChoices.forEach((reqChoice) => {
                                    const val = reqChoice.value;
                                    if (!field.value.includes(val) && value !== val) {
                                      arrayHelpers.push(val);
                                    }
                                  });
                                }
                                closeDropdown();
                              }}
                              value={choice.value}
                              selected={selected}
                            >
                              {choice.label}
                            </DropdownOption>
                          );
                        })
                      ) :
                        null}
                      {nonSelectedValue ?
                        <DropdownOption
                          onClick={() => {
                            helpers.setValue([]);
                            closeDropdown();
                          }}
                          value={nonSelectedValue}
                          selected={!field.value || !field.value.length}
                        >
                          { nonSelectedValue }
                        </DropdownOption> : null
                      }
                    </div>
                  )}
                />
              </div>
              : null
          }
          <ErrorMessage
            name={field.name}
            component="div"
            className="error-text"
          />
        </div>
      </div>

    )}
    />
  );
};

MultiSelectDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
      ]).isRequired,
      required: PropTypes.bool,
    }),
  ).isRequired,
  choiceMap: PropTypes.object.isRequired,
  nonSelectedValue: PropTypes.string,
};

MultiSelectDropdown.defaultProps = {
  nonSelectedValue: null,
};

export { MultiSelectDropdown };
