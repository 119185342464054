import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PATHS, PATH_NAMES } from 'utils/constants';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import TagItem from './tagItem';
import Triangle from '../../../triangle';
import NoTags from './noTags';


class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.ref = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick(e) {
    if (this.state.open && !this.ref.contains(e.target)) {
      this.setState({ open: false });
    }
  }

  render() {
    const { open } = this.state;
    const {
      tags,
      selectedTags,
      toggleTag,
      disabled,
    } = this.props;

    return (
      <div className="tag-select-container" ref={(el) => { this.ref = el; }}>
        <IconTextButton
          icon={'label'}
          buttonText={'add tag'}
          className="text-button"
          buttonHandler={() => { this.setState({ open: !open }); }}
          disabled={disabled}
          toolTipText={disabled ? 'Tags cannot be added to archived employees' : null}
        />
        { open &&
        <div className="tag-select-dropdown">
          <Triangle position={'bottom'} />
          {tags.length ?
            <div>
              <div>
                <ul>
                  {
                    tags.map(tag => (
                      <TagItem
                        key={tag.id}
                        tag={tag.name}
                        onClick={toggleTag(tag)}
                        selected={selectedTags.indexOf(tag.id) >= 0}
                      />
                    ))
                  }
                </ul>
                <div className="bottom-container">
                  <hr />
                  <Link to={`${PATHS[PATH_NAMES.platform_settings]}`}>MANAGE TAGS</Link>
                </div>
              </div>
            </div> :
            <NoTags />
          }
        </div>
        }
      </div>
    );
  }
}

Tags.propTypes = {
  tags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array.isRequired,
  toggleTag: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Tags.defaultProps = {
  selectedTags: [],
  disabled: false,
};

export default Tags;
