import React, { Component } from "react";
import PropTypes from "prop-types";

import TransitionContainer from "../../transitionContainer";
import ProgressBar from "../ProgressBar";

class FileItem extends Component {

  constructor(props) {
    super(props);
    this.deleteFile = this.deleteFile.bind(this);
    this.retryUpload = this.retryUpload.bind(this);
    this.getUpload = this.getUpload.bind(this);
    this.getTransitorRef = (el) => { this.transitor = el; }

    this.initialState = props.progress === 100 ? 1 : 0;
    this.deleting = false;

    if(props.hasError) {
      this.initialState = 2;
    }
  }

  componentWillReceiveProps(nextProps) {
    const prevState = this.transitor.getState();
    if(nextProps.progress === 100 && this.props.progress !== nextProps.progress) {
      this.transitor.advanceState(1);
    }
    else if(!this.props.hasError && nextProps.hasError) {
      this.transitor.advanceState(2);
    }
    else if(this.props.hasError && !nextProps.hasError) {
      this.transitor.advanceState(0);
    }
    if(prevState !== this.transitor.getState()) {
      setTimeout(this.forceUpdate, 1000);
    }
  }

  deleteFile(e) {
    if(this.deleting) {
      return;
    }
    e.stopPropagation();
    this.deleting = true;
    this.props.deleteFile(this.props.name).then(() => {
      this.deleting = false;
    });
  }

  deleteButton() {
    const { progress, hasError, hideRemoveText } = this.props;
    return (
      <div className="file-item-del" onClick={this.deleteFile}>
        <i className="material-icons">
          clear
        </i>
        {
          (progress < 100 && !hasError) || hideRemoveText ? (
            null
          ) : (
            <span>
              Remove
            </span>
          )
        }
      </div>
    )
  }

  retryUpload() {
    this.props.retryUpload(this.props.name);
  }

  getUpload(e) {
    e.preventDefault();
    const dlWindow = window.open("/", "_blank");
    this.props.getUpload(this.props.name, this.props.contributionId).then(({data}) => {
      dlWindow.location = data;
    });
  }

  render() {
    const TransitionContainer = this.props.transitionContainer;
    const loading = this.props.progress < 100 ? 'loading' : '';
    return (
      <div className="file-item">
        <a className={`file-url ${loading}`} href="#" onClick={this.getUpload} target="_blank">
          <i className="material-icons">
            attach_file
          </i>
          <span
            className={`file-name ${loading}`}
          >
            { this.props.name }
          </span>
        </a>
        <div style={{ flex: 1 }}>
          <TransitionContainer initialState={this.initialState} ref={this.getTransitorRef}>
            <div className="file-loading file-status">
              <ProgressBar width={this.props.progressBarWidth} percentage={this.props.progress} />
              { this.deleteButton() }
            </div>
            <div className="file-loaded file-status">
              { this.deleteButton() }
            </div>
            <div className="file-error file-status">
              <button onClick={this.retryUpload}>Retry</button>
              { this.deleteButton() }
            </div>
          </TransitionContainer>
        </div>
      </div>
    );
  }
}

FileItem.propTypes = {
  name: PropTypes.string.isRequired,
  percentComplete: PropTypes.number,
  deleteFile: PropTypes.func,
  retryUpload: PropTypes.func,
  progressBarWidth :PropTypes.number,
  hideRemoveText: PropTypes.bool,
  transitionContainer: PropTypes.func,
};

FileItem.defaultProps = {
  progressBarWidth: 0,
  deleteFileMarginLeft: 0,
  transitionContainer: TransitionContainer,
  hideRemoveText: false,
};

export default FileItem;
