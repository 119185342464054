import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TriangleShadow from '../triangleShadow';
import ErrorBoundary from '../errorBoundary';
import Sidebar from './sidebar';
import { ScrollToTopOnMount } from './scrollToTop';

class BodyWithSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFixed: false,
      selectedTab: props.tabs[0],
    };
    this.onSelectTab = this.onSelectTab.bind(this);
  }

  onSelectTab(tab) {
    this.setState({ selectedTab: tab });
  }

  render() {
    const { isFixed, selectedTab } = this.state;
    const BodyComponent = this.props.bodyMap[selectedTab];

    return (
      <div className={`sidebar-page-container ${this.props.className}`} id="research-page">
        <ErrorBoundary>
          <ScrollToTopOnMount />
          <Sidebar
            title={this.props.title}
            tabs={this.props.tabs}
            tabMap={this.props.tabMap}
            isFixed={isFixed}
            selectedTab={selectedTab}
            selectTab={this.onSelectTab}
          />
          <div className="sidebar-page-body">
            <BodyComponent selectedTab={selectedTab} />
          </div>
          <TriangleShadow />
        </ErrorBoundary>
      </div>
    );
  }
}

export default BodyWithSidebar;

BodyWithSidebar.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  bodyMap: PropTypes.object.isRequired,
  className: PropTypes.string,
  tabMap: PropTypes.object.isRequired,
};

BodyWithSidebar.defaultProps = {
  title: '',
  className: '',
};

