import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import Tippy from '@tippyjs/react';
import SearchFilters from './searchFilters';


const errorMessage = (heading, errorsObj) => (
  <div>
    <div className="heading">
      { heading }
    </div>
    <div className="message">
      <ul>
        {
          Object.entries(errorsObj).map(([key, value]) =>
            (<li key={key}>
              <div>
                {!!key.length && isNaN(key) ? `${key}:` : ''}
              </div>
              <div>
                {value}
              </div>
            </li>),
          )
        }
      </ul>
    </div>
  </div>
);


function HeaderSubRow({
  search, uiDateFilter, errorsObj, isJurisdiction,
}) {
  const numErrors = Object.values(errorsObj).length;
  const errorType = isJurisdiction ? 'search' : 'source';
  const errorHeading = isJurisdiction ? `There is temporarily incomplete data due to the following issue${numErrors > 1 ? 's' : ''}` : `Results may be incomplete for the following source${numErrors > 1 ? 's' : ''}`;
  return (
    <div className="search-header-sub-row">
      <div className="results-details-row">
        <SearchFilters
          search={search}
          uiDateFilter={uiDateFilter}
        />
        {numErrors > 0 ?
          <Tippy
            content={errorMessage(errorHeading, errorsObj)}
          >
            <span className="errors-wrapper">
              <span className="errors">
                <i className="material-icons">info</i>
                {numErrors} {pluralize(errorType, numErrors)} had errors
              </span>
            </span>
          </Tippy>
          : null}
      </div>
    </div>
  );
}


HeaderSubRow.propTypes = {
  search: PropTypes.object.isRequired,
  uiDateFilter: PropTypes.object, // only in research view
  errorsObj: PropTypes.object.isRequired,
  isJurisdiction: PropTypes.bool,
};

HeaderSubRow.defaultProps = {
  uiDateFilter: {},
  isJurisdiction: false,
};

export default HeaderSubRow;
