import React, { useState } from 'react';
import ErrorBoundary from 'components/errorBoundary';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import TriangleShadow from 'components/triangleShadow';
import DashboardWorkflow from 'components/dashboard/dashboardWorkFlowWrapper';
import ChartsWrapper from 'components/dashboard/charts/chartsWrapper';


function Dashboard() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <ErrorBoundary>
        <ScrollToTopOnMount />
        <TriangleShadow />
        <DashboardWorkflow
          openInsights={() => setIsOpen(true)}
        />
        {
          isOpen && (
          <ChartsWrapper
            closeCharts={() => setIsOpen(false)}
          />
          )
        }
      </ErrorBoundary>
    </div>
  );
}

export default Dashboard;
