import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';

import {
  addUpload, removeUpload, retryUpload, getUpload,
} from 'reducers/uploads/actions';

import FileItem from './fileItem';


const FILE_LIMIT = 3;

const NoteFiles = ({
  files,
  fileCount,
  contributionId,
  addUpload,
  removeUpload,
  retryUpload,
  getUpload,
}) => {
  const onFileInputChange = (e) => {
    const updatedFiles = e.target.files;
    if (!window.fileStore) {
      window.fileStore = {};
    }
    if (!window.fileStore[contributionId]) {
      window.fileStore[contributionId] = {};
    }
    // For IE 10+ support, since it doesn't support FileList iteration.
    const filesArray = [];
    for (let i = 0; i < updatedFiles.length; i++) {
      filesArray.push(updatedFiles.item(i));
    }
    filesArray.forEach((file) => {
      addUpload(file, contributionId).catch(() => {
        // Needed to store files for retry.
        window.fileStore[contributionId][file.name] = file;
      });
    });
    e.target.value = null;
  };

  const onFileDelete = (fileName) => removeUpload(fileName, contributionId);

  const onRetryUpload = (fileName) => {
    const targetFile = window.fileStore[contributionId][fileName];
    retryUpload(targetFile, contributionId).then((_) => {
      delete window.fileStore[contributionId][targetFile.name];
    });
  };

  return (
    <div>
      <div className="note-files">
        {
          files.map((file, index) => (
            // File supplies props of `name`, `progress`, and `hasError`
            <FileItem
              key={index}
              deleteFile={onFileDelete}
              retryUpload={onRetryUpload}
              getUpload={getUpload}
              contributionId={contributionId}
              {...file}
            />
          ))
        }
        <div
          className="file-controls"
        >
          <Tippy
            content={"You've reached the maximum number of files for this record. Delete files to continue uploading."}
            interactive
            disabled={fileCount < FILE_LIMIT}
          >
            <label
              htmlFor="hidden-files"
              className={`upload-handler${fileCount >= FILE_LIMIT ? ' disable' : ''}`}
            >
              <input
                type="file"
                name="hidden-files"
                id="hidden-files"
                onChange={onFileInputChange}
                disabled={fileCount >= FILE_LIMIT}
                aria-label="file upload"
              />
              <i className="material-icons">add</i>
              <span>
                Attach Files
              </span>
            </label>
          </Tippy>
        </div>
      </div>
    </div>
  );
};


NoteFiles.propTypes = {
  files: PropTypes.array,
  fileCount: PropTypes.number.isRequired,
  contributionId: PropTypes.number.isRequired,
  addUpload: PropTypes.func.isRequired,
  removeUpload: PropTypes.func.isRequired,
  retryUpload: PropTypes.func.isRequired,
  getUpload: PropTypes.func.isRequired,
};

NoteFiles.defaultProps = {
  files: [],
};

export default connect(null, {
  addUpload,
  removeUpload,
  retryUpload,
  getUpload,
})(NoteFiles);
