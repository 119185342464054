import React from 'react';

const TriangleShadow = () => (
  <svg className="triangle-shadow">
    <defs>
      <filter
        x="0%"
        y="-16%"
        width="110%"
        height="110%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feGaussianBlur in="SourceAlpha" stdDeviation="12" />
      </filter>
    </defs>
  </svg>
);

export default TriangleShadow;
