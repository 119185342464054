import React from 'react';
import PropTypes from 'prop-types';
import { POLITICAL_CONTRIBUTIONS } from 'utils/constants';
import RecordMatchingTable from 'components/shared/matching/matchingTables/recordMatchingTable';
import { CategoryIcon } from 'components/shared/recordItem/categoryIcon';
import Schema from 'components/shared/sortableSchema';
import PreclearanceMatchingTable from './matchingTables/preclearanceMatchingTable';
import MatchesSection from './matchesSection';
import MatchButtons from './matchButtons';
import { PRE_ROW_SCHEMA } from './consts';

const MatchingContainer = ({
  closeModal, loading, rows, columns, sort, onSort, selectedRow, handleRowSelect,
  matches, createMatch, markAsNoMatch, filtersComponent, toggleComponent,
  suggestedMatchToggle, loadMoreRows, endCount, recordToBeMatched, recordSchema,
  noMatch, undoNoMatch,
}) => (
  <div className="matching-modal matching-container on-monitoring">
    <div>
      <h4>
        Monitored record
      </h4>
      <div className="dashboard-styles-wrapper">
        <CategoryIcon sourceCat={POLITICAL_CONTRIBUTIONS} />
        <RecordMatchingTable
          columns={recordSchema}
          rows={[recordToBeMatched]}
          schema={
            <Schema
              schema={recordSchema}
            />
          }
        />
      </div>
    </div>
    <hr />
    { matches.length ?
      <MatchesSection
        matches={matches}
        columns={PRE_ROW_SCHEMA}
      />
      : ''
    }
    <div className="flex-wrapper">
      <h4>Preclearance requests</h4>
    </div>
    {filtersComponent}
    <div className="matching-toggle-wrapper">
      {suggestedMatchToggle}
      {toggleComponent}
    </div>
    <PreclearanceMatchingTable
      loading={loading}
      rows={rows}
      columns={columns}
      sort={sort}
      onSort={onSort}
      selectedRow={selectedRow}
      handleRowSelect={handleRowSelect}
      loadMoreRows={loadMoreRows}
      endCount={endCount}
      matches={matches}
    />
    <MatchButtons
      onCancel={closeModal}
      markAsNoMatch={noMatch ? undoNoMatch : markAsNoMatch}
      createMatch={createMatch}
      nomatchDisabled={Boolean(matches.length)}
      noMatch={noMatch}
    />
  </div>
);


MatchingContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  sort: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  selectedRow: PropTypes.string,
  handleRowSelect: PropTypes.func.isRequired,
  createMatch: PropTypes.func.isRequired,
  markAsNoMatch: PropTypes.func.isRequired,
  matches: PropTypes.array,
  filtersComponent: PropTypes.object.isRequired,
  toggleComponent: PropTypes.object.isRequired,
  suggestedMatchToggle: PropTypes.object,
  loadMoreRows: PropTypes.func.isRequired,
  endCount: PropTypes.number.isRequired,
  recordToBeMatched: PropTypes.object,
  recordSchema: PropTypes.array.isRequired,
  noMatch: PropTypes.bool.isRequired,
  undoNoMatch: PropTypes.func.isRequired,
};

MatchingContainer.defaultProps = {
  selectedRow: null,
  matches: [],
  recordToBeMatched: {},
  suggestedMatchToggle: {},
};

export default MatchingContainer;
