/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { SCROLLABLE_GRID_CLASS, MODAL_CONTAINER, MAIN_TABLE_CONTAINER, SCROLLABLE_CONTAINER } from 'utils/constants';
import Portal from 'components/shared/portal';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import Triangle from 'components/triangle';
import RowCard from './rowCard';
import { PRE_REQUEST, PRE_ROW_SCHEMA, RECORD_ROW_SCHEMA } from './consts';

class ViewMatchesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {
        left: 0,
        top: 0,
      },
    };
    this.viewMatchWidth = window.matchMedia('(max-width: 1025px)').matches ? 510 : 610;
    this.onScroll = this.onScroll.bind(this);
    this.onScrollLeft = this.onScrollLeft.bind(this);
    this.setPosition = this.setPosition.bind(this);
  }

  componentDidMount() {
    this.modal = document.getElementsByClassName(MODAL_CONTAINER)[0];
    this.table = document.getElementsByClassName(SCROLLABLE_GRID_CLASS)[this.modal ? 1 : 0];
    this.scrollLeftTable = document.getElementsByClassName(MAIN_TABLE_CONTAINER)[0];

    this.modal && this.modal.addEventListener('scroll', this.onScroll);
    this.table && this.table.addEventListener('scroll', this.onScroll);
    this.scrollLeftTable && this.scrollLeftTable.addEventListener('scroll', this.onScrollLeft);

    this.setPosition();
  }

  componentWillUnmount() {
    this.modal && this.modal.removeEventListener('scroll', this.onScroll);
    this.table && this.table.removeEventListener('scroll', this.onScroll);
    this.scrollLeftTable && this.scrollLeftTable.removeEventListener('scroll', this.onScrollLeft);
  }

  onScrollLeft(e) {
    const table = e.target;
    const scrollLeftPos = table.scrollLeft;
    const deltaX = scrollLeftPos - this.prevScrollLeftPos;
    const { rect } = this.props;
    this.setState({
      position: {
        ...this.state.position,
        left: ((rect.left - this.viewMatchWidth) + rect.width) + -(deltaX),
      },
    });
  }

  onScroll() {
    const scrollPos = this.table.scrollTop;
    const prevScroll = this.prevScrollPos;
    const deltaY = scrollPos - prevScroll;
    const modalDeltaY = this.modal ? this.modal.scrollTop - this.prevModalScroll : 0;
    const { rect } = this.props;
    this.setState({
      position: {
        ...this.state.position,
        top: (this.topPos + -(deltaY) + -(modalDeltaY)) + rect.height + 10,
      },
    });
  }


  setPosition() {
    const { rect } = this.props;
    const windowLoc = SCROLLABLE_CONTAINER instanceof Window ? (window.scrollY || window.pageYOffset) : SCROLLABLE_CONTAINER.scrollTop;
    const topPos = rect.top + windowLoc;
    this.setState({
      position: {
        left: (rect.left - this.viewMatchWidth) + rect.width,
        top: topPos + rect.height + 10,
      },
    });
    this.prevScrollPos = this.table ? this.table.scrollTop : 0;
    this.prevModalScroll = this.modal ? this.modal.scrollTop : 0;
    this.prevScrollLeftPos = this.scrollLeftTable ? this.scrollLeftTable.scrollLeft : 0;
    this.topPos = topPos;
  }

  render() {
    const { matches, openMatchingDialog, matchType, viewOnly, removeMatch, rect } = this.props;
    return (
      <Portal>
        <div
          ref={this.props.viewMatchRef}
          style={{
            ...this.state.position,
            width: this.viewMatchWidth,
            zIndex: this.modal ? 5 : 'auto',
          }}
          className="tooltip view-match__container"
        >
          <Triangle
            position={'bottom'}
            style={{ right: (rect.width / 2) - 8 }}
          />
          <div className="heading">Matched {pluralize(matchType, matches.length)}</div>
          <div className="view-match__container__records">
            {matches.map(match => (
              <RowCard
                key={match.id}
                record={match}
                schema={matchType === PRE_REQUEST ? PRE_ROW_SCHEMA : RECORD_ROW_SCHEMA}
                removeMatch={removeMatch}
                viewOnly={viewOnly}
                matchType={matchType}
              />
            ))}
          </div>
          { !viewOnly ?
            <div className="view-match__container__flex-row">
              <IconTextButton
                buttonHandler={openMatchingDialog}
                buttonText={'Find additional matches'}
                icon={'add'}
              />
            </div> : ''
          }
        </div>
      </Portal>
    );
  }
}

ViewMatchesContainer.propTypes = {
  matches: PropTypes.array.isRequired,
  openMatchingDialog: PropTypes.func.isRequired,
  matchType: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  removeMatch: PropTypes.func.isRequired,
  rect: PropTypes.object.isRequired,
  viewMatchRef: PropTypes.func.isRequired,
};

ViewMatchesContainer.defaultProps = {
  viewOnly: false,
};

export default ViewMatchesContainer;
