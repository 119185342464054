import { useState, useEffect } from 'react';
import { orderBy, get } from 'lodash';


export const [ASC, DESC] = ['asc', 'desc'];

export const ICON_SORT_MAP = {
  [ASC]: 'arrow_upward',
  [DESC]: 'arrow_downward',
};

const sortFunc = (item, field) => {
  const val = get(item, field);
  if (val && typeof (val) === 'string') {
    return val.toLowerCase();
  } else if (val === null) {
    // to properly sort null values
    return '';
  }
  return val;
};

export const sortData = (data, sortBy, sortDir) =>
  orderBy(data, [item => (sortFunc(item, sortBy))], sortDir);

export default function useSort(defaultSort, data) {
  const [sortBy, setSortBy] = useState(defaultSort);
  const [sortDir, setSortDir] = useState(ASC);
  const [sortedData, setSorted] = useState(data);

  useEffect(() => {
    const sorted = sortData(data, sortBy, sortDir);
    setSorted(sorted);
  }, [sortDir, sortBy]);

  function onSort(col) {
    const dir = col === sortBy && sortDir === ASC ? DESC : ASC;
    setSortBy(col);
    setSortDir(dir);
  }

  return {
    sortDir,
    sortBy,
    sortedData,
    onSort,
  };
}
