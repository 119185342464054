import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { disableSendEmailPermissions } from 'helpers/permissions';
import Section from 'components/shared/settingsPage/section';
import OpenItemWrapper from 'components/shared/openItemWrapper';
import { editAutoAction } from 'reducers/preclearance/autoActions/actions';
import { AutoResponseItem, AutoResponseEditItem } from '../actionItems/autoResponseItem';

const findErrors = (action) => {
  const requiredFields = ['template'];
  const missingFields = requiredFields.filter(f => !action[f]);
  return missingFields.length > 0 ? 'Please fill out all fields' : null;
};

function AutoResponse({
  templates, editAutoAction, action, disableSendEmail,
}) {
  return (
    <Section
      subHeader={'Automated Questionnaire Response'}
      helpText={<p>Select the email template that will automatically be sent when a person fills out the preclearance request form.</p>}
    >
      <OpenItemWrapper
        findErrors={findErrors}
        updateFunc={editAutoAction}
        entityName={'action'}
        render={({ toggleEditing, onFieldChange, openField, errors, onUpdate, onToggle }) => {
          const editing = Boolean(openField);
          const ActionComponent = editing ? AutoResponseEditItem : AutoResponseItem;
          return (
            <div className="auto-actions-wrapper">
              <ActionComponent
                action={openField || action}
                templates={templates}
                toggleEditing={toggleEditing}
                onToggleActive={() => onToggle(action, 'active')}
                onSubmit={onUpdate}
                onFieldChange={onFieldChange}
                errors={errors}
                buttonText={'Update'}
                onClose={() => { toggleEditing(null); }}
                disabled={disableSendEmail}
                disabledMessage="Sending emails is not allowed for your organization."
              />
            </div>
          );
        }}
      />
    </Section>
  );
}


const mapStateToProps = (state) => ({
  templates: state.preclearance.templates,
  action: state.preclearance.autoActions[state.preclearance.table.autoResponseAction],
  disableSendEmail: disableSendEmailPermissions(state.user),
});

export default connect(
  mapStateToProps,
  {
    editAutoAction,
  })(AutoResponse);

AutoResponse.propTypes = {
  templates: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  editAutoAction: PropTypes.func.isRequired,
  disableSendEmail: PropTypes.bool.isRequired,
};

