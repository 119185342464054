import { connect } from 'react-redux';
import RecordGroup from '../shared/searchResults/recordGroup';

import { EMPLOYEES } from '../../utils/constants';
import { byId } from '../../reducers/contributions/selectors';
import * as sourcesSelectors from '../../reducers/sources/selectors';
import * as sourceResultSelectors from '../../reducers/sourceResults/selectors';


const mapStateToProps = (state, props) => {
  const sourceResult = sourceResultSelectors.bySourceId(state.sourceResults, props.source.id, props.searchId);
  const results = byId(state.contributions, sourceResult.data);

  const sources = sourcesSelectors.byId(state.sources.sources, Array.from(new Set(results.map(res => res.sourceId))));
  const schemas = sources.reduce((_schemas, source) => {
    _schemas[source.id] = source[`${props.searchType === EMPLOYEES ? 'person' : 'entity'}_schema`];
    return _schemas;
  }, {});

  return {
    selectedContribs: state.ui.selectedContribs,
    statuses: state.statuses,
    results,
    schemas,
    sourceResultIds: [sourceResult.id],
    isJurisdiction: false,
    url: props.source.url,
    resultCount: results.length,
  };
};


export default connect(mapStateToProps)(RecordGroup);
