import * as types from './types';

const createList = searchType => (state = [], action) => {
  if (action.searchType !== searchType) {
    return state;
  }
  switch (action.type) {
    case types.RECEIVE_SEARCH_OBJS:
      return action.searches.map(search => search.id);
    case types.ADD_SEARCH: {
      return [...state, action.search.id];
    }
    case types.REMOVE_SEARCH: {
      return state.filter(id => id !== action.id);
    }
    default: return state;
  }
};

export default createList;

export const getIds = state => state;
