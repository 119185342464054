import moment from 'moment';
import {
  NA,
  JURISDICTION,
  DATE,
  DECIMAL,
  AMOUNT,
} from './constants';

export const acceptableDateFormats = ['MM-DD-YYYY', 'MM-DD-YY', 'MM-D-YY', 'MM-D-YYYY', 'M-D-YY', 'M-DD-YY', 'M-DD-YYYY', 'M-D-YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY', 'MM/DD/YY', 'MM/D/YY', 'MM/D/YYYY', 'M/D/YY', 'M/DD/YYYY', 'M/DD/YY', 'M/D/YYYY'];

const validateDecimals = (value) => {
  if ((value % 1) !== 0) {
    return value.toString().split('.')[1].length <= 2;
  }
  return true;
};

const VALIDATORS = {
  [DECIMAL]: (columnLabel, value) => {
    value = stripDollarSign(value);
    // cant use typeof bc typeof Number(someStr) => NaN and typeof NaN => "number"
    if (Number(value) * 0 !== 0) {
      return `${columnLabel} must be a number`;
    } else if (!validateDecimals(value)) {
      return `${columnLabel} may not have more than 2 decimals places`;
    }
  },
  [DATE]: (columnLabel, value) => {
    const date = moment(value, acceptableDateFormats, true);
    if (value && !date.isValid()) {
      return 'Please enter a valid date (MM/DD/YYYY)';
    }
  },
};

export function stripDollarSign(value) {
  value = String(value);
  if (value.indexOf('$') === 0) {
    value = value.slice(1);
  }
  return value;
}

export function validateCell(value, { type, label, required }) {
  if (required && (!value || (typeof (value) === 'string' && !value.trim()))) {
    return `${label} can't be blank.`;
  }
  // other types fall into dropdown categories, so not validating those
  if ([DATE, DECIMAL].indexOf(type) < 0) {
    return '';
  }
  return VALIDATORS[type](label, value) || '';
}


export function formatDate(date) {
  return moment(date, acceptableDateFormats).format('YYYY-MM-DD');
}


export function serializeCell(cellVal, colId, jurisdictions, colMap) {
  let value = cellVal;
  const column = colMap[colId];
  const data = {};
  if (!column.required && column.input_type === 'select' && (!value || value === NA)) {
    value = null;
  } else {
    if (colId === AMOUNT) {
      value = stripDollarSign(value) || 0;
    }
    if (colId === 'date') {
      value = formatDate(value);
    }
    if (colId === JURISDICTION) {
      const jurObj = jurisdictions.byName[value];
      data.jurisdiction = jurObj ? jurObj.id : null;
    }
  }
  data[colId] = value;
  return { data };
}


export function serializeRow(row, jurisdictions, colMap, colIds) {
  let data = {};
  colIds.forEach((colId) => {
    const value = row.data[colId].value;
    const cell = serializeCell(value, colId, jurisdictions, colMap);
    data = { ...data, ...cell.data };
  });
  const serialized = {
    hidden: false,
    data,
    files: row.files,
  };
  return serialized;
}
