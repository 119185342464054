import React from 'react';
import PropTypes from 'prop-types';
import JurisdictionDropdown from './jurisdictionDropdownContainer';
import FiltersDropdown from '../filtersDropdown/filtersDropdownContainer';

const JurisdictionForm = ({ jurisdiction, onJurisdictionSelect }) => (
  <div className="form-wrapper header">
    <div>
      <div className="flex row">
        <div className="filter-item">
          <JurisdictionDropdown
            jurisdiction={jurisdiction}
            onJurisdictionSelect={onJurisdictionSelect}
          />
        </div>
        <div className="filter-item">
          <FiltersDropdown />
        </div>
      </div>
    </div>
  </div>
);

export default JurisdictionForm;

JurisdictionForm.propTypes = {
  jurisdiction: PropTypes.object,
  onJurisdictionSelect: PropTypes.func.isRequired,
};

JurisdictionForm.defaultProps = {
  jurisdiction: null,
};
