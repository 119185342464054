import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DOMPurify from 'dompurify';
import Checkbox from 'components/monitoringForm/sourceSelection/checkbox';


function noVal(val) {
  return (val === null || val === undefined || val === '');
}


function TextVal({ value }) {
  return (
    <div>
      {value}
    </div>
  );
}

TextVal.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};


function ChoiceFieldVal({ field, value }) {
  const { choices } = field;
  const choiceMap = new Map(choices);
  return (
    <div>
      {choiceMap.get(value) || value}
    </div>
  );
}

ChoiceFieldVal.propTypes = {
  value: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
};


function DateVal({ value }) {
  return (
    <div>
      {moment(value).format('ll')}
    </div>
  );
}

DateVal.propTypes = {
  value: PropTypes.string.isRequired,
};


function CheckboxGroupVal({ field, value }) {
  const { choices } = field;
  const choiceMap = new Map(choices);
  return (
    <>
      {value.map((val) => (
        <div key={val}>
          <Checkbox
            disabled
            checked
            id={`${val}`}
            onChange={() => {}}
          >
            <span className="md-question answered">
              {choiceMap.get(val) || val}
            </span>
          </Checkbox>
        </div>
      ))}
    </>
  );
}

CheckboxGroupVal.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.array,
};

CheckboxGroupVal.defaultProps = {
  value: [],
};


function ResponseItem({ field, value, hidden }) {
  let Value;

  const fieldType = field.input_type;
  const { label, required } = field;

  switch (fieldType) {
    case 'TextField':
      Value = TextVal;
      break;
    case 'IntegerField':
      Value = TextVal;
      break;
    case 'DecimalField':
      Value = TextVal;
      break;
    case 'DateField':
      Value = DateVal;
      break;
    case 'EnumField':
      Value = ChoiceFieldVal;
      break;
    case 'CheckboxGroupField':
      Value = CheckboxGroupVal;
      break;
    default:
      Value = TextVal;
  }

  if (hidden) {
    return null;
  }

  const labelString = `${label} ${required ? '<span class="required-asterisk">*</span>' : ''}`;
  const cleanLabel = DOMPurify.sanitize(labelString);
  return (
    <div className="response-item">
      <div
        className="md-question answered"
        dangerouslySetInnerHTML={{
          __html: cleanLabel,
        }}
      />
      <div>
        { noVal(value) || (Array.isArray(value) && !value.length)
          ? (
            <div className="no-response">
              No response
            </div>
          )
          : (
            <Value
              value={value}
              fieldId={field.id}
              field={field}
              required={required}
            />
          )}
      </div>
    </div>
  );
}

ResponseItem.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
  hidden: PropTypes.bool,
};

ResponseItem.defaultProps = {
  value: null,
  hidden: false,
};

export default ResponseItem;
