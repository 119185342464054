import * as types from './types';

const defaultState = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_SEARCH:
      return action.searchInfo;
    case types.RESET_STATE:
      return defaultState;
    default: return state;
  }
};
