import { connect } from 'react-redux';
import { hasExportPermissions } from 'helpers/permissions';
import { EMPLOYEES, ENTITIES, JURISDICTIONS } from 'utils/constants';
import { getSearchesByType } from 'reducers/searchObjects/selectors';
import ResultsHeader from './header';


const jurisdictionMapStateToProps = (state, props) => {
  const canExport = hasExportPermissions(state.user);
  const searchRef = state.jurisdictions.find(jur => jur.id === props.selectedJur.id);
  const errors = {};
  // todo how will errors work in this view with the refactoring???
  // to do improve these errors
  Object.values(state.sourceResults).forEach((result) => {
    if (result.error_message) {
      errors[result.error_message] = 1;
    } else if (result.has_errors) {
      errors['Unspecified error.'] = 1;
    } else if (result.max_results) {
      errors['Maximum results returned for this source.'] = 1;
    }
  });

  const searchObjs = getSearchesByType(state, EMPLOYEES).concat(getSearchesByType(state, ENTITIES));
  const resultCount = searchObjs.reduce((totalCount, searchObj) => (totalCount + searchObj.last_count), 0);

  return {
    searchCompleted: true, // na here really
    search: searchRef,
    resultCount,
    errorsObj: errors,
    dateFilter: state.ui.dateFilter,
    exportType: JURISDICTIONS,
    // kind of wierd, but to show count properly
    totalSourceCount: state.ui.resultsLoading ? 0 : 1,
    canExport,
    showProgressBar: false,
    isJurisdiction: true,
  };
};

export const JurisdictionHeader = connect(jurisdictionMapStateToProps)(ResultsHeader);

