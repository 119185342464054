import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';


// NOTE:
// the changes noted are specfically to allow items in the sidebar to appear as if they are being copied into the body (we are not removing the item from one list and adding it to another) (see https://github.com/atlassian/react-beautiful-dnd/issues/216)


function DraggableItem({
  index, children, draggableId, isDragDisabled,
}) {
  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <>
          <div
            className={`${snapshot.isDragging ? 'dragging ' : ''} question-type-item ${isDragDisabled ? 'disabled ' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              // see NOTE
              transform: snapshot.isDragging
                ? provided.draggableProps.style?.transform
                : 'translate(0px, 0px)',
            }}
          >
            { children }
            <i className="material-icons drag-indicator">
              drag_indicator
            </i>
          </div>
          {/* see NOTE */}
          {snapshot.isDragging && (
          <div
            className="question-type-item clone"
            style={{ transform: 'none !important' }}
          >
            { children }
            <i className="material-icons drag-indicator">
              drag_indicator
            </i>
          </div>
          )}
        </>
      )}
    </Draggable>
  );
}

DraggableItem.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.object.isRequired,
  draggableId: PropTypes.string.isRequired,
  isDragDisabled: PropTypes.bool,
};

DraggableItem.defaultProps = {
  isDragDisabled: false,
};

export default DraggableItem;
