import React from 'react';
import PropTypes from 'prop-types';
import ChangeConfirmation from 'components/preclearance/settings/changeConfirmation';
import { RelatedCertificationsList } from 'components/preclearance/settings/relatedDataConfirmations';


const DeleteConfirmation = ({
  form, onCancel, onDelete, certificationTemplates,
}) => {
  const relatedCertifications = certificationTemplates.filter(
    (cert) => (cert.form && cert.form === form.id),
  );

  return (
    <ChangeConfirmation
      onSubmit={onDelete}
      onCancel={onCancel}
      confirmText="Delete"
      headerText="Delete form"
      confirmSentence={(
        <div>
          Are you sure you would like to delete
          {' '}
          <strong>
            {form.name}
          </strong>
          ?
        </div>
    )}
    >
      <RelatedCertificationsList
        relatedCertifications={relatedCertifications}
        isDeleted
        message="This form will be removed from the following unsent certifications and these certifications will not be sent until a new form is selected."
      />
    </ChangeConfirmation>
  );
};

DeleteConfirmation.propTypes = {
  form: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  certificationTemplates: PropTypes.array.isRequired,
};

export default DeleteConfirmation;
