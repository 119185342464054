import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { PEOPLE, BASE_MONITORING_URL } from 'utils/constants';
import EmployeeSearchesList from './employeeSearchesList';


function formatTerms(arr, limit, after) {
  const terms = arr.slice(0, limit).join(', ');
  return arr.length > limit ? terms.concat(after) : terms;
}

function formatNames(search) {
  let mainName = '';
  const aliases = [];
  if (search.last_name) {
    const first = search.first_name;
    const last = search.last_name;
    mainName = `${last}, ${first}`;
    const altFirsts = search.alt_firsts;
    const firsts = [...altFirsts, first];
    const altLasts = search.alt_lasts;
    const lasts = [...altLasts, last];
    for (let i = 0; i < firsts.length; i++) {
      if (aliases.length >= 3) break;
      for (let j = 0; j < lasts.length; j++) {
        if (aliases.length >= 3) break;
        const alias = `${lasts[j]}, ${firsts[i]}`;
        if (alias !== mainName) {
          aliases.push(`(${alias})`);
        }
      }
    }
  } else if (search.entity_name) {
    mainName = search.entity_name;
  }

  return {
    mainName,
    aliases,
  };
}


function formatAliases(aliases) {
  let str = '';
  if (aliases.length) {
    str = aliases.slice(0, 2).join(' ');
  }
  if (aliases.length > 2) {
    str = str.concat(' ...');
  }
  return str;
}


function SearchItemTab({ search, uiSearchType, search: { search_settings } }) {
  const { mainName, aliases } = formatNames(search);
  return (
    <NavLink
      className="employee-tab"
      activeClassName="active"
      to={`/app/${BASE_MONITORING_URL}/${uiSearchType}/${search.id}`}
    >
      <div className="employee-name-container">
        <span className="employee-name-main">
          { mainName }
          <i
            className="archived"
          >
            { search.archived ? ' (archived)' : ''}
          </i>
        </span>
        <span className="employee-name-alt">
          {
            uiSearchType === PEOPLE ?
              formatAliases(aliases)
              : (
                search_settings.exclude.length > 0 && `EXCLUDING: ${formatTerms(search_settings.exclude, 3, '...')}`
              )
          }
        </span>
        {
          uiSearchType === PEOPLE ?
            <EmployeeSearchesList
              searches={search.search_objects}
            />
            :
            null
        }
      </div>
    </NavLink>
  );
}


// withRouter is needed for highlighting NavLink
export default withRouter(SearchItemTab);


SearchItemTab.propTypes = {
  search: PropTypes.object.isRequired,
  uiSearchType: PropTypes.string.isRequired,
};

