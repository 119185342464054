import { createSelector } from 'reselect';
import * as fromById from './byId';
import * as fromAllIds from './allIds';


export const getEmployees = (state) => {
  const ids = fromAllIds.getIds(state.employees.allIds);
  return ids.map(id => fromById.getEmployee(state.employees.byId, id));
};

export const byId = (state, id) => (state.employees.byId[id]);


export const getSearches = createSelector(
  [getEmployees],
  (employees) => employees.reduce((acc, emp) => [...acc, ...emp.search_objects], []),
);


export const getUnarchivedEmployees = createSelector(
  [getEmployees],
  (employees) => employees.filter((employee) => !employee.archived),
);
