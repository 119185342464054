import * as types from './types';
import api from '../../api';

export const setJurisdictions = data => ({
  jurisdictions: data,
  type: types.SET_JURISDICTIONS,
});

export const loadJurisdictions = () => dispatch => api.get('/jurisdictions/')
  .then(({ data }) => {
    dispatch(setJurisdictions(data));
    return data;
  });
