import * as types from './types';

// todo unnest this
const initState = {
  sources: [],
};


export default (state = initState, action) => {
  switch (action.type) {
    case types.SET_SOURCES: {
      const sources = action.sources.sort((a, b) => {
        const possibleSorts = ['category', 'subcategory', 'label'];
        const sortKey = possibleSorts.find(key => a[key] !== b[key]);
        if (!sortKey) {
          return 0;
        }
        return a[sortKey] > b[sortKey] ? 1 : -1;
      });
      return {
        ...state,
        sources,
      };
    }
    default: return state;
  }
};
