import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'api';
import Section from 'components/shared/settingsPage/section';
import Loading from 'components/loading';
import DeleteDelayForm from './DeleteDelayForm';


const noDelayOption = {
  id: null,
  name: 'No delay',
};

const makeOptions = (data) => {
  const options = data.map(({ id, label }) => ({
    id,
    name: label,
  }));
  return [noDelayOption, ...options];
};


const DeleteSettings = () => {
  const [loading, setLoading] = useState(true);
  const [delayOptions, setDelayOptions] = useState([]);

  useEffect(() => {
    let didCancel = false;
    api.get('/delete_delay_settings/').then(({ data }) => {
      if (!didCancel) {
        const options = makeOptions(data);
        setDelayOptions(options);
      }
    }).catch((err) => {
      toast('Something went wrong and we were unable to fetch the delete delay options');
      throw err;
    }).finally(() => {
      if (!didCancel) {
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <Section
      header="Delete Delay"
      helpText={(
        <p>
          It is sometimes useful to keep records of an employee around for a period of time before removing them from the system. Employees who are pending delete will be archived until they are deleted, so they are not actively monitored.
        </p>
      )}
    >
      {
        loading
          ? (
            <div className="load-wrapper">
              <Loading />
            </div>
          )
          : (
            <DeleteDelayForm
              delayOptions={delayOptions}
            />
          )
      }
    </Section>
  );
};


export default DeleteSettings;
