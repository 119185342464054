import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';


const SliderButton = ({
  sliderText,
  active,
  onToggle,
  disabled,
  tooltip,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const toggleSlider = (e) => {
    e.stopPropagation();
    setSubmitting(true);
    onToggle(!active).then(() => {
    }).catch((err) => {
      toast('Something went wrong.');
      throw err;
    }).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <Tippy
      disabled={!tooltip}
      content={tooltip}
    >
      <div>
        <button
          className={`slider ${active ? 'active' : ''}`}
          onClick={toggleSlider}
          type="button"
          disabled={isSubmitting || disabled}
        >
          <div className="slider-tab">
            <span>{ sliderText }</span>
          </div>
        </button>
      </div>
    </Tippy>
  );
};

SliderButton.propTypes = {
  sliderText: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

SliderButton.defaultProps = {
  disabled: false,
  tooltip: null,
};

export default SliderButton;
