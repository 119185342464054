import * as types from './types';

const initState = {
  filter: null,
  sourceCategoryFilter: null,
  animatingItems: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.SET_FILTER: {
      return {
        ...state,
        filter: action.filter,
      };
    }
    case types.SET_CATEGORY_FILTER: {
      return {
        ...state,
        sourceCategoryFilter: action.filter,
      };
    }
    case types.SET_ANIMATING_IDS: {
      const nextAnim = {};
      action.ids.forEach(record => nextAnim[record.meta.id] = record);
      return {
        ...state,
        animatingItems: nextAnim,
      };
    }
    case types.CLEAR_ANIMATING_IDS: {
      return {
        ...state,
        animatingItems: null,
      };
    }
    default:
      return state;
  }
};
