import React from 'react';
import CandidateTooltip from './recordItem/candidateTooltip';


class CandidateTooltipBody extends React.Component {
  componentDidMount() {
    document.body.addEventListener('tooltip.position', (e) => {
      if (this.candTooltip) {
        if (e.detail.recipient) {
          this.candTooltip.activateTooltip(e.detail.recipient, e.detail.boundingBox);
        } else {
          this.candTooltip.deactivateTooltip();
        }
      }
    });
  }

  componentWillUnmount() {
    document.body.removeEventListener('tooltip.position', this.onTooltipPositioning);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
        <CandidateTooltip ref={(el) => { this.candTooltip = el; }} />
      </React.Fragment>
    );
  }
}

export default CandidateTooltipBody;
