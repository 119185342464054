import * as types from '../types';

const initState = {
  reports: [],
  loaded: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.SET_LOADED: {
      return {
        reports: action.reports,
        loaded: true,
      }
    }

    case types.ADD_REPORT: {
      const existingIndex = state.reports.findIndex(r => r.id === action.report.id);
      const existingCopy = [...state.reports];
      if (existingIndex >= 0) {
        existingCopy[existingIndex] = action.report;
        return {
          ...state,
          reports: existingCopy,
        };
      }
      return {
        reports: [...state.reports, action.report],
        loaded: state.loaded,
      }
    }

    case types.DELETE_REPORT: {
      const reports = state.reports.filter(r => r.id !== action.id);
      return {
        ...state,
        reports,
      };
    }

    default: return state;
  }
};
