import * as types from './types';

const initState = {};

const tags = (state = initState, action) => {
  switch (action.type) {
    case types.LOAD_TAGS: {
      const tagState = {};
      action.data.reduce((_allTags, tag) => {
        tagState[tag.id] = tag;
        return tagState;
      }, {});
      return tagState;
    }
    case types.ADD_TAG: {
      const nextState = { ...state };
      nextState[action.data.id] = action.data;
      return nextState;
    }
    case types.UPDATE_TAG: {
      const nextState = { ...state };
      nextState[action.data.id] = action.data;
      return nextState;
    }
    case types.DELETE_TAG: {
      const nextState = { ...state };
      delete nextState[action.id];
      return nextState;
    }
    default:
      return state;
  }
};

export default tags;
