import { groupBy } from 'lodash';
import { alphabeticalSort, sortObjects } from 'helpers/helpers';


const sortPeople = (people) => people.sort((a, b) => {
  const aName = (`${a.last_name} ${a.first_name}`);
  const bName = (`${b.last_name} ${b.first_name}`);
  return alphabeticalSort(aName, bName);
});


const isNodeExpanded = (node, expanded) => (node && expanded.has(node.id));

const nodeHasChildren = (node) => node.children && node.children.length;


export const getFlattenedTree = (nodes, expanded) => nodes.reduce((flattenedTree, node) => {
  if (!nodeHasChildren(node) || !isNodeExpanded(node, expanded)) {
    const nodeCopy = { ...node };
    delete nodeCopy.children;
    return [...flattenedTree, nodeCopy];
  }
  const nodeCopy = { ...node };
  delete nodeCopy.children;
  return [...flattenedTree, nodeCopy, ...getFlattenedTree(node.children, expanded)];
}, []);


export const getParentNodeIds = (nodes) => nodes.reduce((parentNodeIds, node) => {
  if (!nodeHasChildren(node)) {
    return [...parentNodeIds];
  }
  return [...parentNodeIds, node.id, ...getParentNodeIds(node.children)];
}, []);


export const [ARCHIVE, ACTIVE] = ['ARCHIVE', 'ACTIVE'];
const [LETTER, SECTION] = ['LETTER', 'SECTION'];

export const getParentNodeId = (employee) => {
  const letter = employee.last_name[0].toUpperCase();
  return employee.archived ? `${ARCHIVE}-${letter}` : letter;
};


export const buildTree = (employees) => {
  const { ARCHIVE: archived, ACTIVE: active } = groupBy(employees,
    (employee) => (employee.archived ? ARCHIVE : ACTIVE));

  const archivedByLetter = groupBy(archived, (employee) => employee.last_name[0].toUpperCase());
  const activeByLetter = groupBy(active, (employee) => employee.last_name[0].toUpperCase());

  const letters = Object.keys(activeByLetter).map((letter) => ({
    id: letter,
    letter,
    children: sortPeople(activeByLetter[letter]),
    rowType: LETTER,
  }));

  const archivedLetters = Object.keys(archivedByLetter).map((letter) => ({
    id: `${ARCHIVE}-${letter}`,
    letter,
    children: sortPeople(archivedByLetter[letter]),
    rowType: LETTER,
  }));

  // for now only showing archived section if there are archived employees
  // but we may want to change this in the future
  const archivedNodes = archivedLetters.length
    ? [{
      id: ARCHIVE,
      letter: ARCHIVE,
      children: sortObjects(archivedLetters, 'letter'),
      rowType: SECTION,
      icon: 'inventory',
    }]
    : [];

  const allNodes = [
    ...sortObjects(letters, 'letter'),
    ...archivedNodes,
  ];
  return allNodes;
};
