import React from 'react';
import PropTypes from 'prop-types';
import CertificationPreclearanceRequests from './CertificationPreclearanceRequests';
import ResponseItem from '../SingleCertification/ResponseItem';

const SubmissionBody = ({
  header,
  footer,
  fields,
  startDate,
  endDate,
  pcColumns,
  preclearanceRequests,
  responses,
  showDependentFields,
}) => {
  const responseMap = {};
  // to make lookup easier
  (responses || []).reduce((_allRes, res) => {
    responseMap[res.field] = res;
    return responseMap;
  }, {});

  return (
    <div>
      {
        header
        && (
        <div className="survey-form-header">
          { header}
        </div>
        )
      }

      {
        fields.map((field) => {
          const { dependsOn } = field;
          const dependentField = dependsOn ? responseMap[dependsOn.fieldId] : null;
          const dependentValue = dependentField ? dependentField.value : null;
          const hidden = !showDependentFields && Boolean(dependsOn && !dependsOn.values.includes(dependentValue));
          return (
            field.input_type === 'PreclearanceTable' && !hidden
              ? (
                <div className="response-item" key={field.id}>
                  <CertificationPreclearanceRequests
                    startDate={startDate}
                    endDate={endDate}
                    pcRequests={preclearanceRequests}
                    columns={pcColumns}
                  />
                </div>
              )
              : (
                <ResponseItem
                  key={field.id}
                  field={field}
                  value={(responseMap[field.id] && responseMap[field.id].value) || null}
                  hidden={hidden || field.hidden}
                />
              )
          );
        })
      }
      {
        footer
        && (
        <div className="survey-form-footer">
          { footer}
        </div>
        )
      }
    </div>
  );
};

SubmissionBody.propTypes = {
  header: PropTypes.string,
  footer: PropTypes.string,
  fields: PropTypes.array.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  pcColumns: PropTypes.array.isRequired,
  preclearanceRequests: PropTypes.array.isRequired,
  responses: PropTypes.array.isRequired,
  showDependentFields: PropTypes.bool, // if user has not submitted form yet we will show all fields to the compliance user
};

SubmissionBody.defaultProps = {
  header: null,
  footer: null,
  startDate: '',
  endDate: '',
  showDependentFields: false,
};

export default SubmissionBody;
