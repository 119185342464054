/* eslint-disable react/sort-comp */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';


export const CONFIRMATION_TYPES = { update: 'Update', delete: 'Delete' };

class OpenItemWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openField: null,
      showConfirmation: null,
      confirmationType: null,
      errors: null,
    };
    this.onFieldChange = this.onFieldChange.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.toggleConfirmationType = this.toggleConfirmationType.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onFieldChange(value, field) {
    this.setState({ openField: { ...this.state.openField, [field]: value } });
  }

  makeToast(verb) {
    return () => {
      toast(`There was a problem ${verb} this ${this.props.entityName}.`);
    };
  }

  // base for creating, updating, and deleting
  _submitFunc(func, _target, skipValidation, errCb) {
    const target = _target || this.state.openField;
    const errors = skipValidation === true ? false : this.props.findErrors(target);
    if (errors) {
      this.setState({ errors });
      return;
    }
    func(target).then(() => {
      this.setState({ openField: null, showConfirmation: null });
    }).catch((err) => {
      const responseErrors = this.props.errorCb(err);
      if (responseErrors) {
        this.setState({ errors: responseErrors, showConfirmation: null });
      } else {
        errCb();
      }
      throw err;
    });
  }

  onCreate() {
    return this._submitFunc(this.props.createFunc, null, false, this.makeToast('adding'));
  }

  onUpdate() {
    return this._submitFunc(this.props.updateFunc, null, false, this.makeToast('editing'));
  }

  onToggle(_target, field) {
    const target = { ..._target };
    target[field] = !target[field];
    return this._submitFunc(this.props.updateFunc, target, true, this.makeToast('editing'));
  }

  onDelete(id) {
    return () => {
      this.setState({ openField: null, showConfirmation: null });
      this.props.deleteFunc(id || this.state.openField)
        .catch(this.makeToast('deleting'));
    };
  }

  toggleConfirmation(id) {
    // pass in the id of the object that the confirmation is referring to
    this.setState({ showConfirmation: id });
  }

  toggleEditing(editObj) {
    this.setState({ openField: editObj, errors: null });
  }

  toggleConfirmationType(confirmationType, validate = true) {
    return (id) => {
      const errors = validate ? this.props.findErrors(this.state.openField) : null;
      if (errors) {
        this.setState({ errors });
      } else {
        this.setState({ showConfirmation: id, confirmationType });
      }
    };
  }

  render() {
    return (
      this.props.render({
        openField: this.state.openField,
        toggleEditing: this.toggleEditing,
        onFieldChange: this.onFieldChange,
        onDelete: this.onDelete,
        toggleConfirmation: this.toggleConfirmation,
        toggleConfirmationType: this.toggleConfirmationType,
        showConfirmation: this.state.showConfirmation,
        confirmationType: this.state.confirmationType,
        errors: this.state.errors,
        onCreate: this.onCreate,
        onUpdate: this.onUpdate,
        onToggle: this.onToggle,
      })
    );
  }
}

export default OpenItemWrapper;

OpenItemWrapper.propTypes = {
  render: PropTypes.func.isRequired,
  findErrors: PropTypes.func.isRequired,
  createFunc: PropTypes.func,
  updateFunc: PropTypes.func.isRequired,
  deleteFunc: PropTypes.func,
  errorCb: PropTypes.func,
  entityName: PropTypes.string.isRequired,
};

OpenItemWrapper.defaultProps = {
  createFunc: () => {},
  deleteFunc: () => {},
  errorCb: () => {},
};
