import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleEditing } from 'reducers/research/actions';
import ResearchHeader from 'components/shared/searchObjectHeader/researchHeaderContainer';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import ResultsView from './resultsContainer';
import ResearchRecordGroup from './recordGroupContainer';
import ResearchSubcategory from './sourceSubcategoryContainer';

function ResearchResultView({ toggleEditing }) {
  return (
    <ResultsView
      showContribSchema={false}
      subcategoryComponent={ResearchSubcategory}
      recordGroupComponent={ResearchRecordGroup}
    >
      <ResearchHeader
        exportType={'research'}
        editButton={
          <IconTextButton
            icon={'edit'}
            buttonText={'edit'}
            className="text-button"
            buttonHandler={() => toggleEditing({ edit: true })}
          />
        }

      />
    </ResultsView>
  );
}

ResearchResultView.propTypes = {
  toggleEditing: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  toggleEditing: () => dispatch(toggleEditing(true)),
});

export default connect(null, mapDispatchToProps)(ResearchResultView);
