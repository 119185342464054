import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import Tippy from '@tippyjs/react';

import useCancellablePromise from 'hooks/useCancellablePromise';
import useModal from 'hooks/useModal';
import { updateUser } from 'reducers/user/actions';
import { SelectField } from 'components/Certifications/CertificationForm/forms/components';
import ConfirmationModal from './ConfirmationModal';


const ArchiveDelayForm = ({
  isManager,
  archiveDelay,
  delayOptions,
  editUser,
}) => {
  const [modalOpen, openModal, closeModal] = useModal(false);
  const { cancellablePromise } = useCancellablePromise();


  const onSubmit = (values, actions) => {
    cancellablePromise(editUser(values)).then(() => {
      actions.setSubmitting(false);
      closeModal();
      toast('Archive delay configuration saved!');
    }).catch((err) => {
      if (!err.isCanceled) {
        actions.setSubmitting(false);
        closeModal();
        toast('Something went wrong.');
        throw err;
      }
    });
  };

  return (
    <div>
      <Formik
        initialValues={({ archive_delay_setting: archiveDelay })}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {
        ({ isSubmitting }) => (
          <Form
            autoComplete="off"
            noValidate
          >
            <div>
              <Tippy
                content="Only managers are allowed to change the archive delay."
                disabled={isManager}
              >
                <div>
                  <div className="archive-delay-form">
                    <div>
                      <SelectField
                        choices={delayOptions}
                        label="Archive delay"
                        name="archive_delay_setting"
                        disabled={!isManager}
                      />
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <button
                      className="orange-button"
                      type="button"
                      onClick={openModal}
                      disabled={!isManager}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Tippy>
            </div>
            {

            modalOpen
              ? (
                <ConfirmationModal
                  isSubmitting={isSubmitting}
                  closeModal={closeModal}
                />
              )
              : null
            }
          </Form>
        )
      }
      </Formik>
    </div>
  );
};


const mapStateToProps = ({ user }) => ({
  isManager: user.is_manager,
  archiveDelay: user.archive_delay_setting,
});

ArchiveDelayForm.propTypes = {
  isManager: PropTypes.bool.isRequired,
  archiveDelay: PropTypes.number,
  delayOptions: PropTypes.array.isRequired,
  editUser: PropTypes.func.isRequired,
};

ArchiveDelayForm.defaultProps = {
  archiveDelay: null,
};

export default connect(mapStateToProps, {
  editUser: updateUser,
})(ArchiveDelayForm);
