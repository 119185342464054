export const byCategory = (sources, category) =>
  sources.filter(source => source.category === category);

export const bySubcategory = (sources, subcategory) =>
  sources.filter(source => (source.subcategory === subcategory) || source.label === subcategory);

export const allSelected = (sources, selected) => {
  const selSet = new Set(selected);
  return sources.reduce((prev, source) => prev && selSet.has(source.id), true);
};

export const categories = (sources) => {
  const categorySet = sources.reduce((cats, source) => cats.add(source.category), new Set());
  return Array.from(categorySet).sort();
};


export const hasSelected = (sources, selected) => {
  const selSet = new Set(selected);
  return sources.filter(source => selSet.has(source.id));
};

export const subcategories = (sources) => {
  const categorySet = sources.reduce((cats, source) => cats.add(source.subcategory || source.label), new Set());
  return Array.from(categorySet).sort();
};

export const active = (sources, selected) => sources.filter(source => selected.has(source.id));

export const activeByCategory = (sources, selected, category) => {
  const catSources = byCategory(sources, category);
  return active(catSources, selected);
};


export const byName = (sources, sourceNames) => (
  sourceNames.map(sourceName => sources.find(source => source.name == sourceName))
);

export const byId = (sources, sourceIds) => (
  // must be == not === b/c in research view id is a string
  sourceIds.map(id => sources.find(source => source.id == id))
);

export const allIds = sources => (
  sources.map(s => s.id)
);

export const subcategoryMap = (sources) => {
  const subcatMap = {};
  sources.forEach((source) => {
    const subcat = source.subcategory || source.label;
    subcatMap[subcat] ? subcatMap[subcat].push(source) : subcatMap[subcat] = [source];
  });
  return subcatMap;
  };

export const subcategoryResultCounts = (subcategoryMap, sourceCountMap) => {
  const resultCounts = {};
  Object.keys(subcategoryMap).forEach(subcategory => {
    const sources = subcategoryMap[subcategory];
    resultCounts[subcategory] = sources.reduce((runningCount, source) => {
      return runningCount + sourceCountMap[source.id].count;
      // return runningCount + sourceCountMap[source.id];
    }, 0);
  });
  return resultCounts;
};

export const getSourceIds = sources => (sources.map(s => s.id));
