import React from 'react';
import PropTypes from 'prop-types';
import {
  JURISDICTION,
  RECIPIENT,
  STATUS,
  DATE,
  CHECKBOX,
  DECIMAL,
} from './constants';
import {
  DateField,
  TextField,
  SelectField,
  CheckboxField,
  AmountField,
  AmountValue,
  DateValue,
  CheckboxValue,
  StatusValue,
  TextValue,
  SelectValue,
} from './cellComponents';
import CandidateDropdown from './candidateDropdown';
import PreJurisdictionDropdown from './preJurisdictionDropdown';


const Cell = ({
  value,
  error,
  editing,
  column,
  updateCellVal,
  updateDropdownCell,
  updateCheckboxCell,
  currency,
  dropdownTop,
  refCb,
}) => {
  const {
    type: columnType,
    input_type: inputType,
    id: columnId,
    choices,
    readOnly,
  } = column;

  let Field;
  let Value;

  // todo make a single source of truth for determining input type (requires BE changes)
  switch (columnType) {
    // we assume all decimal fields are amounts
    case DECIMAL:
      Field = AmountField;
      Value = AmountValue;
      break;
    case DATE:
      Field = DateField;
      Value = DateValue;
      break;
    case CHECKBOX:
      Field = CheckboxField;
      Value = CheckboxValue;
      break;
    default:
      Field = TextField;
      Value = TextValue;
  }

  if (inputType === 'select') {
    Field = SelectField;
    Value = SelectValue;
  }

  switch (columnId) {
    case JURISDICTION:
      Field = PreJurisdictionDropdown;
      break;
    case RECIPIENT:
      Field = CandidateDropdown;
      break;
    case STATUS:
      Value = StatusValue;
      break;
    default:
      break;
  }


  return (
    editing && !readOnly
      ? (
        <>
          <Field
            value={value}
            checked={!!value}
            name={`${columnId}`}
            onChange={updateCellVal}
            onSelect={updateDropdownCell}
            onCheck={updateCheckboxCell}
            choices={choices}
            currency={currency}
            dropdownTop={dropdownTop}
            refCb={refCb}
          />
          {
            error
            && (
            <span className="error">
              {error}
            </span>
            )
          }
        </>
      )
      : (
        <Value
          value={value}
          currency={currency}
          choices={choices}
        />
      )
  );
};

Cell.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  error: PropTypes.string,
  editing: PropTypes.bool.isRequired,
  column: PropTypes.object.isRequired,
  updateCellVal: PropTypes.func.isRequired,
  updateDropdownCell: PropTypes.func.isRequired,
  updateCheckboxCell: PropTypes.func.isRequired,
  currency: PropTypes.string,
  dropdownTop: PropTypes.bool,
  refCb: PropTypes.func,
};

Cell.defaultProps = {
  error: '',
  currency: null,
  dropdownTop: false,
  refCb: () => {},
};

export default Cell;
