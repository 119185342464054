import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { TextField, TextAreaField } from 'components/shared/SchemaForm/components';
import Fields from './Questions/Fields';
import {
  title,
  footer,
  header,
} from './consts';


function FormBody({
  fields,
  fieldOrder,
  openField,
  setOpenField,
  removeDependency,
  removeDependencyChoice,
}) {
  const [titleField, titleMeta, titleHelpers] = useField(title);

  return (
    <div className="form-builder-body">
      <div>
        <div className="survey-title-header">
          <div className="form-input-hidden-wrapper">
            <TextField
              placeholder="Add a title"
              className="form-input-hidden"
              id={title}
              name={title}
            />
            {titleMeta.error && (
              <p className="form-input-hidden error-text">{titleMeta.error}</p>
            )}
          </div>
        </div>
      </div>
      <div className="form-input-hidden-wrapper">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={header}
        >
          header
        </label>
        <TextAreaField
          placeholder="Add some instructions..."
          className="form-input-hidden"
          id={header}
          name={header}
        />
      </div>
      <Fields
        formFields={fields}
        fieldOrder={fieldOrder}
        openField={openField}
        setOpenField={setOpenField}
        removeDependency={removeDependency}
        removeDependencyChoice={removeDependencyChoice}
      />
      <div className="form-input-hidden-wrapper">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={footer}
        >
          footer
        </label>
        <TextAreaField
          placeholder="Add a footer..."
          className="form-input-hidden"
          id={footer}
          name={footer}
        />
      </div>
    </div>
  );
}

FormBody.propTypes = {
  fields: PropTypes.object.isRequired,
  fieldOrder: PropTypes.array.isRequired,
  openField: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  setOpenField: PropTypes.func.isRequired,
  removeDependency: PropTypes.func.isRequired,
  removeDependencyChoice: PropTypes.func.isRequired,
};

FormBody.defaultProps = {
  openField: null,
};

export default FormBody;
