import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-virtualized';
import HeaderRow from './headerComponents/HeaderRow';
import { ROW_HEIGHT } from './constants';
import LoadingRow from './LoadingRow';


class FixedLeft extends React.Component {
  constructor(props) {
    super(props);
    this.headerRowRenderer = this.headerRowRenderer.bind(this);
    this.loadingRowsRenderer = this.loadingRowsRenderer.bind(this);
  }

  // todo reconcil with loadingRowsRenderer in table.js
  loadingRowsRenderer(height) {
    const { loadingRows, columns, headerHeight } = this.props;
    if (!loadingRows) {
      return;
    }
    const numRows = Math.floor((height - headerHeight) / ROW_HEIGHT);
    return [...Array(numRows).keys()].map((_, index) => (
      <LoadingRow
        key={`fixed-preclearance-row-${index}`}
        className={index % 2 === 1 ? 'odd' : 'even'}
        columns={columns}
      />
    ));
  }

  headerRowRenderer({ style }) {
    const { addingRow, scrollTop } = this.props;
    const buttonText = addingRow ? 'save' : '+ add row';
    const onClick = addingRow ? this.props.saveRow : this.props.toggleAddRow;
    const hasShadow = scrollTop > 0;
    return (
      <div className={`${hasShadow ? 'left-header-shadow' : 'left-header'}`}>
        <HeaderRow
          style={style}
          sortableCols={this.props.columns}
          resizingCol={this.props.resizingCol}
          onResize={this.props.onResize}
          onResizeStop={this.props.onResizeStop}
          updateColumn={this.props.updateColumn}
          addFilter={this.props.addFilter}
          sort={this.props.sort}
          selectedCol={this.props.selectedCol}
          toggleColumnSelect={this.props.toggleColumnSelect}
          canEditColumns={false}
          disableDropdown={this.props.disableDropdown}
          renderInputRow={this.props.renderInputRow}
          addingRow={addingRow}
          hasShadow={hasShadow}
          fixedLeft
        />
        <button
          type="button"
          className={`${hasShadow ? 'shadow' : ''} preclearance-cell add-row-button`}
          onClick={onClick}
          style={{
            width: this.props.columns[0].width,
          }}
        >
          <div>{buttonText}</div>
        </button>
      </div>
    );
  }

  render() {
    const {
      columns,
      height,
      headerHeight,
      rowCount,
      rowHeight,
      getRow,
      rowRenderer,
      scrollTop,
      onScroll,
    } = this.props;

    const width = columns.map((col) => col.width).reduce((acc, val) => acc + val, 0);

    return (
      <Table
        width={width}
        height={height}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        rowCount={rowCount}
        rowGetter={getRow}
        rowRenderer={rowRenderer}
        headerRowRenderer={this.headerRowRenderer}
        noRowsRenderer={() => this.loadingRowsRenderer(height)}
        scrollTop={scrollTop}
        onScroll={onScroll}
      >
        { this.props.renderedColumns }
      </Table>
    );
  }
}

FixedLeft.propTypes = {
  columns: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onScroll: PropTypes.func.isRequired,
  getRow: PropTypes.func.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  scrollTop: PropTypes.number.isRequired,
  loadingRows: PropTypes.bool,
  rowHeight: PropTypes.func.isRequired,
  headerHeight: PropTypes.number.isRequired,
};

FixedLeft.defaultProps = {
  loadingRows: false,
};

export default FixedLeft;
