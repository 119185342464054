import React from 'react';
import PropTypes from 'prop-types';

export const TextField = ({
  value,
  onChange,
  refCb,
  placeholder,
  style,
}) => (
  <input
    ref={(r) => refCb(r)}
    value={value}
    onChange={onChange}
    type="text"
    placeholder={placeholder}
    style={style}
    autoComplete="off"
  />
);

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  refCb: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
};

TextField.defaultProps = {
  refCb: () => {},
  placeholder: '',
  style: {},
};
