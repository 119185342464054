import api from 'api';
import * as types from './types';


const receivePlatforms = (platforms) => ({
  platforms,
  type: types.RECEIVE_PLATFORMS,
});


export const fetchPlatforms = () => (dispatch) => api.get('/integrations/app_switcher/')
  .then(({ data }) => {
    dispatch(receivePlatforms(data));
  });
