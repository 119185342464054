import * as types from './types';

const initialState = {};

const statuses = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_STATUSES: {
      const statusState = {};
      action.data.reduce((_allStatuses, status) => {
        statusState[status.id] = status;
        return statusState;
      }, {});
      return statusState;
    }
    case types.ADD_STATUS: {
      const nextStatuses = { ...state };
      nextStatuses[action.data.id] = action.data;
      return nextStatuses;
    }
    case types.UPDATE_STATUS: {
      const statusesCopy = { ...state };
      statusesCopy[action.data.id] = action.data;
      return statusesCopy;
    }
    case types.DELETE_STATUS: {
      const statusesCopy = { ...state };
      delete statusesCopy[action.id];
      return statusesCopy;
    }
    default: {
      return state;
    }
  }
};

export default statuses;
