import React from 'react';
import api from 'api';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import Tippy from '@tippyjs/react';
import { useFormikContext } from 'formik';
import { ArrayField } from 'components/shared/SchemaForm/components/ArrayField';
import IconTextButton from '../iconTextButton';
import { altFirsts } from './consts';

function getNicknames(name) {
  return api.get(`/nicknames/${encodeURIComponent(name)}`).then(({ data }) => {
    if (data && data.length === 0) {
      toast(`No nicknames found for ${name}`);
      return [];
    }
    return data;
  }).catch((err) => {
    toast('There was a problem adding nicknames.');
    throw err;
  });
}

function AltNickNames({ firstName, value }) {
  const { setFieldValue } = useFormikContext();
  const limit = 5;

  function setNicknames() {
    getNicknames(firstName).then((nicknames) => {
      if (nicknames.length) {
        const alts = [...value];
        // Deduplicate new nicknames from API so we are only adding ones that aren't already listed
        const lowerCaseAlts = alts.map(alt => alt.toLowerCase());
        const newNicknames = nicknames.filter(nickname => lowerCaseAlts.indexOf(nickname.toLowerCase()) < 0);
        if (newNicknames.length === 0) {
          toast(`No new nicknames found for "${firstName}".`);
        } else {
          newNicknames.forEach((nickname) => {
            const emptyIndex = alts.indexOf('');
            if (emptyIndex >= 0) {
              alts[emptyIndex] = nickname;
            } else if (alts.length < limit) {
              alts.push(nickname);
            }
          });
          const nicknamesNotAdded = newNicknames.filter(n => alts.indexOf(n) < 0).length;
          if (nicknamesNotAdded > 0) {
            toast(` ${nicknamesNotAdded} ${pluralize('nickname', nicknamesNotAdded)} could not be added because the limit was exceeded.`);
          }
          setFieldValue(altFirsts, alts);
        }
      }
    });
  }

  const disableAdding = firstName.length < 2 || limit <= value.length;

  return (
    <div>
      <div className={'input-title flex'}>
        <span>alternate first name</span>
        <Tippy
          content={limit <= value.length ? 'You have reached the max number of alternate names.' : 'You may only add nicknames once you have entered a first name.'}
          disabled={!disableAdding}
        >
          <div>
            <IconTextButton
              icon={'add_circle_outline'}
              buttonText={'Auto-Populate Nicknames'}
              buttonHandler={() => { setNicknames(); }}
              disabled={disableAdding}
              className={'secondary-button'}
            />
          </div>
        </Tippy>
      </div>
      <ArrayField
        name={altFirsts}
        id={altFirsts}
        label={'alternate first name'}
        addButtonText={'alternate first name'}
        className={'form-input'}
        limit={limit}
        fieldType={'ArrayField'}
        minFields={1}
      />
    </div>
  );
}

AltNickNames.propTypes = {
  firstName: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
};

export default AltNickNames;
