import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Select from 'react-select';
import { DropdownIndicator, customStyles } from 'components/shared/SchemaForm/components/SearchableSelectField';


const Dropdown = (props) => {
  const {
    options,
    components,
    onChangeCb,
  } = props;
  const [{ value }, _meta, { setValue }] = useField(props);
  // when reorder the field id changes from number to string
  const selectedVal = options.find((option) => option.value == value);
  const handleSelection = (choice, { action }) => {
    switch (action) {
      case 'select-option': {
        setValue(choice.value);
        if (choice.value != value) {
          onChangeCb(choice.value);
        }
        break;
      }
      default:
    }
  };
  return (
    <Select
      options={options}
      components={{
        DropdownIndicator,
        ...components,
      }}
      classNamePrefix="searchable"
      className="select-wrapper"
      styles={customStyles}
      onChange={handleSelection}
      value={selectedVal}
      maxMenuHeight={200}
      isSearchable={false}
      captureMenuScroll={false} // there is bug in the library causing a weird flicker when scrolling with mouse (trackpad is not affected) making this false this prevents it, see https://github.com/JedWatson/react-select/issues/4640
    />
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  onChangeCb: PropTypes.func,
  components: PropTypes.object,
};

Dropdown.defaultProps = {
  components: {},
  onChangeCb: () => {},
};

export default Dropdown;
