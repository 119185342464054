import * as Yup from 'yup';
import { invalidEmailError, requiredError } from 'utils/errorHelpers';


export const [
  to,
  ccEmails,
  subject,
  body,
] = [
  'to',
  'cc',
  'subject',
  'body',
];

export const validationSchema = Yup.object().shape({
  [to]: Yup.string()
    .trim()
    .email(invalidEmailError)
    .required(requiredError),
  [ccEmails]: Yup.array()
    .of(
      Yup.string().email(invalidEmailError),
    ),
});
