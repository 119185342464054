import React from 'react';
import PropTypes from 'prop-types';
import IconTextButton from 'components/monitoringForm/iconTextButton';


function MatchButtons({
  onCancel,
  markAsNoMatch,
  createMatch,
  nomatchDisabled,
  noMatch,
}) {
  return (
    <div className="buttons-wrapper match-buttons">
      <button
        className="text-button uppercase"
        onClick={onCancel}
        type="button"
      >
        Cancel
      </button>
      <IconTextButton
        buttonHandler={markAsNoMatch}
        buttonText={noMatch ? 'Undo No Match' : 'No Match'}
        className={`${noMatch ? '' : 'icon-none '} no-match-button`}
        disabled={nomatchDisabled}
        toolTipText={nomatchDisabled ? 'This record already has a match.' : null}
        icon={noMatch ? 'undo' : null}
      />
      <IconTextButton
        buttonHandler={createMatch}
        buttonText="Match"
        icon="link"
        className="orange-match-button"
      />
    </div>
  );
}

MatchButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  markAsNoMatch: PropTypes.func.isRequired,
  createMatch: PropTypes.func.isRequired,
  nomatchDisabled: PropTypes.bool.isRequired,
  noMatch: PropTypes.bool.isRequired,
};

export default MatchButtons;
