import * as types from '../types';

export const openModal = ({ modalType }) => ({
  type: types.OPEN_PRE_MODAL,
  modalType,
});

export const closeModal = () => ({
  type: types.CLOSE_PRE_MODAL,
});

export const selectRow = ({ selectedRow }) => ({
  type: types.SELECT_ROW,
  selectedRow,
});
