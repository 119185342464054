import React, { useCallback, useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import Sidebar from '../Sidebar/Sidebar';
import { useDependentFields } from './useDependentFields';
import {
  CHOICE_FIELDS,
  FIELD_CHOICE,
  FORM_BODY_DROPPABLE,
  QUESTION_LIST_DROPPABLE,
  SPECIAL,
  SPECIAL_LIST_DROPPABLE,
} from '../consts';
import FormBody from './FormBody';
import {
  defaultQuestion,
  defaultChoice,
} from './consts';


function Form({ form, onValuesChange }) {
  const { fields, fieldOrder } = form;
  const { setFieldValue, values, isValid } = useFormikContext();
  const { removeDependency, removeDependencyChoice, allowReorder } = useDependentFields();
  const [openField, setOpenField] = useState(null);
  const [debouncedValues] = useDebounce(values, 350);

  useEffect(() => {
    if (isValid) {
      onValuesChange(debouncedValues);
    }
  }, [debouncedValues, isValid]);

  const handleOpenField = useCallback((field) => {
    if (isValid && field !== openField) {
      setOpenField(field);
    }
  }, [isValid, openField]);

  const addField = useCallback((inputType, destinationIdx) => {
    const choices = CHOICE_FIELDS.includes(inputType) ? [{ ...defaultChoice() }] : [];
    const label = inputType === SPECIAL ? null : defaultQuestion.label;
    const newField = {
      id: uuidv4(),
      input_type: inputType,
      ...defaultQuestion,
      label,
      choices,
    };
    const newFields = [...fieldOrder];
    newFields.splice(destinationIdx, 0, newField.id);
    setFieldValue('fields', {
      ...fields,
      [newField.id]: newField,
    });
    setFieldValue('fieldOrder', newFields);
    handleOpenField(newField.id);
  }, [fields, fieldOrder, setFieldValue, handleOpenField]);


  const reorderField = useCallback((sourceIdx, destinationIdx, draggableId) => {
    const canReorder = allowReorder(draggableId, destinationIdx);
    if (canReorder) {
      const newFields = [...fieldOrder];
      // remove from old position
      newFields.splice(sourceIdx, 1);
      // insert into new position
      newFields.splice(destinationIdx, 0, draggableId);
      setFieldValue('fieldOrder', newFields);
    }
  }, [allowReorder, fieldOrder, setFieldValue]);


  const reorderFieldChoice = useCallback((sourceIdx, destinationIdx, fieldId) => {
    const field = fields[fieldId];
    if (field) {
      const newChoices = [...field.choices];
      const choice = field.choices[sourceIdx];
      // remove from old position
      newChoices.splice(sourceIdx, 1);
      // insert into new position
      newChoices.splice(destinationIdx, 0, choice);
      setFieldValue(`fields.${fieldId}.choices`, newChoices);
    }
  }, [fields, setFieldValue]);


  const onDragEnd = useCallback((result) => {
    const {
      destination, source, draggableId, type,
    } = result;

    if (!destination) {
      return;
    }
    if (!isValid) {
      toast('Please fix the errored field.');
      return;
    }
    const didNotMove = destination.droppableId === source.droppableId
    && destination.index === source.index;
    if (didNotMove) {
      return;
    }
    if (type === FIELD_CHOICE) {
      reorderFieldChoice(source.index, destination.index, destination.droppableId);
    } else if (source.droppableId === QUESTION_LIST_DROPPABLE
      || source.droppableId === SPECIAL_LIST_DROPPABLE) {
      addField(draggableId, destination.index);
    } else if (source.droppableId === FORM_BODY_DROPPABLE) {
      reorderField(source.index, destination.index, draggableId);
    }
  }, [addField, reorderField, reorderFieldChoice, isValid]);

  return (
    <div>
      <div className="form-builder">
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <Sidebar
            disableSpecialField={
              !!fieldOrder.filter((id) => fields[id].input_type === SPECIAL).length
            }
          />
          <FormBody
            fields={fields}
            fieldOrder={fieldOrder}
            openField={openField}
            setOpenField={handleOpenField}
            removeDependency={removeDependency}
            removeDependencyChoice={removeDependencyChoice}
          />
        </DragDropContext>
      </div>
    </div>
  );
}


Form.propTypes = {
  form: PropTypes.object.isRequired,
  onValuesChange: PropTypes.func,
};

Form.defaultProps = {
  onValuesChange: () => null,
};

export default Form;
