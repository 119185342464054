import * as types from './types';

const initialState = {
  name: '',
  password_expires_after: -1,
  remaining_users: -1,
  users: {},
  sso_enabled: true,
  has_employee_sync_settings: false,
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_ORGANIZATION: {
      return { ...state, ...action.data };
    }
    case types.RECEIVE_USERS: {
      const users = action.data.reduce((allUsers, user) => {
        allUsers[user.id] = user;
        return allUsers;
      }, {});
      return {
        ...state,
        users,
      };
    }
    case types.RECEIVE_USER: {
      return { ...state,
        remaining_users: state.remaining_users - 1,
        users: { ...state.users, [action.data.id]: action.data },
      };
    }
    case types.EDIT_USER: {
      return { ...state,
        users: { ...state.users, [action.data.id]: action.data },
      };
    }
    case types.REMOVE_USER: {
      const newState = {
        ...state,
        remaining_users: state.remaining_users + 1,
      };
      delete newState.users[action.userId];
      return newState;
    }
    default: {
      return state;
    }
  }
};

export default organizationReducer;
