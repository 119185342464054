import * as types from './types';

const initState = {};

const forms = (state = initState, action) => {
  switch (action.type) {
    case types.LOAD_FORMS: {
      const formState = {};
      action.forms.reduce((_allForms, form) => {
        formState[form.id] = form;
        return formState;
      }, {});
      return formState;
    }
    default: {
      return state;
    }
  }
};

export default forms;
