import * as types from './types';

const initState = {
  exports: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.SET_LOADED: {
      return {
        ...state,
        exports: action.exports,
      };
    }
    case types.ADD_EXPORT: {
      const exportIndex = state.exports.findIndex(e => e.id === action.export.id);
      const exportCopy = [...state.exports];
      if (exportIndex >= 0) {
        exportCopy[exportIndex] = action.export;
        return {
          ...state,
          exports: exportCopy,
        };
      }
      return {
        ...state,
        exports: [...state.exports, action.export],
      };
    }
    case types.UPDATE_EXPORT_PROGRESS: {
      const targetExport = state.exports.find(e => e.id === action.id);
      if (!targetExport) {
        return state;
      }
      const nextExport = {
        ...targetExport,
        current: action.current,
        total: action.total,
        errored: action.errored,
        is_complete: action.ready,
      };
      const exportCopy = state.exports.slice(0);
      exportCopy[exportCopy.findIndex(e => e.id === action.id)] = nextExport;
      return {
        ...state,
        exports: exportCopy,
      };
    }
    case types.DELETE_EXPORT: {
      const exports = state.exports.filter(r => r.id !== action.id);
      return {
        ...state,
        exports,
      };
    }
    default: return state;
  }
};
