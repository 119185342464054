import React from 'react';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';


export const AmountFilter = () => (
  <>
    <span className="input-title">
      Filter
    </span>
    <div className="range-filter-wrapper">
      <div>
        <SchemaField
          id="filter.from"
          label=""
          fieldType="TextField"
          className="form-input"
          placeholder="0.00"
          autoFocus
        />
      </div>
      <span>
        to
      </span>
      <div>
        <SchemaField
          id="filter.to"
          label=""
          fieldType="TextField"
          className="form-input"
          placeholder="0.00"
        />
      </div>
    </div>
  </>
);
