import * as types from './types';

const defaultState = {
  editing: false,
  loading: false,
  errors: false,
  // date filter is only being used by search by contributor it is not ideal
  dateFilter: {
    to: null,
    from: null,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_EDITING:
      return {
        ...state,
        editing: action.edit,
      };
    case types.SET_SEARCH:
      return {
        ...state,
        loading: true,
        errors: false,
      };
    case types.SET_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.dateFilter,
      };
    case types.RECEIVE_RESEARCH_RESULTS:
      return {
        ...state,
        loading: false,
      };
    case types.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: false,
      };
    case types.CLEAR_RESULTS:
      return {
        ...state,
        loading: true,
      };
    case types.RECEIVE_ERRORS:
      return {
        ...state,
        errors: true,
        loading: false,
      };
    case types.RESET_STATE:
      return defaultState;
    default: return state;
  }
};
