import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import Select from 'react-select';
import { customStyles, DropdownIndicator } from 'components/shared/SchemaForm/components/SearchableSelectField';
import { Autofill } from 'components/shared/SchemaForm/utils/Autofill';


const SelectField = ({
  label, choices, autofill, fieldMap, disabled, noOptionsMessage, ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const selected = [...choices].find(
    choice => choice.id === field.value,
  );

  const handleSelection = (choice, { action }) => {
    switch (action) {
      case 'select-option': {
        helpers.setValue(choice.id);
        break;
      }
      default:
    }
  };

  return (
    <div className="form-field">
      <label htmlFor={field.name}>{label}</label>
      <Select
        classNamePrefix={'searchable'}
        options={choices}
        onChange={handleSelection}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        isSearchable={false}
        styles={customStyles}
        components={{ DropdownIndicator }}
        isDisabled={disabled}
        value={selected || { id: null, name: '--' }}
        captureMenuScroll={false}
        noOptionsMessage={noOptionsMessage}
        inputId={field.name}
      />
      {autofill && (
        <Autofill
          value={field.value}
          fieldMap={fieldMap}
          field={'id'}
        >
          {choices}
        </Autofill>
      )}
      <ErrorMessage
        name={field.name}
        component="div"
        className="error-text"
      />
    </div>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  autofill: PropTypes.bool,
  fieldMap: PropTypes.object,
  disabled: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
};

SelectField.defaultProps = {
  autofill: false,
  fieldMap: {},
  disabled: false,
  noOptionsMessage: () => ('No options'),
};

export { SelectField };
