import React from 'react';

import Forms from './CertificationForms/CertificationForms';
import EmailTemplates from './EmailTemplates';
import BodyWithSidebar from '../../shared/BodyWithSidebar';

const [FORMS, TEMPLATES] = ['FORMS', 'TEMPLATES'];
const TAB_MAP = {
  [FORMS]: 'Forms',
  [TEMPLATES]: 'Email Templates',
};
const BODY_MAP = {
  [FORMS]: Forms,
  [TEMPLATES]: EmailTemplates,
};

const Settings = () => (
  <BodyWithSidebar
    title="Settings"
    bodyMap={BODY_MAP}
    tabs={Object.keys(BODY_MAP)}
    tabMap={TAB_MAP}
    className="preclearance-settings"
  />
);

export { Settings };
