import React from 'react';
import PropTypes from 'prop-types';
import { BulkExportProgress } from './exportProgress';

const ProgressToast = ({ id, complete, hasError }) => {
  let text = '';
  if (complete && !hasError) {
    text = 'Export complete!';
  } else if (hasError) {
    text = 'An error occurred while exporting';
  } else {
    text = (
      <span>
        Export generating...
        <br />
        Depending on the size of the file, this can take several minutes.
      </span>
    );
  }
  return (
    <span className="progress-toast">
      <span>{ text }</span>
      <BulkExportProgress
        id={id}
        exportType={'export'}
        link={`/bulk_exports/${id}`}
      />
    </span>
  );
};

ProgressToast.propTypes = {
  id: PropTypes.number.isRequired,
  hasError: PropTypes.bool,
  complete: PropTypes.bool,
};

ProgressToast.defaultProps = {
  hasError: false,
  complete: false,
};

export default ProgressToast;

