import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CertificationSurveyForm } from 'components/Certifications/CertificationSurvey/CertificationSurveyForm';


const FormPreview = ({ payload, onClose }) => {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  return (
    <div>
      <div className="form-builder-header">
        <div className="flex-header">
          <button className="icon-text-button back-button uppercase" type="button" onClick={onClose}>
            <i className="material-icons">arrow_back</i>
            back to editing
          </button>
          <span>
            /
          </span>
          <span className="form-header-name uppercase">
            form preview
          </span>
        </div>
      </div>
      <div className="form-preview">
        <CertificationSurveyForm payload={payload} isPreview />
      </div>
    </div>
  );
};

FormPreview.propTypes = {
  payload: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { FormPreview };
