import { cloneDeep } from 'lodash';
import { filterBlanks } from 'utils/serializers/searchSerializer';
import {
  email,
  externalId,
  altFirsts,
  altLasts,
  altEmails,
  primaryAddress,
  addresses,
} from 'components/employeePage/employeeForm/consts';


function removeEmpty(obj) {
  return Object.entries(obj)
    .filter(([_key, value]) => {
      if (typeof value === 'string') {
        return value.trim().length > 0;
      }
      return value;
    })
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
}


export function serializeEmployee(values) {
  const employee = cloneDeep(values);
  const serialized = {
    ...employee,
    [externalId]: employee[externalId] || null,
    [email]: employee[email] || null,
    [altFirsts]: filterBlanks(employee[altFirsts]),
    [altLasts]: filterBlanks(employee[altLasts]),
    [altEmails]: filterBlanks(employee[altEmails]),
    [primaryAddress]: removeEmpty(employee[primaryAddress]),
    // remove objs that only have empty strings as values and remove primary address before sending
    [addresses]: employee[addresses].map(a => removeEmpty(a))
      .filter(obj => Object.keys(obj).length !== 0 && !obj.is_primary),
    custom_data: removeEmpty(employee.custom_data),
  };

  return serialized;
}
