import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../monitoringForm/sourceSelection/checkbox';

export default function TagItem({ tag, onClick, selected, disabled }) {
  return (
    <Checkbox
      checked={selected}
      onChange={onClick}
      id={tag}
      disabled={disabled}
    >
      <span>{tag}</span>
    </Checkbox>
  );
}

TagItem.propTypes = {
  tag: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

TagItem.defaultProps = {
  disabled: false,
};
