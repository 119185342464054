import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Prompt, useHistory } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { PATHS, PATH_NAMES } from 'utils/constants';
import Confirmation from 'components/settings/confirmationShared';
import IconTextButton from 'components/monitoringForm/iconTextButton';


function Header({
  formName, onPublish, isSubmitting, isLoading, onPreview, isValid, editName, isPublished,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState('');
  const [shouldNavigateAway, setShouldNavigateAway] = useState(false);
  const history = useHistory();

  const handleBlockedNavigation = (location) => {
    setNextLocation(location.pathname);
    setIsModalOpen(true);
    return shouldNavigateAway;
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalConfirm = () => {
    setShouldNavigateAway(true);
  };

  useEffect(() => {
    if (shouldNavigateAway) {
      history.push(nextLocation);
    }
  }, [history, nextLocation, shouldNavigateAway]);


  return (
    <div className="form-builder-header">
      <div className="flex-header">
        <Link
          className="back-button uppercase"
          to={PATHS[PATH_NAMES.certification_settings]}
        >
          <i className="material-icons">arrow_back</i>
          certification forms
        </Link>
        <span>
          /
        </span>
        <span className="form-header-name uppercase">
          {formName}
          <IconTextButton
            icon="edit"
            buttonHandler={editName}
            className="edit-button"
            buttonText=""
          />
        </span>
      </div>
      <div className="form-header-publish-group">
        <button disabled={!isValid} className="icon-text-button uppercase" type="button" onClick={onPreview}>
          <i className="material-icons">visibility</i>
          Preview
        </button>
        <div className="publish-status">
          <i
            className="material-icons publish-status-icon"
            style={{ cursor: 'default' }}
          >
            {isLoading ? 'cloud_sync' : 'cloud_done'}
          </i>
          <span>
            {isLoading ? 'Saving...' : ''}
            {!isPublished && !isLoading ? 'Autosaved with unpublished changes' : ''}
            {isPublished && !isLoading ? 'All changes have been published' : ''}
          </span>
        </div>
        <Tippy
          content="Please fix validation errors."
          disabled={isValid}
        >
          <div>
            <button
              className="orange-button"
              type="button"
              onClick={onPublish}
              disabled={isSubmitting || !isValid}
            >
              Publish
            </button>
          </div>
        </Tippy>

      </div>
      <Prompt when={!isPublished} message={handleBlockedNavigation} />
      {isModalOpen && (
      <Confirmation
        open={isModalOpen}
        confirmText="Leave with unpublished changes"
        close={handleModalClose}
        onSubmit={handleModalConfirm}
        dismissText="Go back"
        maxWidth="500px"
      >
        <h2>Unpublished Changes</h2>
        <div className="message">
          This form has been saved with unpublished changes. For these changes to go live you will need to click "Publish" in the form editor, are you sure you want to leave?
        </div>
      </Confirmation>
      )}
    </div>
  );
}

Header.propTypes = {
  formName: PropTypes.string.isRequired,
  onPublish: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  editName: PropTypes.func.isRequired,
  isPublished: PropTypes.bool.isRequired,
};

export default Header;
