import React from 'react';
import PropTypes from 'prop-types';

export default function Section({ header, subHeader, helpText, containerClass, children, loading }) {
  if (loading) return null;
  return (
    <div className={`settings ${containerClass}`}>
      {header && <h1>{header}</h1>}
      <p className="subheader">{subHeader}</p>
      {helpText && <div className="help-text">{helpText}</div>}
      {children}
    </div>
  );
}

Section.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  helpText: PropTypes.object,
  containerClass: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

Section.defaultProps = {
  containerClass: '',
  header: '',
  subHeader: '',
  helpText: null,
  loading: false,
};
