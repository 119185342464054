import React from 'react';
import PropTypes from 'prop-types';
import { CELL, ROW_HEIGHT } from './constants';


const LoadingCell = ({ width }) => (
  <div
    className={`${CELL} loading-cell`}
    style={{ width }}
  >
    <div className="loading-placeholder" />
  </div>
);

LoadingCell.propTypes = {
  width: PropTypes.number.isRequired,
};


const LoadingRow = ({ className, columns }) => (
  <div
    className={`preclearance-row ${className}`}
    style={{ height: ROW_HEIGHT }}
  >
    {columns.map((column, idx) => (
      <LoadingCell
        key={`cell-${idx}-${column.id}`}
        width={column.width}
      />
    ))}
  </div>
);

LoadingRow.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
};

LoadingRow.defaultProps = {
  className: '',
};

export default LoadingRow;
