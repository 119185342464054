import * as types from './types';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.RECEIVE_RESEARCH_RESULTS:
      return Object.values(action.data).reduce((allSourceResults, sourceResult) => {
        allSourceResults[sourceResult.id] = sourceResult;
        return allSourceResults;
      }, {});
    case types.RECEIVE_SOURCE_RESULTS: {
      const stateCopy = { ...state };
      stateCopy[action.data.id] = action.data;
      return stateCopy;
    }
    case types.SELECT_TAB:
      return defaultState;
    case types.RESET_STATE:
    case types.CLEAR_RESULTS:
      return defaultState;
    default: return state;
  }
};
