import React from 'react';
import PropTypes from 'prop-types';
import ActionSentence from './actionSentence';
import { BaseItem, BaseEditItem } from './baseItem';
import ActionOptions from './actionOptions';


export const ActionEditItem = ({
  action,
  statuses,
  templates,
  buttonText,
  onSubmit,
  onClose,
  onFieldChange,
  errors,
  autoActions,
}) => {
  const isInvalid = Object.values(autoActions)
    .some(autoAction => (
      autoAction.id !== action.id &&
      autoAction.status === action.status &&
      autoAction.template === action.template
    ));
  return (
    <BaseEditItem
      buttonText={buttonText}
      onSubmit={onSubmit}
      onClose={onClose}
      disabled={isInvalid}
      errors={errors}
      tooltip={isInvalid ? 'This action already exists.' : ''}
    >
      <ActionSentence
        status={(
          <ActionOptions
            options={Object.values(statuses)}
            dropdownText={statuses[action.status] ? statuses[action.status].label : 'Select status'}
            onDropdownSelect={(o) => { onFieldChange(o.id, 'status'); }}
            field={'label'}
            disabled={false}
          />
        )}
        template={(
          <ActionOptions
            options={Object.values(templates)}
            dropdownText={templates[action.template] ? templates[action.template].name : 'Select template'}
            onDropdownSelect={(o) => { onFieldChange(o.id, 'template'); }}
            field={'name'}
            disabled={false}
          />
        )}
      />
    </BaseEditItem>
  );
};

ActionEditItem.propTypes = {
  action: PropTypes.object.isRequired,
  statuses: PropTypes.object.isRequired,
  templates: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  autoActions: PropTypes.object.isRequired,
  errors: PropTypes.string,
};

ActionEditItem.defaultProps = {
  errors: '',
};


export const ActionItem = ({
  statuses,
  templates,
  action,
  toggleEditing,
  onToggleActive,
  onDelete,
  disabled,
  disabledMessage,
}) => {
  const { active, status, id, template } = action;
  const missingStatus = !statuses[status] ? 'status' : null;
  const missingTemplate = !templates[template] ? 'template' : null;
  const missingItem = missingStatus || missingTemplate;
  const isDisabled = Boolean(missingItem) || disabled;
  const disabledTooltip = disabled ? disabledMessage : `The ${missingItem} associated with this action has been removed, please select a new ${missingItem} to reenable.`;

  return (
    <BaseItem
      tooltip={isDisabled ? disabledTooltip : ''}
      active={active}
      disabled={isDisabled}
      onToggleActive={() => { onToggleActive(action); }}
      onToggleEdit={() => { toggleEditing(action); }}
      onDelete={() => { onDelete(id); }}
    >
      <ActionSentence
        status={statuses[status] ? statuses[status].label : null}
        template={templates[template] ? templates[template].name : null}
      />
    </BaseItem>
  );
};


ActionItem.propTypes = {
  statuses: PropTypes.object.isRequired,
  templates: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  onToggleActive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
};

ActionItem.defaultProps = {
  disabled: false,
  disabledMessage: '',
};
