import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import useCancellablePromise from 'hooks/useCancellablePromise';
import Loading from 'components/loading';
import { filterBlanks } from 'utils/serializers/searchSerializer';
import { getAutoEmail, sendAutoEmail } from 'reducers/preclearance/autoEmailActions';
import ActionConfirmation from './actionConfirmation';
import CustomizeEmailModal from './customizeEmailModal';
import {
  to,
  ccEmails,
  subject,
  body,
} from './consts';
import { CUSTOMIZE_EMAIL_MODAL, ACTION_CONFIRMATION } from '../table/constants';
import { handleInvalidEmailError } from '../table/helpers/utils';


const modalContents = {
  [ACTION_CONFIRMATION]: ActionConfirmation,
  [CUSTOMIZE_EMAIL_MODAL]: CustomizeEmailModal,
};


const EmailActionModal = ({
  statuses,
  templates,
  row,
  modalType,
  action,
  openModal,
  closeModal,
  fromEmail,
}) => {
  const [loading, setLoading] = useState(true);
  const [emailBody, setEmailBody] = useState('');
  const template = templates[action.template];
  const { cancellablePromise } = useCancellablePromise();


  useEffect(() => {
    let didCancel = false;
    setLoading(true);
    getAutoEmail(action.template, row.id)
      .then(({ data }) => {
        if (!didCancel) {
          setEmailBody(data);
        }
      })
      .catch((err) => {
        toast('Something went wrong.');
        throw err;
      }).finally(() => {
        if (!didCancel) {
          setLoading(false);
        }
      });
    return () => {
      didCancel = true;
    };
  }, [action.id, action.template, row.id]);


  const onSendEmail = (values, actions) => {
    const emailPayload = {
      contribution_id: row.id,
      from_email: fromEmail,
      [ccEmails]: filterBlanks(values[ccEmails]),
      ...values,
    };
    cancellablePromise(sendAutoEmail(template.id, emailPayload))
      .then(() => {
        toast(`"${template.name}" has been sent.`);
        actions.setSubmitting(false);
        closeModal();
      })
      .catch((err) => {
        if (!err.isCanceled) {
          toast(handleInvalidEmailError(err) || `There was a problem sending "${template.name}".`);
          actions.setSubmitting(false);
          actions.setErrors(err);
          throw err;
        }
      });
  };


  const rowData = row.data;
  const statusId = rowData.status;
  const emailData = {
    [to]: rowData.email || '',
    [subject]: template.subject,
    [body]: emailBody,
    [ccEmails]: template.cc_emails.length ? [...template.cc_emails] : [''],
  };

  const ModalContents = modalContents[modalType];
  return (
    loading
      ? (
        <div
          className="flex-load-wrapper"
          style={{ minHeight: '300px' }}
        >
          <Loading />
        </div>
      )
      : (
        <ModalContents
          rowData={rowData}
          initFormData={emailData}
          status={statuses[statusId].label}
          templateName={template.name}
          closeModal={closeModal}
          openModal={openModal}
          onSubmit={onSendEmail}
          key={action.id}
        />
      )
  );
};

const mapStateToProps = ({ preclearance }) => {
  const row = preclearance.ui.selectedRow;
  return {
    modalType: preclearance.ui.modalType,
    statuses: preclearance.statuses,
    templates: preclearance.templates,
    fromEmail: preclearance.table.email,
    row,
  };
};

export default connect(mapStateToProps, {
})(EmailActionModal);

EmailActionModal.propTypes = {
  row: PropTypes.object,
  templates: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  statuses: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  fromEmail: PropTypes.string.isRequired,
};

EmailActionModal.defaultProps = {
  row: {},
};
