import React from 'react';
import PropTypes from 'prop-types';
import { CustomDropdown } from '../dropdown';
import { FilterOption } from './statusDropdown';


const CategoryFilters = ({ options, filter, setFilter }) => (
  <CustomDropdown
    render={({
      isOpen, openDropdown, closeDropdown,
    }) => (
      <div
        className="filter-dropdown dropdown"
      >
        <div className="drop-wrapper">
          <button
            onClick={isOpen ? closeDropdown : openDropdown}
            className="dropdownButton text-button uppercase"
            type="button"
          >
            <div className="dropdown-label">
              { filter ? (
                <>
                  <span className="lighter-text">Category: </span>
                  <span>{options[filter].name}</span>
                </>
              ) : (
                <span className="lighter-text">
                  filter by category
                </span>
              )}
            </div>
            <i className="material-icons">
              {isOpen ? 'expand_less' : 'expand_more'}
            </i>
          </button>
        </div>
        {
            isOpen && (
              <div className="dropdown-container">
                <div className="options-wrapper statuses">
                  <div>
                    <FilterOption
                      label="All"
                      checked={filter === null}
                      onClick={() => setFilter(null)}
                    />
                    { Object.values(options).map(({ name, id }) => (
                      <FilterOption
                        key={id}
                        label={name}
                        checked={filter === id}
                        onClick={() => setFilter(id)}
                      />
                    ))}
                  </div>
                </div>
              </div>

            )
          }
      </div>
    )}
  />
);


CategoryFilters.propTypes = {
  options: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.number,
};

CategoryFilters.defaultProps = {
  filter: null,
};

export default CategoryFilters;
