import { connect } from 'react-redux';
import {
  createColumn,
  updateColumn,
  deleteColumn,
  createRow,
  updateRow,
  deleteRow,
  filter,
  updateOrd,
  fetchColumns,
} from 'reducers/preclearance/actions';
import { selectRow, openModal as openPreModal } from 'reducers/preclearance/ui/actions';
import { openModal } from 'reducers/ui/actions';
import Table from './table';
import { FIXED_LEFT_COLUMN_IDS } from './constants';
import { hasPreclearancePermissions } from '../../../helpers/permissions';

const mapStateToProps = (state) => {
  const { jurisdictions, preclearance: { columns, rows, statuses } } = state;
  const canEditColumns = state.user.is_manager;
  return {
    jurisdictions,
    columns,
    rows,
    statuses,
    leftColumnIds: FIXED_LEFT_COLUMN_IDS,
    canEditColumns,
    showPreclearanceSettings: hasPreclearancePermissions(state.user),
    showAutomatches: state.user.show_automatches,
    modalOpen: Boolean(state.preclearance.ui.modalType),
  };
};

export default connect(mapStateToProps,
  {
    createColumn,
    updateColumn,
    deleteColumn,
    createRow,
    updateRow,
    deleteRow,
    filter,
    updateOrd,
    fetchColumns,
    openModal,
    openPreModal,
    selectRow,
  })(Table);
