import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import useCancellablePromise from 'hooks/useCancellablePromise';
import ContributorSearchForm from './contributorSearchForm';
import HeaderForm from './headerSearchForm';
import {
  validationSchema,
} from './consts';


export const makeCountHeader = (text, count) => (
  <span>{text}{count > 0 && <span className="count">{count}</span>}</span>
);


function ContributorSearchFormWrapper({
  sources,
  initForm,
  createSearch,
  remainingResearches,
  edit,
  inHeader,
  toggleEditing,
}) {
  const { cancellablePromise } = useCancellablePromise();
  const limitReached = remainingResearches <= 0;
  const FormComponent = inHeader ? HeaderForm : ContributorSearchForm;

  function handleSubmit(values, actions) {
    const sourcesLength = Array.from(values.sources).length;
    if (sourcesLength < 1) {
      toast('Please select at least one source.');
      actions.setSubmitting(false);
      return;
    }
    actions.setSubmitting(true);
    const searchData = {
      ...values,
      all_sources: sourcesLength === sources.length,
    };
    cancellablePromise(createSearch(searchData)).then(() => {
      actions.setSubmitting(false);
      actions.resetForm({
        values: { ...initForm },
      });
      toggleEditing(false);
    }).catch((err) => {
      if (!err.isCanceled) {
        actions.setSubmitting(false);
        const errors = err && err.response && err.response.data;
        let errorMessage = 'Something went wrong.';
        if (Array.isArray(errors)) {
          errorMessage = errors[0];
        } else if (errors) {
          errorMessage = 'Some fields had errors';
          actions.setErrors(errors);
        }
        toast(errorMessage);
        throw err;
      }
    });
  }

  return (
    <Formik
      initialValues={Object.assign({}, initForm)}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values, resetForm }) => (
        <FormComponent
          sources={sources}
          limitReached={limitReached}
          isSubmitting={isSubmitting}
          values={values}
          edit={edit}
          resetForm={() => {
            resetForm({
              values: { ...initForm },
            });
            toggleEditing();
          }}
        />
      )}
    </Formik>
  );
}

ContributorSearchFormWrapper.propTypes = {
  sources: PropTypes.array.isRequired,
  initForm: PropTypes.object.isRequired,
  createSearch: PropTypes.func.isRequired,
  inHeader: PropTypes.bool.isRequired,
  remainingResearches: PropTypes.number.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

export default ContributorSearchFormWrapper;
