import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isBeforeDay } from '../../helpers/date_helpers';
import ExportList from './exportList';
import DatePickerWrapper from './datePickerWrapper';

class ExportForm extends Component {
  constructor(props) {
    super(props);

    const date = this.props.globalDateFilter ? moment(this.props.globalDateFilter) : null;
    this.state = {
      selectedemployees: [],
      selectedentities: [],
      unselectedentities: [...this.props.entities],
      unselectedemployees: [...this.props.employees],
      error: '',
      errors: {},
      startDate: date,
      endDate: null,
      exporting: false,
    };
    this.onDatesChange = this.onDatesChange.bind(this);
    this.toggleSearchObject = this.toggleSearchObject.bind(this);
    // this.isOutsideDateFilter = this.isOutsideDateFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onDatesChange({ endDate, startDate }) {
    let newErrors = {};
    if (endDate) {
      newErrors = { ...newErrors, endDate: false };
    }
    if (startDate) {
      newErrors = { ...newErrors, startDate: false };
    }
    this.setState({ errors: { ...this.state.errors, ...newErrors } });
    this.setState({ endDate, startDate });
  }

  toggleSearchObject(search, type, action) {
    // search --> search object
    // type --> employees or entities
    // action --> add or remove
    const newErrors = action === 'add' ? { selected: null } : {};

    // if toggling all send null for search and null for type
    if (!search && !type) {
      const nextState = action === 'add' ? ({
        selectedemployees: this.props.employees,
        selectedentities: this.props.entities,
        unselectedentities: [],
        unselectedemployees: [],
        errors: { ...this.state.errors, ...newErrors },
      }) : ({
        selectedemployees: [],
        selectedentities: [],
        unselectedentities: this.props.entities,
        unselectedemployees: this.props.employees,
      });
      this.setState({ ...this.state, ...nextState });
      return;
    } else if (!search) {
      const selectedField = `selected${type}`;
      const unselectedField = `unselected${type}`;
      const nextState = action === 'add' ? ({
        [selectedField]: this.props[type],
        [unselectedField]: [],
        errors: { ...this.state.errors, ...newErrors },
      }) : ({
        [selectedField]: [],
        [unselectedField]: this.props[type],
      });
      this.setState({ ...this.state, ...nextState });
      return;
    }
    const selectedField = `selected${type}`;
    const unselectedField = `unselected${type}`;

    if (action === 'add') {
      this.setState(prevState => ({
        [unselectedField]: prevState[unselectedField].filter(s => s.search_object_id !== search.search_object_id),
        [selectedField]: [...this.state[selectedField], search],
        errors: { ...this.state.errors, ...newErrors },
      }));
    } else {
      this.setState(prevState => ({
        [selectedField]: prevState[selectedField].filter(s => s.search_object_id !== search.search_object_id),
        [unselectedField]: [...this.state[unselectedField], search],
      }));
    }
  }

  invalidDate(dateInput) {
    return (dateInput && dateInput.value && !(moment(dateInput.value, 'MM/DD/YYYY', true).isValid()));
  }


  handleSubmit() {
    if (this.state.selectedemployees.length === 0 && this.state.selectedentities.length === 0) {
      this.setState({ errors: { selected: 'Please select at least one person or company to export' } });
      return;
    }
    let { endDate, startDate } = this.state;

    // this is weird but the datepicker does not always set the date even if there is a value in it since it expects in a certain format but that is not always clear to the user so here we check if the user put anything in the input and try to validate it if they did and tell them the correct format
    const startDateInput = document.getElementById('startDate');
    const endDateInput = document.getElementById('endDate');
    const dateError = 'Please enter a valid date in the form of mm/dd/yyyy or leave blank for any date';
    const { globalDateFilter } = this.props;
    if (this.invalidDate(startDateInput)) {
      this.setState({ errors: { ...this.state.errors, startDate: dateError } });
      return;
    } else if (this.invalidDate(endDateInput)) {
      this.setState({ errors: { ...this.state.errors, endDate: dateError } });
      return;
    }

    if ((globalDateFilter && !startDate) || (globalDateFilter && isBeforeDay(startDate, moment(globalDateFilter)))) {
      this.setState({ errors: { ...this.state.errors, startDate: 'Date must be after global date filter date.' } });
      return;
    }

    endDate = endDate ? moment(endDate, 'llll').format('YYYY-MM-DD') : null;
    startDate = startDate ? moment(startDate, 'llll').format('YYYY-MM-DD') : null;

    this.setState({ exporting: true });

    const employeeIds = this.state.selectedemployees.map(e => e.search_object_id);
    const entityIds = this.state.selectedentities.map(e => e.search_object_id);
    const ids = employeeIds.concat(entityIds);

    this.props.createExport({
      date_from: startDate,
      date_to: endDate,
      search_objects: ids,
    }).then((record) => {
      this.props.onNewReportGeneration(record.id);
      const date = this.props.globalDateFilter ? moment(this.props.globalDateFilter) : null;
      this.setState({
        error: '',
        endDate: null,
        startDate: date,
        selectedemployees: [],
        selectedentities: [],
        unselectedentities: [...this.props.entities],
        unselectedemployees: [...this.props.employees],
        errors: {},
        exporting: false,
      });
    }).catch((err) => {
      this.setState({
        error: 'We\'re sorry, something went wrong creating this export.',
        exporting: false,
      });
      throw err;
    });
  }

  // isOutsideDateFilter(day) {
  //   if (this.props.globalDateFilter) {
  //     return isBeforeDay(day, moment(this.props.globalDateFilter));
  //   }
  //   return false;
  // }

  render() {
    const { selectedemployees, selectedentities, unselectedemployees, unselectedentities } = this.state;
    const noSearches = this.props.employees.length === 0 && this.props.entities.length === 0;

    return (
      <div>
        <div className="form-info">
          This will export an Excel spreadsheet containing records within the selected date range for the selected monitored people and companies.
        </div>
        <p className="subheader">Select Monitored Searches</p>
        <div className="help-text">
          Select which monitored people or companies to include in the export.
        </div>
        <div className="flex-wrapper">
          <ExportList
            employees={unselectedemployees}
            entities={unselectedentities}
            listType={'available'}
            toggleSearchObject={this.toggleSearchObject}
            error={this.state.errors.available || ''}
            noSearches={noSearches}
          />
          <ExportList
            employees={selectedemployees}
            entities={selectedentities}
            listType={'selected'}
            toggleSearchObject={this.toggleSearchObject}
            error={this.state.errors.selected || ''}
            noSearches={noSearches}
          />
        </div>
        <p className="subheader">Select Record Dates</p>
        <div className="help-text">
          Optionally filter out records before or after a specified date.
        </div>

        <DatePickerWrapper
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          startDateLabel={'From'}
          endDateLabel={'To'}
          startDatePlaceholder={'Any'}
          endDatePlaceholder={'Any'}
          errors={this.state.errors}
          onDatesChange={this.onDatesChange}
          // isOutsideDateFilter={this.isOutsideDateFilter}
        />

        <div className="text-center">
          <button
            onClick={this.handleSubmit}
            className="btn btn-primary"
            disabled={this.state.exporting}
          >
            {this.state.exporting ? (
              <span>
                Exporting
                <span className="loader__dot">.</span>
                <span className="loader__dot">.</span>
                <span className="loader__dot">.</span>
              </span>
            ) : 'Export'}
          </button>
          <div className="error-text">
            { this.state.error }
          </div>
        </div>

      </div>
    );
  }
}


ExportForm.propTypes = {
  employees: PropTypes.array.isRequired,
  entities: PropTypes.array.isRequired,
  createExport: PropTypes.func.isRequired,
  onNewReportGeneration: PropTypes.func.isRequired,
  globalDateFilter: PropTypes.string,
};


ExportForm.defaultProps = {
  globalDateFilter: null,
};

export default ExportForm;
