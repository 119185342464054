import React from 'react';
import PropTypes from 'prop-types';


const PlatformName = ({
  name,
  iconUrl,
}) => (
  <div className="platform-name-wrapper">
    <img
      src={iconUrl}
      alt={`${name} logo`}
      width="30"
      height="30"
    />
    <span className="platform-name">
      { name }
    </span>
  </div>
);


PlatformName.propTypes = {
  name: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
};

export default PlatformName;
