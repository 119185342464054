import { MultiRequestCanceler, RequestTrackerCanceler } from 'utils/apiUtil';
import * as types from './types';
import api from '../../api';

const loadResults = (id, data) => ({
  id,
  results: data,
  type: types.LOAD_RESULTS,
});

const loadJurisdictionResults = (id, data) => ({
  id,
  results: data,
  type: types.LOAD_JURISDICTION_RESULTS,
});

const loadIndividualResult = (id, resultId, data) => ({
  id,
  result_id: resultId,
  data,
  type: types.LOAD_INDIVIDUAL_RESULT,
});


export const clearResults = () => ({
  type: types.CLEAR_RESULTS,
});

export const removeSearchObjResults = id => ({
  id,
  type: types.REMOVE_SEARCH_OBJ_RESULTS,
});

export const toggleJurisdictionLoading = loading => ({
  type: types.TOGGLE_JURISDICTION_LOADING,
  loading,
});


export const fetchJurisdictionResults = (id, searchType, jurId) => (dispatch, getState) => {
  const { ui } = getState();
  const { dateFilter } = ui;
  const args = { ...dateFilter };
  args.jurisdiction = jurId;

  const queryString = Object.keys(args).filter(k => !!args[k]).map(k => `${k}=${args[k]}`).join('&');

  const url = `/${searchType}/${id}/last/?${queryString}`;
  return api.get(url, { cancelToken: MultiRequestCanceler.newCancel() })
    .then(({ data }) => {
      dispatch(loadJurisdictionResults(id, data));
      return data;
    });
};


export const fetchResults = (id, searchType) => (dispatch, getState) => {
  const { ui } = getState();
  const { dateFilter } = ui;
  const args = { ...dateFilter };

  const queryString = Object.keys(args).filter(k => !!args[k]).map(k => `${k}=${args[k]}`).join('&');

  const url = `/${searchType}/${id}/last/?${queryString}`;
  return api.get(url, {
    cancelToken: RequestTrackerCanceler.getCancelToken(id),
  })
    .then(({ data }) => {
      if (data.length > 0) {
        dispatch(loadResults(id, data));
      }
      return data;
    });
};


// DO NOT ADD / AFTER URL IT WILL BREAK THE CODE!!!
export const fetchResult = (id, resultId, searchType) => (dispatch, getState) => {
  const { ui } = getState();
  const { dateFilter } = ui;

  const args = { ...dateFilter };
  args.id = resultId;

  const queryString = Object.keys(args).filter(k => !!args[k]).map(k => `${k}=${args[k]}`).join('&');
  const url = `/${searchType}/${id}/last/?${queryString}`;

  return api.get(url, {
    cancelToken: RequestTrackerCanceler.getCancelToken(id),
  }).then(({ data }) => {
    if (data.completed) {
      dispatch(loadIndividualResult(id, resultId, data));
    }
    return data;
  });
};
