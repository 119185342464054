import React from 'react';
import PropTypes from 'prop-types';

const Cell = ({ renderer, children }) => <td>{renderer(children)}</td>;

Cell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  renderer: PropTypes.func,
};

Cell.defaultProps = {
  renderer: value => value,
};

export { Cell };
