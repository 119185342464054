import * as types from './types';
import { RECEIVE_EMPLOYEES } from 'reducers/employees/types';


const byId = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EMPLOYEES: {
      const nextState = { ...state };
      action.employees.forEach((employee) => {
        employee.search_objects.forEach((searchObj) => {
          const id = searchObj.search_object_id;
          nextState[id] = {
            ...nextState[id],
            id,
            ...searchObj,
          };
        });
      });
      return nextState;
    }
    case types.RECEIVE_SEARCH:
    case types.EDIT_SEARCH: {
      const { search } = action;
      return {
        ...state,
        [search.id]: {
          ...search,
        },
      };
    }
    case types.RECEIVE_SEARCH_OBJS: {
      const nextState = { ...state };
      action.searches.forEach((search) => {
        nextState[search.id] = search;
      });
      return nextState;
    }
    case types.ADD_SEARCH: {
      return {
        ...state,
        [action.search.id]: action.search,
      };
    }
    case types.REMOVE_SEARCH: {
      const nextState = { ...state };
      delete nextState[action.id];
      return nextState;
    }
    default: return state;
  }
};

export default byId;

export const getSearch = (state, id) => state[id];
