import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createPreNotification, editPreNotification, removePreNotification } from 'reducers/preclearance/notifications/actions';
import Section from 'components/shared/settingsPage/section';
import SettingsTable from 'components/shared/settingsTable';
import OpenItemWrapper from 'components/shared/openItemWrapper';
import EditItem from 'components/shared/editItem';
import OnOffToggle from 'components/shared/onOffToggle';
import NotificationItem from '../emailItems/notificationItem';
import { handleInvalidEmailError } from '../../../table/helpers/utils';

const onSubmitCatch = err => (
  handleInvalidEmailError(err) || 'There was a problem adding this email address'
);

function Notifications({
  notifications,
  createPreNotification,
  editPreNotification,
  removePreNotification
}) {
  // TO DO - Disable deleting default email associated with account
  return (
    <Section
      conainterClass={'notifications'}
      header={'Manage Questionnaire Notifications'}
      helpText={
        <p>
          Each time an employee fills out a preclearance request form, a notification will be
          sent to the following email addresses that have notifications enabled.
        </p>
      }
    >
      <div>
        <OpenItemWrapper
          findErrors={({ email }) => {
            if (!email) return 'Please enter a valid email address.';
            if (Object.values(notifications).map(n => n.email).indexOf(email) >= 0) {
              return 'This email already exists.';
            }
          }}
          createFunc={createPreNotification}
          updateFunc={editPreNotification}
          deleteFunc={removePreNotification}
          errorCb={onSubmitCatch}
          entityName={'email'}
          render={({ openField, toggleEditing, onFieldChange, onDelete, errors, onToggle, onCreate }) => (
            <div>
              {Object.values(notifications).length > 0 ?
                <SettingsTable
                  leftColumn={'Email'}
                  rightColumns={['Notifications', 'Delete']}
                >
                  {
                    Object.values(notifications).map(notification => (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        onToggleActive={() => onToggle(notification, 'active')}
                        onDelete={onDelete(notification.id)}
                      />
                    ),
                    )
                  }
                </SettingsTable>
                : ''}
              <div className="table-wrapper">
                <div className="table-container adding">
                  {openField && !openField.id ?
                    <EditItem
                      value={openField.email}
                      buttonText={'Add'}
                      showDelete={false}
                      onFieldChange={(e) => { onFieldChange(e.target.value, 'email'); }}
                      onClose={() => { toggleEditing(null); }}
                      errors={errors}
                      onSubmit={onCreate}
                      confirmationShowing={false}
                      extraChildren={(
                        <div className="text-center table-cell">
                          <span>
                            <OnOffToggle
                              active={openField.active}
                              onToggle={() => { onFieldChange(!openField.active, 'active'); }}
                            />
                          </span>
                        </div>
                      )}
                    />
                    :
                    <span className="button-wrapper">
                      <button
                        onClick={() => { toggleEditing({ email: '', active: true }); }}
                        className="text-button uppercase add-field-button"
                        disabled={false}
                      // disabled={Object.values(this.props.notifications).length >= this.notificationsLimit}
                      >
                        + Add Another Email
                      </button>
                      {/*  TO DO figure out what limit is */}
                      {/* <Tooltip>There is a limit of {this.notificationsLimit} notification emails.</Tooltip> */}
                    </span>
                  }
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </Section>
  );
}


const mapStateToProps = state => ({
  notifications: state.preclearance.notifications,
});

export default connect(
  mapStateToProps,
  {
    createPreNotification,
    editPreNotification,
    removePreNotification,
  })(Notifications);

Notifications.propTypes = {
  notifications: PropTypes.object.isRequired,
  createPreNotification: PropTypes.func.isRequired,
  editPreNotification: PropTypes.func.isRequired,
  removePreNotification: PropTypes.func.isRequired,
};
