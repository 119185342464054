import React from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';

import { FORM_ELEMENT } from '../constants';

const TextField = ({ label, element, ...props }) => {
  const [field] = useField(props);

  let rows;
  if (element === 'textarea') {
    rows = 5;
  }

  return <Field as={element} rows={rows} {...field} {...props} />;
};

TextField.propTypes = {
  ...FORM_ELEMENT,
  element: PropTypes.oneOf(['input', 'textarea']),
};

TextField.defaultProps = {
  element: 'input',
};

export { TextField };
