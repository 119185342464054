import * as types from './types';
import api from '../../api';

const receiveRows = rows => ({
  type: types.RECEIVE_ROWS,
  rows,
});

const receiveRow = row => ({
  type: types.RECEIVE_ROW,
  row,
});

const removeRow = rowId => ({
  type: types.REMOVE_ROW,
  rowId,
});

const receiveColumns = columns => ({
  type: types.RECEIVE_COLUMNS,
  columns,
});

const receiveColumn = column => ({
  type: types.RECEIVE_COLUMN,
  column,
});

const removeColumn = columnId => ({
  type: types.REMOVE_COLUMN,
  columnId,
});

export const createRow = rowData => (dispatch) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(rowData.data));
  rowData.files.forEach(file => formData.append('files', file));
  return api.post('/custom/rows/', formData)
    .then(({ data: row }) => dispatch(receiveRow(row)));
};

// this function can be used to update entire row at once or a single cell value in that row
export const updateRow = (rowId, rowData) => (dispatch) => {
  return api.patch(`/custom/rows/${rowId}/`, rowData)
    .then(({ data: row }) => dispatch(receiveRow(row)));
};

export const deleteRow = rowId => (dispatch) => {
  return api.delete(`custom/rows/${rowId}/`)
    .then(() => dispatch(removeRow(rowId)));
};

export const fetchColumns = () => (dispatch) => {
  return api.get('/custom/columns/').then(({ data }) => dispatch(receiveColumns(data)));
};

export const createColumn = colData => (dispatch) => {
  return api.post('/custom/columns/', colData)
    .then(({ data: column }) => dispatch(receiveColumn(column)))
};

export const updateColumn = (colId, colData) => (dispatch) => {
  return api.patch(`/custom/columns/${colId}/`, colData)
    .then(({ data: column }) => dispatch(receiveColumn(column)));
};

export const updateOrd = ordArray => () => {
  return api.put('custom/columns/order/', ordArray);
};

export const deleteColumn = colId => (dispatch) => {
  return api.delete(`/custom/columns/${colId}/`)
    .then(() => dispatch(removeColumn(colId)));
};

export const filter = qString => (dispatch) => {
  return api.get(`/custom/rows/?${qString}`).then(({ data: rows }) => {
    return dispatch(receiveRows(rows));
  });
};

export const sendEmail = email => api.post('/pre/send_email/', email);
