import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import pluralize from 'pluralize';
import { FieldArray, useField } from 'formik';
import Tippy from '@tippyjs/react';
import { SchemaField } from '../SchemaField';
import { FORM_ELEMENT } from '../constants';

const ObjectArrayField = ({
  defaultNewObj, elemMap, addButtonText, limit, cols, dataPrivate, ...props
}) => {
  const [field] = useField(props);
  const limitReached = field.value.length >= limit;

  return (
    <FieldArray
      {...field}
      render={({ remove, push }) => (
        <div>
          {field.value.map((_val, index) => (
            <div key={index}>
              <div className="multi-field-header">
                <button
                  type="button"
                  className="close text-button"
                  onClick={() => remove(index)}
                >
                  <i className="material-icons">close</i>
                </button>
                <div className="multi-field-label">
                  { addButtonText }
                </div>
              </div>
              {
                _.chunk(Object.keys(elemMap), cols).map((col, i) => (
                  <div className="row" key={`row-${i}`}>
                    {col.map(key => (
                      <div className="col" key={`${key}-${index}`}>
                        <SchemaField
                          {...elemMap[key]}
                          name={`${field.name}.${index}.${key}`}
                          id={`${field.name}.${index}.${key}`}
                          className={'form-input'}
                          data-private={dataPrivate}
                        />
                      </div>
                    ))}
                  </div>
                ))
              }

            </div>
          ))}
          <Tippy
            content={`There is a limit of ${limit} ${pluralize.plural(addButtonText)}`}
            disabled={!limitReached}
          >
            <div className="button-wrapper">
              <button
                type="button"
                className="text-button uppercase add-field-button"
                onClick={() => push(Object.assign({}, defaultNewObj))}
                disabled={limitReached}
              >
              + Add {addButtonText}
              </button>
            </div>
          </Tippy>
        </div>
      )}
    />
  );
};

ObjectArrayField.propTypes = {
  ...FORM_ELEMENT,
  defaultNewObj: PropTypes.object.isRequired, // nested obj field to init val
  elemMap: PropTypes.object.isRequired, // map of nested field to field props
  addButtonText: PropTypes.string,
  cols: PropTypes.number,
  dataPrivate: PropTypes.bool, // exclude data from logRocket
};

ObjectArrayField.defaultProps = {
  addButtonText: '',
  cols: 1,
  dataPrivate: false,
};

export { ObjectArrayField };
