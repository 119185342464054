import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS, PATH_NAMES } from 'utils/constants';

const NoTags = () => (
  <div className="no-tags">
    <span>No tags yet.</span>
    <div>
      <Link to={`${PATHS[PATH_NAMES.platform_settings]}`}> Visit the Settings page to add tags!</Link>
    </div>
  </div>
);

export default NoTags;
