import { DEFAULT_COL_WIDTH } from 'components/preclearance/table/constants';
import { SELECTION_COL, SELECTION_COL_WIDTH } from '../consts';

export const getInitialCellWidth = (colId, totalWidth, numCols) => {
  const min = (totalWidth - SELECTION_COL_WIDTH) / (numCols - 1);
  if (colId === SELECTION_COL.id) {
    return SELECTION_COL_WIDTH;
  }
  return min > DEFAULT_COL_WIDTH ? Math.round(min) : DEFAULT_COL_WIDTH;
};

