/* eslint-disable eqeqeq */
import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';

// dependentFieldMap is a map of dependee_id to list of depender ids
// state = {
//   dependee: dependers
//   dependent_field_id: [...depender_ids]
// }
// ex. only show Q2 (dependee) if Q1 (depender) is 'yes' (Q2 is dependent on Q1)
// dependentFieldMap = {
//   1: [2]
// }

// using == because when reorder happens field id in fieldOrder can be a string instead of number
const useDependentFields = () => {
  const [dependentFieldMap, setDependentMap] = useState({});
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const deps = values.fieldOrder.reduce((fieldMap, fieldId) => {
      const field = values.fields[fieldId];
      if (field.dependencies.length) {
        field.dependencies.forEach((dependency) => {
          fieldMap[dependency.prerequisite_field] = [
            ...(fieldMap[dependency.prerequisite_field] || []),
            field.id,
          ];
        });
      }
      return fieldMap;
    }, {});
    setDependentMap(deps);
  }, [values.fields, values.fieldOrder]);


  // used when field type changes from radio to non radio or when field is removed
  const removeDependency = (fieldId) => {
    const dependers = dependentFieldMap[fieldId] || [];
    dependers.forEach((depId) => {
      const nextDependencies = values.fields[depId].dependencies.filter((dep) => (
        dep.prerequisite_field != fieldId
      ));
      setFieldValue(`fields.${depId}.dependencies`, nextDependencies);
    });
  };

  // if a choice value for a dependent is deleted and it was the only choice then remove dep
  // otherwise remove it from the prerequisite_values but keep the other deps
  const removeDependencyChoice = (fieldId, choiceIndex) => {
    const dependers = dependentFieldMap[fieldId] || [];
    const dependee = values.fields[fieldId];
    const removedChoice = dependee.choices[choiceIndex].value;
    dependers.forEach((depId) => {
      const depender = values.fields[depId];
      const nextDependencies = depender.dependencies.reduce((acc, dep) => {
        let nextVals = dep.prerequisite_values;
        if (dep.prerequisite_field == fieldId) {
          nextVals = dep.prerequisite_values.filter((val) => (val !== removedChoice));
        }
        return nextVals.length ? [...acc, { ...dep, prerequisite_values: nextVals }] : [...acc];
      }, []);
      setFieldValue(`fields.${depId}.dependencies`, nextDependencies);
    });
  };

  const allowReorder = (fieldId, destinationIdx) => {
    const { fieldOrder, fields } = values;
    const field = fields[fieldId];
    const { dependencies } = field;
    const dependers = dependentFieldMap[fieldId] || [];
    if (dependencies.length) {
      // if new position is before (<=) the position of field it is dependent on, then disallow move
      const dependentFieldPositions = dependencies.map((dep) => fieldOrder
        .findIndex((id) => id == dep.prerequisite_field));
      const preventMove = dependentFieldPositions.filter((index) => destinationIdx <= index).length;
      if (preventMove) {
        toast('This field must come after the field it is dependent on');
        return false;
      }
    }
    if (dependers.length) {
      // if new position is after (>=) the position of fields that depend on it, then disallow move
      const dependerFieldPositions = dependers.map((depender) => fieldOrder
        .findIndex((id) => (id == depender)));
      const preventMove = dependerFieldPositions.filter((index) => destinationIdx >= index).length;
      if (preventMove) {
        toast('This field must come before the fields that depend on it');
        return false;
      }
    }
    return true;
  };


  return {
    removeDependency,
    removeDependencyChoice,
    allowReorder,
  };
};

export { useDependentFields };
