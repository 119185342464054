import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RequestTrackerCanceler } from 'utils/apiUtil';
import _ from 'lodash';
import { DropdownOption } from 'components/shared/objectDropdown';


// todo repeating a lot of dropdown logic from components/dropdown.js b/c we need to clear the filter when clicking away, try to find a way to share this logic. Figure out a way to simplify, not using onBlur b/c clicking scrollbar in IE caused the input to lose focus

class SearchableDropdown extends Component {
  constructor(props) {
    super(props);
    const { value, getValue } = props;
    const initValue = getValue(value);
    this.state = {
      search: initValue || '', // search query
      selectedOption: initValue || '', // actually selected option
      options: [],
      expanded: false,
    };
    this.debounce = _.debounce(this.debounce, 500);
    this.onSearch = this.onSearch.bind(this);
    this.selectOption = this.selectOption.bind(this);

    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this.state.search && this.onSearch(this.state.search);
  }

  componentWillUnmount() {
    this.debounce.cancel();
    RequestTrackerCanceler.cancelByRequestId(this.props.cancelToken);
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('mousedown', this.onMouseDown);
  }

  onSearch(search) {
    this.props.searchFunc(search)
      .then(({ data }) => {
        this.setState({ options: data });
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  // prevent the dropdown from closing if click originated inside input of dropdown
  onMouseDown(e) {
    if (this.dropdownEl.contains(e.target)) {
      this.stayOpen = true;
    } else {
      this.stayOpen = false;
    }
  }

  onKeyDown(e) {
    if (e.key === 'Tab') {
      this.closeDropdown();
    }
  }

  openDropdown() {
    this.setState({ expanded: true });
    document.addEventListener('click', this.handleOutsideClick, false);
    document.addEventListener('mousedown', this.onMouseDown);
  }

  closeDropdown() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({ expanded: false, search: this.state.selectedOption });
  }

  handleOutsideClick(e) {
    if (
      (this.dropdownEl && this.dropdownEl.contains(e.target)) ||
            this.stayOpen
    ) {
      return;
    }
    this.closeDropdown();
  }

  handleChange(e) {
    this.setState({ search: e.target.value }, () => {
      const { search } = this.state;
      if (search.trim().length) {
        this.debounce(search);
      }
    });
  }

  debounce(search) {
    this.onSearch(search);
  }

  selectOption(value) {
    this.props.onSelect(value);
    const displayVal = this.props.getValue(value);
    this.setState(
      {
        search: displayVal,
        selectedOption: displayVal,
      },
      () => {
        this.closeDropdown();
      },
    );
  }

  render() {
    const { search, expanded } = this.state;
    const { refCb, className, id, createCustomOption, allowBlank } = this.props;
    const options = this.state.options;
    const showCustomOption = allowBlank || (!allowBlank && search.trim().length);
    return (
      <div
        ref={(el) => {
          this.dropdownEl = el;
        }}
        className={`searchable-dropdown preclearance-dropdown ${className} ${
          expanded ? 'open' : ''
        }`}
        id={id}
      >
        <div className="dropdown-value">
          <input
            ref={refCb}
            value={search}
            onKeyDown={(e) => {
              this.onKeyDown(e);
            }}
            onChange={(e) => {
              this.handleChange(e);
              if (!expanded && e.target.value) {
                this.openDropdown(e);
              }
            }}
            onFocus={(e) => {
              if (options.length || showCustomOption) {
                this.openDropdown(e);
              }
            }}
          />
        </div>
        {expanded ? (
          <div className={'dropdown-options dropdown-container'}>
            {this.props.renderOptions(options, this.selectOption)}
            { showCustomOption ? (
              <DropdownOption
                value={search}
                onClick={() => {
                  const customOption = createCustomOption(search);
                  this.selectOption(customOption);
                }}
              >
                Add {`"${search}"`}
              </DropdownOption>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

SearchableDropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  onSelect: PropTypes.func.isRequired,
  refCb: PropTypes.func,
  className: PropTypes.string,
  searchFunc: PropTypes.func.isRequired,
  renderOptions: PropTypes.func.isRequired,
  id: PropTypes.string,
  getValue: PropTypes.func,
  createCustomOption: PropTypes.func,
  allowBlank: PropTypes.bool,
  cancelToken: PropTypes.string.isRequired,
};

SearchableDropdown.defaultProps = {
  refCb: () => {},
  className: '',
  id: null,
  getValue: value => value,
  createCustomOption: value => value,
  allowBlank: false,
};

export default SearchableDropdown;
