import moment from 'moment';

// Taken from airbnb react dates src/utils
export const isBeforeDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
};

export const isSameDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date()
    && a.month() === b.month()
    && a.year() === b.year();
};

export const isAfterDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b) && !isSameDay(a, b);
};

export const isInclusivelyBeforeDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isAfterDay(a, b);
};

export const isInclusivelyAfterDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
};


export const invalidDate = dateInput => (dateInput && dateInput.value && !(moment(dateInput.value, 'MM/DD/YYYY', true).isValid()));

export const acceptableDateFormats = ['MM/DD/YYYY', 'MM/DD/YY', 'MM/D/YY', 'MM/D/YYYY', 'M/D/YY', 'M/DD/YYYY', 'M/DD/YY', 'M/D/YYYY'];

export const validateDate = (value) => {
  const date = moment(value, acceptableDateFormats, true);
  return date.isValid();
};
