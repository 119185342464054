/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RequestTrackerCanceler } from 'utils/apiUtil';
import Sidebar from 'components/sidebar/sidebar';
import Body from 'containers/body';
import TriangleShadow from 'components/triangleShadow';
import Loading from 'components/loading';
import ErrorBoundary from 'components/errorBoundary';
import { getEmployees } from 'reducers/employees/selectors';
import { fetchEmployees } from 'reducers/employees/actions';
import { getEntities } from 'reducers/entities/selectors';
import { fetchEntities } from 'reducers/entities/actions';
import { clearResults } from 'reducers/results/actions';
import { toggleContribution, resetUI, closeModal } from 'reducers/ui/actions';
import WorkFlowUI from 'components/workFlowUI';
import Modal from 'components/modal/modal';
import { SEARCH_TYPE_MAP, EMPLOYEES } from 'utils/constants';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.loadInitialData();
  }

  componentDidUpdate(prevProps) {
    const { selected, uiSearchType } = this.props;
    const selectedMismatch = (!selected && prevProps.selected) || (selected && !prevProps.selected) || ((selected && prevProps.selected) && (selected.id !== prevProps.selected.id));
    const searchTypeMismatch = uiSearchType !== prevProps.uiSearchType;
    if (selectedMismatch || searchTypeMismatch) {
      this.props.closeModal();
      this.props.toggleContribution(this.props.selectedContribs, false);
    }
  }

  componentWillUnmount() {
    RequestTrackerCanceler.cancelAllRequests();
    this.props.resetUI();
    this.props.clearResults();
  }


  loadInitialData() {
    Promise.all([
      this.props.fetchEmployees(),
      this.props.fetchEntities(),
    ])
      .then(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }
    const { limitReached, searchType, employeeSyncEnabled } = this.props;
    const disableAdd = limitReached
      || (searchType === EMPLOYEES && employeeSyncEnabled);

    return (
      <ErrorBoundary>
        <ScrollToTopOnMount />
        <WorkFlowUI>
          <div className="sidebar-page-container">
            <Sidebar
              selected={this.props.selected}
              searchType={searchType}
              uiSearchType={this.props.uiSearchType}
              employees={this.props.employees}
              entities={this.props.entities}
              batchEditMode={this.props.batchEditMode}
              fetchEmployees={this.props.fetchEmployees}
              dateFilter={this.props.dateFilter}
              employeeSyncEnabled={employeeSyncEnabled}
              disableAdd={disableAdd}
            />
            <Body
              dateFilter={this.props.dateFilter}
              selected={this.props.selected}
              searchType={searchType}
              uiSearchType={this.props.uiSearchType}
              disableAdd={disableAdd}
              employees={this.props.employees}
              entities={this.props.entities}
            />
          </div>
        </WorkFlowUI>
        <Modal />
        <TriangleShadow />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { match: { params } } = props;
  const searchType = SEARCH_TYPE_MAP[params.searchType];
  const selected = state[searchType].byId[params.id] || null;
  const limit = state.user[`remaining_${searchType}`];

  return {
    employees: getEmployees(state),
    entities: getEntities(state),
    selected,
    searchType, // employees / entities
    uiSearchType: params.searchType, // people / companies
    dateFilter: state.ui.dateFilter,
    selectedContribs: state.ui.selectedContribs,
    limitReached: limit <= 0,
    employeeSyncEnabled: state.organization.has_employee_sync_settings,
    batchEditMode: state.ui.selectedContribs.size > 0,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchEmployees,
    fetchEntities,
    clearResults,
    toggleContribution,
    resetUI,
    closeModal,
  },
)(MainPage);

MainPage.propTypes = {
  fetchEmployees: PropTypes.func.isRequired,
  fetchEntities: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  entities: PropTypes.array.isRequired,
  clearResults: PropTypes.func.isRequired,
  resetUI: PropTypes.func.isRequired,
  searchType: PropTypes.string.isRequired,
  uiSearchType: PropTypes.string.isRequired,
  dateFilter: PropTypes.object.isRequired,
  selected: PropTypes.object,
  toggleContribution: PropTypes.func.isRequired,
  selectedContribs: PropTypes.object.isRequired,
  limitReached: PropTypes.bool.isRequired,
  batchEditMode: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  employeeSyncEnabled: PropTypes.bool.isRequired,
};

MainPage.defaultProps = {
  selected: {},
};
