import React from 'react';
import PropTypes from 'prop-types';

export const TextValue = ({ value }) => (
  <span>
    { value }
  </span>
);

TextValue.propTypes = {
  value: PropTypes.string.isRequired,
};
