import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const RecordDateItem = ({ date }) => (
  <div className="note-action-item">
    <div className="comment-body">
      Record detected on <strong>{ moment(date).format('LL') }</strong>.
    </div>
  </div>
);


RecordDateItem.propTypes = {
  date: PropTypes.string.isRequired,
};

const CommentItem = ({ commenter, date, comment }) => (
  <div className="note-action-item">
    <div className="comment-header">
      <span className="commenter">{commenter}</span>
      <span>{date}</span>
    </div>
    <div className="comment-body">
      {comment}
    </div>
  </div>
);


CommentItem.propTypes = {
  commenter: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
};

const ShareItem = ({ username, date, recipients }) => (
  <div className="note-action-item share">
    <i className="icon-send" />
    <span>{username} sent this record to {recipients.join(', ')} on {date}.</span>
  </div>
);

ShareItem.propTypes = {
  username: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  recipients: PropTypes.array.isRequired,
};

const StatusItem = ({ username, date, oldStatus, newStatus }) => (
  <div className="note-action-item share">
    <i className="material-icons">label</i>
    <span>{username} changed status from {`"${oldStatus}"`} to {`"${newStatus}"`} on {date}</span>
  </div>
);

StatusItem.propTypes = {
  username: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  oldStatus: PropTypes.string.isRequired,
  newStatus: PropTypes.string.isRequired,
};


export const [STATUS, COMMENT, SHARE] = ['status', 'comment', 'share'];

const ActionsLog = ({ actions, firstSeen }) => (
  <div className="actions-wrapper">
    {
      actions.sort((a, b) => moment(b.created).diff(moment(a.created))).map((action, i) => {
        const { user, created } = action;
        const fullname = user ? `${user.first_name} ${user.last_name}` : '';
        const username = !user || fullname.trim().length > 0 ? fullname : user.email;
        const actionItem = action.action;
        const date = moment(created).format('LL');
        return (
          <div key={i}>
            {actionItem[SHARE] ?
              (<ShareItem
                username={username}
                date={date}
                recipients={actionItem[SHARE].to}
              />) : ''}
            { actionItem[COMMENT] ?
              <CommentItem
                commenter={username}
                date={date}
                comment={actionItem[COMMENT]}
              /> : '' }
            { actionItem[STATUS] ?
              <StatusItem
                username={username}
                date={date}
                oldStatus={actionItem[STATUS].old}
                newStatus={actionItem[STATUS].new}
              /> : '' }
          </div>
        );
      })
    }
    { firstSeen ?
      <RecordDateItem
        date={firstSeen}
      /> : ''}
  </div>
);

ActionsLog.propTypes = {
  actions: PropTypes.array.isRequired,
  firstSeen: PropTypes.string,
};

ActionsLog.defaultProps = {
  firstSeen: null,
};

export default ActionsLog;
