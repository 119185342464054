import { v4 as uuidv4 } from 'uuid';

export const formFields = {
  title: 'title',
  header: 'header',
  footer: 'footer',
  fields: 'fields',
  isActive: 'is_active',
};

export const {
  title,
  header,
  footer,
  fields,
  isActive,
} = formFields;


export const blankForm = {
  [title]: '',
  [header]: '',
  [footer]: '',
  [isActive]: false,
  [fields]: [],
};


export const defaultQuestion = {
  label: 'Question',
  is_required: false,
  is_hidden: false,
  dependencies: [],
  choices: [],
};

export const defaultChoice = () => ({
  label: '',
  value: uuidv4(),
  id: uuidv4(),
});
