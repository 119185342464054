import React from 'react';
import PropTypes from 'prop-types';


export const CheckboxField = ({
  name,
  checked,
  onCheck,
}) => (
  <label
    htmlFor={name}
    className="preclearance-checkbox"
  >
    <input
      name={name}
      onChange={(e) => onCheck(e.target.checked)}
      type="checkbox"
      checked={checked}
    />
    <div className="checkmark" />
  </label>
);


CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
};
