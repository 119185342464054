import * as types from './types';
import api from '../../api';

const setCategories = data => ({
  categories: data,
  type: types.SET_CATEGORIES,
});

export const loadCategories = () => dispatch => api.get('/categories/')
  .then(({ data }) => {
    dispatch(setCategories(data));
    return data;
  });

