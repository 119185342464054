import React from 'react';
import PropTypes from 'prop-types';

export default function TagItem({ tagName, onRemove, removable }) {
  return (
    <div className="tag-item">
      <span>{tagName}</span>
      {removable && <i onClick={onRemove} className="material-icons">close</i>}
    </div>
  );
}

TagItem.propTypes = {
  tagName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  removable: PropTypes.bool.isRequired,
};

TagItem.defaultProps = {
  onRemove: () => {},
};
