import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, DropdownOption } from 'components/shared/objectDropdown';


class Dropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.onEscKey = this.onEscKey.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('keydown', this.onEscKey);
  }

  onEscKey(e) {
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.closeDropdown();
    }
  }

  openDropdown(e) {
    e.preventDefault();
    this.setState({ expanded: true });
    document.addEventListener('click', this.handleOutsideClick, false);
    document.addEventListener('keydown', this.onEscKey);
  }

  closeDropdown() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({ expanded: false, filter: '' });
  }


  handleOutsideClick(e) {
    if (this.dropdownEl && this.dropdownEl.contains(e.target)) {
      return;
    }
    this.closeDropdown();
  }

  render() {
    if (this.props.render) {
      return this.props.render({
        isOpen: this.state.expanded,
        openDropdown: this.openDropdown,
        closeDropdown: this.closeDropdown,
        dropdownRef: (el) => { this.dropdownEl = el; },
      });
    }
    return (
      <div className="dropdown">
        <DropdownButton
          disabled={this.props.disabled}
          dropdownText={this.props.dropdownText}
          isOpen={this.state.expanded}
          onClick={this.openDropdown}
        />
        { this.state.expanded ?
          <div
            className="dropdown-container"
            ref={(el) => { this.dropdownEl = el; }}
            style={{ height: this.props.height }}
          >
            { this.props.options.map((option, i) => (
              <DropdownOption
                key={i}
                value={option}
                onClick={() => {
                  this.props.handleDropdownSelection(option);
                  this.closeDropdown();
                }}
                selected={this.props.selected && option.toLowerCase() === this.props.selected.toLowerCase()}
              >
                {option}
              </DropdownOption>
            )) } </div> : ''
        }
      </div>
    );
  }
}

// adding filler props to appease propTypes
export const CustomDropdown = ({ render, dropdownRef }) => (
  <Dropdown
    render={render}
    dropdownText=""
    options={[]}
    handleDropdownSelection={() => {}}
    selected=""
    dropdownRef={dropdownRef}
  />);

CustomDropdown.propTypes = {
  render: PropTypes.func.isRequired,
};

Dropdown.propTypes = {
  dropdownText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  options: PropTypes.array.isRequired,
  handleDropdownSelection: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  height: PropTypes.string,
  disabled: PropTypes.bool,
  render: PropTypes.func,
};

Dropdown.defaultProps = {
  selected: null,
  height: 'auto',
  disabled: false,
  render: null,
};

export default Dropdown;
