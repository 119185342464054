import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import TemplateConfirmation from 'components/preclearance/settings/changeConfirmation';
import useCancellablePromise from 'hooks/useCancellablePromise';

import EmailTemplateForm from './emailTemplateForm';
import Portal from '../portal';


export default function EmailTemplateItem({
  editing,
  template,
  macros,
  macroLinks,
  onSave,
  onDelete,
  toggleOpen,
  macroLinkHelpText,
  showSubmissionConfirmation,
  renderRelatedData,
}) {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const { cancellablePromise } = useCancellablePromise();

  const handleDelete = () => {
    cancellablePromise(onDelete(template.id))
      .then(() => {
        toggleOpen(null);
        setDeleteConfirmation(false);
      }).catch((err) => {
        if (!err.isCanceled) {
          toast('There was a problem deleting this template');
          throw err;
        }
      });
  };


  return (
    <li className="action-item">
      <div className="action-wrapper">
        <span>{template.name}</span>
        {
          editing
            ? (
              <IconTextButton
                className="edit-button"
                buttonHandler={() => setDeleteConfirmation(true)}
                icon="delete"
                buttonText="delete"
              />
            )
            : (
              <IconTextButton
                className="edit-button"
                buttonHandler={() => toggleOpen({ ...template })}
                icon="edit"
                buttonText="edit"
              />
            )
        }
      </div>
      {
        (editing)
          ? (
            <EmailTemplateForm
              template={template}
              macros={macros}
              macroLinks={macroLinks}
              onSave={onSave}
              toggleOpen={toggleOpen}
              macroLinkHelpText={macroLinkHelpText}
              renderRelatedData={renderRelatedData}
              showSubmissionConfirmation={showSubmissionConfirmation}
            />
          )
          : null
      }
      {deleteConfirmation
        ? (
          <Portal>
            <div className="settings">
              <TemplateConfirmation
                onSubmit={handleDelete}
                onCancel={() => { setDeleteConfirmation(false); }}
                confirmText="delete"
                headerText="Delete Template"
                confirmSentence={(
                  <span>
                    Are you sure you would like to delete this template?
                  </span>
            )}
              >
                { renderRelatedData(template.id, true) }
              </TemplateConfirmation>
            </div>
          </Portal>
        )
        : null}
    </li>
  );
}

EmailTemplateItem.propTypes = {
  editing: PropTypes.bool,
  template: PropTypes.object.isRequired,
  macros: PropTypes.array.isRequired,
  macroLinks: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  toggleOpen: PropTypes.func.isRequired,
  macroLinkHelpText: PropTypes.string,
  renderRelatedData: PropTypes.func,
  showSubmissionConfirmation: PropTypes.bool.isRequired,
};

EmailTemplateItem.defaultProps = {
  editing: false,
  onDelete: () => {},
  macroLinks: [],
  macroLinkHelpText: '',
  renderRelatedData: () => {},
};
