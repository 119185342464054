import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ManualReports from 'components/reports/manualReports';
import AutoReports from 'components/reports/autoReports';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import Loading from 'components/loading';
import ErrorBoundary from 'components/errorBoundary';
import {
  loadReports,
  loadScheduledReports,
  createAutoReports,
  updateAutoReports,
  createManualReport,
  getUpdatedCounts,
  removeReport,
} from 'reducers/reports/actions';
import PastExports from 'components/bulkExports/pastExports';
import ReportGenerationModal from 'components/reports/generationModal';


class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      expanded: false,
    };

    this.timers = {};

    this.togglePastReports = this.togglePastReports.bind(this);
    this.startPoll = this.startPoll.bind(this);
    this.onNewReportGeneration = this.onNewReportGeneration.bind(this);
  }

  componentDidMount() {
    this._mounted = true;
    this.props.loadReports();
    this.props.loadScheduledReports();
  }

  componentWillReceiveProps(nextProps) {
    this.startPoll(nextProps.reports);
  }

  componentWillUnmount() {
    this._mounted = false;
    Object.values(this.timers).forEach((timer) => {
      clearTimeout(timer);
    });
  }


  onNewReportGeneration(id) {
    this.setState({
      currentTarget: id,
    });

    this.modal.open();
    if (!this.timers[id]) {
      this.timers[id] = this.individualPoll(id);
    }
  }

  individualPoll(id) {
    if (!this._mounted) {
      return;
    }
    clearTimeout(this.timers[id]);
    return setTimeout(_ => {
      this.props.getUpdatedCounts(id).then((data) => {
        if (data.file_report) {
          this.timers[id] = null;
        }
        if (!data.ready) {
          if (!data.errored) {
            this.timers[id] = this.individualPoll(id);
          }
        }
      });
    }, 3000);
  }

  startPoll(reports) {
    reports.forEach((r) => {
      if (!this.timers[r.id] && !r.is_complete && !r.errored) {
        this.timers[r.id] = this.individualPoll(r.id);
      } else if (r.file_report || r.errored) {
        this.timers[r.id] = null;
      }
    });
  }

  togglePastReports() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    if (!this.props.loaded || !this.props.scheduledLoaded) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }

    const currentReport = this.props.reports ? this.props.reports.find(r => r.id === this.state.currentTarget) : null;

    return (
      <div id="reports" className="container">
        <ErrorBoundary>
          <ScrollToTopOnMount />
          <div className="panel">
            <ManualReports
              createManualReport={this.props.createManualReport}
              onNewReportGeneration={this.onNewReportGeneration}
            />
            <hr />
            <AutoReports
              userEmail={this.props.user.username}
              createAutoReports={this.props.createAutoReports}
              updateAutoReports={this.props.updateAutoReports}
              scheduledReports={this.props.scheduledReports}
            />
            <hr />
            <button
              className="past-reports-header"
              onClick={this.togglePastReports}
            >
              <h1>Past Reports</h1>
              { this.state.expanded ?
                <i className="material-icons">expand_less</i> :
                <i className="material-icons">expand_more</i>
              }
            </button>
            { this.state.expanded ?
              <div>
                <PastExports
                  exportType={'report'}
                  exports={this.props.reports}
                  createManualReport={this.props.createManualReport}
                  onNewReportGeneration={this.onNewReportGeneration}
                  removeReport={this.props.removeReport}
                  exportUrl={'reports'}
                />
              </div>
              : ''}
            <hr />
          </div>
          <ReportGenerationModal
            ref={(el) => { this.modal = el; }}
            report={currentReport}
            createManualReport={this.props.createManualReport}
            onNewReportGeneration={this.onNewReportGeneration}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

Reports.propTypes = {
  user: PropTypes.object.isRequired,
  reports: PropTypes.array.isRequired,
  scheduledReports: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  scheduledLoaded: PropTypes.bool.isRequired,
  loadReports: PropTypes.func.isRequired,
  loadScheduledReports: PropTypes.func.isRequired,
  createAutoReports: PropTypes.func.isRequired,
  updateAutoReports: PropTypes.func.isRequired,
  createManualReport: PropTypes.func.isRequired,
  removeReport: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  reports: state.reports.reports,
  scheduledReports: state.reports.scheduledReports,
  loaded: state.reports.loaded,
  scheduledLoaded: state.reports.scheduledLoaded,
});

export default connect(
  mapStateToProps,
  {
    loadReports,
    loadScheduledReports,
    createAutoReports,
    updateAutoReports,
    createManualReport,
    getUpdatedCounts,
    removeReport,
  },
)(Reports);
