import { connect } from 'react-redux';
import PreclearanceBody from 'components/preclearance/preclearanceBody';
import { fetchPreclearanceSettings } from 'reducers/preclearance/settingsActions';
import { openModal, closeModal as closePreModal, selectRow } from '../reducers/preclearance/ui/actions';
import { closeModal } from '../reducers/ui/actions';

const mapStateToProps = (state) => {
  const { preclearance: { statuses, templates, autoActions } } = state;
  return {
    statuses,
    templates,
    autoActions,
  };
};

export default connect(mapStateToProps,
  {
    fetchPreclearanceSettings,
    openModal,
    closeModal,
    closePreModal,
    selectRow,
  })(PreclearanceBody);
