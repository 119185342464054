import React from 'react';
import PropTypes from 'prop-types';
import EditQuestion from './EditQuestion';
import { FIELD_TYPES } from '../../consts';
import SchemaQuestion from './SchemaQuestion';
import ConditionalHeading from './ConditionalHeading';


const EditableQuestion = ({
  field,
  index,
  removeField,
  inEditMode,
  removeDependency,
  removeDependencyChoice,
}) => {
  const { id, input_type: inputType, dependencies } = field;

  return (
    inEditMode
      ? (
        <EditQuestion
          field={field}
          index={index}
          removeField={removeField}
          removeDependency={removeDependency}
          removeDependencyChoice={removeDependencyChoice}
        />
      )
      : (
        <div>
          {
            dependencies.length
              ? (
                <ConditionalHeading
                  dependencies={dependencies}
                />
              )
              : null
            }
          <SchemaQuestion
            id={id}
            label={field.label}
            inputType={inputType}
            placeholder={FIELD_TYPES[inputType].label}
            required={field.is_required}
            disabled
            choices={field.choices}
          />
        </div>
      )
  );
};

EditableQuestion.propTypes = {
  field: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  removeField: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  removeDependency: PropTypes.func.isRequired,
  removeDependencyChoice: PropTypes.func.isRequired,
};

export default EditableQuestion;
