import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditItem from 'components/shared/editItem';


const AlertEmailForm = ({ submitting, onSubmit, onClose }) => {
  const [emailVal, setEmailVal] = useState('');
  const [error, setError] = useState('');

  const addEmail = () => {
    if (emailVal.indexOf('@') === -1 || !emailVal.trim().length) {
      setError('Please enter a valid email address');
      return;
    }
    onSubmit(emailVal).then(() => {
    }).catch((err) => {
      const { data, status } = err.response;
      if (status) {
        let errorMessage = data;
        if (typeof data === 'object') {
          if (data.alert_emails) {
            errorMessage = Object.values(data.alert_emails).join('');
          }
        }
        setError(errorMessage);
      }
      throw err;
    });
  };

  return (
    <EditItem
      value={emailVal}
      buttonText="Add"
      showDelete={false}
      onFieldChange={(e) => {
        setEmailVal(e.target.value);
        if (e.target.value.trim().length > 0) {
          setError('');
        }
      }}
      onClose={onClose}
      onSubmit={addEmail}
      disableSubmit={submitting}
      errors={error}
    />
  );
};


AlertEmailForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AlertEmailForm;
