import React from 'react';
import PropTypes from 'prop-types';


const TextAreaField = ({ placeholder, disabled }) => (
  <textarea
    type="text"
    placeholder={placeholder}
    className="preview-input"
    disabled={disabled}
    rows={3}
    style={{
      resize: 'none',
    }}
  />
);

TextAreaField.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

TextAreaField.defaultProps = {
  placeholder: '',
  disabled: false,
};

export { TextAreaField };
