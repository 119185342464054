import React from 'react';
import PropTypes from 'prop-types';


// // either bounded by two brackets or reach end of string
// const leftBound = (str, idx) => {
//   if (idx === 0) return false;
//   if (str[idx] === '{' && str[idx - 1] === '{') return true;
//   return leftBound(str, idx - 1);
// };

// const rightBound = (str, idx) => {
//   if (idx === str.length) return false;
//   if (str[idx] === '}' && str[idx - 1] === '}') return true;
//   return rightBound(str, idx + 1);
// };


// either bounded by two brackets or reach end of string
const leftBound = (str, idx) => {
  if (idx === 0) return false;
  if (str[idx - 1] === '}' && str[idx - 2] === '}') return false;
  if (str[idx] === '{' && str[idx - 1] === '{') return true;
  return leftBound(str, idx - 1);
};

const rightBound = (str, idx) => {
  if (idx === str.length) return false;
  if (str[idx] === '{' && str[idx + 1] === '{') return false;
  if (str[idx] === '}' && str[idx - 1] === '}') return true;
  return rightBound(str, idx + 1);
};

const startsToRight = (str, idx) => (str[idx] === '{' && str[idx + 1] === '{');
const startsToLeft = (str, idx) => (str[idx - 1] === '}' && str[idx - 2] === '}');

// assuming a start from open brackets
const rightEnd = (str, idx) => {
  if (idx === str.length) return null;
  if (str[idx] === '}' && str[idx - 1] === '}') return idx + 1;
  return rightEnd(str, idx + 1);
};

const leftEnd = (str, idx) => {
  if (idx === 0) return null;
  if (str[idx - 1] === '{' && str[idx - 2] === '{') return idx - 2;
  return leftEnd(str, idx - 1);
};

// detect if idx of string falls within a macro
const insideMacro = (str, idx) => (
  leftBound(str, idx) && rightBound(str, idx)
);

// TODO: fix shift + left, shift + right, shift + left
const onKeyDown = (e) => {
  // console.log('----------------selection start and end at keydown:', e.target.selectionStart, e.target.selectionEnd);
  switch (e.keyCode) {
    case 39: // right
    // console.log(e.target.selectionDirection);
      if (startsToRight(e.target.value, e.target.selectionEnd)) {
        if (e.shiftKey) {
        // e.preventDefault();
          e.target.selectionEnd = rightEnd(e.target.value, e.target.selectionEnd) - 1;
        } else {
          e.target.selectionEnd = rightEnd(e.target.value, e.target.selectionEnd);
        }
      } else if (startsToRight(e.target.value, e.target.selectionStart) && e.shiftKey && e.target.selectionDirection === 'backward' && e.target.selectionStart !== e.target.selectionEnd) {
        // console.log('doing thing here,')
        e.target.selectionStart = rightEnd(e.target.value, e.target.selectionStart) - 1;
        // console.log('e.targetstart, end:', e.target.selectionStart, e.target.selectionEnd);
      }
      break;
    case 37: // left
    // console.log(e.target.selectionDirection);
      if (startsToLeft(e.target.value, e.target.selectionStart)) {
        const leftSelectionEnd = leftEnd(e.target.value, e.target.selectionStart) + 1;
        if (e.shiftKey) {
          // e.preventDefault();
          // console.log('this one; start, end:', e.target.selectionStart, e.target.selectionEnd);
          e.target.selectionStart = leftSelectionEnd;
          // console.log('changed start; end?', e.target.selectionEnd);
        } else {
          e.target.selectionEnd = leftSelectionEnd;
        }
      } else if (startsToLeft(e.target.value, e.target.selectionEnd) && e.shiftKey && e.target.selectionDirection === 'forward') { // if shiftkey, and going left, and ends left, and macro currently highlighted (aka selectstart < selectEnd), aka unhighlight last highlighted macro
        e.target.selectionEnd = leftEnd(e.target.value, e.target.selectionEnd) + 1;
      }
      break;
    case 8:
      if (startsToLeft(e.target.value, e.target.selectionEnd)) {
        const { selectionStart, selectionEnd } = e.target;
        if (selectionStart === selectionEnd) {
          e.target.selectionStart = leftEnd(e.target.value, selectionEnd - 1);
        }
      } else if (insideMacro(e.target.value, e.target.selectionStart) || insideMacro(e.target.value, e.target.selectionEnd)) {
        e.preventDefault();
      }
      break;
    default:
      if (insideMacro(e.target.value, e.target.selectionStart) || insideMacro(e.target.value, e.target.selectionEnd)) {
        e.preventDefault();
        break;
      }
      break;
  }
};

const onKeyUp = (e) => {
  // console.log('---selection start and end at keyup:', e.target.selectionStart, e.target.selectionEnd);

  switch (e.keyCode) {
    case 38:
    case 40:
      if (insideMacro(e.target.value, e.target.selectionEnd)) {
        // console.log('this??')
        const leftBound = leftEnd(e.target.value, e.target.selectionEnd);
        const rightBound = rightEnd(e.target.value, e.target.selectionEnd);
        const rightDist = (rightBound - e.target.selectionEnd);
        const leftDist = (e.target.selectionEnd - leftBound);
        const cursorPos = leftDist <= rightDist ? leftBound : rightBound;
        e.target.selectionEnd = cursorPos;
        e.target.selectionStart = cursorPos;
      }
      break;
    default:
      break;
  }
};

const onMouseUp = (e) => {
  e.persist();
  // selection start/end changes after onMouseUp, so do these checks after the selection end is updated
  // to handle the case where an entire macro is highlighted and the user then clicks inside of the macro
  setTimeout(() => {
    if (insideMacro(e.target.value, e.target.selectionEnd)) {
      const leftBound = leftEnd(e.target.value, e.target.selectionEnd);
      const rightBound = rightEnd(e.target.value, e.target.selectionEnd);
      if (e.shiftKey) {
        e.target.selectionEnd = leftBound;
      } else {
        const rightDist = (rightBound - e.target.selectionEnd);
        const leftDist = (e.target.selectionEnd - leftBound);
        const cursorPos = leftDist <= rightDist ? leftBound : rightBound;
        e.target.selectionEnd = cursorPos;
        e.target.selectionStart = cursorPos;
      }
    }
    if (insideMacro(e.target.value, e.target.selectionStart)) {
      // if highlighting from left to right
      e.target.selectionStart = rightEnd(e.target.value, e.target.selectionStart);
    }
  }, 1);
};


export default class MacroTextAreaWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cursorPos: 0,
    };
    this.insertMacro = this.insertMacro.bind(this);
  }

  insertMacro(macro) {
    const macroStr = `{{ ${macro} }}`;
    const { textAreaValue } = this.props;
    const cursorPos = this.textar.selectionEnd;
    const newBody = textAreaValue.slice(0, cursorPos) + macroStr + textAreaValue.slice(cursorPos);
    this.props.onBodyChange(newBody);
    this.textar.focus();
    setTimeout(() => { this.textar.selectionEnd = cursorPos + macroStr.length; }, 1);
  }

  render() {
    return (
      this.props.render({
        onMouseUp,
        onKeyUp,
        onKeyDown,
        textAreaRef: (textar) => { this.textar = textar; },
        insertMacro: this.insertMacro,
      })

    );
  }
}

MacroTextAreaWrapper.propTypes = {
  onBodyChange: PropTypes.func.isRequired,
  textAreaValue: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
};
