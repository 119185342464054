/* eslint-disable max-classes-per-file */
import moment from 'moment';
import {
  ADD_ATTACHMENT,
  MATCHES,
  NA,
  JURISDICTION,
  SUBMISSION_DATE,
} from '../constants';

class Cell {
  constructor(value, colId, rowId) {
    this.value = value ?? '';
    this.colId = colId;
    this.rowId = rowId;
    this.active = false;
  }
}

export default class Row {
  constructor(row, colMap, jurisdictionMap) {
    this.id = row.id;
    this.data = {};
    this.files = row.files;
    this.matches = row.matches;
    this.submission_date = row.submission_date;
    Object.keys(colMap).forEach((colId) => {
      const col = colMap[colId];
      const actualColId = col._id || col.id;
      let val = row.data[colId];
      if (col.input_type === 'select' && !val && !col.required) {
        val = NA; // todo: add NA logic to dropdown?
      }
      if (colId === JURISDICTION) {
        const jurId = row.data.jurisdiction;
        const jurObj = jurisdictionMap.byId[jurId];
        // override val with true jurisdiction name b/c jurisdiction names set via the form can be truncated
        val = jurObj ? jurObj.name : val;
      }
      this.data[actualColId] = new Cell(val, colId, row.id);
      this.data[colId] = this.data[actualColId];
    });
    this.data[ADD_ATTACHMENT] = new Cell(this.files, ADD_ATTACHMENT, row.id);
    this.data[MATCHES] = new Cell(this.matches, MATCHES, row.id);
    this.data[SUBMISSION_DATE] = new Cell(moment(this.submission_date).format('YYYY-MM-DD'), SUBMISSION_DATE, row.id);
  }

  deleteColumn(colId) {
    delete this.data[colId];
  }

  addCell(val, colId, rowId) {
    this.data[colId] = new Cell(val, colId, rowId);
  }
}
