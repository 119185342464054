import React from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { TextField } from 'components/shared/SchemaForm/components';
import { FieldArray, useField } from 'formik';
import { FIELD_CHOICE } from 'components/FormBuilder/consts';
import { defaultChoice } from '../consts';


const Choice = ({
  name,
  id,
  index,
  onRemove,
  isRemoveable,
  addChoice,
  icon,
}) => {
  const fieldId = `${name}.${index}`;
  const [field, meta, helpers] = useField(fieldId);
  const { label } = field.value;

  return (
    <Draggable
      draggableId={`${id}`}
      index={index}
    >
      {(provided) => (
        <div className="editable-option-wrapper">
          <div
            className="editable-option"
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <i
              className="material-icons drag-indicator"
              {...provided.dragHandleProps}
            >
              drag_indicator
            </i>
            <i className="material-icons option-icon">
              { icon }
            </i>
            <TextField
              id={`${fieldId}.label`}
              name={`${fieldId}.label`}
              className="form-input-hidden"
              autoFocus
              onKeyDown={(e) => {
                if (e.key === 'Enter' && label.trim().length) {
                  addChoice(index + 1, { ...defaultChoice() });
                }
              }}
            />
            {
              isRemoveable
                ? (
                  <button
                    type="button"
                    className="text-button"
                    onClick={() => onRemove(index)}
                  >
                    <i className="material-icons remove-option">close</i>
                  </button>
                ) : null
            }
          </div>
          {meta.error && meta.error.label && (
            <p className="error-text">{meta.error.label}</p>
          )}
        </div>
      )}
    </Draggable>
  );
};

Choice.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  isRemoveable: PropTypes.bool.isRequired,
  addChoice: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
};


const ChoicesField = ({
  fieldId,
  choicesFieldName,
  choices,
  removeDependencyChoice,
  choiceIcon,
}) => (
  <FieldArray
    name={choicesFieldName}
  >
    {({ remove, push, insert }) => (
      <div>
        <Droppable
          droppableId={`${fieldId}`}
          type={FIELD_CHOICE}
        >
          {(provided, snapshot) => (
            <div
              className={`${snapshot.isDraggingOver ? 'active ' : ''}`}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div>
                {choices.map((choice, index) => (
                  <Choice
                    key={choice.id}
                    name={choicesFieldName}
                    id={choice.id}
                    index={index}
                    onRemove={() => {
                      remove(index);
                      removeDependencyChoice(fieldId, index);
                    }}
                    isRemoveable={choices.length > 1}
                    addChoice={insert}
                    icon={choiceIcon}
                  />
                ))}
              </div>
              { provided.placeholder }
            </div>
          )}
        </Droppable>
        <div className="button-wrapper">
          <button
            type="button"
            className="text-button uppercase add-field-button"
            onClick={() => push({ ...defaultChoice() })}
          >
            + Add option
          </button>
        </div>
      </div>
    )}

  </FieldArray>
);

ChoicesField.propTypes = {
  fieldId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  choicesFieldName: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  removeDependencyChoice: PropTypes.func.isRequired,
  choiceIcon: PropTypes.string.isRequired,
};

export default ChoicesField;
