import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { TEMPLATE_TYPES, USE_TEMPLATE, CUSTOMIZE_TEMPLATE, MACRO_LINK_HELP_TEXT } from 'components/Certifications/constants';
import { TextField, SelectField, TemplateArea } from '../components';
import SelectionToggle from '../components/SelectionToggle';


const fieldMap = {
  subject: 'email_subject',
  body: 'email_body',
};


const EmailForm = ({ emailTemplates, macros }) => {
  const templates = useRef(emailTemplates);
  const isInitialMount = useRef(true);
  const [emailTemplate] = useField('email_template');
  const { setFieldValue } = useFormikContext();

  const initTemplateType = emailTemplate.value ? USE_TEMPLATE : CUSTOMIZE_TEMPLATE;
  const [templateType, setTemplateType] = useState(initTemplateType);
  const useTemplate = templateType === USE_TEMPLATE;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      switch (templateType) {
        case CUSTOMIZE_TEMPLATE:
          setFieldValue('email_template', null);
          break;
        case USE_TEMPLATE: {
          setFieldValue('email_template', emailTemplates[0].id);
          break;
        }
        default:
          return null;
      }
    }
  }, [templateType]);

  return (
    <div className="create-cert-form">
      <h3>Customize Email</h3>
      {
        emailTemplates.length ?
          <div className="flex-wrapper">
            <SelectField
              choices={templates.current}
              label="Template"
              name="email_template"
              autofill
              fieldMap={fieldMap}
              disabled={!useTemplate}
            />
            <div className="template-toggle">
              <SelectionToggle
                selectedVal={templateType}
                choices={TEMPLATE_TYPES}
                setValue={setTemplateType}
              />
            </div>
          </div>
          :
          null
      }
      <TextField
        name="email_subject"
        label="Subject"
        disabled={useTemplate}
      />
      <TemplateArea
        label="Body"
        name="email_body"
        variables={macros.variables || []}
        links={macros.links || []}
        readOnly={useTemplate}
        macroLinkHelpText={MACRO_LINK_HELP_TEXT}
      />
    </div>
  );
};
EmailForm.propTypes = {
  emailTemplates: PropTypes.array.isRequired,
  macros: PropTypes.object.isRequired,
};

export { EmailForm };
