import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import useCancellablePromise from 'hooks/useCancellablePromise';
import { maxLengthError } from 'utils/errorHelpers';
import { SchemaField } from '../SchemaForm/SchemaField';


const MAX_COMMENT_LENGTH = 5000;

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .required('Please enter a comment')
    .max(MAX_COMMENT_LENGTH, maxLengthError(MAX_COMMENT_LENGTH)),
});


const CommentForm = ({
  createComment,
  contributionId,
}) => {
  const { cancellablePromise } = useCancellablePromise();
  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  const submitComment = ({ comment }, { setSubmitting, resetForm }) => {
    cancellablePromise(createComment(contributionId, comment)).then(() => {
      toast('Comment saved!');
      setSubmitting(false);
      resetForm();
    }).catch((err) => {
      if (!err.isCanceled) {
        toast('There was a problem saving this comment.');
        setSubmitting(false);
        throw err;
      }
    });
  };

  return (
    <div>
      <Formik
        initialValues={{
          comment: '',
        }}
        onSubmit={submitComment}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          isSubmitting,
          values,
        }) => (
          <Form autoComplete="off" noValidate>
            <div>
              <SchemaField
                element="textarea"
                label="Add comment:"
                name="comment"
                id="comment"
                fieldType="TextField"
                innerRef={inputElement}
              />
              <div className="comment-button-wrapper">
                <div className="helptext">
                  { values.comment.length }
                  {' '}
                  /
                  {' '}
                  {MAX_COMMENT_LENGTH}
                  {' '}
                  characters
                </div>
                <button
                  className="orange-button"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Comment
                </button>
              </div>

            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CommentForm.propTypes = {
  contributionId: PropTypes.number.isRequired,
  createComment: PropTypes.func.isRequired,
};

export default CommentForm;
