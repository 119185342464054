import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import {
  PATHS, PATH_NAMES,
} from 'utils/constants';


const DeleteConfirmation = ({
  name,
  closeModal,
  deleteFunc,
  uiSearchType,
  deleteFuncArgs,
  deleteText,
  history,
  redirectUrl,
  deleteDelay,
  deleteDelaySetting,
}) => (
  <div className="form-wrapper search-form">
    <div className="delete-confirmation">
      <div>
        { deleteText }
        { ' ' }
        Are you sure you want to delete
        {' '}
        <strong>
          { name }
        </strong>
        ?
       {
        deleteDelay ? (
          <div className="confirmation-note">
            Note that the delete delay setting of
            {' '}
            <Tippy
              interactive
              content={(
                <div>
                  The delete delay setting can be changed in the delete actions tab on the
                  {' '}
                  <Link
                    to={`${PATHS[PATH_NAMES.platform_settings]}`}
                    className="orange-link"
                  >
                    platform settings page
                  </Link>
                  .
                </div>
              )}
            >
              <span className="tooltip-target">
                { deleteDelaySetting }
              </span>
            </Tippy>
            {' '}
            will be applied by default. { name } will be archived in the meantime.
          </div>
        )
          : <div>This action cannot be undone.</div>
        }
        <div className="buttons">
          <button
            className="text-button uppercase"
            onClick={closeModal}
            type="button"
          >
            Cancel
          </button>
          <button
            className="orange-button"
            type="button"
            onClick={() => {
              history.push(`${redirectUrl}`);
              closeModal();
              deleteFunc(...deleteFuncArgs).catch((err) => {
                toast(`There was a problem deleting this ${pluralize.singular(uiSearchType)}.`);
                throw err;
              });
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
);

DeleteConfirmation.propTypes = {
  name: PropTypes.string.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  uiSearchType: PropTypes.string.isRequired,
  deleteFuncArgs: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  deleteText: PropTypes.string,
  deleteDelay: PropTypes.number,
  deleteDelaySetting: PropTypes.string
};

DeleteConfirmation.defaultProps = {
  deleteText: '',
  deleteDelay: null,
  deleteDelaySetting: null
};

export default DeleteConfirmation;
