import api from '../../../api';
import * as types from '../types';

const loadPreNotifications = data => ({
  data,
  type: types.LOAD_PRE_NOTIFICATIONS,
});

const addPreNotification = data => ({
  data,
  type: types.ADD_PRE_NOTIFICATION,
});

const updatePreNotification = data => ({
  data,
  type: types.UPDATE_PRE_NOTIFICATION,
});

const deletePreNotification = id => ({
  id,
  type: types.DELETE_PRE_NOTIFICATION,
});


export const fetchPreNotifications = () => dispatch =>
  api.get('/pre/notifications/')
    .then(({ data }) => {
      dispatch(loadPreNotifications(data));
      return data;
    });

export const createPreNotification = newNotification => dispatch =>
  api.post('/pre/notifications/', newNotification)
    .then(({ data }) => {
      dispatch(addPreNotification(data));
      return data;
    });

export const editPreNotification = newNotification => dispatch =>
  api.put(`/pre/notifications/${newNotification.id}/`, newNotification)
    .then(({ data }) => {
      dispatch(updatePreNotification(data));
      return data;
    });

export const removePreNotification = id => dispatch =>
  api.delete(`/pre/notifications/${id}/`)
    .then(({ data }) => {
      dispatch(deletePreNotification(id));
      return data;
    });
