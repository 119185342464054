import * as types from './types';
import { TOGGLE_JURISDICTION_LOADING, LOAD_RESULTS } from '../results/types';

const initState = {
  dateFilter: {
    to: null,
    from: null,
  },
  selectedContribs: new Set(),
  recipient: null,
  resultsLoading: true,
  sessionExpired: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.SET_DATE_FILTER: {
      const { dateFilter } = action;
      return {
        ...state,
        dateFilter,
        selectedContribs: new Set(),
        resultsLoading: true,
      };
    }
    case types.TOGGLE_CONTRIB: {
      const nextState = new Set(state.selectedContribs);
      if (action.active) {
        action.contribIds.forEach(r => nextState.add(r));
      } else {
        action.contribIds.forEach(r => nextState.delete(r));
      }
      return {
        ...state,
        selectedContribs: nextState,
      };
    }
    case types.SET_VISIBLE_RECIPIENT: {
      return {
        ...state,
        recipient: action.recipient,
      };
    }
    case TOGGLE_JURISDICTION_LOADING: {
      return {
        ...state,
        resultsLoading: action.loading,
      };
    }
    case LOAD_RESULTS: {
      return {
        ...state,
        resultsLoading: false,
      };
    }
    case types.EXPIRE_SESSION: {
      if (!state.sessionExpired) {
        return {
          ...state,
          sessionExpired: true,
        };
      }
      return state;
    }
    case types.RESET_UI: {
      return {
        ...initState,
        sessionExpired: state.sessionExpired,

      };
    }
    default:
      return state;
  }
};
