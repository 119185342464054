import React from 'react';
import { Field, useField } from 'formik';

import { FORM_ELEMENT } from '../constants';

const IntegerField = ({ ...props }) => {
    const [field] = useField(props);

    return <Field type="number" {...field} {...props} />;
};

IntegerField.propTypes = {
    ...FORM_ELEMENT,
};

export { IntegerField };
