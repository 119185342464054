import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import BatchEditBanner from './dashboard/batchEditBanner';
import { setSectionStatus, updateContributionStatus } from '../reducers/contributions/actions';
import { toggleContribution } from '../reducers/ui/actions';


class WorkFlowUI extends Component {
  constructor(props) {
    super(props);
    this.setRecordStatus = this.setRecordStatus.bind(this);
  }

  setRecordStatus(status) {
    const ids = Array.from(this.props.selected);
    const setStatusFunc = this.props.onDashboard ? this.props.dashboardSetSectionStatus : this.props.setSectionStatus;
    setStatusFunc(ids, status.id).then((data) => {
      const count = ids.length;
      const label = status.label || '';

      const toastContent = (
        <div>
          Marked
          {' '}
          <strong>{count.overrideLocaleString()}</strong>
          {' '}
          {pluralize('record', count)}
          {' '}
          as
          {' '}
          <strong>{`"${label}"`}</strong>
        </div>
      );
      toast(toastContent);

      this.props.toggleContribution(this.props.selected, false);
      return data;
    }).catch((err) => {
      toast('There was a problem changing this status.');
      throw err;
    });
  }

  render() {
    const { children, statuses, selected } = this.props;
    const numSelected = selected.size;

    return (
      <>
        { children }
        {
          numSelected > 0
            ? (
              <BatchEditBanner
                options={statuses}
                numSelected={numSelected.overrideLocaleString()}
                setRecordStatus={this.setRecordStatus}
                onCancel={() => { this.props.toggleContribution(selected, false); }}
              />
            )
            : null
        }

      </>
    );
  }
}
const mapStateToProps = (state, props) => {
  const { match } = props;
  const onDashboard = match.path.indexOf('dashboard') >= 0;
  return {
    statuses: Object.values(state.statuses),
    selected: state.ui.selectedContribs,
    onDashboard,
  };
};

export default withRouter(connect(
  mapStateToProps,
  {
    dashboardSetSectionStatus: setSectionStatus,
    setSectionStatus: updateContributionStatus,
    toggleContribution,
  },
)(WorkFlowUI));

WorkFlowUI.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  statuses: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  setSectionStatus: PropTypes.func.isRequired,
  dashboardSetSectionStatus: PropTypes.func.isRequired,
  toggleContribution: PropTypes.func.isRequired,
  onDashboard: PropTypes.bool.isRequired, // not great, but using this to determine which setStatus method to use
};

WorkFlowUI.defaultProps = {
  children: {},
};
