import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { CustomDropdown } from 'components/dropdown';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import ViewMatchesContainer from './viewMatch';

const HasMatchButton = ({
  matches, openMatchingDialog, matchType, viewOnly, removeMatch, disabled,
}) => {
  const [rectangle, setRectangle] = useState({});

  return (
    <CustomDropdown render={({
      isOpen,
      openDropdown,
      closeDropdown,
      dropdownRef,
    }) => (
      <div>
        <IconTextButton
          buttonHandler={(e) => {
            const rect = e.target.parentElement.getBoundingClientRect();
            setRectangle({
              left: rect.left,
              top: rect.top,
              height: rect.height,
              width: rect.width,
            });
            return isOpen ? closeDropdown(e) : openDropdown(e);
          }}
          buttonText={`${matches.length} ${pluralize('match', matches.length)}`}
          icon={'link'}
          className={'match-button has-match'}
          disabled={disabled}
        />
        {
          isOpen && (
            <ViewMatchesContainer
              matches={matches}
              viewMatchRef={dropdownRef}
              matchType={matchType}
              viewOnly={viewOnly}
              rect={rectangle}
              openMatchingDialog={() => {
                openMatchingDialog();
                closeDropdown();
              }}
              removeMatch={removeMatch}
            />
          )
        }
      </div>
    )}
    />
  );
};

HasMatchButton.propTypes = {
  matches: PropTypes.array.isRequired,
  openMatchingDialog: PropTypes.func,
  matchType: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  removeMatch: PropTypes.func,
};


HasMatchButton.defaultProps = {
  viewOnly: false,
  openMatchingDialog: () => {},
  disabled: false,
  removeMatch: () => {},
};

export default HasMatchButton;
