import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';
import { Link } from 'react-router-dom';
import { SELF } from 'components/employeePage/consts';
import { SidebarSearch } from '../sidebar/search';
import AlphaSelector from '../sidebar/alphaSelector';
import ListItem from './listItem';
import { BASE_MONITORING_URL } from '../../utils/constants';

// to aviod ternary statements everywhere, the keys here coorespond to this.props.listType
const listTypeObj = {
  selected: {
    emptyStateMessage: 'No people or companies selected.',
    icon: 'close',
    action: 'remove',
  },
  available: {
    emptyStateMessage: 'All people and companies have been selected.',
    icon: 'arrow_forward',
    action: 'add',
  },
};

const rowHeights = {
  row: 47,
  alphaRow: 36,
  headerRow: 51,
};

const sortNames = (a, b) => {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  return 0;
};

const AllButton = ({ icon, action, disabled, toggleSearchObject }) => (
  <button
    onClick={() => { toggleSearchObject(null, null, action); }}
    disabled={disabled}
    className={`${action}`}
  >
    <span>{action} All</span><i className="material-icons">{icon}</i>
  </button>
);

AllButton.propTypes = {
  icon: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  toggleSearchObject: PropTypes.func.isRequired,
};

AllButton.defaultProps = {
  disabled: false,
};

class ExportList extends Component {
  constructor(props) {
    super(props);
    this.letterMap = [];
    this.prevOpened = false;
    this.openLetters = [];
    this.state = {
      filter: '',
    };
    this.renderListHeader = this.renderListHeader.bind(this);
    this.onSearchFilter = this.onSearchFilter.bind(this);
    this.toggleLetter = this.toggleLetter.bind(this);
    this.generateRows = this.generateRows.bind(this);
    this.getRow = this.getRow.bind(this);
    this.calcRowHeight = this.calcRowHeight.bind(this);
    this.noRowsRenderer = this.noRowsRenderer.bind(this);
    this.getRowHeaderIndex = this.getRowHeaderIndex.bind(this);
    this.getListMeta = this.getListMeta.bind(this);
  }

  componentWillMount() {
    this.generateRows();
  }

  componentWillReceiveProps(nextProps) {
    this.generateRows(nextProps.employees, nextProps.entities);
  }

  componentDidUpdate() {
    // for now
    if (this.list) {
      // this.generateRows();
      this.list.forceUpdateGrid();
      this.list.recomputeRowHeights();
    }
  }

  onSearchFilter(e) {
    this.setState({
      filter: e.target.value,
    });
  }

  getListMeta(index) {
    let prevActive = -1;
    let currentActive = 0;
    let testNode = null;
    let isHeader = false;
    const useFilteredSearches = this.state.filter.trim();
    for (let i = 0; i < this.letterMap.length; i++) {
      testNode = this.letterMap[i];
      prevActive = currentActive;
      isHeader = testNode.renderType === 'header';
      if (!testNode.expanded) {
        currentActive += useFilteredSearches
        && !testNode.filterSearches.length && !isHeader ? 0 : 1;
      } else if (testNode.expanded && useFilteredSearches
        && testNode.filterSearches.length === 0 && !isHeader) {
        continue;
      } else {
        currentActive += 1 + (useFilteredSearches ?
          testNode.filterSearches.length : testNode.searches.length);
      }
      if (index >= prevActive && index < currentActive) {
        break;
      }
    }
    return { prevActive, testNode };
  }

  getRowHeaderIndex(index) {
    const useFilteredSearches = this.state.filter.trim();
    if (useFilteredSearches) {
      const entityNode = this.letterMap.find(n => n.type === 'entities');
      if ((index === 0) || (entityNode && entityNode.filterSearches.length && index === entityNode.filterSearches.length + 1)) {
        return true;
      }
    } else {
      return (index === 0) || (this.props.entities.length && index === (this.props.entities.length + 1));
    }
  }

  getRow({ index, key, style }) {
    const { prevActive, testNode } = this.getListMeta(index);
    const { listType, toggleSearchObject } = this.props;
    const hasFilters = this.state.filter.trim();
    // TODO: test this logic and probably come up with a better way
    const headerClass = this.getRowHeaderIndex(index);

    const first = index - prevActive === 1 ? 'first' : '';
    const last = testNode[hasFilters ? 'filterSearches' : 'searches'].length === index - prevActive ? 'last' : '';

    const rowClass = prevActive === index ? 'alpha-row' : `emp-row ${first} ${last}`;

    const search = hasFilters ? testNode.filterSearches[index - prevActive - 1] : testNode.searches[index - prevActive - 1];

    const action = listTypeObj[listType].action;
    if (headerClass) {
      return (
        <div
          key={key}
          style={style}
          className="header-row"
        >
          <ListItem
            icon={listTypeObj[listType].icon}
            item={testNode.letter}
            direction={listType}
            handleSelect={() => { toggleSearchObject(null, testNode.type, action); }}
          />
        </div>
      );
    }
    const searchType = search ? (search.search_type || '') : '';
    return (
      <div
        key={key}
        style={style}
        className={rowClass}
      >
        {
          prevActive === index ? (
            <AlphaSelector
              letter={testNode.letter}
              toggleExpanded={() => (this.toggleLetter(testNode.letter))}
              expanded={testNode.expanded}
            />
          ) : (
            <ListItem
              key={search.search_object_id}
              icon={listTypeObj[listType].icon}
              item={testNode.type === 'employees' ? `${search.last_name}, ${search.first_name}` : search.entity_name}
              searchType={searchType === SELF ? '' : searchType}
              archived={search.archived}
              direction={listType}
              handleSelect={() => { toggleSearchObject(search, testNode.type, action); }}
            />
          )
        }
      </div>
    );
  }

  toggleLetter(target = null, isExpanded) {
    if (typeof (target) === 'object') {
      this.letterMap.forEach(n => n.expanded = !!(target || []).length);
    } else {
      const targetNode = this.letterMap.find(n => n.letter === target);
      if (targetNode) {
        targetNode.expanded = typeof (isExpanded) === 'undefined' ? !targetNode.expanded : isExpanded;
      }
      const openIndex = this.openLetters.indexOf(targetNode.letter);
      if (openIndex >= 0) {
        this.openLetters.splice(openIndex, 1);
      }
    }
    this.forceUpdate();
  }

  filterFunc(names) {
    const matchCases = this.state.filter.split(/\s+/).map(s => s.toLowerCase().replace(',', ''));
    return matchCases.every(item => names.some(name =>
      name.toLowerCase().indexOf(item) === 0));
  }

  filterFuncEntities(names) {
    const RegExpEscape = str => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    const regTest = new RegExp(RegExpEscape(this.state.filter), 'gi');
    return names.some(n => regTest.test(n)) || names.some(n => regTest.test(n));
  }

  filterSearches() {
    const filterInUse = !(this.state.filter.length === 0 || !this.state.filter.trim());

    if (filterInUse) {
      this.letterMap.forEach((f) => {
        f.filterSearches = f.searches.filter((e) => {
          const names = f.type === 'employees' ? [e.first_name, e.last_name] : [e.entity_name];
          return f.type === 'employees' ? this.filterFunc(names) : this.filterFuncEntities(names);
        });
      });
    }
  }


  generateRows(employees = this.props.employees, entities = this.props.entities) {
    // For entities
    const sortedEntities = entities.sort((a, b) => (sortNames(a.entity_name, b.entity_name)));

    const entMap = [];
    if (entities.length > 0) {
      entMap[0] = {
        searches: sortedEntities,
        letter: 'Companies',
        expanded: true,
        type: 'entities',
      };
    }

    // For employees
    const mapper = {};
    employees.forEach((e) => {
      const l = e.last_name[0].toUpperCase();
      if (!mapper[l]) {
        mapper[l] = [];
      }
      mapper[l].push(e);
    });
    const letters = Object.keys(mapper).sort();

    this.letterMap = this.letterMap.filter(l => letters.indexOf(l.letter) >= 0);

    const nextMap = [];
    letters.forEach((l, i) => {
      const prevElem = this.letterMap.find(elem => elem.letter === l) || {};
      const expanded = (this.props.listType === 'selected' && Object.values(prevElem).length === 0) || !!prevElem.expanded;
      nextMap[i] = {
        searches: mapper[l],
        letter: l,
        expanded,
        type: 'employees',
      };
    });

    const header = {
      searches: [],
      filterSearches: [],
      letter: 'People',
      expanded: true,
      type: 'employees',
      renderType: 'header',
    };
    if (employees.length > 0) {
      nextMap.unshift(header);
    }

    this.letterMap = nextMap;

    this.letterMap.forEach((l) => {
      l.searches.sort((a, b) => {
        const aName = (`${a.last_name} ${a.first_name}`);
        const bName = (`${b.last_name} ${b.first_name}`);
        return sortNames(aName, bName);
      });
    });

    this.letterMap = entMap.concat(this.letterMap);
  }

  calcRowHeight({ index }) {
    const { prevActive } = this.getListMeta(index);
    let row = 'row';
    if (this.getRowHeaderIndex(index)) {
      row = 'headerRow';
    } else if (prevActive === index) {
      row = 'alphaRow';
    }
    return rowHeights[row];
  }

  noRowsRenderer() {
    const { noSearches, listType } = this.props;
    let message = (<div>{listTypeObj[listType].emptyStateMessage}</div>);
    if (this.state.filter.trim()) {
      message = (<div>No people or companies found with the name {`"${this.state.filter}"`}</div>);
    } else if (noSearches && listType === 'available') {
      message = (
        <div>
          <div>Please add a person or company to monitor before bulk exporting.</div>
          <Link to={`/app/${BASE_MONITORING_URL}`} className="back-button">
            <i className="material-icons">arrow_back</i> Back to monitoring
          </Link>
        </div>
      );
    }

    return (
      <div className="empty">
        {message}
      </div>
    );
  }

  renderListHeader() {
    const { employees, entities, listType } = this.props;
    const count = employees.length + entities.length;
    return (
      <div className="list-header">
        <div>
          {listType} &mdash; {count}
        </div>
        <AllButton
          toggleSearchObject={this.props.toggleSearchObject}
          icon={listTypeObj[listType].icon}
          action={listTypeObj[listType].action}
          disabled={count === 0}
        />
      </div>
    );
  }


  render() {
    this.filterSearches();
    const { error } = this.props;
    const height = 480;
    const totalRows = this.letterMap.reduce((acc, val) => {
      if (this.state.filter.trim()) {
        const filteredEmps = this.letterMap.some(l => l.letter !== 'Companies' && l.filterSearches.length > 0);
        return acc + (val.filterSearches.length > 0 || (val.renderType === 'header' && filteredEmps) ? 1 : 0) +
         (val.expanded ? val.filterSearches.length : 0);
      }
      return acc + 1 + (val.expanded ? val.searches.length : 0);
    }, 0);

    const width = 350;

    return (
      <div>
        <div className={`list-wrapper ${error ? 'error' : ''}`}>
          <div className="header-wrapper">
            { this.renderListHeader() }
            <div className="search-wrapper">
              <SidebarSearch
                onSearchFilter={this.onSearchFilter}
                filterValue={this.state.filter}
                searchPlaceholder={'search...'}
              />
            </div>
          </div>
          <List
            height={height}
            rowCount={totalRows}
            rowHeight={this.calcRowHeight}
            rowRenderer={this.getRow}
            noRowsRenderer={this.noRowsRenderer}
            width={width}
            ref={(el) => { this.list = el; }}
          />
        </div>
        <div className="error-text">
          {error}
        </div>
      </div>
    );
  }
}


ExportList.propTypes = {
  employees: PropTypes.array.isRequired,
  entities: PropTypes.array.isRequired,
  // listType is 'selected' or 'available'
  listType: PropTypes.string.isRequired,
  toggleSearchObject: PropTypes.func.isRequired,
  noSearches: PropTypes.bool,
  error: PropTypes.string,
};

ExportList.defaultProps = {
  noSearches: false,
  error: '',
};

export default ExportList;
