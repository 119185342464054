import React from 'react';
import PropTypes from 'prop-types';
import Confirmation from '../../settings/confirmationShared';


export default function ChangeConfirmation({
  onSubmit,
  onCancel,
  confirmText,
  headerText,
  children,
  confirmSentence,
  disableConfirm,
}) {
  return (
    <Confirmation
      open
      close={onCancel}
      confirmText={confirmText}
      dismissText="Cancel"
      onSubmit={onSubmit}
      disableConfirm={disableConfirm}
    >
      <div>
        <h2>{headerText}</h2>
        <div className="preclearance-change-confirmation">
          {children}
          {
            <div className="message">{confirmSentence}</div>
          }
        </div>
      </div>
    </Confirmation>
  );
}

ChangeConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  confirmSentence: PropTypes.object.isRequired,
  children: PropTypes.object,
  disableConfirm: PropTypes.bool,
};

ChangeConfirmation.defaultProps = {
  children: null,
  disableConfirm: false,
};
