import api from 'api';
import { batchActions } from 'redux-batched-actions';
import { buildQuery } from 'utils/apiUtil';
import * as types from './types';


const setAggregation = (data, aggType) => ({
  data,
  aggType,
  type: types.SET_AGGREGATION,
});

export const getChartData = (by, limit, from, to, order, sortByDim) => (dispatch) => {
  const query = {
    by,
    limit,
    from_date: from,
    to_date: to,
    order,
    sort_by_dim: sortByDim,
  };

  return api.get(`/agg/contributions/?${buildQuery(query)}`).then(({ data }) => {
    const aggData = (data && data.aggregation) || [];
    dispatch(batchActions([
      setAggregation(aggData, by),
    ]));
    return aggData;
  });
};
