import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/loading';
import { formatNames } from 'components/shared/searchObjectHeader/header';
import SearchFilters from 'components/shared/searchObjectHeader/searchFilters';
import RecordTable from './recordTable';
import { scrollToElem } from '../../../helpers/helpers';
import { SCROLLABLE_CONTAINER } from '../../../utils/constants';

class RecordGroup extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.toggleOpen = this.toggleOpen.bind(this);

    this.listenForScroll = this.listenForScroll.bind(this);
    this.listenForResize = this.listenForResize.bind(this);
    this.scrollToTableTop = this.scrollToTableTop.bind(this);
    this.preventCloseOnLinkClick = e => e.stopPropagation();

    this.onPageScroll = this.listenForScroll;
    this.onPageResize = this.listenForResize;
    this.navBarHeight = document.getElementById('navBar').getBoundingClientRect().bottom;
    this.loading = false;
  }

  componentDidMount() {
    SCROLLABLE_CONTAINER.addEventListener('scroll', this.listenForScroll);
    window.addEventListener('resize', this.listenForResize);
    if (this.props.defaultOpen) {
      this.toggleOpen();
    }
  }

  componentWillUnmount() {
    SCROLLABLE_CONTAINER.removeEventListener('scroll', this.listenForScroll);
    window.removeEventListener('resize', this.listenForResize);
  }

  listenForScroll() {
    if (!this.fixedHeader || !this.state.open) {
      return;
    }

    const isFixed = this.fixedHeader.classList.contains('on');
    if (this.state.open && !this.loading) {
      const { bottom, width } = this.elem.querySelector('.source-table').getBoundingClientRect();
      const fixedSchemaHeader = this.elem.querySelector('.source-table .fixed-header');
      const relSchemaHeader = this.elem.querySelector('.source-table .schema-header');

      const { top } = this.sourceHeader.getBoundingClientRect();
      const { innerHeight } = window;

      const tHeadHeight = relSchemaHeader.offsetHeight;

      const headerHeight = this.sourceHeader.offsetHeight + tHeadHeight;

      const testHeight = bottom - (innerHeight / 2);
      if (top <= this.navBarHeight && bottom > innerHeight && !isFixed) {
        this.fixedHeader.classList.add('on');
        fixedSchemaHeader.classList.add('on');
        fixedSchemaHeader.style.width = `calc(${width - 2}px)`;
        this.fixedHeader.style.width = `calc(${width}px)`;
        fixedSchemaHeader.style.height = tHeadHeight;
        fixedSchemaHeader.style.top = `${this.fixedHeader.offsetHeight + this.navBarHeight}px`;
      } else if ((top > this.navBarHeight || testHeight < headerHeight) && isFixed) {
        this.fixedHeader.classList.remove('on');
        fixedSchemaHeader.classList.remove('on');
        this.fixedHeader.style.width = null;
        this.fixedHeader.style.height = null;
      }
    }
  }

  listenForResize() {
    if (this.state.open && !this.loading) {
      const fixedSchemaHeader = this.elem.querySelector('.source-table .fixed-header');

      const { width } = this.elem.querySelector('.source-table').getBoundingClientRect();
      fixedSchemaHeader.style.width = `${width + 10}px`;
      this.fixedHeader.style.width = `${width}px`;
    }
  }

  scrollToTableTop(cb) {
    const target = this.sourceHeader.getBoundingClientRect().top - this.navBarHeight;
    if (target >= 0) {
      cb();
    } else {
      scrollToElem(target, cb);
    }
  }

  toggleOpen() {
    // in jurisdiction view we load results on open
    if (!this.props.results && !this.loading) {
      this.loading = true;
      const { searchObjId, searchType, jurId } = this.props;
      this.props.fetchResults(searchObjId, searchType, jurId).then(() => {
        this.loading = false;
      });
    }
    if (!this.state.open) {
      const target = this.elem.getBoundingClientRect().top - this.navBarHeight;
      scrollToElem(target);
    }
    this.setState({ open: !this.state.open });
  }

  headerGen(options) {
    const {
      searchDetails, errorMessage, tableTitle, url, isJurisdiction,
    } = this.props;
    const { open: isOpen } = this.state;
    const { className, style, name, ref } = options;
    const warnings = [errorMessage];
    const hasWarnings = warnings.filter(warn => !!warn).length > 0;
    const { aliases } = formatNames(searchDetails);
    return (
      <div
        className={className}
        role="button"
        tabIndex={0}
        onClick={this.toggleOpen}
        ref={ref}
        name={name}
        style={style}
      >
        <div className="flex-wrapper">
          <h3>
            {tableTitle}
            {hasWarnings ?
              <span className="tool-tip-wrapper">
                <i className="material-icons">&#xE000;</i>
                <div className="error-tool-tip">{warnings.map((w, i) => (<div key={i}>{w}</div>))}</div>
              </span> : ''}
            <span className="count-wrapper">{this.props.resultCount.overrideLocaleString()}</span>
          </h3>
          <div className="source-details">
            {url ?
              <a
                href={url}
                onClick={this.preventCloseOnLinkClick}
                className="text-button uppercase selected"
                target="_blank"
                rel="noopener noreferrer"
              > Verify at source</a> : ''
            }
            <span>
              {isOpen ?
                <i className="material-icons">expand_less</i> :
                <i className="material-icons">expand_more</i>
              }
            </span>
          </div>
        </div>
        {
          isOpen && isJurisdiction ?
            <div className="results-details-row">
              <SearchFilters
                search={searchDetails}
                aliases={aliases}
              />
            </div>
            : null
        }
      </div>
    );
  }


  render() {
    const { tableTitle, searchObjId, showContribSchema } = this.props;
    const { open: isOpen } = this.state;
    const sourceHeaderOpts = {
      className: `source-row ${isOpen ? 'open' : ''}`,
      name: `${tableTitle.toLowerCase()} ${searchObjId}`,
      ref: (el) => { this.sourceHeader = el; },
    };

    const fixedHeaderOpts = {
      className: 'source-row fixed-header',
      ref: (el) => { this.fixedHeader = el; },
    };

    return (
      <div className="source-container" ref={(el) => { this.elem = el; }}>
        {this.headerGen(sourceHeaderOpts)}
        {isOpen ? this.headerGen(fixedHeaderOpts) : ''}
        { isOpen ? (
          <div>
            { this.props.results ?
              <RecordTable
                showContribSchema={showContribSchema}
                scrollToTableTop={this.scrollToTableTop}
                isLast={this.props.isLast}
                results={this.props.results}
                selectedContribs={this.props.selectedContribs}
                statuses={this.props.statuses}
                sourceResultIds={this.props.sourceResultIds}
                schemas={this.props.schemas}
                isJurisdiction={this.props.isJurisdiction}
                objId={searchObjId}
              /> :
              <div className="load-wrapper">
                <div>
                  <Loading />
                </div>
              </div>
            }
          </div>
        ) : null}
      </div>
    );
  }
}

RecordGroup.propTypes = {
  results: PropTypes.array,
  tableTitle: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool.isRequired, // open by default (if this is the only source in its subcategory)
  isJurisdiction: PropTypes.bool.isRequired,
  showContribSchema: PropTypes.bool, // determines whether to render checkboxes, notes, and statuses.
  url: PropTypes.string,
  schemas: PropTypes.object,
  statuses: PropTypes.object.isRequired,
  selectedContribs: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
  sourceResultIds: PropTypes.array,
  searchDetails: PropTypes.object,
  errorMessage: PropTypes.string,
  resultCount: PropTypes.number.isRequired,
  // the following props are only needed in jurisdiction view
  searchObjId: PropTypes.number,
  fetchResults: PropTypes.func,
  searchType: PropTypes.string,
  jurId: PropTypes.number,
};

RecordGroup.defaultProps = {
  isLast: false,
  showContribSchema: true,
  searchDetails: {},
  errorMessage: '',
  // default props for jurisdiction view
  results: null,
  schemas: {},
  sourceResultIds: [],
  url: '',
  // default props for non jurisdiciton view
  searchObjId: 0,
  fetchResults: () => {},
  searchType: '',
  jurId: null,
};

export default RecordGroup;
