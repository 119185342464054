import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './checkbox';
import SubCategoryToggleItem from './subCategoryToggleItem';
import { selectors as fromSources } from '../../../reducers/sources';

class CategorySourcesSelection extends React.Component {
  renderCategoryCheckbox() {
    const { activeCategory, sources, selected, onToggleSource } = this.props;
    const selectedInCategory = fromSources.hasSelected(sources, selected);
    const checked = fromSources.allSelected(sources, selected);
    const indeterminate = !checked && (selectedInCategory.length > 0);
    return (
      <div className="category">
        <Checkbox
          id={`${activeCategory}-all`}
          checked={checked}
          indeterminate={indeterminate}
          onChange={e => onToggleSource(sources.map(s => s.id), e.target.checked, indeterminate)}
        >
          <span>All {activeCategory}</span>
        </Checkbox>
      </div>
    );
  }

  renderSubcategoryList(subCategories) {
    const { selected, onToggleSource } = this.props;
    return subCategories.map((subcategory) => {
      const subSources = fromSources.bySubcategory(this.props.sources, subcategory);
      const expanded = this.props.expanded.has(subcategory);
      return (
        <SubCategoryToggleItem
          key={subcategory}
          sources={subSources}
          subcategory={subcategory}
          expanded={expanded}
          selected={selected}
          onToggleSource={onToggleSource}
          onToggleExpanded={() => this.props.toggleExpanded(subcategory)}
        />
      );
    });
  }

  renderColumns(subCategories) {
    const leftIndex = Math.ceil(subCategories.length / 2);
    const left = subCategories.slice(0, leftIndex);
    const right = subCategories.slice(leftIndex);

    return (
      <React.Fragment >
        <div className="sourceColumnContainer">
          <div className="sourceColumn">
            {this.renderSubcategoryList(left)}
          </div>
          <div className="sourceColumn">
            {this.renderSubcategoryList(right)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const subCategories = fromSources.subcategories(this.props.sources).sort();

    return (
      <React.Fragment >
        {this.renderCategoryCheckbox()}
        {
          this.renderColumns(subCategories)
        }
      </React.Fragment>
    );
  }
}

CategorySourcesSelection.propTypes = {
  sources: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  expanded: PropTypes.object.isRequired,
  onToggleSource: PropTypes.func.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
  activeCategory: PropTypes.string.isRequired,
};


export default CategorySourcesSelection;
