// field type enums
export const [
  TEXT,
  TEXT_AREA,
  DATE,
  INTEGER,
  DECIMAL,
  RADIO,
  CHECKBOX,
  SPECIAL,
] = [
  'Text',
  'TextArea',
  'Date',
  'Integer',
  'Decimal',
  'Radio',
  'CheckboxGroup',
  '__SPECIAL__',
];


export const SCHEMA_INPUT_MAP = {
  [TEXT]: 'TextField',
  [TEXT_AREA]: 'TextField',
  [DECIMAL]: 'DecimalField',
  [INTEGER]: 'IntegerField',
  [DATE]: 'DateField',
  [RADIO]: 'EnumField',
  [CHECKBOX]: 'CheckboxGroupField',
  [SPECIAL]: 'PreclearanceTable',
};


export const FIELD_TYPES = {
  [TEXT]: {
    value: TEXT,
    label: 'Short text',
    icon: 'short_text',
  },
  [TEXT_AREA]: {
    value: TEXT_AREA,
    label: 'Long text',
    icon: 'subject',
  },
  [INTEGER]: {
    value: INTEGER,
    label: 'Number',
    icon: 'numbers',
  },
  [DECIMAL]: {
    value: DECIMAL,
    label: 'Amount',
    icon: 'attach_money',
  },
  [DATE]: {
    value: DATE,
    label: 'Date',
    icon: 'calendar_today',
  },
  [CHECKBOX]: {
    value: CHECKBOX,
    label: 'Checkbox',
    icon: 'check_box',
  },
  [RADIO]: {
    value: RADIO,
    label: 'Multiple choice',
    icon: 'radio_button_checked',
  },
};

export const SPECIAL_FIELD = {
  value: SPECIAL,
  label: 'Preclearance',
  icon: 'task',
};

export const CHOICE_FIELDS = [RADIO, CHECKBOX];

export const CHOICE_ICON_MAP = {
  [CHECKBOX]: 'check_box_outline_blank',
  [RADIO]: 'radio_button_unchecked',
};

// droppable ids
export const [QUESTION_LIST_DROPPABLE, SPECIAL_LIST_DROPPABLE, FORM_BODY_DROPPABLE] = ['QUESTION_LIST_DROPPABLE', 'SPECIAL_LIST_DROPPABLE', 'FORM_BODY_DROPPABLE'];

// droppable types
// (used to prevent form element draggables from interacting with field choice draggables)
export const [FORM_ELEMENT, FIELD_CHOICE] = ['FORM_ELEMENT', 'FIELD_CHOICE'];
