// probs should rename
export const byId = (contribState, contributionIds) => (
  contributionIds.map(contribId => contribState.byId[contribId])
);

export const byMetaIds = (state, metaIds) => {
  const stateArr = Object.values(state.byId);
  return metaIds.reduce((contribs, id) => {
    contribs.push(stateArr.find(contrib => (
      // inconsistency in results serialized in BE in dashboard vs search obj result
      (contrib.meta.id === id) || (contrib.meta.contribution_id === id)
    )));
    return contribs;
  }, []);
};

export const getAllContributions = contribState => contribState.allIds.map(id => contribState.byId[id]);
