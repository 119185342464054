import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import LoadingBar from '../searchResults/loadingbar';
import { formatNames } from './header';


function constructExportUrl({ dateFilter, search, exportType }) {
  const baseUrl = `/api/${exportType}/${search.id}/export`;

  const params = [];
  const dfRef = dateFilter.to || dateFilter.from ? dateFilter : {};
  Object.keys(dfRef).forEach((key) => {
    if (key && dfRef && dfRef[key]) {
      params.push(`${key}=${dfRef[key]}`);
    }
  });
  if (params.length) {
    return `${baseUrl}?${params.join('&')}`;
  }
  return baseUrl;
}


function HeaderMainRow({
  personType,
  search,
  searchCompleted,
  resultCount,
  completedSourceCount,
  totalSourceCount,
  showProgressBar,
  editButton,
  dateFilter,
  alertButton,
  exportType,
  canExport,
  disableExports,
  exportDisabledMessage,
}) {
  const exportDisabled = !searchCompleted || disableExports;
  const { mainName } = formatNames(search);

  const isLoading = totalSourceCount === 0;

  return (
    <div className="search-header-main-row">
      <div className="person-type">{personType}</div>
      <div className="results-name-row">
        <h4>
          <div className="header-wrapper">
            <div className="name-wrapper">
              <div>
                <strong>{mainName}</strong>
              </div>
              {isLoading ? ''
                : <React.Fragment>
                  <div className="count-wrapper">
                    {resultCount.overrideLocaleString()}
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
          {showProgressBar && !isLoading && completedSourceCount !== 0 && totalSourceCount !== completedSourceCount ?
            <LoadingBar
              totalSourceCount={totalSourceCount}
              completedSourceCount={completedSourceCount}
            /> : ''
          }
        </h4>
        <div className="results-actions">
          { editButton }
          <div className="button-wrapper">
            { canExport &&
            <Tippy
              content={exportDisabledMessage}
              disabled={!disableExports}
            >
              <a
                className={`${exportDisabled ? 'disabled' : ''} text-button uppercase`}
                href={exportDisabled ? '' : constructExportUrl({ dateFilter, search, exportType })}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  return exportDisabled ? e.preventDefault() : null;
                }}
              >
                <i className="material-icons">
                  open_in_new
                </i>
                Export
              </a>
            </Tippy>
            }
          </div>
          { alertButton }
        </div>
      </div>
    </div>
  );
}

HeaderMainRow.propTypes = {
  totalSourceCount: PropTypes.number,
  completedSourceCount: PropTypes.number,
  resultCount: PropTypes.number.isRequired,
  searchCompleted: PropTypes.bool.isRequired,
  dateFilter: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  alertButton: PropTypes.object,
  exportDisabledMessage: PropTypes.string,
  disableExports: PropTypes.bool, // whether or not to disable export button
  canExport: PropTypes.bool.isRequired,
  exportType: PropTypes.string.isRequired, // 'jurisdictions', 'research', 'employees', 'entities'
  personType: PropTypes.string, // only for employee searches (SPOUSE / DEPENDENT)
  showProgressBar: PropTypes.bool,
  editButton: PropTypes.object,
};

HeaderMainRow.defaultProps = {
  alertButton: null,
  disableExports: false,
  totalSourceCount: 0,
  completedSourceCount: 0, // total/completed not needed from jurisdiction view bc it doesnt use loading bar
  personType: '',
  showProgressBar: true,
  editButton: null,
  exportDisabledMessage: '',
};


export default HeaderMainRow;
