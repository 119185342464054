import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { makeCancelable } from 'utils/cancelablePromise';
import { MATCHING_RECORD_LIMIT } from 'utils/constants';
import { SELECTION_COL, DESC, MONITORING, PRECLEARANCE, MONITORED_RECORD, PRE_REQUEST } from 'components/shared/matching/consts';
import MatchingContainerOnPre from 'components/shared/matching/matchingContainerOnPre';
import MatchingContainerOnMonitoring from 'components/shared/matching/matchingContainerOnMonitoring';
import Filters from './filters';
import ToggleWithLabel from '../toggleWithLabel';

const matchingComponentMap = {
  [MONITORING]: MatchingContainerOnMonitoring,
  [PRECLEARANCE]: MatchingContainerOnPre,
};

const recordType = {
  [MONITORING]: MONITORED_RECORD.toLowerCase(),
  [PRECLEARANCE]: PRE_REQUEST.toLowerCase(),
};

const defaultFilterState = {
  name: '',
  recipient: '',
  year: '',
};

const defaultSortState = {
  sortBy: 'date',
  sortDirection: DESC,
};

const makeYearOptions = (earliestYear, latestYear) => {
  const years = [{ id: '', year: 'All' }];
  for (let i = latestYear; i > earliestYear - 1; i--) {
    years.push({ id: i, year: i });
  }
  return years;
};
const currentYear = new Date().getFullYear();


class MatchingWrapper extends React.Component {
  constructor(props) {
    super(props);
    const filterState = Object.assign({}, defaultFilterState);
    const suggestedMatches = Boolean(this.props.automatches.length);
    filterState.name = suggestedMatches ? '' : props.defaultNameFilter || '';
    filterState.recipient = suggestedMatches ? '' : props.defaultRecipientFilter || '';
    filterState.year = suggestedMatches ? '' : props.defaultYearFilter || '';

    this.state = {
      loading: false,
      filters: filterState,
      filter_matched: false,
      sort: defaultSortState,
      selectedRow: null,
      suggestedMatches,
    };
    this.rows = [];
    this.columns = [];
    this.fetchRecords = this.fetchRecords.bind(this);
    this.fetchMoreRecords = this.fetchMoreRecords.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onToggleSuggestedMatches = this.onToggleSuggestedMatches.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleRowSelect = this.handleRowSelect.bind(this);
    this.markAsNoMatch = this.markAsNoMatch.bind(this);
    this.undoNoMatch = this.undoNoMatch.bind(this);
    this.createMatch = this.createMatch.bind(this);
    this.endCount = Number.MAX_SAFE_INTEGER;
    this.appliedFilters = Object.assign({}, this.state.filters);
    this.yearOptions = makeYearOptions((props.earliestYearOption || 2000), currentYear);
  }

  componentDidMount() {
    this.fetchRecords(0, this.state.filters);
  }

  componentWillUnmount() {
    this.cancelablePromise && this.cancelablePromise.cancel();
  }

  onFilterChange(field, value) {
    this.setState({
      filters: {
        ...this.state.filters,
        [field]: value,
      },
    });
  }

  onToggle(field, active) {
    this.setState({
      [field]: active,
    }, () => {
      this.fetchRecords(0, this.appliedFilters);
    });
  }

  onToggleSuggestedMatches(active) {
    let filters = Object.assign({}, this.state.filters);
    if (active) {
      filters = Object.assign({}, defaultFilterState);
    } else if (!active && !this.state.filters.name) {
      filters.name = this.props.defaultNameFilter;
    }
    this.setState({
      suggestedMatches: active,
      filters,
    }, () => {
      this.applyFilters();
    });
  }

  onSort({ sortBy, sortDirection }) {
    this.setState({
      sort: {
        sortBy,
        sortDirection,
      },
    }, () => {
      this.fetchRecords(0, this.appliedFilters);
    });
  }

  handleRowSelect(row) {
    this.setState({ selectedRow: row });
  }

  markAsNoMatch() {
    this.props.closeModal();
    this.props.markAsNoMatch(this.props.recordToBeMatched).then(() => {
      toast(`This ${recordType[this.props.viewType]} has been marked as "No Match"`);
    }).catch((err) => {
      toast(`Something went wrong and we were unable to mark this ${recordType[this.props.viewType]} as "No Match".`);
      throw err;
    });
  }

  undoNoMatch() {
    this.props.closeModal();
    this.props.undoNoMatch(this.props.recordToBeMatched).then(() => {
      toast(`This ${recordType[this.props.viewType]} is no longer marked as "No Match".`);
    }).catch((err) => {
      toast('Something went wrong.');
      throw err;
    });
  }

  createMatch() {
    if (!this.state.selectedRow) {
      toast(`Please select a record to match to this ${recordType[this.props.viewType]}.`);
      return;
    }
    this.props.closeModal();
    this.props.makeMatch(this.props.recordToBeMatched, this.state.selectedRow)
      .then(() => {
        toast('Match successful!');
      })
      .catch((err) => {
        toast('Something went wrong and we were unable to match records.');
        throw err;
      });
  }

  applyFilters() {
    const { filters, suggestedMatches } = this.state;
    if (!filters.name.trim() && !suggestedMatches) {
      toast('Please enter a name filter.');
      return;
    }
    this.fetchRecords(0, filters);
    this.appliedFilters = Object.assign({}, this.state.filters);
  }

  fetchMoreRecords() {
    this.fetchRecords(this.rows.length, this.appliedFilters);
  }

  fetchRecords(offset, filters) {
    if (this.state.loading) return;
    if (!offset) {
      this.rows = [];
      this.endCount = Number.MAX_SAFE_INTEGER;
    }
    this.setState({
      loading: true,
      selectedRow: null,
    });
    const { sort, filter_matched, suggestedMatches } = this.state;
    const recordId = suggestedMatches ? this.props.automatchId : '';

    this.cancelablePromise = makeCancelable(
      this.props.getPotentialMatches({ ...filters, filter_matched }, recordId, sort, offset),
    );
    this.cancelablePromise
      .promise
      .then(({ data }) => {
        this.rows = offset ? [...this.rows, ...data.rows] : data.rows;
        this.columns = [SELECTION_COL, ...data.columns];
        if (data.rows && data.rows.length < MATCHING_RECORD_LIMIT) {
          this.endCount = this.rows.length;
        }
        this.setState({ loading: false });
        const { matches } = this.props;
        if (suggestedMatches && !matches.length) {
          this.setState({ selectedRow: this.rows[0] });
        }
      }).catch((err) => {
        if (!err.isCanceled) {
          this.setState({ loading: false });
          toast('Something went wrong and we were unable to fetch records.');
          throw err;
        }
      });
  }

  render() {
    const { selectedRow, loading, filters, filter_matched, suggestedMatches, sort } = this.state;
    const { viewType } = this.props;
    const MatchingComponent = matchingComponentMap[this.props.viewType];

    const infinteLoaderProps = {
      loadMoreRows: this.fetchMoreRecords,
      endCount: this.endCount,
    };
    return (
      <MatchingComponent
        closeModal={this.props.closeModal}
        loading={loading}
        rows={this.rows}
        columns={this.columns}
        selectedRow={selectedRow && selectedRow.id}
        handleRowSelect={this.handleRowSelect}
        sort={sort}
        onSort={this.onSort}
        createMatch={this.createMatch}
        markAsNoMatch={this.markAsNoMatch}
        matches={this.props.matches}
        recordSchema={this.props.recordSchema} // only used on monitoring / dashboard
        {...infinteLoaderProps}
        recordToBeMatched={this.props.serializedRecord}
        noMatch={this.props.noMatch}
        undoNoMatch={this.undoNoMatch}
        toggleComponent={
          <ToggleWithLabel
            onToggle={() => { this.onToggle('filter_matched', !filter_matched); }}
            active={filter_matched}
            disabled={loading}
            label={`Only unmatched ${viewType === PRECLEARANCE ? 'records' : 'requests'}`}
          />
        }
        suggestedMatchToggle={
          <ToggleWithLabel
            onToggle={() => { this.onToggleSuggestedMatches(!suggestedMatches); }}
            active={suggestedMatches}
            disabled={loading}
            label={'Suggested matches'}
          />
        }
        filtersComponent={
          <Filters
            onFieldChange={this.onFilterChange}
            filters={filters}
            onFilter={this.applyFilters}
            loading={loading}
            yearOptions={this.yearOptions}
          />
        }
      />
    );
  }
}


MatchingWrapper.propTypes = {
  recordToBeMatched: PropTypes.object.isRequired,
  serializedRecord: PropTypes.object.isRequired,
  makeMatch: PropTypes.func.isRequired,
  markAsNoMatch: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultNameFilter: PropTypes.string,
  defaultRecipientFilter: PropTypes.string,
  defaultYearFilter: PropTypes.string,
  getPotentialMatches: PropTypes.func.isRequired,
  matches: PropTypes.array,
  automatches: PropTypes.array,
  viewType: PropTypes.string.isRequired,
  recordSchema: PropTypes.array,
  earliestYearOption: PropTypes.number,
  automatchId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  undoNoMatch: PropTypes.func.isRequired,
};

MatchingWrapper.defaultProps = {
  defaultNameFilter: '',
  defaultRecipientFilter: '',
  defaultYearFilter: '',
  matches: [],
  automatches: [],
  recordSchema: [],
  earliestYearOption: null,
  automatchId: null,
};

export default MatchingWrapper;
