import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DELETE_EMP_SEARCH } from 'utils/constants';
import { createEmployeeSearch, updateEmployeeSearch } from 'reducers/employees/actions';
import { allIds } from 'reducers/sources/selectors';
import { byId } from 'reducers/searchObjects/selectors';
import { byId as getEmployee } from 'reducers/employees/selectors';
import { openModal } from 'reducers/ui/modal/actions';
import { fields, defaultSearchState } from './consts';
import PersonSearchForm from './personSearchForm';


const formatSearch = (search, sourceIds) => {
  const formattedSearch = {};
  Object.values(fields).forEach((field) => {
    if (field === 'states') {
      formattedSearch[field] = Object.assign([], search[field]);
    } else if (field === 'sources') {
      // filter out sources not returned from api
      const sources = Array.from(search[field] || [])
        .filter(sourceId => sourceIds.includes(sourceId));
      formattedSearch[field] = new Set(sources);
    } else if (search[field] instanceof Array) {
      formattedSearch[field] = search[field].length ? Object.assign([], search[field]) : [''];
    } else if (search[field] === undefined) {
      // it's possible for searches to be missing fields like strict_last_name or exclude_employer
      formattedSearch[field] = defaultSearchState(sourceIds)[field];
    } else {
      formattedSearch[field] = search[field];
    }
  });
  return formattedSearch;
};


const mapStateToProps = (state, { match, searchType }) => {
  const { id } = match.params;
  const { sources } = state.sources;
  const sourceIds = allIds(sources);
  const initForm = defaultSearchState(sourceIds);

  return {
    sources,
    initForm,
    edit: false,
    employeeId: id,
    searchType,
  };
};

export const NewSearchForm = withRouter(connect(mapStateToProps, {
  submitSearch: createEmployeeSearch,
})(PersonSearchForm));


const editMapStateToProps = (state, { match }) => {
  const { sources } = state.sources;
  const sourceIds = allIds(sources);
  const { id, searchId } = match.params;
  const searchObject = byId(state, searchId);
  const searches = getEmployee(state, id).search_objects;
  // eslint-disable-next-line eqeqeq
  const searchMeta = searches.find((search) => search.search_object_id == searchId);

  const search = formatSearch({ ...searchObject }, sourceIds);

  return {
    sources,
    initForm: search,
    edit: true,
    employeeId: id,
    searchId,
    searchType: searchMeta.search_type,
    archived: searchMeta.archived,
  };
};

export const EditForm = withRouter(connect(editMapStateToProps, {
  submitSearch: updateEmployeeSearch,
  toggleConfirmDelete: searchId => (
    openModal({ modalType: DELETE_EMP_SEARCH, modalProps: { searchId } })
  ),
})(PersonSearchForm));
