import React from 'react';

const LoadingMore = () => (
  <div className="loading-wrapper">
    <span>Loading more records</span>
    <span className="loader__dot">.</span>
    <span className="loader__dot">.</span>
    <span className="loader__dot">.</span>
  </div>
);

export default LoadingMore;
