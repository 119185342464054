import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import Tippy from '@tippyjs/react';
import {
  FIELD_TYPES,
  SPECIAL_FIELD,
  QUESTION_LIST_DROPPABLE,
  SPECIAL_LIST_DROPPABLE,
  FORM_ELEMENT,
} from '../consts';
import QuestionItemContent from './QuestionItemContent';
import DraggableItem from './DraggableItem';


function Sidebar({ disableSpecialField }) {
  return (
    <div className="form-builder-sidebar">
      <div className="form-builder-sidebar-section">
        <div className="uppercase sidebar-heading">
          Questions
        </div>
        <Droppable
          droppableId={QUESTION_LIST_DROPPABLE}
          type={FORM_ELEMENT}
          isDropDisabled
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {Object.values(FIELD_TYPES).map(({ label, icon, value }, index) => (
                <DraggableItem
                  key={label}
                  index={index}
                  draggableId={value}
                >
                  <QuestionItemContent
                    icon={icon}
                    label={label}
                  />
                </DraggableItem>
              ))}
              { provided.placeholder }
            </div>
          )}
        </Droppable>
      </div>
      <div className="form-builder-sidebar-section">
        <div className="uppercase sidebar-heading">
          Dynamic content
          <Tippy
            content="Pull in dynamic content from the platform for employee review."
          >
            <i className="material-icons">
              info
            </i>
          </Tippy>
        </div>
        <Droppable
          droppableId={SPECIAL_LIST_DROPPABLE}
          type={FORM_ELEMENT}
          isDropDisabled
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <DraggableItem
                key={SPECIAL_FIELD.label}
                index={0}
                draggableId={SPECIAL_FIELD.value}
                isDragDisabled={disableSpecialField}
              >
                <Tippy
                  content="Only one preclearance module is allowed in the form."
                  disabled={!disableSpecialField}
                >
                  <div>
                    <QuestionItemContent
                      icon={SPECIAL_FIELD.icon}
                      label={SPECIAL_FIELD.label}
                    />
                  </div>
                </Tippy>
              </DraggableItem>
              { provided.placeholder }
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  disableSpecialField: PropTypes.bool.isRequired,
};

export default Sidebar;
