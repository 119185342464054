import React from 'react';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';


export const SelectFilter = ({ label, choices }) => (
  <SchemaField
    id="filter"
    label="Filter"
    fieldType="SelectField"
    title={`Filter by ${label}`}
    className="form-input"
    choices={choices}
    required
  />
);
