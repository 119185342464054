import * as types from './types';

const byId = (state = {}, action) => {
  switch (action.type) {
    case types.RECEIVE_ENTITIES: {
      const nextState = { ...state };
      action.entities.forEach((entity) => {
        nextState[entity.id] = entity;
      });
      return nextState;
    }
    case types.EDIT_ENTITY:
    case types.ADD_ENTITY: {
      return {
        ...state,
        [action.entity.id]: action.entity,
      };
    }
    case types.REMOVE_ENTITY: {
      const nextState = { ...state };
      delete nextState[action.id];
      return nextState;
    }
    default: return state;
  }
};

export default byId;

export const getEntity = (state, id) => state[id];
