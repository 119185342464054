import { toast } from 'react-toastify';

import * as types from './types';
import api from '../../api';
import { createContrib } from '../contributions/actions';

const trackUploadInStore = (fileObj, uploader, contribId) => ({
  type: types.ADD_UPLOAD,
  name: fileObj.name,
  uploader,
  contribId,
  fileObj,
});

const untrackUploadInStore = (contribId, fileName) => ({
  type: types.REMOVE_UPLOAD,
  contribId,
  fileName,
});

const updateUploadInStore = (fileName, contribId, progress, hasError) => ({
  type: types.UPDATE_PROGRESS,
  contribId,
  fileName,
  progress,
  hasError: !!hasError,
});

const getUploadUrl = (fileName, contribId) => {
  return api.post(`/contributions/${contribId}/get_upload_permissions/`, {
    filename: fileName,
  }).catch((err) => {
    toast(err.response.data);
    throw err;
  });
};

export const addUpload = (fileObj, contribId) => (dispatch) => {
  let workingId = contribId;
  return (new Promise((resolve) => {
    // this is broken in jurisdiction view
    if (isNaN(contribId)) {
      return createContrib(contribId)(dispatch).then((data) => {
        resolve(data);
      });
    } else {
      return resolve(null);
    }
  })).then(response => {
    if(response) {
      workingId = response.meta.id;
    }
    return getUploadUrl(fileObj.name, workingId);
  }).then(({data}) => {
    return new Promise((resolve, reject) => {
      const uploader = new XMLHttpRequest();
      uploader.open("POST", data.url);

      uploader.addEventListener("load", function(event) {
        // This feels pretty hacky, but for some reason, the actual error event
        // isn't firing if the request fails.
        setTimeout(_ => {
          // If we're in the jurisdiction view and editing a record with no
          // contribution data, we're still using the record ID as a key.
          // This keeps us from dealing with possible complexity on active/inactive
          // states when a contribution status magically comes into view.
          if(event.target.status >= 400) {
            const errMsg = event.target.responseXML.querySelector("Message").innerHTML;
            toast(errMsg);
            dispatch(updateUploadInStore(fileObj.name, workingId, 0, true));
            return reject();
          }
          else {
            // Hacky as shit, but needed to prevent race condition of cancelling
            // the upload before it completes.
            if(event.target.status === 0) {
              dispatch(untrackUploadInStore(workingId, fileObj.name));
              return reject();
            }
            return api.patch(`/contributions/${workingId}/check_file/`, {
              filename: fileObj.name
            }).then(_ => {
              dispatch(updateUploadInStore(fileObj.name, workingId, 100, false));
              return resolve();
            }).catch(e => {
              toast(e.response.data);
              dispatch(updateUploadInStore(fileObj.name, workingId, 0, true));
              return reject();
            });
          }
        }, 1000);
      })
      uploader.addEventListener("progress", function(progEvent) {
        if(progEvent.lengthComputable) {
          // TODO: In the FileItem component, make the transition trigger fire on
          // receiving a dl-able link, as opposed to getting to 100%.
          const percentage = Math.min(progEvent.loaded / progEvent.total * 100, 99);
          dispatch(updateUploadInStore(fileObj.name, workingId, percentage));
        }
      });

      dispatch(trackUploadInStore(fileObj, uploader, workingId));

      const formData = new FormData();
      Object.keys(data.fields).forEach(key => {
        formData.append(key, data.fields[key]);
      });
      formData.append("file", fileObj);

      uploader.send(formData);
    });
  });
};

export const removeUpload = (fileName, contribId) => (dispatch, getState) => {
  const uploadsForContrib = getState().uploads[contribId];
  if (uploadsForContrib) {
    const fileIndex = uploadsForContrib.findIndex(storedFile => storedFile.name === fileName);
    if (fileIndex < 0) {
      return toast.error('File was not found for this contribution.');
    }
    if (fileIndex >= 0 && uploadsForContrib[fileIndex].uploadObj) {
      uploadsForContrib[fileIndex].uploadObj.abort();
    }
    if (uploadsForContrib[fileIndex].hasError) {
      return dispatch(untrackUploadInStore(contribId, fileName));
    }
  }
  return api.delete(`/contributions/${contribId}/delete_attachment/`, {
    params: { filename: fileName }
  }).then(() => {
    dispatch(untrackUploadInStore(contribId, fileName));
  }).catch((err) => {
    toast(err.response.data);
    throw err;
  });
};

export const retryUpload = (file, contribId) => (dispatch, getState) => {
  const filesForContribution = getState().uploads[contribId] || [];
  const fileToRetry = filesForContribution.find(contribFile => file.name === contribFile.name);
  if (!fileToRetry) {
    return;
  }
  dispatch(updateUploadInStore(file.name, contribId, 0, false));
  setTimeout(() => {
    getUploadUrl(file.name, contribId).then(({data}) => {
      fileToRetry.uploadObj.open('POST', data.url);
      const formData = new FormData();
      Object.keys(data.fields).forEach((key) => {
        formData.append(key, data.fields[key]);
      });
      formData.append('file', file);
      fileToRetry.uploadObj.send(formData);
    });
  }, 1000);
};

export const getUpload = (fileName, contribId) => (dispatch) => {
  return api.post(`/contributions/${contribId}/get_download_permissions/`, {
    filename: fileName,
  }).catch((err) => {
    toast(err.response.data);
    throw err;
  });
};
