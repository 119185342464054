import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_DATE_RANGE } from 'utils/constants';
import { CustomDropdown } from 'components/dropdown';
import { DropdownOption, DropdownButton } from 'components/shared/objectDropdown';
import CustomDateInput from 'components/filtersDropdown/customDateInput';
import { validateDateRange } from 'components/filtersDropdown/filtersDropdown';


export function DateFilterDropdown({
  disabled, dropdownText, dateFilter, selectDate, options, disabledDateOptions,
  customDate, globalDateFilter,
}) {
  const [error, setError] = useState('');
  const [customDateInput, setCustomDate] = useState({ ...customDate });

  function validateDates(closeCb) {
    const err = validateDateRange(customDateInput, globalDateFilter);
    if (err) {
      setError(err);
      return;
    }
    selectDate(CUSTOM_DATE_RANGE, customDateInput);
    setError('');
    closeCb();
  }

  const buttonText = dropdownText === CUSTOM_DATE_RANGE ? `${customDate.from || 'Any'} - ${customDate.to || 'Any'}` : dropdownText;

  return (
    <CustomDropdown
      render={({ isOpen, openDropdown, closeDropdown, dropdownRef }) => (
        <div className="dropdown">
          <DropdownButton
            disabled={disabled}
            dropdownText={buttonText}
            isOpen={isOpen}
            onClick={openDropdown}
          />
          {isOpen ?
            <div className="dropdown-container" ref={dropdownRef}>
              {options.map(option => (
                <DropdownOption
                  key={option}
                  value={option}
                  selected={option === dateFilter}
                  disabled={disabledDateOptions.includes(option)}
                  onClick={() => {
                    selectDate(option);
                    setError('');
                    setCustomDate({ to: '', from: '' });
                    closeDropdown();
                  }}
                >
                  { option }
                </DropdownOption>
              ))}
              <CustomDateInput
                customDate={customDateInput}
                handleSelect={() => {}}
                handleFieldChange={(_field, value) => {
                  setCustomDate(value);
                }}
              />
              <div className="custom-date-submit">
                <button
                  className="text-button uppercase"
                  onClick={() => { validateDates(closeDropdown); }}
                >
                  Filter
                </button>
                <div className="error-text">
                  { error }
                </div>
              </div>
            </div>
            : null}
        </div>
      )}
    />
  );
}


DateFilterDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  dropdownText: PropTypes.string.isRequired,
  dateFilter: PropTypes.string.isRequired,
  selectDate: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabledDateOptions: PropTypes.array,
  customDate: PropTypes.object.isRequired,
  globalDateFilter: PropTypes.string,
};

DateFilterDropdown.defaultProps = {
  globalDateFilter: null,
  disabledDateOptions: [],
};
