import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { hasExportPermissions } from 'helpers/permissions';
import { BASE_MONITORING_URL } from 'utils/constants';
import { RequestTrackerCanceler, CONTRIBUTIONS_REQ_ID } from 'utils/apiUtil';
import { OrderStack, DATE, NAME } from 'utils/dashboardQueryUtil';
import Modal from 'components/modal/modal';
import Loading from 'components/loading';
import WorkFlowUI from 'components/workFlowUI';
import ScrollToTop from 'components/dashboard/scrollToTop';
import StatusDropdown from 'components/dashboard/statusDropdown';
import SortButtons from 'components/dashboard/sortButtons';
import CategoryFilters from 'components/dashboard/categoryFilters';
import FiltersDropdown from 'components/filtersDropdown/filtersDropdownContainer';
import DashboardResults from 'components/dashboard/dashboardResults';
import EmptyState from 'components/shared/emptyState';
import NoResults from 'components/shared/searchResults/noresults';
import CandidateTooltipBody from 'components/shared/candidateTooltipBody';

import { getContributions } from 'reducers/contributions/actions';
import { changeFilter, setCatFilter } from 'reducers/ui/dashboard/actions';
import { setDateFilter, toggleContribution, closeModal } from 'reducers/ui/actions';
import { clearResults } from 'reducers/results/actions';
import IconTextButton from '../monitoringForm/iconTextButton';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 1,
    };

    this.setFilter = this.setFilter.bind(this);
    this.constructExportUrl = this.constructExportUrl.bind(this);
    this.getFreshContributions = this.getFreshContributions.bind(this);
    this.getMoreContributions = this.getMoreContributions.bind(this);
    this.renderResults = this.renderResults.bind(this);
    this.toggleOrder = this.toggleOrder.bind(this);

    this.orderStack = new OrderStack([DATE, NAME]);
    this.ordering = '';
  }


  componentDidMount() {
    const defaultStatus = Object.values(this.props.statuses).find(status => status.default);
    this.props.changeFilter(defaultStatus);
    this.props.getContributions('set', defaultStatus, this.props.sourceCategoryFilter, this.props.dateFilter)
      .finally(() => {
        this.setState({ loading: this.state.loading - 1 });
      });
  }


  componentDidUpdate(prevProps) {
    const { sourceCategoryFilter, dateFilter } = this.props;
    const fields = ['to', 'from'];
    const dateFilterChanged = fields.some(
      (field) => prevProps.dateFilter[field] !== dateFilter[field],
    );
    const categoryFilterChanged = prevProps.sourceCategoryFilter !== sourceCategoryFilter;
    if (dateFilterChanged || categoryFilterChanged) {
      this.getFreshContributions();
    }
  }


  componentWillUnmount() {
    this.props.toggleContribution(this.props.selected, false);
    this.props.setCatFilter(null);
    this.props.setDateFilter(null);
    this.props.clearResults();
    this.props.closeModal();
    this.props.changeFilter(null);
    RequestTrackerCanceler.cancelByRequestId(CONTRIBUTIONS_REQ_ID);
  }

  getFreshContributions() {
    this.props.toggleContribution(this.props.selected, false);
    this.setState({
      loading: this.state.loading + 1,
    }, () => {
      const { sourceCategoryFilter, filter, dateFilter } = this.props;
      this.props.getContributions('set', filter, sourceCategoryFilter, dateFilter, this.ordering, 0)
        .finally(() => this.setState({ loading: this.state.loading - 1 }));
    });
  }

  getMoreContributions(offset) {
    const { sourceCategoryFilter, filter, dateFilter } = this.props;
    return this.props.getContributions('append', filter, sourceCategoryFilter, dateFilter, this.ordering, offset).then((data) => {
      return data;
    });
  }

  setFilter(filter) {
    if ((filter === null && this.props.filter === null) || (filter && this.props.filter && filter.id === this.props.filter.id)) {
      return;
    }
    this.props.changeFilter(filter);
    this.getFreshContributions();
  }

  toggleOrder(orderName) {
    this.orderStack.toggleOrder(orderName);
    this.ordering = this.orderStack.buildQuery();
    if (this.orderTimeout) {
      clearTimeout(this.orderTimeout);
    }
    this.orderTimeout = setTimeout(() => this.getFreshContributions(), 100);
  }

  constructExportUrl() {
    const {
      dateFilter, filter, sourceCategoryFilter, categories,
    } = this.props;
    const baseUrl = '/api/contributions/export/';
    const params = [];
    if (filter) {
      params.push(`status=${filter.id}`);
    }
    const dfRef = dateFilter.to || dateFilter.from ? dateFilter : {};
    Object.keys(dfRef).forEach((key) => {
      if (key && dfRef && dfRef[key]) {
        params.push(`${key}=${dfRef[key]}`);
      }
    });
    if (sourceCategoryFilter) {
      params.push(`category=${sourceCategoryFilter}`);
    } else {
      // must send all categories as params otherwise export will be blank
      Object.values(categories).forEach(({ id }) => params.push(`category=${id}`));
    }
    if (params.length) {
      return `${baseUrl}?${params.join('&')}`;
    }
    return baseUrl;
  }

  renderResults() {
    if (this.state.loading) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }
    const {
      dateFilter, sourceCategoryFilter, filter, statuses, categories, noContribs,
    } = this.props;
    const filterText = filter ? statuses[filter.id] : null;
    const dateFilterSet = !!(dateFilter.to || dateFilter.from);
    const filtersSet = filterText !== null || dateFilterSet || sourceCategoryFilter;

    if (noContribs && !(filtersSet)) {
      return (
        <EmptyState
          icon="search"
          messageHeading="No records yet."
          message="This is where you will see all new records for the people and companies you are monitoring as they appear in our system."
        >
          <Link to={`/app/${BASE_MONITORING_URL}`}>
            <button
              className="orange-button"
              type="button"
            >
              Add person
            </button>
          </Link>
        </EmptyState>
      );
    }

    const noResultsDashboard = (
      <NoResults
        dateFilter={dateFilter}
        render={({ dateFilterText }) => {
          const statusFilterText = filterText ? ` marked as "${filterText.label}"` : '';
          const categoryFilterText = sourceCategoryFilter ? ` in ${categories[sourceCategoryFilter].name}` : '';
          return (
            <span>
              No records found
              {statusFilterText}
              {categoryFilterText}
              {dateFilterText}
              .
            </span>
          );
        }}
      />
    );

    if (noContribs && filtersSet) {
      return noResultsDashboard;
    }

    return (
      <CandidateTooltipBody>
        <DashboardResults
          filter={this.props.filter}
          toggleContribution={this.props.toggleContribution}
          selected={this.props.selected}
          getContributions={this.getMoreContributions}
          statuses={this.props.statuses}
          noResultsDashboard={noResultsDashboard}
        />
      </CandidateTooltipBody>
    );
  }

  render() {
    const {
      sourceCategoryFilter,
      filter,
      statuses,
      categories,
      canExport,
      openInsights,
    } = this.props;
    const disableExport = !canExport || (!filter || filter.default);

    return (
      <WorkFlowUI>
        <div className="dashboard-wrapper">
          <div className="flex-header dashboard-header">
            <div className="flex-header">
              <SortButtons toggleOrder={this.toggleOrder} />
              <StatusDropdown
                filter={filter}
                options={Object.values(statuses)}
                setFilter={this.setFilter}
              />
              <FiltersDropdown />
              <CategoryFilters
                filter={sourceCategoryFilter}
                options={categories}
                setFilter={this.props.setCatFilter}
              />
            </div>
            <div className="flex-header flex-shrink-0">
              {
                disableExport
                  ? ''
                  : (
                    <a
                      className="text-button uppercase export-button"
                      href={this.constructExportUrl()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="material-icons">
                        open_in_new
                      </i>
                      Export
                    </a>
                  )
              }
              <IconTextButton
                icon="insights"
                buttonHandler={openInsights}
                buttonText="insights"
              />
            </div>
          </div>

          { this.renderResults() }
          <ScrollToTop />
        </div>
        <Modal />
      </WorkFlowUI>
    );
  }
}

const mapStateToProps = (state) => ({
  noContribs: state.contributions.allIds.length === 0,
  filter: state.ui.dashboard.filter,
  dateFilter: state.ui.dateFilter,
  selected: state.ui.selectedContribs,
  jurisdictions: state.jurisdictions,
  statuses: state.statuses,
  sourceCategoryFilter: state.ui.dashboard.sourceCategoryFilter,
  categories: state.categories,
  canExport: hasExportPermissions(state.user),
});

export default connect(
  mapStateToProps,
  {
    getContributions,
    setDateFilter,
    toggleContribution,
    changeFilter,
    setCatFilter,
    clearResults,
    closeModal,
  },
)(Dashboard);

Dashboard.propTypes = {
  filter: PropTypes.object,
  dateFilter: PropTypes.object.isRequired,
  noContribs: PropTypes.bool.isRequired,
  getContributions: PropTypes.func.isRequired,
  toggleContribution: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired,
  changeFilter: PropTypes.func.isRequired,
  setCatFilter: PropTypes.func.isRequired,
  setDateFilter: PropTypes.func.isRequired,
  statuses: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  sourceCategoryFilter: PropTypes.number,
  clearResults: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  canExport: PropTypes.bool.isRequired,
  openInsights: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  filter: null,
  sourceCategoryFilter: null,
};
