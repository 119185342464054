import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/toolTip';
import { onCopyText } from 'helpers/helpers';

export const EmbedCode = ({ onCancel, formUrl }) => {
  const textInput = React.createRef();
  return (
    <div>
      <textarea
        ref={textInput}
        className="code-snippet"
        value={`<iframe class="embed-form" src=${formUrl} frameborder="0" onmousewheel="" width="100%" height="533" ></iframe>`}
        readOnly
      />
      <div className="button-wrapper">
        <button className="uppercase text-button" onClick={onCancel}>
          Cancel
        </button>
        <button className="orange-button" onClick={() => { onCopyText(textInput.current); }}>
          Copy
        </button>
      </div>
    </div>
  );
};

EmbedCode.propTypes = {
  onCancel: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
};


export const Link = ({ onCancel, formUrl }) => {
  const textInput = React.createRef();
  return (
    <div>
      <input
        ref={textInput}
        value={formUrl}
        readOnly
      />
      <div className="button-wrapper">
        <button className="uppercase text-button" onClick={onCancel}>
          Cancel
        </button>
        <button className="orange-button" onClick={() => { onCopyText(textInput.current); }}>
          Copy
        </button>
      </div>
    </div>
  );
};

Link.propTypes = {
  onCancel: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
};


class ShareTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.options[0],
    };
    this.onSelectOption = this.onSelectOption.bind(this);
  }

  onSelectOption(option) {
    this.setState({ selectedOption: option });
  }

  render() {
    const { selectedOption } = this.state;
    const { options, bodyMap, iconMap } = this.props;
    const BodyComponent = bodyMap[selectedOption];

    return (
      <Tooltip position={'bottom'}>
        <div>
          <h2>Share Form</h2>
          <div className="tooltip-body">
            <div className="share-header">
              <span>Share via:</span>
              { options.map((option, i) => (
                <button
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  onClick={() => { this.onSelectOption(option); }}
                  className={`${selectedOption === option ? 'selected ' : ''}text-button`}
                >
                  <i className="material-icons">{iconMap[option]}</i>
                </button>
              ),
              )}
            </div>
            <div>
              <h3>{ selectedOption }</h3>
              <BodyComponent
                onCancel={this.props.onCancel}
                formUrl={this.props.formUrl}
                userFullName={this.props.userFullName}
                fromEmail={this.props.fromEmail}
              />
            </div>
          </div>
        </div>
      </Tooltip>
    );
  }
}

ShareTooltip.propTypes = {
  onCancel: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  bodyMap: PropTypes.object.isRequired,
  iconMap: PropTypes.object.isRequired,
  fromEmail: PropTypes.string.isRequired,
};


export default ShareTooltip;
