import React from 'react';
import PropTypes from 'prop-types';

import { Column, Table } from '../../shared/SimpleTable';

const EmptyState = ({ certificationPeriod }) => (
  <div className="simple-table-wrapper empty">
      No preclearance requests with dates between <br />{certificationPeriod}
  </div>
);

EmptyState.propTypes = {
  certificationPeriod: PropTypes.string.isRequired,
};

const PreclearanceTable = ({ data, columns, certificationPeriod }) => (
  data && data.length ? (
    <Table data={data} style={{ width: '100%' }}>
      {
        columns.map(col => (
          <Column
            key={col.key}
            dataKey={col.key}
            label={col.label}
            type={col.type}
          />
        ))
      }
    </Table>
  )
    :
    <EmptyState certificationPeriod={certificationPeriod} />
);

PreclearanceTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      amount: PropTypes.number,
      recipient: PropTypes.string,
      office_sought: PropTypes.string,
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  certificationPeriod: PropTypes.string.isRequired,
};

export { PreclearanceTable };
