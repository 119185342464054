import { batchActions } from 'redux-batched-actions';
import api from '../../../api';
import { receiveActions } from '../autoActions/actions';
import * as types from '../types';

const loadPreStatuses = data => ({
  data,
  type: types.LOAD_PRE_STATUSES,
});

const addPreStatus = data => ({
  data,
  type: types.ADD_PRE_STATUS,
});

const updatePreStatus = data => ({
  data,
  type: types.UPDATE_PRE_STATUS,
});

const deletePreStatus = id => ({
  id,
  type: types.DELETE_PRE_STATUS,
});


export const fetchPreStatuses = () => dispatch =>
  api.get('/pre/statuses/')
    .then(({ data }) => {
      dispatch(loadPreStatuses(data));
      return data;
    });

export const createPreStatus = newStatus => dispatch =>
  api.post('/pre/statuses/', newStatus)
    .then(({ data }) => {
      dispatch(addPreStatus(data));
      return data;
    });

export const editPreStatus = newStatus => dispatch =>
  api.put(`/pre/statuses/${newStatus.id}/`, newStatus)
    .then(({ data }) => {
      dispatch(updatePreStatus(data));
      return data;
    });

export const removePreStatus = id => dispatch =>
  api.delete(`/pre/statuses/${id}/`)
    .then(({ data }) => dispatch(batchActions([
      deletePreStatus(id),
      receiveActions(data),
    ])));
