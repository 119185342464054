export const RECEIVE_ROWS = 'RECEIVE_ROWS';
export const RECEIVE_ROW = 'RECEIVE_ROW';
export const REMOVE_ROW = 'DELETE_ROW';
export const RECEIVE_COLUMNS = 'RECEIVE_COLUMNS';
export const RECEIVE_COLUMN = 'RECEIVE_COLUMN';
export const REMOVE_COLUMN = 'REMOVE_COLUMN';
export const RECEIVE_INITIAL_DATA = 'RECEIVE_INITIAL_DATA';

export const RECEIVE_INITIAL_SETTINGS_DATA = 'RECEIVE_INITIAL_SETTINGS_DATA';

// statuses
export const LOAD_PRE_STATUSES = 'LOAD_PRE_STATUSES';
export const ADD_PRE_STATUS = 'ADD_PRE_STATUS';
export const UPDATE_PRE_STATUS = 'UPDATE_PRE_STATUS';
export const DELETE_PRE_STATUS = 'DELETE_PRE_STATUS';

// notifications
export const LOAD_PRE_NOTIFICATIONS = 'LOAD_PRE_NOTIFICATIONS';
export const ADD_PRE_NOTIFICATION = 'ADD_PRE_NOTIFICATION';
export const UPDATE_PRE_NOTIFICATION = 'UPDATE_PRE_NOTIFICATION';
export const DELETE_PRE_NOTIFICATION = 'DELETE_PRE_NOTIFICATION';

// auto actions
export const LOAD_AUTO_ACTIONS = 'LOAD_AUTO_ACTIONS';
export const RECEIVE_AUTO_ACTIONS = 'RECEIVE_AUTO_ACTIONS';
export const ADD_AUTO_ACTION = 'ADD_AUTO_ACTION';
export const UPDATE_AUTO_ACTION = 'UPDATE_AUTO_ACTION';
export const DELETE_AUTO_ACTION = 'DELETE_AUTO_ACTION';

// match actions
export const LOAD_MATCH_ACTIONS = 'LOAD_MATCH_ACTIONS';
export const ADD_MATCH_ACTION = 'ADD_MATCH_ACTION';
export const UPDATE_MATCH_ACTION = 'UPDATE_MATCH_ACTION';
export const DELETE_MATCH_ACTION = 'DELETE_MATCH_ACTION';
export const MATCH_ACTION = 'pre/MATCH_ACTION'; // used to update status of record when match is made via websockets

// templates
export const LOAD_TEMPLATES = 'LOAD_TEMPLATES';
export const RECEIVE_TEMPLATE = 'RECEIVE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

// ui
export const OPEN_PRE_MODAL = 'OPEN_PRE_MODAL';
export const CLOSE_PRE_MODAL = 'CLOSE_PRE_MODAL';
export const SELECT_ROW = 'SELECT_ROW';

// uploads
export const ADD_UPLOAD = 'preclearance/uploads/ADD_UPLOAD';
export const REMOVE_UPLOAD = 'preclearance/uploads/REMOVE_UPLOAD';
export const UPDATE_PROGRESS = 'preclearance/uploads/UPDATE_PROGRESS';
export const POPULATE_INITIAL = 'preclearance/uploads/POPULATE_INITIAL';

// table
export const UPDATE_EMAIL = 'preclearance/table/UPDATE_EMAIL';
export const UPDATE_TEMPLATE = 'preclearance/table/UPDATE_TEMPLATE';

// reports
export const ADD_REPORT = 'preclearance/reports/ADD_REPORT';
export const DELETE_REPORT = 'preclearance/reports/DELETE_REPORT';
export const SET_LOADED = 'preclearance/reports/SET_LOADED';
