import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS, PATH_NAMES } from 'utils/constants';
import ExpandableSection from 'components/shared/expandableSection';
import ActionSentence, { MatchesStatusSentence } from './automatedActions/actionItems/actionSentence';


export const RelatedCertificationsList = ({ relatedCertifications, isDeleted, message }) => {
  const numRelatedActions = relatedCertifications.length;
  const actionText = isDeleted ? 'affected' : 'updated';
  return (
    numRelatedActions ?
      <ExpandableSection
        title={
          <span className="flex-wrapper">
            <i className="orange-icon material-icons">
              sync
            </i>
            {numRelatedActions} {pluralize('certification', numRelatedActions)} will be {actionText}
          </span>
        }
      >
        { message &&
          <div className="help-text">{ message }</div>
        }
        <ul>
          {
            relatedCertifications.map(certification => (
              <li key={certification.name}>
                <Link className="name-link" to={`${PATHS[PATH_NAMES.certification_templates]}/${certification.id}`}>
                  {certification.name}
                </Link>
              </li>
            ))
          }

        </ul>
      </ExpandableSection>
      : null
  );
};

RelatedCertificationsList.propTypes = {
  relatedCertifications: PropTypes.array.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

RelatedCertificationsList.defaultProps = {
  message: '',
};


const RelatedActionsList = ({
  relatedActions,
  relatedMatchActions,
  requestStatuses,
  recordStatuses,
  templates,
  isDeleted,
}) => {
  const numRelatedActions = relatedActions.length + relatedMatchActions.length;
  const actionText = isDeleted ? 'disabled' : 'changed';
  return (
    numRelatedActions ?
      <ExpandableSection
        title={
          <span className="flex-wrapper">
            <i className="orange-icon material-icons">
              bolt
            </i>
            {numRelatedActions} {pluralize('action', numRelatedActions)} will be {actionText}
          </span>
        }
      >
        <ul>
          {
            relatedActions.map((action) => {
              const status = requestStatuses[action.status];
              const template = templates[action.template];
              return (
                <li key={action.id}>
                  <ActionSentence
                    status={status && status.label}
                    template={template && template.name}
                  />
                </li>
              );
            })
          }

          {
            relatedMatchActions.map((action) => {
              const reqStatus = requestStatuses[action.request_status];
              const recStatus = recordStatuses[action.record_status];
              return (
                <li key={`${action.id}-match`}>
                  <MatchesStatusSentence
                    requestStatus={reqStatus && reqStatus.label}
                    recordStatus={recStatus && recStatus.label}
                  />
                </li>
              );
            })
          }

        </ul>
      </ExpandableSection>
      : null
  );
};

RelatedActionsList.propTypes = {
  templates: PropTypes.object.isRequired,
  requestStatuses: PropTypes.object.isRequired, // preclearance statuses
  recordStatuses: PropTypes.object, // monitoring statuses
  relatedActions: PropTypes.array.isRequired,
  relatedMatchActions: PropTypes.array,
  isDeleted: PropTypes.bool.isRequired,
};

RelatedActionsList.defaultProps = {
  templates: {},
  recordStatuses: {},
  relatedActions: [],
  relatedMatchActions: [],
};

const mapStateToProps = ({ preclearance, statuses }) => ({
  requestStatuses: preclearance.statuses,
  templates: preclearance.templates,
  recordStatuses: statuses,
});

export const ActionsList = connect(mapStateToProps, {})(RelatedActionsList);
