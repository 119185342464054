import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import { SUPPORT_EMAIL } from 'utils/constants';


const LimitReachedTooltip = ({ type }) => (
  <div>
    Your account has reached the maximum number of
    {' '}
    {type}
    {' '}
    searches that can be added. Please reach out to us at
    {' '}
    <a className="orange-link" href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer">{SUPPORT_EMAIL}</a>
    {' '}
    to increase your limit.
  </div>
);

LimitReachedTooltip.propTypes = {
  type: PropTypes.string.isRequired,
};


const SyncTooltipMessage = () => (
  <div>
    Your organization has the ComplySci user sync enabled, please add any persons you wish to monitor in ComplySci and they will be added to illumis. Feel free to reach out to us at
    {' '}
    <a className="orange-link" href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer">{SUPPORT_EMAIL}</a>
    {' '}
    with any questions.
  </div>
);


const NewButton = ({
  disabled, type, style, url, syncEnabled,
}) => {
  const Tooltip = syncEnabled ? SyncTooltipMessage : LimitReachedTooltip;
  return (
    <Link to={url}>
      <div className="new-button-container" style={style}>
        <Tippy
          disabled={!disabled}
          content={<Tooltip type={type} />}
          interactive
        >
          <div className="new-button-wrapper">
            <button
              id={`${type}-add`}
              className="new-button text-button uppercase"
              disabled={disabled}
              type="button"
            >
              <i className={`icon-add-${type}`} />
              Add
              {' '}
              {type}
            </button>
          </div>
        </Tippy>
      </div>
    </Link>

  );
};

NewButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  style: PropTypes.object,
  syncEnabled: PropTypes.bool,
};

NewButton.defaultProps = {
  style: {},
  syncEnabled: false,
};

export default NewButton;
