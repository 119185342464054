import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'helpers/formatFieldHelpers';


export const DateValue = ({ value }) => (
  <span className="right-align">
    {formatDate(value)}
  </span>
);

DateValue.propTypes = {
  value: PropTypes.string.isRequired,
};
