/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { BASE_MONITORING_URL, PEOPLE } from 'utils/constants';
import EmptyState from 'components/shared/emptyState';
import Table from 'components/shared/SimpleVirtualTable';
import CertificationTableHeader from 'components/Certifications/CertificationsList/CertificationTableHeader';
import useSort from 'hooks/useSort';
import ResendCertification from './ResendCertification/ResendCertification';
import ResendCertificationEmail from './ResendCertification/ResendCertificationEmail';


const columns = [
  {
    sort: 'last_name',
    label: 'name',
  },
  {
    sort: 'email',
    label: 'email',
  },
  {
    sort: 'submitted_at',
    label: 'submitted',
  },
  {
    label: 'status',
  },
  {
    label: '',
  },
  {
    label: '',
  },
];


const Row = ({
  empId, name, email, submissionDate, submissionLink, submissionId, deleted, archived,
}) => {
  const empPageURL = `/app/${BASE_MONITORING_URL}/${PEOPLE}/${empId}`;
  const missingEmailMessage = deleted ? 'This employee has been deleted.' : (<div>This employee does not have an associated email address, please add their email address <Link to={empPageURL} className="orange-link">here</Link> and resend the form.</div>
  );

  return (
    <div className="certification-row">
      <div className={`certification-cell ${deleted ? 'deleted' : ''}`}>
        {
          empId && !deleted ? (
            <span>
              <Link to={empPageURL} className="name-link">
                <span>{ name }</span>
              </Link>
              { archived && <i className="deleted"> (archived)</i> }
            </span>
          )
            : (
              <span>
                { name }
                { ' ' }
                { deleted && <i>(deleted)</i> }
                { archived && !deleted && <i>(archived)</i> }
              </span>
            )
        }
      </div>
      <div className="certification-cell">
        {
          email
        ||
        (
          !deleted &&
          <Tippy
            content={missingEmailMessage}
            interactive
          >
            <div className="missing-data">
              <i className="material-icons">
                error_outline
              </i>
              <span>
                email missing
              </span>
            </div>
          </Tippy>
        )
        }
      </div>
      <div className="certification-cell">
        { submissionDate ? moment(submissionDate).format('ll') : '--' }
      </div>
      <div className="certification-cell">
        <div className={`submission-status uppercase ${submissionDate ? 'gray' : 'red'}`}>
          { submissionDate ? 'Complete' : 'Not submitted' }
        </div>
      </div>
      <div className="certification-cell">
        {
          submissionDate ?
            null
            :
            <ResendCertification
              disabled={!email}
              disabledText={missingEmailMessage}
              renderForm={props => (
                <ResendCertificationEmail
                  {...props}
                  submissionId={submissionId}
                />
              )
              }
            />
        }
      </div>
      <div className="certification-cell">
        {
          deleted && !submissionDate ?
            <Tippy
              content={'This employee has been deleted and did not fill out the certification form.'}
            >
              <div>
                <button className="deleted text-button uppercase submission-link" disabled>
                  view
                </button>
              </div>
            </Tippy>
            :
            <Link to={`${submissionLink}`} className="uppercase submission-link">
              <span>view</span>
            </Link>
        }

      </div>
    </div>
  );
};

Row.propTypes = {
  empId: PropTypes.number,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  submissionDate: PropTypes.string,
  submissionLink: PropTypes.string.isRequired,
  submissionId: PropTypes.number.isRequired,
  deleted: PropTypes.bool,
  archived: PropTypes.bool,
};

Row.defaultProps = {
  empId: null,
  email: null,
  submissionDate: null,
  deleted: false,
  archived: false,
};


function noRowsRenderer() {
  return (
    <EmptyState
      messageHeading={'No recipients.'}
      message={''}
    />
  );
}


function RecipientTable({ recipients }) {
  const { url } = useRouteMatch();
  const { submissionId } = useParams();
  const { sortDir, sortBy, sortedData: sortedRecipients, onSort } = useSort('submitted_at', recipients);

  function rowRenderer({ key, style, index }) {
    const {
      employee_id, first_name, last_name, email, submitted_at, id, is_deleted: deleted, archived,
    } = sortedRecipients[index];
    // eslint-disable-next-line eqeqeq
    const selected = submissionId == id;
    return (
      <div
        key={key}
        style={style}
        className={`certification-wrapper-row ${selected ? 'selected' : ''}`}
      >
        <Row
          empId={employee_id}
          name={`${last_name}, ${first_name}`}
          email={email}
          submissionDate={submitted_at}
          submissionLink={`${url}/submission/${id}`}
          submissionId={id}
          deleted={deleted}
          archived={archived}
        />
      </div>
    );
  }

  return (
    <div className="dashboard-card">
      <div>
        <CertificationTableHeader
          columns={columns}
          sortBy={sortBy}
          sortDir={sortDir}
          onSort={onSort}
        />
        <Table
          rows={sortedRecipients}
          rowRenderer={rowRenderer}
          noRowsRenderer={noRowsRenderer}
          rowHeight={66}
        />
      </div>
    </div>
  );
}

RecipientTable.propTypes = {
  recipients: PropTypes.array.isRequired,
};

export default RecipientTable;
