import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, useField, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

import api from '../../../../api';
import { FORM_ELEMENT } from '../constants';
import { Autofill } from '../utils/Autofill';

const AutocompleteField = ({
  dataUrl,
  queryParam,
  debounceTime,
  renderResult,
  autofill,
  fieldMap,
  autofillField,
  ...props
}) => {
  const [results, setResults] = useState([]),
    [isOpen, setIsOpen] = useState(false),
    [hasSelected, setHasSelected] = useState(true),
    [previousValue, setPreviousValue] = useState(null),
    [isFocused, setIsFocused] = useState(false),
    [field, meta, helpers] = useField(props),
    { setFieldValue, initialValues } = useFormikContext();

  const selectEl = useRef(null);

  const handleFocus = () => {
      setIsFocused(true);
      setPreviousValue(field.value);
      setHasSelected(false);
      if (field.value) {
        setIsOpen(true);
      }
    },
    handleBlur = (e) => {
      field.onBlur(e);
      setIsFocused(false);
    },
    handleSelect = (value) => {
      setHasSelected(true);
      helpers.setValue(value);
      setIsOpen(false);
    },
    handleCustomValue = () => {
      setHasSelected(true);
      setIsOpen(false);
      if (fieldMap) {
        Object.values(fieldMap)
          .filter(fieldId => fieldId !== field.name)
          .forEach((fieldId) => {
            setFieldValue(fieldId, initialValues[fieldId]);
          });
      }
    },
    querySource = useCallback(
      debounce((query) => {
        api.get(dataUrl, {
          params: {
            [queryParam]: query,
          },
        }).then((resp) => {
          if (resp.status === 200) {
            setResults(resp.data);
          }
        });
      }, debounceTime),
      [],
    );

    // Handle select open/close & API query
  useEffect(() => {
    if (!hasSelected && field.value) {
      setIsOpen(true);
      querySource(field.value);
    } else {
      setIsOpen(false);
    }
  }, [hasSelected, field.value]);

  // Reset value on leaving field if not cleared or selected
  useEffect(() => {
    if (!isFocused && !hasSelected && !isOpen) {
      helpers.setValue(previousValue);
    }
  }, [isOpen, isFocused, hasSelected, previousValue]);

  // Collapse on click outside
  useEffect(() => {
    const collapseSelect = (event) => {
      if (selectEl && selectEl.current && !selectEl.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', collapseSelect);
    return () => window.removeEventListener('click', collapseSelect);
  }, []);

  // Collapse on escape key
  useEffect(() => {
    const collapseSelect = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', collapseSelect);
    return () => window.removeEventListener('keydown', collapseSelect);
  }, []);

  return (
    <div ref={selectEl}>
      <div className="candidate-dropdown-wrapper">
        <Field
          onFocus={handleFocus}
          className="candidate-input"
          {...field}
          {...props}
          onBlur={handleBlur}
        />
      </div>
      <div
        className="dropdown-container"
        style={{ visibility: isOpen ? 'visible' : 'hidden' }}
      >
        <div className="options-wrapper">
          <div
            className="dropdown-options"
            style={{ maxHeight: 250, overflowY: 'auto' }}
          >
            {results.map(result => (
              <label key={result.id}>
                <button
                  type="button"
                  className="option"
                  onClick={() => handleSelect(result.id)}
                >
                  {renderResult(result)}
                </button>
              </label>
            ))}
            <label>
              <button
                type="button"
                className="option"
                onClick={handleCustomValue}
              >
                Add {field.value}
              </button>
            </label>
          </div>
        </div>
      </div>
      {autofill && (
        <Autofill
          value={field.value}
          fieldMap={fieldMap}
          field={autofillField}
        >
          {results}
        </Autofill>
      )}
    </div>
  );
};

AutocompleteField.propTypes = {
  ...FORM_ELEMENT,
  dataUrl: PropTypes.string.isRequired,
  queryParam: PropTypes.string,
  debounceTime: PropTypes.number,
  autofill: PropTypes.bool,
  autofillField: PropTypes.string,
  fieldMap: PropTypes.object,
  renderResult: PropTypes.func.isRequired,
};

AutocompleteField.defaultProps = {
  autofill: false,
  queryParam: 'query',
  debounceTime: 250,
  autofillField: 'id',
};

export { AutocompleteField };
