import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { updateUser } from 'reducers/user/actions';
import Section from 'components/shared/settingsPage/section';
import SettingsPage from 'components/shared/settingsPage/settingsPage';
import { updateOrganization } from 'reducers/organization/actions';

const userPolicyHelpText = 'Sets the maximum number of days you can have the same password before being forced to reset it. If set to 0 days (the default) you will never be forced to reset your password. We recommend setting this to 42 days, but you can choose any number under 1,000.';
const orgPolicyHelpText = 'Sets the maximum number of days a user can have the same password before being forced to reset it. If set to 0 days (the default) you will never be forced to reset your password. We recommend setting this to 42 days, but you can choose any number under 1,000.';

function PasswordExpiration({ editable, onChange, value, focus }) {
  return editable ? (
    <div key={'ppid'}className="password-policy-label">
      Password expires after <input
        key={'password-expire-input'}
        type={'number'}
        value={value}
        onChange={onChange}
        // hacky solution to this input losing focus due to being re-rendered
        // only from userPasswordPolicy bc of how it's rendered completely fresh
        // (in a function that gets called every render)
        autoFocus={focus}
      /> days
    </div>
  ) : <React.Fragment>
      Your organization has a policy of <span><strong>{value}</strong></span> days.
  </React.Fragment>;
}

PasswordExpiration.propTypes = {
  editable: PropTypes.bool.isRequired,
  focus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

PasswordExpiration.defaultProps = {
  focus: false,
};

// this file is kinda messy and can probably be improved.
// not using SimpleForm bc of how different the PasswordExpiration input is
class BasePasswordPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      policy: props.passwordExpiresAfter,
      focus: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.passwordExpiresAfter !== prevProps.passwordExpiresAfter) {
      this.setState({ policy: this.props.passwordExpiresAfter });
    }
  }

  onChange(e) {
    this.setState({ policy: e.target.value, focus: true });
  }

  onSubmit() {
    this.props.updatePolicy({ password_expires_after: this.state.policy }).then(() => {
      this.setState({ focus: false });
      toast('Password policy changed successfuly.');
    }).catch(() => toast('There was an issue updating your password policy.'));
  }

  render() {
    return (this.props.render({
      onChange: this.onChange,
      focus: this.state.focus,
      value: this.state.policy,
      onSubmit: this.onSubmit,
      disabled: this.state.policy === '',
    }));
  }
}

BasePasswordPolicy.propTypes = {
  updatePolicy: PropTypes.func.isRequired,
  passwordExpiresAfter: PropTypes.number,
  render: PropTypes.func.isRequired,
};

BasePasswordPolicy.defaultProps = {
  showLastChange: true,
  password_last_changed: '',
  passwordExpiresAfter: 0,
};

const orgPasswordPolicy = ({ updateOrganization, passwordExpiresAfter }) => (
  <BasePasswordPolicy
    passwordExpiresAfter={passwordExpiresAfter}
    updatePolicy={updateOrganization}
    render={({ value, onChange, onSubmit, disabled }) => (
      <Section
        header={'Update Policy'}
        helpText={<div>{orgPolicyHelpText}</div>}
      >
        <div className="password-policy">
          <PasswordExpiration
            editable
            value={value}
            onChange={onChange}
          />
          <button disabled={disabled} className="orange-button" onClick={onSubmit}>Apply Policy</button>
        </div>
      </Section>
    )}
  />
);

orgPasswordPolicy.propTypes = {
  passwordExpiresAfter: PropTypes.number.isRequired,
  updateOrganization: PropTypes.func.isRequired,
};

const orgMapStateToProps = ({ organization: { password_expires_after } }) => ({
  passwordExpiresAfter: Number(password_expires_after),
});


const userPaswordPolcy = ({ updateUser, editable, password_last_changed, passwordExpiresAfter }) => (
  <BasePasswordPolicy
    passwordExpiresAfter={passwordExpiresAfter}
    updatePolicy={updateUser}
    render={({ value, onChange, onSubmit, disabled, focus }) => {
      const section = () => (
        <Section
          helpText={<div>{userPolicyHelpText}</div>}
        >
          <div className="password-policy">
            <div>Last password change was <strong>{password_last_changed}</strong>.</div>
            <PasswordExpiration
              editable={editable}
              value={value}
              onChange={onChange}
              focus={focus}
            />
            {editable &&
              <button disabled={disabled} className="orange-button" onClick={onSubmit}>Apply Policy</button>
            }
          </div>
        </Section>
      );
      return (
        <SettingsPage
          header={'Password Policy'}
          sections={[section]}
        />
      );
    }}
  />
);

userPaswordPolcy.propTypes = {
  passwordExpiresAfter: PropTypes.number.isRequired,
  password_last_changed: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

const userMapStateToProps = ({ user: { password_expires_after, password_last_changed }, organization }) => ({
  passwordExpiresAfter: Number(organization.password_expires_after) || Number(password_expires_after),
  editable: !organization.password_expires_after, // user's policy is editable if no org-level one is set
  password_last_changed,
});


export const OrgPasswordPolicy = connect(orgMapStateToProps,
  { updateOrganization })(orgPasswordPolicy);

export default connect(userMapStateToProps, { updateUser })(userPaswordPolcy);
