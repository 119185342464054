import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { generateTokens, disableTwoFactor } from 'reducers/user/actions';
import Section from 'components/shared/settingsPage/section';
import ExpandableSection from 'components/shared/expandableSection';


const tokenHelp = (
  <p>If you don't have any devices with you, you can access your account using backup tokens.
    The backup tokens below can be used for login verification.
    If you've used up all your backup tokens, you can generate a new set of backup tokens.
  </p>
);

class BackupTokens extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.generateTokens = this.generateTokens.bind(this);
  }

  generateTokens() {
    this.props.generateTokens().then(() => {
      this.setState({ open: true });
    });
  }

  render() {
    const { backupTokens } = this.props;

    const tokenList = (<div>
      <ul>
        {backupTokens.map(token => <li data-private key={token}>{token}</li>)}
      </ul>
      <p>Print these tokens and keep them somewhere safe.</p>
      <button className="orange-button" onClick={this.generateTokens}>regenerate tokens</button>
    </div>);

    return (
      <Section
        containerClass={'two-factor-auth'}
        header={'Backup Tokens'}
        helpText={tokenHelp}
      >
        {backupTokens.length ?
          <React.Fragment>
            <p>You have {backupTokens.length} tokens remaining.</p>
            <ExpandableSection
              title={'Show Tokens'}
              startOpen={this.state.open}
            >
              {tokenList}
            </ExpandableSection>
          </React.Fragment> :
          <React.Fragment>
            <p>You don't have any backup tokens yet.</p>
            <button className="orange-button" onClick={this.generateTokens}>generate tokens</button>
          </React.Fragment>
        }
      </Section>
    );
  }
}

BackupTokens.propTypes = {
  backupTokens: PropTypes.array.isRequired,
  generateTokens: PropTypes.func.isRequired,
};

const mapStateToProps = ({ user: { backup_tokens } }) => ({
  backupTokens: backup_tokens,
});

export default connect(mapStateToProps, { generateTokens, disableTwoFactor })(BackupTokens);
