import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter, Redirect, Switch } from 'react-router-dom';
import pluralize from 'pluralize';
import { deserializeDate } from 'utils/serializers/dateSerializer';
import EntityForm, { EditEntityForm } from 'components/monitoringForm/entityForm/entityFormContainer';
import EmployeeRouter from 'components/employeePage/employeeRouter';
import EmployeeForm, { EditEmployeeForm } from 'components/employeePage/employeeForm/employeeFormContainer';
import EmployeeWrapper from 'components/employeePage/employeeWrapper';
import CandidateTooltipBody from 'components/shared/candidateTooltipBody';
import SearchObjectResults from 'components/monitoring/searchObjectView';
import { ENTITIES, MONITORING_ROUTES, BASE_MONITORING_URL, COMPANIES, EMPLOYEE_PATHS, PATH_NAMES } from 'utils/constants';
import ScrollToTop from 'components/shared/scrollToTop';


function Body(props) {
  const { selected, searchType, uiSearchType, dateFilter, match, disableAdd } = props;

  const from = dateFilter.from ? `${deserializeDate(dateFilter.from)}` : 'any date';
  const to = dateFilter.to ? `${deserializeDate(dateFilter.to)}` : 'any date';
  const filterText = dateFilter.from || dateFilter.to ? ` with dates from ${from} to ${to}` : '';

  const singularItem = pluralize.singular(uiSearchType);

  useEffect(() => {
    if (!props[searchType].length && !disableAdd) {
      props.history.push(`/app/${BASE_MONITORING_URL}/${uiSearchType}/new`);
    }
  }, []);

  return (
    <div className="monitoring-body-wrapper">
      <div className="monitoring-sidebar-body">
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path={`/app/${BASE_MONITORING_URL}/:searchType(${MONITORING_ROUTES.join('|')})`}
            render={() => (
              <div className="default">
                <div>
                  {`Select a ${singularItem} from the sidebar to see their records${filterText}.`}
                </div>
              </div>
            )}
          />
          <Route
            path={`/app/${BASE_MONITORING_URL}/:searchType/new`}
            render={() => {
              if (disableAdd) {
                return <Redirect to={`/app/${BASE_MONITORING_URL}/${match.params.searchType}`} />;
              }
              if (match.params.searchType === COMPANIES) {
                return <EntityForm />;
              }
              return <EmployeeForm formType={match.params.searchType} />;
            }}
          />

          <Route
            exact
            path={`/app/${BASE_MONITORING_URL}/:searchType(${COMPANIES})/:id`}
            render={() => {
              if (!selected) return <Redirect to={`/app/${BASE_MONITORING_URL}/${match.params.searchType}`} />;
              return (
                (<CandidateTooltipBody>
                  <div className="result-container-wrapper">
                    <SearchObjectResults
                      key={selected.search_object_id}
                      searchObjId={selected.search_object_id}
                      searchType={ENTITIES}
                      linkToEdit={`${match.url}/edit`}
                      alertsActive={selected.alerts_active}
                      id={selected.id}
                    />
                  </div>
                </CandidateTooltipBody>)
              );
            }}
          />

          <Redirect exact from={match.path} to={`${match.path}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`} />

          <Route
            path={`${match.path}/edit`}
            render={() => {
              if (!selected) {
                return <Redirect to={`/app/${BASE_MONITORING_URL}/${match.params.searchType}`} />;
              }
              if (match.params.searchType === COMPANIES) {
                return <EditEntityForm />;
              }
              return (
                <EmployeeWrapper>
                  <EditEmployeeForm />
                </EmployeeWrapper>
              );
            }}
          />


          <Route
            path={match.path}
            render={() => {
              if (!selected) return <Redirect to={`/app/${BASE_MONITORING_URL}/${match.params.searchType}`} />;
              return (
                <EmployeeWrapper>
                  <EmployeeRouter employee={selected} />
                </EmployeeWrapper>
              );
            }}
          />

        </Switch>

      </div>
    </div>
  );
}


Body.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  searchType: PropTypes.string.isRequired,
  employees: PropTypes.array.isRequired,
  entities: PropTypes.array.isRequired,
  uiSearchType: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  disableAdd: PropTypes.bool.isRequired,
  selected: PropTypes.object,
  history: PropTypes.object.isRequired,
};

Body.defaultProps = {
  selected: null,
};

export default withRouter(Body);
