import React from 'react';
import PropTypes from 'prop-types';
import ColumnForm from './ColumnForm';


const EditColumn = ({
  column,
  updateColumn,
  onCancel,
}) => {
  const { label } = column;
  return (
    <ColumnForm
      id={column.id}
      label={label}
      onSubmit={updateColumn}
      onCancel={onCancel}
      headerText="Edit Field"
    />
  );
};

EditColumn.propTypes = {
  column: PropTypes.object.isRequired,
  updateColumn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditColumn;
