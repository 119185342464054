import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Logo from 'components/svgs/logo';
import { PATHS, PATH_NAMES } from 'utils/constants';
import SettingsDropdown from './settingsDropdown';
import AppSwitcher from './AppSwitcher';


function NavBar({ location: { pathname }, showCertifications }) {
  function navLink(path, text) {
    return (
      <Link className={`text-button nav-link appLink ${pathname.includes(path) && 'active'}`} to={path}>{text}</Link>
    );
  }

  return (
    <header>
      <nav className="navbar navbar-toggleable-md navbar-inverse" id="navBar">
        <div className="nav-wrapper" id="nav-left">
          <span className="logo-wrapper">
            <Link className="logo appLink" to={PATHS[PATH_NAMES.dashboard]}>
              <Logo />
            </Link>
          </span>
          {navLink(PATHS[PATH_NAMES.dashboard], 'Dashboard')}
          {navLink(PATHS[PATH_NAMES.monitoring], 'Database')}
          {navLink(PATHS[PATH_NAMES.research], 'Research')}
          {navLink(PATHS[PATH_NAMES.preclearance], 'Preclearance')}
          {
            showCertifications && navLink(PATHS[PATH_NAMES.certifications], 'Certifications')
          }
        </div>
        <div className="nav-right">
          <SettingsDropdown />
          <AppSwitcher />
        </div>
      </nav>
    </header>
  );
}

NavBar.propTypes = {
  location: PropTypes.object.isRequired,
  showCertifications: PropTypes.bool.isRequired,
};

export default withRouter(NavBar);
