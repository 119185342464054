import * as types from './types';

const initState = {
  reports: [],
  loaded: false,
  scheduledReports: [],
  scheduledLoaded: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case types.SET_LOADED: {
      return {
        reports: action.reports,
        loaded: true,
        scheduledReports: state.scheduledReports,
        scheduledLoaded: state.scheduledLoaded,
      };
    }
    case types.SET_SCHEDULED: {
      return {
        reports: state.reports,
        loaded: state.loaded,
        scheduledReports: action.scheduledReports,
        scheduledLoaded: true,
      };
    }
    case types.ADD_MANUAL_REPORT: {
      const existingIndex = state.reports.findIndex(r => r.id === action.report.id);
      const existingCopy = [...state.reports];
      if (existingIndex >= 0) {
        existingCopy[existingIndex] = action.report;
        return {
          ...state,
          reports: existingCopy,
        };
      }
      return {
        reports: [...state.reports, action.report],
        loaded: state.loaded,
        scheduledReports: state.scheduledReports,
        scheduledLoaded: state.scheduledLoaded,
      };
    }
    case types.ADD_AUTO_REPORT: {
      if (state.scheduledReports.some(r => r.id === action.report.id)) {
        return state;
      }
      const autoReport = [...state.scheduledReports, action.report];
      return {
        reports: state.reports,
        loaded: state.loaded,
        scheduledReports: autoReport,
        scheduledLoaded: state.scheduledLoaded,
      };
    }
    case types.EDIT_AUTO_REPORT: {
      const scheduledReportsCopy = [...state.scheduledReports];
      const reportIndex = scheduledReportsCopy.findIndex(r => r.id === action.report.id);
      if (reportIndex < 0) {
        return state;
      }
      scheduledReportsCopy[reportIndex] = action.report;
      return {
        reports: state.reports,
        loaded: state.loaded,
        scheduledReports: scheduledReportsCopy,
        scheduledLoaded: state.scheduledLoaded,
      };
    }
    case types.UPDATE_REPORT_PROGRESS: {
      const targetReport = state.reports.find(report => report.id === action.id);
      if (!targetReport) {
        break;
      }
      const nextReport = {
        ...targetReport,
        current: action.current,
        total: action.total,
        errored: action.errored,
        is_complete: action.ready,
      };
      const reportCopy = state.reports.slice(0);
      reportCopy[reportCopy.findIndex(r => r.id === action.id)] = nextReport;
      return {
        ...state,
        reports: reportCopy,
      };
    }
    case types.DELETE_REPORT: {
      const reports = state.reports.filter(r => r.id !== action.id);
      return {
        ...state,
        reports,
      };
    }
    default: return state;
  }
};
