import React from 'react';
import PropTypes from 'prop-types';

import ModalUI from 'components/modal/modalUI';


const ConfirmationModal = ({ isSubmitting, closeModal }) => (
  <ModalUI
    open
    close={closeModal}
    className="small center"
  >
    <div>
      <h2>
        Update Delete Delay
      </h2>
      <div className="message">
        This will update the pending delete date for any employees who are currently scheduled to be deleted.
      </div>
    </div>
    <div className="button-wrapper">
      <button
        onClick={closeModal}
        className="text-button uppercase"
        type="button"
      >
        cancel
      </button>
      <button
        className="orange-button"
        type="submit"
        disabled={isSubmitting}
      >
        update
      </button>
    </div>
  </ModalUI>
);


ConfirmationModal.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ConfirmationModal;
