import * as types from './types';
import { ADD_MATCHED_RECORD, TOGGLE_PRECLEARANCE_NO_MATCH, DELETE_MATCH } from '../matches/types';

const defaultState = [];

export default (state = defaultState, action) => {
  let reqIndex;
  let newReq;
  let newState;
  switch (action.type) {
    case types.RECEIVE_ROWS:
    case types.RECEIVE_INITIAL_DATA:
      return action.rows;
    case types.RECEIVE_ROW:
      newState = Object.assign([], state);
      reqIndex = state.findIndex(row => row.id === action.row.id);
      if (reqIndex >= 0) {
        newState[reqIndex] = action.row;
        return newState;
      }
      return [action.row, ...newState];
    case types.REMOVE_ROW:
      newState = Object.assign([], state).filter(row => row.id !== action.rowId);
      return newState;
    case types.MATCH_ACTION: {
      const { request } = action.data;
      newState = [...state];
      reqIndex = state.findIndex(row => row.id === request.id);
      if (reqIndex >= 0) {
        newState[reqIndex] = request;
      }
      return newState;
    }
    case ADD_MATCHED_RECORD:
      reqIndex = state.findIndex(row => row.id === action.requestId);
      newState = Object.assign([], state);
      newReq = Object.assign(state[reqIndex],
        {
          matches: [...state[reqIndex].matches, action.record],
          no_match: false,
        });
      newState.splice(reqIndex, 1, newReq);
      return newState;
    case TOGGLE_PRECLEARANCE_NO_MATCH: {
      reqIndex = state.findIndex(row => row.id === action.requestId);
      newState = Object.assign([], state);
      const { noMatch } = action;
      const matches = noMatch ? [] : [...state[reqIndex].matches];
      newReq = Object.assign(state[reqIndex], { matches, no_match: noMatch });
      newState.splice(reqIndex, 1, newReq);
      return newState;
    }
    case DELETE_MATCH:
      reqIndex = state.findIndex(row => row.id === action.requestId);
      if (reqIndex < 0) {
        return state;
      }
      newState = Object.assign([], state);
      newReq = Object.assign(state[reqIndex],
        {
          matches: state[reqIndex].matches.filter(record => record.id !== action.recordMetaId),
        });
      newState.splice(reqIndex, 1, newReq);
      return newState;

    default:
      return state;
  }
};
