import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import Tippy from '@tippyjs/react';
import { PATHS, PATH_NAMES, SUPPORT_EMAIL } from 'utils/constants';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import ErrorBoundary from 'components/errorBoundary';
import CertificationsTable from './CertificationsTable';
import { fetchForms } from "reducers/certifications/forms/actions";
import Loading from "../../loading";


export const noPublishedForms = (
  <div>
    This feature requires you to have at least one published form, please create and publish a form
    {' '}
    <Link
      className="orange-link"
      to={PATHS[PATH_NAMES.certification_settings]}
    >
      here
    </Link>
    .
  </div>
);


function Certifications({ preclearanceSsoEnabled, hasForms, fetchForms }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let didCancel = false;
    Promise.all([
      fetchForms(),
    ]).catch((err) => {
      toast('Something went wrong.');
      throw err;
    }).finally(() => {
      if (!didCancel) {
        setIsLoading(false);
      }
    })
    return () => {
      didCancel = true;
    };
  }, [])

  if (isLoading) {
    return (
      <div className="load-screen">
        <Loading />
      </div>
    );
  }

  const preventCreation = !preclearanceSsoEnabled || !hasForms;
  let disabledMessage;
  if (!preclearanceSsoEnabled) {
    disabledMessage = (
      <div>
        This feature requires you to set up SSO for your employees. Please reach out to us at
        {' '}
        <a href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer" className="orange-link">{SUPPORT_EMAIL}</a>
        {' '}
        to enable.
      </div>
    );
  }
  if (!hasForms) {
    disabledMessage = noPublishedForms;
  }

  return (
    <ErrorBoundary>
      <ScrollToTopOnMount />
      <div className="certifications-container">
        <div className="flex-header">
          <h2>Certifications</h2>
          <div className="certification-actions">
            <Link
              className="icon-text-button uppercase"
              to={PATHS[PATH_NAMES.certification_settings]}
            >
              <i className="material-icons">
                settings
              </i>
              certification settings
            </Link>
            <Tippy
              content={disabledMessage}
              interactive
              disabled={!preventCreation}
            >
              {
                !preventCreation
                  ? (
                    <Link
                      className="orange-button"
                      to={PATHS[PATH_NAMES.create_certification]}
                    >
                      <i className="material-icons">
                        add
                      </i>
                      create new certification
                    </Link>
                  )
                  : (
                    <div>
                      <button
                        className="orange-button"
                        disabled
                        type="button"
                      >
                        <i className="material-icons">
                          add
                        </i>
                        create new certification
                      </button>
                    </div>
                  )
              }
            </Tippy>
          </div>
        </div>
        <CertificationsTable />
      </div>
    </ErrorBoundary>
  );
}


const mapStateToProps = ({ organization, certifications }) => ({
  preclearanceSsoEnabled: organization.preclearance_sso_enabled,
  hasForms: !isEmpty(certifications.forms),
});


Certifications.propTypes = {
  preclearanceSsoEnabled: PropTypes.bool,
  hasForms: PropTypes.bool,
  fetchForms: PropTypes.func.isRequired,
};

Certifications.defaultProps = {
  preclearanceSsoEnabled: false,
  hasForms: false,
};


export default connect(mapStateToProps, {
  fetchForms,
})(Certifications);
