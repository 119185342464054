import * as types from './types';


const platforms = (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_PLATFORMS: {
      return action.platforms;
    }
    default: {
      return state;
    }
  }
};

export default platforms;
