import * as types from '../types';

export const openModal = ({ modalType, modalProps }) => ({
  type: types.OPEN_MODAL,
  modalType,
  modalProps,
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});
