import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { deserializeDate, serializeDate, serializeEndDate } from 'utils/serializers/dateSerializer';
import { TODAY, TWO_YEARS_AGO, SIX_MONTHS_AGO, PAST_TWO_YEARS, PAST_SIX_MONTHS, CUSTOM_DATE_RANGE } from '../../utils/constants';
import { CustomDropdown } from '../dropdown';
import FiltersPanel from './filtersPanel';
import { validateDate, isBeforeDay, acceptableDateFormats } from '../../helpers/date_helpers';


export function validateDateRange(dateObj, globalDF) {
  const to = dateObj.to ? moment(dateObj.to, acceptableDateFormats, true) : null;
  const from = dateObj.from ? moment(dateObj.from, acceptableDateFormats, true) : null;
  const globalDateFilter = globalDF || null;
  const invalidDates = Object.values(dateObj).filter(date => date && !validateDate(date)).length;
  if (invalidDates) {
    return 'Please enter a valid date in this format: mm/dd/yyyy';
  }
  const outsideGlobalDate = [to, from].filter(date => date && globalDateFilter && isBeforeDay(date, moment(globalDateFilter))).length;
  if (outsideGlobalDate) {
    return 'Date must be after global date filter date';
  }
  const invalidRange = Boolean(to && from && isBeforeDay(to, from));
  if (invalidRange) {
    return 'Please enter a valid date range';
  }
  return null;
}

class FiltersDropdown extends Component {
  constructor(props) {
    super(props);
    const { globalDateFilter } = props;
    this.formattedGlobalDateFilter = globalDateFilter ? moment(globalDateFilter).format('MM/DD/YYYY') : null;
    this.noFilterOptionKey = this.formattedGlobalDateFilter ? `After ${this.formattedGlobalDateFilter}` : 'Any date';

    this.state = {
      tagFilters: props.tagFilters,
      dateFilter: this.noFilterOptionKey,
      customDateInput: {
        from: '',
        to: '',
      },
      errors: {
        date: '',
      },
    };

    this.dateOptions = {
      [this.noFilterOptionKey]: { from: null, to: null },
      [PAST_TWO_YEARS]: { from: TWO_YEARS_AGO, to: TODAY },
      [PAST_SIX_MONTHS]: { from: SIX_MONTHS_AGO, to: TODAY },
      [CUSTOM_DATE_RANGE]: { ...this.state.customDateInput },
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleRadioSelect = this.handleRadioSelect.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.openCallback = this.openCallback.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.prevDateFilter = this.state.dateFilter;
  }

  handleFieldChange(filterField, value) {
    this.setState({ [filterField]: value });
  }

  handleRadioSelect(filterField, value) {
    this.setState({ [filterField]: value });
  }

  toggleCheckbox(filterField, ids, active) {
    const nextState = new Set(this.state[filterField]);
    if (active) {
      ids.forEach(id => nextState.add(id));
    } else {
      ids.forEach(id => nextState.delete(id));
    }
    this.setState({ [filterField]: nextState });
  }


  applyFilters(closeCb) {
    const { dateFilter, globalDateFilter } = this.props;
    const { customDateInput } = this.state;
    let newDateFilter = this.dateOptions[this.state.dateFilter];
    if (this.state.dateFilter === CUSTOM_DATE_RANGE) {
      newDateFilter = {
        from: serializeDate(customDateInput.from),
        to: serializeEndDate(customDateInput.to),
      };
      const errors = validateDateRange(customDateInput, globalDateFilter);
      if (errors) {
        this.setState({ errors: { ...this.state.errors, date: errors } });
        return;
      }
    }

    const dateFilterMismatch = newDateFilter.to !== dateFilter.to || newDateFilter.from !== dateFilter.from;

    if (dateFilterMismatch) {
      this.props.setDateFilter(newDateFilter);
      this.props.clearResults();
      this.prevDateFilter = this.state.dateFilter;
    }
    this.props.setTagFilters(new Set(this.state.tagFilters));
    closeCb();
  }

  clearFilters(closeCb) {
    this.setState({
      tagFilters: new Set(),
      dateFilter: this.noFilterOptionKey,
    }, () => {
      this.applyFilters(closeCb);
    });
  }

  openCallback() {
    let customDateInput = {
      to: '',
      from: '',
    };
    const { to, from } = this.props.dateFilter;
    if (this.prevDateFilter === CUSTOM_DATE_RANGE) {
      customDateInput = {
        from: from ? deserializeDate(from) : '',
        to: to ? deserializeDate(to) : '',
      };
    }
    this.setState({
      dateFilter: this.prevDateFilter,
      tagFilters: this.props.tagFilters,
      customDateInput,
      errors: { date: '' },
    });
  }

  render() {
    const { dateFilter, tagFilters, showTagFilters } = this.props;
    const to = dateFilter.to ? `${deserializeDate(dateFilter.to)}` : 'any date';
    const from = dateFilter.from ? `${deserializeDate(dateFilter.from)}` : 'any date';
    const dateFilterSet = Boolean(dateFilter.to || dateFilter.from);
    const numFilters = dateFilterSet + (showTagFilters ? tagFilters.size : 0);

    const dateFilterText = dateFilterSet ? (
      <div className="dropdown-label">
        <span className="lighter-text">Date:</span>
        {' '}
        <span>
          {from}
          {' - '}
          {to}
        </span>
      </div>
    )
      : (
        <div className="dropdown-label">
          <span className="lighter-text">Filter by date</span>
        </div>
      );
    const dropdownText = showTagFilters ?
      (<span>
        <i className="icon-filter" />
        { numFilters ? 'Edit ' : 'Add '} filters
        { numFilters ? <div className="badge">{numFilters}</div> : ''}
      </span>)
      :
      dateFilterText;

    return (
      <div className="filter-dropdown dropdown">
        <CustomDropdown render={({ isOpen, openDropdown, closeDropdown, dropdownRef }) => (
          <div>
            <div className="drop-wrapper">
              <button
                onClick={isOpen ? closeDropdown : openDropdown}
                className="dropdownButton text-button uppercase"
              >
                { dropdownText }
                <i className="material-icons">
                  {isOpen ? 'expand_less' : 'expand_more'}
                </i>
              </button>
            </div>
            {
              isOpen ?
                <div className="dropdown-container" ref={dropdownRef}>
                  <FiltersPanel
                    globalDateFilter={this.formattedGlobalDateFilter}
                    dateFilter={this.state.dateFilter}
                    dateOptions={this.dateOptions}
                    customDateInput={this.state.customDateInput}
                    tagFilters={this.state.tagFilters}
                    tags={this.props.tags}
                    appliedFilters={Boolean(numFilters)}
                    handleFieldChange={this.handleFieldChange}
                    handleRadioSelect={this.handleRadioSelect}
                    toggleCheckbox={this.toggleCheckbox}
                    applyFilters={() => { this.applyFilters(closeDropdown); }}
                    clearFilters={() => { this.clearFilters(closeDropdown); }}
                    showTagFilters={showTagFilters}
                    openCallback={this.openCallback}
                    errors={this.state.errors}
                  />
                </div>
                : null
            }
          </div>
        )}
        />
      </div>
    );
  }
}

FiltersDropdown.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  tagFilters: PropTypes.object,
  tags: PropTypes.array.isRequired,
  setDateFilter: PropTypes.func.isRequired,
  setTagFilters: PropTypes.func,
  globalDateFilter: PropTypes.string,
  showTagFilters: PropTypes.bool,
  clearResults: PropTypes.func.isRequired,
};

FiltersDropdown.defaultProps = {
  globalDateFilter: null,
  showTagFilters: false,
  setTagFilters: () => {},
  tagFilters: new Set(),
};

export default FiltersDropdown;
