// css classnames
export const [CELL, ROW, HEADER_ROW] = ['preclearance-cell', 'preclearance-row', 'preclearance-header-row'];

// non-data keys
export const ADD_ROW = 'ADD_ROW';
export const ADD_COLUMN = 'ADD_COLUMN';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const MATCHES = 'MATCHES';
export const CURRENCY = 'currency';
export const SUBMISSION_DATE = 'submission_date';

export const START_WIDTH = 140;
export const ROW_HEIGHT = 50;
export const ADD_ROW_BUTTON_HEIGHT = 25;
export const INPUT_ROW_HEIGHT = 80;
export const HEADER_ROW_HEIGHT = 50;
export const ADD_COLUMN_BUTTON_WIDTH = 80;
export const ADD_ATTACHMENT_WIDTH = 180;
export const MATCH_COL_WIDTH = 200;
export const DEFAULT_COL_WIDTH = 140;
export const SUBMISSION_DATE_WIDTH = 180;
export const DROPDOWN_HEIGHT = 150;
// kind of arbitrary, just using a number thats at least the max of all browser scroll bars
// purpose: avoid scroll bar width / height from pushing in on table column and therefore shrinking column widths, so that they may keep aligned with header widths
export const SCROLLBAR_OFFSET = 20;
export const NA = 'N/A';

// todo: get from API instead
export const FIXED_LEFT_COLUMN_IDS = ['status', 'email'];
export const FIXED_RIGHT_COLUMN_IDS = [ADD_COLUMN, ADD_ATTACHMENT, MATCHES];

// special columns
export const [RECIPIENT, JURISDICTION, STATUS, AMOUNT] = ['recipient', 'jurisdiction_name', 'status', 'amount'];

// for the template that will be autoopened
export const REQUEST_MORE_INFORMATION = 'Request more information';
// for the the modals
export const [CUSTOMIZE_EMAIL_MODAL, ACTION_CONFIRMATION, MATCHING_MODAL] = ['customizeEmailModal', 'actionConfirmation', 'matchingModal'];

// column types
export const [
  TEXT,
  DATE,
  DECIMAL,
  CHECKBOX,
  ENUM,
  SELECT,
] = [
  'TextField',
  'DateField',
  'DecimalField',
  'CheckboxField',
  'EnumField',
  'SelectField',
];
