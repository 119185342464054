import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import useCancellablePromise from 'hooks/useCancellablePromise';
import { PATH_NAMES, PATHS, COMPANIES } from 'utils/constants';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import { SearchFormHeading, UpdateSearchText } from 'components/monitoringForm/formHeadings';
import { CheckboxGroup } from 'components/shared/SchemaForm/components/CheckboxGroup';
import SubmitButton from '../submitButton';
import {
  Tab, TabSet, TabPanel, TabContent,
} from '../tabPanel';
import SourceSelectionWrapper from '../sourceSelection/formikSourceSelectionWrapper';
import {
  entityName, exclude, searchAs, searchAsChoices,
} from './entityFormContainer';


export function getFilterCount(values) {
  const count = values.filter((v) => v && v.length).length;
  return count;
}


function EntityForm({
  edit,
  submitFunc,
  initForm,
  sources,
  entityValidationSchema,
  toggleDeleteModal,
  entity,
}) {
  const history = useHistory();
  const { cancellablePromise } = useCancellablePromise();

  function handleSubmit(values, actions) {
    const sourcesLength = Array.from(values.sources).length;
    if (sourcesLength < 1) {
      toast('Please select at least one source.');
      actions.setSubmitting(false);
      return;
    }
    actions.setSubmitting(true);
    const payload = {
      ...values,
      all_sources: sourcesLength === sources.length,
    };
    cancellablePromise(submitFunc(payload)).then((data) => {
      actions.setSubmitting(false);
      return history.push(`${PATHS[PATH_NAMES.monitoring]}/${COMPANIES}/${data.id}`);
    }).catch((err) => {
      if (!err.isCanceled) {
        actions.setSubmitting(false);
        const errors = err && err.response && err.response.data;
        let errorMessage = 'Something went wrong.';
        if (Array.isArray(errors)) {
          // max entities error
          errorMessage = errors[0];
        } else if (errors) {
          errorMessage = 'Some fields had errors';
          actions.setErrors(errors);
        }
        toast(errorMessage);
        throw err;
      }
    });
  }

  return (
    <div className="form-wrapper search-form employee-form" data-testid="add-entity-form">
      { edit ? (
        <>
          <SearchFormHeading
            toggleConfirmDelete={toggleDeleteModal}
            headerType="company"
            closeUrl={`${PATHS[PATH_NAMES.monitoring]}/${COMPANIES}/${entity.id}`}
            editing
          />
          <UpdateSearchText searchType="company" />
        </>
      )
        : (
          <SearchFormHeading
            headerType="company"
            closeUrl={`${PATHS[PATH_NAMES.monitoring]}/${COMPANIES}`}
          />
        )}
      <Formik
        initialValues={({ ...initForm })}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validationSchema={entityValidationSchema}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off" noValidate>
            <div className="row employee-name-row">
              <div className="col">
                <div className="focusable">
                  <SchemaField
                    name={entityName}
                    id={entityName}
                    label="Company name"
                    fieldType="TextField"
                    className="form-input"
                    required
                  />
                </div>
              </div>
              <div className="col-no-flex">
                <SubmitButton
                  isSubmitting={isSubmitting}
                  edit={edit}
                  type="company"
                  icon="company"
                />
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col">
                <div className="input-title">
                  <span>Search by</span>
                </div>
                <div className="search-by-row">
                  <CheckboxGroup
                    name={searchAs}
                    multiple
                    choices={searchAsChoices}
                  />
                </div>
              </div>
            </div>

            <TabPanel
              render={({ activeTab, setActiveTab }) => (
                <div>
                  <TabSet
                    onTabClick={setActiveTab}
                  >
                    <Tab
                      tabKey={0}
                      activeTab={activeTab}
                      badge={getFilterCount(values[exclude])}
                    >
                      Exclude Filters
                    </Tab>
                    <Tab
                      tabKey={1}
                      activeTab={activeTab}
                      badge={values.sources.size}
                    >
                      Sources
                    </Tab>
                  </TabSet>

                  <TabContent
                    tabKey={0}
                    activeTab={activeTab}
                  >
                    <div>
                      <p>
                        This will filter out records that contain any of the words you enter below.
                        This can be helpful for filtering out companies with the same name. For example,
                        "Dominos" might exclude "sugar" to only see records referring to the pizza company.
                      </p>
                      <SchemaField
                        name={exclude}
                        id={exclude}
                        label="excluding"
                        addButtonText="word to exclude"
                        className="form-input"
                        limit={30}
                        fieldType="ArrayField"
                        minFields={1}
                        limitReachedText="words to exclude"
                      />
                    </div>
                  </TabContent>

                  <TabContent
                    tabKey={1}
                    activeTab={activeTab}
                  >
                    <div className="source-selection">
                      <SourceSelectionWrapper
                        sources={sources}
                        selected={values.sources}
                        field="sources"
                      />
                    </div>
                  </TabContent>
                </div>
              )}
            />

          </Form>
        )}
      </Formik>
    </div>
  );
}

EntityForm.propTypes = {
  edit: PropTypes.bool,
  submitFunc: PropTypes.func.isRequired,
  initForm: PropTypes.object.isRequired,
  sources: PropTypes.array.isRequired,
  entityValidationSchema: PropTypes.object.isRequired,
  toggleDeleteModal: PropTypes.func,
  entity: PropTypes.object,
};

EntityForm.defaultProps = {
  edit: false,
  toggleDeleteModal: () => {},
  entity: {},
};

export default EntityForm;
