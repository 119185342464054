import { combineReducers } from 'redux';

import searchObjectsReducer from './searchObjects/reducer';
import employeesReducer from './employees/reducer';
import entitiesReducer from './entities/reducer';
import sourceReducer from './sources/reducer';
import statusesReducer from './statuses/reducer';
import resultsReducer from './results/reducer';
import userReducer from './user/reducer';
import reportReducer from './reports/reducer';
import jurisdictionReducer from './jurisdictions/reducer';
import uiReducer from './ui/index';
import uploadsReducer from './uploads/reducer';
import bulkExportsReducer from './bulkExports/reducer';
import preclearance from './preclearance/index';
import research from './research/index';
import contributions from './contributions/reducer';
import sourceResults from './sourceResults/reducer';
import categoryReducer from './categories/reducer';
import tagsReducer from './tags/reducer';
import customFieldsReducer from './customFields/reducer';
import organization from './organization/reducer';
import platforms from './platforms/reducer';
import aggregations from './aggregations/reducer';
import certifications from './certifications/index';


export default combineReducers({
  sources: sourceReducer,
  statuses: statusesReducer,
  results: resultsReducer,
  employees: employeesReducer,
  entities: entitiesReducer,
  searchObjects: searchObjectsReducer,
  user: userReducer,
  reports: reportReducer,
  jurisdictions: jurisdictionReducer,
  ui: uiReducer,
  uploads: uploadsReducer,
  bulkExports: bulkExportsReducer,
  preclearance,
  research,
  contributions,
  sourceResults,
  categories: categoryReducer,
  tags: tagsReducer,
  customFields: customFieldsReducer,
  organization,
  platforms,
  aggregations,
  certifications,
});
