import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { EMPLOYEE_PATHS, PATH_NAMES, PEOPLE, PATHS } from 'utils/constants';
import { SELF, SPOUSE, DEPENDENT } from 'components/employeePage/consts';
import { getSearch } from 'reducers/searchObjects/actions';
import { byId } from 'reducers/searchObjects/selectors';
import Loading from 'components/loading';
import { EditForm, NewSearchForm } from './personForm/personSearchFormContainer';


const MonitoringForm = ({ searchType, edit, fetchSearch, searchFetched }) => {
  const [loading, setLoading] = useState(edit && !searchFetched);
  const { id, searchId } = useParams();
  const history = useHistory();

  useEffect(() => {
    let didCancel = false;
    if (edit && !searchFetched) {
      fetchSearch(searchId)
        .then(() => {
        }).catch((err) => {
          if (!didCancel) {
            history.push(`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/${id}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`);
            toast('There was a problem getting the details for this search.');
            throw err;
          }
        }).finally(() => {
          if (!didCancel) {
            setLoading(false);
          }
        });
    }
    return () => {
      didCancel = true;
    };
  }, [edit]);

  const Form = edit ? EditForm : NewSearchForm;
  return (
    loading ?
      <div className="result-loader-container"><Loading /></div>
      :
      <Form searchType={searchType} />
  );
};

const mapStateToProps = (state, { match: { params } }) => {
  const search = byId(state, params.searchId);
  return {
    searchFetched: Boolean(search && search.sources),
  };
};

MonitoringForm.propTypes = {
  searchType: PropTypes.oneOf([SELF, SPOUSE, DEPENDENT]),
  edit: PropTypes.bool,
  fetchSearch: PropTypes.func.isRequired,
  searchFetched: PropTypes.bool,
};

MonitoringForm.defaultProps = {
  searchType: null, // only if edit is false (otherwise we get search type from the search itself)
  edit: false,
  searchFetched: false,
};

export default withRouter(connect(mapStateToProps, {
  fetchSearch: getSearch,
})(MonitoringForm));
