import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FileItem from 'components/shared/recordItem/fileItem';
import { StaticTransitionContainer } from 'components/transitionContainer';
import { addUpload, removeUpload, getUpload } from 'reducers/preclearance/uploads/actions';
import {
  CELL,
  ADD_ATTACHMENT_WIDTH,
  ROW_HEIGHT,
} from './constants';

const FILE_HEIGHT = 18;
const ATTACH_BUTTON_HEIGHT = 21;
const CELL_PADDING = 25;

class AttachmentCell extends React.Component {
  constructor(props) {
    super(props);
    this.onFileInputChange = this.onFileInputChange.bind(this);
  }

  onFileInputChange(e) {
    const files = e.target.files;
    if (!window.fileStore) {
      window.fileStore = {};
    }
    if (!window.fileStore[this.props.rowId]) {
      window.fileStore[this.props.rowId] = {};
    }
    // For IE 10+ support, since it doesn't support FileList iteration.
    const filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    filesArray.forEach((file) => {
      this.props.addUpload(file, this.props.rowId).catch((err) => {
        // Needed to store files for retry.
        window.fileStore[this.props.rowId][file.name] = file;
        throw err;
      });
    });
    e.target.value = null;
  }

  onFileDelete(fileName) {
    return this.props.removeUpload(fileName, this.props.rowId);
  }

  retryUpload(fileName) {
    const targetFile = window.fileStore[this.props.rowId][fileName];
    this.props.retryUpload(targetFile, this.props.rowId).then(() => {
      delete window.fileStore[this.props.rowId][targetFile.name];
    });
  }

  render() {
    const { onClick, rowIdx, files, editing, removeUpload, getUpload, rowId, cellStyle, cellClass } = this.props;
    const progressBarWidth = 50;
    const disabled = files.length === 3;
    
    // not using css bc height: fit-content not working in IE 10
    const cellHeight = editing ? (files.length * FILE_HEIGHT) + ATTACH_BUTTON_HEIGHT + CELL_PADDING : 0;
    return (
      <div
        className={`${cellClass ? cellClass : `${editing}`} ${CELL} attachment-cell`}
        style={{ width: ADD_ATTACHMENT_WIDTH, height: Math.max(cellHeight, ROW_HEIGHT), ...cellStyle }}
        onClick={onClick}
      >
        {editing ?
          <React.Fragment>
            { files.length ?
              <div className="file-container">
                {
                  files.map((file) => {
                    return (
                      <FileItem
                        hideRemoveText
                        key={file.name}
                        name={file.name}
                        progress={file.progress}
                        progressBarWidth={progressBarWidth}
                        deleteFile={() => removeUpload(file.name, rowId)}
                        getUpload={() => getUpload(file.name, rowId)}
                        retryUpload={() => { }}
                        transitionContainer={StaticTransitionContainer}
                      />
                    )
                  })
                }
              </div>
              : null
            }
            
            <input type="file" id={`file-upload-${rowIdx}`} style={{ display: 'none' }} onChange={this.onFileInputChange} />
            <label
              htmlFor={`${disabled ? '' : `file-upload-${rowIdx}`}`}
            >
              <span className={`${disabled ? 'disabled' : ''} attach-button`}>+ attach files</span>
            </label>
          </React.Fragment>
          :
          <div>
            { files.length ?
              <i className="material-icons has-files">attach_file</i> :
              <i className="icon-add-attachment" />
            }
          </div>
        }
      </div>
    );
  }
}

AttachmentCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  rowIdx: PropTypes.number.isRequired,
  files: PropTypes.array.isRequired,
  editing: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  addUpload: PropTypes.func.isRequired,
  removeUpload: PropTypes.func.isRequired,
  getUpload: PropTypes.func.isRequired,
  cellClass: PropTypes.string,
  cellStyle: PropTypes.object,
};

AttachmentCell.defaultProps = {
  cellClass: '',
  cellStyle: {},
};

const mapStateToProps = ({ preclearance: { uploads } }, { rowId }) => ({
  files: (uploads && uploads[rowId]) || [],
});


export default connect(mapStateToProps, { addUpload, removeUpload, getUpload })(AttachmentCell);


export const DisconnectedAttachmentCell = ({ files, setUploads, cellClass, cellStyle }) => {
  const addUpload = file => {
    file.loading = false;
    file.progress = 100;
    setUploads(files.concat(file));
    return new Promise(res => res());
  }

  const removeUpload = (fileName) => {
    setUploads(files.filter(f => f.name !== fileName));
    return new Promise(res => res())
  }

  return (
    <AttachmentCell
      onClick={() => { }}
      getUpload={() => { }}
      rowIdx={0}
      rowId={'0'}
      files={files}
      editing={'true'}
      addUpload={addUpload}
      removeUpload={removeUpload}
      cellClass={cellClass}
      cellStyle={cellStyle}
    />
  );
};

DisconnectedAttachmentCell.propTypes = {
  setUploads: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  cellClass: PropTypes.string.isRequired,
  cellStyle: PropTypes.object.isRequired,
};
