import React from 'react';
import PropTypes from 'prop-types';


const AlphaSelector = ({
  letter, expanded, toggleExpanded,
}) => (
  <div
    className={`alpha-selector-toggle ${expanded ? 'expanded' : ''}`}
    onClick={toggleExpanded}
    role="button"
    tabIndex="0"
  >
    <span className="alpha-selector-letter">
      { letter }
    </span>

    <span className="alpha-selector-icon">
      {expanded
        ? <i className="material-icons">expand_less</i>
        : <i className="material-icons">expand_more</i>}
    </span>
  </div>
);


export default AlphaSelector;

AlphaSelector.propTypes = {
  letter: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpanded: PropTypes.func.isRequired,
};
