import * as types from './types';

// keep track of sourceResultIds for current search, incase another search is made
//   before the current one finishes

const defaultState = [];

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.RECEIVE_RESEARCH_RESULTS:
      return Object.values(action.data).map(source => source.id);
    case types.SELECT_TAB:
      return defaultState;
    case types.RESET_STATE:
    case types.CLEAR_RESULTS:
      return defaultState;
    default: return state;
  }
};
