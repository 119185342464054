import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeModal } from 'reducers/preclearance/ui/actions';
import ModalUI from 'components/modal/modalUI';
import { MATCHING_MODAL, CUSTOMIZE_EMAIL_MODAL, ACTION_CONFIRMATION } from '../table/constants';
import ActionModal from './actionModal';
import MatchingWrapper from 'components/shared/matching/matchingWrapperOnPreclearance';

const modals = {
  [MATCHING_MODAL]: {
    component: MatchingWrapper,
    className: '',
  },
  // ActionModal handles which contents to render for the next 2
  [ACTION_CONFIRMATION]: {
    component: ActionModal,
    className: 'action-modal settings',
  },
  [CUSTOMIZE_EMAIL_MODAL]: {
    component: ActionModal,
    className: 'action-modal settings',
  },
};

const disallowClose = [ACTION_CONFIRMATION, CUSTOMIZE_EMAIL_MODAL];

// eslint-disable-next-line no-shadow
const PreclearanceModal = ({ modalType, closeModal }) => {
  if (!modalType) {
    return null;
  }
  const modal = modals[modalType];
  const ModalContents = modal.component;

  return (
    <ModalUI
      open
      className={modal.className}
      close={disallowClose.includes(modalType) ? () => {} : closeModal}
    >
      <ModalContents closeModal={closeModal} />
    </ModalUI>
  );
};

PreclearanceModal.propTypes = {
  modalType: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({ preclearance }) => ({
  modalType: preclearance.ui.modalType,
});

export default connect(mapStateToProps, { closeModal })(PreclearanceModal);
