import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PlatformName from './PlatformName';


const PlatformLoginLink = ({
  name,
  iconUrl,
  url,
}) => (
  <Link
    to={url}
    target="_blank"
    rel="noopener noreferrer"
    className="option"
  >
    <PlatformName
      name={name}
      iconUrl={iconUrl}
    />
  </Link>
);

PlatformLoginLink.propTypes = {
  name: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default PlatformLoginLink;
