import api from 'api';
import * as types from './types';

const createMatch = ({ request, recordId }) => ({
  request,
  recordId,
  type: types.ADD_MATCHED_REQUEST,
});

const addMatchOnPreclearance = ({ requestId, record }) => ({
  requestId,
  record,
  type: types.ADD_MATCHED_RECORD,
});

const toggleNoMatch = (recordId, noMatch) => ({
  recordId,
  noMatch,
  type: types.TOGGLE_NO_MATCH,
});

const togglePreclearanceNoMatch = (requestId, noMatch) => ({
  requestId,
  noMatch,
  type: types.TOGGLE_PRECLEARANCE_NO_MATCH,
});

const deleteMatch = ({ requestId, recordMetaId, recordId }) => ({
  requestId,
  recordMetaId,
  recordId, // needed for contrib slice
  type: types.DELETE_MATCH,
});


export const makeMatch = (record, request) => dispatch =>
  api.post('/matches/', {
    request: request.id,
    record: record.meta.id || record.meta.contribution_id, // records on monitoring have contrib_id instead of id
  }).then(({ data }) => {
    dispatch(createMatch({ request, recordId: record.data.id }));
    return data;
  });

export const makePreclearanceMatch = (request, record) => dispatch =>
  api.post('/matches/', {
    request: request.id,
    record: record.id,
  }).then(({ data }) => {
    dispatch(addMatchOnPreclearance({ requestId: request.id, record }));
    return data;
  });

export const markAsNoMatch = record => dispatch =>
  api.post(`/matches/records/${record.meta.id}/no_match/`)
    .then(() => {
      dispatch(toggleNoMatch(record.data.id, true));
    });

export const markRequestAsNoMatch = request => dispatch =>
  api.post(`/matches/preclearance/${request.id}/no_match/`)
    .then(() => {
      dispatch(togglePreclearanceNoMatch(request.id, true));
    });

export const removeMatch = ({ requestId, recordMetaId, recordId }) => dispatch =>
  api.post('/matches/unmatch/', {
    request: requestId,
    record: recordMetaId,
  }).then(() => {
    dispatch(deleteMatch({ requestId, recordMetaId, recordId }));
  });

export const undoNoMatch = (record) => (dispatch) => api.post('/matches/unmatch/', {
  request: null,
  record: record.meta.id,
}).then(() => {
  dispatch(toggleNoMatch(record.data.id));
});

export const undoRequestNoMatch = (request) => (dispatch) => api.post('/matches/unmatch/', {
  request: request.id,
  record: null,
}).then(() => {
  dispatch(togglePreclearanceNoMatch(request.id, false));
});
