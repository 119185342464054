import { ALLOWED_CURRENCIES, DEFAULT_CURRENCY } from 'utils/constants';
import { stripDollarSign } from '../components/preclearance/table/cellValidator';


const CURRENCY_MAP = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  TWD: 'NT$',
};

export const getCurrencySymbol = (_currency) => {
  let currency = _currency;
  if (ALLOWED_CURRENCIES.indexOf(currency) < 0) {
    currency = DEFAULT_CURRENCY;
  }
  return CURRENCY_MAP[currency];
};


export const formatAmount = (amt, _currency) => {
  // IE 10 does not support Intl
  const amount = stripDollarSign(amt);
  let currency = _currency;
  if (ALLOWED_CURRENCIES.indexOf(currency) < 0) {
    currency = DEFAULT_CURRENCY;
  }
  if (window.Intl) {
    const formatter = new Intl.NumberFormat('en-US', {
      currency,
      style: 'currency',
      minimumFractionDigits: 2,
    });
    return formatter.format(amount);
  }
  const localeContrib = (parseFloat(amount) || 0).overrideLocaleString(); // todo make sure this is ok
  // const localeContrib = amount.toLocaleString();
  const pointIndex = localeContrib.indexOf('.');
  let decimal = pointIndex >= 0 ? localeContrib.slice(pointIndex + 1) : '';
  if (decimal.length < 2) {
    decimal += '0'.repeat(2 - decimal.length);
  }
  const decimalInNumString = pointIndex === -1 ? localeContrib.length : pointIndex;
  // puts negative in front of amount
  if (localeContrib.indexOf('-') === 0) {
    return `-$${localeContrib.slice(1, decimalInNumString) || '0'}.${decimal}`;
  }
  return `$${localeContrib.slice(0, decimalInNumString) || '0'}.${decimal}`;
};

export const formatDate = (date) => {
  if (!date) {
    return '';
  }
  const [year, month, day] = date.split('-');
  if (!year || !month || !day) {
    return date;
  }
  return `${month}/${day}/${year}`;
};

// using for the amended field on record that is sometimes a boolean value
export const formatBoolField = (bool) => {
  const TRUTHY_VALUES = ['t', 'true', 'y', 'yes', 'on', '1', '1.0'];
  const FALSEY_VALUES = ['f', 'false', 'n', 'no', 'off', '0', '0.0'];
  const stringBool = bool.toString().toLowerCase();
  if (TRUTHY_VALUES.indexOf(stringBool) > 0) return 'Yes';
  if (FALSEY_VALUES.indexOf(stringBool) > 0) return 'No';
};

// This function is used for all records (on monitoring, dashboard, and research)
export const parseField = (value, fieldType) => {
  if (fieldType == 'date') {
    return formatDate(value);
  } else if (fieldType == 'float' || fieldType == 'amount') {
    // Don't think we'll have a case where we have multiple numerical fields,
    // so assuming that numerical field => currency field.
    return formatAmount(value);
  } else if (value) {
    // Sometimes joined fields have extra dashes in them. This will only apply for string fields.
    // Removes leading dash from recipient fields if it exists.
    if (fieldType == 'text' && value.slice(0, 3) === ' - ') {
      value = value.slice(3);
    }
    // Removes trailing dash from recipient fields if present.
    if (fieldType == 'text' && value.slice(-3) === ' - ') {
      value = value.slice(0, -3);
    }
  }
  return value;
};
