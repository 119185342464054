import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  PATHS,
  PATH_NAMES,
} from 'utils/constants';

function renderCertificationDetail(label, val) {
  if (!val) {
    return null;
  }
  return (
    <div key={`${label}-detail-item`} className="cert-detail-item">
      <div className="cert-details-label">{label}</div>
      <div>{val}</div>
    </div>
  );
}

function SingleCertificationHeader({
  name, status, date, certificationPeriod, recipients, statusFilter, actions, form,
}) {
  return (
    <div>
      <Link
        className="back-button"
        to={PATHS[PATH_NAMES.certifications]}
      >
        <i className="material-icons">arrow_back</i>
        all certifications
      </Link>
      <div className="flex-header">
        <h2>{name}</h2>
        <div className="certification-actions">
          { actions }
        </div>
      </div>
      <div className="certification-details">
        {[
          [status, date],
          ['certification period', certificationPeriod],
          ['recipients', recipients],
          ['statuses included', statusFilter.join(', ')],
          ['form', form],
        ].map(
          (details) => renderCertificationDetail(...details),
        )}
      </div>
    </div>
  );
}

SingleCertificationHeader.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  certificationPeriod: PropTypes.string.isRequired,
  recipients: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  statusFilter: PropTypes.array,
  actions: PropTypes.node,
  form: PropTypes.object,
};

SingleCertificationHeader.defaultProps = {
  recipients: null,
  statusFilter: null,
  actions: null,
  form: null,
};

export default SingleCertificationHeader;
