import React from 'react';
import PropTypes from 'prop-types';


function QuestionItemContent({ icon, label }) {
  return (
    <div className="question-type-label">
      <i className="question-type-icon material-icons">
        {icon}
      </i>
      <span>
        {label}
      </span>
    </div>
  );
}

QuestionItemContent.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default QuestionItemContent;
