import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import { requiredError, minLengthError, maxLengthError } from 'utils/errorHelpers';
import { DELETE_ENTITY } from 'utils/constants';
import { createEntity, updateEntity } from 'reducers/entities/actions';
import { allIds } from 'reducers/sources/selectors';
import { byId } from 'reducers/entities/selectors';
import { openModal } from 'reducers/ui/actions';
import EntityForm from './entityForm';

export const [entityName, exclude, searchAs] = ['entity_name', 'exclude', 'search_as'];
export const searchAsChoices = [
  {
    value: 'entity',
    label: 'Employer field',
    tooltip: 'Enabling this will apply the company name search to the “employer” field on the record.',
  },
  {
    value: 'person',
    label: 'Name field',
    tooltip: 'Enabling this will apply the company name search to the “contributor name” field on the record.',
  },
];

const entityValidationSchema = Yup.object().shape({
  [entityName]: Yup.string()
    .trim()
    .min(2, minLengthError(2))
    .max(200, maxLengthError(200))
    .required(requiredError),
  [exclude]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .min(3, minLengthError(3))
        .max(200, maxLengthError(200)),
    ),
  [searchAs]: Yup.array()
    .of(
      Yup.string(),
    )
    .min(1, 'You must select at least one search field.'),
});


const editMapStateToProps = (state, { match }) => {
  const entity = byId(state, match.params.id);
  const { sources } = state.sources;
  const sourceIds = allIds(sources);
  const entityCopy = cloneDeep(entity);
  const settings = entityCopy.search_settings;
  // filter out sources not returned from api
  const selectedSources = (settings.sources || []).filter(sourceId => sourceIds.includes(sourceId));
  const initForm = {
    ...entityCopy,
    [exclude]: settings[exclude].length ? settings[exclude] : [''],
    [searchAs]: settings[searchAs],
    sources: new Set(selectedSources),
    all_sources: settings.all_sources,
  };

  return {
    sources,
    initForm,
    edit: true,
    entityValidationSchema,
    entity,
  };
};

export const EditEntityForm = withRouter(connect(editMapStateToProps, {
  submitFunc: updateEntity,
  toggleDeleteModal: () => openModal({ modalType: DELETE_ENTITY }),
})(EntityForm));


const mapStateToProps = (state) => {
  const { sources } = state.sources;
  const sourceIds = allIds(sources);
  const selectedSources = new Set(sourceIds);
  const initForm = {
    [entityName]: '',
    [exclude]: [''],
    [searchAs]: ['entity'],
    sources: selectedSources,
  };

  return {
    sources,
    initForm,
    edit: false,
    entityValidationSchema,
  };
};

export default connect(mapStateToProps, {
  submitFunc: createEntity,
})(EntityForm);
