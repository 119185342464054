import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { closeModal } from 'reducers/ui/actions';
import { STATUS_SCHEMA } from 'utils/constants';
import { sendRecord } from 'reducers/contributions/actions';
import Schema from 'components/shared/sortableSchema';
import RecordMatchingTable from 'components/shared/matching/matchingTables/recordMatchingTable';
import SimpleForm from 'components/shared/forms/simpleForm';

const fieldMap = {
  to: {
    label: 'email to',
    required: true,
    type: 'text',
    placeholder: 'placeholder@gmail.com',
  },
  subject: {
    label: 'subject',
    required: false,
    type: 'text',
  },
  body: {
    label: 'message',
    textArea: true,
    required: false,
    type: 'text',
  },
};

const defaultEmail = {
  to: '',
  subject: '',
  body: '',
};

class SendRecordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(emailInfo) {
    if (!emailInfo.to.trim().length) {
      toast("Please enter the recipient's email address");
      return;
    }
    const { contribId, userEmail } = this.props;
    const recipients = emailInfo.to.split(',').filter(rec => rec.trim());
    const emailData = {
      ...emailInfo,
      to: recipients,
      from_email: userEmail,
    };
    // eslint-disable-next-line consistent-return
    return this.props.sendRecord(contribId, emailData).then(() => {
      toast('Record sent.');
      this.props.closeModal();
    }).catch((err) => {
      toast('There was a problem sending this record.');
      throw err;
    });
  }

  render() {
    const { recordToSend, recordSchema } = this.props;
    return (
      <div>
        <h2>Send Record</h2>
        <div className="message">
          <RecordMatchingTable
            columns={recordSchema}
            rows={[recordToSend]}
            schema={
              <Schema
                schema={recordSchema}
              />
            }
          />
          <SimpleForm
            onSubmit={this.onSubmit}
            onCancel={this.props.closeModal}
            cancelText={'Cancel'}
            confirmText={'Send'}
            defaultState={defaultEmail}
            fieldMap={fieldMap}
          />
        </div>
      </div>
    );
  }
}

SendRecordDialog.propTypes = {
  closeModal: PropTypes.func.isRequired,
  contribId: PropTypes.number.isRequired,
  recordSchema: PropTypes.array.isRequired,
  recordToSend: PropTypes.object.isRequired,
  sendRecord: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { recordId } = state.ui.modal.modalProps;
  const record = state.contributions.byId[recordId];
  const recordSchema = state.sources.sources.find(source => source.id === record.sourceId).person_schema;
  const recordStatus = state.statuses[record.meta.contribution_status || record.meta.status];
  return ({
    recordId,
    contribId: record.meta.id || record.meta.contribution_id,
    recordToSend: {
      ...record.data,
      contribution_status: recordStatus ? recordStatus.label : 'N/A',
    },
    recordSchema: [...recordSchema, STATUS_SCHEMA],
    userEmail: state.user.username,
  });
};

export default connect(
  mapStateToProps,
  {
    closeModal,
    sendRecord,
  })(SendRecordDialog);
