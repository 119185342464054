import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPreclearanceSettings } from 'reducers/preclearance/settingsActions';
import Loading from '../../loading';
import BodyWithSidebar from '../../shared/BodyWithSidebar';
import Questionnaire from './questionnaire/questionnaire';
import AutomatedActions from './automatedActions/automatedActions';
import PreclearanceStatusLabels from './preclearanceStatusLabels';
import EmailTemplates from './emailTemplates/emailTemplatesWrapper';
import Notifications from './notifications/notifications';
import Reports from './reports/reports';


const [
  QUESTIONNAIRE,
  AUTOMATED_ACTIONS,
  PRE_STATUS_LABELS,
  EMAIL_TEMPLATES,
  NOTIFICATIONS,
  REPORTS,
] = [
  'QUESTIONNAIRE',
  'AUTOMATED_ACTIONS',
  'PRE_STATUS_LABELS',
  'EMAIL_TEMPLATES',
  'NOTIFICATIONS',
  'REPORTS',
];

const PRECLEARANCE_SETTINGS_TAB_MAP = {
  [QUESTIONNAIRE]: 'Questionnaire',
  [AUTOMATED_ACTIONS]: 'Automated Actions',
  [PRE_STATUS_LABELS]: 'Status Labels',
  [EMAIL_TEMPLATES]: 'Email Templates',
  [NOTIFICATIONS]: 'Notifications',
  [REPORTS]: 'Reports',
};

const bodyMap = {
  [QUESTIONNAIRE]: Questionnaire,
  [AUTOMATED_ACTIONS]: AutomatedActions,
  [PRE_STATUS_LABELS]: PreclearanceStatusLabels,
  [EMAIL_TEMPLATES]: EmailTemplates,
  [NOTIFICATIONS]: Notifications,
  [REPORTS]: Reports,
};

class PreclearanceSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    this.props.fetchPreclearanceSettings().then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }
    return (
      <BodyWithSidebar
        title={'Settings'}
        tabs={Object.keys(bodyMap)}
        tabMap={PRECLEARANCE_SETTINGS_TAB_MAP}
        bodyMap={bodyMap}
        className={'preclearance-settings'}
      />
    );
  }
}

export default connect(
  null,
  {
    fetchPreclearanceSettings,
  })(PreclearanceSettings);

PreclearanceSettings.propTypes = {
  fetchPreclearanceSettings: PropTypes.func.isRequired,
};
