import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Label, Tooltip } from 'recharts';
import { IS_IE_TEN } from 'utils/constants';
import { COLORS, CHART_HEIGHT, LABEL_STYLES } from './consts';

function renderLegendText(value, entry) {
  const { count } = entry.payload;
  const countStyles = {
    ...LABEL_STYLES,
    fontWeight: '600',
    fill: '#202020',
  };

  return (
    <span>
      <text className="legend-count" style={countStyles}>
        {count.overrideLocaleString()}
      </text>
      <br />
      <text className="legend-name" style={LABEL_STYLES}>{value}</text>
    </span>
  );
}


function CustomLabel({ viewBox, totalCount }) {
  const { cx, cy } = viewBox;
  const countStyles = {
    ...LABEL_STYLES,
    fontSize: '18px',
    fontWeight: 'bold',
    fill: '#202020',
  };

  return (
    <text
      x={cx}
      y={cy}
      className="recharts-text recharts-label"
      textAnchor="middle"
      dominantBaseline="central"
      style={LABEL_STYLES}
    >
      <tspan
        x={cx}
        dy="-0.5em"
        alignmentBaseline="middle"
        className="donut-label-count"
        style={countStyles}
      >
        {totalCount}
      </tspan>
      <tspan x={cx} dy="2em" alignmentBaseline="middle">
        contributions total
      </tspan>
    </text>
  );
}

CustomLabel.propTypes = {
  viewBox: PropTypes.object,
  totalCount: PropTypes.string.isRequired,
};

CustomLabel.defaultProps = {
  viewBox: {},
};


function DonutChart({ data, nameKey, chartRef }) {
  const totalCount = data.reduce((acc, item) => acc + item.count, 0);

  return (
    <div>
      <ResponsiveContainer width={'99%'} height={CHART_HEIGHT}>
        <PieChart ref={chartRef}>
          <Pie
            data={data}
            dataKey="count"
            nameKey={nameKey}
            innerRadius={80}
            legendType="square"
            paddingAngle={2}
            blendStroke
            animationBegin={0}
            isAnimationActive={!IS_IE_TEN}
          >
            <Label
              position="center"
              content={<CustomLabel totalCount={totalCount.overrideLocaleString()} />}
            />
            {
              data.map((_entry, index) => {
                // if there are not very many data points use every other color in the array for higher contrast
                const colorIdx = (data.length < (COLORS.length / 2)) ? ((index % COLORS.length) + index + 1) : (index % COLORS.length);
                return (
                  <Cell key={index} fill={COLORS[colorIdx]} />
                );
              })
            }
          </Pie>
          <Legend
            verticalAlign="top"
            align="right"
            layout="vertical"
            formatter={renderLegendText}
          />
          <Tooltip
            cursor={{ fill: 'rgba(206, 206, 206, 0.2)' }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

DonutChart.propTypes = {
  data: PropTypes.array.isRequired,
  nameKey: PropTypes.string.isRequired,
  chartRef: PropTypes.func,
};

DonutChart.defaultProps = {
  chartRef: () => {},
};

export default DonutChart;
