import React from 'react';
import PropTypes from 'prop-types';
import { deserializeDate } from 'utils/serializers/dateSerializer';
import EmptyState from '../emptyState';

const NoResults = ({ render, dateFilter }) => {
  const from = dateFilter.from ? `${deserializeDate(dateFilter.from)}` : 'any date';
  const to = dateFilter.to ? `${deserializeDate(dateFilter.to)}` : 'any date';
  const dateFilterText = dateFilter.from || dateFilter.to ? ` with record dates from ${from} to ${to}` : '';
  return (
    <EmptyState
      messageHeading={'No results found.'}
      icon={'search'}
      message={render({ dateFilterText })}
    />
  );
};

NoResults.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
};


export default NoResults;
