import React from 'react';
import PropTypes from 'prop-types';

const PLACEHOLDER = '----------';

const ActionSentence = ({ status, template }) => (
  <div className="action-sentence">
    <span>When status changes to </span>
    <strong>{status || PLACEHOLDER} </strong>
    <span>send email template named </span>
    <strong>{template || PLACEHOLDER}</strong>
  </div>
);


ActionSentence.propTypes = {
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  template: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

ActionSentence.defaultProps = {
  status: null,
  template: null,
};

export default ActionSentence;


export const AutoResponseSentence = ({ template }) => (
  <div className="action-sentence">
    <span>When a preclearance request form is submitted, send email template named </span>
    <strong>{template || PLACEHOLDER}</strong>
  </div>
);

AutoResponseSentence.propTypes = {
  template: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};


export const MatchesStatusSentence = ({ recordStatus, requestStatus }) => (
  <div className="action-sentence">
    <span>If a preclearance request marked as </span>
    <strong>{requestStatus || PLACEHOLDER}</strong>
    <span> is matched to a monitored record, automatically mark the monitored record as </span>
    <strong>{recordStatus || PLACEHOLDER}</strong>
  </div>
);

MatchesStatusSentence.propTypes = {
  recordStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  requestStatus: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

MatchesStatusSentence.defaultProps = {
  recordStatus: null,
  requestStatus: null,
};
