import React from 'react';
import PropTypes from 'prop-types';

export const SelectValue = ({ value, choices }) => {
  const choiceMap = new Map(choices);
  return (
    <span>
      {choiceMap.get(value) || value}
    </span>
  );
};


SelectValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  choices: PropTypes.array.isRequired,
};
