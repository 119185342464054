import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconTextButton from './iconTextButton';

export const UpdateSearchText = ({ searchType }) => (
  <div className="form-info">
    Updating
    {' '}
    {(/^[aeiou]$/).test(searchType) ? 'an' : 'a'}
    {' '}
    {searchType.toLowerCase()}
    {' '}
    will replace them with a new search, and some filtered results may no longer be included in the search.
  </div>
);

UpdateSearchText.propTypes = {
  searchType: PropTypes.string.isRequired,
};


export const SearchFormHeading = ({
  toggleConfirmDelete,
  headerType,
  closeUrl,
  hideDelete,
  editing,
}) => (
  <div className="form-heading">
    <div className="results-name-row results-header">
      <div className="header-wrapper">
        <Link className="text-button" to={closeUrl}>
          <i className="material-icons">close</i>
        </Link>
        <span>
          { editing ? 'Edit' : 'Add New' }
          {' '}
          {headerType}
        </span>
      </div>
      <div className="results-actions">
        {
          editing && !hideDelete
          && (
            <IconTextButton
              icon="delete"
              buttonHandler={toggleConfirmDelete}
              buttonText={`Delete ${headerType}`}
            />
          )
        }
      </div>
    </div>
  </div>

);

SearchFormHeading.propTypes = {
  toggleConfirmDelete: PropTypes.func,
  headerType: PropTypes.string.isRequired,
  closeUrl: PropTypes.string.isRequired,
  hideDelete: PropTypes.bool,
  editing: PropTypes.bool,
};

SearchFormHeading.defaultProps = {
  toggleConfirmDelete: () => {},
  hideDelete: false,
  editing: false,
};
