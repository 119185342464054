import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MODAL_CONTAINER, SCROLLABLE_CONTAINER } from 'utils/constants';

export default class ModalUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.onEscKey = this.onEscKey.bind(this);
  }

  componentDidMount() {
    if (this.props.hideScroll) {
      document.addEventListener('keydown', this.onEscKey);
      this.bodyElem = document.querySelector('body');
      this.bodyElem.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    if (this.props.hideScroll) {
      this.bodyElem.style.overflow = 'auto';
      document.removeEventListener('keydown', this.onEscKey);
    }
  }

  onEscKey(e) {
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.props.close();
    }
  }

  render() {
    if (!this.props.open) {
      return null;
    }

    let windowLoc;
    if (SCROLLABLE_CONTAINER instanceof Window) {
      // for all other browsers
      windowLoc = window.scrollY || window.pageYOffset;
    } else {
      // for IE 10 only
      windowLoc = SCROLLABLE_CONTAINER.scrollTop;
    }

    return (
      <div
        className={`${this.props.className} ${MODAL_CONTAINER}`}
        style={{ marginTop: windowLoc }}
        onMouseDown={(e) => {
          // only close modal if click originated outside modal-box
          if (e.target !== e.currentTarget) {
            this.stayOpen = true;
          } else {
            this.stayOpen = false;
          }
        }}
        onClick={() => {
          if (this.stayOpen) {
            return;
          }
          this.props.close();
        }}
        ref={this.props.modalRef}
        role="dialog"
      >
        <div
          style={{ width: this.props.width, maxWidth: this.props.maxWidth, minWidth: this.props.minWidth }}
          className={this.props.center ? 'modal-box center' : 'modal-box'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button
            className="text-button modal-close"
            onClick={() => {
              this.props.close();
            }}
          >
            <i className="material-icons">close</i>
          </button>
          {this.props.children}
          <div style={{ visibility: 'none', width: '1000px', height: '0px' }}>
            {
            // Hack to fix IE positioning issue
            }
          </div>
        </div>
      </div>
    );
  }
}

ModalUI.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  center: PropTypes.bool,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  className: PropTypes.string,
  hideScroll: PropTypes.bool,
  modalRef: PropTypes.func,
};

ModalUI.defaultProps = {
  center: false,
  width: '',
  maxWidth: '',
  minWidth: '',
  className: '',
  hideScroll: true,
  modalRef: () => {},
};
