import React from 'react';
import PropTypes from 'prop-types';


const FiltersHeader = ({
  options,
  setFilter,
  filter,
  removeFilter,
}) => {
  const orderedOptions = [...options];
  // The default option should be first
  orderedOptions.sort((a) => (a.default ? -1 : 1));
  return (
    <div className="filters-header">
      {orderedOptions.map((option) => (
        <span
          key={option.id}
        >
          <button
            type="button"
            className={`text-button uppercase ${filter && option.id === filter.id ? 'active' : ''}`}
            onClick={() => { setFilter(option); }}
          >
            <span>
              {option.label}
            </span>
          </button>
        </span>
      ))}
      <button
        type="button"
        className={`text-button uppercase ${filter === null ? 'active' : ''}`}
        onClick={() => { removeFilter(null); }}
      >
        <span>All</span>
      </button>
    </div>
  );
};


FiltersHeader.propTypes = {
  options: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

FiltersHeader.defaultProps = {
  filter: null,
};

export default FiltersHeader;
