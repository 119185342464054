import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Modal from 'components/modal/modal';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import Loading from '../loading';
import ErrorBoundary from '../errorBoundary';
import TriangleShadow from '../triangleShadow';
import PreclearanceTable from './table/tableContainer';
import PreclearanceModal from './modals/preclearanceModalManager';

import { REQUEST_MORE_INFORMATION, CUSTOMIZE_EMAIL_MODAL, ACTION_CONFIRMATION } from './table/constants';


class PreclearanceBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.handleActionConfirmation = this.handleActionConfirmation.bind(this);
  }

  componentDidMount() {
    this.props.fetchPreclearanceSettings().then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    this.props.closeModal();
    this.props.closePreModal();
  }

  handleActionConfirmation(row) {
    const rowData = row.data;
    const statusId = rowData.status;
    const { autoActions, statuses, templates } = this.props;
    const actions = Object.values(autoActions).filter(action => action.status === statusId && action.active);
    toast(`${rowData.first_name} ${rowData.last_name}'s request has been marked as "${statuses[rowData.status].label}"`);
    if (!actions.length) { return; }
    const firstTemplateId = actions[0].template;
    const modalType = templates[firstTemplateId].name === REQUEST_MORE_INFORMATION ? CUSTOMIZE_EMAIL_MODAL : ACTION_CONFIRMATION;
    this.props.selectRow({ selectedRow: row });
    this.props.openModal({ modalType });
  }


  render() {
    if (this.state.loading) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }
    return (
      <div className="preclearance-container">
        <ErrorBoundary>
          <ScrollToTopOnMount />
          <PreclearanceModal />
          <Modal />
          <PreclearanceTable
            handleActionConfirmation={this.handleActionConfirmation}
          />
          <TriangleShadow />
        </ErrorBoundary>
      </div>
    );
  }
}

export default PreclearanceBody;

PreclearanceBody.propTypes = {
  autoActions: PropTypes.object.isRequired,
  statuses: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  closePreModal: PropTypes.func.isRequired,
  fetchPreclearanceSettings: PropTypes.func.isRequired,
  selectRow: PropTypes.func.isRequired,
  templates: PropTypes.object.isRequired,
};
