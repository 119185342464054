import React from 'react';
import PropTypes from 'prop-types';
import { CELL } from './constants';


const CellWrapper = ({
  children,
  className,
  width,
  onCellClick,
}) => (
  <div
    className={`${CELL} ${className}`}
    style={{ width }}
    onClick={onCellClick}
  >
    { children }
  </div>
);


CellWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  onCellClick: PropTypes.func,
};

CellWrapper.defaultProps = {
  children: null,
  className: '',
  onCellClick: () => {},
};

export default CellWrapper;
