import React from 'react';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';


export const TextFilter = ({ label }) => (
  <SchemaField
    id="filter"
    label="Filter"
    fieldType="TextField"
    className="form-input"
    placeholder={`Filter by ${label}`}
    autoFocus
    required
  />
);
