/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { convertToHTML } from 'draft-convert';
import { useField } from 'formik';
import StyleButton from './StyleButton';
import { INLINE_STYLES, BLOCK_TYPES } from './consts';


function TextEditor({ name, placeholder }) {
  const [field, _meta, helpers] = useField(name);

  const blocksFromHTML = convertFromHTML(field.value);
  const initState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  );
  const [editorState, setEditorState] = useState(
    () => EditorState.createWithContent(initState),
  );
  const editor = React.useRef(null);

  const focusEditor = () => {
    editor.current.focus();
  };

  useEffect(() => {
    // select all content
    setEditorState((prevEditorState) => {
      const currentContent = prevEditorState.getCurrentContent();
      const selection = prevEditorState.getSelection().merge({
        anchorKey: currentContent.getFirstBlock().getKey(),
        anchorOffset: 0,
        focusOffset: currentContent.getLastBlock().getText().length,
        focusKey: currentContent.getLastBlock().getKey(),
      });
      return (
        EditorState.forceSelection(prevEditorState, selection)
      );
    });
  }, []);

  const onChange = (nextState) => {
    setEditorState(nextState);
    const html = convertToHTML(nextState.getCurrentContent());
    helpers.setValue(html);
  };

  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleKeyCommand = (command, state) => {
    const newState = RichUtils.handleKeyCommand(state, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };


  let editorClassName = 'form-label-input';
  // hide placeholder if bullets and no text otherwise it overlaps and looks weird
  const contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      editorClassName += ' hide-placeholder';
    }
  }

  const currentStyle = editorState.getCurrentInlineStyle();

  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div>
      <div
        className={editorClassName}
        onClick={focusEditor}
      >
        <Editor
          editorState={editorState}
          onChange={onChange}
          handleKeyCommand={handleKeyCommand}
          ref={editor}
          placeholder={placeholder}
        />
      </div>
      <div className="style-buttons">
        <div className="flex-header">
          {
            INLINE_STYLES.map(({ icon, style }) => (
              <StyleButton
                key={style}
                icon={icon}
                style={style}
                onToggle={toggleInlineStyle}
                active={currentStyle.has(style)}
              />
            ))
          }
          {
            BLOCK_TYPES.map(({ icon, style }) => (
              <StyleButton
                key={style}
                icon={icon}
                style={style}
                onToggle={toggleBlockType}
                active={style === blockType}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}

TextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

TextEditor.defaultProps = {
  placeholder: '',
};

export default TextEditor;
