import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import Loading from 'components/loading';
import ManualReports from "../../../reports/manualReports";
import ReportGenerationModal from "../../../reports/generationModal";
import PastExports from "../../../bulkExports/pastExports";
import {
  createReport,
  removeReport,
  loadReports,
  getReport,
} from 'reducers/preclearance/reports/actions';


const Reports = ({
  tableId,
  reports,
  loaded,
  createReport,
  loadReports,
  removeReport,
  getReport,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [modal, setModal] = useState(null);
  const currentReport = reports ? reports.find(r => r.id === activeId) : null;

  const handleReport = async (data) => {
    return await createReport({
      table: tableId,
      ...data,
    })
  }

  const togglePastReports = () => {
    setIsExpanded(!isExpanded);
  }

  const onReportGeneration = (id) => {
    setActiveId(id);
  }

  useEffect(() => {
    loadReports();
  }, [])

  useEffect(() => {
    if (activeId) {
      if (modal) {
        modal.open();
      }
      const interval = setInterval(() => {
        getReport(activeId).then((data) => {
          if (data.is_complete) {
            clearInterval(interval);
          }
        }).catch(() => {
          setActiveId(null);
          clearInterval(interval);
        });
      }, 3000)

      return () => clearInterval(interval);
    }
  }, [activeId])

  if (!loaded) {
    return (
      <div className="load-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div id="reports">
        <ManualReports
          createManualReport={handleReport}
          onNewReportGeneration={onReportGeneration}
          message="This will export an Excel report for all preclearance requests that were submitted within the date range specified. If no preclearance requests were submitted during the specified date range your report will be blank. Please note that all dates are reported as UTC, the results of your report do not take into account your local time zone. You may wish to expand the report date range to account for any time zone offset. Preclearance records recently added or updated may not be immediately reflected in historical reports."
        />
        <hr />
        <button
          className="past-reports-header"
          onClick={togglePastReports}
        >
          <h1>Past Reports</h1>
          {isExpanded ? (
            <i className='material-icons'>expand_less</i>
          ) : (
            <i className='material-icons'>expand_more</i>
          )}
        </button>
        {isExpanded && (
          <div>
            <PastExports
              removeReport={removeReport}
              exportUrl="reports"
              exportType="preclearance report"
              createManualReport={handleReport}
              onNewReportGeneration={onReportGeneration}
              exports={reports}
            />
          </div>
        )}
        <hr />
      </div>
      <ReportGenerationModal
        message="Your report is being generated, this may take a few minutes depending on the number of preclearance requests in the system. If you close this window, it will also be available in the past reports section."
        ref={(el) => setModal(el)}
        report={currentReport}
        createManualReport={handleReport}
        onNewReportGeneration={onReportGeneration}
      />
    </div>
  )
}

Reports.propTypes = {
  tableId: PropTypes.string.isRequired,
  reports: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  createReport: PropTypes.func.isRequired,
  loadReports: PropTypes.func.isRequired,
  removeReport: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  tableId: state.preclearance.table.tableId,
  reports: state.preclearance.reports.reports,
  loaded: state.preclearance.reports.loaded,
})

export default connect(
  mapStateToProps,
  {
    createReport,
    loadReports,
    removeReport,
    getReport,
  }
)(Reports)
