import { cloneDeep } from 'lodash';


export const filterBlanks = field => (field || []).reduce((acc, input) => {
  if (input.trim().length > 0) {
    // trim whitespace otherwise filtering may not work correctly
    acc.push(input.trim());
  }
  return acc;
}, []);

export const serializeSearch = (searchInfo) => {
  const searchRecord = cloneDeep(searchInfo);

  const {
    search_type,
    first_name,
    last_name,
    strict_last_name,
    alt_firsts,
    alt_lasts,
    zips,
    states,
    employers,
    excluded_addresses,
    excluded_employers,
    excluded_occupations,
    all_sources,
  } = searchRecord;


  // MUST filter out blank entries for altFirsts,
  // altLasts, and zipcodes otherwise an error will be thrown!
  const toReturn = {
    search_type,
    search_settings: {
      sources: Array.from(searchRecord.sources),
      all_sources,
      search_json_data: {
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        alt_firsts: filterBlanks(alt_firsts),
        alt_lasts: filterBlanks(alt_lasts),
        zips: filterBlanks(zips),
        employers: filterBlanks(employers),
        states: states || [],
        excluded_addresses: filterBlanks(excluded_addresses),
        excluded_employers: filterBlanks(excluded_employers),
        excluded_occupations: filterBlanks(excluded_occupations),
        strict_last_name: strict_last_name || false,
      },
    },
  };

  return toReturn;
};
