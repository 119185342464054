import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import { updateUser } from 'reducers/user/actions';
import SettingsTable from 'components/shared/settingsTable';
import AlertEmailForm from './AlertEmailForm';

const emailLimit = 15;

const AlertsEmailManager = ({ emails, updateAlertEmails }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const hasAlertEmails = emails.length > 0;

  const addEmail = (email) => {
    setSubmitting(true);
    return updateAlertEmails({
      alert_emails: [...emails, email],
    }).then(() => {
      setIsFormOpen(false);
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const removeEmail = (index) => {
    setSubmitting(true);
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    updateAlertEmails({
      alert_emails: updatedEmails,
    }).then(() => {
      toast('Email removed.');
    }).catch((err) => {
      toast('There was a problem deleting this email.');
      throw err;
    }).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <div>
      <h1>
        Manage Alerts Emails
      </h1>
      <div className="help-text">
        <p>Manage email addresses that receive alerts for new records added to the platform.</p>
      </div>
      { hasAlertEmails || isFormOpen
        ? (
          <SettingsTable
            leftColumn="Email"
            rightColumns={['Delete']}
          >
            { emails.map((email, i) => (
              <div className="table-row" role="row" key={i}>
                <div className="table-cell">
                  <span>{email}</span>
                </div>
                <div className="table-cell text-center">
                  <span>
                    <button
                      onClick={() => { removeEmail(i); }}
                      className="delete text-button"
                      type="button"
                      aria-label="delete"
                      disabled={submitting}
                    >
                      <i className="icon-trash" />
                    </button>
                  </span>
                </div>
              </div>
            )) }
          </SettingsTable>
        )
        : null}
      <div className="table-wrapper">
        <div className="table-container adding">
          { isFormOpen
            ? (
              <div className={`${hasAlertEmails ? 'indent' : 'no-padding'}`}>
                <AlertEmailForm
                  submitting={submitting}
                  onSubmit={addEmail}
                  onClose={() => { setIsFormOpen(false); }}
                />
              </div>
            )
            : (
              <span className={`button-wrapper ${hasAlertEmails ? 'indent' : ''}`}>
                <Tippy
                  disabled={emails.length < emailLimit}
                  content={`There is a limit of ${emailLimit} emails that may receive alerts.`}
                >
                  <span>
                    <button
                      className="text-button uppercase add-field-button"
                      onClick={() => { setIsFormOpen(true); }}
                      disabled={emails.length >= emailLimit}
                      type="button"
                    >
                      + Add email
                    </button>
                  </span>
                </Tippy>
              </span>
            )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user }) => ({
  emails: user.alert_emails,
});

export default connect(
  mapStateToProps,
  { updateAlertEmails: updateUser },
)(AlertsEmailManager);

AlertsEmailManager.propTypes = {
  emails: PropTypes.array.isRequired,
  updateAlertEmails: PropTypes.func.isRequired,
};
