import React from 'react';
import PropTypes from 'prop-types';
import { MatchesStatusSentence } from './actionSentence';
import { BaseItem, BaseEditItem } from './baseItem';
import ActionOptions from './actionOptions';


export const ActionEditItem = ({
  action,
  recordStatuses,
  requestStatuses,
  buttonText,
  onSubmit,
  onClose,
  onFieldChange,
  errors,
  autoActions,
}) => {
  const isInvalid = Object.values(autoActions)
    .some(autoAction => (
      autoAction.id !== action.id &&
      autoAction.request_status === action.request_status
    ));
  const { record_status, request_status } = action;
  return (
    <BaseEditItem
      buttonText={buttonText}
      onSubmit={onSubmit}
      onClose={onClose}
      disabled={isInvalid}
      errors={errors}
      tooltip={isInvalid ? 'Each preclearance status may only be used once.' : ''}
    >
      <MatchesStatusSentence
        recordStatus={(
          <ActionOptions
            options={Object.values(recordStatuses)}
            dropdownText={recordStatuses[record_status] ? recordStatuses[record_status].label : 'Select monitored status'}
            onDropdownSelect={(o) => { onFieldChange(o.id, 'record_status'); }}
            field={'label'}
            disabled={false}
          />
        )}
        requestStatus={(
          <ActionOptions
            options={Object.values(requestStatuses)}
            dropdownText={requestStatuses[request_status] ? requestStatuses[request_status].label : 'Select preclearance status'}
            onDropdownSelect={(o) => { onFieldChange(o.id, 'request_status'); }}
            field={'label'}
            disabled={false}
          />
        )}
      />
    </BaseEditItem>
  );
};

ActionEditItem.propTypes = {
  action: PropTypes.object.isRequired,
  recordStatuses: PropTypes.object.isRequired,
  requestStatuses: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  autoActions: PropTypes.object.isRequired,
  errors: PropTypes.string,
};

ActionEditItem.defaultProps = {
  errors: '',
};


export const ActionItem = ({
  recordStatuses,
  requestStatuses,
  action,
  toggleEditing,
  onToggleActive,
  onDelete,
}) => {
  const { active, record_status, request_status, id } = action;
  const missingRecordStatus = !recordStatuses[record_status] ? 'monitored status' : null;
  const missingRequestStatus = !requestStatuses[request_status] ? 'preclearance status' : null;
  const missingItem = missingRecordStatus || missingRequestStatus;
  const disabled = Boolean(missingItem);
  const disabledTooltip = `The ${missingItem} associated with this action has been removed, please select a new ${missingItem} to reenable.`;

  return (
    <BaseItem
      tooltip={disabled ? disabledTooltip : ''}
      active={active}
      disabled={disabled}
      onToggleActive={() => { onToggleActive(action); }}
      onToggleEdit={() => { toggleEditing(action); }}
      onDelete={() => { onDelete(id); }}
    >
      <MatchesStatusSentence
        recordStatus={recordStatuses[record_status] ? recordStatuses[record_status].label : null}
        requestStatus={requestStatuses[request_status] ? requestStatuses[request_status].label : null}
      />
    </BaseItem>
  );
};


ActionItem.propTypes = {
  recordStatuses: PropTypes.object.isRequired,
  requestStatuses: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  onToggleActive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
