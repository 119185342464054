import React from 'react';
import PropTypes from 'prop-types';
import SearchForm from './forms/contributorSearchFormContainer';
import Results from './results';
import Loading from '../loading';
import { ErrorComponent } from '../errorBoundary';
import CandidateTooltipBody from '../shared/candidateTooltipBody';

const SingleSearchBody = ({ currentSearch, loading, errors }) => {
  const inHeader = !!currentSearch || loading;
  const formClass = `${inHeader ? 'header' : ''} search-form form-wrapper`;
  return (
    <CandidateTooltipBody>
      <div
        className={formClass}
      >
        <SearchForm inHeader={inHeader} />
      </div>
      {
        loading ?
          (
            <div className="results">
              <div className="load-wrapper"><Loading /></div>
            </div>
          ) : null
      }
      {currentSearch && !loading ?
        <div className="results-body">
          {errors ? <ErrorComponent className="results-error" /> : <Results searchId={currentSearch.id} />}
        </div>
        : ''
      }
    </CandidateTooltipBody>
  );
};

SingleSearchBody.propTypes = {
  currentSearch: PropTypes.object,
  loading: PropTypes.bool,
  errors: PropTypes.bool.isRequired,
};

SingleSearchBody.defaultProps = {
  currentSearch: null,
  loading: false,
};

export default SingleSearchBody;
