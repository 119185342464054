import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  EMPLOYEES, EMPLOYEE_PATHS, PATH_NAMES, PEOPLE, PATHS,
} from 'utils/constants';
import CandidateTooltipBody from 'components/shared/candidateTooltipBody';
import SearchObjectResults from 'components/monitoring/searchObjectView';
import { nonSelfSearches, sortSearches, getSelfSearch } from './consts';


function EmployeeSearchResults({ search, personType, shouldDefaultOpen }) {
  const searchObjId = search.search_object_id;
  const { id, searchId } = useParams();
  const { archived } = search;
  return (
    <SearchObjectResults
      searchObjId={searchObjId}
      searchType={EMPLOYEES}
      linkToEdit={`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/${id}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}/${searchObjId}/edit`}
      personType={personType}
      // eslint-disable-next-line eqeqeq
      shouldDefaultOpen={shouldDefaultOpen || searchId == searchObjId}
      alertsActive={search.alerts_active}
      isArchived={archived}
      id={id}
    />
  );
}

EmployeeSearchResults.propTypes = {
  search: PropTypes.object.isRequired,
  personType: PropTypes.string,
  shouldDefaultOpen: PropTypes.bool,
};

EmployeeSearchResults.defaultProps = {
  personType: '',
  shouldDefaultOpen: true,
};


function EmployeeSearches({ employee }) {
  const searches = employee.search_objects;
  const searchObj = getSelfSearch(searches);
  const additionalSearches = nonSelfSearches(searches);

  return (
    <CandidateTooltipBody>
      <div className="result-container-wrapper">
        {searchObj ? (
          <EmployeeSearchResults
            search={searchObj}
            shouldDefaultOpen={!additionalSearches.length}
          />
        )
          : (
            // this should never be hit but just in case
            <div />
          )}
        {sortSearches(additionalSearches).map((search) => (
          <EmployeeSearchResults
            key={search.search_object_id}
            search={search}
            personType={search.search_type}
            shouldDefaultOpen={false}
          />
        ))}
      </div>
    </CandidateTooltipBody>
  );
}


EmployeeSearches.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default EmployeeSearches;
