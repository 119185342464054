import React from 'react';
import PropTypes from 'prop-types';
import { JURISDICTION } from 'utils/constants';
import { PRE_ROW_SCHEMA, RECORD_ROW_SCHEMA, ASC, DESC, MATCHES } from 'components/shared/matching/consts';
import MatchesSection from 'components/shared/matching/matchesSection';
import PreclearanceMatchingTable from 'components/shared/matching/matchingTables/preclearanceMatchingTable';
import MatchButtons from 'components/shared/matching/matchButtons';
import Schema from 'components/shared/sortableSchema';
import RecordMatchingTable from './matchingTables/recordMatchingTable';

const rowColumns = [
  { id: 'status', label: 'Status' },
  { id: 'first_name', label: 'First Name' },
  { id: 'last_name', label: 'Last Name' },
  ...PRE_ROW_SCHEMA,
];


const MatchingContainer = ({
  closeModal, loading, rows, columns, sort, onSort, selectedRow, handleRowSelect,
  matches, createMatch, markAsNoMatch, filtersComponent, toggleComponent,
  suggestedMatchToggle, loadMoreRows, endCount, recordToBeMatched, noMatch, undoNoMatch,
}) => (
  <div className="matching-modal matching-container on-preclearance">
    <div>
      <h4>
        Preclearance request
      </h4>
      <div>
        <PreclearanceMatchingTable
          columns={rowColumns}
          rows={[recordToBeMatched]}
        />
      </div>
    </div>
    <hr />
    { matches.length ?
      <MatchesSection
        matches={matches}
        columns={RECORD_ROW_SCHEMA}
      />
      : ''
    }
    <div>
      <h4>Monitored records</h4>
    </div>
    {filtersComponent}
    <div className="matching-toggle-wrapper">
      {suggestedMatchToggle}
      {toggleComponent}
    </div>
    <div>
      <RecordMatchingTable
        loading={loading}
        columns={columns}
        rows={rows}
        fetchMoreRecords={loadMoreRows}
        endCount={endCount}
        selectedRow={selectedRow}
        handleRowSelect={handleRowSelect}
        matches={matches}
        schema={
          <Schema
            schema={columns}
            sortBy={sort.sortBy}
            sortDirection={sort.sortDirection}
            onLabelClick={(sortBy) => {
              onSort({ sortBy, sortDirection: sort.sortDirection === ASC ? DESC : ASC });
            }}
            disabledSortIds={[JURISDICTION, MATCHES]} // sorts are broken on BE so disable for now
          />
        }
      />
      <MatchButtons
        onCancel={closeModal}
        markAsNoMatch={noMatch ? undoNoMatch : markAsNoMatch}
        createMatch={createMatch}
        nomatchDisabled={Boolean(matches.length)}
        noMatch={noMatch}
      />
    </div>
  </div>
);


MatchingContainer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  sort: PropTypes.object.isRequired,
  onSort: PropTypes.func.isRequired,
  selectedRow: PropTypes.number,
  handleRowSelect: PropTypes.func.isRequired,
  createMatch: PropTypes.func.isRequired,
  markAsNoMatch: PropTypes.func.isRequired,
  matches: PropTypes.array,
  filtersComponent: PropTypes.object.isRequired,
  toggleComponent: PropTypes.object.isRequired,
  suggestedMatchToggle: PropTypes.object,
  loadMoreRows: PropTypes.func.isRequired,
  endCount: PropTypes.number.isRequired,
  recordToBeMatched: PropTypes.object,
  noMatch: PropTypes.bool.isRequired,
  undoNoMatch: PropTypes.func.isRequired,
};

MatchingContainer.defaultProps = {
  selectedRow: null,
  matches: [],
  recordToBeMatched: {},
  suggestedMatchToggle: {},
};

export default MatchingContainer;
