import React from 'react';
import PropTypes from 'prop-types';
import {
  FIELD_TYPES,
  RADIO,
  CHECKBOX,
} from '../../consts';
import ChoicesField from './ChoicesField';
import SchemaQuestion from './SchemaQuestion';


const QuestionInput = ({ fieldId, inputType, ...props }) => {
  let Field;

  switch (inputType) {
    case RADIO:
      Field = ChoicesField;
      break;
    case CHECKBOX:
      Field = ChoicesField;
      break;
    default:
      Field = SchemaQuestion;
  }
  return (
    <Field
      id={fieldId}
      fieldId={fieldId}
      inputType={inputType}
      disabled
      {...props}
    />
  );
};

QuestionInput.propTypes = {
  inputType: PropTypes.oneOf(Object.keys(FIELD_TYPES)).isRequired,
  fieldId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

export default QuestionInput;
