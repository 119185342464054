import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useField, ErrorMessage } from 'formik';

import { getSortedTags } from 'reducers/tags/selectors';
import NoTags from 'components/shared/tags/dropdown/noTags';
import { CheckboxGroup } from 'components/shared/SchemaForm/components/CheckboxGroup';


const Tags = ({ tags, fieldName }) => {
  const [field] = useField({ name: fieldName });

  if (!tags.length) {
    return (
      <React.Fragment>
        <NoTags />
        <ErrorMessage
          name={field.name}
          component="div"
          className="error-text"
        />
      </React.Fragment>

    );
  }

  return (
    <CheckboxGroup
      name={field.name}
      choices={tags.map(tag => ({
        value: tag.id,
        label: tag.name,
      }))}
      multiple
    />
  );
};

Tags.propTypes = {
  fieldName: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = state => ({
  tags: getSortedTags(state),
});

const ConnectedTags = connect(mapStateToProps)(Tags);
export { ConnectedTags as Tags };
