import { useState, useEffect } from 'react';
import api from 'api';
import { toast } from 'react-toastify';


export const fetchCertificationTemplates = () => (
  api.get('/attestations/templates')
);

export const fetchCertifications = () => (
  api.get('/attestations/certifications')
);

/* filter out templates that are not recurring and have already been
sent since editing / deleting them does not make ux sense */
export const filterTemplates = (templates, certifications) => {
  const templateIds = certifications.map(cert => cert.template);
  const templateSet = new Set(templateIds);
  return templates.filter((template) => {
    const recurring = template.schedule && template.schedule.is_recurring;
    return recurring || (!recurring && !templateSet.has(template.id));
  });
};


function useCertifications() {
  const [certifications, setCertifications] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let didCancel = false;
    Promise.all([
      fetchCertifications(),
      fetchCertificationTemplates(),
    ]).then((responses) => {
      if (!didCancel) {
        const certs = responses[0].data;
        const temps = responses[1].data;
        const filteredTemplates = filterTemplates(temps, certs);
        setTemplates(filteredTemplates);
        setCertifications(certs);
      }
    }).catch((err) => {
      if (!didCancel) {
        toast('Something went wrong and we were unble to fetch your certifications.');
      }
      throw (err);
    }).finally(() => {
      if (!didCancel) {
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, []);
  return ({
    templates,
    certifications,
    loading,
  });
}

export default useCertifications;
