import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import StateDropdown from '../stateDropdown';


function StateDropdownWrapper({ field, selected, ...props }) {
  const { setFieldValue } = useFormikContext();

  function handleSelectState(e, selectedState) {
    setFieldValue(field, [...selected, selectedState]);
  }

  function handleUnselectState(e, selectedState) {
    const updatedStates = [...selected].filter(state => state !== selectedState);
    setFieldValue(field, updatedStates);
  }

  return (
    <StateDropdown
      handleSelectState={handleSelectState}
      handleUnselectState={handleUnselectState}
      selectedStates={selected}
      {...props}
    />
  );
}

StateDropdownWrapper.propTypes = {
  selected: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
};

export default StateDropdownWrapper;
