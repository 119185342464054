import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const loginModal = () => (
  <div className="login-prompt modal-container">
    <div className="modal-box">
      <h2 className="modal-header">Your session has expired.</h2>
      <div className="modal-message">
        Please log back in to renew your session.
      </div>
      <div className="button-wrapper">
        <a href="/login">
          <button className="orange-button">log in</button>
        </a>
      </div>
    </div>
  </div>
);

const SessionBoundary = ({ sessionExpired, children }) => {
  if (sessionExpired) {
    return (
      <div>
        {loginModal()}
        {children}
      </div>
    );
  }
  return children;
};

const mapStateToProps = ({ ui: { sessionExpired } }) => ({
  sessionExpired,
});

export default withRouter(connect(mapStateToProps)(SessionBoundary));
