import React from 'react';
import { Field, useField } from 'formik';

import { FORM_ELEMENT } from '../constants';

const EnumField = ({ ...props }) => {
  const [field, _meta, helpers] = useField(props);
  const choices = props.choices || [];
  const groupId = `radio-group-${props.id}`;

  return (
    <ul className="radio-ul" role="group" aria-labelledby={groupId}>
      {choices.map(([value, name]) => (
        <li key={value}>
          <label>
            <Field
              type="radio"
              name={field.name}
              value={value}
              className="radio-ul"
            />
            <span>{name}</span>
          </label>
        </li>
      ))}
      {
        !props.required && !props.readOnly && field.value
          ? (
            <div className="clear-selection">
              <button
                type="button"
                className="text-button uppercase"
                onClick={() => helpers.setValue('')}
              >
                clear selection
              </button>
            </div>
          )
          : null
      }
    </ul>
  );
};

EnumField.propTypes = {
  ...FORM_ELEMENT,
};

export { EnumField };
