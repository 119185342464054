import React from 'react';
import PropTypes from 'prop-types';
import OnOffToggle from '../../../../shared/onOffToggle';

const NotificationItem = ({ notification, onToggleActive, onDelete }) => {
  return (
    <div className={`${notification.active ? '' : 'inactive '}table-row`}>
      <div className="table-cell">
        <span>
          {notification.email}
        </span>
      </div>
      <div className="text-center table-cell">
        <span>
          <OnOffToggle
            active={notification.active}
            onToggle={() => { onToggleActive(notification); }}
          />
        </span>
      </div>
      <div className="text-center table-cell">
        <span>
          <button className="delete text-button" onClick={() => { onDelete(notification.id); }}>
            <i className="icon-trash" />
          </button>
        </span>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onToggleActive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default NotificationItem;
