import * as types from '../types';
import api from 'api';

const addReport = data => ({
  report: data,
  type: types.ADD_REPORT,
});

const setLoaded = data => ({
  reports: data,
  type: types.SET_LOADED,
});

const deleteReport = id => ({
  id,
  type: types.DELETE_REPORT,
});


export const loadReports = () => dispatch =>
  api.get('/pre/reports/')
    .then(({ data }) => {
      dispatch(setLoaded(data));
    });

export const createReport = reportData => dispatch =>
  api.post('/pre/reports/', reportData)
    .then(({ data }) => {
      dispatch(addReport(data));
      return data;
    });

export const removeReport = id => dispatch =>
  api.delete(`/pre/reports/${id}/`)
    .then(() => {
      dispatch(deleteReport(id));
    });

export const getReport = id => dispatch =>
  api.get(`/pre/reports/${id}/`)
    .then(({ data }) => {
      dispatch(addReport(data));
      return data;
    })
