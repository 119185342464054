/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { parseField } from 'helpers/formatFieldHelpers';


const RECIPIENT = 'recipient';

const RecordCell = ({
  schema,
  onRecipientEnter,
  onRecipientExit,
  data,
  hasFields,
  showCcd,
}) => {
  const { name, url, type } = schema;
  const isRecipient = name === RECIPIENT;

  const cellVal = (data[name] !== null && data[name] !== '') ? parseField(data[name], type) : 'N/A';
  const sanitizedVal = DOMPurify.sanitize(cellVal);

  return (
    <div
      data-cell={name}
      className="dashboard-cell"
      onMouseEnter={isRecipient ? onRecipientEnter : null}
      onMouseLeave={isRecipient ? onRecipientExit : null}
    >
      <div
        className="record-cell"
      >
        <span>
          {
            url && data[url] ? (
              <a
                href={data[url]}
                target="_blank"
                rel="noopener noreferrer"
                dangerouslySetInnerHTML={{
                  __html: sanitizedVal,
                }}
              />
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: sanitizedVal }}
              />
            )
          }
          {
            isRecipient && hasFields() && showCcd ? (
              <i className="material-icons">search</i>
            ) : (
              null
            )
          }
        </span>
      </div>
    </div>
  );
};

RecordCell.propTypes = {
  schema: PropTypes.object.isRequired,
  onRecipientEnter: PropTypes.func.isRequired,
  onRecipientExit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  hasFields: PropTypes.func.isRequired,
  showCcd: PropTypes.bool.isRequired,
};


export default RecordCell;
