import React from 'react';
import { components } from 'react-select';
import QuestionItemContent from 'components/FormBuilder/Sidebar/QuestionItemContent';


export const OptionWithIcon = (props) => {
  const { data: { label, icon } } = props;
  const { Option } = components;
  return (
    <Option {...props}>
      <QuestionItemContent
        icon={icon}
        label={label}
      />
    </Option>
  );
};


export const ValueWithIcon = (props) => {
  const { data: { label, icon } } = props;
  const { SingleValue } = components;
  return (
    <SingleValue {...props}>
      <QuestionItemContent
        icon={icon}
        label={label}
      />
    </SingleValue>
  );
};
