import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { PATH_NAMES, PEOPLE, PATHS } from 'utils/constants';
import { fetchEmployee as getEmployee } from 'reducers/employees/actions';
import Loading from 'components/loading';


function EmployeeWrapper({ children, fetchEmployee }) {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    let didCancel = false;
    fetchEmployee(id)
      .then(() => {
      }).catch((err) => {
        if (!didCancel) {
          history.push(`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}`);
          toast('There was a problem getting the details for this employee.');
          throw err;
        }
      }).finally(() => {
        if (!didCancel) {
          setLoading(false);
        }
      });

    return () => {
      didCancel = true;
    };
  }, [id, history, fetchEmployee]);


  return (
    loading ? (
      <div className="result-loader-container">
        <Loading />
      </div>
    )
      : children
  );
}


EmployeeWrapper.propTypes = {
  fetchEmployee: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};


export default withRouter(connect(null, {
  fetchEmployee: getEmployee,
})(EmployeeWrapper));
