import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, useField } from 'formik';
import DOMPurify from 'dompurify';
import Tippy from '@tippyjs/react';

import {
  TextField,
  DecimalField,
  IntegerField,
  DateField,
  EnumField,
  SelectField,
  AutocompleteField,
  CheckboxField,
  CheckboxGroup,
  ArrayField,
  SearchableSelectField,
} from './components';
import { FORM_ELEMENT, FIELD_TYPES } from './constants';

const SchemaField = ({
  id,
  label,
  fieldType,
  dependsOn,
  tooltip,
  readOnly,
  labelClass,
  skipValidation,
  preclearanceModule,
  ...props
}) => {
  const { values } = useFormikContext(),
    [field, meta, helpers] = useField(id),
    [isHidden, setIsHidden] = useState(false),
    dependentValue = dependsOn ? values[dependsOn.fieldId] : null; // todo dependsOn label is different for certifications

  if (!FIELD_TYPES.has(fieldType)) {
    console.warn(`Invalid field type "${fieldType}"`);
    return null;
  }


  let Field;
  let fieldLabel;
  let showErrors = true;

  switch (fieldType) {
    case 'TextField':
      Field = TextField;
      break;
    case 'IntegerField':
      Field = IntegerField;
      break;
    case 'DecimalField':
      Field = DecimalField;
      break;
    case 'DateField':
      Field = DateField;
      break;
    case 'EnumField':
      Field = EnumField;
      break;
    case 'SelectField':
      Field = SelectField;
      break;
    case 'AutocompleteField':
      Field = AutocompleteField;
      break;
    case 'CheckboxField':
      Field = CheckboxField;
      fieldLabel = label;
      break;
    case 'CheckboxGroupField':
      Field = CheckboxGroup;
      break;
    case 'ArrayField':
      Field = ArrayField;
      showErrors = false;
      break;
    case 'SearchableSelectField':
      Field = SearchableSelectField;
      break;
    case 'PreclearanceTable':
      Field = preclearanceModule;
      fieldLabel = '';
      break;
    default:
      Field = 'input';
  }

  const validate = (value) => {
    if (skipValidation) {
      return;
    }
    if (props.required && ['', null, undefined, false].includes(value)) {
      return 'This field is required.';
    }
  };

  useEffect(() => {
    if (dependsOn) {
      if (dependsOn.values.includes(dependentValue)) {
        setIsHidden(false);
      } else {
        setIsHidden(true);
        helpers.setValue(meta.initialValue);
      }
    }
  }, [dependentValue]);

  if (isHidden) {
    return null;
  }

  const labelString = `${label} ${props.required ? '<span class="required-asterisk">*</span>' : ''}`;
  const cleanLabel = DOMPurify.sanitize(labelString);

  return (
    <div style={{ display: props.hidden ? 'none' : 'initial' }}>
      <Tippy content={tooltip} disabled={!tooltip}>
        <div>
          <div className={labelClass}>
            {!fieldLabel && label && (
              <label
                htmlFor={id}
                dangerouslySetInnerHTML={{
                  __html: cleanLabel,
                }}
              />
            )}
            { props.infoText && (
              <span className="tooltip-wrapper">
                <Tippy content={props.infoText}>
                  <i className="material-icons">info</i>
                </Tippy>
              </span>
            )}
          </div>
          <Field
            id={id}
            name={id}
            validate={validate}
            readOnly={readOnly ? 'readonly' : false}
            label={fieldLabel}
            {...props}
          />
          {meta.touched && meta.error && showErrors && (
            <p className="error-text">{meta.error}</p>
          )}
        </div>
      </Tippy>
    </div>
  );
};

SchemaField.propTypes = {
  ...FORM_ELEMENT,
  className: PropTypes.string,
  labelClass: PropTypes.string,
  skipValidation: PropTypes.bool,
};

SchemaField.defaultProps = {
  required: false,
  hidden: false,
  choices: null,
  fieldType: 'TextField',
  dependsOn: null,
  labelClass: 'input-title',
  className: '',
  skipValidation: false,
};

export { SchemaField };
