import React from 'react';
import PropTypes from 'prop-types';

// using keys corresponding to attribute names on user object
const [ACTIVE, NAME, EMAIL, MANAGER, TWO_FACTOR, EDIT, DELETE] = ['is_active', 'full_name', 'email', 'is_manager', 'two_factor_enabled', 'EDIT', 'DELETE'];

const genericColumns = [ACTIVE, NAME, EMAIL, MANAGER, EDIT, DELETE];
const twoFactorColumns = [ACTIVE, NAME, EMAIL, MANAGER, TWO_FACTOR, EDIT, DELETE];

const headerMap = {
  [ACTIVE]: { name: 'active', className: '' },
  [NAME]: { name: 'name', className: '' },
  [EMAIL]: { name: 'email', className: '' },
  [MANAGER]: { name: 'manager', className: 'text-center' },
  [TWO_FACTOR]: { name: 'two-factor', className: 'text-center' },
  [EDIT]: { name: 'edit', className: 'text-center' },
  [DELETE]: { name: 'delete', className: 'text-center' },
};

function TableHeader({ headers }) {
  return (
    <thead>
      <tr>
        {
          headers.map((header, i) => <th key={i} className={header.className}>{header.name}</th>)
        }
      </tr>
    </thead>
  );
}

TableHeader.propTypes = {
  headers: PropTypes.array.isRequired,
};

const valueFields = {
  [ACTIVE]: (val, k) => <td key={k}>{val ? <i className="material-icons">check</i> : null}</td>,
  [NAME]: (val, k) => <td key={k}>{val}</td>,
  [EMAIL]: (val, k) => <td key={k}>{val}</td>,
  [MANAGER]: (val, k) => <td key={k} className="text-center">{val ? <i className="material-icons">check</i> : null}</td>,
  [TWO_FACTOR]: (val, k) => <td key={k} className="text-center">{val ? <i className="material-icons">check</i> : null}</td>,
  [EDIT]: () => null,
  [DELETE]: () => null,
};

function TableRow({ row, columns, onEdit, onDelete }) {
  return (
    <tr>
      {columns.map((column, i) => valueFields[column](row[column], i))}
      <td key={6} className="text-center"><i className="icon-pencil" onClick={onEdit} /></td>
      <td key={7} className="text-center"><i className="icon-trash" onClick={onDelete} /></td>
    </tr>
  );
}

TableRow.propTypes = {
  row: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function UserTable({ rows, onEdit, onDelete, showTwoFactor }) {
  const columns = showTwoFactor ? twoFactorColumns : genericColumns;
  return (
    <div className="table-responsive userTable">
      <table className="table">
        <TableHeader headers={columns.map(col => headerMap[col])} />
        <tbody>
          {rows.map((row, i) => (
            <TableRow
              key={i}
              row={row}
              columns={columns}
              onEdit={onEdit(row)}
              onDelete={onDelete(row)}
            />))}
        </tbody>
      </table>
    </div>
  );
}

UserTable.propTypes = {
  rows: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  showTwoFactor: PropTypes.bool.isRequired,
};

export default UserTable;
