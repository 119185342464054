import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';

import api from 'api';
import { PATHS, PATH_NAMES } from 'utils/constants';
import ErrorBoundary from 'components/errorBoundary';
import Loading from 'components/loading';
import { CertificationSurveyForm } from './CertificationSurveyForm';
import CertificationSurveySubmission from './CertificationSurveySubmission';
import { INITIAL_DATA } from './consts';


const CertificationSurvey = ({ isPreview }) => {
  const [payload, setPayload] = useState(INITIAL_DATA);
  const [isLoading, setIsLoading] = useState(!isPreview);
  const { surveyId } = useParams();
  const dataUrl = isPreview ? '/attestations/templates/fields/default' : `/public/attestations/certifications/${surveyId}`;
  const history = useHistory();

  useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      api.get(dataUrl).then(({ data }) => {
        const survey = isPreview ? { ...INITIAL_DATA, fields: data } : data;
        setPayload(survey);
        setIsLoading(false);
      }).catch((err) => {
        if (err.response) {
          const { status } = err.response;
          if (status === 403) {
            history.push(`${PATHS[PATH_NAMES.not_authorized]}`);
          } else if (status === 404) {
            history.push(`${PATHS[PATH_NAMES.not_found]}`);
          }
        }
        throw err;
      });
    }
    return () => {
      didCancel = true;
    };
  }, [dataUrl, isPreview]);


  if (isLoading) {
    return (
      <div className="load-screen">
        <Loading />
      </div>
    );
  }

  const {
    start_date: startDate,
    end_date: endDate,
    submitted,
    attestation_id: attestationId,
  } = payload;

  return (
    <ErrorBoundary>
      {
        submitted && attestationId ?
          <CertificationSurveySubmission
            fields={payload.fields}
            startDate={startDate}
            endDate={endDate}
            header={payload.header}
            footer={payload.footer}
            submissionId={attestationId}
            organization={payload.organization}
          />
          :
          <CertificationSurveyForm
            isPreview={isPreview}
            payload={payload}
          />
      }
    </ErrorBoundary>

  );
};

CertificationSurvey.propTypes = {
  isPreview: PropTypes.bool,
};

CertificationSurvey.defaultProps = {
  isPreview: false,
};

export { CertificationSurvey };
