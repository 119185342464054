import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';

const ErrorListener = ({ onError }) => {
  const { errors } = useFormikContext();

  useEffect(() => {
    if (!isEmpty(errors)) {
      onError(errors);
    }
  }, [errors]);

  return null;
};

ErrorListener.propTypes = {
  onError: PropTypes.func.isRequired,
};

export { ErrorListener };
