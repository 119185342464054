import React from 'react';

const NotAuthorized = () => (
  <div className="notFound">
    <div>
      <div className="message">
        <span className="number">
          403
        </span>
        <span className="divider" />
        <span className="description">
          Not Authorized
        </span>
      </div>

      <span className="description">
          You do not have permission to access this page, please contact your system administrator for more information.
      </span>
    </div>
  </div>
);

export default NotAuthorized;
