import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import Tippy from '@tippyjs/react';


// not being used in SchemaField but is used in other formik form implementations
const Checkbox = ({ name, label, value, tooltip }) => (
  <Tippy content={tooltip} disabled={!tooltip}>
    <div className="checkbox">
      {/* must be a string because of this issue https://github.com/formium/formik/issues/2775 */}
      <Field id={value} name={name} type="checkbox" value={value.toString()} />
      <label htmlFor={value}>
        <span>{label}</span>
      </label>
    </div>
  </Tippy>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

Checkbox.defaultProps = {
  tooltip: null,
};


const CheckboxGroup = ({ name, label, value, multiple, choices }) => {
  if (multiple) {
    if (!Array.isArray(choices)) {
      console.error('"choices" is required when "multiple" is true');
    }

    return (
      <React.Fragment>
        <div role="group" className="multi-checkbox">
          {choices.map(choice => (
            <Checkbox
              key={choice.value}
              label={choice.label}
              name={name}
              value={choice.value}
              tooltip={choice.tooltip}
            />
          ))}
        </div>
        <ErrorMessage
          name={name}
          component="div"
          className="error-text"
        />
      </React.Fragment>
    );
  }
  if (!value) {
    console.error('"value" is required when "multiple" is false');
  }

  return <Checkbox label={label} name={name} value={value} />;
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Date),
  ]),
  label: PropTypes.string,
  multiple: PropTypes.bool,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

CheckboxGroup.defaultProps = {
  value: undefined,
  label: undefined,
  multiple: false,
  choices: [],
};

export { CheckboxGroup };
