import * as types from './types';

const initState = {};

const customFields = (state = initState, action) => {
  switch (action.type) {
    case types.LOAD_FIELDS: {
      const nextState = {};
      action.data.reduce((_allFields, field) => {
        nextState[field.id] = field;
        return nextState;
      }, {});
      return nextState;
    }
    case types.ADD_FIELD: {
      const nextState = { ...state };
      nextState[action.data.id] = action.data;
      return nextState;
    }
    case types.UPDATE_FIELD: {
      const nextState = { ...state };
      nextState[action.data.id] = action.data;
      return nextState;
    }
    case types.DELETE_FIELD: {
      const nextState = { ...state };
      delete nextState[action.id];
      return nextState;
    }
    default:
      return state;
  }
};

export default customFields;
