import { combineReducers } from 'redux';
import { EMPLOYEES, ENTITIES } from 'utils/constants';
import byId from './byId';
import createList from './createList';


const listByType = combineReducers({
  [EMPLOYEES]: createList(EMPLOYEES),
  [ENTITIES]: createList(ENTITIES),
});


const searchObjects = combineReducers({
  byId,
  listByType,
});

export default searchObjects;
