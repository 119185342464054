import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';


const HiddenForm = ({
  url,
  data,
}) => {
  const form = useRef(null);
  useEffect(() => {
    if (form.current) {
      form.current.submit();
    }
  }, []);

  return (
    <form
      method="POST"
      style={{ display: 'none' }}
      action={url}
      ref={form}
    >
      {
        Object.keys(data).map((key) => (
          <input
            key={key}
            name={key}
            value={data[key]}
            readOnly
            type="hidden"
          />
        ))
      }
    </form>
  );
};

HiddenForm.propTypes = {
  url: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default HiddenForm;
