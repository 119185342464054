import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { EMPLOYEE_PATHS, PATH_NAMES } from 'utils/constants';
import { byId } from 'reducers/searchObjects/selectors';
import IconTextButton from 'components/monitoringForm/iconTextButton';


function SpouseDependentItem({ id, name }) {
  const { url } = useRouteMatch();

  return (
    <span className="custom-field-item">
      <span className="table-row">
        <span className="table-cell">
          {name}
        </span>
        <Link
          className="text-center table-cell"
          to={`${url}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}/${id}/edit`}
        >
          <IconTextButton
            icon={'edit'}
            buttonText={''}
            className={'edit-button'}
          />
        </Link>
      </span>
    </span>
  );
}

SpouseDependentItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};


const mapStateToProps = (state, { id }) => {
  const searchObject = byId(state, id);
  const { first_name: firstName, last_name: lastName } = searchObject || {};
  const name = searchObject ? `${firstName} ${lastName}` : '';
  return {
    name,
  };
};

export default connect(mapStateToProps, {})(SpouseDependentItem);
