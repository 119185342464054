import get from 'lodash/get';
import moment from 'moment';
import * as Papa from 'papaparse';
import {
  ADD_ROW,
  ADD_ROW_BUTTON_HEIGHT,
  ADD_COLUMN,
  ADD_ATTACHMENT,
  ADD_ATTACHMENT_WIDTH,
  ROW_HEIGHT,
  ADD_COLUMN_BUTTON_WIDTH,
  HEADER_ROW_HEIGHT,
  STATUS,
  MATCHES,
  FIXED_LEFT_COLUMN_IDS,
  MATCH_COL_WIDTH,
  SUBMISSION_DATE,
  SUBMISSION_DATE_WIDTH,
  DATE,
} from '../constants';
import { formatAmount, formatDate } from 'helpers/formatFieldHelpers';
import { serializeColumns, createColumn } from './column';
import Row from './row';


// empty space at top to line up with 'add row button' on left side
const ADD_ROW_PLACEHOLDER = {
  placeholder: true,
  type: ADD_ROW,
  height: 25,
  data: {},
};

const ADD_COLUMN_PLACEHOLDER = {
  placeholder: true,
  type: ADD_COLUMN,
  id: ADD_COLUMN,
  label: '+',
  width: ADD_COLUMN_BUTTON_WIDTH,
};

const ADD_ATTACHMENT_PLACEHOLDER = {
  placeholder: true,
  type: ADD_ATTACHMENT,
  id: ADD_ATTACHMENT,
  label: 'Attachments',
  width: ADD_ATTACHMENT_WIDTH,
};

const MATCH_COL_PLACEHOLDER = {
  placeholder: true,
  type: MATCHES,
  id: MATCHES,
  label: 'Matched monitored record',
  width: MATCH_COL_WIDTH,
};

const SUBMISSION_DATE_PLACEHOLDER = {
  placeholder: true,
  type: DATE,
  id: SUBMISSION_DATE,
  label: 'Submission date',
  width: SUBMISSION_DATE_WIDTH,
  readOnly: true,
};


const getMatches = (row, showAutomatches) => {
  const { matches, no_match: noMatch, automatches } = row;
  let match = '';
  if (matches.length === 0 && noMatch) {
    match = 'no match';
  } else if (matches.length > 0 && !noMatch) {
    const matchString = matches.map(match => {
      const {
        contributor, address, date, amount, employer, recipient,
      } = match;
      return `Contributor: ${contributor}\n`
             + `Address: ${address}\n`
             + `Date: ${formatDate(date)}\n`
             + `Amount: ${formatAmount(amount)}\n`
             + `Employer: ${employer}\n`
             + `Recipient: ${recipient}`;
    });
    match = matchString.join('\n\n');
  } else if (showAutomatches && matches.length === 0 && !noMatch && automatches.length > 0) {
    match = `${automatches.length} potential match(es)`;
  }
  return match;
};

// _colMap: orderable columns with data

// a helper class to manage all the grid logic of the preclearance table,
//  such as accessing, adding, and deleting rows, columns, and cell values.
//  should be separate from presentational logic.
export class PreclearanceTableHelper {
  constructor({
    rows, columns, leftColumnIds, showAutomatches, jurisdictions, statuses,
  }) {
    this.jurisdictions = jurisdictions;
    this.statuses = statuses;
    this.showAutomatches = showAutomatches;

    // to do figure out where source of truth is
    const serializedColumns = serializeColumns(columns, this.statuses);
    this._colMap = serializedColumns;
    this._leftColumnIds = leftColumnIds;
    this._colOrd = Object.values(this._colMap).map((col) => col.id);
    this._originalOrd = Object.assign([], this._colOrd);

    this._rows = rows.map((row) => new Row(row, this._colMap, this.jurisdictions));
    this.setCurrencyColId();
  }

  rows() {
    if (!this._rows.length) return [];
    return [ADD_ROW_PLACEHOLDER, ...this._rows];
  }

  rowsToRender() {
    if (!this._rows.length) return [];
    const rows = [ADD_ROW_PLACEHOLDER, ...this._rows];
    return rows;
  }

  orderedInternalIds() {
    return this.columnsWithData().map((col) => col._id);
  }

  columns() {
    return Object.values(this.colMap());
  }

  columnIdsWithData() {
    return [...this._colOrd];
  }

  // ordered
  columnsWithData() {
    return [...(this._colOrd).map((id) => this._colMap[id])];
  }

  // ordered
  rightColumns() {
    return this.colOrd().map((colId) => this.colMap()[colId])
      .filter((col) => (FIXED_LEFT_COLUMN_IDS.indexOf(col.id) < 0));
  }

  leftColumns() {
    const columns = this._leftColumnIds.map((col) => this.getColumn(col));
    return columns;
  }


  colMap() {
    return {
      ...this._colMap,
      [MATCHES]: MATCH_COL_PLACEHOLDER,
      [ADD_ATTACHMENT]: ADD_ATTACHMENT_PLACEHOLDER,
      [ADD_COLUMN]: ADD_COLUMN_PLACEHOLDER,
      [SUBMISSION_DATE]: SUBMISSION_DATE_PLACEHOLDER,
    };
  }

  getRow({ index }) {
    return this.rowsToRender()[index] || ADD_ROW_PLACEHOLDER;
  }

  rowHeight({ index }) {
    return get(this.rows()[index], 'height', ROW_HEIGHT);
  }

  getColumn(colId) {
    return this.colMap()[colId];
  }

  colOrd() {
    return [...this._colOrd, SUBMISSION_DATE, MATCHES, ADD_ATTACHMENT, ADD_COLUMN];
  }

  updateColOrd(newOrd) {
    this._colOrd = [...this._leftColumnIds, ...newOrd];
  }

  originalColOrd() {
    return [...this._originalOrd, SUBMISSION_DATE, MATCHES, ADD_ATTACHMENT, ADD_COLUMN];
  }

  originalOrderedColumns() {
    // return right columns in their original order
    return this.originalColOrd().map((colId) => this.colMap()[colId]);
  }

  orderedColumns() {
    return this.colOrd().map((colId) => this.colMap()[colId]);
  }

  getCell(colId, rowIdx) {
    const cell = this.rows()[rowIdx].data[colId];
    return cell;
  }

  setCurrencyColId() {
    const currencyCol = this.columns().find((col) => col.label.toLowerCase() === 'currency');
    this.currencyColId = currencyCol ? currencyCol.id : null;
  }

  clearCurrencyColId(colId) {
    if (colId === this.currencyColId) {
      this.currencyColId = null;
    }
  }

  getCurrency(row) {
    if (!this.currencyColId) return null;
    return row.data[this.currencyColId].value;
  }

  makeBlankRow() {
    const newRow = { data: {} };
    this.columnsWithData().forEach(({ id: colId, defaultValue }) => {
      const newCell = { value: defaultValue || '', colId };
      if (colId === STATUS) {
        const defaultStatus = Object.values(this.statuses).find((status) => status.default);
        newCell.value = defaultStatus.id;
      }
      newRow.data[colId] = newCell;
    });
    newRow.data[ADD_ATTACHMENT] = ADD_ATTACHMENT_PLACEHOLDER;
    newRow.files = [];
    newRow.data[MATCHES] = MATCH_COL_PLACEHOLDER;
    newRow.matches = [];
    newRow[SUBMISSION_DATE] = SUBMISSION_DATE_PLACEHOLDER;
    newRow.data[SUBMISSION_DATE] = {
      ...SUBMISSION_DATE_PLACEHOLDER,
      value: moment().format('MM/DD/YYYY'),
    };
    return newRow;
  }

  addRow(row) {
    const newRow = new Row(row, this._colMap, this.jurisdictions);
    this._rows.unshift(newRow);
  }

  replaceRows(rows) {
    const serializedRows = rows.map((row) => new Row(row, this._colMap, this.jurisdictions));
    this._rows = serializedRows;
  }

  deleteRow(idx) {
    this._rows.splice(idx - 1, 1);
  }

  // column: a column successfully saved, directly from API (or redux state)
  addColumn(column) {
    const newCol = createColumn(column);
    this._colOrd.push(newCol.id);
    this._originalOrd.push(newCol.id);
    this._colMap[newCol.id] = newCol;
    this._rows.forEach((row) => row.addCell(newCol.defaultValue, newCol.id, row.id));
    this.setCurrencyColId();
  }

  deleteColumn(colId) {
    this._colOrd.splice(this._colOrd.indexOf(colId), 1);
    this._originalOrd.splice(this._originalOrd.indexOf(colId), 1);
    delete this._colMap[colId];
    this._rows.forEach((row) => { row.deleteColumn(colId); });
    this.clearCurrencyColId(colId);
  }

  // assuming all rows are the same height
  totalRowHeight() {
    return ROW_HEIGHT * this._rows.length;
  }

  calcNewCellCoords([currColId, currRowIdx], [deltaCol, deltaRow]) {
    const currColIdx = this.colOrd().indexOf(currColId);

    const newColIdx = currColIdx + deltaCol;
    const newRowIdx = currRowIdx + deltaRow;

    if (newColIdx === this.columns().length - 1 || newColIdx < 0) return;
    if (newRowIdx === this.rows().length || newRowIdx < 1) return;

    const newColId = this.colOrd()[newColIdx];
    return [newColId, newRowIdx];
  }


  calcSpaceBelow(rowIdx) {
    // todo use positioning library like popper instead
    const tableHeight = 400; // using min table height for now
    const totalRowHeight = this.totalRowHeight();
    const spaceAbove = (rowIdx * ROW_HEIGHT) + ADD_ROW_BUTTON_HEIGHT;
    const tableBodyHeight = tableHeight - HEADER_ROW_HEIGHT;
    return Math.max(tableBodyHeight, totalRowHeight) - spaceAbove;
  }

  getRecords(reduxRows) {
    const records = [];
    this._rows
      .forEach((row) => {
        const record = {};
        const reduxRow = reduxRows.find(reduxRow => reduxRow.id === row.id);
        Object.values(row.data).forEach((cell) => {
          const column = this._colMap[cell.colId];
          if (column) {
            const { label, choices } = column;
            const { value } = cell;
            const val = column.input_type === 'select' ? new Map(choices).get(value) || value : value;
            record[label] = val;
          }
        });
        record[SUBMISSION_DATE_PLACEHOLDER.label] = moment(row[SUBMISSION_DATE]).format('MM/DD/YYYY');
        record['Matches'] = getMatches(reduxRow, this.showAutomatches);
        records.push(record);
      });
    return records;
  }

  toCsvString(reduxRows) {
    return Papa.unparse(this.getRecords(reduxRows), { escapeFormulae: true });
  }

  toCsvDownload(reduxRows) {
    const downloadData = new Blob([this.toCsvString(reduxRows)], { type: 'text/csv;charset=utf-8;' });
    const filename = `illumis Compliance Preclearance Export - ${moment().format('MM-DD-YYYY')}.csv`;

    if (navigator.msSaveBlob) { // IE11 support
      navigator.msSaveBlob(downloadData, filename);
    } else {
      const downloadEl = document.createElement('a');
      downloadEl.href = URL.createObjectURL(downloadData);
      downloadEl.download = filename;
      downloadEl.style.display = 'none';
      downloadEl.click();
      downloadEl.remove();
    }
  }
}
