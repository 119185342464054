import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import MatchButton from 'components/shared/matching/hasMatchButton';

const MatchButtonRenderer = ({
  noMatch, matches, automatches, openMatchingDialog, disableMatching, disableViewingMatch, matchType, toolTipText, removeMatch, viewOnly, showAutomatches,
}) => {
  if (noMatch) {
    return (
      <IconTextButton
        buttonHandler={openMatchingDialog}
        buttonText={'No Match'}
        className={'icon-none match-button no-match'}
        disabled={viewOnly}
      />
    );
  } else if (matches && matches.length) {
    return (
      <MatchButton
        matches={matches}
        disabled={disableViewingMatch}
        openMatchingDialog={openMatchingDialog}
        matchType={matchType}
        viewOnly={viewOnly}
        removeMatch={removeMatch}
      />
    );
  } else if (automatches && automatches.length && showAutomatches) {
    return (
      <IconTextButton
        buttonHandler={openMatchingDialog}
        buttonText={`${automatches.length} Potential ${pluralize('match', automatches.length)}`}
        className={'match-button automatch'}
        icon={'link'}
        disabled={viewOnly}
      />
    );
  } else if (!viewOnly) {
    return (
      <IconTextButton
        buttonHandler={openMatchingDialog}
        buttonText={'Find Match'}
        icon={'add'}
        disabled={disableMatching}
        className={'match-button default-match-button'}
        toolTipText={disableMatching ? toolTipText : null}
      />
    );
  }
  return '';
};

MatchButtonRenderer.propTypes = {
  noMatch: PropTypes.bool,
  matches: PropTypes.array,
  automatches: PropTypes.array,
  openMatchingDialog: PropTypes.func,
  disableMatching: PropTypes.bool,
  disableViewingMatch: PropTypes.bool,
  removeMatch: PropTypes.func,
  matchType: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  toolTipText: PropTypes.string,
  showAutomatches: PropTypes.bool,
};

MatchButtonRenderer.defaultProps = {
  matches: [],
  automatches: [],
  openMatchingDialog: () => {},
  disableMatching: false,
  disableViewingMatch: false,
  removeMatch: () => {},
  viewOnly: false,
  noMatch: false,
  toolTipText: 'Only political contributions may be matched at this time.',
  showAutomatches: false,
};


export default MatchButtonRenderer;
