import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ExpandableSection from 'components/shared/expandableSection';
import RowCard from './rowCard';

const MatchesSection = ({ matches, columns }) => (
  <ExpandableSection
    title={`Matched ${pluralize('record', matches.length)}`}
    startOpen
  >
    {matches.map(match => (
      <RowCard
        key={match.id}
        record={match}
        schema={columns}
        viewOnly
      />
    ))}
  </ExpandableSection>
);

MatchesSection.propTypes = {
  matches: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default MatchesSection;
