import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SortablePane, Pane } from 'react-sortable-pane';
import {
  SCROLLBAR_OFFSET,
  ROW_HEIGHT,
  ADD_COLUMN,
  FIXED_RIGHT_COLUMN_IDS,
  START_WIDTH,
  HEADER_ROW,
} from '../constants';
import HeaderCell from './HeaderCell';
import MenuWrapper from './MenuWrapper';
import AddColumn from './AddColumn';


const HeaderRow = ({
  style,
  columns, // all cols on that side (only necessary for right side so maybe pass in instead?)
  sortableCols, // just the columns within rows.data
  resizingCol,
  onResize,
  onResizeStop,
  onSortEnd,
  createColumn,
  updateColumn,
  deleteColumn,
  addFilter,
  sort,
  selectedCol,
  toggleColumnSelect,
  canEditColumns,
  disableDropdown,
  renderInputRow,
  addingRow,
  hasShadow,
  fixedLeft,
}) => {
  useEffect(() => {
    // this element causes positioning issues when adding rows
    // see https://github.com/bokuweb/react-sortable-pane/issues/226
    // todo look into other libraries for reordering / resizing
    const resizableBaseRef = document.getElementsByClassName('__resizable_base__')[0];
    resizableBaseRef && resizableBaseRef.remove();
  }, [sortableCols.length]);

  const { width } = style;

  // these columns are being appended on the FE and are not true columns
  // they cannot be ordered or resized since they don't exist on the BE
  const fixedRightCols = columns.slice(columns.length - FIXED_RIGHT_COLUMN_IDS.length);
  const fixedRightWidths = fixedRightCols.reduce((acc, col) => (acc + col.width), 0);
  const sortableWidth = fixedLeft ? width : (width - fixedRightWidths - SCROLLBAR_OFFSET);

  const inputRow = addingRow ? renderInputRow(fixedLeft) : null;

  return (
    <div
      className={`${hasShadow ? 'header-shadow' : ''}`}
      style={{
        width,
      }}
    >
      <div
        style={{ height: ROW_HEIGHT }}
        className={HEADER_ROW}
      >
        <SortablePane
          direction="horizontal"
          style={{
            ...style,
            paddingRight: '',
            width: sortableWidth,
          }}
          height={ROW_HEIGHT}
          onOrderChange={onSortEnd}
          onResize={onResize}
          onResizeStop={onResizeStop}
          disableEffect
          isSortable={!addingRow && !fixedLeft && !selectedCol}
        >
          {sortableCols.map((column) => {
            const { id } = column;
            const selected = selectedCol && column.id === selectedCol.id;
            const activeResize = resizingCol && column.id === resizingCol;
            return (
              <Pane
                resizable={{ y: false, xy: false }}
                minHeight={50}
                minWidth={START_WIDTH}
                defaultSize={{ width: column.width }}
                key={id}
                className="preclearance-header-cell-wrapper"
              >
                <HeaderCell
                  column={column}
                  selected={Boolean(selected)}
                  activeResize={activeResize}
                  toggleColumnSelect={toggleColumnSelect}
                  addingRow={addingRow}
                  menu={(
                    <MenuWrapper
                      column={column}
                      updateColumn={updateColumn}
                      deleteColumn={deleteColumn}
                      addFilter={addFilter}
                      onSort={sort}
                      disableDropdown={disableDropdown}
                      canEdit={canEditColumns}
                      closeMenu={toggleColumnSelect(null)}
                    />
                  )}
                />
              </Pane>
            );
          })}
        </SortablePane>
        {!fixedLeft
          ? (
            fixedRightCols.map((column) => {
              const { id } = column;
              const selected = selectedCol && id === selectedCol.id;
              const addCol = id === ADD_COLUMN;
              return (
                <HeaderCell
                  key={id}
                  column={column}
                  selected={Boolean(selected)}
                  toggleColumnSelect={toggleColumnSelect}
                  addingRow={addingRow}
                  menu={addCol
                    ? (
                      <AddColumn
                        column={column}
                        createColumn={createColumn}
                        onCancel={toggleColumnSelect(null)}
                      />
                    ) : null}
                  style={{
                    width: addCol ? column.width + SCROLLBAR_OFFSET : column.width,
                    position: 'relative',
                  }}
                  className={addCol ? ' add-column' : 'no-select'}
                />
              );
            })
          )
          : null}
      </div>
      {inputRow}
    </div>
  );
};

HeaderRow.propTypes = {
  style: PropTypes.object.isRequired,
  columns: PropTypes.array,
  sortableCols: PropTypes.array.isRequired,
  resizingCol: PropTypes.string,
  onResize: PropTypes.func.isRequired,
  onResizeStop: PropTypes.func.isRequired,
  onSortEnd: PropTypes.func,
  createColumn: PropTypes.func,
  updateColumn: PropTypes.func.isRequired,
  deleteColumn: PropTypes.func,
  addFilter: PropTypes.func.isRequired,
  sort: PropTypes.func,
  selectedCol: PropTypes.object,
  toggleColumnSelect: PropTypes.func.isRequired,
  disableDropdown: PropTypes.bool,
  canEditColumns: PropTypes.bool.isRequired,
  renderInputRow: PropTypes.func.isRequired,
  addingRow: PropTypes.bool,
  hasShadow: PropTypes.bool,
  fixedLeft: PropTypes.bool,
};

HeaderRow.defaultProps = {
  columns: [],
  resizingCol: null,
  onSortEnd: () => {},
  createColumn: () => {},
  deleteColumn: () => {},
  sort: () => {},
  selectedCol: null,
  disableDropdown: false,
  addingRow: false,
  hasShadow: false,
  fixedLeft: false,
};

export default HeaderRow;
