import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeModal } from 'reducers/ui/actions';
import { deleteEmployeeSearch } from 'reducers/employees/actions';
import { byId } from 'reducers/searchObjects/selectors';
import DeleteConfirmation from 'components/monitoringForm/deleteConfirmation';
import { BASE_MONITORING_URL, EMPLOYEE_PATHS, PATH_NAMES } from 'utils/constants';

const mapStateToProps = (state, props) => {
  const { match: { params } } = props;
  const { id } = params;
  const { searchId } = state.ui.modal.modalProps;
  const searchObject = byId(state, searchId);
  const { first_name: firstName, last_name: lastName } = searchObject;
  const uiSearchType = params.searchType;
  return {
    deleteFuncArgs: [id, searchId],
    uiSearchType,
    name: `${firstName} ${lastName}`,
    redirectUrl: `/app/${BASE_MONITORING_URL}/${uiSearchType}/${id}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`,
  };
};

export default withRouter(connect(mapStateToProps, {
  closeModal,
  deleteFunc: deleteEmployeeSearch,
})(DeleteConfirmation));
