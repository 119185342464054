import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CustomDropdown } from '../dropdown';
import { PATHS, PATH_NAMES } from '../../utils/constants';
import { hasManageOrgPermissions, hasExportPermissions } from '../../helpers/permissions';

function SettingsDropdown({ username, canManage, canExport, organizationName }) {
  const body = (
    <div className="dropdown-menu dropdown-menu-right">
      <Link className="dropdown-item" to={PATHS[PATH_NAMES.platform_settings]}>Platform Settings</Link>
      { canExport && <Link className="dropdown-item" to={PATHS[PATH_NAMES.reports]}>Reports</Link> }
      { canExport && <Link className="dropdown-item" to={PATHS[PATH_NAMES.bulk_exports]}>Bulk Exports</Link>}
      <Link className="dropdown-item" to={PATHS[PATH_NAMES.manage_account]}>Manage Account</Link>
      {canManage && <Link className="dropdown-item" to={PATHS[PATH_NAMES.manage_organization]}>Manage {organizationName}</Link>}
      <a className="dropdown-item" href="https://illumis.com/help-center/compliance" target="_blank" rel="noopener noreferrer">Help Center</a>
      <a className="dropdown-item" href="/logout/">Logout</a>
    </div>
  );
  return (
    <CustomDropdown
      render={({ isOpen, openDropdown, closeDropdown }) => {
        return (
          <div className="navbar-nav">
            <div
              onClick={isOpen ? closeDropdown : openDropdown}
              className={`text-button nav-link dropdown-toggle ${isOpen ? 'active' : ''}`}
              id="dropdown03"
            >
              <span className="dropdown-toggle">{username}</span>
              <i className="material-icons dropdown-toggle">{ isOpen ? 'expand_less' : 'expand_more'}</i>
            </div>
            {isOpen && body}
          </div>
        );
      }}
    />
  );
}

SettingsDropdown.propTypes = {
  username: PropTypes.string.isRequired,
  canManage: PropTypes.bool.isRequired,
  canExport: PropTypes.bool.isRequired,
  organizationName: PropTypes.string.isRequired,
};

const mapStateToProps = ({ user, organization: { name } }) => ({
  username: user.full_name.trim() || user.email,
  canManage: hasManageOrgPermissions(user),
  canExport: hasExportPermissions(user),
  organizationName: name,
});

export default connect(mapStateToProps)(SettingsDropdown);
