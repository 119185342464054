import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './checkbox';
import SourceToggleItem from './sourceToggleItem';
import { selectors as fromSources } from '../../../reducers/sources';

const SubCategoryToggleItem = ({ subcategory, sources, selected, expanded,
  onToggleSource, onToggleExpanded }) => {
  const checked = fromSources.allSelected(sources, selected);
  const selectedInCategory = fromSources.hasSelected(sources, selected);
  const indeterminate = !checked && (selectedInCategory.length > 0);
  const renderSourceList = !(sources[0].label === subcategory);
  let toggleSwitch = '';
  let sourceList = '';
  if (renderSourceList) {
    const icon = expanded ? 'icon-caret-up' : 'icon-caret-down';

    toggleSwitch = (
      <button className="text-button" type="button" onClick={onToggleExpanded}>
        <i className={`${icon} toggle`} />
      </button>
    );

    if (expanded) {
      const sourceItems = sources.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      }).map(source => (
        <SourceToggleItem
          key={source.id}
          source={source}
          onToggleSource={onToggleSource}
          checked={selected.has(source.id)}
        />));

      sourceList = (
        <div className="sourceList">
          { sourceItems }
        </div>
      );
    }
  }

  return (
    <div className="subcategorySection" key={subcategory}>
      <div>
        <div>
          <Checkbox
            id={`${subcategory}-all`}
            checked={checked}
            indeterminate={indeterminate}
            onChange={e => onToggleSource(sources.map(s => s.id), e.target.checked, indeterminate)}
          >
            <span>{subcategory}</span>
          </Checkbox>
          {toggleSwitch}
        </div>
        {sourceList}
      </div>
    </div>
  );
};

SubCategoryToggleItem.propTypes = {
  subcategory: PropTypes.string.isRequired,
  sources: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onToggleSource: PropTypes.func.isRequired,
  onToggleExpanded: PropTypes.func.isRequired,
};

export default SubCategoryToggleItem;
