import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReportEntry from '../reports/reportEntry';

class PastExports extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <div>
        <div className="form-info">
          Any past {this.props.exportType}s you have created will be shown here.
        </div>
        { this.props.exports.length === 0 ?
          <div className="no-reports">
            You have no past exports.
          </div> :
          <div className="past-reports">
            <table>
              <thead>
                <tr>
                  <th>{this.props.dateType || this.props.exportType} Dates</th>
                  <th>Download</th>
                  <th>Delete {this.props.exportType}</th>
                </tr>
              </thead>
              <tbody>
                { this.props.exports.sort((a, b) => new Date(b.created) - new Date(a.created))
                  .map(e => (
                    <ReportEntry
                      id={e.id}
                      key={e.id}
                      startDate={e.date_from}
                      endDate={e.date_to}
                      link={e.download_url || `/${this.props.exportUrl}/${e.id}`}
                      createManualReport={this.props.createManualReport}
                      onNewReportGeneration={this.props.onNewReportGeneration}
                      removeReport={this.props.removeReport}
                      exportType={this.props.exportType}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        }
      </div>
    );
  }
}

PastExports.propTypes = {
  exports: PropTypes.array.isRequired,
  exportType: PropTypes.string.isRequired,
  exportUrl: PropTypes.string.isRequired,
  removeReport: PropTypes.func.isRequired,
  dateType: PropTypes.string,
  createManualReport: PropTypes.func.isRequired,
  onNewReportGeneration: PropTypes.func.isRequired,
};

PastExports.defaultProps = {
  dateType: '',
};

export default PastExports;
