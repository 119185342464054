import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Select, { components } from 'react-select';


export const customStyles = {
  // override default styles
  // (additional styles applied via className to take advantage of sass mixins)
  option: () => ({
  }),
  control: () => ({
    padding: '3px 8px 3px 16px',
    display: 'flex',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: () => ({
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0px',
  }),
  singleValue: provided => ({
    ...provided,
    margin: '0px',
    color: '#676767',
  }),
};


const ClearIndicator = props => (
  <components.DropdownIndicator {...props}>
    <i className="material-icons">close</i>
  </components.DropdownIndicator>
);


export const DropdownIndicator = (props) => {
  const { menuIsOpen } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <i className="material-icons">
        {menuIsOpen ? 'expand_less' : 'expand_more'}
      </i>
    </components.DropdownIndicator>
  );
};


const SearchableSelectField = ({
  options,
  clearable,
  disabled,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const { value } = field;
  // there is a chance the selected option is not in the options array
  const initValue = options.find(option => option.value === value) || { label: value, value };

  const handleChange = (option, { action }) => {
    switch (action) {
      case 'clear': {
        setValue('');
        break;
      }
      case 'select-option': {
        setValue(option.value);
        break;
      }
      default:
    }
  };

  return (
    <div>
      <Select
        options={options}
        isClearable={clearable}
        classNamePrefix={'searchable'}
        styles={customStyles}
        onChange={handleChange}
        components={{ ClearIndicator, DropdownIndicator }}
        placeholder={props.title}
        defaultValue={value ? initValue : null}
        maxMenuHeight={200}
        isDisabled={disabled}
      />
    </div>
  );
};

SearchableSelectField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  title: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
};

SearchableSelectField.defaultProps = {
  title: '',
  clearable: false,
  disabled: false,
};

export { SearchableSelectField };
