import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const AccordionToggle = ({
  children,
  collapseIcon,
  expandIcon,
  onClick,
  expanded,
  className,
  canToggle,
}) => {
  const tabIndex = canToggle ? '0' : null;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      onClick={canToggle ? onClick : null}
      role={canToggle ? 'button' : null}
      tabIndex={tabIndex}
    >
      { canToggle ?
        <i className="material-icons toggle-icon">
          {
            expanded ? collapseIcon : expandIcon
          }
        </i>
        : null
      }

      {children}
    </div>
  );
};

AccordionToggle.propTypes = {
  children: PropTypes.node.isRequired,
  collapseIcon: PropTypes.string,
  expandIcon: PropTypes.string,
  onClick: PropTypes.func,
  expanded: PropTypes.bool.isRequired,
  className: PropTypes.string,
  canToggle: PropTypes.bool,
};

AccordionToggle.defaultProps = {
  collapseIcon: 'expand_less',
  expandIcon: 'expand_more',
  onClick: () => {},
  expanded: false,
  className: '',
  canToggle: true,
};


function Accordion({ defaultExpanded, children, className, canToggle }) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [toggleContent, content] = children;
  const toggle = React.cloneElement(toggleContent, {
    onClick: () => setExpanded(!expanded),
    expanded,
    canToggle,
  });

  return (
    <div
      className={`${className} ${expanded ? 'open' : ''}`}
    >
      { toggle }
      <div
        className="accordion-content"
        style={{
          display: expanded ? 'inherit' : 'none',
        }}
      >
        { content }
      </div>
    </div>
  );
}

Accordion.propTypes = {
  defaultExpanded: PropTypes.bool,
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
  canToggle: PropTypes.bool,
};

Accordion.defaultProps = {
  defaultExpanded: false,
  className: '',
  canToggle: true,
};

export default Accordion;
