import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import ZipcodeFields from 'components/monitoringForm/personForm/zipcodes';
import ExpandableSection from 'components/shared/expandableSection';
import StateDropdownWrapper from '../../monitoringForm/personForm/formikStateDropdownWrapper';
import SearchButton from './searchButton';

import SourceSelectionWrapper from '../../monitoringForm/sourceSelection/formikSourceSelectionWrapper';
import {
  firstName,
  lastName,
  states,
  to,
  from,
  researchLimitTooltip,
} from './consts';
import { makeCountHeader } from './contributorSearchFormWrapper';


function ContributorSearchForm({
  sources,
  limitReached,
  isSubmitting,
  values,
}) {
  return (
    <div>
      <div className="form-heading">
          Search By Contributor
      </div>
      <div>
        <Form autoComplete="off" noValidate>
          <div className="employee-form">
            <div className="row">
              <div className="col">
                <SchemaField
                  name={firstName}
                  id={firstName}
                  label={'First name'}
                  fieldType={'TextField'}
                  className={'form-input'}
                  required
                />
              </div>
              <div className="col">
                <SchemaField
                  name={lastName}
                  id={lastName}
                  label={'Last name'}
                  fieldType={'TextField'}
                  className={'form-input'}
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="focusable">
                  <div className="input-title">
                        U.S. States
                  </div>
                  <StateDropdownWrapper
                    field={states}
                    selected={values[states]}
                  />
                </div>
              </div>
              <div className="col">
                <ZipcodeFields />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div>
                  <div className="input-title">Record Dates</div>
                  <div className="range-input-container">
                    <SchemaField
                      name={from}
                      id={from}
                      label={'from'}
                      fieldType={'TextField'}
                      className={'form-input range-input'}
                      placeholder={'mm/dd/yyyy'}
                    />
                    <span>to</span>
                    <SchemaField
                      name={to}
                      id={to}
                      label={'to'}
                      fieldType={'TextField'}
                      className={'form-input range-input'}
                      placeholder={'mm/dd/yyyy'}
                    />
                  </div>
                </div>
              </div>
              <div className="col" />
            </div>
          </div>

          <div className="source-selection">
            <ExpandableSection
              title={makeCountHeader('Sources', values.sources.size)}
            >
              <SourceSelectionWrapper
                sources={sources}
                selected={values.sources}
                field={'sources'}
              />
            </ExpandableSection>
          </div>
          <div className="search-container">
            <div className="search-button-container">
              <SearchButton
                tooltipMessage={limitReached ? researchLimitTooltip : ''}
                disabled={limitReached || isSubmitting}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

ContributorSearchForm.propTypes = {
  sources: PropTypes.array.isRequired,
  limitReached: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
};

export default ContributorSearchForm;
