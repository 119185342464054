import axios from 'axios';
import Cookie from 'cookie';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { SUPPORT_EMAIL } from 'utils/constants';

const getCSRFToken = () => Cookie.parse(document.cookie).csrftoken;

const _api = axios.create({
  baseURL: '/api',
  headers: {
    'X-XSRF-TOKEN': getCSRFToken(),
    CSRF_COOKIE_USED: true,
  },
  timeout: 60000,
});

const networkConnectionId = 'networkConnectionLostId';
const networkConnectionLostMessage = 'Connection lost. Your computer seems to be offline.';
const cantReachServerId = 'cantReachServerId';
const cantReachServerMessage = `There seems to be an issue connecting to our servers. Please contact ${SUPPORT_EMAIL} for support.`; // todo: make an actual hyperlink
const rateLimitErrorMessage = 'You have hit our rate limit, please try again in a few seconds.';

const connectionIssueToast = (toastId, message) => {
  if (!toast.isActive(toastId)) {
    toast(message, {
      toastId,
      autoClose: false,
      position: 'top-center',
      className: 'connection-error',
    });
  }
};

const clearToast = (toastId) => {
  if (toast.isActive(toastId)) {
    toast.dismiss(toastId);
  }
};

const wrappedApi = {};

// wrap each restful method in a function that calls the original restful method on the axios object,
// and catches network/timeout errors or clears toasts from previous network/timeout errors.

['get', 'post', 'put', 'patch', 'delete'].forEach((method) => {
  wrappedApi[method] = (...args) => _api[method](...args)
    .then((response) => {
      [networkConnectionId, cantReachServerId].forEach(toastId => clearToast(toastId));
      return response;
    })
    .catch((error) => {
      // if the request was canceled, just throw it to break out of the promise chain
      if (axios.isCancel(error)) {
        throw error;
      }
      if (!error.response) {
        if (window.navigator.onLine === false) {
          clearToast(cantReachServerId);
          connectionIssueToast(networkConnectionId, networkConnectionLostMessage);
        } else {
          clearToast(networkConnectionId);
          connectionIssueToast(cantReachServerId, cantReachServerMessage);
        }
      } else {
        if (error.response.status === 429) {
          toast(rateLimitErrorMessage);
        }
        [networkConnectionId, cantReachServerId].forEach(toastId => clearToast(toastId));
      }
      const errorRes = (error && error.response && error.response.data) || 'No error response data.';
      Sentry.captureEvent({
        message: `api error: ${JSON.stringify(errorRes)}`,
      });
      throw error;
    });
});

export default wrappedApi;
