import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { disableSendEmailPermissions } from 'helpers/permissions';
import ExpandableSection from 'components/shared/expandableSection';
import { PreclearanceSurvey } from 'components/PreclearanceSurvey/PreclearanceSurvey';
import ShareTooltip, { Link, EmbedCode } from './shareTooltip';
import { CustomDropdown } from '../../../dropdown';
import EmailLinkForm from './EmailLinkForm';

const [EMAIL_LINK, LINK, EMBED_CODE] = ['Email link', 'Link', 'Embed code'];

const shareOptionsBodyMap = {
  [EMAIL_LINK]: EmailLinkForm,
  [LINK]: Link,
  [EMBED_CODE]: EmbedCode,
};

const shareOptionsIconMap = {
  [EMAIL_LINK]: 'mail_outline',
  [LINK]: 'link',
  [EMBED_CODE]: 'code',
};

const Questionnaire = ({
  tableId, userFullName, preclearanceSsoEnabled, fromEmail, disableSendEmail,
}) => {
  // ie does not have origin
  const baseUrl = window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
  const formUrl = `${baseUrl}/preclearance_survey/${tableId}`;

  const shareOptions = [
    ...(disableSendEmail ? [] : [EMAIL_LINK]),
    LINK,
    ...(preclearanceSsoEnabled ? [] : [EMBED_CODE]),
  ];

  return (
    <div className="questionnaire-page">
      <div className="flex-wrapper">
        <h1>Preclearance Request Form</h1>
        <CustomDropdown
          render={({
            isOpen,
            openDropdown,
            closeDropdown,
            dropdownRef,
          }) => (
            <div>
              <button
                className="orange-button"
                onClick={isOpen ? closeDropdown : openDropdown}
                type="button"
              >
                Share Form
              </button>
              {isOpen ? (
                <div ref={dropdownRef}>
                  <ShareTooltip
                    onCancel={closeDropdown}
                    formUrl={formUrl}
                    userFullName={userFullName}
                    options={shareOptions}
                    bodyMap={shareOptionsBodyMap}
                    iconMap={shareOptionsIconMap}
                    fromEmail={fromEmail}
                  />
                </div>
              ) : null}
            </div>
          )}
        />
      </div>
      <div className="help-text">
        Below you can see a preview of the preclearance request form
        that you can share with your employees. Each time they fill out
        the form it will create a new entry in the preclearance table.
      </div>
      <ExpandableSection title={'Preview form'}>
        <div className="preclearance-preview">
          <PreclearanceSurvey
            tableIdOverride={tableId}
            isPreview
          />
        </div>
      </ExpandableSection>
    </div>
  );
};

const mapStateToProps = state => ({
  tableId: state.preclearance.table.tableId,
  userFullName: state.user.full_name,
  preclearanceSsoEnabled: state.organization.preclearance_sso_enabled,
  fromEmail: state.preclearance.table.email,
  disableSendEmail: disableSendEmailPermissions(state.user),
});

export default connect(mapStateToProps, {})(Questionnaire);

Questionnaire.propTypes = {
  tableId: PropTypes.string.isRequired,
  userFullName: PropTypes.string.isRequired,
  preclearanceSsoEnabled: PropTypes.bool,
  fromEmail: PropTypes.string.isRequired,
  disableSendEmail: PropTypes.bool,
};

Questionnaire.defaultProps = {
  preclearanceSsoEnabled: false,
  disableSendEmail: false,
};
