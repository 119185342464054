import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { isInclusivelyBeforeDay, invalidDate } from '../../../helpers/date_helpers';

import { updateDateFilter } from '../../../reducers/user/actions';
import Tooltip from '../../toolTip';
import Section from '../../shared/settingsPage/section';

class GlobalDateFilter extends Component {
  constructor(props) {
    super(props);
    const userDate = this.props.user.global_date_filter;
    const date = userDate ? moment(userDate) : null;
    this.state = {
      date,
      errors: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
  }


  onSubmit(date) {
    const dateInput = document.getElementById('global-date-filter');
    const dateError = 'Please enter a valid date in the form of mm/dd/yyyy or leave blank to remove';

    if (invalidDate(dateInput)) {
      this.setState({ errors: { ...this.state.error, date: dateError } });
      return;
    } else if (dateInput && dateInput.value && !isInclusivelyBeforeDay(moment(dateInput.value), moment())) {
      this.setState({ errors: { ...this.state.error, date: 'Please enter a date in the past.' } });
      return;
    }

    const unixDate = date ? moment(date).utc().startOf('day').unix() : null;

    this.props.updateDateFilter({ global_date_filter: unixDate }).then((res) => {
      const newDate = res ? moment(res) : null;
      const toastMessage = newDate === null ? 'Your date filter has been removed' : 'Your date filter has been updated.';
      toast(toastMessage);
      this.setState({
        date: newDate,
        errors: {},
      });
    })
      .catch((err) => {
        const oldDate = this.props.user.global_date_filter ? moment(this.props.user.global_date_filter) : null;
        this.setState({
          date: oldDate,
          errors: { ...this.state.errors, date: 'There was a problem updating the date filter.' },
        });
        throw err;
      });
  }

  render() {
    const disabled = !this.props.user.is_manager;

    return (
      <Section
        header="Date Filter"
        containerClass="global-datefilter"
        helpText={<p>Exclude all records on the platform before the specified date. This filter will be applied to the date field on a record and will remove records from the dashboard as well as all reports and exports.</p>}
      >
        <div>
          <div className="settings-flex-wrapper">
            <label>Exclude records before:</label>

            <div className={`${disabled ? 'disabled' : ''}`}>
              <SingleDatePicker
                date={this.state.date}
                onDateChange={date => this.setState({ date })}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                id="global-date-filter"
                placeholder="mm/dd/yyyy"
                noBorder
                numberOfMonths={1}
                hideKeyboardShortcutsPanel
                disabled={disabled}
              />
              <Tooltip>
                Only managers are allowed to change the excluded date filter.
              </Tooltip>
            </div>
            <div className="error-text text-center">{ this.state.errors.date }</div>
            {
              this.props.user.global_date_filter && !disabled ?
                <div>
                  <button
                    className="text-button uppercase"
                    onClick={() => { this.onSubmit(null); }}
                    disabled={disabled}
                  >
                    <i className="material-icons">close</i>Remove date filter
                  </button>
                </div> : ''
            }
          </div>
          <div className="button-wrapper">
            <button
              className="orange-button"
              onClick={() => this.onSubmit(this.state.date)}
              disabled={disabled}
            >
              Submit
            </button>
            <Tooltip>
              Only managers are allowed to change the excluded date filter.
            </Tooltip>
          </div>
        </div>
      </Section>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  { updateDateFilter },
)(GlobalDateFilter);

GlobalDateFilter.propTypes = {
  user: PropTypes.object.isRequired,
  updateDateFilter: PropTypes.func.isRequired,
};
