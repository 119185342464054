import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorBoundary from 'components/errorBoundary';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import Loading from 'components/loading';
import ExportForm from 'components/bulkExports/exportForm';
import PastExports from 'components/bulkExports/pastExports';
import { getSearches } from 'reducers/employees/selectors';
import { fetchEmployees } from 'reducers/employees/actions';
import { getEntities } from 'reducers/entities/selectors';
import { fetchEntities } from 'reducers/entities/actions';
import {
  loadExports,
  createExport,
  getUpdatedCounts,
  removeExport,
} from 'reducers/bulkExports/actions';


class BulkExports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentTarget: null,
    };
    this.timers = {};
    this.onNewExportGeneration = this.onNewExportGeneration.bind(this);
  }

  componentDidMount() {
    this.loadInitialData();
  }


  onNewExportGeneration(id) {
    this.setState({
      currentTarget: id,
    });
    this.props.getUpdatedCounts(id);
  }

  loadInitialData() {
    Promise.all([
      this.props.fetchEmployees(),
      this.props.fetchEntities(),
      this.props.loadExports(),
    ])
      .then(() => {
        this.setState({
          loading: false,
        });
        // if any are still in a loading state when page is refreshed
        this.props.exports.forEach((e) => {
          if (!e.is_complete && !e.errored) {
            this.props.getUpdatedCounts(e.id);
          }
        });
      });
  }


  render() {
    if (this.state.loading) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }

    return (
      <div id="bulk-exports" className="container">
        <ErrorBoundary>
          <ScrollToTopOnMount />
          <div className="panel">
            <h1>Bulk Exports</h1>
            <ExportForm
              createExport={this.props.createExport}
              employees={this.props.employees}
              entities={this.props.entities}
              onNewReportGeneration={this.onNewExportGeneration}
              globalDateFilter={this.props.globalDateFilter}
            />
            <hr />
            <h1>Past Exports</h1>
            <PastExports
              exportType={'export'}
              dateType={'record'}
              exports={this.props.exports}
              createManualReport={this.props.createExport}
              removeReport={this.props.removeExport}
              exportUrl={'bulk_exports'}
              onNewReportGeneration={this.onNewExportGeneration}
            />
          </div>
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  exports: state.bulkExports.exports,
  employees: getSearches(state),
  entities: getEntities(state),
  globalDateFilter: state.user.global_date_filter,
});

export default connect(
  mapStateToProps,
  {
    fetchEmployees,
    fetchEntities,
    loadExports,
    createExport,
    getUpdatedCounts,
    removeExport,
  },
)(BulkExports);

BulkExports.propTypes = {
  fetchEmployees: PropTypes.func.isRequired,
  fetchEntities: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  entities: PropTypes.array.isRequired,
  exports: PropTypes.array.isRequired,
  loadExports: PropTypes.func.isRequired,
  createExport: PropTypes.func.isRequired,
  getUpdatedCounts: PropTypes.func.isRequired,
  removeExport: PropTypes.func.isRequired,
  globalDateFilter: PropTypes.string,
};

BulkExports.defaultProps = {
  globalDateFilter: null,
};
