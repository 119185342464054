export const SET_SEARCH = 'research/SET_SEARCH';
export const TOGGLE_EDITING = 'research/TOGGLE_EDITING';
export const SELECT_TAB = 'research/SELECT_TAB';
export const RECEIVE_RESEARCH_RESULTS = 'research/RECEIVE_RESEARCH_RESULTS';
export const RECEIVE_SOURCE_RESULTS = 'research/RECEIVE_SOURCE_RESULTS';
export const TOGGLE_LOADING = 'research/TOGGLE_LOADING';
export const RECEIVE_ERRORS = 'research/RECEIVE_ERRORS';
export const RESET_STATE = 'research/RESET_STATE';
export const CLEAR_RESULTS = 'research/CLEAR_RESULTS';
export const SET_DATE_FILTER = 'research/SET_DATE_FILTER';
