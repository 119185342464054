import { connect } from 'react-redux';
import { createSearch, toggleEditing } from 'reducers/research/actions';
import { deserializeDate } from 'utils/serializers/dateSerializer';
import ContributorSearchForm from './contributorSearchFormWrapper';
import { fields, defaultSearchState } from './consts';


const formatSearch = (search, dateFilter, sources) => {
  const formattedSearch = {};
  Object.values(fields).forEach((field) => {
    if (field === 'states') {
      formattedSearch[field] = Object.assign([], search[field]);
    } else if (field === 'sources') {
      formattedSearch[field] = new Set(search[field]);
    } else if (field === 'from' || field === 'to') {
      formattedSearch[field] = dateFilter[field] ? deserializeDate(dateFilter[field]) : '';
    } else if (search[field] instanceof Array) {
      formattedSearch[field] = search[field].length ? Object.assign([], search[field]) : [''];
    } else if (search[field] === undefined) {
      formattedSearch[field] = defaultSearchState(sources)[field];
    } else {
      formattedSearch[field] = search[field];
    }
  });
  return formattedSearch;
};

const mapStateToProps = (state, { inHeader }) => {
  const { research: { ui, currentSearch }, user } = state;
  const edit = ui.editing || false;
  const sources = state.sources.sources;
  const { dateFilter } = ui;
  const initForm = edit ?
    formatSearch(currentSearch, dateFilter, sources) : defaultSearchState(sources);
  return {
    edit,
    sources,
    inHeader,
    remainingResearches: user.remaining_researches,
    initForm,
  };
};

export default connect(mapStateToProps, {
  createSearch,
  toggleEditing,
})(ContributorSearchForm);
