import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { requiredError } from 'utils/errorHelpers';
import { TextField } from 'components/Certifications/CertificationForm/forms/components';


const name = 'name';

const validationSchema = Yup.object().shape({
  [name]: Yup.string()
    .trim()
    .required(requiredError),
});

const FormNameForm = ({
  handleSubmit,
  initialValues,
  formHeading,
  helpText,
  onClose,
  submitButtonText,
}) => (
  <div>
    <h2>
      { formHeading }
    </h2>
    <div className="message">
      <div className="help-text">
        { helpText }
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form
            autoComplete="off"
            noValidate
            className="create-cert-form"
          >
            <TextField
              name={name}
              label="name"
              id={name}
              required
              autoFocus
            />
            <div className="button-wrapper">
              <button
                onClick={onClose}
                className="text-button uppercase"
                type="button"
              >
                Cancel
              </button>
              <button
                className="orange-button"
                type="submit"
                disabled={isSubmitting}
              >
                { submitButtonText }
              </button>
            </div>
          </Form>
        )}

      </Formik>
    </div>
  </div>
);


FormNameForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  formHeading: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
};

FormNameForm.defaultProps = {
  submitButtonText: 'Next',
};

export default FormNameForm;
