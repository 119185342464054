import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, useField } from 'formik';

import { FORM_ELEMENT } from '../constants';
import { Autofill } from '../utils/Autofill';


const SelectField = ({
  title,
  autofill,
  autofillField,
  fieldMap,
  sourceData,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const [isOpen, setIsOpen] = useState(false);
  const choiceObj = (props.choices || []).reduce((acc, choice) => {
    const [key, value] = choice;
    acc[key] = value;
    return acc;
  }, {});
  const selectedChoice = choiceObj[field.value] || field.value;
  const [selectLabel, setSelectLabel] = useState(selectedChoice || title);

  const dropdownEl = useRef(null);

  if (autofill) {
    if (!fieldMap || !autofillField || !sourceData) {
      throw '"autofillField", "fieldMap", and "sourceData" are required when "autofill" is true';
    }
  }

  const handleSelectToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value, label) => {
    helpers.setValue(value);
    setSelectLabel(label);
    handleSelectToggle();
  };

    // Collapse on click outside
  useEffect(() => {
    const collapseSelect = (event) => {
      if (dropdownEl && dropdownEl.current && !dropdownEl.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('click', collapseSelect);
    return () => window.removeEventListener('click', collapseSelect);
  }, []);

  // Collapse on escape key
  useEffect(() => {
    const collapseSelect = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', collapseSelect);
    return () => window.removeEventListener('keydown', collapseSelect);
  }, []);


  return (
    <div ref={dropdownEl} id="select-wrapper">
      <div className="drop-wrapper bordered-dropdown">
        <button
          type="button"
          className="dropdownButton text-button"
          onClick={handleSelectToggle}
        >
          <span>
            <span>{selectLabel}</span>
            { props.clearable && field.value ?
              <span
                role="button"
                className="clear"
                tabIndex={0}
                onClick={() => handleSelect('', title || 'Select')}
              >
                <i className="material-icons">close</i>
              </span>
              : null}
          </span>
          <i id="expand-collapse-icon" className="material-icons">
            {isOpen ? 'expand_less' : 'expand_more'}
          </i>
        </button>
      </div>
      <div
        className="dropdown-container"
        style={{ visibility: isOpen ? 'visible' : 'hidden' }}
      >
        <div className="options-wrapper">
          <div className="dropdown-options">
            {props.choices.map(([value, name]) => (
              <label key={value}>
                <Field
                  as="button"
                  type="button"
                  className="option"
                  value={value}
                  name={field.name}
                  onClick={() => handleSelect(value, name)}
                >
                  {name}
                </Field>
              </label>
            ))}
          </div>
        </div>
      </div>
      {autofill && (
        <Autofill
          value={field.value}
          fieldMap={fieldMap}
          field={autofillField}
        >
          {sourceData}
        </Autofill>
      )}
    </div>
  );
};

SelectField.propTypes = {
  ...FORM_ELEMENT,
  title: PropTypes.string,
  autofill: PropTypes.bool,
  autofillField: PropTypes.string,
  fieldMap: PropTypes.object,
  sourceData: PropTypes.arrayOf(PropTypes.object),
};

SelectField.defaultProps = {
  autofill: false,
  title: 'Select',
};

export { SelectField };
