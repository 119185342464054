import React from 'react';
import PropTypes from 'prop-types';
import { SortablePane, Pane } from 'react-sortable-pane';
import { HEADER_HEIGHT } from '../consts';

const TableHeader = ({
  columns, style, resizingCol, onResize, onResizeStop, initColWidths, columnWidths,
}) => (
  <SortablePane
    direction="horizontal"
    style={{ ...style }}
    onResize={onResize}
    onResizeStop={onResizeStop}
    disableEffect
    isSortable={false}
  >
    {columns.map((column, i) => {
      const colId = column.props.id;
      const activeResize = resizingCol && colId === resizingCol;
      return (
        <Pane
          key={colId}
          className={`${i === columns.length - 1 ? 'last ' : ''}preclearance-header-cell-wrapper`}
          resizable={{ y: false, xy: false }}
          minWidth={initColWidths[colId]}
          size={{ width: columnWidths[colId], height: HEADER_HEIGHT }}
        >
          {column}
          { activeResize ? <div className="resize-border-div" /> : null}
        </Pane>
      );
    },
    )}
  </SortablePane>
);


TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  style: PropTypes.object.isRequired,
  resizingCol: PropTypes.string,
  onResize: PropTypes.func,
  onResizeStop: PropTypes.func,
  initColWidths: PropTypes.object,
  columnWidths: PropTypes.object.isRequired,
};

TableHeader.defaultProps = {
  resizingCol: null,
  onResize: () => {},
  onResizeStop: () => {},
  initColWidths: {},
};

export default TableHeader;
