import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchMatchActions } from 'reducers/preclearance/matchActions/actions';
import SettingsPage from 'components/shared/settingsPage/settingsPage';
import Loading from 'components/loading';
import StatusChangeActions from './sections/statusChangeActions';
import MatchedStatusActions from './sections/matchedStatusActions';
import AutoResponse from './sections/autoResponse';

function AutomatedActions({ shareSearches, fetchMatchActions }) {
  const sections = [StatusChangeActions, AutoResponse];
  if (shareSearches) {
    sections.splice(1, 0, MatchedStatusActions);
  }
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // in case any of the actions were disabled as a result of deleting a status, we make a fresh call each time.
    fetchMatchActions()
      .then(() => {
        setLoading(false);
      });
  }, []);
  return (
    loading ?
      <div className="load-wrapper"><Loading /></div>
      : <SettingsPage header={'Automated Actions'} sections={sections} />
  );
}

AutomatedActions.propTypes = {
  shareSearches: PropTypes.bool.isRequired,
  fetchMatchActions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  shareSearches: state.organization.share_searches,
});


export default connect(mapStateToProps, { fetchMatchActions })(AutomatedActions);
