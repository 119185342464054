import api from '../../../api';
import * as types from '../types';

const updateEmail = ({ email }) => ({
  email,
  type: types.UPDATE_EMAIL,
});

const updateTemplate = data => ({
  data,
  type: types.UPDATE_TEMPLATE,
});

export const editEmail = ({ tableId, email }) => dispatch => (
  api.put(`/custom/table/${tableId}/`, { email })
    .then(({ data }) => {
      dispatch(updateEmail(data));
      return data;
    })
);

export const editTemplate = (tableId, templateId) => dispatch =>
  api.put(`/custom/table/${tableId}/`, templateId)
    .then(({ data }) => {
      dispatch(updateTemplate(data));
      return data;
    });

