import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { maxLengthError } from 'utils/errorHelpers';
import useCancellablePromise from 'hooks/useCancellablePromise';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';


const MAX_COLUMN_LABEL_LENGTH = 150;

const validationSchema = Yup.object().shape({
  label: Yup.string()
    .required('Please enter a field name.')
    .max(MAX_COLUMN_LABEL_LENGTH, maxLengthError(MAX_COLUMN_LABEL_LENGTH)),
});


const ColumnForm = ({
  id,
  label,
  onSubmit,
  onCancel,
  headerText,
}) => {
  const { cancellablePromise } = useCancellablePromise();
  const handleSubmit = (values, actions) => {
    cancellablePromise(onSubmit(values.label, id)).then(() => {
      actions.setSubmitting(false);
    }).catch((err) => {
      let errorMessage = '';
      if (err && err.response && err.response.status === 409) {
        errorMessage = 'A field with this label already exists';
      }
      if (!err.isCanceled) {
        actions.setErrors({ label: errorMessage });
        actions.setSubmitting(false);
      }
    });
  };

  return (
    <div className="preclearance-popover-body">
      <div className="header">
        <span>{headerText}</span>
      </div>
      <div className="body">
        <Formik
          initialValues={{ label }}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <SchemaField
                name="label"
                id="label"
                label="Field name"
                fieldType="TextField"
                className="form-input"
                autoFocus
                required
              />
              <div className="buttons">
                <button
                  type="button"
                  className="text-button uppercase"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button orange-button"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};


ColumnForm.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
};

ColumnForm.defaultProps = {
  id: null,
};

export default ColumnForm;
