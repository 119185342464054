import { createSelector } from 'reselect';
import moment from 'moment';
import { getStatuses } from 'reducers/statuses/selectors';
import { CONTRIBUTOR, MONTH, STATUS } from 'components/dashboard/charts/consts';
import { getSearchObjs } from 'reducers/searchObjects/selectors';
import { SELF } from 'components/employeePage/consts';


function serializeMonthData(data) {
  return data.map(dataEntry => ({
    ...dataEntry,
    month: moment(dataEntry.month, 'YYYY-MM-DD').format('MMM YYYY'),
  }));
}

function serializeStatusData(data, statuses) {
  return data.map(dataEntry => ({
    ...dataEntry,
    status: statuses[dataEntry.status] && statuses[dataEntry.status].label,
  }));
}

function serializeContributorData(data, searchObjs) {
  // in case a search id is not in searchObj state (could happen if another user added a search after we already fetched searchObjs)
  return data.reduce((serialized, dataEntry) => {
    const { contributor } = dataEntry;
    const search = searchObjs[contributor];
    if (search) {
      const persons = search.persons || [];
      const selfSearch = (persons).find(person => person.search_type === SELF);
      const employeeId = selfSearch ? selfSearch.employee_id : (persons[0] || {}).employee_id;
      return serialized.concat({
        ...dataEntry,
        contributor: `${search.first_name} ${search.last_name}`,
        id: contributor,
        employeeId,
      });
    }
    return serialized;
  }, []);
}


const getAggKey = (_state, key) => key;

const getAggDataByKey = (state, nameKey) => (state.aggregations[nameKey] || []);

export const makeGetSerializedAggData = () => createSelector(
  [getAggKey, getAggDataByKey, getStatuses, getSearchObjs],
  (nameKey, data, statuses, searchObjs) => {
    switch (nameKey) {
      case MONTH:
        return serializeMonthData(data);
      case STATUS:
        return serializeStatusData(data, statuses);
      case CONTRIBUTOR:
        return serializeContributorData(data, searchObjs);
      default:
        return data;
    }
  },
);
