import * as Yup from 'yup';
import DOMPurify from 'dompurify';
import { FIELD_TYPES, SPECIAL } from './consts';

const validInputTypes = [...Object.keys(FIELD_TYPES), SPECIAL];

const DependentSchema = Yup.object().shape({
  prerequisite_field: Yup.mixed().required(),
  prerequisite_values: Yup.array().of(Yup.string().required().label('Dependent value')).required(),
});

const ChoiceSchema = Yup.object().shape({
  id: Yup.mixed().required(),
  value: Yup.mixed().required(),
  label: Yup.string().min(1).required().label('Label'),
});

const FieldSchema = Yup.object().shape({
  id: Yup.mixed().required(),
  input_type: Yup.mixed().oneOf(validInputTypes),
  label: Yup.string().nullable().when('input_type', {
    is: (inputType) => inputType !== SPECIAL,
    then: Yup.string()
      .transform((value) => DOMPurify.sanitize(value, { ALLOWED_TAGS: [] }))
      .min(1)
      .required()
      .label('Label'),
  }),
  choices: Yup.array().of(ChoiceSchema),
  dependencies: Yup.array().of(DependentSchema).required(),
  is_hidden: Yup.bool().required(),
  is_required: Yup.bool().required(),
});

const FormSchema = Yup.object().shape({
  title: Yup.string().min(1).required().label('Title'),
  is_active: Yup.bool().required(),
  header: Yup.string().label('Header'),
  footer: Yup.string().label('Footer'),
  fields: Yup.lazy((obj) => {
    const schema = {};
    Object.keys(obj).forEach((key) => {
      schema[key] = FieldSchema;
    });
    return Yup.object(schema);
  }),
});

export { FormSchema };
