import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { selectors as fromSources } from '../../../reducers/sources';
import Checkbox from './checkbox';
import CategorySourcesSelection from './categorySourcesSelection';


class SourceSelectionPanel extends Component {
  constructor(props) {
    super(props);
    const { sources } = props;
    const categories = fromSources.categories(sources);

    this.state = {
      categories,
      expanded: new Set(),
      activeCategory: categories[0],
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded(subcategory) {
    const updated = new Set(this.state.expanded);
    if (updated.has(subcategory)) {
      updated.delete(subcategory);
    } else {
      updated.add(subcategory);
    }
    this.setState({ expanded: updated });
  }

  renderAllSourcesCheckbox() {
    const { sources, selected, onToggleSource } = this.props;
    const checked = fromSources.allSelected(sources, selected);
    const indeterminate = !checked && (selected.size > 0);

    return (
      <div id="allSourcesCheckbox">
        <Checkbox
          id="select-all"
          checked={checked}
          indeterminate={indeterminate}
          onChange={e => onToggleSource(sources.map(s => s.id), e.target.checked, indeterminate)}
        >
          <span> All Sources </span>
        </Checkbox>
      </div>
    );
  }

  render() {
    const { categories, activeCategory } = this.state;
    const { selected } = this.props;

    const sources = fromSources.byCategory(this.props.sources, activeCategory);
    return (
      <div>
        { this.renderAllSourcesCheckbox() }
        <div className="sourceSelectionPanel">
          <div className="categorySelection">
            {
              categories.map(category => (
                <button
                  type="button"
                  className={`${activeCategory === category ? 'active ' : ''}category`}
                  key={category}
                  onClick={() => this.setState({ activeCategory: category })}
                >
                  <span className="label">{category}</span>
                  <span className="count">
                    {fromSources.activeByCategory(this.props.sources, selected, category).length}
                  </span>
                </button>
              ))
            }
          </div>
          <div className="sources">
            <div className="source-wrapper">
              <select
                className="form-control"
                value={activeCategory}
                onChange={e => this.setState({ activeCategory: e.target.value })}
              >
                {
                  categories.map(category => (
                    <option key={category} value={category}>
                      {category} {' '}
                    ({fromSources.activeByCategory(this.props.sources, selected, category).length})
                    </option>
                  ))
                }
              </select>

              <CategorySourcesSelection
                sources={sources}
                activeCategory={activeCategory}
                selected={this.props.selected}
                onToggleSource={this.props.onToggleSource}
                expanded={this.state.expanded}
                toggleExpanded={this.toggleExpanded}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}

SourceSelectionPanel.propTypes = {
  sources: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onToggleSource: PropTypes.func.isRequired,
};


export default SourceSelectionPanel;
