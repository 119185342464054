/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import useCancellablePromise from 'hooks/useCancellablePromise';

import { PATH_NAMES, PATHS } from 'utils/constants';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import { fetchPreStatuses } from 'reducers/preclearance/statuses/actions';
import { fetchEmployees } from 'reducers/employees/actions';
import { fetchEmailTemplates } from 'reducers/certifications/emailTemplates/actions';
import { fetchMacros } from 'reducers/certifications/macros/actions';
import { fetchForms } from 'reducers/certifications/forms/actions';
import ErrorBoundary from 'components/errorBoundary';
import { Step } from 'components/shared/Stepper';
import { SetUpForm, RecipientForm, EmailForm } from './forms';
import Loading from '../../loading';
import { RECURRING, ONCE, setUpFormValidation, recipientFormValidation, emailFormValidation } from '../constants';
import FormikStepWrapper from './FormikStepWrapper';


const serializeTemplate = (template) => {
  const is_recurring = template.frequency === RECURRING;
  const payload = {
    ...template,
    schedule: {
      is_recurring,
      at_date: is_recurring ? null : template.at_date,
      frequency: is_recurring ? template.schedule : ONCE,
    },
  };
  delete payload.frequency;
  delete payload.at_date;

  if (is_recurring) {
    delete payload.start_date;
    delete payload.end_date;
  }
  if (template.email_template) {
    // if using an email template, body and subject will be pulled from template
    delete payload.email_subject;
    delete payload.email_body;
  }
  return payload;
};


const CertificationForm = ({
  getInitialValues,
  edit,
  submitFunc,
  cancelUrl,
  getEmployees,
  getStatuses,
  getEmailTemplates,
  getMacros,
  getForms,
  statusOptions,
  emailTemplates,
  macros,
  forms,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    let didCancel = false;
    Promise.all([
      getEmployees(),
      getStatuses(),
      getEmailTemplates(),
      getMacros(),
      getForms(),
    ]).catch((err) => {
      toast('Something went wrong.');
      throw err;
    }).finally(() => {
      if (!didCancel) {
        setIsLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, []);

  const handleCancel = () => {
    history.push(cancelUrl);
  };

  const submitForm = (values, actions) => {
    const payload = serializeTemplate(values);
    cancellablePromise(submitFunc(payload)).then(({ data }) => {
      history.push(`${PATHS[PATH_NAMES.certification_templates]}/${data.id}`);
      toast(`Certification ${edit ? 'updated' : 'created'}!`);
    }).catch((err) => {
      if (!err.isCanceled) {
        const errors = err && err.response && err.response.data;
        let errorMessage = 'Some fields had errors.';
        const scheduleErrs = errors && errors.schedule;
        if (errors && errors.non_field_errors) {
          errorMessage = errors.non_field_errors;
        } else if (scheduleErrs && scheduleErrs.non_field_errors
          && Array.isArray(scheduleErrs.non_field_errors)) {
          // this is to handle the error message if certifications have been sent for the day
          errorMessage = errors.schedule.non_field_errors[0];
        }
        actions.setSubmitting(false);
        actions.setErrors(errors);
        toast(errorMessage);
        throw err;
      }
    });
  };


  if (isLoading) {
    return (
      <div className="load-screen">
        <Loading />
      </div>
    );
  }

  const initialValues = getInitialValues(emailTemplates, forms);

  return (
    <ErrorBoundary>
      <ScrollToTopOnMount />
      <FormikStepWrapper
        initialValues={initialValues}
        submitForm={submitForm}
        handleCancel={handleCancel}
      >
        <Step
          title="Set Up"
          validationSchema={setUpFormValidation}
        >
          <SetUpForm
            statusOptions={statusOptions}
            edit={edit}
            forms={forms}
          />
        </Step>
        <Step
          title="Select Recipients"
          validationSchema={recipientFormValidation}
        >
          <RecipientForm />
        </Step>
        <Step
          title="Customize Email"
          validationSchema={emailFormValidation}
        >
          <EmailForm
            emailTemplates={Object.values(emailTemplates)}
            macros={macros}
          />
        </Step>
      </FormikStepWrapper>
    </ErrorBoundary>
  );
};

const mapStateToProps = ({ preclearance, certifications: { emailTemplates, macros, forms } }) => {
  const { statuses } = preclearance;
  const statusOptions = {};
  Object.values(statuses).forEach((status) => {
    statusOptions[status.id] = {
      value: status.id,
      label: status.label,
      required: status.default,
    };
  });
  return {
    statusOptions,
    emailTemplates,
    macros,
    forms: Object.values(forms),
  };
};

CertificationForm.propTypes = {
  getInitialValues: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  submitFunc: PropTypes.func.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  getEmployees: PropTypes.func.isRequired,
  getStatuses: PropTypes.func.isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  getMacros: PropTypes.func.isRequired,
  getForms: PropTypes.func.isRequired,
  statusOptions: PropTypes.object.isRequired,
  emailTemplates: PropTypes.object.isRequired,
  macros: PropTypes.object.isRequired,
  forms: PropTypes.array.isRequired,
};

CertificationForm.defaultProps = {
  edit: false,
};

const ConnectedCertificationForm = connect(
  mapStateToProps,
  {
    getEmployees: fetchEmployees,
    getStatuses: fetchPreStatuses,
    getEmailTemplates: fetchEmailTemplates,
    getMacros: fetchMacros,
    getForms: fetchForms,
  },
)(CertificationForm);

export { ConnectedCertificationForm as CertificationForm };
