import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import IconTextButton from 'components/monitoringForm/iconTextButton';


const ToggleField = ({ active, fieldName, toggleText }) => {
  const [_field, _meta, helpers] = useField(fieldName);
  return (
    <IconTextButton
      className={active ? 'toggle-active' : ''}
      icon={active ? 'toggle_on' : 'toggle_off'}
      buttonText={toggleText}
      buttonHandler={() => helpers.setValue(!active)}
    />
  );
};

ToggleField.propTypes = {
  active: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  toggleText: PropTypes.string.isRequired,
};

export default ToggleField;
