import React from 'react';
import PropTypes from 'prop-types';
import PlatformName from './PlatformName';


const PlatformLink = ({
  name,
  description,
  iconUrl,
  url,
}) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="option"
  >
    <PlatformName
      name={name}
      iconUrl={iconUrl}
    />
    <div className="platform-description">
      { description }
    </div>
  </a>
);


PlatformLink.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default PlatformLink;
