import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { createExport, getUpdatedCounts } from '../../reducers/bulkExports/actions';
import { createManualReport } from '../../reducers/reports/actions';
import { createReport } from "../../reducers/preclearance/reports/actions";
import TransitionContainer from '../transitionContainer';


class ExportProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: props.report.current || 0,
      total: props.report.total || 0,
    };
    this.loaded = this.isComplete();
    this.rerunReportGeneration = this.rerunReportGeneration.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.report && nextProps.report.errored) {
      this.transitor.advanceState(2);
    } else if (nextProps.report) {
      this.setState({
        current: nextProps.report.current,
        total: nextProps.report.total,
      }, () => {
        if (this.isComplete() && !this.loaded && this.transitor) {
          this.loaded = true;
          setTimeout((_) => {
            this.transitor.advanceState(1);
          }, 1000);
        } else if (!this.isComplete() && nextProps.report && !nextProps.report.errored) {
          this.transitor.advanceState(0);
        }
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.current !== nextState.current ||
      this.state.total !== nextState.total ||
      (nextProps.report && this.props.report.errored !== nextProps.report.errored) || (nextProps.report && this.props.report.is_complete !== nextProps.report.is_complete)
    );
  }

  isComplete() {
    return this.props.report.is_complete;
  }


  progressBar() {
    const { current, total } = this.state;
    const progress = total === 0 ? 0 : Math.floor((current / total) * 100);

    return (
      <div className="report-progress-container">
        <div className="report-progress-bar-container">
          <div className="report-progress-bar" style={{ width: `${progress}%` }} />
        </div>
        <span>
          { progress }%
        </span>
      </div>
    );
  }

  rerunReportGeneration(e) {
    e.preventDefault();
    const { date_from, date_to, search_objects } = this.props.report;
    const { exportType } = this.props;

    const newReportGen = exportType === 'report' ? this.props.onNewReportGeneration : this.props.getUpdatedCounts;

    // TODO make this better - hacky fix for now
    const exportObj = exportType === 'report' ? ({
      date_from,
      date_to,
    }) : ({
      date_from,
      date_to,
      search_objects,
    });
    const toastId = this.props.id;
    if (toast.isActive(toastId)) {
      toast.dismiss(toastId);
    }
    this.props.createReport(exportObj).then((record) => {
      if (record && record.id) {
        if (this.transitor && exportType === 'report') {
          this.transitor.advanceState(1);
        }
        newReportGen(record.id);
      }
    });
  }

  errorState() {
    return (
      <span>
        <a href="#" data-state={2} onClick={this.rerunReportGeneration}>
          <i className="material-icons">replay</i>
          ERROR
        </a>
      </span>
    );
  }

  render() {
    const { id } = this.props;
    const { errored } = this.props.report;
    const completed = this.isComplete();

    const expectedState = errored ? 2 : (completed ? 1 : 0);

    return (
      <TransitionContainer
        style={{ minHeight: '50px' }}
        ref={(el) => { this.transitor = el; }}
        initialState={expectedState}
      >
        { this.progressBar() }
        <span key={id}>
          <a data-state={1} href={this.props.link}>
            <i className="material-icons">save_alt</i>XLS
          </a>
        </span>
        {
          this.errorState()
        }
      </TransitionContainer>
    );
  }
}


const reportMapStateToProps = (state, props) => ({
  report: state.reports.reports.find(r => r.id === props.id),
});

const bulkExportMapStateToProps = (state, props) => ({
  report: state.bulkExports.exports.find(r => r.id === props.id),
});

const preclearanceReportMapStateToProps = (state, props) => ({
  report: state.preclearance.reports.reports.find(r => r.id === props.id),
});

const bulkMapDispatchToProps = {
  createReport: createExport,
  getUpdatedCounts,
};

const reportMapDispatchToProps = {
  createReport: createManualReport,
  getUpdatedCounts,
};

const preclearanceReportMapDispatchToProps = {
  createReport,
  getUpdatedCounts,
}

export const ReportProgress = connect(reportMapStateToProps, reportMapDispatchToProps)(ExportProgress);

export const BulkExportProgress = connect(bulkExportMapStateToProps, bulkMapDispatchToProps)(ExportProgress);

export const PreclearanceReportProgress = connect(preclearanceReportMapStateToProps, preclearanceReportMapDispatchToProps)(ExportProgress);


ExportProgress.propTypes = {
  report: PropTypes.object,
  id: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  exportType: PropTypes.string.isRequired,
  onNewReportGeneration: PropTypes.func,
  createReport: PropTypes.func.isRequired,
  getUpdatedCounts: PropTypes.func.isRequired,
};

ExportProgress.defaultProps = {
  report: null,
  onNewReportGeneration: () => {},
};
