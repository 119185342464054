import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PATHS, PATH_NAMES } from 'utils/constants';


const TableRow = ({
  id,
  name,
  modified,
  handleDelete,
}) => {
  const editLink = `${PATHS[PATH_NAMES.forms]}/${id}/edit`;
  return (
    <div
      className="certification-wrapper-row"
      role="row"
    >
      <div className="certification-row">
        <div className="certification-cell">
          <Link
            to={`${editLink}`}
            className="name-link"
          >
            <span>{ name }</span>
          </Link>
        </div>
        <div className="certification-cell">
          { moment(modified).format('ll') }
        </div>
        <div className="certification-cell">
          <Link
            to={`${editLink}`}
            className="icon-text-button"
          >
            <i className="material-icons">
              edit
            </i>
          </Link>
        </div>
        <div className="certification-cell">
          <button
            type="button"
            className="icon-text-button"
            onClick={handleDelete}
          >
            <i className="material-icons">
              delete
            </i>
          </button>
        </div>
      </div>
    </div>
  );
};

TableRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  modified: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default TableRow;
