import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';

import api from 'api';
import { PATHS, PATH_NAMES } from 'utils/constants';
import { buildQuery } from 'utils/apiUtil';
import { PreclearanceSurvey } from '../../PreclearanceSurvey';
import { SchemaForm } from '../../shared/SchemaForm/SchemaForm';
import ModalUI from '../../modal/modalUI';
import Loading from '../../loading';
import CertificationTitle from '../../shared/SchemaForm/FormTitle';
import CertificationPreclearanceRequests from './CertificationPreclearanceRequests';
import { UNATTESTED_PC_COLS } from '../constants';
import { SAMPLE_DATA } from './consts';


const CertificationSurveyForm = ({ payload, isPreview }) => {
  const defaultPCReqs = isPreview ? SAMPLE_DATA : [];
  const [pcRequests, setPCRequests] = useState(defaultPCReqs);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [shouldLoadPCReqs, setShouldLoadPCReqs] = useState(!isPreview);
  const [isLoading, setIsLoading] = useState(!isPreview);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { surveyId } = useParams();
  const pcRequestsUrl = '/attestations/employee/preclearance';
  const history = useHistory();
  const [modalContainer, setModalRef] = useState(null);


  const fetchPCRequests = (startDate, endDate, statuses) => {
    const query = {
      start_date: startDate,
      end_date: endDate,
      status: statuses,
    };
    api.get(`${pcRequestsUrl}?${buildQuery(query)}`).then(({ data }) => {
      setPCRequests(data);
    }).catch((err) => {
      if (err.response) {
        const { status } = err.response;
        if (status === 403) {
          history.push(`${PATHS[PATH_NAMES.not_authorized]}`);
        }
      }
      toast('There was a problem fetching your preclearance requests.');
      throw err;
    }).finally(() => {
      setIsLoading(false);
      setShouldLoadPCReqs(false);
    });
  };

  useEffect(() => {
    if (shouldLoadPCReqs) {
      const { start_date, end_date, status_filter } = payload;
      fetchPCRequests(start_date, end_date, status_filter);
    }
  }, [shouldLoadPCReqs]);

  const handleSubmit = async (data) => {
    setIsLoading(true);

    const submission = {};
    submission.responses = Object.entries(data).map(([key, value]) => ({
      field_id: key,
      value,
    }));
    submission.preclearance_requests = pcRequests.map(req => ({ custom_contribution: req.id }));

    try {
      const resp = await api.put(`/attestations/certifications/${surveyId}/attestations`, submission);
      if (resp.status === 200) {
        setHasSubmitted(true);
      }
    } catch (e) {
      toast('Something went wrong.');
      if (e.response) {
        return e.response.data;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreclearanceSubmit = () => {
    setIsModalOpen(false);
    setShouldLoadPCReqs(true);
  };

  const modalRef = useCallback((node) => {
    if (node !== null) {
      setModalRef(node);
    }
  }, []);

  const header = (
    payload.header && (
      <div className="survey-form-header">
        { payload.header}
      </div>
    )
  );

  const preclearanceModule = () => (
    <>
      <CertificationPreclearanceRequests
        pcRequests={pcRequests}
        startDate={payload.start_date}
        endDate={payload.end_date}
        columns={UNATTESTED_PC_COLS}
      />
      <div className="button-wrapper required-asterisk">
        <button
          type="button"
          className={`text-button uppercase add-field-button ${
            isPreview ? 'disabled' : ''
          }`}
          onClick={() => setIsModalOpen(true)}
          disabled={isPreview}
        >
          + Add additional preclearance requests
        </button>
      </div>
    </>
  );

  const footer = (
    payload.footer &&
    <div className="survey-form-footer">
      { payload.footer}
    </div>
  );

  const title = (
    <CertificationTitle title={payload.name || 'Certification Form'} organization={payload.organization} />
  );

  if (isLoading) {
    return (
      <div className="load-screen">
        <Loading />
      </div>
    );
  }

  if (hasSubmitted) {
    return (
      <div className="post-form-submission-page">
        <div className="text-group">
          <h1>Thank you!</h1>
          <p>Your certification form has been submitted.</p>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="survey-form-body">
        <div className="survey-panel">
          <SchemaForm
            formElements={payload.fields}
            title={title}
            header={header}
            footer={footer}
            formProps={{
              className: 'survey-form-section',
              id: 'details-form',
            }}
            submitProps={{
              id: 'survey-submit',
              disabled: isPreview,
            }}
            onSubmit={handleSubmit}
            fieldTypeKey={'input_type'}
            labelClass={'md-question'}
            preclearanceModule={preclearanceModule}
          />
        </div>
      </div>
      { isModalOpen ?
        <ModalUI
          maxWidth="75vw"
          open={isModalOpen}
          close={() => setIsModalOpen(false)}
          hideScroll
          className={'preclearance-modal'}
          modalRef={modalRef}
        >
          <PreclearanceSurvey
            tableIdOverride={payload.table}
            onSubmit={handlePreclearanceSubmit}
            scrollContainer={modalContainer}
          />
        </ModalUI>
        : null}
    </React.Fragment>
  );
};

CertificationSurveyForm.propTypes = {
  isPreview: PropTypes.bool,
  payload: PropTypes.object.isRequired,
};

CertificationSurveyForm.defaultProps = {
  isPreview: false,
};

export { CertificationSurveyForm };
