import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import DOMPurify from 'dompurify';
import api from 'api';
import NotFound from 'containers/notfound';
import NotAuthorized from 'containers/notauthorized';
import Loading from '../loading';
import { SchemaForm } from '../shared/SchemaForm/SchemaForm';
import FormTitle from '../shared/SchemaForm/FormTitle';

import { FileUpload } from './FileUpload';
import {
  createCandidateDetails,
  createCandidateText,
} from '../preclearance/table/candidateDropdown';

const PreclearanceSurvey = ({
  tableIdOverride,
  onSubmit,
  scrollContainer,
  isPreview,
}) => {
  const { tableId } = useParams();
  const dataUrl = `/pre/survey/${tableIdOverride || tableId}`;
  const [payload, setPayload] = useState({});
  const [files, setFiles] = useState([]);
  const [isNotAuthorized, setIsNotAuthorized] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const isLoading = !hasInitialized || isSubmitting || (payload.ssoUrl && !isPreview);

  useEffect(() => {
    api.get(dataUrl)
      .then((resp) => {
        const data = resp.data;
        const candidateField = data.elements.filter(
          element => element.internal_name === 'recipient',
        )[0];
        candidateField.renderResult = result => (
          <React.Fragment>
            {createCandidateText(result)}
            <span className="extra-data">
              {createCandidateDetails(result)}
            </span>
          </React.Fragment>
        );
        const jurisdictionField = data.elements.filter(element => element.internal_name === 'jurisdiction_name')[0];
        jurisdictionField.renderResult = result => (
          <span>
            {result.name}
            {!!result.parent_name && ` (${result.parent_name})`}
          </span>
        );
        setPayload(data);
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 403) {
            setIsNotAuthorized(true);
          }
        }
      })
      .finally(() => {
        setHasInitialized(true);
      });
  }, []);

  if (hasSubmitted) {
    return (
      <div className="post-form-submission-page">
        <div className="text-group">
          <h1>Thank you!</h1>
          <p>
            Your preclearance request form has been submitted, Compliance will
            review and notify you.
          </p>
          <a
            style={{
              cursor: 'pointer',
              color: payload.accent_color,
            }}
            onClick={(e) => {
              e.preventDefault();
              window.location.reload();
            }}
          >
            Please click here to submit another preclearance request
          </a>
        </div>
      </div>
    );
  }

  if (payload.ssoUrl && !isPreview) {
    window.location.href = payload.ssoUrl;
  }

  if (isNotAuthorized) {
    return <NotAuthorized />;
  }

  if (isEmpty(payload) && hasInitialized) {
    return <NotFound />;
  }

  const handleSubmit = async (data) => {
    setIsSubmitting(true);

    // Send back in required format for CustomContribution table
    const internalNames = payload.elements.filter(
      element => element.internal_name,
    );
    const internalNameMap = {};
    const response = {
      data: {},
    };

    // Create mapping of table field names
    internalNames.forEach((element) => {
      internalNameMap[element.id] = element.internal_name;
    });

    // Build response
    Object.entries(data)
      .filter(([fieldId, _]) => !fieldId.startsWith('exclude__'))
      .forEach(([fieldId, value]) => {
        if (fieldId in internalNameMap) {
          const internalName = internalNameMap[fieldId];
          response[internalName] = value;
        } else {
          response.data[fieldId] = value;
        }
      });
    if (files.length) {
      response.files = files;
    }

    // Send to backend, display errors if available
    try {
      const resp = await api.post(dataUrl, response);
      if (resp.status === 201) {
        setHasSubmitted(true);
        onSubmit();
      }
    } catch (e) {
      if (e.response) {
        const data = e.response.data || {};
        const errors = {};
        const fieldMap = {};

        // Create inverse name mapping
        Object.entries(internalNameMap).forEach(([key, value]) => {
          fieldMap[value] = key;
        });

        // Map field errors to IDs
        Object.entries(data).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            errors[fieldMap[key]] = value.join('\r\n');
          }
        });

        return errors;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpload = (files) => {
    const parsedFiles = [];
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        parsedFiles.push({
          data: reader.result.split(',')[1],
          name: file.name,
        });
      };
    });
    setFiles(parsedFiles);
  };

  const surveyTitle = (
    <FormTitle
      title={payload.title || 'Preclearance Form'}
      organization={payload.organization}
      logoImage={payload.logo_image}
    />
  );

  const surveyFooter = (
    <div>
      <FileUpload
        onUpload={handleUpload}
        maxSize={2097152}
        buttonColor={payload.accent_color}
      />
      {
        payload.footer && (
          <div className="survey-form-footer">
            {payload.footer}
          </div>
        )
      }
    </div>
  );

  const headerText = DOMPurify.sanitize(payload.header);
  const surveyHeader = (
    headerText && (
      <div
        dangerouslySetInnerHTML={{
          __html: headerText,
        }}
      />
    )
  );

  return (
    <>
      {isLoading && (
        <div className="load-screen">
          <Loading />
        </div>
      )}
      <div
        className="survey-form-body"
        style={{ display: isLoading ? 'none' : 'block' }}
      >
        {
          payload.banner_image
            && (
              <div
                className="survey-banner"
                style={{
                  backgroundImage: `url(${payload.banner_image})`,
                }}
              />
            )
        }
        {payload.elements && (
          <div className="survey-panel">
            <SchemaForm
              title={surveyTitle}
              header={surveyHeader}
              footer={surveyFooter}
              formElements={payload.elements}
              initialValues={payload.initialValues}
              formProps={{
                className: 'survey-form-section',
                id: 'details-form',
              }}
              submitProps={{
                id: 'survey-submit',
                disabled: isPreview,
                style: {
                  backgroundColor: payload.accent_color,
                },
              }}
              onSubmit={handleSubmit}
              fieldTypeKey="fieldType"
              labelClass="md-question"
              scrollContainer={scrollContainer}
            />
          </div>
        )}
      </div>
    </>
  );
};

PreclearanceSurvey.propTypes = {
  tableIdOverride: PropTypes.string,
  onSubmit: PropTypes.func,
  scrollContainer: PropTypes.object, // for the pc form in modal
  isPreview: PropTypes.bool,
};

PreclearanceSurvey.defaultProps = {
  tableIdOverride: null,
  onSubmit: () => null,
  scrollContainer: null,
  isPreview: false,
};

export { PreclearanceSurvey };
