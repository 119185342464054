import { combineReducers } from 'redux';
import rows from './rowsReducer';
import columns from './columnsReducer';
import uploads from './uploads/uploadsReducer';
import table from './table/tableReducer';
import statuses from './statuses/statusesReducer';
import notifications from './notifications/notificationsReducer';
import templates from './templates/templatesReducer';
import autoActions from './autoActions/autoActionsReducer';
import matchActions from './matchActions/matchActionsReducer';
import macros from './macrosReducer';
import ui from './ui/uiReducer';
import reports from './reports/reportsReducer';

export default combineReducers({
  rows,
  columns,
  uploads,
  table,
  statuses,
  notifications,
  templates,
  autoActions,
  matchActions,
  macros,
  ui,
  reports,
});
