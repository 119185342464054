/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import OnOffToggle from 'components/shared/onOffToggle';


export const BaseEditItem = ({
  buttonText,
  onSubmit,
  onClose,
  tooltip,
  errors,
  disabled,
  children,
}) => {
  return (
    <div className="editing action-item">
      <div className="action-wrapper">
        {children}
        <div className="action-buttons">
          <Tippy
            content={tooltip}
            disabled={!tooltip}
          >
            <div className="tooltip-wrapper">
              <button
                className="orange-button"
                onClick={onSubmit}
                disabled={disabled}
                type="button"
              >
                {buttonText}
              </button>
            </div>
          </Tippy>
          <button
            className="text-button"
            onClick={onClose}
            type="button"
          >
            <i className="material-icons">close</i>
          </button>
        </div>
      </div>
      <div className="error-text">
        {errors}
      </div>
    </div>
  );
};

BaseEditItem.propTypes = {
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.string,
};

BaseEditItem.defaultProps = {
  errors: '',
  tooltip: '',
  disabled: false,
};


export const BaseItem = ({
  tooltip,
  disabled,
  active,
  children,
  onToggleActive,
  onToggleEdit,
  onDelete,
  allowDelete,
}) => {
  return (
    <div className={`${!active ? 'inactive ' : ''}action-item`}>
      {disabled ? (
        <div className="error-wrapper">
          <Tippy
            content={tooltip}
            disabled={!tooltip}
          >
            <i className="material-icons error-icon">error_outline</i>
          </Tippy>
        </div>
      ) : ''}
      <div className="action-wrapper">
        {children}
        <div className="action-buttons">
          <Tippy
            content={tooltip}
            disabled={!tooltip}
          >
            <div className="toggle-wrapper">
              <OnOffToggle
                active={active}
                onToggle={onToggleActive}
                disabled={disabled}
              />
            </div>
          </Tippy>
          <button
            className="text-button"
            onClick={onToggleEdit}
            type="button"
          >
            <i className="material-icons">edit</i>
          </button>
          {allowDelete
            && (
              <button
                className="text-button"
                onClick={onDelete}
                type="button"
              >
                <i className="icon-trash" />
              </button>
            )}
        </div>
      </div>
    </div>
  );
};


BaseItem.propTypes = {
  disabled: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  allowDelete: PropTypes.bool,
  onToggleEdit: PropTypes.func.isRequired,
  onToggleActive: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  children: PropTypes.object.isRequired,
  tooltip: PropTypes.string,
};

BaseItem.defaultProps = {
  allowDelete: true,
  onDelete: () => { },
  tooltip: '',
};
