import React from 'react';
import PropTypes from 'prop-types';

import { StepperNav } from './StepperNav';
import { Step } from './Step';

export const StepType = PropTypes.shape({
  type: PropTypes.oneOf([Step]),
});

const Stepper = ({
  children,
  StepWrapperComponent,
  onCancel,
  disabled,
  currentStep,
  setCurrentStep,
}) => {
  const steps = children.map(child => ({
    title: child.props.title,
  }));

  return (
    <div className="stepper-wrapper">
      <StepperNav
        currentStep={currentStep}
        setStep={setCurrentStep}
        steps={steps}
        onCancel={onCancel}
        disabled={disabled}
      />
      <div className="stepper-body">
        <StepWrapperComponent>{children[currentStep]}</StepWrapperComponent>
      </div>
    </div>
  );
};

Stepper.propTypes = {
  children: PropTypes.arrayOf(StepType).isRequired,
  onCancel: PropTypes.func,
  StepWrapperComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.oneOf(['div', 'p', 'article', 'body', React.Fragment]),
  ]),
  disabled: PropTypes.bool,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

Stepper.defaultProps = {
  onCancel: () => null,
  StepWrapperComponent: React.Fragment,
  disabled: false,
};

export { Stepper };
