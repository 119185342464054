import React from 'react';
import PropTypes from 'prop-types';
import { ICON_SORT_MAP, ASC, DESC } from 'hooks/useSort';


function CertificationTableHeader({ columns, onSort, sortDir, sortBy }) {
  return (
    <div className="certification-wrapper-row">
      <div className="certification-row certification-header-row" role="row">
        {columns.map(({ label, sort }, i) => {
          const isActive = sortBy === sort;
          return (
            <div
              key={i}
              className={`${isActive && sort ? 'sorted' : ''} uppercase certification-cell`}
              onClick={sort ? () => { onSort(sort); } : null}
              role="cell"
              tabIndex={0}
              style={{
                cursor: sort ? 'pointer' : 'auto',
              }}
            >
              <div className="certification-header-cell">
                <span>
                  {label}
                </span>
                {
                  isActive ? (
                    <i className="sort-icon material-icons">
                      {
                        ICON_SORT_MAP[sortDir]
                      }
                    </i>
                  ) : (
                    null
                  )
                }
              </div>
            </div>
          );
        },
        )}
      </div>
    </div>
  );
}

CertificationTableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ).isRequired,
  onSort: PropTypes.func,
  sortDir: PropTypes.oneOf([ASC, DESC]),
  sortBy: PropTypes.string,
};

CertificationTableHeader.defaultProps = {
  onSort: () => {},
  sortDir: null,
  sortBy: null,
};

export default CertificationTableHeader;
