import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import {
  DATE,
  CHECKBOX,
  DECIMAL,
} from '../constants';
import {
  textFilterValidation,
  boolFilterValidation,
  dateFilterValidation,
  amountFilterValidation,
} from './filterValidators';
import {
  TextFilter,
  AmountFilter,
  BoolFilter,
  DateFilter,
  SelectFilter,
} from './filterComponents';
import { formatDate } from '../cellValidator';


const serializeDate = ({ to, from }) => ({
  from: from ? formatDate(from) : '',
  to: to ? formatDate(to) : '',
});

const defaultSerializer = (filter) => filter;


const FilterColumn = ({
  column,
  addFilter,
}) => {
  const {
    type,
    input_type: inputType,
    label,
    id,
    choices,
  } = column;

  let FilterInput;
  let initFilter;
  let validationSchema;
  let serializer = defaultSerializer;

  switch (type) {
    case DECIMAL:
      FilterInput = AmountFilter;
      initFilter = { from: '', to: '' };
      validationSchema = amountFilterValidation;
      break;
    case DATE:
      FilterInput = DateFilter;
      initFilter = { from: '', to: '' };
      validationSchema = dateFilterValidation;
      serializer = serializeDate;
      break;
    case CHECKBOX:
      FilterInput = BoolFilter;
      initFilter = false;
      validationSchema = boolFilterValidation;
      break;
    default:
      FilterInput = TextFilter;
      initFilter = '';
      validationSchema = textFilterValidation;
  }

  if (inputType === 'select') {
    FilterInput = SelectFilter;
  }

  const handleSubmit = (values) => {
    const { filter } = values;
    addFilter(label, id, type, serializer(filter), choices);
  };

  return (
    <div className="preclearance-popover-body">
      <div className="body">
        <Formik
          initialValues={{
            filter: initFilter,
          }}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <FilterInput
                choices={choices}
                label={label}
              />
              <div className="buttons">
                <button
                  type="submit"
                  className="text-button uppercase underline-button"
                  disabled={isSubmitting}
                >
                  Filter
                </button>
              </div>
            </Form>
          )}
        </Formik>

      </div>
    </div>

  );
};


FilterColumn.propTypes = {
  column: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
};

export default FilterColumn;
