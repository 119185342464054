import React from 'react';
import PropTypes from 'prop-types';
import IconTextButton from 'components/monitoringForm/iconTextButton';

function CertificationTemplateBody({ icon, heading, message, onDelete, onEdit }) {
  return (
    <div className="certification-template-body">
      <div className="certification-template-icon">
        <i className="material-icons">
          { icon }
        </i>
      </div>
      <div>
        <h3 className="certification-template-heading">{heading}</h3>
      </div>
      <div className="certification-template-message">
        {message}
      </div>
      <div className="certification-template-actions">
        <IconTextButton
          icon={'edit'}
          buttonHandler={onEdit}
          buttonText={'Edit certification'}
        />
        <IconTextButton
          icon={'delete'}
          buttonHandler={onDelete}
          buttonText={'Delete certification'}
        />
      </div>
    </div>
  );
}

CertificationTemplateBody.propTypes = {
  icon: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default CertificationTemplateBody;
