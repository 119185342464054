import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { POLITICAL_CONTRIBUTIONS } from 'utils/constants';
import ObjectDropdown from '../objectDropdown';
import Checkbox from '../../monitoringForm/sourceSelection/checkbox';
import ToolTip from '../../toolTip';
import RecordWrapper from './recordWrapper';
import RecordCell from './recordCell';

export default class ResultRow extends PureComponent {
  componentWillReceiveProps(nextProps) {
    const sortKeyChange = this.props.sortBy != nextProps.sortBy;
    const valuesChanged = this.props.schema.some(s => this.props.data[s.name] !== nextProps.data[s.name]);
    const shouldUpdate = sortKeyChange || valuesChanged;

    if (shouldUpdate) {
      this.forceUpdate();
    }
  }

  render() {
    const { schema, data, meta, contribSchema, selectedContribs, toggleContribution, hasJurSelected, statuses, showContribSchema, sourceCat, isJurisdiction } = this.props;
    const useRecordId = (hasJurSelected !== undefined ? !hasJurSelected : false); // if hasJurSel is undefined, or if it's false.
    const noteIdParam = useRecordId ? this.props.data.id : this.props.meta.contribution_id; // for submitting note
    // if record does not hasJurSelected using result id as a fallback
    // const contribId = meta.contribution_id || data.id; // todo: verify this is always === noteIdParam; this was used for noteCell's toggleNote.
    return (
      <RecordWrapper
        noteIdParam={noteIdParam}
        sourceId={this.props.sourceId}
        data={this.props.data}
        meta={this.props.meta}
        createComment={this.props.createComment}
        setSectionStatus={this.props.setSectionStatus}
        toggleContribution={this.props.toggleContribution}
        disableMatching={sourceCat !== POLITICAL_CONTRIBUTIONS}
        viewOnly={isJurisdiction}
        render={({
          onRecipientEnter,
          onRecipientExit,
          hasFields,
          showCcd,
          setStatus,
          renderComments,
          renderSendRecord,
          renderMatchButton,
        }) => {
          const checked = selectedContribs.has(meta.contribution_id);
          const currentStatus = statuses[meta.contribution_status];
          const disabledStatus = (hasJurSelected !== undefined ? !hasJurSelected : false);

          // TODO: make this copy better
          const disabledTooltipText = 'This source is not part of the selected sources for this search';

          return (
            <div className="dashboard-record">
              {showContribSchema ?
                <div className="record-checkbox">
                  <Checkbox
                    id={`contribs-${meta.contribution_id}-${this.props.objId}`}
                    checked={checked}
                    onChange={e => toggleContribution([meta.contribution_id], e.target.checked)}
                    disabled={disabledStatus}
                  >
                    <span />
                  </Checkbox>
                  {disabledStatus ?
                    <ToolTip position={'right'}><span>{disabledTooltipText}</span></ToolTip> : ''
                  }
                </div>
                : null
              }
              {schema.map(s => (
                <RecordCell
                  key={s.name}
                  schema={s}
                  onRecipientEnter={onRecipientEnter}
                  onRecipientExit={onRecipientExit}
                  data={data}
                  hasFields={hasFields}
                  showCcd={showCcd}
                />
              ))}
              {
                contribSchema.map(c => (
                  <div key={c.name} className={`dashboard-cell fixedWidth ${c.name === 'contribution_notes' || c.name === 'email_record' ? 'notes' : ''}`}>
                    {c.name === 'contribution_status' && !disabledStatus ?
                      <ObjectDropdown
                        dropdownText={currentStatus ? currentStatus.label : ''}
                        selected={currentStatus}
                        options={Object.values(statuses)}
                        handleDropdownSelection={setStatus}
                        field={'label'}
                      />
                      : ''}
                    {c.name === 'contribution_status' && disabledStatus ?
                      <div className="status-wrapper">
                        <ObjectDropdown
                          dropdownText={!disabledStatus ? currentStatus.label : 'n/a'}
                          selected={currentStatus}
                          options={[]}
                          handleDropdownSelection={() => { }}
                          disabled={disabledStatus}
                          field={'label'}
                        />
                        <ToolTip position={'top'}><span>{disabledTooltipText}</span></ToolTip>
                      </div>
                      : ''}
                    {c.name === 'matches' ?
                      renderMatchButton()
                      : null}
                    {c.name === 'contribution_notes' ?
                      renderComments()
                      : null
                    }
                    {c.name === 'email_record' ?
                      renderSendRecord()
                      : null
                    }
                  </div>
                ))
              }
            </div>
          );
        }}
      />
    );
  }
}

ResultRow.propTypes = {
  sortBy: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  schema: PropTypes.array.isRequired,
  contribSchema: PropTypes.array.isRequired, // status and notes
  selectedContribs: PropTypes.object.isRequired,
  toggleContribution: PropTypes.func.isRequired,
  setSectionStatus: PropTypes.func.isRequired,
  hasJurSelected: PropTypes.bool,
  createComment: PropTypes.func,
  statuses: PropTypes.object.isRequired,
  showContribSchema: PropTypes.bool, // determines whether to render checkboxes, notes, and statuses
  sourceId: PropTypes.number.isRequired,
  sourceCat: PropTypes.string, // n/a in jurView
  isJurisdiction: PropTypes.bool,
  objId: PropTypes.number,
};

ResultRow.defaultProps = {
  hasJurSelected: true,
  showContribSchema: true,
  createComment: () => { },
  sourceCat: '',
  isJurisdiction: false,
  objId: 0,
};
