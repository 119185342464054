import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createTag, editTag, removeTag } from 'reducers/tags/actions';
import { getSortedTags } from 'reducers/tags/selectors';
import Section from 'components/shared/settingsPage/section';
import SettingsTable from 'components/shared/settingsTable';
import OpenItemWrapper, { CONFIRMATION_TYPES } from 'components/shared/openItemWrapper';
import EditableItem from 'components/shared/editableItem';
import EditItem from 'components/shared/editItem';
import Confirmation from 'components/preclearance/settings/changeConfirmation';
import useCertifications from 'components/Certifications/CertificationsList/useCertifications';
import Loading from 'components/loading';
import { RelatedCertificationsList } from 'components/preclearance/settings/relatedDataConfirmations';
import { TAGS } from 'components/Certifications/constants';


// eslint-disable-next-line no-shadow
const TagsManager = ({ tags, createTag, editTag, removeTag }) => {
  const { templates, loading } = useCertifications();
  const charLimit = 50;
  const findErrors = (tag) => {
    const { name } = tag;
    if (!name.trim()) return 'Please enter a tag name.';
    if (name.length > charLimit) {
      return `Tag names must be less than ${charLimit} characters long.`;
    }
    const notUnique = tags.some(t => t.id !== tag.id && t.name === tag.name);
    if (notUnique) {
      return 'A tag with this name already exists';
    }
  };

  const renderRelatedData = (id, isDeleted) => {
    const relatedCertifications = templates.filter(cert =>
      (cert.employee_selector === TAGS && cert.tag_list && cert.tag_list.includes(id)));
    const message = isDeleted ? 'This tag will be removed from the following certifications and individuals with this tag will no longer receive it:' : '';
    return (
      <RelatedCertificationsList
        relatedCertifications={relatedCertifications}
        isDeleted={isDeleted}
        message={message}
      />
    );
  };

  return (
    <Section
      header="Manage Tags"
      helpText={<p>Tags can be used to create categories for different sets of employees within your organization. It may be useful to tag individuals by department or team.</p>}
    >
      {
        loading ?
          <div className="load-wrapper">
            <Loading />
          </div>
          :
          <OpenItemWrapper
            findErrors={findErrors}
            createFunc={createTag}
            updateFunc={editTag}
            deleteFunc={removeTag}
            entityName={'tag'}
            render={({
              openField,
              toggleEditing,
              onFieldChange,
              onCreate,
              onUpdate,
              onDelete,
              errors,
              showConfirmation,
              toggleConfirmationType,
              toggleConfirmation,
              confirmationType,
            }) => (
              <div>
                {
                  tags.length || openField ?
                    <SettingsTable
                      leftColumn={'Tag'}
                      rightColumns={['Edit', 'Delete']}
                    >
                      {
                        tags.map(tag => (
                          <EditableItem
                            key={tag.id}
                            editing={openField && openField.id === tag.id}
                            value={openField && openField.id === tag.id ? openField.name : tag.name}
                            toggleEditing={() => toggleEditing(tag)}
                            onClose={() => toggleEditing(null)}
                            onFieldChange={(e) => { onFieldChange(e.target.value, 'name'); }}
                            onSubmit={() => { toggleConfirmationType(CONFIRMATION_TYPES.update)(tag); }}
                            errors={errors || ''}
                            showDelete={false}
                            confirmationShowing={Boolean(showConfirmation)}
                            postEditCells={(
                              <div className="text-center table-cell">
                                <span>
                                  <button
                                    className="delete text-button"
                                    onClick={() => toggleConfirmationType(CONFIRMATION_TYPES.delete, false)(tag)}
                                    type="button"
                                    aria-label="delete"
                                  >
                                     <i className="icon-trash" />
                                  </button>
                                </span>
                              </div>
                            )}
                          />
                        ))
                      }
                    </SettingsTable>
                    : ''
                }
                <div className="table-wrapper">
                  <div className="table-container adding">
                    { openField && !openField.id ?
                      <div className="indent">
                        <EditItem
                          value={openField.name}
                          buttonText={'Add'}
                          showDelete={false}
                          onFieldChange={(e) => { onFieldChange(e.target.value, 'name'); }}
                          onClose={() => toggleEditing(null)}
                          extraCells={<div className="text-center table-cell" />}
                          errors={errors || ''}
                          onSubmit={onCreate}
                          confirmationShowing={Boolean(showConfirmation)}
                        />
                      </div>
                      :
                      <span className="button-wrapper indent">
                        <button
                          onClick={() => { toggleEditing({ name: '' }); }}
                          className="text-button uppercase add-field-button"
                        >
                      + Add custom tag
                        </button>
                      </span>
                    }
                  </div>
                </div>
                {showConfirmation ?
                  <Confirmation
                    onSubmit={confirmationType === CONFIRMATION_TYPES.delete ?
                      onDelete(showConfirmation.id)
                      : onUpdate
                    }
                    onCancel={() => { toggleConfirmation(null); }}
                    confirmText={confirmationType}
                    headerText={`${confirmationType} Tag`}
                    confirmSentence={<span>
                      Are you sure you would like to {confirmationType.toLowerCase()} the tag <strong>{`"${showConfirmation.name}"`}</strong>?
                    </span>}
                  >
                    <React.Fragment>
                      <div className="message">
                        {
                          confirmationType === CONFIRMATION_TYPES.delete ?
                            `This will remove the tag "${showConfirmation.name}" from all people with this tag. ` :
                            `All of the people currently tagged as "${showConfirmation.name}" will now be tagged as "${openField.name}". `
                        }
                      </div>
                      {renderRelatedData(showConfirmation.id, confirmationType === CONFIRMATION_TYPES.delete)}
                    </React.Fragment>
                  </Confirmation>
                  : ''
                }
              </div>
            )}
          />
      }

    </Section>
  );
};


TagsManager.propTypes = {
  tags: PropTypes.array.isRequired,
  createTag: PropTypes.func.isRequired,
  editTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tags: getSortedTags(state),
});


export default connect(
  mapStateToProps,
  {
    createTag,
    editTag,
    removeTag,
  })(TagsManager);
