import React from 'react';
import Section from 'components/shared/settingsPage/section';
import PropTypes from 'prop-types';
import Qr from './qr';


const [ENABLE, QR] = ['ENABLE', 'QR'];


const helpText = 'Two-factor authentication is not enabled for your account. Enable two-factor authentication for enhanced account security.';

function Enable({ toggleEnable }) {
  return (
    <Section
      helpText={<p>{helpText}</p>}
    >
      <button className="orange-button" onClick={toggleEnable}>enable two-factor authentication</button>
    </Section>
  );
}

Enable.propTypes = {
  toggleEnable: PropTypes.func.isRequired,
};

const stateMap = {
  [ENABLE]: Enable,
  [QR]: Qr,
};

class TwoFactor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: ENABLE,
    };
  }

  render() {
    const { state } = this.state;
    const Component = stateMap[state];
    return (
      <Component
        toggleEnable={() => { this.setState({ state: state === ENABLE ? QR : ENABLE }); }}
      />
    );
  }
}

export default TwoFactor;
