import React from 'react';
import PropTypes from 'prop-types';
import ObjectDropdown from '../../../../shared/objectDropdown';

const ActionOptions = ({ options, field, dropdownText, onDropdownSelect }) => (
  <ObjectDropdown
    options={options}
    dropdownText={dropdownText}
    handleDropdownSelection={onDropdownSelect}
    field={field}
    disabled={false}
  />
);

ActionOptions.propTypes = {
  options: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  onDropdownSelect: PropTypes.func.isRequired,
  dropdownText: PropTypes.string.isRequired,
};

ActionOptions.defaultProps = {
  errors: '',
};

export default ActionOptions;
