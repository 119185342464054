import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SimpleForm from 'components/shared/forms/simpleForm';
import Section from 'components/shared/settingsPage/section';
import { fetchCode, submitCode } from 'reducers/user/actions';
import QRCode from './svg';


const fieldMap = {
  token: {
    label: 'token',
    type: 'text',
    required: true,
  },
  key: {
    label: 'key',
    type: 'hidden',
  },
};

const helpText = 'To start using a token generator, please use your smartphone to scan the QR code below. For example, use Google Authenticator. Then, enter the token generated by the app.';

class Qr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      loading: true,
    };
    this.key = '';
  }

  componentDidMount() {
    fetchCode().then(({ data }) => {
      this.setState({ code: data.path_d, loading: false, key: data.key });
      this.key = data.key;
    }).catch(() => {
      this.setState({ error: 'There was a problem loading your QR code. Please refresh and try again.' });
    });
  }

  render() {
    const { toggleEnable, submitCode } = this.props;
    return (
      <Section
        helpText={<p>{helpText}</p>}
        loading={this.state.loading}
      >
        <div className="qr-form-container">
          <QRCode pathD={this.state.code} />
          <SimpleForm
            submitOnEnter
            fieldMap={fieldMap}
            defaultState={{ token: '', key: this.state.key }}
            onSubmit={submitCode}
            onCancel={toggleEnable}
            confirmText={'next'}
            cancelText={'cancel'}
            genericError={'There was a problem submitting this token.'}
          />
        </div>
      </Section>
    );
  }
}

Qr.propTypes = {
  toggleEnable: PropTypes.func.isRequired,
  submitCode: PropTypes.func.isRequired,
};

export default connect(null, { submitCode })(Qr);
