import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Stepper, StepType } from 'components/shared/Stepper';


const StepWrapper = ({ children }) => <div className="create-cert-wrapper">{children}</div>;

StepWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};


const FormikStepWrapper = ({
  children,
  initialValues,
  submitForm,
  handleCancel,
}) => {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];

  const handleSubmit = (values, actions) => {
    const isLast = step === childrenArray.length - 1;
    if (isLast) {
      submitForm(values, actions);
    } else {
      setStep(currentStep => currentStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={handleSubmit}
    >
      {
        ({ isSubmitting }) => (
          <Form autoComplete="off">
            <Stepper
              StepWrapperComponent={StepWrapper}
              onCancel={handleCancel}
              disabled={isSubmitting}
              currentStep={step}
              setCurrentStep={setStep}
            >
              {childrenArray}
            </Stepper>
          </Form>
        )
      }
    </Formik>
  );
};

FormikStepWrapper.propTypes = {
  children: PropTypes.arrayOf(StepType).isRequired,
  initialValues: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default FormikStepWrapper;
