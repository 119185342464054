import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

import { SidebarSearch } from './search';
import SearchItemTab from './searchItemTab';
import { SidebarEmpty, SidebarNoSearchQuery } from './sidebarEmpty';

class EntitySidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
    };

    this.getRow = this.getRow.bind(this);
    this.noRowsRenderer = this.noRowsRenderer.bind(this);
    this.filterEntities = this.filterEntities.bind(this);
    this.onSearchFilter = this.onSearchFilter.bind(this);
  }


  onSearchFilter(e) {
    this.setState({
      filter: e.target.value,
    });
  }


  getRow({ index, style, key }) {
    const filteredEntities = this.filterEntities();
    const entityObj = filteredEntities[index];
    const isFirst = index === 0;
    const isLast = index === filteredEntities.length - 1;
    const { uiSearchType } = this.props;
    return (
      <div key={key} style={style} className={`emp-row${isFirst ? ' first' : ''}${isLast ? ' last' : ''}`}>
        <SearchItemTab
          search={entityObj}
          uiSearchType={uiSearchType}
        />
      </div>
    );
  }

  noRowsRenderer(filter) {
    const { entities, uiSearchType } = this.props;
    if (entities.length === 0) {
      return <SidebarEmpty searchType={uiSearchType} />;
    }
    return <SidebarNoSearchQuery searchType={uiSearchType} query={filter} />;
  }

  filterEntities() {
    const { filter } = this.state;
    const sortedEntities = [...this.props.entities].sort((a, b) => {
      if (a.entity_name.toLowerCase() < b.entity_name.toLowerCase()) {
        return -1;
      }
      if (a.entity_name.toLowerCase() > b.entity_name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    const RegExpEscape = str => str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    const regTest = new RegExp(RegExpEscape(filter), 'gi');
    const filterInUse = !(filter.length === 0 || !filter.trim());
    if (filterInUse) {
      return sortedEntities.filter((e) => {
        const names = [e.entity_name];
        return names.some(n => regTest.test(n)) || names.some(n => regTest.test(n));
      });
    }
    return sortedEntities;
  }


  render() {
    const { scrollableHeight, uiSearchType, shouldRender } = this.props;
    const { filter } = this.state;

    const filteredEntities = this.filterEntities();
    const totalRows = filteredEntities.length;

    // doing this here to save filter when switching tabs
    if (!shouldRender) {
      return null;
    }

    return (
      <div>
        <div className="sidebar-search-container">
          <SidebarSearch
            onSearchFilter={this.onSearchFilter}
            filterValue={filter}
            searchPlaceholder={`search ${uiSearchType}...`}
          />
        </div>
        <div className="alpha-selector-entities">
          <List
            height={scrollableHeight}
            rowCount={totalRows}
            rowHeight={window.innerWidth <= 1025 ? 58 : 68}
            rowRenderer={this.getRow}
            noRowsRenderer={() => this.noRowsRenderer(filter)}
            width={this.props.sidebarWidth}
            ref={(el) => { this.list = el; }}
          />
        </div>
      </div>
    );
  }
}

EntitySidebar.propTypes = {
  entities: PropTypes.array.isRequired,
  scrollableHeight: PropTypes.number.isRequired,
  sidebarWidth: PropTypes.number.isRequired,
  uiSearchType: PropTypes.string.isRequired,
  shouldRender: PropTypes.bool.isRequired,
};


export default EntitySidebar;
