import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';


const BatchEditBanner = ({
  options, numSelected, setRecordStatus, onCancel,
}) => {
  const [chosenOption, setChosenOption] = useState(null);

  useEffect(() => {
    const onEscKey = (e) => {
      if (e.key === 'Escape') {
        onCancel();
      }
    };
    document.addEventListener('keydown', onEscKey);
    return () => document.removeEventListener('keydown', onEscKey);
  }, [onCancel]);

  const onSave = () => {
    setRecordStatus(chosenOption);
  };

  return (
    <div>
      { chosenOption
        ? (
          <div className="edit-banner confirmation">
            <div>
              Are you sure you want to mark
              {' '}
              <strong>{numSelected}</strong>
              {' '}
              { pluralize('record', numSelected) }
              {' '}
              as
              {' '}
              <strong>{`"${chosenOption.label}"`}</strong>
              ?
            </div>
            <div>
              <button
                className="orange-button"
                onClick={onSave}
                type="button"
              >
                Yes, mark
                {' '}
                { pluralize('record', numSelected) }
              </button>
              <button
                className="text-button uppercase close"
                onClick={() => { setChosenOption(null); }}
                type="button"
              >
                Nevermind
              </button>
            </div>
          </div>
        )

        : (
          <div className="edit-banner">
            <div />
            <div>
              <span className="banner-text">
                Mark
                {' '}
                <strong>{numSelected}</strong>
                {' '}
                { pluralize('record', numSelected) }
                {' '}
                as:
              </span>
              {options.map((o) => (
                <button
                  key={o.id}
                  type="button"
                  className={chosenOption && chosenOption.id === o.id ? 'active option' : 'option'}
                  onClick={() => { setChosenOption(o); }}
                >
                  <span>{o.label}</span>
                </button>
              ))}
            </div>

            <div>
              <button
                className="text-button uppercase close"
                onClick={onCancel}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
    </div>
  );
};

BatchEditBanner.propTypes = {
  options: PropTypes.array.isRequired,
  numSelected: PropTypes.string,
  setRecordStatus: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

BatchEditBanner.defaultProps = {
  numSelected: '0',
};

export default BatchEditBanner;
