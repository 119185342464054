import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editEmail } from 'reducers/preclearance/table/tableActions';
import Section from 'components/shared/settingsPage/section';
import EditableItem from 'components/shared/editableItem';
import OpenItemWrapper from 'components/shared/openItemWrapper';
import SettingsTable from 'components/shared/settingsTable';

import { handleInvalidEmailError } from '../../../table/helpers/utils';

const onSubmitCatch = err => (
  handleInvalidEmailError(err) || 'There was a problem adding this email address. Please Ensure it is a valid email.'
);

function FromEmail({ fromEmail, tableId, editEmail }) {
  return (
    <Section
      containerClass={'notifications'}
      header={'Manage Email Address'}
      helpText={(
        <p>
          Specify the email address that replies to automated emails will be directed to.
        </p>
      )}
    >
      <OpenItemWrapper
        findErrors={({ email }) => {
          if (!email) return 'Please enter a valid email address.';
        }}
        updateFunc={editEmail}
        entityName={'email'}
        errorCb={onSubmitCatch}
        render={({
          openField,
          toggleEditing,
          onFieldChange,
          errors,
          onUpdate,
        }) => (
          <SettingsTable
            leftColumn={'Email'}
            rightColumns={['Edit']}
          >
            <EditableItem
              editing={Boolean(openField)}
              value={openField ? openField.email : fromEmail}
              toggleEditing={() => toggleEditing({ tableId, email: fromEmail })}
              onClose={() => toggleEditing(null)}
              onFieldChange={(e) => { onFieldChange(e.target.value, 'email'); }}
              onSubmit={onUpdate}
              errors={errors || ''}
              showDelete={false}
            />
          </SettingsTable>
        )}
      />
    </Section>
  );
}


const mapStateToProps = state => ({
  fromEmail: state.preclearance.table.email,
  tableId: state.preclearance.table.tableId,
});

export default connect(mapStateToProps, { editEmail })(FromEmail);

FromEmail.propTypes = {
  fromEmail: PropTypes.string,
  tableId: PropTypes.string.isRequired,
  editEmail: PropTypes.func.isRequired,
};

FromEmail.defaultProps = {
  fromEmail: '',
};
