import api from 'api';
import * as types from './types';

const loadTemplates = templates => ({
  templates,
  type: types.LOAD_TEMPLATES,
});

const addTemplate = template => ({
  template,
  type: types.ADD_TEMPLATE,
});

const editTemplate = template => ({
  template,
  type: types.EDIT_TEMPLATE,
});

const removeTemplate = id => ({
  id,
  type: types.REMOVE_TEMPLATE,
});


export const fetchEmailTemplates = () => dispatch => api.get('/attestations/email/templates/').then(({ data }) => {
  dispatch(loadTemplates(data));
});


export const createEmailTemplate = template => dispatch => api.post('/attestations/email/templates/', template).then(({ data }) => {
  dispatch(addTemplate(data));
});

export const updateEmailTemplate = template => dispatch => api.put(`/attestations/email/templates/${template.id}/`, template).then(({ data }) => {
  dispatch(editTemplate(data));
});

export const deleteEmailTemplate = id => dispatch => api.delete(`/attestations/email/templates/${id}/`).then(() => {
  dispatch(removeTemplate(id));
});
