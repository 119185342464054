import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalUI from '../modal/modalUI';
import TransitionContainer from '../transitionContainer';

class ReportGenerationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.retryGeneration = this.retryGeneration.bind(this);
  }


  componentWillReceiveProps(nextProps) {
    if (!this.props.report || !nextProps.report) {
      return;
    }
    const nextCompleted = nextProps.report.is_complete;
    const hasCompleted = this.props.report.is_complete;
    const statefulChangeOccurred = (nextCompleted && nextCompleted != hasCompleted) ||
        nextProps.report.errored != this.props.report.errored;

    const hasErrored = nextProps.report.errored;

    if (this.transitor && this.buttonTransitor) {
      if (statefulChangeOccurred) {
        setTimeout((_) => {
          if (nextCompleted && this.transitor) {
            this.transitor.advanceState(1);
          } else if (hasErrored && this.transitor) {
            this.transitor.advanceState(2);
            this.buttonTransitor.advanceState(1);
            this.setState({
              hasError: true,
            });
          } else if (this.transitor) {
            this.transitor.advanceState(0);
            this.buttonTransitor.advanceState(0);
          }
        }, 1500);
      } else if (nextCompleted && this.transitor.getState() !== 1) {
        this.transitor.advanceState(1);
      }
    }
  }

  open() {
    this.setState({
      open: true,
    });
  }

  close() {
    this.transitor.reset();
    this.setState({
      open: false,
    });
  }

  retryGeneration(e) {
    e.preventDefault();
    this.props.createManualReport({
      date_to: this.props.report.date_to,
      date_from: this.props.report.date_from
    }).then(() => {
      this.props.onNewReportGeneration(this.props.report.id);
    });
  }

  render() {
    if (!this.state.open) {
      return null;
    }
    const progress = Math.floor(this.props.report.current / this.props.report.total * 100) || 0;
    const path = this.props.report.download_url || `/reports/${this.props.report.id}/`;

    return (
      <ModalUI
        open={this.state.open}
        close={this.close}
        center
        width={'70vw'}
        maxWidth={'800px'}
      >
        <div className="generation-progress">
          <span className="generation-header">
            {this.props.message}
          </span>
          <TransitionContainer ref={(el) => { this.transitor = el; }} style={{ minHeight: 200 }}>
            <div className="generation-load-container">
              <div className="generation-load-bar">
                <div className="generation-load-progress" style={{ width: `${progress}%` }} />
              </div>
              <span>
                { progress }%
              </span>
            </div>
            <div className="generation-loaded-text">
              Your report has completed!
            </div>
            <div className="generation-loaded-text">
              An error has occurred while generating your report. Click below to try again.
            </div>
          </TransitionContainer>

          <div className="generation-button-container">
            <TransitionContainer ref={el => this.buttonTransitor = el}>
              <a
                href={path}
                className={`${this.props.report.is_complete ? '' : 'disabled'}`}
                onClick={(e) => {
                  if (progress < 100) {
                    return e.preventDefault();
                  }
                  this.close();
                }}
              >
                Download Report
              </a>
              <a
                href="#"
                onClick={this.retryGeneration}
              >
                Generate Report
              </a>
            </TransitionContainer>

          </div>
        </div>
      </ModalUI>
    );
  }
}

ReportGenerationModal.propTypes = {
  report: PropTypes.object,
  message: PropTypes.string,
};

ReportGenerationModal.defaultProps = {
  report: {},
  message: "Your report is being generated, this may take a few minutes depending on the number of people you are monitoring. If you close this window, it will also be available in the past reports section."
};

export default ReportGenerationModal;
