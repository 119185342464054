import { combineReducers } from 'redux';
import byId from './byId';
import allIds from './allIds';


const entities = combineReducers({
  byId,
  allIds,
});

export default entities;
