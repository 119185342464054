import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import pluralize from 'pluralize';
import { EMPLOYEES, MONITORING_ROUTES, BASE_MONITORING_URL } from '../../utils/constants';
import EntitySidebar from './entitySidebar';
import FiltersDropdown from '../filtersDropdown/filtersDropdownContainer';
import NewButton from './newButton';
import EmployeeSidebar from './employeeSidebar';


export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollableHeight: 0,
      tagFilters: [],
    };

    this.setScrollableHeight = this.setScrollableHeight.bind(this);
    this.setTagFilters = this.setTagFilters.bind(this);

    window.addEventListener('resize', this.setScrollableHeight);

    this.batchEditBannerHeight = 95;
  }


  componentDidMount() {
    this.setScrollableHeight();
  }


  componentDidUpdate(prevProps) {
    const { searchType } = this.props;
    if ((searchType !== prevProps.searchType) || (this.props.batchEditMode !== prevProps.batchEditMode)) {
      const editBanner = document.querySelector('.edit-banner');
      this.batchEditBannerHeight = editBanner ? editBanner.clientHeight : 95;
      this.setScrollableHeight();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScrollableHeight);
  }


  setTagFilters(tagFilters) {
    this.setState({ tagFilters: Array.from(tagFilters) });
  }


  setScrollableHeight() {
    const sidebar = document.querySelector('.sidebar') ? document.querySelector('.sidebar').clientHeight : 0;

    const search = document.querySelector('.sidebar-search-container') ? document.querySelector('.sidebar-search-container').clientHeight : 0;

    const tabs = document.querySelector('.tabs') ? document.querySelector('.tabs').clientHeight : 0;

    const addButton = document.querySelector('.new-button-wrapper') ? document.querySelector('.new-button-wrapper').clientHeight : 0;

    const dateFilterHeight = document.querySelector('.date-wrapper') ? document.querySelector('.date-wrapper').clientHeight : 0;

    let scrollableHeight = (sidebar - search - addButton - tabs - dateFilterHeight) - 92;

    if (this.props.batchEditMode) {
      scrollableHeight -= this.batchEditBannerHeight;
    }

    this.setState({
      scrollableHeight,
    });
  }


  render() {
    const {
      searchType,
      uiSearchType,
      batchEditMode,
      disableAdd,
      employeeSyncEnabled,
    } = this.props;
    const { tagFilters } = this.state;
    const singularSearchType = pluralize.singular(uiSearchType);

    const isEmployee = searchType === EMPLOYEES;
    const sidebar = document.querySelector('.sidebar');
    const sidebarWidth = sidebar ? sidebar.clientWidth : 280;

    return (
      <div className="sidebar">
        <div className="date-wrapper">
          <FiltersDropdown
            showTagFilters={isEmployee}
            setTagFilters={this.setTagFilters}
            tagFilters={new Set(tagFilters)}
          />
        </div>
        <div className="tabs">
          { MONITORING_ROUTES.map(route => (
            <NavLink
              key={route}
              className={'tab'}
              activeClassName={'selected-tab'}
              to={`/app/${BASE_MONITORING_URL}/${route}`}
            >
              <span className="text-button uppercase">{route}</span>
            </NavLink>
          ))}
        </div>
        <EmployeeSidebar
          employees={this.props.employees}
          selected={this.props.selected}
          uiSearchType={uiSearchType}
          onEmployeeTab={isEmployee}
          fetchEmployees={this.props.fetchEmployees}
          scrollableHeight={this.state.scrollableHeight}
          sidebarWidth={sidebarWidth}
          tagFilters={tagFilters}
        />
        <EntitySidebar
          entities={this.props.entities}
          scrollableHeight={this.state.scrollableHeight}
          sidebarWidth={sidebarWidth}
          uiSearchType={uiSearchType}
          shouldRender={!isEmployee}
        />
        <NewButton
          url={`/app/${BASE_MONITORING_URL}/${uiSearchType}/new`}
          disabled={disableAdd}
          type={singularSearchType}
          style={{ bottom: batchEditMode ? `${this.batchEditBannerHeight}px` : '0px' }}
          syncEnabled={searchType === EMPLOYEES && employeeSyncEnabled}
        />
      </div>
    );
  }
}


Sidebar.propTypes = {
  employees: PropTypes.array.isRequired,
  entities: PropTypes.array.isRequired,
  selected: PropTypes.object,
  searchType: PropTypes.string.isRequired,
  uiSearchType: PropTypes.string.isRequired,
  batchEditMode: PropTypes.bool,
  fetchEmployees: PropTypes.func.isRequired,
  disableAdd: PropTypes.bool,
  employeeSyncEnabled: PropTypes.bool,
};

Sidebar.defaultProps = {
  selected: null,
  batchEditMode: false,
  disableAdd: false,
  employeeSyncEnabled: false,
};
