import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import moment from 'moment';
import { isInclusivelyAfterDay } from 'react-dates';

import { RadioField, DateField, DateRangeField, TextField, SelectField } from './components';
import { FREQUENCY_TYPES, SCHEDULE_TYPES, ONCE } from '../../constants';
import { MultiSelectDropdown } from './components/MultiSelect';
import { noPublishedForms } from '../../CertificationsList/Certifications';


const SetUpForm = ({ edit, statusOptions, forms }) => {
  const [frequency] = useField('frequency');
  return (
    <div className="create-cert-form">
      <h3>{ edit ? 'Edit ' : 'Create '} Certification</h3>
      <TextField name="name" label="Name of certification" />
      <SelectField
        label='Form'
        name='form'
        choices={forms}
        noOptionsMessage={() => (noPublishedForms)}
      />
      <RadioField
        choices={FREQUENCY_TYPES}
        label="Certification Type"
        name="frequency"
      />
      {frequency.value === ONCE ? (
        <React.Fragment>
          <DateField
            name="at_date"
            label="Sending Date"
            helpText={'Certifications will be sent at 9 am Eastern Time on the selected day'}
            isOutsideRange={day => !isInclusivelyAfterDay(day, moment())}
          />
          <div id="date-range" className="form-field">
            <label id="range-label">
              Certification period
            </label>
            <DateRangeField
              isOutsideRange={() => false}
              startFieldName="start_date"
              endFieldName="end_date"
              startDateLabel="from"
              endDateLabel="to"
            />
          </div>
        </React.Fragment>
      ) : (
        <RadioField choices={SCHEDULE_TYPES} label="Frequency" name="schedule" />
      )}
      <MultiSelectDropdown
        label="Select preclearance statuses"
        name="status_filter"
        choiceMap={statusOptions}
        choices={Object.values(statusOptions)}
        nonSelectedValue={'All'}
      />
    </div>
  );
};

SetUpForm.propTypes = {
  statusOptions: PropTypes.object.isRequired,
  forms: PropTypes.array.isRequired,
  edit: PropTypes.bool,
};

SetUpForm.defaultProps = {
  edit: false,
};

export { SetUpForm };
