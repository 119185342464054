import React from 'react';
import { Field, useField } from 'formik';


export const BoolFilter = () => {
  const [field, meta, helpers] = useField('filter');
  return (
    <>
      <span className="input-title">
        Filter
      </span>
      <div className="preclearance-checkbox">
        <Field
          type="checkbox"
          name="filter"
          id="filter"
        />
        <span className="checkmark" />
        {meta.touched && meta.error && (
          <p className="error-text">
            {meta.error}
          </p>
        )}
      </div>
    </>
  );
};
