import React from 'react';
import PropTypes from 'prop-types';
import { formatAmount } from 'helpers/formatFieldHelpers';


export const AmountValue = ({ value, currency }) => (
  <span className="right-align">
    {formatAmount(value, currency)}
  </span>
);


AmountValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  currency: PropTypes.string,
};

AmountValue.defaultProps = {
  currency: null,
};
