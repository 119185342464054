import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationShared from 'components/settings/confirmationShared';

const TemplateConfirmation = ({
  close,
  onSubmit,
}) => (
  <ConfirmationShared
    open
    close={close}
    dismissText={'Cancel'}
    confirmText={'Delete'}
    onSubmit={onSubmit}
    maxWidth={'700px'}
  >
    <h2>Delete Certification</h2>
    <div className="message">
      Are you sure you would like to delete this certification?
    </div>
  </ConfirmationShared>
);

TemplateConfirmation.propTypes = {
  close: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TemplateConfirmation;
