import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { closeModal } from 'reducers/ui/modal/actions';
import { removeMatch } from 'reducers/matches/actions';

// eslint-disable-next-line no-shadow
const DeleteMatchConfirmation = ({ closeModal, removeMatch, modalProps }) => (
  <div>
    <h2>Remove Match</h2>
    <div className="message">
      Are you sure you would like to remove this preclearance request from this monitored record?
    </div>
    <div className="button-wrapper">
      <button
        onClick={closeModal}
        className="text-button uppercase"
      >
        Cancel
      </button>
      <button
        className="orange-button"
        onClick={() => {
          removeMatch(modalProps).catch((err) => {
            toast('There was a problem unmatching these records.');
            throw err;
          });
          closeModal();
        }}
      >
        Unmatch
      </button>
    </div>
  </div>
);

export default connect(
  null,
  {
    closeModal,
    removeMatch,
  },
)(DeleteMatchConfirmation);

DeleteMatchConfirmation.propTypes = {
  modalProps: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  removeMatch: PropTypes.func.isRequired,
};
