import React from 'react';
import PropTypes from 'prop-types';
import OnOffToggle from 'components/shared/onOffToggle';
import Tooltip from 'components/toolTip';

const ToggleWithLabel = ({ onToggle, active, disabled, label, tooltipText }) => (
  <div className="labeled-toggle">
    <span className="input-title">{label}</span>
    <div className="tooltip-wrapper">
      <OnOffToggle
        onToggle={() => { onToggle(!active); }}
        active={active}
        disabled={disabled}
      />
      { tooltipText && <Tooltip>{tooltipText}</Tooltip>}
    </div>
  </div>
);

ToggleWithLabel.propTypes = {
  onToggle: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

ToggleWithLabel.defaultProps = {
  tooltipText: '',
};

export default ToggleWithLabel;
