import api from 'api';
import * as types from './types';

const loadForms = forms => ({
  forms,
  type: types.LOAD_FORMS,
});


export const fetchForms = () => dispatch => api.get('/forms/form/choices/').then(({ data }) => {
  dispatch(loadForms(data));
});
