import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'components/errorBoundary';
import ModalUI from 'components/modal/modalUI';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import ChartItemContainer from './chartItemContainer';
import { CHART_MAP } from './consts';


function ChartsWrapper({ closeCharts }) {
  return (
    <ErrorBoundary>
      <ModalUI
        close={closeCharts}
        open
        maxWidth="100vw"
        minWidth="100vw"
        className="full-screen-modal"
      >
        <div className="charts-wrapper">
          <div className="grid-container">
            <IconTextButton
              buttonHandler={closeCharts}
              buttonText="Back to dashboard"
              icon="arrow_back"
              className="back-button"
            />
          </div>
          <div className="grid-container">
            {
            Object.values(CHART_MAP).map((chart) => (
              <div key={chart.nameKey} className="grid-item">
                <ChartItemContainer
                  title={chart.title}
                  nameKey={chart.nameKey}
                  limit={chart.limit}
                  chartType={chart.type}
                  allowExport={chart.allowExport}
                  defaultDate={chart.defaultDate}
                  order={chart.order}
                  sortByDim={chart.sortByDim}
                  exportFunc={chart.exportFunc}
                />
              </div>
            ))
          }
          </div>
        </div>
      </ModalUI>
    </ErrorBoundary>
  );
}


ChartsWrapper.propTypes = {
  closeCharts: PropTypes.func.isRequired,
};

export default ChartsWrapper;
