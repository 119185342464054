import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import api from 'api';
import { buildQuery } from 'utils/apiUtil';
import { toast } from 'react-toastify';
import Loading from 'components/loading';
import SubmissionHeader from './SubmissionHeader';
import { ATTESTED_PC_COLS, UNATTESTED_PC_COLS } from '../constants';
import SubmissionBody from '../CertificationSurvey/SubmissionBody';


function Submission({ startDate, endDate, fields, header, footer, statuses }) {
  const [submission, setSubmission] = useState({});
  const [unattestedPCReqs, setPCReqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id, submissionId } = useParams();

  useEffect(() => {
    let didCancel = false;
    api.get(`/attestations/certifications/${id}/attestations/${submissionId}/`).then(({ data }) => {
      if (!didCancel) {
        setSubmission(data);
        // if employee was deleted and didn't fill out form the call to get their pc reqs will fail
        if (!data.submitted_at && !data.is_deleted) {
          const query = {
            start_date: startDate,
            end_date: endDate,
            status: statuses,
          };
          api.get(`/compliance/employee/${data.employee}/preclearance?${buildQuery(query)}`).then((res) => {
            if (!didCancel) {
              setPCReqs(res.data);
            }
          }).catch((err) => {
            if (!didCancel) {
              toast('Something went wrong and we were unable to fetch the preclearance requests for this submission');
              throw (err);
            }
          }).finally(() => {
            if (!didCancel) {
              setLoading(false);
            }
          });
        } else {
          setLoading(false);
        }
      }
    }).catch((err) => {
      if (!didCancel) {
        setLoading(false);
        toast('Something went wrong and we were unable to fetch this submission');
        throw (err);
      }
    });
    return () => {
      didCancel = true;
    };
  }, []);

  const { responses, is_deleted: deleted } = submission;
  const submissionDate = submission.submitted_at;
  const preclearanceRequests = submissionDate ? submission.preclearance_requests : unattestedPCReqs;
  const pcCols = submissionDate ? ATTESTED_PC_COLS : UNATTESTED_PC_COLS;
  const name = `${submission.first_name} ${submission.last_name}`

  return (
    <div className="drawer">
      {
        loading ?
          <div className="flex-load-wrapper">
            <Loading />
          </div>
          :
          <div>
            <SubmissionHeader
              name={<div>{ name } { deleted && <i>(deleted)</i>}</div>}
              date={submissionDate}
              certificationId={id}
            />
            <div className="submission-body">
              <SubmissionBody
                header={header}
                footer={footer}
                fields={fields}
                startDate={startDate}
                endDate={endDate}
                pcColumns={pcCols}
                preclearanceRequests={preclearanceRequests}
                responses={responses}
                showDependentFields={!submissionDate}
              />
            </div>
          </div>
      }
    </div>
  );
}

Submission.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  fields: PropTypes.array.isRequired,
  header: PropTypes.string,
  footer: PropTypes.string,
  statuses: PropTypes.array,
};

Submission.defaultProps = {
  startDate: '',
  endDate: '',
  header: null,
  footer: null,
  statuses: [],
};

export default Submission;
