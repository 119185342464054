import React from 'react';
import PropTypes from 'prop-types';

export const SidebarEmpty = ({ searchType }) => (
  <div className="sidebar-empty">
    <div>
      { searchType === 'companies' ?
        <i className="material-icons">
        work_outline
        </i> :
        <i className="material-icons">
        person_outline
        </i>
      }

      <h2>{`No ${searchType} yet`}</h2>
      <p>{`You will see a list of your ${searchType} here.`}</p>
    </div>
  </div>
);

SidebarEmpty.propTypes = {
  searchType: PropTypes.string.isRequired,
};


export const SidebarNoSearchQuery = ({ query, searchType, tags }) => {
  let message = `No ${searchType} found with the`;
  if (query) {
    message += ` name "${query}"`;
  }
  if (tags) {
    message += ` ${query ? 'and ' : ''}applied tag filters`;
  }
  return (
    <div className="sidebar-empty">
      <div>
        <p>{message}.</p>
      </div>
    </div>
  );
};

SidebarNoSearchQuery.propTypes = {
  query: PropTypes.string.isRequired,
  tags: PropTypes.number,
  searchType: PropTypes.string.isRequired,
};

SidebarNoSearchQuery.defaultProps = {
  tags: 0,
};
