import React from 'react';
import PropTypes from 'prop-types';
import api from 'api';
import { RequestTrackerCanceler } from 'utils/apiUtil';
import { connect } from 'react-redux';
import { sortObjects } from 'helpers/helpers';
import { selectors as fromJurisdictions } from 'reducers/jurisdictions';
import { JurisdictionOptions } from 'components/jurisdictionDropdown';
import SearchableDropdown from './searchableDropdown';

const cancelToken = 'JURISDICTION_SUGGESTION';

const getJurisdictionSuggestions = (search) => {
  RequestTrackerCanceler.cancelByRequestId(cancelToken);
  return api.get(`/jurisdictions/?name=${encodeURIComponent(search)}`, {
    cancelToken: RequestTrackerCanceler.getCancelToken(cancelToken),
  });
};


function PreJurisdictionDropdown({ jurisdictions, ...props }) {
  const parentJurisdictions = fromJurisdictions.parentJurisdictions(jurisdictions);
  const sortedParents = sortObjects(parentJurisdictions, 'name');
  return (
    <SearchableDropdown
      {...props}
      id={'jurisdiction-dropdown'}
      searchFunc={getJurisdictionSuggestions}
      allowBlank
      cancelToken={cancelToken}
      renderOptions={(options, handleSelect) => (
        sortedParents.map(parent => (
          <JurisdictionOptions
            key={parent.id}
            jurisdictions={options}
            parent={parent}
            handleSelection={jur => handleSelect(jur.name)}
          />
        ))
      )}
    />
  );
}

PreJurisdictionDropdown.propTypes = {
  jurisdictions: PropTypes.array.isRequired,
};

const mapStateToProps = ({ jurisdictions }) => ({
  jurisdictions,
});

export default connect(mapStateToProps)(PreJurisdictionDropdown);
