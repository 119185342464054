import * as types from './types';

const initialState = {
  id: null,
  username: '',
  organization: '',
  alert_emails: null,
  full_name: '',
  remaining_entities: Number.MAX_SAFE_INTEGER / 2,
  remaining_employees: Number.MAX_SAFE_INTEGER / 2,
  remaining_researches: Number.MAX_SAFE_INTEGER / 2,
  backup_tokens: [],
  two_factor_enabled: false,
  password_expires_after: 0,
  password_last_changed: '',
  show_automatches: false,
  global_date_filter: null,
  archive_delay_setting: null,
  features: {},
  permissions: {},
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CURRENT_USER:
      return {
        ...state,
        ...action.data,
      };
    case types.RESTORE_EMPLOYEE:
    case types.ADD_EMPLOYEE: {
      const nextCount = state.remaining_employees - 1;
      return {
        ...state,
        remaining_employees: nextCount,
      };
    }
    case types.ARCHIVE_EMPLOYEE:
    case types.REMOVE_EMPLOYEE: {
      const nextCount = state.remaining_employees + 1;
      return {
        ...state,
        remaining_employees: nextCount,
      };
    }
    case types.ADD_ENTITY: {
      const nextCount = state.remaining_entities - 1;
      return {
        ...state,
        remaining_entities: nextCount,
      };
    }
    case types.REMOVE_ENTITY: {
      const nextCount = state.remaining_entities + 1;
      return {
        ...state,
        remaining_entities: nextCount,
      };
    }
    case types.SET_REMAINING_RESEARCHES: {
      return {
        ...state,
        remaining_researches: action.remainingResearches,
      };
    }
    case types.EDIT_DATE_FILTER: {
      return {
        ...state,
        global_date_filter: action.data,
      };
    }
    case types.EDIT_AUTOMATCH_SETTING: {
      return {
        ...state,
        show_automatches: action.data,
      };
    }
    default:
      return state;
  }
};

export default user;
