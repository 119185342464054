import React from 'react';


const AppSwitcherIcon = () => (
  <svg width="28" height="28" viewBox="0 0 268 274" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_145_637)">
      <path d="M53.6807 2.15564H21.4022C9.51767 2.15564 -0.116638 11.7899 -0.116638 23.6745V55.953C-0.116638 67.8375 9.51767 77.4718 21.4022 77.4718H53.6807C65.5652 77.4718 75.1995 67.8375 75.1995 55.953V23.6745C75.1995 11.7899 65.5652 2.15564 53.6807 2.15564Z" fill="#044862" />
      <path d="M150.515 2.15564H118.237C106.352 2.15564 96.7179 11.7899 96.7179 23.6745V55.953C96.7179 67.8375 106.352 77.4718 118.237 77.4718H150.515C162.4 77.4718 172.034 67.8375 172.034 55.953V23.6745C172.034 11.7899 162.4 2.15564 150.515 2.15564Z" fill="#DA521F" />
      <path d="M247.35 2.15564H215.071C203.187 2.15564 193.552 11.7899 193.552 23.6745V55.953C193.552 67.8375 203.187 77.4718 215.071 77.4718H247.35C259.234 77.4718 268.869 67.8375 268.869 55.953V23.6745C268.869 11.7899 259.234 2.15564 247.35 2.15564Z" fill="#044862" />
      <path d="M53.6807 98.9905H21.4022C9.51767 98.9905 -0.116638 108.625 -0.116638 120.509V152.788C-0.116638 164.672 9.51767 174.307 21.4022 174.307H53.6807C65.5652 174.307 75.1995 164.672 75.1995 152.788V120.509C75.1995 108.625 65.5652 98.9905 53.6807 98.9905Z" fill="#044862" />
      <path d="M150.515 98.9905H118.237C106.352 98.9905 96.7179 108.625 96.7179 120.509V152.788C96.7179 164.672 106.352 174.307 118.237 174.307H150.515C162.4 174.307 172.034 164.672 172.034 152.788V120.509C172.034 108.625 162.4 98.9905 150.515 98.9905Z" fill="#044862" />
      <path d="M247.35 98.9905H215.071C203.187 98.9905 193.552 108.625 193.552 120.509V152.788C193.552 164.672 203.187 174.307 215.071 174.307H247.35C259.234 174.307 268.869 164.672 268.869 152.788V120.509C268.869 108.625 259.234 98.9905 247.35 98.9905Z" fill="#044862" />
      <path d="M53.6807 195.825H21.4022C9.51767 195.825 -0.116638 205.459 -0.116638 217.343V249.622C-0.116638 261.507 9.51767 271.141 21.4022 271.141H53.6807C65.5652 271.141 75.1995 261.507 75.1995 249.622V217.343C75.1995 205.459 65.5652 195.825 53.6807 195.825Z" fill="#044862" />
      <path d="M150.515 195.825H118.237C106.352 195.825 96.7179 205.459 96.7179 217.343V249.622C96.7179 261.507 106.352 271.141 118.237 271.141H150.515C162.4 271.141 172.034 261.507 172.034 249.622V217.343C172.034 205.459 162.4 195.825 150.515 195.825Z" fill="#044862" />
      <path d="M247.35 195.825H215.071C203.187 195.825 193.552 205.459 193.552 217.343V249.622C193.552 261.507 203.187 271.141 215.071 271.141H247.35C259.234 271.141 268.869 261.507 268.869 249.622V217.343C268.869 205.459 259.234 195.825 247.35 195.825Z" fill="#044862" />
    </g>
    <defs>
      <clipPath id="clip0_145_637">
        <rect width="268" height="273.826" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AppSwitcherIcon;
