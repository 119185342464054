import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';


const SearchButton = ({ disabled, tooltipMessage }) => (
  <Tippy
    content={tooltipMessage}
    disabled={!disabled}
  >
    <div>
      <button
        className="orange-button search-tooltip-hover"
        disabled={disabled}
        type="submit"
      >
          Search
      </button>
    </div>
  </Tippy>
);

SearchButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  tooltipMessage: PropTypes.string,
};

SearchButton.defaultProps = {
  tooltipMessage: '',
};

export default SearchButton;
