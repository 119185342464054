import React from 'react';
import PropTypes from 'prop-types';
import { CustomDropdown } from '../dropdown';

const ALL_STATUS = { label: 'All', id: null };

export function FilterOption({ label, onClick, checked }) {
  return (
    <li onClick={onClick} className={checked ? 'checked' : ''}>
      {checked && <i className="material-icons">done</i>}
      <span>{label}</span>
    </li>
  );
}

FilterOption.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

function FiltersHeader({ options, setFilter, filter }) {
  const orderedOptions = [...options];
  // The default option should be first
  orderedOptions.sort((a) => (a.default ? -1 : 1));

  const selectedStat = filter ? options.find((o) => o.id === filter.id) : ALL_STATUS;
  return (
    <div className="filter-dropdown dropdown">
      <CustomDropdown
        render={({ isOpen, openDropdown, closeDropdown }) => (
          <div>
            <div className="drop-wrapper">
              <button
                onClick={isOpen ? closeDropdown : openDropdown}
                className="dropdownButton text-button uppercase"
                type="button"
              >
                <div className="dropdown-label">
                  {filter
                    ? (
                      <span>
                        <span className="lighter-text">Status:</span>
                        {' '}
                        <span>{selectedStat.label}</span>
                      </span>
                    )
                    : <span className="lighter-text">filter by status</span>}
                </div>
                <i className="material-icons">
                  {isOpen ? 'expand_less' : 'expand_more'}
                </i>
              </button>
            </div>
            {
                isOpen
                  ? (
                    <div className="dropdown-container">
                      <div className="options-wrapper statuses">
                        {orderedOptions.map((o) => (
                          <FilterOption
                            key={o.label}
                            onClick={() => { closeDropdown(); setFilter(o); }}
                            label={o.label}
                            checked={o.id === selectedStat.id}
                          />
                        ))}
                        <FilterOption
                          key="all"
                          onClick={() => { closeDropdown(); setFilter(null); }}
                          label={ALL_STATUS.label}
                          checked={filter === null}
                        />
                      </div>
                    </div>
                  )
                  : null
              }
          </div>
        )}
      />
    </div>
  );
}

FiltersHeader.propTypes = {
  options: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.object,
};

FiltersHeader.defaultProps = {
  filter: null,
};

export default FiltersHeader;
