import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeModal } from 'reducers/ui/actions';
import {
  ARCHIVE_EMPLOYEE,
  DELETE_EMPLOYEE,
  DELETE_ENTITY,
  DELETE_EMP_SEARCH,
  DELETE_MATCH,
  PRE_MATCHING_MODAL,
  SEND_RECORD,
} from 'utils/constants';
import DeleteMatchConfirmation from 'components/shared/matching/deleteMatchConfirmation';
import matchingContainerOnMonitoring from 'components/shared/matching/matchingWrapperOnMonitoring';
import SendRecordDialog from 'components/shared/recordItem/sendRecordDialog';
import DeleteEntityConfimation from 'components/monitoringForm/entityForm/entityDeleteConfirmation';
import DeleteEmployeeConfirmation from 'components/employeePage/employeeForm/employeeDeleteConfirmation';
import ArchiveConfirmation from 'components/employeePage/employeeForm/ArchiveConfirmation';
import DeleteEmpSearchConfirmation from 'components/monitoringForm/personForm/empSearchDeleteConfirmation';
import ModalUI from './modalUI';


const modals = {
  [ARCHIVE_EMPLOYEE]: {
    component: ArchiveConfirmation,
    className: 'small center',
  },
  [DELETE_EMPLOYEE]: {
    component: DeleteEmployeeConfirmation,
    className: 'small center',
  },
  [DELETE_ENTITY]: {
    component: DeleteEntityConfimation,
    className: 'small center',
  },
  [DELETE_EMP_SEARCH]: {
    component: DeleteEmpSearchConfirmation,
    className: 'small center',
  },
  [DELETE_MATCH]: {
    component: DeleteMatchConfirmation,
    className: 'small center',
  },
  [PRE_MATCHING_MODAL]: {
    component: matchingContainerOnMonitoring,
    className: '',
  },
  [SEND_RECORD]: {
    component: SendRecordDialog,
    className: 'action-modal send-record',
  },
};


// eslint-disable-next-line no-shadow
const Modal = ({ modalType, modalProps, closeModal }) => {
  if (!modalType) {
    return null;
  }
  const modal = modals[modalType];
  const ModalContents = modal.component;

  return (
    <ModalUI
      open
      close={closeModal}
      className={modal.className}
    >
      <ModalContents
        modalProps={modalProps}
      />
    </ModalUI>
  );
};

Modal.propTypes = {
  modalType: PropTypes.string.isRequired,
  modalProps: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  modalProps: {},
};

const mapStateToProps = state => ({
  modalType: state.ui.modal.modalType,
  modalProps: state.ui.modal.modalProps,
});

export default connect(mapStateToProps, { closeModal })(Modal);
