export function pendoScript(user) {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v,
        w,
        x,
        y,
        z; o = p[d] = p[d] || {}; o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad']; for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
        }(v[w]));
      }
      y = e.createElement(n); y.async = !0; y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0]; z && z.parentNode.insertBefore(y, z);
    }(window, document, 'script', 'pendo'));

    pendo.initialize({
      visitor: {
        id: user.id,
      },

      account: {
        id: user.username,
        organization: user.organization,
      },
    });
  }('81578569-f35f-4f15-5234-7fb83f3ec94f'));
}
