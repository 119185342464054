import moment from 'moment';
import { acceptableDateFormats } from 'helpers/date_helpers';

// turn a date in (mm/dd/yyyy) into unix
export const serializeDate = (date) => {
  const unixDate = date ? moment(date, acceptableDateFormats).utc().startOf('day').unix() : null;
  return unixDate;
};

export const serializeEndDate = (date) => {
  const unixDate = date ? moment(date, acceptableDateFormats).utc().endOf('day').unix() : null;
  return unixDate;
};

// turn a unix date into (mm/dd/yyyy)
export const deserializeDate = date => moment(date, 'X').utc().format('MM/DD/YYYY');
