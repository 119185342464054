import * as Yup from 'yup';
import moment from 'moment';
import { requiredError } from 'utils/errorHelpers';

export const [ALL, LIST, TAGS] = ['ALL', 'LIST', 'TAGS'];
export const [QUARTERLY, YEARLY] = ['QUARTERLY', 'YEARLY'];
export const [SENT, SCHEDULED] = ['sent', 'scheduled'];

export const RECIPIENT_TYPES = [
  {
    value: ALL,
    label: 'All Employees',
  },
  {
    value: TAGS,
    label: 'By Tag',
  },
  {
    value: LIST,
    label: 'By Individual',
  },
];

export const frequencyPeriod = {
  QUARTERLY: 'quarter',
  YEARLY: 'year',
};

export const [RECURRING, ONCE] = ['RECURRING', 'ONCE'];

export const FREQUENCY_TYPES = [
  {
    value: ONCE,
    label: 'One Time',
  },
  {
    value: RECURRING,
    label: 'Recurring',
  },
];

export const SCHEDULE_TYPES = [
  {
    value: QUARTERLY,
    label: 'Quarterly',
    description:
      'This email will be sent on the day after each quarter ends and the certification period will be that quarter.',
  },
  {
    value: YEARLY,
    label: 'Annual',
    description:
      'This email will be sent on the day after each year ends and the certification period will be that year.',
  },
];

export const [CUSTOMIZE_TEMPLATE, USE_TEMPLATE] = ['CUSTOMIZE_TEMPLATE', 'USE_TEMPLATE'];
export const TEMPLATE_TYPES = [
  {
    value: USE_TEMPLATE,
    label: 'Use Template',
  },
  {
    value: CUSTOMIZE_TEMPLATE,
    label: 'Customize',
  },
];

export const BLANK_TEMPLATE = {
  id: null,
  subject: '',
  body: '',
};

export const MACRO_LINK_HELP_TEXT = 'This link will provide employees access to the certification form (chosen in step 1 of the create certification process) via SSO.';


// todo ideally reconcil these keys on BE but for now...
export const ATTESTED_PC_COLS = [
  {
    key: 'contributor_first_name',
    label: 'First Name',
  },
  {
    key: 'contributor_last_name',
    label: 'Last Name',
  },
  {
    key: 'date',
    label: 'Date',
    type: 'date',
  },
  {
    key: 'amount',
    label: 'Amount',
    type: 'currency',
  },
  {
    key: 'recipient',
    label: 'Recipient',
  },
  {
    key: 'office_sought',
    label: 'Office',
  },
];

export const UNATTESTED_PC_COLS = [
  {
    key: 'first_name',
    label: 'First Name',
  },
  {
    key: 'last_name',
    label: 'Last Name',
  },
  {
    key: 'date',
    label: 'Date',
    type: 'date',
  },
  {
    key: 'amount',
    label: 'Amount',
    type: 'currency',
  },
  {
    key: 'recipient',
    label: 'Recipient',
  },
  {
    key: 'office_sought',
    label: 'Office',
  },
];


export const dateFormat = 'YYYY-MM-DD';
const dateError = 'Please enter a valid date';

const validateDate = (_value, originalValue) => {
  const date = moment(originalValue, dateFormat, true);
  return date.isValid() ? date.toDate() : date.isValid();
};


export const setUpFormValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(requiredError),
  at_date: Yup.date().nullable()
    .when('frequency', {
      is: ONCE,
      then: Yup.date()
        .transform(validateDate)
        .typeError(dateError)
        .required(requiredError),
    }),
  start_date: Yup.date().nullable()
    .when('frequency', {
      is: ONCE,
      then: Yup.date()
        .transform(validateDate)
        .typeError(dateError)
        .required(requiredError),
    }),
  end_date: Yup.date().nullable()
    .when('frequency', {
      is: ONCE,
      then: Yup.date()
        .transform(validateDate)
        .typeError(dateError)
        .required(requiredError),
    }),
  form: Yup.string().nullable()
    .required('Please select a form.'),
});


export const recipientFormValidation = Yup.object().shape({
  tag_list: Yup.array()
    .when('employee_selector', {
      is: TAGS,
      then: Yup.array()
        .min(1, 'Please select at least one tag.'),
    }),
  employee_list: Yup.array()
    .when('employee_selector', {
      is: LIST,
      then: Yup.array()
        .min(1, 'Please select at least one employee.'),
    }),
});

export const emailFormValidation = Yup.object().shape({
  email_subject: Yup.string()
    .when('email_template', {
      is: null,
      then: Yup.string()
        .trim()
        .required(requiredError),
    }),
  email_body: Yup.string()
    .when('email_template', {
      is: null,
      then: Yup.string()
        .trim()
        .required(requiredError),
    }),
});

