import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import moment from 'moment';

import { PATHS, PATH_NAMES } from 'utils/constants';
import useCancellablePromise from 'hooks/useCancellablePromise';
import { deleteEmployee, setFieldData } from 'reducers/employees/actions';
import IconTextButton from '../monitoringForm/iconTextButton';


const PendingDeleteBanner = ({
  pendingArchivalDate,
  pendingDeleteDate,
  id,
  deleteFunc,
  updateEmployeeField,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { cancellablePromise } = useCancellablePromise();

  const cancelDelete = () => {
    setSubmitting(true);
    cancellablePromise(updateEmployeeField(id, { delay_delete: false })).then(() => {
      toast('Delete canceled.');
      setSubmitting(false);
    }).catch((err) => {
      if (!err.isCanceled) {
        toast('Something went wrong.');
        setSubmitting(false);
        throw err;
      }
    });
  };

  const handleDelete = () => {
    setSubmitting(true);
    cancellablePromise(deleteFunc(id)).then(() => {
      toast('Deleted.');
      setSubmitting(false);
    }).catch((err) => {
      if (!err.isCanceled) {
        toast('There was a problem deleting this employee.');
        setSubmitting(false);
        throw err;
      }
    });
  };

  return (
    <div className="archived-banner" data-testid="delete-banner">
      <div className="flex-header">
        <i className="material-icons">
          schedule
        </i>
        <span>
          This person and all related spouses and dependents are
          {' '}
          <Tippy
            interactive
            content={(
              <div>
                The delete delay setting can be changed in the delete actions tab on the
                {' '}
                <Link
                  to={`${PATHS[PATH_NAMES.platform_settings]}`}
                  className="orange-link"
                >
                  platform settings page
                </Link>
                .
              </div>
              )}
          >
            <span className="tooltip-target">
              scheduled to be deleted
            </span>
          </Tippy>
          {' '}
          on
          {' '}
          { moment(pendingDeleteDate).format('ll') }
        </span>
      </div>
      <div className="flex-header">
        <IconTextButton
          buttonHandler={cancelDelete}
          icon="replay"
          buttonText="cancel delete"
          disabled={isSubmitting}
          className="text-button"
        />
        <IconTextButton
          buttonHandler={handleDelete}
          icon="inventory"
          buttonText="delete now"
          disabled={isSubmitting}
          className="text-button"
        />
      </div>
    </div>
  );
};


PendingDeleteBanner.propTypes = {
  pendingArchivalDate: PropTypes.string,
  pendingDeleteDate: PropTypes.string,
  id: PropTypes.number.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  updateEmployeeField: PropTypes.func.isRequired,
};

PendingDeleteBanner.defaultProps = {
  pendingArchivalDate: '',
  pendingDeleteDate: '',
};

export default connect(null, {
  deleteFunc: deleteEmployee,
  updateEmployeeField: setFieldData,
})(PendingDeleteBanner);
