import { combineReducers } from 'redux';
import currentSearch from './searchObjectReducer';
import ui from './uiReducer';
import results from './resultsReducer';
import sourceResults from './sourceResultsReducer';

export default combineReducers({
  currentSearch,
  ui,
  results, // the sourceResultIds of the current search
  sourceResults,
});
