import moment from 'moment';
import { exportChartWithLegend } from 'utils/exportChart';
import SimpleBarChart from './barChart';
import DonutChart from './donutChart';
import ListChart from './list';


export const [JURISDICTION, STATUS, MONTH, CONTRIBUTOR, RECIPIENT] = ['jurisdiction', 'status', 'month', 'contributor', 'recipient'];

export const [BAR, DONUT, LIST] = [SimpleBarChart, DonutChart, ListChart];

export const COLORS = ['#11174B', '#162065', '#1C2B7F', '#24448E', '#2D5E9E', '#3577AE', '#3D91BE', '#46AACE', '#62BED2', '#8ACDCE', '#B3DDCC', '#DCECC9', '#FFD1CF', '#FF8E88', '#FF6159'];

export const LABEL_STYLES = {
  fontFamily: 'Poppins, sans-serif',
  fontSize: '12px',
  fill: '#676767',
};

export const CHART_HEIGHT = 300;
export const CHART_WIDTH = 710;


const ONE_YEAR_AGO = moment().subtract(1, 'year');
const YTD = moment().startOf('year');

export const [YEAR_TO_DATE, PAST_YEAR] = ['Year to date', 'Past year'];

export const DATE_OPTIONS_MAP = {
  [YEAR_TO_DATE]: { from: YTD.format('YYYY-MM-DD'), to: null },
  [PAST_YEAR]: { from: ONE_YEAR_AGO.format('YYYY-MM-DD'), to: null },
};

export function getDefaultDateOption(globalDateFilter) {
  const formattedGDF = globalDateFilter ? moment(globalDateFilter).format('MM/DD/YYYY') : null;
  return formattedGDF ? `After ${formattedGDF}` : 'Any date';
}

const [ASC, DESC] = ['asc', 'desc'];


export const CHART_MAP = {
  MONTH: {
    title: 'Contributions by month',
    nameKey: MONTH,
    limit: null,
    order: ASC,
    sortByDim: true,
    type: BAR,
    allowExport: true,
    defaultDate: PAST_YEAR,
  },
  STATUS: {
    title: 'Contributions by status',
    nameKey: STATUS,
    limit: null,
    type: DONUT,
    allowExport: true,
    exportFunc: exportChartWithLegend,
  },
  // not implementing right now b/c we need to refactor first
  // JURISDICTION: {
  //   title: 'Top jurisdictions',
  //   nameKey: JURISDICTION,
  //   limit: 15,
  //   type: BAR,
  //   allowExport: true,
  // },
  RECIPIENT: {
    title: 'Top recipients',
    nameKey: RECIPIENT,
    limit: 10,
    type: LIST,
    allowExport: false,
  },
  CONTRIBUTOR: {
    title: 'Top contributors',
    nameKey: CONTRIBUTOR,
    limit: 10,
    type: LIST,
    allowExport: false,
  },
};
