import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JurisdictionTabBody from '../jurisdictions/jurisdictionBody';
import SingleSearchBody from './singleSearchContainer';
import BodyWithSidebar from '../shared/BodyWithSidebar';

const [ONE_OFF, JURISDICTIONS] = ['ONE_OFF', 'JURISDICTIONS'];

const RESEARCH_TAB_MAP = {
  [ONE_OFF]: 'Search by Contributor',
  [JURISDICTIONS]: 'View by Jurisdiction',
};

const bodyMap = {
  [ONE_OFF]: SingleSearchBody,
  [JURISDICTIONS]: JurisdictionTabBody,
};

class Research extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.researchTabs = [ONE_OFF];
    this.props.showJurisdictions && this.researchTabs.push(JURISDICTIONS);
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  render() {
    return (
      <BodyWithSidebar
        title={'Research'}
        tabs={this.researchTabs}
        tabMap={RESEARCH_TAB_MAP}
        bodyMap={bodyMap}
      />
    );
  }
}
Research.propTypes = {
  resetState: PropTypes.func.isRequired,
};

export default Research;
