import React from 'react';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import { ACTION_CONFIRMATION } from '../table/constants';
import {
  to,
  ccEmails,
  subject,
  body,
  validationSchema,
} from './consts';


const CustomizeEmailModal = ({
  openModal, onSubmit, initFormData,
}) => (
  <div>
    <h2>Customize Email</h2>
    <div className="message">
      <Formik
        initialValues={({ ...initFormData })}
        onSubmit={onSubmit}
        validateOnChange={false}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off" noValidate>
            <div className="search-form simple-form">
              <SchemaField
                name={to}
                id={to}
                label="to"
                fieldType="TextField"
                className="form-input"
                required
              />
              <SchemaField
                name={ccEmails}
                id={ccEmails}
                label="cc"
                fieldType="ArrayField"
                addButtonText="cc email"
                className="form-input"
                limit={10}
                minFields={1}
              />
              <SchemaField
                name={subject}
                id={subject}
                label="email subject"
                fieldType="TextField"
                className="form-input"
              />
              <SchemaField
                name={body}
                id={body}
                label="email body"
                fieldType="TextField"
                className="form-input"
                element="textarea"
              />
              <div className="modal-button-wrapper">
                <button
                  onClick={() => { openModal({ modalType: ACTION_CONFIRMATION }); }}
                  type="button"
                  className="uppercase text-button"
                  disabled={isSubmitting}
                >
                  cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="orange-button"
                >
                  send
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
);

export default CustomizeEmailModal;

CustomizeEmailModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initFormData: PropTypes.object.isRequired,
};
