import React from 'react';
import PropTypes from 'prop-types';

const Sidebar = ({ title, tabs, tabMap, selectedTab, selectTab }) => (
  <div className="sidebar-nav">
    <h2>{title}</h2>
    { tabs.map((tab, i) => (
      <button
        key={i}
        className={`${selectedTab === tab ? 'selected ' : ''}nav-link`}
        onClick={() => { selectTab(tab); }}
      >
        {tabMap[tab]}
      </button>
    ),
    )}
  </div>
);

Sidebar.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.array,
  tabMap: PropTypes.object.isRequired,
  selectedTab: PropTypes.string,
  selectTab: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  title: '',
  tabs: [],
  selectedTab: '',
};

export default Sidebar;
