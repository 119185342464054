import { ADD_SEARCH, EDIT_SEARCH } from 'reducers/searchObjects/types';
import * as types from './types';

const updateSearchObjs = (state, action, searchMeta) => {
  const searches = [...state.search_objects];
  const index = searches.findIndex(search => search.search_object_id == action.search.id);
  if (index >= 0) {
    searches[index] = {
      ...searches[index],
      ...searchMeta,
    };
  }
  return searches;
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case types.RECEIVE_EMPLOYEES: {
      const nextState = { ...state };
      action.employees.forEach((employee) => {
        const { id } = employee;
        nextState[id] = {
          ...nextState[id],
          ...employee,
        };
      });
      return nextState;
    }
    case types.UPDATE_TAGS: {
      const employee = state[action.employeeId];
      return {
        ...state,
        [action.employeeId]: {
          ...employee,
          tags: [...action.tags],
        },
      };
    }
    case types.RESTORE_EMPLOYEE:
    case types.ARCHIVE_EMPLOYEE:
    case types.EDIT_EMPLOYEE:
    case types.ADD_EMPLOYEE: {
      return {
        ...state,
        [action.employee.id]: action.employee,
      };
    }
    case types.REMOVE_EMPLOYEE: {
      const nextState = { ...state };
      delete nextState[action.id];
      return nextState;
    }
    case ADD_SEARCH: {
      //  a new search created through the UI can only be attached to a single employee at this time
      const searchMeta = action.search.persons[0];
      const employeeId = searchMeta.employee_id;
      return {
        ...state,
        [employeeId]: {
          ...state[employeeId],
          search_objects: [
            ...state[employeeId].search_objects,
            {
              search_object_id: action.search.id,
              ...searchMeta,
            },
          ],
        },
      };
    }
    case EDIT_SEARCH: {
      const people = action.search.persons;
      return people.reduce((acc, person) => {
        const employeeId = person.employee_id;
        const empState = state[employeeId];
        acc[employeeId] = {
          ...empState,
          search_objects: updateSearchObjs(empState, action, person),
        };
        return acc;
      }, Object.assign({}, state));
    }
    case types.REMOVE_EMPLOYEE_SEARCH: {
      const { employeeId, searchId } = action;
      const employee = state[employeeId] || {};
      const updatedSearches = (employee.search_objects || []).filter(search =>
        search.search_object_id != searchId);
      return {
        ...state,
        [employeeId]: {
          ...employee,
          search_objects: [...updatedSearches],
        },
      };
    }
    default: return state;
  }
};

export default byId;

export const getEmployee = (state, id) => state[id];
