import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PATH_NAMES, PATHS, PEOPLE } from 'utils/constants';
import Loading from 'components/loading';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import EmptyState from 'components/shared/emptyState';
import { ErrorComponent } from 'components/errorBoundary';
import { DateFilterDropdown } from './chartDateDropdown';


export function ChartItem({
  title, nameKey, chartType, loading, allowExport, exportFunc,
  chartRef, dateFilter, globalDateFilter, dateFilterSet, dateOptions,
  disabledDateOptions, selectDate, customDate, data, error,
}) {
  const ChartComponent = chartType;
  const emptyStateMessage = !dateFilterSet ?
    (<div>Please visit the <Link to={`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/new`}>monitoring page</Link> to add a search.</div>)
    : 'No data matching filter.';
  return (
    <div className="dashboard-card">
      <div className="chart-heading">
        <div className="chart-title">{title}</div>
        <div className="chart-buttons">
          <DateFilterDropdown
            disabled={loading}
            dateFilter={dateFilter}
            dropdownText={dateFilterSet ? dateFilter : 'Date filter'}
            selectDate={selectDate}
            options={dateOptions}
            disabledDateOptions={disabledDateOptions}
            customDate={customDate}
            globalDateFilter={globalDateFilter}
          />
          { allowExport &&
            <IconTextButton
              icon={'open_in_new'}
              buttonText={'Export'}
              buttonHandler={exportFunc}
              disabled={loading || !data.length}
            />
          }
        </div>
      </div>
      {loading ?
        <div className="load-wrapper">
          <Loading />
        </div> :
        <div>
          { error ?
            <ErrorComponent
              className={'noResults'}
              apologyText={'Something went wrong.'}
              errorBody={error}
            /> :
            <div>
              { !data.length ?
                <EmptyState
                  messageHeading={'No data.'}
                  message={emptyStateMessage}
                /> :
                <ChartComponent
                  nameKey={nameKey}
                  data={data}
                  chartRef={chartRef}
                />}
            </div>
          }
        </div>
      }
    </div>
  );
}

ChartItem.propTypes = {
  title: PropTypes.string,
  nameKey: PropTypes.string.isRequired,
  chartType: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  allowExport: PropTypes.bool.isRequired,
  dateFilter: PropTypes.string.isRequired,
  globalDateFilter: PropTypes.string,
  dateFilterSet: PropTypes.bool.isRequired,
  dateOptions: PropTypes.array.isRequired,
  disabledDateOptions: PropTypes.array,
  selectDate: PropTypes.func.isRequired,
  customDate: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  chartRef: PropTypes.func,
  exportFunc: PropTypes.func,
  error: PropTypes.string,
};

ChartItem.defaultProps = {
  title: '',
  limit: null,
  order: null,
  sortByDim: false,
  chartRef: () => {},
  exportFunc: () => {},
  error: '',
  globalDateFilter: null,
  disabledDateOptions: [],
};
