export const byIds = (state, ids) => ids.map(id => state[id]).filter(Boolean);

export const withErrors = (state, ids) => (
  byIds(state, ids).filter(sourceResult => (sourceResult.has_errors))
);

// export const withoutErrors = (state, ids) => (
//   byIds(state, ids).filter(sourceResult => (
//     sourceResult.completed && (!sourceResult.has_errors && sourceResult.data.length))
//   )
// );

export const withData = (state, ids) => (
  byIds(state, ids).filter(sourceResult => (
    sourceResult.completed && sourceResult.data.length),
  )
);

export const completed = (state, ids) => (
  byIds(state, ids).filter(sourceResult => sourceResult.completed)
);

export const contributionIdsBySources = (state, sourceResultIds) => (
  sourceResultIds.reduce((allContribIds, sourceResultId) => (
    allContribIds.concat(state[sourceResultId].data)
  ), [])
);

export const bySourceId = (state, sourceId, searchId) => (
  Object.values(state).find(sourceResult => (
    sourceResult.source === sourceId && sourceResult.searchId === searchId
  ))
);

export const bySourceIds = (state, sourceIds, searchId) => {
  const sourcesArr = Object.values(state);
  return sourceIds.map((sourceId) => (
    sourcesArr.find(sourceResult => sourceResult.source === sourceId && (searchId ? sourceResult.searchId === searchId : true)))
  ).filter(Boolean);
};

