import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, useParams, useLocation, useHistory } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import moment from 'moment';
import api from 'api';
import { toast } from 'react-toastify';
import { PATHS, PATH_NAMES } from 'utils/constants';
import ErrorBoundary from 'components/errorBoundary';
import { ScrollToTopOnMount } from 'components/shared/scrollToTop';
import { fetchPreStatuses } from 'reducers/preclearance/statuses/actions';
import Loading from 'components/loading';
import SingleCertificationHeader from './SingleCertificationHeader';
import RecipientTable from './RecipientTable';
import Submission from './Submission';
import { SENT } from '../constants';
import ResendCertification from './ResendCertification/ResendCertification';
import BulkResendCertification from './ResendCertification/BulkResendCertification';
import { getStatusText } from './CertificationTemplate';


const getCertification = id => (
  api.get(`/attestations/certifications/${id}`)
);


function SingleCertification({ getStatuses, statuses }) {
  const [certification, setCertification] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let didCancel = false;
    Promise.all([
      getCertification(id),
      getStatuses(),
    ]).then(([res]) => {
      if (!didCancel) {
        setCertification(res.data);
      }
    }).catch((err) => {
      if (!didCancel) {
        if (err && err.response && err.response.status === 404) {
          toast('Certification not found.');
          history.push(`${PATHS[PATH_NAMES.certifications]}`);
          return;
        }
        toast('Something went wrong and we were unable to get the details for this certification.');
        throw (err);
      }
    }).finally(() => {
      if (!didCancel) {
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, []);

  const {
    name,
    sent_at: sentDate,
    start_date: startDate,
    end_date: endDate,
    recipients,
    fields,
    header,
    footer,
    status_filter: statusFilter,
  } = certification;
  const certificationPeriod = `${moment(startDate).format('ll')} - ${moment(endDate).format('ll')}`;

  return (
    <ErrorBoundary>
      <ScrollToTopOnMount />
      {
        loading ?
          <div className="flex-load-wrapper">
            <Loading />
          </div>
          :
          <div className="certifications-container">
            <SingleCertificationHeader
              name={name}
              status={SENT}
              date={sentDate ? moment(sentDate).format('ll') : '--'}
              certificationPeriod={certificationPeriod}
              statusFilter={getStatusText(statusFilter, statuses)}
              actions={(
                <React.Fragment>
                  <ResendCertification
                    renderForm={props => (
                      <BulkResendCertification
                        {...props}
                        email={{
                          body: certification.email_body,
                          subject: certification.email_subject,
                        }}
                      />)
                    }
                  />
                  <a
                    className={'icon-text-button uppercase'}
                    href={`/api/attestations/certifications/${id}/export`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="material-icons">
                      open_in_new
                    </i>
                    Export
                  </a>
                </React.Fragment>
              )}
            />
            <RecipientTable
              recipients={recipients || []}
            />
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames="slide"
                timeout={300}
              >
                <Switch location={location}>
                  <Route path={`${PATHS[PATH_NAMES.certifications]}/:id/submission/:submissionId`}>
                    <Submission
                      startDate={startDate}
                      endDate={endDate}
                      fields={fields}
                      header={header}
                      footer={footer}
                      statuses={certification.status_filter}
                    />
                  </Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          </div>
      }
    </ErrorBoundary>
  );
}

SingleCertification.propTypes = {
  getStatuses: PropTypes.func.isRequired,
  statuses: PropTypes.object.isRequired,
};

const mapStateToProps = ({ preclearance: { statuses } }) => ({
  statuses,
});

export default connect(
  mapStateToProps,
  {
    getStatuses: fetchPreStatuses,
  })(SingleCertification);
