import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import api from 'api';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { fetchMacros } from 'reducers/certifications/macros/actions';
import useCancellablePromise from 'hooks/useCancellablePromise';
import Loading from 'components/loading';
import { MACRO_LINK_HELP_TEXT } from 'components/Certifications/constants';
import { TextField, TemplateArea } from '../../CertificationForm/forms/components';
import { validationSchema } from './ResendCertificationEmail';


const sendEmail = (id, email) => api.post(`attestations/certifications/${id}/resend_mail`, email);


const BulkResendCertification = ({
  id,
  closeForm,
  email,
  macros,
  getMacros,
}) => {
  const [loading, setLoading] = useState(true);
  const { cancellablePromise } = useCancellablePromise();

  useEffect(() => {
    let didCancel = false;
    getMacros().catch((err) => {
      throw err;
    }).finally(() => {
      if (!didCancel) {
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, []);

  const onSubmit = (values, actions) => {
    cancellablePromise(sendEmail(id, values)).then(() => {
      toast('Certification resent!');
      actions.setSubmitting(false);
      closeForm();
    }).catch((err) => {
      if (!err.isCanceled) {
        toast('Something went wrong and we were unable to resend this certification.');
        actions.setSubmitting(false);
      }
    });
  };

  return (
    <div className="resend-certification">
      {
        loading ?
          <Loading />
          :
          <div>
            <h2>Resend Certification Email</h2>
            <div className="message">
              <div className="help-text">
                This email will be sent to all employees who have not submitted the certification form yet.
              </div>
              <Formik
                initialValues={Object.assign({}, email)}
                onSubmit={onSubmit}
                validateOnChange={false}
                validationSchema={validationSchema}
              >
                {
                  ({ isSubmitting }) => (
                    <Form
                      autoComplete="off"
                      noValidate
                      className="create-cert-form"
                    >
                      <TextField
                        name={'subject'}
                        label={'Subject'}
                        required
                      />
                      <TemplateArea
                        name={'body'}
                        label={'Body'}
                        variables={macros.variables}
                        links={macros.links}
                        macroLinkHelpText={MACRO_LINK_HELP_TEXT}
                      />
                      <div className="button-wrapper">
                        <button
                          type="button"
                          className="uppercase text-button"
                          onClick={closeForm}
                        >
                          Cancel
                        </button>
                        <button
                          className="orange-button"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Send
                        </button>
                      </div>
                    </Form>
                  )
                }
              </Formik>
            </div>
          </div>
      }
    </div>
  );
};


const mapStateToProps = ({ certifications: { macros } }) => ({
  macros,
});

BulkResendCertification.propTypes = {
  id: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  email: PropTypes.object.isRequired,
  macros: PropTypes.object.isRequired,
  getMacros: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    getMacros: fetchMacros,
  },
)(BulkResendCertification);
