import * as types from '../types';

const initialState = {};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case types.RECEIVE_INITIAL_SETTINGS_DATA: {
      return action.notifications;
    }
    case types.LOAD_PRE_NOTIFICATIONS: {
      const notificationsState = {};
      action.data.reduce((_allNotifications, notification) => {
        notificationsState[notification.id] = notification;
        return notificationsState;
      }, {});
      return notificationsState;
    }
    case types.ADD_PRE_NOTIFICATION: {
      const notificationCopy = { ...state };
      notificationCopy[action.data.id] = action.data;
      return notificationCopy;
    }
    case types.UPDATE_PRE_NOTIFICATION: {
      const notificationCopy = { ...state };
      notificationCopy[action.data.id] = action.data;
      return notificationCopy;
    }
    case types.DELETE_PRE_NOTIFICATION: {
      const notificationCopy = { ...state };
      delete notificationCopy[action.id];
      return notificationCopy;
    }
    default: {
      return state;
    }
  }
};

export default notifications;
