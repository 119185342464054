import api from '../../api';
import * as types from './types';


const loadCurrentUser = data => ({
  data,
  type: types.LOAD_CURRENT_USER,
});

const editDateFilter = data => ({
  data,
  type: types.EDIT_DATE_FILTER,
});

const editAutomatchSetting = data => ({
  data,
  type: types.EDIT_AUTOMATCH_SETTING,
});

export const setRemainingResearches = remainingResearches => ({
  type: types.SET_REMAINING_RESEARCHES,
  remainingResearches,
});

export const fetchCurrentUser = () => dispatch =>
  api.get('/user/')
    .then(({ data }) => {
      dispatch(loadCurrentUser(data));
      return data;
    });

export const updateDateFilter = dateFilter => dispatch =>
  api.patch('/global_date_filter/', dateFilter.global_date_filter)
    .then(({ data }) => {
      dispatch(editDateFilter(data));
      return data;
    });

export const updateAutomatchSetting = showAutomatches => dispatch =>
  api.patch('/show_automatches/', showAutomatches)
    .then(({ data }) => {
      dispatch(editAutomatchSetting(data));
      return data;
    });

export const updateUser = userData => dispatch =>
  api.patch('/user/', userData)
    .then(({ data }) => dispatch(loadCurrentUser(data)));

// data should contain token and key
export const submitCode = data => dispatch =>
  api.post('/two_factor_auth/submit_token/', data)
    .then(() => dispatch(loadCurrentUser({ two_factor_enabled: true })));

export const generateTokens = () => dispatch =>
  api.post('/two_factor_auth/generate_backup_tokens/')
    .then(({ data }) => dispatch(loadCurrentUser({ backup_tokens: data })));

export const disableTwoFactor = () => dispatch =>
  api.post('/two_factor_auth/disable/')
    .then(() => dispatch(loadCurrentUser({ backup_tokens: [], two_factor_enabled: false })));


// the data returned here DOES NOT need to go in redux,
// so these are not thunk action creators (hence the different method signature)

export const fetchCode = () =>
  api.get('/two_factor_auth/qr_code/');


export const changePassword = passwordData => () =>
  api.patch('/change_password/', passwordData);
