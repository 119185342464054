import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AutoSizer, InfiniteLoader, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { PATH_NAMES, PATHS, PEOPLE, MATCHING_RECORD_LIMIT, SCROLLABLE_GRID_CLASS } from 'utils/constants';
import { SELECTION_COL, PRE_REQUEST } from 'components/shared/matching/consts';
import Loading from 'components/loading';
import LoadingMore from 'components/shared/loadingMore';
import EmptyState from 'components/shared/emptyState';
import Cell from 'components/shared/matching/matchingTables/cell';
import RadioButton from 'components/shared/radioButton';

class RecordMatchingTable extends Component {
  constructor(props) {
    super(props);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.noRowsRenderer = this.noRowsRenderer.bind(this);
    this.onPageResize = this.onPageResize.bind(this);
    this.cache = new CellMeasurerCache({
      defaultHeight: window.innerHeight, // fix for scrollbar throwing off row height
      fixedWidth: true,
      keyMapper: rowIndex => (this.props.rows[rowIndex].id),
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.onPageResize);
  }

  componentDidUpdate(prevProps) {
    // this is so there is no gap after the "loading more..." animation
    if (this.props.rows.length !== prevProps.rows.length) {
      const remainder = this.props.rows.length % MATCHING_RECORD_LIMIT;
      const index = this.props.rows.length - (remainder || MATCHING_RECORD_LIMIT);
      if (index > 0) {
        for (let i = prevProps.rows.length - 1; i < this.props.rows.length; i++) {
          this.cache.clear(i);
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onPageResize);
  }

  onPageResize() {
    setTimeout((_) => {
      if (this.cache) {
        this.cache.clearAll();
        this.list.recomputeRowHeights();
      }
    }, 0);
  }


  rowRenderer({ key, index, style, parent }) {
    const { rows, loading, selectedRow, handleRowSelect, matches } = this.props;
    const record = rows[index];
    const atEnd = index === rows.length - 1;
    const disabled = matches.some(match => match.id === record.id);
    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style} className="dashboard-record-wrapper">
          <div className="dashboard-record">
            {this.props.columns.map((col) => {
              const value = col.id ? record[col.id] : record[col.name];
              return (
                <div key={`row${index}-${col.id || col.name}`} className={`dashboard-cell ${col.id || col.name}`}>
                  {col.id === SELECTION_COL.id ?
                    <span>
                      <RadioButton
                        id={record.id}
                        value={record.id}
                        checked={Boolean(selectedRow && selectedRow === record.id)}
                        onChange={() => { handleRowSelect(record); }}
                        disabled={disabled}
                        disabledText={'This record has already been matched to this request.'}
                      />
                    </span>
                    :
                    <Cell
                      value={value !== null ? value : 'N/A'}
                      col={col.id || col.name}
                      matchType={PRE_REQUEST}
                      noMatch={record.no_match}
                    />}
                </div>
              );
            },
            ) }
          </div>
          {(atEnd && loading) ? (
            <LoadingMore />) : ''}
        </div>
      </CellMeasurer>
    );
  }

  noRowsRenderer() {
    if (this.props.loading) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }
    return (
      <EmptyState
        icon={'search'}
        messageHeading={'No records found.'}
        message={<div>Try adjusting the filters or go to the <Link to={`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/new`}>monitoring page</Link> to add this person.</div>}
      />
    );
  }


  render() {
    const { rows, endCount, schema, fetchMoreRecords } = this.props;
    const height = 300;
    const rowCount = rows.length;
    return (
      <div className="source-table record-matching-container">
        <div className="dashboard-record schema">
          {schema}
        </div>
        <AutoSizer disableHeight>
          {
            ({ width }) => (
              <div style={{ width }}>
                <InfiniteLoader
                  isRowLoaded={({ index }) => !!rows[index]}
                  loadMoreRows={fetchMoreRecords}
                  rowCount={endCount}
                >
                  {
                    ({ onRowsRendered, registerChild }) => (
                      <List
                        ref={(el) => {
                          registerChild(el);
                          this.list = el;
                        }}
                        height={height}
                        width={width}
                        deferredMeasurementCache={this.cache}
                        rowHeight={({ index }) => this.cache.rowHeight({ index })}
                        rowCount={rowCount}
                        rowRenderer={this.rowRenderer}
                        onRowsRendered={onRowsRendered}
                        noRowsRenderer={this.noRowsRenderer}
                        className={SCROLLABLE_GRID_CLASS}
                      />
                    )
                  }
                </InfiniteLoader>
              </div>
            )
          }
        </AutoSizer>
      </div>
    );
  }
}

RecordMatchingTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  fetchMoreRecords: PropTypes.func.isRequired,
  selectedRow: PropTypes.number,
  handleRowSelect: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired,
  endCount: PropTypes.number.isRequired,
  matches: PropTypes.array,
};

RecordMatchingTable.defaultProps = {
  loading: false,
  selectedRow: null,
  matches: [],
  fetchMoreRecords: () => {},
  handleRowSelect: () => {},
  endCount: 1,
};

export default RecordMatchingTable;
