import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { closeModal, openModal } from '../../../reducers/preclearance/ui/actions';
import EmailActionModal from './emailActionModal';
import { REQUEST_MORE_INFORMATION, CUSTOMIZE_EMAIL_MODAL, ACTION_CONFIRMATION } from '../table/constants';

class ActionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentActionIdx: 0,
    };
    this.closeCurrentModal = this.closeCurrentModal.bind(this);
  }

  closeCurrentModal() {
    const { currentActionIdx } = this.state;
    const nextActionIdx = currentActionIdx + 1;
    if (nextActionIdx < this.props.actionsToBeTaken.length) {
      const { actionsToBeTaken, templates } = this.props;
      const nextTemplateId = actionsToBeTaken[nextActionIdx].template;
      const modalType = templates[nextTemplateId].name === REQUEST_MORE_INFORMATION ? CUSTOMIZE_EMAIL_MODAL : ACTION_CONFIRMATION;
      this.props.openModal({ modalType });
      this.setState({ currentActionIdx: nextActionIdx });
    } else {
      this.props.closeModal();
    }
  }

  render() {
    const { actionsToBeTaken } = this.props;
    const { currentActionIdx } = this.state;
    if (!actionsToBeTaken.length) { return null; }
    return (
      <div className="action-modal settings">
        <EmailActionModal
          closeModal={this.closeCurrentModal}
          openModal={this.props.openModal}
          action={actionsToBeTaken[currentActionIdx]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const row = state.preclearance.ui.selectedRow;
  const autoActions = state.preclearance.autoActions;
  const statusId = row.data.status;
  const actionsToBeTaken = Object.values(autoActions).filter(action => action.status === statusId && action.active);
  return {
    actionsToBeTaken,
    templates: state.preclearance.templates,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  openModal,
})(ActionModal);

ActionModal.propTypes = {
  actionsToBeTaken: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  templates: PropTypes.object.isRequired,
};
