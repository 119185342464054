import React from 'react';
import { ToastContainer } from 'react-toastify';
// import { Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';
// documentation can be found here: https://github.com/fkhadra/react-toastify
import PropTypes from 'prop-types';


const CloseButton = ({ closeToast }) => (
  <button onClick={closeToast} className="text-button modal-close">
    <i
      className="material-icons"
    >
    close
    </i>
  </button>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func,
};

CloseButton.defaultProps = {
  closeToast: () => {},
};

const Confirmation = children => (
  <div>
    <ToastContainer
      autoClose={3000}
      hideProgressBar
      closeOnClick={false}
      position="bottom-left"
      closeButton={<CloseButton />}
    >
      { children }
    </ToastContainer>
  </div>
);

export default Confirmation;
