import { SortDirection } from 'react-virtualized';

export const [AMOUNT, DATE, MATCHES] = ['amount', 'date', 'matches'];

export const { ASC, DESC } = SortDirection;

export const SORT_MAP = {
  [ASC]: '',
  [DESC]: '-',
};


export const TABLE_HEIGHT = 300;
export const HEADER_HEIGHT = 33;
export const SELECTION_COL_WIDTH = 56;

export const SELECTION_COL = {
  id: 'selection_col',
  label: '',
};

export const ICON_SORT_MAP = {
  [ASC]: 'arrow_upward',
  [DESC]: 'arrow_downward',
};


export const PRE_ROW_SCHEMA = [
  { id: 'date', label: 'Date' },
  { id: 'amount', label: 'Amount' },
  { id: 'recipient', label: 'Recipient' },
  { id: 'office_sought', label: 'Office Sought' },
  { id: 'jurisdiction', label: 'Jurisdiction' },
];

export const RECORD_ROW_SCHEMA = [
  { id: 'address', label: 'Address' },
  { id: 'date', label: 'Date' },
  { id: 'amount', label: 'Amount' },
  { id: 'employer', label: 'Employer' },
  { id: 'recipient', label: 'Recipient' },
  { id: 'jurisdiction', label: 'Jurisdiction' },
];

// match types
export const MONITORED_RECORD = 'Monitored record';
export const PRE_REQUEST = 'Preclearance request';

export const [MONITORING, PRECLEARANCE] = ['monitoring', 'preclearance'];