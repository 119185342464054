import { connect } from 'react-redux';
import SingleSearchBody from './singleSearchBody';

const mapStateToProps = state => ({
  currentSearch: state.research.currentSearch,
  loading: state.research.ui.loading,
  errors: state.research.ui.errors,
});

export default connect(mapStateToProps)(SingleSearchBody);
