import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router';
import moment from 'moment';
import { toast } from 'react-toastify';

import api from 'api';
import { PATHS, PATH_NAMES } from 'utils/constants';
import Loading from 'components/loading';
import CertificationTitle from 'components/shared/SchemaForm/FormTitle';
import SubmissionBody from './SubmissionBody';
import { ATTESTED_PC_COLS } from '../constants';


const CertificationSurveySubmission = ({
  fields,
  startDate,
  endDate,
  header,
  footer,
  submissionId,
  organization,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [submission, setSubmission] = useState({});
  const { surveyId } = useParams();
  const history = useHistory();

  useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      api.get(`/public/attestations/certifications/${surveyId}/attestations/${submissionId}`).then(({ data }) => {
        setSubmission(data);
      }).catch((err) => {
        if (err.response) {
          const { status } = err.response;
          if (status === 403) {
            history.push(`${PATHS[PATH_NAMES.not_authorized]}`);
          } else if (status === 404) {
            history.push(`${PATHS[PATH_NAMES.not_found]}`);
          }
        } else {
          toast('Something went wrong.');
        }
        throw (err);
      }).finally(() => {
        if (!didCancel) {
          setIsLoading(false);
        }
      });
    }
    return () => {
      didCancel = true;
    };
  }, [surveyId, submissionId]);

  const { responses, preclearance_requests: preclearanceRequests } = submission;

  return (
    isLoading ?
      <div className="load-screen">
        <Loading />
      </div>
      :
      <div>
        <div className="survey-form-body">
          <div className="survey-panel">
            <CertificationTitle
              organization={organization}
            />
            <div className="form-submitted-header">
              You submitted this certification form on {moment(submission.submitted_at).format('ll')}
            </div>
            <SubmissionBody
              header={header}
              footer={footer}
              fields={fields}
              startDate={startDate}
              endDate={endDate}
              pcColumns={ATTESTED_PC_COLS}
              preclearanceRequests={preclearanceRequests}
              responses={responses}
            />
          </div>
        </div>
      </div>
  );
};

CertificationSurveySubmission.propTypes = {
  fields: PropTypes.array.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  header: PropTypes.string,
  footer: PropTypes.string,
  submissionId: PropTypes.number.isRequired,
  organization: PropTypes.string.isRequired,
};

CertificationSurveySubmission.defaultProps = {
  header: null,
  footer: null,
};

export default CertificationSurveySubmission;
