export const [DATE, NAME] = ['DATE', 'NAME'];


const orderMap = {
  [DATE]: ['', '-date', 'date'],
  [NAME]: ['', 'name', '-name'],
};

export class OrderStack {
  constructor(orders) {
    this.orderStates = orders.reduce((allOrders, order) => {
      allOrders[order] = 0;
      return allOrders;
    }, {});
    this.appliedOrders = [];
  }

  toggleOrder(orderName) {
    const newOrd = (this.orderStates[orderName] + 1) % 3;
    this.orderStates[orderName] = newOrd;
    // move most recently applied filter to front
    this.appliedOrders = [orderName].concat(this.appliedOrders.filter(ord => ord !== orderName));
  }

  buildQuery() {
    return this.appliedOrders
      .map(ordName => orderMap[ordName][this.orderStates[ordName]])
      .filter(ord => Boolean(ord))
      .join(',');
  }
}
