import React from 'react';
import PropTypes from 'prop-types';


const StepperNav = ({
  currentStep,
  setStep,
  steps,
  onCancel,
  disabled,
}) => {
  const canStepForward = currentStep < steps.length - 1;

  return (
    <div className="stepper-nav">
      <div className="stepper-nav-selector">
        {steps.map((step, i) => {
          const stepIsBehind = i <= currentStep;
          return (
            <button
              key={step.title}
              className={`text-button stepper-nav-cell ${i === currentStep ? 'active' : ''}`}
              onClick={() => {
                if (stepIsBehind) {
                  setStep(i);
                }
              }}
              disabled={i > currentStep + 1}
              type={stepIsBehind ? 'button' : 'submit'}
            >
              <div className="step-label">
                Step {i + 1}
              </div>
              <span>{step.title}</span>
            </button>
          );
        })}
      </div>
      <div className="stepper-nav-toolbar">
        <button className="icon-text-button" type="button" onClick={onCancel}>
          <i className="material-icons">close</i>
          <span>Cancel</span>
        </button>
        <button
          className="orange-button"
          type="submit"
          disabled={disabled}
        >
          {canStepForward ? 'Next' : 'Finish'}
        </button>
      </div>
    </div>
  );
};

StepperNav.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
};

StepperNav.defaultProps = {
  onCancel: () => null,
  disabled: false,
};

export { StepperNav };
