/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import useCancellablePromise from 'hooks/useCancellablePromise';
import { serializeEmployee } from 'utils/serializers/employeeSerializer';
import {
  PATH_NAMES, PATHS, PEOPLE, SUPPORT_EMAIL, EMPLOYEE_PATHS,
} from 'utils/constants';
import { ArrayField } from 'components/shared/SchemaForm/components/ArrayField';
import { ObjectArrayField } from 'components/shared/SchemaForm/components/ObjectArrayField';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import ExpandableSection from 'components/shared/expandableSection';
import SubmitButton from 'components/monitoringForm/submitButton';
import { NewEmployeeHeading, EditEmployeeHeading } from './EmployeeFormHeading';
import {
  employeeValidationSchema,
  initAddressFields,
  addressFieldsObj,
  firstName,
  lastName,
  altFirsts,
  altLasts,
  primaryAddress,
  addresses,
  email,
  altEmails,
  externalId,
} from './consts';
import ArchivedBanner from '../ArchivedBanner';
import PendingArchiveBanner from '../PendingArchiveBanner';
import PendingDeleteBanner from '../PendingDeleteBanner';


function EmployeeForm({
  edit, submitFunc, initForm, customFields, employee, toggleDeleteModal,
  openArchiveModal, restoreEmployee,
}) {
  const [tags, updateTags] = useState([]);
  const history = useHistory();
  const { cancellablePromise } = useCancellablePromise();

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    let payload = { ...values, tags };
    if (edit) {
      // since tags are updated upon selection don't send the tags key to avoid a race condition
      // (BE will only update tags if key exists)
      delete payload.tags;
    }
    payload = serializeEmployee(payload);
    cancellablePromise(submitFunc(payload)).then(({ empId }) => {
      actions.setSubmitting(false);
      return history.push(`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/${empId}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`);
    }).catch((err) => {
      if (!err.isCanceled) {
        actions.setSubmitting(false);
        const errors = err && err.response && err.response.data;
        let errorMessage = 'Something went wrong.';
        if (Array.isArray(errors)) {
          // max employees error
          [errorMessage] = errors;
        } else if (errors) {
          errorMessage = 'Some fields had errors';
          actions.setErrors(errors);
        }
        toast(errorMessage);
        throw err;
      }
    });
  };

  const handleRestore = () => {
    toast('Restoring employee...');
    // redirect before sending request to avoid button being clicked multiple times
    history.push(`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/${employee.id}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`);
    restoreEmployee(employee.id).then(() => {
    }).catch((err) => {
      const errors = err && err.response && err.response.data;
      let errorMessage = 'Something went wrong.';
      if (Array.isArray(errors)) {
        // max employees / monitored persons error
        [errorMessage] = errors;
      }
      toast(errorMessage);
      throw err;
    });
  };

  const {
    archived,
    archived_date: archivedDate,
    pending_archival_date: pendingArchiveDate,
    pending_delete_date: pendingDeleteDate,
    id,
  } = employee;
  return (
    <>
      {
        archived
          && (
          <ArchivedBanner
            archivedDate={archivedDate}
            id={id}
          />
          )
      }
      {
        pendingArchiveDate && !archived
        && (
        <PendingArchiveBanner
          pendingArchivalDate={pendingArchiveDate}
          id={employee.id}
        />
        )
      }
      {
        pendingDeleteDate
        && (
        <PendingDeleteBanner
          pendingDeleteDate={pendingDeleteDate}
          id={employee.id}
        />
        )
      }
      <div className="form-wrapper search-form employee-form" data-testid="add-employee-form">

        {
          edit
            ? (
              <EditEmployeeHeading
                headerType="person"
                closeUrl={`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}/${employee.id}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`}
                employeeObj={employee}
                toggleConfirmDelete={toggleDeleteModal}
                openArchiveModal={openArchiveModal}
                restoreEmployee={handleRestore}
              />
            )
            : (
              <NewEmployeeHeading
                headerType="person"
                updateTags={updateTags}
                selectedTags={tags}
                closeUrl={`${PATHS[PATH_NAMES.monitoring]}/${PEOPLE}`}
              />
            )
        }
        <Formik
          initialValues={({ ...initForm })}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validationSchema={employeeValidationSchema}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off" noValidate>
              <fieldset disabled={archived}>
                <div className="row employee-name-row">
                  <div className="col">
                    <SchemaField
                      name={firstName}
                      id={firstName}
                      label="First name"
                      fieldType="TextField"
                      className="form-input"
                      required
                    />
                    <ArrayField
                      name={altFirsts}
                      id={altFirsts}
                      label=""
                      addButtonText="alternate first name"
                      className="form-input"
                      limit={5}
                    />
                  </div>
                  <div className="col">
                    <SchemaField
                      name={lastName}
                      id={lastName}
                      label="Last name"
                      fieldType="TextField"
                      className="form-input"
                      required
                    />
                    <ArrayField
                      name={altLasts}
                      id={altLasts}
                      label=""
                      addButtonText="alternate last name"
                      className="form-input"
                      limit={3}
                    />
                  </div>

                  <div className="col-no-flex">
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      edit={edit}
                      type="person"
                      icon="person"
                    />
                  </div>

                </div>
                <hr />


                <ExpandableSection
                  title="Person Details"
                  startOpen
                >
                  <div className="row">
                    <div className="col">
                      <SchemaField
                        name={email}
                        id={email}
                        label="Primary email"
                        fieldType="TextField"
                        className="form-input"
                        data-private
                      />
                      <SchemaField
                        name={altEmails}
                        id={altEmails}
                        label=""
                        fieldType="ArrayField"
                        addButtonText="alternate email"
                        limit={5}
                        className="form-input"
                        labelClass=""
                        dataPrivate
                      />
                    </div>
                    <div className="col">
                      <SchemaField
                        name={externalId}
                        id={externalId}
                        label="Employee id"
                        fieldType="TextField"
                        className="form-input"
                        data-private
                      />
                    </div>
                  </div>
                  {
                    chunk(customFields, 2).map((col, i) => (
                      <div className="row" key={`custom-data-row-${i}`}>
                        {col.map((field) => (
                          <div key={field.id} className="col">
                            <SchemaField
                              name={`custom_data.${field.label}`}
                              id={`custom_data.${field.label}`}
                              label={field.label}
                              fieldType="TextField"
                              disabled={!field.is_editable}
                              className="form-input"
                              tooltip={!field.is_editable ? `This field is not editable. Please contact ${SUPPORT_EMAIL} with any questions.` : null}
                            />
                          </div>
                        ))}
                      </div>
                    ))
                  }
                </ExpandableSection>

                <ExpandableSection
                  title="Addresses"
                  startOpen
                >
                  {
                    chunk(Object.values(addressFieldsObj), 2).map((col, i) => (
                      <div className="row" key={`address-row-${i}`}>
                        {col.map((field) => (
                          <div key={field.id} className="col">
                            <SchemaField
                              {...field}
                              name={`${primaryAddress}.${field.id}`}
                              id={`${primaryAddress}.${field.id}`}
                              className="form-input"
                              data-private
                              disabled={archived} // need to explicitly pass to searchable select field
                            />
                          </div>
                        ))}
                      </div>
                    ))
                  }
                  <ObjectArrayField
                    name={addresses}
                    id={addresses}
                    addButtonText="alternate address"
                    defaultNewObj={({ ...initAddressFields })}
                    elemMap={addressFieldsObj}
                    limit={5}
                    cols={2}
                    dataPrivate
                  />
                </ExpandableSection>
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

EmployeeForm.propTypes = {
  edit: PropTypes.bool.isRequired,
  submitFunc: PropTypes.func.isRequired,
  initForm: PropTypes.object.isRequired,
  customFields: PropTypes.array.isRequired,
  toggleDeleteModal: PropTypes.func,
  openArchiveModal: PropTypes.func,
  restoreEmployee: PropTypes.func,
  employee: PropTypes.object,
};

EmployeeForm.defaultProps = {
  toggleDeleteModal: () => {},
  openArchiveModal: () => {},
  restoreEmployee: () => {},
  employee: {},
};

export default EmployeeForm;
