import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { JURISDICTION, STATUS, PRE_REQUEST, NOTES, EMAIL_RECORD } from 'utils/constants';
import { ASC } from 'components/shared/matching/consts';
import ToolTip from '../toolTip';

export default class Schema extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleToolTip: null,
    };

    this.cells = {};
    this.checkOverflow = this.checkOverflow.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.cellRef = this.cellRef.bind(this);
  }

  onHover(index, label) {
    if (this.checkOverflow(label, index)) {
      this.setState({ visibleToolTip: index });
    }
  }

  onLeave() {
    this.setState({ visibleToolTip: null });
  }

  checkOverflow(value, index) {
    const elem = document.getElementById(`schema-${value}`);
    const dashCell = this.cells[index];
    if (elem && dashCell) {
      const elemWidth = elem.getBoundingClientRect().width;
      const dashWidth = dashCell.getBoundingClientRect().width;
      return (dashWidth < elemWidth);
    }
    return false;
  }

  cellRef(i, el) {
    this.props.cellRef(el);
    this.cells[i] = el;
  }

  render() {
    const { schema, onLabelClick, sortBy, sortDirection, ascendingSort, disabledSortIds } = this.props;
    const fixedWidth = [JURISDICTION, STATUS, PRE_REQUEST, NOTES, EMAIL_RECORD];
    return (
      <React.Fragment>
        {schema.map((s, i) => {
          const normalizedName = s.name || s.id;
          const isActive = normalizedName === sortBy;
          const disabledSort = disabledSortIds.indexOf(s.id) >= 0;
          return (
            <div
              className={`dashboard-cell schema-attr ${s.label} ${isActive ? 'sorted' : ''} ${fixedWidth.indexOf(s.label) >= 0 ? ' fixedWidth' : ''} ${disabledSort ? ' disabled' : ''}`}
              data-sort={normalizedName}
              onClick={disabledSort ? () => {} : () => onLabelClick(normalizedName)}
              key={normalizedName}
              ref={el => this.cellRef(i, el)}
              role="row"
              tabIndex={0}
              onMouseEnter={() => { this.onHover(i, s.label); }}
              onMouseLeave={this.onLeave}
            >
              <span data-sort={normalizedName} className="hoverable">
                <span data-sort={normalizedName}>
                  {s.label}
                </span>
                {
                  isActive ? (
                    <i className="sort-icon material-icons" data-sort={normalizedName}>
                      {
                        sortDirection === ascendingSort ? (
                          'arrow_upward'
                        ) : (
                          'arrow_downward'
                        )
                      }
                    </i>
                  ) : (
                    null
                  )
                }
              </span>
              <div className={`${this.state.visibleToolTip !== null && i === this.state.visibleToolTip ? 'wrapper-show' : 'wrapper-hide'}`}>
                <ToolTip
                  position={'top'}
                >
                  {s.label}
                </ToolTip>
              </div>

              <span className="hidden-span" data-sort={s.name} id={`schema-${s.label}`}>
                {s.label}
              </span>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

Schema.propTypes = {
  schema: PropTypes.array.isRequired,
  onLabelClick: PropTypes.func,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  cellRef: PropTypes.func,
  ascendingSort: PropTypes.string,
  disabledSortIds: PropTypes.array,
};

Schema.defaultProps = {
  sortBy: null,
  sortDirection: null,
  onLabelClick: () => {},
  cellRef: () => {},
  ascendingSort: ASC,
  disabledSortIds: [],
};
