import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'helpers/formatFieldHelpers';
import { TextField } from './TextField';


export const DateField = ({
  value,
  ...props
}) => (
  <TextField
    {...props}
    value={formatDate(value)}
    placeholder="MM/DD/YYY"
    style={{ textAlign: 'right' }}
  />
);


DateField.propTypes = {
  value: PropTypes.string.isRequired,
};
