import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Section from 'components/shared/settingsPage/section';
import SettingsPage from 'components/shared/settingsPage/settingsPage';
import SimpleForm from 'components/shared/forms/simpleForm';
import { updateUser } from 'reducers/user/actions';


const fieldMap = {
  first_name: {
    label: 'first name',
    type: 'text',
  },
  last_name: {
    label: 'last name',
    type: 'text',
  },
  email: {
    label: 'email address',
    type: 'text',
    required: true,
    info: 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.',
  },
};


function EditProfile({ fname, lname, email, updateUser }) {
  const defaultState = {
    first_name: fname,
    last_name: lname,
    email,
  };
  const section = () => (
    <Section>
      <SimpleForm
        fieldMap={fieldMap}
        onSubmit={updateUser}
        defaultState={defaultState}
        confirmText={'save'}
        genericError={'There was a problem saving these settings.'}
        successToast={'Changes saved successfully.'}
      />
    </Section>
  );

  return (
    <SettingsPage
      header={'Edit Your Profile'}
      sections={[section]}
    />
  );
}

EditProfile.propTypes = {
  fname: PropTypes.string,
  lname: PropTypes.string,
  email: PropTypes.string.isRequired,
  updateUser: PropTypes.func.isRequired,
};

EditProfile.defaultProps = {
  fname: '',
  lname: '',
};

const mapStateToProps = ({ user: { first_name, last_name, email } }) => ({
  fname: first_name,
  lname: last_name,
  email,
});

export default connect(mapStateToProps, { updateUser })(EditProfile);

