export const LOAD_FORMS = 'LOAD_FORMS';
export const ADD_FORM = 'ADD_FORM';
export const EDIT_FORM = 'EDIT_FORM';
export const DELETE_FORM = 'DELETE_FORM';


function reducer(state, action) {
  switch (action.type) {
    case LOAD_FORMS: {
      const formsById = {};
      const allForms = [];
      action.forms.forEach((form) => {
        formsById[form.id] = { ...form };
        allForms.push(form.id);
      });
      return {
        loading: false,
        formsById,
        allForms,
      };
    }
    case ADD_FORM: {
      const { form } = action;
      const forms = { ...state.formsById };
      forms[form.id] = form;
      return {
        ...state,
        formsById: forms,
        allForms: [form.id, ...state.allForms],
      };
    }
    case EDIT_FORM: {
      const forms = { ...state.formsById };
      forms[action.form.id] = action.form;
      return {
        ...state,
        formsById: forms,
      };
    }
    case DELETE_FORM: {
      const forms = state.allForms.filter((id) => id !== action.id);
      return {
        ...state,
        allForms: forms,
      };
    }
    default:
      throw new Error();
  }
}

export default reducer;

export const getForms = (state) => state.allForms.map((id) => state.formsById[id]);
export const getForm = (state, id) => state.formsById[id];
