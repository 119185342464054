import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Column, InfiniteLoader } from 'react-virtualized';
import { ROW_HEIGHT, CELL, ROW, SCROLLBAR_OFFSET } from 'components/preclearance/table/constants';
import { PATHS, PATH_NAMES, SCROLLABLE_GRID_CLASS, MAIN_TABLE_CONTAINER } from 'utils/constants';
import Loading from 'components/loading';
import LoadingMore from 'components/shared/loadingMore';
import EmptyState from 'components/shared/emptyState';
import RadioButton from 'components/shared/radioButton';
import { getInitialCellWidth } from './tableHelpers';
import Cell from './cell';
import { SELECTION_COL, TABLE_HEIGHT, HEADER_HEIGHT, ICON_SORT_MAP, MONITORED_RECORD, MATCHES } from '../consts';
import TableHeader from './tableHeader';


class PreclearanceMatchingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidths: {},
      resizingCol: null,
    };
    this.rowGetter = this.rowGetter.bind(this);
    this.rowRenderer = this.rowRenderer.bind(this);
    this.loadingRowsRenderer = this.loadingRowsRenderer.bind(this);
    this.noRowsRenderer = this.noRowsRenderer.bind(this);
    this.headerRowRenderer = this.headerRowRenderer.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onResizeStop = this.onResizeStop.bind(this);
    this.initColWidths = {};
  }

  componentDidMount() {
    if (this.props.columns) {
      this.buildColumnMap();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.columns.length !== prevProps.columns.length) {
      this.buildColumnMap();
    }
    if (this.props.endCount !== prevProps.endCount) {
      const lastIdx = this.props.rows.length - 1;
      this.virtualTable && lastIdx > 0 && this.virtualTable.recomputeRowHeights(lastIdx);
    }
  }

  onResize(_event, colId, _axis, _element, { width: deltaX }) {
    const { columnWidths } = this.state;
    this.startWidth = this.startWidth || columnWidths[colId];
    const newWidth = this.startWidth + deltaX;
    this.setState({
      columnWidths: {
        ...columnWidths,
        [colId]: newWidth,
      },
      resizingCol: colId,
    });
  }

  onResizeStop() {
    this.startWidth = null;
    this.setState({
      resizingCol: null,
    });
  }

  buildColumnMap() {
    const totalWidth = (this.table && this.table.clientWidth) || 0;
    const columnWidths = {};
    this.props.columns.map(col => columnWidths[col.id] = getInitialCellWidth(col.id, totalWidth, this.props.columns.length));
    this.initColWidths = columnWidths;
    this.setState({
      columnWidths,
    });
  }

  rowRenderer({ index, rowData, style }) {
    const { selectedRow, rows, loading, matches } = this.props;
    const atEnd = index === rows.length - 1;
    const disabled = matches.some(match => match.id === rowData.id);
    const rowCells = this.props.columns.map(col => (
      this.cellRenderer(rowData, index, col.id, disabled)
    ));
    return (
      <div
        key={`preclearance-row-${index}`}
        style={style}
        className={`${index % 2 === 0 ? 'even' : 'odd'} ${rowData.id === selectedRow ? 'selected' : ''}`}
      >
        <div
          style={{ height: ROW_HEIGHT }}
          className={`${ROW}`}
        >
          {rowCells}
        </div>
        {(atEnd && loading) ? (
          <LoadingMore />) : ''}
      </div>
    );
  }

  loadingRowsRenderer() {
    const numRows = Math.floor((TABLE_HEIGHT - HEADER_HEIGHT) / ROW_HEIGHT);
    return [...Array(numRows + 1).keys()].map((_, index) => {
      const rowCells = this.props.columns.map((col, idx) => (
        <div
          className={`${CELL} loading-cell`}
          key={`row${idx}-${col.id}`}
          style={{ width: this.state.columnWidths[col.id] }}
        >
          <div className={'loading-placeholder'} />
        </div>
      ));
      return (
        <div
          className={`${ROW} ${index % 2 === 1 ? 'odd' : 'even'}`}
          key={`preclearance-row-${index}`}
          style={{ height: ROW_HEIGHT }}
        >
          {rowCells}
        </div>
      );
    });
  }

  noRowsRenderer() {
    if (this.props.loading) {
      return this.loadingRowsRenderer();
    }
    return (
      <EmptyState
        icon={'search'}
        messageHeading={'No preclearance requests found.'}
        message={<div>Try adjusting the filters or go to the <Link to={PATHS[PATH_NAMES.preclearance]}>preclearance table</Link> to add a preclearance request.</div>}
      />
    );
  }

  cellRenderer(rowData, rowIndex, col, disabled) {
    const { selectedRow, handleRowSelect, columns } = this.props;
    const { resizingCol } = this.state;
    let width = this.state.columnWidths[col];
    const lastCol = columns[columns.length - 1];
    if (col === lastCol.id) {
      // to compensate for the scrollbar in IE :(
      width = this.state.columnWidths[col] - SCROLLBAR_OFFSET;
    }
    return (
      <div
        className={`${CELL} ${resizingCol === col ? 'resize' : ''}`}
        key={`row${rowIndex}-${col}`}
        style={{ width }}
      >
        {
          col === SELECTION_COL.id ?
            <span>
              <RadioButton
                id={rowData.id}
                checked={selectedRow === rowData.id}
                onChange={() => { handleRowSelect(rowData); }}
                value={rowData.id}
                disabled={disabled}
                disabledText={'This request has already been matched to this record.'}
              />
            </span>
            :
            <Cell
              value={rowData[col]}
              noMatch={rowData.no_match}
              col={col}
              matchType={MONITORED_RECORD}
            />
        }
      </div>
    );
  }

  headerRowRenderer({ style, columns }) {
    return (
      <TableHeader
        columns={columns}
        style={style}
        resizingCol={this.state.resizingCol}
        onResize={this.onResize}
        onResizeStop={this.onResizeStop}
        initColWidths={this.initColWidths}
        columnWidths={this.state.columnWidths}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  headerCellRenderer({ dataKey, sortBy, sortDirection, col }) {
    return (
      <React.Fragment>
        <span>{ col.label }</span>
        {sortBy === dataKey && <i className="material-icons">{ICON_SORT_MAP[sortDirection]}</i>}
      </React.Fragment>
    );
  }

  rowGetter({ index }) {
    return this.props.rows[index];
  }

  render() {
    const { rows, columns, loading, sort, onSort, loadMoreRows, endCount } = this.props;
    const { sortBy, sortDirection } = sort;
    const minWidth = Object.values(this.state.columnWidths).reduce((acc, num) => (acc + num), 0);
    return (
      <div className="preclearance-table-container">
        {loading && !rows.length && (
          <div className="table-load-screen">
            <Loading />
          </div>
        )}
        <div className={MAIN_TABLE_CONTAINER} ref={(el) => { this.table = el; }}>
          <InfiniteLoader
            isRowLoaded={({ index }) => !!rows[index]}
            loadMoreRows={loadMoreRows}
            rowCount={endCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <Table
                ref={(el) => {
                  registerChild(el);
                  this.virtualTable = el;
                }}
                onRowsRendered={onRowsRendered}
                width={minWidth}
                height={TABLE_HEIGHT}
                headerHeight={HEADER_HEIGHT}
                rowHeight={({ index }) => ROW_HEIGHT + (loading && index === rows.length - 1 ? 72 : 0)} // to compensate for loading more animation when fetching more rows
                rowCount={rows.length}
                rowRenderer={this.rowRenderer}
                rowGetter={this.rowGetter}
                noRowsRenderer={this.noRowsRenderer}
                sortBy={sortBy}
                sortDirection={sortDirection}
                sort={onSort}
                headerRowRenderer={this.headerRowRenderer}
                gridClassName={SCROLLABLE_GRID_CLASS}
              >
                { columns.map(col => (
                  <Column
                    {...col}
                    width={minWidth}
                    label={col.label}
                    key={col.id}
                    dataKey={col.id}
                    className={CELL}
                    headerClassName={`preclearance-header-cell${sortBy === col.id ? ' sort' : ''} `}
                    headerRenderer={args => this.headerCellRenderer({ ...args, col })}
                    disableSort={col.id === SELECTION_COL.id || col.id === MATCHES || loading}
                  />))}
              </Table>
            )}
          </InfiniteLoader>
        </div>
      </div>
    );
  }
}

PreclearanceMatchingTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  sort: PropTypes.object.isRequired,
  onSort: PropTypes.func,
  selectedRow: PropTypes.string,
  handleRowSelect: PropTypes.func.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  endCount: PropTypes.number.isRequired,
  matches: PropTypes.array,
};

PreclearanceMatchingTable.defaultProps = {
  selectedRow: null,
  loading: false,
  onSort: () => {},
  handleRowSelect: () => {},
  sort: { sortBy: null, sortDirection: null },
  loadMoreRows: () => {},
  endCount: 1,
  matches: [],
};

export default PreclearanceMatchingTable;
