import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { byId } from 'reducers/searchObjects/selectors';
import { nonSelfSearches, sortSearches } from 'components/employeePage/consts';


function SpouseDependentItem({ name, searchType }) {
  return (
    <div className="employee-tab-search-item">
      <div className="employee-tab-search-type">
        {searchType}
      </div>
      <div className="employee-tab-search-name">
        {name}
      </div>
    </div>
  );
}

SpouseDependentItem.propTypes = {
  name: PropTypes.string.isRequired,
  searchType: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { id }) => {
  const searchObject = byId(state, id);
  const { first_name: firstName, last_name: lastName } = searchObject || {};
  const name = searchObject ? `${lastName}, ${firstName}` : '';
  return {
    name,
  };
};

const SearchItem = connect(mapStateToProps, {})(SpouseDependentItem);


function EmployeeSearchesList({ searches }) {
  const searchList = nonSelfSearches(searches);
  return (
    sortSearches(searchList).map(search => (
      <SearchItem
        key={search.search_object_id}
        id={search.search_object_id}
        searchType={search.search_type}
      />
    ))

  );
}

export default EmployeeSearchesList;
