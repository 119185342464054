import { useRef, useEffect } from 'react';
import { makeCancelable } from 'utils/cancelablePromise';

export default function useCancellablePromise(cancelable = makeCancelable) {
  const emptyPromise = Promise.resolve(true);

  // test if the input argument is a cancelable promise generator
  if (cancelable(emptyPromise).cancel === undefined) {
    throw new Error('promise wrapper argument must provide a cancel() function');
  }

  const promises = useRef();

  useEffect(
    () => {
      promises.current = promises.current || [];
      return function cancel() {
        promises.current.forEach(p => p.cancel());
        promises.current = [];
      };
    }, [],
  );

  function cancellablePromise(p) {
    const cPromise = cancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }

  return { cancellablePromise };
}
