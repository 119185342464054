import React from 'react';
import { SCROLLABLE_CONTAINER } from '../../utils/constants';
import { scrollToElem } from '../../helpers/helpers';

const ScrollToTop = () => (
  <button
    className="scroll-button"
    onClick={() => {
      const windowLoc = SCROLLABLE_CONTAINER instanceof Window ? (window.scrollY || window.pageYOffset) : SCROLLABLE_CONTAINER.scrollTop;
      scrollToElem(-(windowLoc));
    }}
  >
    <i className="material-icons">arrow_upward</i>
  </button>
);

export default ScrollToTop;
