import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SUPPORT_EMAIL } from 'utils/constants';
import { disableTwoFactor } from 'reducers/user/actions';
import Section from 'components/shared/settingsPage/section';
import DisableConfirmation from './disableConfirmation';

const helpText = `We strongly encourage you to use two-factor authentication on your account to ensure account security. Please contact ${SUPPORT_EMAIL} with any questions.`;

class Disable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: false, showConf: false };
  }

  renderDisableConf() {
    if (!this.state.showConf) return null;
    return (
      <DisableConfirmation
        disableConfirm={!this.state.checked}
        close={() => { this.setState({ showConf: false, checked: false }); }}
        onCheck={() => { this.setState({ checked: !this.state.checked }); }}
        onSubmit={this.props.disableTwoFactor}
      />
    );
  }

  render() {
    return (
      <Section
        containerClass={'two-factor-auth'}
        header={'Disable Two-Factor Authentication'}
        helpText={<p>{helpText}</p>}
      >
        <button
          className="orange-button"
          onClick={() => { this.setState({ showConf: true }); }}
        >
            disable two-factor authentication
        </button>
        {this.renderDisableConf()}
      </Section>
    );
  }
}

Disable.propTypes = {
  disableTwoFactor: PropTypes.func.isRequired,
};

export default connect(null, { disableTwoFactor })(Disable);
