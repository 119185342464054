import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setFieldData } from 'reducers/employees/actions';
import OpenItemWrapper from 'components/shared/openItemWrapper';
import CustomField from 'components/shared/customFields/customField';


const CustomFieldsManager = ({
  empId, customData, fields, setCustomData, readOnly,
}) => (
  <OpenItemWrapper
    findErrors={() => {}}
    updateFunc={(openField) => setCustomData(empId, {
      custom_data: { ...customData, ...openField },
    })}
    entityName="field"
    render={({
      openField,
      toggleEditing,
      onFieldChange,
      errors,
      onUpdate,
    }) => (
      Object.values(fields).map((field) => (
        customData[field.label]
          ? (
            <CustomField
              key={field.id}
              dataType={field.data_type}
              field={field.label}
              disabled={!field.is_editable || readOnly}
              customData={customData}
              openField={openField}
              toggleEditing={toggleEditing}
              onFieldChange={onFieldChange}
              errors={errors}
              onUpdate={onUpdate}
            />
          ) : null
      ))
    )}
  />
);

CustomFieldsManager.propTypes = {
  empId: PropTypes.number.isRequired,
  customData: PropTypes.object.isRequired,
  setCustomData: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

CustomFieldsManager.defaultProps = {
  readOnly: false,
};

const mapStateToProps = (state) => ({
  fields: state.customFields,
});

export default connect(
  mapStateToProps,
  {
    setCustomData: setFieldData,
  },
)(CustomFieldsManager);
