import * as types from './types';
import api from '../../api';

const receiveOrganization = data => ({
  data,
  type: types.LOAD_ORGANIZATION,
});

const receiveUsers = data => ({
  data,
  type: types.RECEIVE_USERS,
});

const receiveUser = data => ({
  data,
  type: types.RECEIVE_USER,
});

const editUser = data => ({
  data,
  type: types.EDIT_USER,
});

const removeUser = userId => ({
  userId,
  type: types.REMOVE_USER,
});

export const getOrganization = () => dispatch =>
  api.get('/organization/')
    .then(({ data }) => dispatch(receiveOrganization(data)));

export const getOrgUsers = () => dispatch =>
  api.get('/organization/users/')
    .then(({ data }) => dispatch(receiveUsers(data)));

export const updateOrganization = orgData => dispatch =>
  api.patch('/organization/', orgData)
    .then(({ data }) => dispatch(receiveOrganization(data)));

export const createOrgUser = userData => dispatch =>
  api.post('/organization/users/', userData)
    .then(({ data }) => dispatch(receiveUser(data)));

export const updateOrgUser = userData => dispatch =>
  api.patch(`/organization/users/${userData.id}/`, userData)
    .then(({ data }) => dispatch(editUser(data)));

export const deleteOrgUser = userId => dispatch =>
  api.delete(`/organization/users/${userId}/`)
    .then(() => dispatch(removeUser(userId)));
