import React from 'react';
import PropTypes from 'prop-types';

const CheckboxField = ({ choices }) => (
  choices.map(({ label, id }) => (
    <div key={id} className="question-option">
      <i className="material-icons option-icon">
        check_box_outline_blank
      </i>
      <div>
        {
          label
        }
      </div>
    </div>
  ))
);

CheckboxField.propTypes = {
  choices: PropTypes.array.isRequired,
};

export { CheckboxField };
