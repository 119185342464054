import $ from 'jquery';
import { toast } from 'react-toastify';
import { SCROLLABLE_CONTAINER } from '../utils/constants';

export const scrollToElem = (top, cb) => {
  const sign = Math.sign(top) < 0 ? '-' : '+';
  const magnitude = Math.floor(Math.abs(top));
  // to keep page from shooting up to top if the sort column is double clicked

  const scrollable = SCROLLABLE_CONTAINER instanceof Window ? (document.scrollingElement || document.documentElement) : document.body;

  if (!scrollable.classList.contains('scrolling')) {
    scrollable.classList.add('scrolling');
    $(scrollable).animate({
      scrollTop: `${sign}=${magnitude}`,
    }, 800, 'swing', () => {
      scrollable.classList.remove('scrolling');
      if (cb) {
        cb();
      }
    });
  } else {
    $(scrollable).stop();
    scrollable.classList.remove('scrolling');
    scrollToElem(top, cb);
  }
};

export const toTitleCase = str => str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());


export const onCopyText = (textEl) => {
  // please pass the actual html element
  textEl.select();
  document.execCommand('copy');
  toast('Copied to clipboard.');
};

export const alphabeticalSort = (a, b) => {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  return 0;
};

// takes an array of objects and the field that the array will be sorted by
export const sortObjects = (objArray, fieldToSortBy) => (
  objArray.sort((a, b) => alphabeticalSort(a[fieldToSortBy], b[fieldToSortBy]))
);

// replacing the tags with a space so that it is readable
export const stripHTML = (html) => html.replace(/(<([^>]+)>)/gi, ' ');
