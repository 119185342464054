import React from 'react';
import PropTypes from 'prop-types';
import ToolTip from 'components/toolTip';

const RadioButton = ({ id, checked, disabled, children, onChange, value, disabledText }) => (
  <div className="radio-button">
    <input
      id={id}
      type="radio"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      value={value}
    />
    <label htmlFor={id}>{children}</label>
    {disabled && disabledText ? <ToolTip position={'right'}>{disabledText}</ToolTip> : '' }
  </div>
);

RadioButton.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  disabledText: PropTypes.string,
};

RadioButton.defaultProps = {
  disabled: false,
  children: null,
  disabledText: '',
};

export default RadioButton;
