import React from 'react';
import PropTypes from 'prop-types';
import { isCancel } from 'axios';
import { RequestTrackerCanceler } from 'utils/apiUtil';
import { toast } from 'react-toastify';
import Loading from '../../loading';
import NoResults from './noresults';


class SearchObjectView extends React.Component {
  componentDidMount() {
    !this.props.allSourceResultsFetched && this.fetchInitialData();
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    const dfMismatch = this.props.dateFilter.from !== prevProps.dateFilter.from || this.props.dateFilter.to !== prevProps.dateFilter.to;
    const objMismatch = this.props.search.id !== prevProps.search.id;

    const loading = this.props.loading && this.props.loading !== prevProps.loading;
    if (dfMismatch || objMismatch || loading) {
      clearTimeout(this.timer);
      const searchId = objMismatch ? prevProps.search.id : this.props.search.id;
      RequestTrackerCanceler.cancelByRequestId(searchId);
      !this.props.allSourceResultsFetched && this.fetchInitialData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    RequestTrackerCanceler.cancelByRequestId(this.props.search.id);
  }

  getResults(searchId, incompleteResults, searchType) {
    clearTimeout(this.timer);
    this.timer = setTimeout((_) => {
      if (!this._isMounted) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return Promise.all(incompleteResults.map((rId) => {
        return this.props.fetchResult(
          searchId,
          rId,
          searchType,
        );
      },
      )).then((results) => {
        const stillIncompleteResults = results.filter(r => !r.completed).map(r => r.id);
        if (stillIncompleteResults.length) {
          this.getResults(searchId, stillIncompleteResults, searchType);
        }
      });
    }, 3000);
  }

  fetchInitialData() {
    const { search, searchType } = this.props;
    this.props.fetchResults(search.id, searchType).then((data) => {
      const incompleteResults = data.filter(d => !d.completed).map(d => d.id);
      if (incompleteResults.length > 0) {
        this.getResults(search.id, incompleteResults, searchType);
      }
    }).catch((err) => {
      if (!isCancel(err)) {
        toast('We were unable to fetch results for this search.');
        throw err;
      }
    });
  }


  render() {
    const { showContribSchema, searchCompleted, searchType, subcategories, noResults, loading, sources, subcategoryComponent, recordGroupComponent, search, shouldDefaultOpen } = this.props;
    const defaultOpen = shouldDefaultOpen && subcategories.length === 1 && searchCompleted;
    const Subcategory = subcategoryComponent;
    return (
      <div className="result-section-container">
        <div className="results-container">
          {this.props.children}
          {
            loading ?
              <div className="result-loader-container">
                <Loading />
              </div>
              : null
          }
          {noResults ?
            <NoResults
              dateFilter={this.props.dateFilter}
              render={({ dateFilterText }) => (
                <span>
                  A search for <span className="main-name">{search && search.first_name ? `${search.first_name} ${search.last_name}` : search.entity_name}</span>{dateFilterText} did not return any results.
                </span>)}
            />
            : null
          }
          {
            subcategories.sort().map((subcatName, i) => (
              <Subcategory
                showContribSchema={showContribSchema}
                key={subcatName}
                name={subcatName}
                searchType={searchType}
                isLast={i === (Object.keys(subcategories).length - 1)}
                defaultOpen={defaultOpen}
                sources={sources}
                recordGroupComponent={recordGroupComponent}
                searchId={search.id}
              />
            ))
          }
        </div>
      </div>
    );
  }
}


SearchObjectView.propTypes = {
  children: PropTypes.object,
  search: PropTypes.object.isRequired,
  dateFilter: PropTypes.object.isRequired,
  searchType: PropTypes.string,
  subcategories: PropTypes.array.isRequired,
  fetchResults: PropTypes.func.isRequired,
  fetchResult: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  noResults: PropTypes.bool.isRequired,
  searchCompleted: PropTypes.bool.isRequired,
  allSourceResultsFetched: PropTypes.bool.isRequired,
  showContribSchema: PropTypes.bool.isRequired, // determines whether to render checkboxes, notes, and statuses
  sources: PropTypes.array.isRequired,
  recordGroupComponent: PropTypes.func.isRequired,
  subcategoryComponent: PropTypes.func.isRequired,
  shouldDefaultOpen: PropTypes.bool,
};

SearchObjectView.defaultProps = {
  renderHeader: true,
  searchType: '',
  shouldDefaultOpen: true,
  children: null,
};

export default SearchObjectView;
