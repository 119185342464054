import { cloneDeep } from 'lodash';
import { filterBlanks } from 'utils/serializers/searchSerializer';
import {
  entityName,
  exclude,
  searchAs,
} from 'components/monitoringForm/entityForm/entityFormContainer';

export const serializeEntity = (values) => {
  const entity = cloneDeep(values);

  const serialized = {
    [entityName]: entity[entityName].trim(),
    search_settings: {
      [exclude]: filterBlanks(entity[exclude]),
      [searchAs]: entity[searchAs],
      sources: Array.from(entity.sources),
      all_sources: entity.all_sources,
    },
  };
  return serialized;
};
