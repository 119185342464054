import { connect } from 'react-redux';
import RecordGroup from 'components/shared/searchResults/recordGroup';
import { fetchJurisdictionResults as fetchResults } from 'reducers/results/actions';
import { getResultsById } from 'reducers/results/selectors';
import { byId } from 'reducers/contributions/selectors';
import * as sourcesSelectors from 'reducers/sources/selectors';
import * as sourceResultSelectors from 'reducers/sourceResults/selectors';
import { EMPLOYEES } from 'utils/constants';


const mapStateToProps = (state, props) => {
  const { searchObj, searchType } = props;
  const searchObjResult = getResultsById(state, searchObj.id);
  let results = null;
  let schemas = null;
  let sourceResultIds = null;

  if (searchObjResult) {
    const contribIds = sourceResultSelectors.contributionIdsBySources(state.sourceResults, searchObjResult.sourceResults); // todo: rename to sourceResultIds

    results = byId(state.contributions, contribIds);

    const sources = sourcesSelectors.byId(state.sources.sources, Array.from(new Set(results.map(res => res.sourceId))));

    schemas = sources.reduce((_schemas, source) => {
      _schemas[source.id] = source[`${searchType === EMPLOYEES ? 'person' : 'entity'}_schema`];
      return _schemas;
    }, {});
    sourceResultIds = searchObjResult.sourceResults;
  }
  return {
    tableTitle: searchObj.entity_name || `${searchObj.last_name}, ${searchObj.first_name}`,
    searchDetails: searchObj,
    results,
    selectedContribs: state.ui.selectedContribs,
    statuses: state.statuses,
    searchObjId: Number(searchObj.id),
    sourceResultIds,
    schemas,
    isJurisdiction: true,
    resultCount: searchObj.last_count,
  };
};

export default connect(
  mapStateToProps,
  {
    fetchResults,
  })(RecordGroup);
