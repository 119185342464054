import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage, useFormikContext } from 'formik';
import { dateFormat as defaultDateFormat } from 'components/Certifications/constants';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import DatePickerWrapper from 'components/bulkExports/datePickerWrapper';

const DateRangeField = ({
  dateFormat,
  isOutsideRange,
  startFieldName,
  endFieldName,
  startDateLabel,
  endDateLabel,
}) => {
  const [startDateField] = useField(startFieldName);
  const [endDateField] = useField(endFieldName);
  const { setFieldValue } = useFormikContext();

  return (
    <div>
      <DatePickerWrapper
        startDate={startDateField.value ? moment(startDateField.value) : null}
        endDate={endDateField.value ? moment(endDateField.value) : null}
        startDateLabel={startDateLabel}
        endDateLabel={endDateLabel}
        isOutsideRange={isOutsideRange}
        onDatesChange={({ startDate, endDate }) => {
          const start = startDate ? startDate.format(dateFormat) : null;
          const end = endDate ? endDate.format(dateFormat) : null;
          setFieldValue(startFieldName, start);
          setFieldValue(endFieldName, end);
        }}
        numberOfMonths={2}
      />
      <div className="reports-row">
        <div className="wrapper">
          <div className="error-text">
            <ErrorMessage
              name={startFieldName}
              component="div"
            />
          </div>
          <div className="error-text">
            <ErrorMessage
              name={endFieldName}
              component="div"
            />
          </div>

        </div>
      </div>
    </div>
  );
};

DateRangeField.propTypes = {
  dateFormat: PropTypes.string,
  isOutsideRange: PropTypes.func,
  startFieldName: PropTypes.string.isRequired,
  endFieldName: PropTypes.string.isRequired,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
};

DateRangeField.defaultProps = {
  dateFormat: defaultDateFormat,
  isOutsideRange: () => false,
  startDateLabel: '',
  endDateLabel: '',
};

export { DateRangeField };
