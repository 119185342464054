import * as types from './types';

const allIds = (state = [], action) => {
  switch (action.type) {
    case types.RECEIVE_EMPLOYEES: {
      return action.employees.map(emp => emp.id);
    }
    case types.ADD_EMPLOYEE: {
      return [...state, action.employee.id];
    }
    case types.REMOVE_EMPLOYEE: {
      return state.filter(id => id !== action.id);
    }
    default: return state;
  }
};

export default allIds;

export const getIds = state => state;
