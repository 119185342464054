import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSearchesByType } from 'reducers/searchObjects/selectors';
import JurisdictionRecordGroup from './recordGroupContainer';
import { scrollToElem } from '../../helpers/helpers';


class SubcategoryRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.scrollToHeaderTop = this.scrollToHeaderTop.bind(this);
  }

  componentDidMount() {
    if (this.props.defaultOpen) {
      this.toggleOpen();
    }
  }

  toggleOpen() {
    if (!this.state.open) {
      this.scrollToHeaderTop();
    }
    this.setState({ open: !this.state.open });
  }

  scrollToHeaderTop() {
    const target = this.subCatHeader.getBoundingClientRect().top;
    scrollToElem(target);
  }

  render() {
    const { searchObjects, recordCount, entitySlice } = this.props;
    const count = searchObjects.length;
    const defaultOpen = searchObjects.length === 1;
    return (
      <div className={this.state.open ? 'subcategory-container open' : 'subcategory-container'}>
        <button
          className="subcategory-row"
          onClick={this.toggleOpen}
          ref={(el) => { this.subCatHeader = el; }}
          name={this.props.name}
        >
          <h2 className="subcategory-header">
            {this.props.name}
            <span className="count-wrapper">{recordCount.overrideLocaleString()}</span>
          </h2>

          <div className="subcategory-details">
            <span>
              {this.state.open ?
                <i className="material-icons">expand_less</i> :
                <i className="material-icons">expand_more</i>
              }
            </span>
          </div>
        </button>
        {
          this.state.open ? (
            this.props.searchObjects.map((searchObj, idx) => (
              <JurisdictionRecordGroup
                key={searchObj.id}
                searchObj={searchObj}
                jurId={this.props.jurId}
                defaultOpen={defaultOpen}
                searchType={entitySlice}
                isLast={this.props.isLast && idx === (count - 1)}
                scrollToHeaderTop={this.scrollToHeaderTop}
              />
            ))
          ) : (
            null
          )
        }
      </div>
    );
  }
}

const jdMapStateToProps = (state, { entitySlice }) => {
  const searches = getSearchesByType(state, entitySlice);

  const orderedSearchObjects = searches
    .sort((a, b) => (((a.entity_name || a.last_name)
      .toLowerCase()) < ((b.entity_name || b.last_name).toLowerCase()) ? -1 : 1));

  const recordCount = searches.reduce((totalCount, searchObj) => totalCount + searchObj.last_count, 0);

  return {
    searchObjects: orderedSearchObjects,
    recordCount,
  };
};

SubcategoryRow.propTypes = {
  name: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  searchObjects: PropTypes.array.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
  entitySlice: PropTypes.string.isRequired,
  recordCount: PropTypes.number.isRequired,
  jurId: PropTypes.number.isRequired,
};

SubcategoryRow.defaultProps = {
  isLast: false,
};

export default connect(jdMapStateToProps)(SubcategoryRow);
