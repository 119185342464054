import * as Yup from 'yup';
import moment from 'moment';
import { requiredError, minLengthError, maxLengthError, dateError } from 'utils/errorHelpers';
import { acceptableDateFormats } from 'helpers/date_helpers';


export const fields = {
  firstName: 'first_name',
  lastName: 'last_name',
  zipcodes: 'zips',
  states: 'states',
  sources: 'sources',
  from: 'from',
  to: 'to',
};

export const {
  firstName,
  lastName,
  zipcodes,
  states,
  sources,
  from,
  to,
} = fields;

export const defaultSearchState = allSources => ({
  [firstName]: '',
  [lastName]: '',
  [zipcodes]: [''],
  [sources]: new Set(allSources.map(s => s.id)),
  [states]: [],
  [from]: '',
  [to]: '',
});


export const researchLimitTooltip = 'Your account has reached the maximum number of searches that can be made.';


const validateDate = (value, originalValue) => {
  const date = moment(originalValue, acceptableDateFormats, true);
  return date.isValid() ? date.toDate() : date.isValid();
};

export const validationSchema = Yup.object().shape({
  [firstName]: Yup.string()
    .trim()
    .min(2, minLengthError(2))
    .max(150, maxLengthError(150))
    .required(requiredError),
  [lastName]: Yup.string()
    .trim()
    .min(2, minLengthError(2))
    .max(200, maxLengthError(200))
    .required(requiredError),
  [zipcodes]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .max(11, maxLengthError(11)),
    ),
  [to]: Yup.date()
    .transform(validateDate)
    .typeError(dateError),
  [from]: Yup.date()
    .transform(validateDate)
    .typeError(dateError),
});
