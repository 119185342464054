import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { closeModal } from 'reducers/ui/actions';
import { deleteEmployee } from 'reducers/employees/actions';
import { byId } from 'reducers/employees/selectors';
import DeleteConfirmation from 'components/monitoringForm/deleteConfirmation';
import { BASE_MONITORING_URL } from 'utils/constants';


const mapStateToProps = (state, props) => {
  const { match: { params } } = props;
  const uiSearchType = params.searchType;
  const selected = byId(state, params.id) || {};
  const { first_name: firstName, last_name: lastName } = selected;
  const name = `${firstName} ${lastName}`;
  const deleteDelaySetting = state.user.delete_delay_setting_data;
  return {
    deleteFuncArgs: [selected.id],
    uiSearchType,
    name,
    deleteText: `Deleting ${name} will remove all of their records from the platform as well as records from their spouse or dependents.`,
    redirectUrl: `/app/${BASE_MONITORING_URL}/${uiSearchType}`,
    deleteDelay: state.user.delete_delay_setting,
    deleteDelaySetting: deleteDelaySetting ? deleteDelaySetting.label : null
  };
};

export default withRouter(connect(mapStateToProps, {
  closeModal,
  deleteFunc: deleteEmployee,
})(DeleteConfirmation));
