import api from 'api';
import { batchActions } from 'redux-batched-actions';
import { buildQuery, RequestTrackerCanceler, EMPLOYEES_REQ_ID } from 'utils/apiUtil';
import { serializeSearch } from 'utils/serializers/searchSerializer';
import { addSearch, editSearch } from 'reducers/searchObjects/actions';
import { removeSearchObjResults } from 'reducers/results/actions';
import { byId } from 'reducers/employees/selectors';
import { byId as getSearchById } from 'reducers/searchObjects/selectors';
import { EMPLOYEES } from 'utils/constants';
import * as types from './types';
import { buildSearch, shouldRunSearch, runEmployeeSearch } from './employeeSearchUtils';


const receiveEmployees = data => ({
  employees: data,
  type: types.RECEIVE_EMPLOYEES,
});

const addEmployee = data => ({
  employee: data,
  type: types.ADD_EMPLOYEE,
});

const editEmployee = data => ({
  employee: data,
  type: types.EDIT_EMPLOYEE,
});

const removeEmployeeSearch = (employeeId, searchId) => ({
  employeeId,
  searchId,
  type: types.REMOVE_EMPLOYEE_SEARCH,
});

const removeEmployee = id => ({
  id,
  type: types.REMOVE_EMPLOYEE,
});

const updateTags = (employeeId, tags) => ({
  employeeId,
  tags,
  type: types.UPDATE_TAGS,
});

const archiveEmp = (employee) => ({
  employee,
  type: types.ARCHIVE_EMPLOYEE,
});

const restoreEmp = (employee) => ({
  employee,
  type: types.RESTORE_EMPLOYEE,
});


export const fetchEmployees = (tags, name) => (dispatch) => {
  RequestTrackerCanceler.cancelByRequestId(EMPLOYEES_REQ_ID);
  const query = {
    tag: tags,
    name,
  };
  return api.get(`/compliance/employees?${buildQuery(query)}`, {
    cancelToken: RequestTrackerCanceler.getCancelToken(EMPLOYEES_REQ_ID),
  }).then(({ data }) => {
    dispatch(receiveEmployees(data));
    return data;
  });
};

export const fetchEmployee = id => dispatch => api.get(`/compliance/employee/${id}`)
  .then(({ data }) => {
    dispatch(editEmployee(data));
    return data;
  });


export const createEmployeeSearch = (id, searchInfo) => (dispatch) => {
  const searchData = serializeSearch(searchInfo);
  return api.post(`/compliance/employee/${id}/search`, searchData)
    .then(({ data }) => {
      dispatch(addSearch(EMPLOYEES, data));
      return { data, empId: id };
    });
};


export const updateEmployeeSearch = (id, searchInfo) => (dispatch) => {
  RequestTrackerCanceler.cancelByRequestId(searchInfo.id);
  return api.put(`/compliance/employee/${id}/search/${searchInfo.id}`, serializeSearch(searchInfo))
    .then(({ data }) => {
      dispatch(batchActions([
        editSearch(data),
        removeSearchObjResults(data.id),
      ]));
      return { data, empId: id };
    });
};


export const updateEmployeeAlert = (id, searchId, alert) => dispatch => api.put(`/compliance/employee/${id}/search/${searchId}`, { alerts_active: alert })
  .then(({ data }) => {
    dispatch(batchActions([
      editSearch(data),
    ]));
    return data;
  });


export const deleteEmployeeSearch = (id, searchId) => (dispatch, getState) => {
  // remove from state immediately to avoid trying to fetch the search
  // before this request has returned
  const search = getSearchById(getState(), searchId);
  dispatch(removeEmployeeSearch(id, searchId));
  RequestTrackerCanceler.cancelByRequestId(searchId);
  return api.delete(`/compliance/employee/${id}/search/${searchId}`)
    .then(() => {
    }).catch((err) => {
      // put search back if deletion fails
      dispatch(addSearch(EMPLOYEES, search));
      throw err;
    });
};


export const createEmployee = employeeInfo => (dispatch, getState) =>
  api.post('compliance/employee', employeeInfo).then(({ data }) => {
    dispatch(addEmployee(data));
    const { sources } = getState().sources;
    return dispatch(createEmployeeSearch(data.id, buildSearch(data, sources)));
  });


export const updateEmployee = employeeInfo => (dispatch, getState) => {
  const { id } = employeeInfo;
  const employee = byId(getState(), id);
  return api.put(`compliance/employee/${id}`, employeeInfo).then(({ data }) => {
    dispatch(editEmployee(data));
    if (shouldRunSearch(employee, data)) {
      return dispatch(runEmployeeSearch(data));
    }
    return { data, empId: data.id };
  });
};


export const setFieldData = (id, fieldData) => (dispatch) => api.patch(`compliance/employee/${id}`, fieldData).then(({ data }) => {
  dispatch(editEmployee(data));
  return data;
});


export const archiveEmployee = (id) => (dispatch) => api.patch(`compliance/employee/${id}`, { archived: true }).then(({ data }) => {
  dispatch(archiveEmp(data));
}).catch((err) => {
  throw err;
});


export const restoreEmployee = (id) => (dispatch) => api.patch(`compliance/employee/${id}`, { archived: false }).then(({ data }) => {
  // search is rerun as part of restoration process on BE
  // clear out old results to prompt fetching of updated results
  const searchObjActions = data.search_objects.map(({ search_object_id: searchId }) => (
    removeSearchObjResults(searchId)));
  dispatch(batchActions([
    restoreEmp(data),
    ...searchObjActions,
  ]));
}).catch((err) => {
  throw err;
});


export const deleteEmployee = id => dispatch =>
  api.delete(`compliance/employee/${id}`).then(() => {
    dispatch(fetchEmployees());
  }).catch((err) => {
    throw err;
  });


export const editTags = (id, tags) => (dispatch, getState) => {
  // save old tags in case there was a problem updating
  const currentTags = byId(getState(), id).tags;
  // update tags immediatly for better ux
  dispatch(updateTags(id, tags));
  return dispatch(setFieldData(id, { tags }))
    .catch((err) => {
      dispatch(updateTags(id, currentTags));
      throw err;
    });
};
