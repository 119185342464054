import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Tippy from '@tippyjs/react';
import { stripHTML } from 'helpers/helpers';
import IconTextButton from 'components/monitoringForm/iconTextButton';
import { RADIO } from 'components/FormBuilder/consts';
import DependentInput from './DependentInput';


const tooltip = (<div className="conditional-field-tooltip">Please add a multiple choice question before this question to use this feature</div>);


const DependentToolbar = ({ fieldName, dependencies, order }) => {
  const [_field, _meta, helpers] = useField(fieldName);
  const [fields] = useField('fields');
  const [fieldOrder] = useField('fieldOrder');

  const conditionalFieldOptions = useMemo(() => (
    fieldOrder.value.reduce((allOptions, id, index) => {
      const field = fields.value[id];
      if (field.input_type === RADIO && index < order) {
        const strippedLabel = stripHTML(field.label);
        allOptions.push({ value: id, label: strippedLabel });
      }
      return allOptions;
    }, [])
  ), [fieldOrder.value, fields.value, order]);

  const active = dependencies.length;
  const initVal = conditionalFieldOptions.length ? [{
    prerequisite_field: conditionalFieldOptions[0].value,
    prerequisite_values: [''],
  }] : [];

  return (
    <div>
      <Tippy
        content={tooltip}
        disabled={conditionalFieldOptions.length}
      >
        <div style={{ display: 'inline-block' }}>
          <IconTextButton
            className={active ? 'toggle-active' : ''}
            icon={active ? 'toggle_on' : 'toggle_off'}
            buttonText="Make conditional"
            buttonHandler={() => helpers.setValue(active ? [] : initVal)}
            disabled={!conditionalFieldOptions.length}
          />
        </div>
      </Tippy>
      {
      active
        ? (
          dependencies.map((dependentItem, index) => {
            const fieldId = dependentItem.prerequisite_field;
            const dependentField = fields.value[fieldId];
            return (
              <DependentInput
                key={`dependent-item-${index}`}
                fieldName={`${fieldName}.${index}`}
                fieldOptions={conditionalFieldOptions}
                valueOptions={dependentField.choices}
                dependentValues={dependentItem.prerequisite_values}
              />
            );
          })
        )
        : null
    }
    </div>
  );
};

DependentToolbar.propTypes = {
  fieldName: PropTypes.string.isRequired,
  dependencies: PropTypes.array.isRequired,
  order: PropTypes.number.isRequired,
};

export default DependentToolbar;

