/* eslint-disable max-classes-per-file */
import { CURRENCY_CHOICES, DEFAULT_CURRENCY } from 'utils/constants';
import {
  DEFAULT_COL_WIDTH,
  NA,
  STATUS,
  CURRENCY,
  DECIMAL,
  CHECKBOX,
  TEXT,
  DATE,
  ENUM,
  SELECT,
} from '../constants';


class Column {
  constructor(column, statusMap) {
    Object.keys(column).forEach((key) => { this[key] = column[key]; });
    // there are a few orgs with columns that have no type or an unsupported type (ex. ForeignKey)
    // ideally we should properly migrate them and remove this check
    if ([DATE, DECIMAL, CHECKBOX, ENUM, SELECT].indexOf(column.type) < 0) {
      this.type = TEXT;
    }
    // todo: just keep one id
    this.id = column.internal_name || column.id;
    this._id = column.id;
    this.width = column.width || DEFAULT_COL_WIDTH;

    if (column.internal_name === STATUS) {
      this.choices = Object.values(statusMap).map(({ id, label }) => ([id, label]));
    } else if (column.input_type === 'select') {
      this.choices = !column.required ? [[NA, NA], ...this.choices] : [...this.choices];
      this.defaultValue = !column.required ? NA : '';
    }
  }
}

class CurrencyColumn {
  constructor(column) {
    Object.keys(column).forEach((key) => { this[key] = column[key]; });
    this.id = column.internal_name || column.id;
    this._id = column.id;
    this.width = column.width || DEFAULT_COL_WIDTH;
    this.input_type = 'select';
    this.choices = CURRENCY_CHOICES;
    this.defaultValue = DEFAULT_CURRENCY;
  }
}

export const serializeColumns = (cols, statuses) => {
  const colMap = {};
  cols.forEach((col) => {
    const serialized = createColumn(col, statuses);
    colMap[serialized.id] = serialized;
  });
  return colMap;
};

export const createColumn = (col, statuses) => {
  if (col.label.toLowerCase() === CURRENCY) {
    return new CurrencyColumn(col);
  }
  return new Column(col, statuses);
};
