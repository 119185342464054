/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import { disableSendEmailPermissions } from 'helpers/permissions';
import { createAutoAction, editAutoAction, removeAutoAction } from 'reducers/preclearance/autoActions/actions';
import { excludingId } from 'reducers/preclearance/autoActions/selectors';
import Section from 'components/shared/settingsPage/section';
import OpenItemWrapper from 'components/shared/openItemWrapper';
import Confirmation from 'components/settings/confirmationShared';
import ActionSentence from '../actionItems/actionSentence';
import { ActionItem, ActionEditItem } from '../actionItems/actionItem';


const findErrors = (action) => {
  const requiredFields = ['status', 'template'];
  const missingFields = requiredFields.filter(f => !action[f]);
  return missingFields.length > 0 ? 'Please fill out all fields' : null;
};

// TO DO -- check this limit
const actionLimit = 10;

function StatusChangeActions({
  statuses,
  templates,
  autoActions,
  createAutoAction,
  editAutoAction,
  removeAutoAction,
  disableSendEmail,
}) {
  const atLimit = Object.values(autoActions).length >= actionLimit;
  return (
    <Section
      subHeader={'Status Change Actions'}
      helpText={<p>Set up automated actions based on a specified trigger. Select the email template you would like to send when the status is changed.</p>}
    >
      <OpenItemWrapper
        findErrors={findErrors}
        createFunc={createAutoAction}
        updateFunc={editAutoAction}
        deleteFunc={removeAutoAction}
        entityName={'action'}
        render={({ toggleEditing,
          onFieldChange,
          openField,
          showConfirmation,
          toggleConfirmation,
          errors,
          onCreate,
          onDelete,
          onUpdate,
          onToggle,
        }) => {
          return (
            <div className="auto-actions-wrapper">
              {Object.values(autoActions).map((action) => {
                const editing = Boolean(openField && openField.id === action.id);
                const ActionComponent = editing ? ActionEditItem : ActionItem;
                return (
                  <ActionComponent
                    key={action.id}
                    action={editing ? openField : action}
                    statuses={statuses}
                    templates={templates}
                    toggleEditing={toggleEditing}
                    onToggleActive={() => onToggle(action, 'active')}
                    onDelete={toggleConfirmation}
                    onSubmit={onUpdate}
                    onFieldChange={onFieldChange}
                    errors={errors}
                    buttonText={'Update'}
                    autoActions={autoActions}
                    onClose={() => { toggleEditing(null); }}
                    disabled={disableSendEmail}
                    disabledMessage="Sending emails is not allowed for your organization."
                  />
                );
              })}
              {openField && !openField.id
                ? (
                  <ActionEditItem
                    action={openField}
                    statuses={statuses}
                    templates={templates}
                    autoActions={autoActions}
                    buttonText={'Add'}
                    onSubmit={onCreate}
                    onFieldChange={onFieldChange}
                    onClose={() => { toggleEditing(null); }}
                    errors={errors}
                  />
                )
                : (
                  <Tippy
                    content={disableSendEmail ? 'Sending emails is not allowed for your organization.' : `There is a limit of ${actionLimit} actions.`}
                    disabled={!disableSendEmail && !atLimit}
                  >
                    <span className="button-wrapper">
                      <button
                        onClick={() => {
                          toggleEditing({ status: null, template: null, active: true });
                        }}
                        className="text-button uppercase add-field-button"
                        disabled={disableSendEmail || atLimit}
                        type="button"
                      >
                        + Add action
                      </button>
                    </span>
                  </Tippy>
                )}
              {showConfirmation ?
                <Confirmation
                  open
                  close={() => { toggleConfirmation(null); }}
                  confirmText={'Delete'}
                  dismissText={'Cancel'}
                  onSubmit={onDelete(autoActions[showConfirmation].id)}
                >
                  <div>
                    <h2>Delete Action</h2>
                    <div className="message">
                      Are you sure you would like to delete this automated action?
                      <ul>
                        <li>
                          <ActionSentence
                            status={statuses[autoActions[showConfirmation].status] ? statuses[autoActions[showConfirmation].status].label : null}
                            template={templates[autoActions[showConfirmation].template] ? templates[autoActions[showConfirmation].template].name : null}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Confirmation> : ''}
            </div>
          );
        }}
      />
    </Section>
  );
}


const mapStateToProps = (state) => ({
  statuses: state.preclearance.statuses,
  templates: state.preclearance.templates,
  autoActions: excludingId(state.preclearance.autoActions, state.preclearance.table.autoResponseAction),
  disableSendEmail: disableSendEmailPermissions(state.user),
});

export default connect(
  mapStateToProps,
  {
    createAutoAction,
    editAutoAction,
    removeAutoAction,
  })(StatusChangeActions);

StatusChangeActions.propTypes = {
  statuses: PropTypes.object.isRequired,
  templates: PropTypes.object.isRequired,
  autoActions: PropTypes.object.isRequired,
  createAutoAction: PropTypes.func.isRequired,
  editAutoAction: PropTypes.func.isRequired,
  removeAutoAction: PropTypes.func.isRequired,
  disableSendEmail: PropTypes.bool.isRequired,
};

