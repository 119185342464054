import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Section from 'components/shared/settingsPage/section';
import SimpleForm from 'components/shared/forms/simpleForm';
import { createOrgUser, updateOrgUser } from 'reducers/organization/actions';

function BaseUserForm({
  header,
  defaultState,
  submitFunc,
  onCancel,
  fieldMap,
}) {
  return (
    <Section
      header={header}
    >
      <SimpleForm
        submitOnEnter
        fieldMap={fieldMap}
        onSubmit={submitFunc}
        onCancel={onCancel}
        defaultState={defaultState}
        confirmText={'save'}
        cancelText={'cancel'}
        genericError={'There was a problem saving this user.'}
        successToast={'User saved successfully.'}
      />
    </Section>
  );
}

BaseUserForm.propTypes = {
  submitFunc: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fieldMap: PropTypes.object.isRequired,
  defaultState: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
};

BaseUserForm.defaultProps = {
  first_name: '',
  last_name: '',
};


const sharedUserFieldMap = {
  first_name: {
    label: 'first name',
    type: 'text',
  },
  last_name: {
    label: 'last name',
    type: 'text',
  },
  email: {
    label: 'email address',
    type: 'text',
    required: true,
    info: 'Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.',
  },
  is_manager: {
    inputClass: 'inline',
    label: 'manager',
    type: 'checkbox',
    info: 'User is allowed to access organization administration and billing',
  },
};

const editUserFieldMap = {
  ...sharedUserFieldMap,
  is_active: {
    inputClass: 'inline',
    label: 'active',
    type: 'checkbox',
    info: 'Designates whether this user should be treated as active. Unselect this instead of deleting accounts.',
  },
};

const newUserFieldMap = {
  ...sharedUserFieldMap,
  password: {
    label: 'password',
    type: 'password',
  },
  password_confirmation: {
    label: 'password confirmation',
    type: 'password',
    info: 'Enter the same password as before, for verification.',
  },
};

const editFormFields = Object.keys(editUserFieldMap);

const defaultUser = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_confirmation: '',
  is_manager: false,
};

function NewForm({ createUser, submitCb, onCancel }) {
  return (
    <BaseUserForm
      defaultState={defaultUser}
      header={'Add User'}
      fieldMap={newUserFieldMap}
      submitFunc={user => createUser(user).then(submitCb)}
      onCancel={onCancel}
    />
  );
}

NewForm.propTypes = {
  createUser: PropTypes.func.isRequired,
  submitCb: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function EditForm({ user, updateUser, submitCb, onCancel }) {
  const defaultState = editFormFields.reduce((editUser, field) => {
    editUser[field] = user[field];
    return editUser;
  }, {});
  return (
    <BaseUserForm
      defaultState={defaultState}
      header={'Edit User'}
      fieldMap={editUserFieldMap}
      submitFunc={editedUser => updateUser({ ...user, ...editedUser }).then(submitCb)}
      onCancel={onCancel}
    />);
}

EditForm.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  submitCb: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export const NewUserForm = connect(null, { createUser: createOrgUser })(NewForm);
export const EditUserForm = connect(null, { updateUser: updateOrgUser })(EditForm);
