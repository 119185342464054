import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EditItem extends Component {
  constructor(props) {
    super(props);
    this.onEnterKey = this.onEnterKey.bind(this);
  }

  componentDidMount() {
    this.inputRef.focus();
    // this makes the cursor go to the end of the input
    const { value } = this.inputRef;
    this.inputRef.value = '';
    this.inputRef.value = value;
  }

  onEnterKey(e) {
    if (this.props.confirmationShowing) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      this.props.onSubmit();
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      this.props.onClose();
    }
  }


  render() {
    const {
      value, buttonText, showDelete, errors, onClose, onSubmit, onDelete,
      onFieldChange, extraChildren, disableSubmit,
    } = this.props;
    return (
      <div className="table-row">
        <div className="table-cell editing">
          <input
            ref={(el) => { this.inputRef = el; }}
            className={errors ? 'error' : ''}
            type="text"
            value={value}
            onChange={(e) => { onFieldChange(e); }}
            onKeyDown={(e) => { this.onEnterKey(e); }}
          />
          <div className="error-text">
            {errors}
          </div>
        </div>
        {extraChildren}
        <div className="text-center table-cell">
          <button
            type="button"
            className="orange-button"
            disabled={disableSubmit}
            onClick={() => { onSubmit(); }}
          >
            {buttonText}
          </button>
          <span className="extra-buttons">
            {showDelete
              ? (
                <button
                  type="button"
                  className="delete text-button"
                  onClick={onDelete}
                  aria-label="delete"
                >
                  <i className="icon-trash" />
                </button>
              )
              : ''}
            <button
              type="button"
              className="text-button"
              onClick={() => { onClose(); }}
            >
              <i className="material-icons">close</i>
            </button>
          </span>
        </div>
        { this.props.extraCells }
      </div>
    );
  }
}

EditItem.propTypes = {
  value: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  showDelete: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onFieldChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
  confirmationShowing: PropTypes.bool,
  extraChildren: PropTypes.object,
  extraCells: PropTypes.object,
  disableSubmit: PropTypes.bool,
};

EditItem.defaultProps = {
  showDelete: false,
  onDelete: () => { },
  errors: '',
  confirmationShowing: false,
  extraChildren: null,
  extraCells: null,
  disableSubmit: false,
};

export default EditItem;
