import React, { Component } from 'react';
import Triangle from '../../triangle';


const INCUMBENT_MAP = {
  null: 'N/A',
  undefined: 'N/A',
  '': 'N/A',
  false: 'No',
  no: 'No',
  n: 'No',
  true: 'Yes',
  yes: 'Yes',
  y: 'Yes',
};

const getIncumbentVal = val => INCUMBENT_MAP[String(val).toLowerCase()];

export default class CandidateTooltip extends Component {

  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      trianglePos: 'top',
    };

    this.onDocumentScroll = this.onDocumentScroll.bind(this);
    window.addEventListener('scroll', this.onDocumentScroll);

    this.scrollPos = window.scrollY || window.pageYOffset;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onDocumentScroll);
  }

  onDocumentScroll(e) {
    const nextScrollPos = window.scrollY || window.pageYOffset;
    this.setState({
      top: this.state.top - (nextScrollPos - this.scrollPos),
    });
    this.scrollPos = nextScrollPos;
  }

  onMouseEnter(e) {
    this.setState({
      backupRecipient: { ...this.state.recipient },
    });
  }

  onMouseLeave() {
    this.setState({
      backupRecipient: null,
    });
  }

  activateTooltip(recipient, positionBox, threshold) {
    const { top, left, width } = positionBox;
    const bboxHeight = positionBox.height;
    const leftOffset = width / 2;
    threshold = threshold || 200;
    const isIE = navigator.userAgent.indexOf('Trident') >= 0;
    if (top < threshold) {
      this.setState({
        trianglePos: 'bottom',
        recipient,
      }, () => {
        const { height, width } = document.querySelector('.candidate-tooltip').getBoundingClientRect();
        this.setState({
          top: top + height + (bboxHeight * 1.2) - (isIE ? (height + 10) : 0) + 10,
          left: left + leftOffset - (document.querySelector('.candidate-tooltip').clientWidth / 2),
        });
      })
    }
    else {
      this.setState({
        top,
        trianglePos: 'top',
        recipient,
      }, () => {
        const { height } = document.querySelector('.candidate-tooltip').getBoundingClientRect();
        this.setState({
          left: left + leftOffset - (document.querySelector('.candidate-tooltip').clientWidth / 2),
          top: this.state.top - (isIE ? (height + 10) : 0),
        });
      });
    }
  }

  deactivateTooltip() {
    this.setState({
      recipient: null,
    });
  }

  render() {
    const primaryFields = [
      'candidate',
      'committee',
      'jurisdiction',
      'office',
      'party',
    ];
    const recipient = this.state.recipient || this.state.backupRecipient || {};
    const hasRecipient = !!Object.values(recipient).length;
    if (!hasRecipient) return null;
    const incumbentField = !recipient.candidate ? 'N/A' : getIncumbentVal(recipient.is_incumbent);
    return (
      <div
        className="candidate-tooltip"
        style={{
          top: this.state.top,
          left: this.state.left,
        }}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <div className="candidate-header">
          Recipient Details
        </div>
        <div className="candidate-content">
          {
            primaryFields.map(field => (
              <div key={field} className="candidate-row">
                <span className="candidate-row-label">{ field === 'office' ? 'Office Seeking' : field[0].toUpperCase() + field.slice(1) }</span>
                <span className="candidate-row-value">{ recipient[field] || 'N/A' }</span>
              </div>
            ))
          }
          <div className="candidate-row spacer">
            <div className="candidate-row">
              <span className="candidate-row-label">YEAR</span>
              <span className="candidate-row-value">{ recipient.election_year || 'N/A' }</span>
            </div>
            <div className="candidate-row">
              <span className="candidate-row-label">INCUMBENT</span>
              <span className="candidate-row-value">{ incumbentField }</span>
            </div>
          </div>
        </div>
        <div className={`${this.state.trianglePos} candidate-triangle`}>
          <Triangle position={this.state.trianglePos} color={this.state.trianglePos == 'bottom' ? '#EDF0F5' : '#FFFFFF'} />
        </div>
      </div>
    );
  }
}