import React from 'react';
import PropTypes from 'prop-types';


export default function SettingsTable({ leftColumn, rightColumns, children, wrapperClass }) {
  return (
    <div className={`table-wrapper ${wrapperClass}`} role="table">
      <div className="table-container">
        <div>
          <div className="table-row header">
            <div className="table-cell">
              <span>{leftColumn}</span>
            </div>
            {rightColumns.map(rightColumn => (
              <div key={rightColumn} className="text-center table-cell">
                <span>{rightColumn}</span>
              </div>
            ))}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}


SettingsTable.propTypes = {
  wrapperClass: PropTypes.string,
  leftColumn: PropTypes.string.isRequired,
  rightColumns: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

SettingsTable.defaultProps = {
  wrapperClass: '',
};
