import React, { useEffect } from 'react';
import { useField, useFormikContext } from 'formik';

import { Individuals, Tags } from './components';
import { RadioField } from '../components';
import { RECIPIENT_TYPES, ALL, TAGS, LIST } from '../../../constants';


const RecipientForm = () => {
  const [recipientType] = useField('employee_selector');
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const { value } = recipientType;
    switch (value) {
      case ALL:
        setFieldValue('tag_list', []);
        setFieldValue('employee_list', []);
        break;
      case TAGS:
        setFieldValue('employee_list', []);
        break;
      case LIST:
        setFieldValue('tag_list', []);
        break;
      default:
        return null;
    }
  }, [recipientType.value]);

  const renderRecipientList = () => {
    switch (recipientType.value) {
      case ALL:
        return <div />;
      case TAGS:
        return <Tags fieldName="tag_list" />;
      case LIST:
        return <Individuals fieldName="employee_list" />;
      default:
        return null;
    }
  };

  return (
    <div className="create-cert-form">
      <h3>Select Recipients</h3>
      <div>
        <RadioField
          choices={RECIPIENT_TYPES}
          label=""
          name="employee_selector"
        />
        { renderRecipientList() }
      </div>
    </div>
  );
};

export { RecipientForm };
