import moment from 'moment';
import * as Yup from 'yup';
import { dateError } from 'utils/errorHelpers';
import { acceptableDateFormats } from '../cellValidator';


const validateDate = (_value, originalValue) => {
  const date = moment(originalValue, acceptableDateFormats, true);
  return date.isValid() ? date.toDate() : date.isValid();
};


export const textFilterValidation = Yup.object().shape({
  filter: Yup.string()
    .trim()
    .required('Please enter a filter.'),
});


export const boolFilterValidation = Yup.object().shape({
  filter: Yup.boolean(),
});


export const dateFilterValidation = Yup.object().shape({
  filter: Yup.object().shape({
    from: Yup.date()
      .when('to', {
        is: (to) => !to,
        then: Yup.date()
          .transform(validateDate)
          .typeError(dateError)
          .required('At least one date is required.'),
      }),
    to: Yup.date()
      .when('from', {
        is: (from) => !from,
        then: Yup.date()
          .transform(validateDate)
          .typeError(dateError)
          .required('At least one date is required.'),
      }),
  },
  [
    ['from', 'to'],
    ['to', 'from'],
  ]),
});


export const amountFilterValidation = Yup.object().shape({
  filter: Yup.object().shape({
    from: Yup.number()
      .when('to', {
        is: (to) => !to,
        then: Yup.number()
          .typeError('Please enter a valid amount.')
          .required('At least one amount is required.'),
      }),
    to: Yup.number()
      .when('from', {
        is: (from) => !from,
        then: Yup.number()
          .typeError('Please enter a valid amount.')
          .required('At least one amount is required.'),
      }),
  },
  [
    ['from', 'to'],
    ['to', 'from'],
  ]),
});
