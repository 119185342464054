import React from 'react';
import PropTypes from 'prop-types';

// types to vals
export const valueNormalizers = {
  textarea: input => input.value,
  text: input => input.value,
  password: input => input.value,
  checkbox: input => input.checked,
};

function Errors({ errors }) {
  if (errors instanceof Array) {
    return (
      <React.Fragment>
        {errors.map((e, i) => <p key={i} className="error-message">{e}</p>)}
      </React.Fragment>
    );
  } else if (errors instanceof Object) {
    return (
      <React.Fragment>
        {Object.values(errors).map((e, i) => <p key={i} className="error-message">{e}</p>)}
      </React.Fragment>
    );
  }
  return <p className="error-message">{errors}</p>;
}

Errors.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

function Input({ value, label, placeholder, onChange, info, error, textArea, type, inputClass,
  onKeyDown }) {
  return (
    <div className={`input-wrapper ${inputClass}`}>
      {error && <Errors errors={error} />}
      <label htmlFor={label}>
        {type !== 'hidden' && label}
      </label>
      {textArea ?
        <textarea
          id={label}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        :
        <input
          type={type}
          id={label}
          value={value}
          checked={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          autoComplete={'off'} // todo
        />
      }
      {info && <p>{info}</p>}
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onChange: PropTypes.func.isRequired,
  textArea: PropTypes.bool,
  inputClass: PropTypes.string,
  onKeyDown: PropTypes.func,
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  info: '',
  error: '',
  inputClass: '',
  type: 'text',
  focusable: true,
  textArea: false,
  onKeyDown: () => {},
};

export default Input;
