import * as types from './types';
import * as contributionTypes from '../contributions/types';
import * as resultTypes from '../results/types';

const initState = {};

export default (state = initState, action) => {
  const nextState = { ...state };
  switch (action.type) {
    case types.ADD_UPLOAD: {
      const appendableObj = {
        name: action.name,
        uploadObj: action.uploader,
        progress: 0,
      };
      if (!nextState[action.contribId]) {
        nextState[action.contribId] = [appendableObj];
      } else {
        nextState[action.contribId] = [...nextState[action.contribId], appendableObj];
      }
      return nextState;
    }
    case types.REMOVE_UPLOAD: {
      const fileList = nextState[action.contribId] || [];
      nextState[action.contribId] = fileList.filter(file => file.name !== action.fileName);
      return nextState;
    }
    case types.UPDATE_PROGRESS: {
      const fileList = nextState[action.contribId] || [];
      const matchingFileIndex = fileList.findIndex(({ name }) => name === action.fileName);
      nextState[action.contribId] = fileList.slice();
      const fields = ['progress', 'hasError'];
      fields.forEach((field) => {
        nextState[action.contribId][matchingFileIndex][field] = action[field];
      });
      return nextState;
    }
    case types.POPULATE_INITIAL: {
      const { files, contribId } = action;
      const nextState = { ...state };
      if (!files.length) {
        return state;
      }
      nextState[contribId] = files.map(file => ({ hasError: false, progress: 100, name: file }));
      return nextState;
    }
    case contributionTypes.APPEND_CONTRIBUTIONS: {
      const nextState = { ...state };
      action.contributions.filter(contrib => contrib.meta.files.length > 0).forEach((contrib) => {
        nextState[contrib.meta.id] = contrib.meta.files.map(file => ({ progress: 100, hasError: false, name: file }));
      });
      return nextState;
    }
    case contributionTypes.SET_CONTRIBUTIONS: {
      const nextState = { ...state };
      action.contributions.filter(contrib => contrib.meta.files.length > 0).forEach((contrib) => {
        nextState[contrib.meta.id] = contrib.meta.files.map(file => ({ progress: 100, hasError: false, name: file }));
      });
      return nextState;
    }
    case resultTypes.LOAD_RESULTS: {
      const nextState = { ...state };
      action.results.forEach((result) => {
        if (!result.data) {
          return;
        }
        result.data.filter(record => record.meta && record.meta.contribution_files && record.meta.contribution_files.length > 0).forEach((record) => {
          nextState[record.meta.contribution_id] = record.meta.contribution_files.map(file => ({ name: file, hasError: false, progress: 100 }));
        });
      });
      return nextState;
    }
    // todo we removed notes / uploads from jurisdiction view so we don't need this anymore but we might want to add back in the future, tho this will need to be properly updated
    // case resultTypes.LOAD_JURISDICTION_RESULTS: {
    //   const nextState = {...state};
    //   action.results.forEach(result => {
    //     result.results.forEach(resObj => {
    //       resObj.data.forEach(record => {
    //         if(!record.meta.contribution_files.length) {
    //           return;
    //         }
    //         nextState[record.meta.contribution_id] = record.meta.contribution_files.map(file => ({name: file, hasError: false, progress: 100}));
    //       });
    //     });
    //   });
    // return nextState;
    // }
    default: {
      return state;
    }
  }
};
