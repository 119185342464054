import React, { Component } from 'react';
import PropTypes from 'prop-types';


class AbstractDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.openDropdown = this.openDropdown.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.onEscKey = this.onEscKey.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    document.removeEventListener('keydown', this.onEscKey);
  }

  onEscKey(e) {
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.closeDropdown();
    }
  }

  openDropdown(e) {
    e.preventDefault();
    this.setState({ expanded: true });
    document.addEventListener('click', this.handleOutsideClick, false);
    document.addEventListener('keydown', this.onEscKey);
  }

  closeDropdown() {
    document.removeEventListener('click', this.handleOutsideClick, false);
    this.setState({ expanded: false });
  }


  handleOutsideClick(e) {
    if (this.dropdownEl && this.dropdownEl.contains(e.target)) {
      return;
    }
    this.closeDropdown();
  }

  render() {
    return (
      <div className="dropdown">
        <div className="drop-wrapper">
          <button disabled={this.props.disabled} className={this.state.expanded ? 'dropdownButton text-button open' : 'dropdownButton text-button'} onClick={this.openDropdown}>
            <span className="dropdown-text">{ this.props.dropdownText }</span>
            <i className="material-icons">
              {this.state.expanded ? 'expand_less' : 'expand_more'}
            </i>
          </button>
        </div>
        { this.state.expanded ?
          <div
            className="dropdown-container"
            ref={(el) => { this.dropdownEl = el; }}
            style={{ height: this.props.height }}
          >
            { this.props.children }
          </div> : ''
        }
      </div>
    );
  }
}

// adding filler props to appease propTypes


AbstractDropdown.propTypes = {
  dropdownText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  height: PropTypes.string,
  disabled: PropTypes.bool,
};

AbstractDropdown.defaultProps = {
  height: 'auto',
  disabled: false,
  render: null,
  dropdownText: '',
};

export default AbstractDropdown;
