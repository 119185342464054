/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { AutoResponseSentence } from './actionSentence';
import { BaseItem, BaseEditItem } from './baseItem';
import ActionOptions from './actionOptions';


export const AutoResponseEditItem = ({
  action, templates, buttonText, onSubmit, onClose, onFieldChange, errors,
}) => {
  return (
    <BaseEditItem
      buttonText={buttonText}
      onSubmit={onSubmit}
      onClose={onClose}
      errors={errors}
    >
      <AutoResponseSentence
        template={(
          <ActionOptions
            options={Object.values(templates)}
            dropdownText={templates[action.template] ? templates[action.template].name : 'Select template'}
            onDropdownSelect={(o) => { onFieldChange(o.id, 'template'); }}
            field={'name'}
            disabled={false}
          />
      )}
      />
    </BaseEditItem>
  );
};

AutoResponseEditItem.propTypes = {
  action: PropTypes.object.isRequired,
  templates: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
};

AutoResponseEditItem.defaultProps = {
  errors: '',
};


export const AutoResponseItem = ({
  templates, action, toggleEditing, onToggleActive, disabled, disabledMessage,
}) => {
  const { active, template } = action;
  const missingItem = !templates[template] ? 'template' : null;
  const isDisabled = Boolean(missingItem) || disabled;
  const disabledTooltip = disabled ? disabledMessage : `The ${missingItem} associated with this action has been removed, please select a new ${missingItem} to reenable.`;

  return (
    <BaseItem
      tooltip={isDisabled ? disabledTooltip : ''}
      disabled={isDisabled}
      active={active}
      onToggleActive={() => { onToggleActive(action); }}
      onToggleEdit={() => { toggleEditing(action); }}
      allowDelete={false}
    >
      <AutoResponseSentence
        template={templates[template] ? templates[template].name : ''}
      />
    </BaseItem>
  );
};


AutoResponseItem.propTypes = {
  templates: PropTypes.object.isRequired,
  action: PropTypes.object.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  onToggleActive: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
};

AutoResponseItem.defaultProps = {
  disabled: false,
  disabledMessage: '',
};
