import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { byId } from 'reducers/entities/selectors';
import { closeModal } from 'reducers/ui/actions';
import { deleteEntity } from 'reducers/entities/actions';
import { BASE_MONITORING_URL } from 'utils/constants';
import DeleteConfirmation from '../deleteConfirmation';


const mapStateToProps = (state, props) => {
  const { match: { params } } = props;
  const uiSearchType = params.searchType;
  const selected = byId(state, params.id) || {};
  const { entity_name: entityName } = selected;
  return {
    deleteFuncArgs: [selected.id, selected.search_object_id],
    uiSearchType,
    name: entityName,
    redirectUrl: `/app/${BASE_MONITORING_URL}/${uiSearchType}`,
  };
};

export default withRouter(connect(mapStateToProps, {
  closeModal,
  deleteFunc: deleteEntity,
})(DeleteConfirmation));
