import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import api from 'api';
import { PATHS, PATH_NAMES } from 'utils/constants';
import Loading from 'components/loading';
import { getPlatform } from 'reducers/platforms/selectors';
import HiddenForm from './HiddenForm';


// doing it this way to avoid a blocked popup error from the browser
const PlatformAccess = ({
  platformID,
  platformClientID,
  platformUserID,
  platformUrl,
  platformName,
}) => {
  const [loginData, setLoginData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    let didCancel = false;
    const getToken = () => {
      api.post('/integrations/saml_token/', {
        target_platform_id: platformID,
        platform_client_id: platformClientID,
        platform_user_id: platformUserID,
        destination_url: platformUrl,
      }).then(({ data }) => {
        if (!didCancel) {
          setLoginData(data);
        }
      }).catch((err) => {
        toast('Something went wrong.');
        throw err;
      });
    };

    if (!platformUrl) {
      history.push(`/app/${PATHS[PATH_NAMES.not_found]}`);
    } else {
      getToken();
      toast(`Logging into ${platformName}`);
    }

    return () => {
      didCancel = true;
    };
  }, [platformID, platformClientID, platformUserID, platformUrl, platformName, history]);

  return (
    <>
      { loginData
        ? (
          <HiddenForm
            url={`${platformUrl}`}
            data={{
              SAMLResponse: loginData.samlToken,
              RelayState: loginData.relayState,
            }}
          />
        ) : null }
      <div className="flex-load-wrapper">
        <Loading />
      </div>
    </>
  );
};

PlatformAccess.propTypes = {
  platformID: PropTypes.number.isRequired,
  platformClientID: PropTypes.string.isRequired,
  platformUserID: PropTypes.string.isRequired,
  platformUrl: PropTypes.string.isRequired,
  platformName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const { platformId } = props.match.params;
  const platform = getPlatform(state, platformId) || {};
  const {
    platformID,
    platformClientID,
    platformUserID,
    platformURL: platformUrl,
    platformName,
  } = platform;
  return {
    platformID,
    platformClientID,
    platformUserID,
    platformUrl,
    platformName,
  };
};

export default connect(
  mapStateToProps,
  {},
)(PlatformAccess);
