import * as Yup from 'yup';
import { requiredError, minLengthError, maxLengthError } from 'utils/errorHelpers';


export const fields = {
  id: 'id',
  firstName: 'first_name',
  lastName: 'last_name',
  strictLastName: 'strict_last_name',
  altFirsts: 'alt_firsts',
  altLasts: 'alt_lasts',
  zipcodes: 'zips',
  states: 'states',
  employers: 'employers',
  sources: 'sources',
  excludedAddresses: 'excluded_addresses',
  excludedEmployers: 'excluded_employers',
  excludedOccupations: 'excluded_occupations',
};

export const {
  firstName,
  lastName,
  strictLastName,
  altFirsts,
  altLasts,
  zipcodes,
  states,
  employers,
  sources,
  excludedAddresses,
  excludedEmployers,
  excludedOccupations,
} = fields;


export const defaultSearchState = (sourceIds) => ({
  [firstName]: '',
  [lastName]: '',
  [strictLastName]: false,
  [altFirsts]: [''],
  [altLasts]: [''],
  [zipcodes]: [''],
  [employers]: [''],
  [sources]: new Set(sourceIds),
  [states]: [],
  [excludedAddresses]: [''],
  [excludedEmployers]: [''],
  [excludedOccupations]: [''],
});

export const geoFiltersHeading = 'These filters are applied to the contributor address field. When searching common names, it is not required but is recommended that you add states (U.S.) or postal codes (U.S. and foreign) to narrow search results. In cases where state and zip code filters overlap the more specific filter (zip code) will be applied.';
export const addressFieldToolTip = 'This will filter out records that contain any of the words you enter below within the address field. This can be helpful if you would like to exclude records related to someone with the same name living at a different address. You may enter any part of an address including street name, house number or postal code.';
export const employerFiltersHeading = 'The employer filter will only return records where the applied term is in the employer field. The exclude employer and exclude occupation filters will filter out records where the applied term is included in the respective field.';
export const excludeEmployerToolTip = 'This will filter out records that contain any of the words you enter below within the employer field. When searching common names, this can be helpful if you would like to exclude records from someone with the same name but working for a different employer.';
export const excludeOccupationToolTip = 'This will filter out records that contain any of the words you enter below within the occupation field.';
export const employerHelpText = 'Note: this is a strict filter and will exclude any records that do not include a matching term in the employer field';
export const disabledNameTooltip = 'Name may be changed by editing the employee.';


export const validationSchema = Yup.object().shape({
  [firstName]: Yup.string()
    .trim()
    .min(2, minLengthError(2))
    .max(100, maxLengthError(100))
    .required(requiredError),
  [lastName]: Yup.string()
    .trim()
    .min(2, minLengthError(2))
    .max(100, maxLengthError(100))
    .required(requiredError),
  [altFirsts]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .min(2, minLengthError(2))
        .max(100, maxLengthError(100)),
    ),
  [altLasts]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .min(2, minLengthError(2))
        .max(100, maxLengthError(100)),
    ),
  [zipcodes]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .max(11, maxLengthError(11)),
    ),
  [employers]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .min(2, minLengthError(2)),
    ),
  [excludedEmployers]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .min(2, minLengthError(2)),
    ),
  [excludedOccupations]: Yup.array()
    .of(
      Yup.string()
        .trim()
        .max(255, maxLengthError(255)),
    ),
});
