import React from 'react';
import PropTypes from 'prop-types';
import TagItem from './tagItem';

function AppliedTags({ tags, selectedTags, setTags, removable }) {
  return (
    <ul className="tag-container">
      {
        selectedTags.map((tagId) => {
          const tag = tags[tagId];
          return tag && (
            <TagItem
              key={tag.id}
              tagName={tag.name}
              onRemove={setTags(tag)}
              removable={removable}
            />
          );
        })
      }
    </ul>
  );
}

AppliedTags.propTypes = {
  tags: PropTypes.object.isRequired,
  selectedTags: PropTypes.array.isRequired,
  setTags: PropTypes.func,
  removable: PropTypes.bool,
};

AppliedTags.defaultProps = {
  setTags: () => {},
  removable: true,
  selectedTags: [],
};

export default AppliedTags;

