import * as types from '../types';

const initState = {};

const matchActions = (state = initState, action) => {
  switch (action.type) {
    case types.RECEIVE_INITIAL_SETTINGS_DATA: {
      return action.autoMatchActions;
    }
    case types.LOAD_MATCH_ACTIONS: {
      const matchActionState = {};
      action.data.reduce((_allActions, matchAction) => {
        matchActionState[matchAction.id] = matchAction;
        return matchActionState;
      }, {});
      return matchActionState;
    }
    case types.ADD_MATCH_ACTION: {
      const nextState = { ...state };
      nextState[action.data.id] = action.data;
      return nextState;
    }
    case types.UPDATE_MATCH_ACTION: {
      const nextState = { ...state };
      nextState[action.data.id] = action.data;
      return nextState;
    }
    case types.DELETE_MATCH_ACTION: {
      const nextState = { ...state };
      delete nextState[action.id];
      return nextState;
    }
    default: return state;
  }
};

export default matchActions;
