/* eslint-disable camelcase */
import React from 'react';
import api from 'api';
import { RequestTrackerCanceler } from 'utils/apiUtil';
import { DropdownOption } from 'components/shared/objectDropdown';
import SearchableDropdown from './searchableDropdown';


export function createCandidateText({ candidate, committee, recipient }) {
  const formattedCommittee =
        committee && candidate ? `[${committee}]` : committee;
  return `${candidate} ${formattedCommittee} ${recipient}`.trim();
}


export function createCandidateDetails({
  office,
  election_year,
  jurisdiction,
}) {
  if (office || election_year || jurisdiction) {
    return (
      <span className="extra-ccd-info">
        {' '}
        &mdash; {office} {election_year || ''}{' '}
        {jurisdiction ? `(${jurisdiction})` : ''}
      </span>
    );
  }
  return '';
}

const cancelToken = 'CANDIDATE_SUGGESTION';

const getCandidateSuggestions = (search) => {
  RequestTrackerCanceler.cancelByRequestId(cancelToken);
  return api.get(`/ccd_suggest/?query=${encodeURIComponent(search)}`, {
    cancelToken: RequestTrackerCanceler.getCancelToken(cancelToken),
  });
};


function renderOptions(options, selectOption) {
  return (
    options.map((option) => {
      const { candidate, committee, recipient } = option;
      const candidateText = createCandidateText(option);
      const candidateDetails = createCandidateDetails(option);
      return (
        <DropdownOption
          key={`${option.id}`}
          value={option.id}
          onClick={(e) => {
            e.preventDefault();
            const selectVal = `${candidate} ${committee && candidate ? '' : committee
            } ${recipient}`.trim();
            selectOption(selectVal);
          }}
        >
          <React.Fragment>
            <span>{candidateText}</span>
            {candidateDetails}
          </React.Fragment>
        </DropdownOption>
      );
    })
  );
}


function CandidateDropdown(props) {
  return (
    <SearchableDropdown
      {...props}
      cancelToken={cancelToken}
      searchFunc={getCandidateSuggestions}
      renderOptions={renderOptions}
    />
  );
}

export default CandidateDropdown;
