import * as types from './types';
import api from '../../api';

const setSources = data => ({
  sources: data,
  type: types.SET_SOURCES,
});

export const loadSources = () => dispatch => api.get('/sources/')
  .then(({ data }) => {
    dispatch(setSources(data));
    return data;
  });
