import * as types from './types';

const initialState = [];

const macros = (state = initialState, action) => {
  switch (action.type) {
    case types.RECEIVE_INITIAL_SETTINGS_DATA: {
      return action.macros;
    }
    default: {
      return state;
    }
  }
};

export default macros;
