import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'api';
import Section from 'components/shared/settingsPage/section';
import Loading from 'components/loading';
import ArchiveDelayForm from './ArchiveDelayForm';


const noDelayOption = {
  id: null,
  name: 'No delay',
};

const makeOptions = (data) => {
  const options = data.map(({ id, label }) => ({
    id,
    name: label,
  }));
  return [noDelayOption, ...options];
};


const ArchiveSettings = () => {
  const [loading, setLoading] = useState(true);
  const [delayOptions, setDelayOptions] = useState([]);

  useEffect(() => {
    let didCancel = false;
    api.get('/archive_delay_settings/').then(({ data }) => {
      if (!didCancel) {
        const options = makeOptions(data);
        setDelayOptions(options);
      }
    }).catch((err) => {
      toast('Something went wrong and we were unable to fetch the archive delay options');
      throw err;
    }).finally(() => {
      if (!didCancel) {
        setLoading(false);
      }
    });
    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <Section
      header="Archive Delay"
      helpText={(
        <p>
          There can oftentimes be a delay between when a contribution was made and when it is filed/publicly available and will appear in illumis. Instead of immediately archiving an exited employee, you may want to set an optional delay (we typically suggest 6 months) so that you will still receive contributions that the employee may have made while they were still employed by your firm. Employees who are pending archive will still be monitored for new records until they are archived.
        </p>
      )}
    >
      {
        loading
          ? (
            <div className="load-wrapper">
              <Loading />
            </div>
          )
          : (
            <ArchiveDelayForm
              delayOptions={delayOptions}
            />
          )
      }
    </Section>
  );
};


export default ArchiveSettings;
