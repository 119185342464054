export const LOAD_CURRENT_USER = 'user/LOAD_CURRENT_USER';
export const EDIT_ALERT_EMAILS = 'user/EDIT_ALERT_EMAILS';
export const SET_REMAINING_RESEARCHES = 'user/SET_REMAINING_RESEARCHES';
export const EDIT_DATE_FILTER = 'user/EDIT_DATE_FILTER';
export const EDIT_AUTOMATCH_SETTING = 'user/EDIT_AUTOMATCH_SETTING';

export { ADD_ENTITY, REMOVE_ENTITY } from '../entities/types';
export {
  ADD_EMPLOYEE,
  REMOVE_EMPLOYEE,
  ARCHIVE_EMPLOYEE,
  RESTORE_EMPLOYEE,
} from '../employees/types';
