import * as types from '../types';

const initialState = {};

const autoActions = (state = initialState, action) => {
  switch (action.type) {
    case types.RECEIVE_INITIAL_SETTINGS_DATA: {
      return action.autoActions;
    }
    case types.LOAD_AUTO_ACTIONS: {
      const autoActionState = {};
      action.data.reduce((_allAutoActions, autoAction) => {
        autoActionState[autoAction.id] = autoAction;
        return autoActionState;
      }, {});
      return autoActionState;
    }
    case types.RECEIVE_AUTO_ACTIONS: {
      return action.data.reduce((_allAutoActions, autoAction) => {
        _allAutoActions[autoAction.id] = autoAction;
        return _allAutoActions;
      }, { ...state });
    }
    case types.ADD_AUTO_ACTION: {
      const autoActionsCopy = { ...state };
      autoActionsCopy[action.data.id] = action.data;
      return autoActionsCopy;
    }
    case types.UPDATE_AUTO_ACTION: {
      const autoActionsCopy = { ...state };
      autoActionsCopy[action.data.id] = action.data;
      return autoActionsCopy;
    }
    case types.DELETE_AUTO_ACTION: {
      const autoActionsCopy = { ...state };
      delete autoActionsCopy[action.id];
      return autoActionsCopy;
    }
    default: {
      return state;
    }
  }
};

export default autoActions;
