import React from 'react';
import PropTypes from 'prop-types';
import EditableItem from 'components/shared/editableItem';

// possible types for custom fields
const [LIST, TEXT] = ['List', 'Text'];

function renderListData(val) {
  return val.length ? val.join(', ') : 'N/A';
}

function CustomField({ field, dataType, customData, openField, toggleEditing, errors, disabled,
  onFieldChange, onUpdate }) {
  const editing = openField && Object.keys(openField)[0] === field;
  const val = dataType === LIST ? renderListData(customData[field]) : customData[field];
  return (
    <div className="custom-field-item-wrapper">
      <div className={`${disabled ? 'disabled ' : ''}custom-field-item`}>
        <span className="employee-field-header">
          {field}:
        </span>
        <EditableItem
          editing={editing}
          value={editing ? openField[field] : val}
          toggleEditing={() => toggleEditing({ [field]: customData[field] })}
          onClose={() => toggleEditing(null)}
          onFieldChange={(e) => { onFieldChange(e.target.value, field); }}
          onSubmit={onUpdate}
          errors={errors || ''}
          showDelete={false}
          buttonText={'Save'}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

CustomField.propTypes = {
  field: PropTypes.string.isRequired,
  customData: PropTypes.object.isRequired,
  openField: PropTypes.object,
  errors: PropTypes.object,
  onFieldChange: PropTypes.func.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  dataType: PropTypes.string.isRequired,
};

CustomField.defaultProps = {
  openField: null,
  errors: null,
  disabled: false,
};

export default CustomField;
