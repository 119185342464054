import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePickerWrapper from '../bulkExports/datePickerWrapper';

class ManualReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      startDate: null,
      endDate: null,
      exporting: false,
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onDatesChange({ endDate, startDate }) {
    let newErrors = {};
    if (endDate) {
      newErrors = { ...newErrors, endDate: false };
    }
    if (startDate) {
      newErrors = { ...newErrors, startDate: false };
    }
    this.setState({ errors: { ...this.state.errors, ...newErrors } });
    this.setState({ endDate, startDate });
  }

  handleSubmit() {
    let { endDate, startDate } = this.state;

    let errors = {};
    if (!startDate || !startDate._d) { // eslint-disable-line no-underscore-dangle
      errors = { ...errors, startDate: 'Please select a valid from date' };
    }
    if (!endDate || !endDate._d) { // eslint-disable-line no-underscore-dangle
      errors = { ...errors, endDate: 'Please select a valid to date' };
    }
    if (Object.values(errors).some(err => err)) {
      this.setState({ errors });
      return;
    }

    endDate = moment(endDate, 'llll').format('YYYY-MM-DD');
    startDate = moment(startDate, 'llll').format('YYYY-MM-DD');

    this.setState({ exporting: true });

    this.props.createManualReport({
      date_from: startDate,
      date_to: endDate,
    }).then((record) => {
      this.props.onNewReportGeneration(record.id);
      this.setState({
        error: '',
        errors: {},
        endDate: null,
        startDate: null,
        exporting: false,
      });
    }).catch((err) => {
      const { data, status } = err.response;
      if (status) {
        let errorMessage = data;
        if (typeof data === 'object') {
          if (data) {
            errorMessage = Object.values(data.join(''));
          }
        }
        this.setState({
          error: errorMessage,
          exporting: false,
        });
      }
      throw err;
    });
  }


  render() {
    return (
      <div id="manual-reports">
        <h1>Export Report</h1>
        <div className="form-info">
          {this.props.message}
        </div>
        <DatePickerWrapper
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          startDateLabel={'Start Date'}
          endDateLabel={'End Date'}
          startDatePlaceholder={'mm/dd/yyyy'}
          endDatePlaceholder={'mm/dd/yyyy'}
          errors={this.state.errors}
          onDatesChange={this.onDatesChange}
        />
        <div className="text-center">
          <button
            onClick={this.handleSubmit}
            className="btn btn-primary orange-button"
            disabled={this.state.exporting}
          >
            {this.state.exporting ? <span>Exporting <span className="loader__dot">.</span>
              <span className="loader__dot">.</span>
              <span className="loader__dot">.</span></span> : 'Export'}
          </button>
          <div className="error-text">
            { this.state.error }
          </div>
        </div>
      </div>
    );
  }
}


ManualReports.propTypes = {
  createManualReport: PropTypes.func.isRequired,
  onNewReportGeneration: PropTypes.func.isRequired,
  message: PropTypes.string,
};

ManualReports.defaultProps = {
  message: "This will export an Excel report for records that were detected within the date range specified, this does not correspond to the date on the record but rather when the record was seen within our system. If no new records have entered our system or you did not have any monitoring in place during the specified date range, your report will be blank."
}


export default ManualReports;
