import React from 'react';
import PropTypes from 'prop-types';


const SelectionToggle = ({ selectedVal, choices, setValue }) => (choices.map(({ label, value }) => {
  const selected = selectedVal === value;
  return (
    <button
      key={value}
      type="button"
      onClick={() => { setValue(value); }}
      className={`text-button uppercase ${selected ? ' highlighted' : ''}`}
    >
      {label}
    </button>
  );
})
);

SelectionToggle.propTypes = {
  selectedVal: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default SelectionToggle;
