import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import Dropdown from './Dropdown';


const DependentInput = ({
  fieldName,
  fieldOptions,
  valueOptions,
  dependentValues,
}) => {
  const [_field, meta, { setValue: setPrereqVals }] = useField(`${fieldName}.prerequisite_values`);
  const handleDependentFieldChange = () => {
    setPrereqVals(['']);
  };

  return (
    <div className="conditional-sentence-wrapper">
      <div
        className="conditional-sentence"
      >
        <span>Only show if</span>
        <Dropdown
          name={`${fieldName}.prerequisite_field`}
          options={fieldOptions}
          onChangeCb={handleDependentFieldChange}
        />
        <span>is</span>
        {/* we are only allowing one value via the ui but we can support multiple */}
        { dependentValues.map((value, index) => (
          <Dropdown
            key={`${value}`}
            name={`${fieldName}.prerequisite_values.${index}`}
            options={valueOptions}
          />
        ))}
      </div>
      {meta.error && (
      <span className="error-text">{meta.error}</span>
      )}
    </div>

  );
};

DependentInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldOptions: PropTypes.array.isRequired,
  valueOptions: PropTypes.array.isRequired,
  dependentValues: PropTypes.array.isRequired,
};

export default DependentInput;

