import * as types from '../types';

const initialState = {};

const statuses = (state = initialState, action) => {
  switch (action.type) {
    case types.RECEIVE_INITIAL_SETTINGS_DATA: {
      return action.statuses;
    }
    case types.LOAD_PRE_STATUSES: {
      const statusState = {};
      action.data.reduce((_allStatuses, status) => {
        statusState[status.id] = status;
        return statusState;
      }, {});
      return statusState;
    }
    case types.ADD_PRE_STATUS: {
      const statusCopy = { ...state };
      statusCopy[action.data.id] = action.data;
      return statusCopy;
    }
    case types.UPDATE_PRE_STATUS: {
      const statusCopy = { ...state };
      statusCopy[action.data.id] = action.data;
      return statusCopy;
    }
    case types.DELETE_PRE_STATUS: {
      const statusCopy = { ...state };
      delete statusCopy[action.id];
      return statusCopy;
    }
    default: {
      return state;
    }
  }
};

export default statuses;
