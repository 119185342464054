/* eslint-disable arrow-parens */
import { expireSession } from '../reducers/ui/actions';

const authErrs = ['Authentication credentials were not provided.', 'CSRF Failed: CSRF token missing or incorrect.'];

const thunkMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  if (typeof action === 'function') {
    return action(dispatch, getState).catch((err) => {
      if (err.response) {
        if ((err.response.status === 403 || err.response.status === 401)) {
          const errDetail = err.response.data.detail;
          if (authErrs.includes(errDetail)) {
            dispatch(expireSession());
          } else if (err.response.data === 'Your password has expired. Please save a new password.') {
            dispatch(expireSession());
          }
        }
      }
      throw err;
    });
  }
  return next(action);
};

export default thunkMiddleware;
