import React from 'react';
import PropTypes from 'prop-types';

import { Cell } from './Cell';

const Row = ({ dataKeys, children, renderers }) => {
  return (
    <tr>
      {dataKeys.map(dataKey => (
        <Cell key={dataKey} renderer={renderers[dataKey]}>
          {children[dataKey]}
        </Cell>
      ))}
    </tr>
  );
};

Row.propTypes = {
  dataKeys: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  children: PropTypes.object.isRequired,
  renderers: PropTypes.objectOf(PropTypes.func).isRequired,
};

export { Row };
