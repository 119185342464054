import React from 'react';
import { toast } from 'react-toastify';
import ProgressToast from '../../components/bulkExports/progressToast';

const INITIAL_POLL_FREQUENCY = 3000;


function awaitExport({ id, data, pollExport, updateCount }) {
  const intervalName = `bulkExportInterval${id}`;

  return new Promise((res) => {
    const poll = () => {
      pollExport(id).then(({ data }) => {
        if ((data.ready && data.file_export) || data.errored) {
          clearInterval(window[intervalName]);
          res(data);
        }
        updateCount({ ...data, id });
        const toastId = id;
        const { ready, errored } = data;

        if (toast.isActive(toastId)) {
          toast.update(toastId, {
            render: <ProgressToast id={id} complete={ready} hasError={errored} />,
            autoClose: false,
            className: 'progress-toast',
          });
        }
      });
    };
    if ((data.ready && data.file_export) || data.errored) {
      res(data);
    } else {
      // setup initial polling
      window[intervalName] = setInterval(poll, INITIAL_POLL_FREQUENCY);
    }
  });
}

export default awaitExport;
