import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import { fetchCurrentUser } from 'reducers/user/actions';
import { getOrganization } from 'reducers/organization/actions';
import { fetchStatuses } from 'reducers/statuses/actions';
import { loadSources } from 'reducers/sources/actions';
import { loadJurisdictions } from 'reducers/jurisdictions/actions';
import { loadCategories } from 'reducers/categories/actions';
import { fetchTags } from 'reducers/tags/actions';
import { fetchCustomFields } from 'reducers/customFields/actions';
import { fetchPlatforms } from 'reducers/platforms/actions';
import NavBar from 'components/NavBar/appNav';
import MainApp from 'containers/main';
import NotFound from 'containers/notfound';
import PlatformSettings from 'containers/platformSettings';
import Loading from 'components/loading';
import Reports from 'containers/reports';
import BulkExports from 'containers/bulkExports';
import Dashboard from 'containers/dashboard';
import Preclearance from 'containers/preclearance';
import SessionBoundary from 'components/sessionBoundary';
import Research from 'containers/research';
import PreclearanceSettings from 'components/preclearance/settings/preclearanceSettings';
import Certifications from 'components/Certifications/CertificationsList/Certifications';
import SingleCertification from 'components/Certifications/SingleCertification/SingleCertification';
import CertificationTemplate from 'components/Certifications/SingleCertification/CertificationTemplate';
import { CertificationSettings, CreateCertification, EditCertification } from 'components/Certifications';
import FormBuilder from 'components/FormBuilder/FormBuilder';
import {
  IS_IE_TEN,
  MONITORING_ROUTES,
  BASE_MONITORING_URL,
  DASHBOARD_ROUTE,
  PATHS,
  PATH_NAMES,
} from 'utils/constants';
import AccountSettings from 'containers/accountSettings';
import OrganizationSettings from 'components/settings/organizationSettings/organizationSettings';
import PlatformAccess from 'components/PlatformAccess/PlatformAccess';
import {
  hasPreclearancePermissions,
  hasManageOrgPermissions,
  hasExportPermissions,
  hasCertificationsPermissions,
} from 'helpers/permissions';
import { pendoScript } from './pendo';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    // this stops scroll to record animation if user starts to scroll
    this.onWheel = () => {
      $(document.scrollingElement || document.documentElement).stop();
    };
    document.addEventListener('mousewheel', this.onWheel);
  }

  componentDidMount() {
    this.loadInitialData();
    if (IS_IE_TEN) {
      document.documentElement.classList.add('ie-ten');
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousewheel', this.onWheel);
  }

  loadInitialData() {
    Promise.allSettled([
      this.props.fetchCurrentUser(),
      this.props.getOrganization(),
      this.props.fetchStatuses(),
      this.props.loadSources(),
      this.props.loadCategories(),
      this.props.loadJurisdictions(),
      this.props.fetchTags(),
      this.props.fetchCustomFields(),
      this.props.fetchPlatforms(),
    ]).then(() => {
      this.setState({ loading: false });
      const { user } = this.props;
      const { id, username, organization } = user;
      pendoScript(user);
      Sentry.configureScope((scope) => {
        scope.setUser({
          username,
          userId: id,
          organization,
        });
      });
      if (!user.dont_track && window.FULLSTORY_ENABLED) {
        LogRocket.init('gwtdzo/illumis', {
          network: {
            isEnabled: false,
          },
        });
        LogRocket.identify(id, {
          name: user.full_name,
          email: user.email,
        });
      }
    });
  }

  render() {
    const {
      user,
      showPreclearanceSettings,
      canManageOrg,
      canExport,
      preclearanceSsoEnabled,
      showCertifications,
    } = this.props;
    if (this.state.loading) {
      return (
        <div className="load-screen">
          <Loading />
        </div>
      );
    }

    return (
      <SessionBoundary>
        <div className="app-container">
          <NavBar
            showCertifications={showCertifications}
          />
          <div className="app-body">
            <Switch>
              <Route
                exact
                path="/app"
                component={() => (
                  <Redirect
                    to={`/app/${
                      user.wipe_logs
                        ? BASE_MONITORING_URL
                        : DASHBOARD_ROUTE
                    }`}
                  />
                )}
              />
              <Route
                path={PATHS[PATH_NAMES.preclearance_settings]}
                render={(props) => {
                  if (showPreclearanceSettings) {
                    return <PreclearanceSettings {...props} />;
                  }
                  return <NotFound {...props} />;
                }}
              />
              <Route
                path={PATHS[PATH_NAMES.preclearance]}
                component={Preclearance}
              />
              {/* automatically redirecting in case users had '/all-records' bookmarked */}
              <Route
                exact
                path={`/app/${BASE_MONITORING_URL}`}
                component={() => (
                  <Redirect
                    to={`/app/${BASE_MONITORING_URL}/${MONITORING_ROUTES[0]}`}
                  />
                )}
              />
              <Route
                exact
                path={`/app/${BASE_MONITORING_URL}/:searchType(${MONITORING_ROUTES.join('|')})`}
                component={MainApp}
              />
              <Route
                path={`/app/${BASE_MONITORING_URL}/:searchType(${MONITORING_ROUTES.join('|')})/:id`}
                component={MainApp}
              />
              <Route
                path={PATHS[PATH_NAMES.platform_settings]}
                component={PlatformSettings}
              />
              {canExport && (
                <Route
                  path={PATHS[PATH_NAMES.reports]}
                  component={Reports}
                />
              )}
              {canExport && (
                <Route
                  path={PATHS[PATH_NAMES.bulk_exports]}
                  component={BulkExports}
                />
              )}
              <Route
                path={PATHS[PATH_NAMES.dashboard]}
                component={Dashboard}
              />
              <Route
                path={PATHS[PATH_NAMES.research]}
                component={Research}
              />
              { showCertifications
                && (
                <Route
                  exact
                  path={PATHS[PATH_NAMES.certifications]}
                  component={Certifications}
                />
                )}
              { showCertifications
                && (
                <Route
                  exact
                  path={`${PATHS[PATH_NAMES.certification_templates]}/:id`}
                  component={CertificationTemplate}
                />
                )}
              { showCertifications
                && (
                <Route
                  path={`${PATHS[PATH_NAMES.certification_templates]}/:id/edit`}
                >
                  <EditCertification />
                </Route>
                )}
              {
                preclearanceSsoEnabled && showCertifications
                  && (
                  <Route
                    path={PATHS[PATH_NAMES.create_certification]}
                  >
                    <CreateCertification />
                  </Route>
                  )
              }
              { showCertifications
                && (
                <Route
                  path={PATHS[PATH_NAMES.certification_settings]}
                >
                  <CertificationSettings />
                </Route>
                )}
              { showCertifications
                && (
                <Route
                  path={`${PATHS[PATH_NAMES.certifications]}/:id`}
                  component={SingleCertification}
                  exact
                />
                )}
              { showCertifications
                && (
                <Route
                  path={`${PATHS[PATH_NAMES.certifications]}/:id/submission/:submissionId`}
                  component={SingleCertification}
                />
                )}
              <Route
                path={`${PATHS[PATH_NAMES.forms]}/:id/edit`}
                component={FormBuilder}
              />
              <Route
                path={PATHS[PATH_NAMES.manage_account]}
                component={AccountSettings}
              />
              <Route
                path={PATHS[PATH_NAMES.manage_organization]}
                render={(props) => {
                  if (canManageOrg) {
                    return (
                      <OrganizationSettings {...props} />
                    );
                  }
                  return <NotFound {...props} />;
                }}
              />
              <Route
                path={`${PATHS[PATH_NAMES.platform_access]}/:platformId`}
                component={PlatformAccess}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </div>
          <div id="portal-root" />
        </div>
      </SessionBoundary>
    );
  }
}

const mapStateToProps = ({ user, organization }) => ({
  user,
  canExport: hasExportPermissions(user),
  canManageOrg: hasManageOrgPermissions(user),
  showPreclearanceSettings: hasPreclearancePermissions(user),
  preclearanceSsoEnabled: organization.preclearance_sso_enabled,
  showCertifications: hasCertificationsPermissions(user),
});

export default connect(mapStateToProps, {
  fetchCurrentUser,
  getOrganization,
  fetchStatuses,
  loadSources,
  loadJurisdictions,
  loadCategories,
  fetchTags,
  fetchCustomFields,
  fetchPlatforms,
})(App);

App.propTypes = {
  user: PropTypes.object.isRequired,
  canManageOrg: PropTypes.bool,
  canExport: PropTypes.bool.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  fetchStatuses: PropTypes.func.isRequired,
  loadSources: PropTypes.func.isRequired,
  loadJurisdictions: PropTypes.func.isRequired,
  loadCategories: PropTypes.func.isRequired,
  fetchTags: PropTypes.func.isRequired,
  fetchCustomFields: PropTypes.func.isRequired,
  showPreclearanceSettings: PropTypes.bool.isRequired,
  preclearanceSsoEnabled: PropTypes.bool,
  showCertifications: PropTypes.bool.isRequired,
};

App.defaultProps = {
  canManageOrg: false,
  preclearanceSsoEnabled: false,
};
