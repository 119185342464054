import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Triangle from './triangle';

class ToolTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {},
    };
    this.setPosition = this.setPosition.bind(this);
  }

  setPosition(cellSize, rowSize) {
    if (cellSize && rowSize && this.tooltip) {
      const style = {
        left: `${((cellSize.left - rowSize.left) - ((this.tooltip.getBoundingClientRect().width - cellSize.width) / 2))}px`,
      };
      this.setState({ style });
    }
  }

  render() {
    const { message, heading, position, children } = this.props;
    const style = { ...this.state.style };
    if (this.props._top) {
      // Hacky workaround to force the top position of the tooltip.
      style.top = this.props._top;
    }
    return (
      <div
        aria-hidden
        className={`tooltip ${position}`}
        style={style}
        onClick={(e) => { e.stopPropagation(); }}
        ref={(el) => { this.tooltip = el; }}
      >
        <Triangle position={position} />
        { heading.length > 0 ?
          <div className="heading">
            { heading }
          </div>
          : ''
        }
        { children }
        <div className="message">
          <ul>
            {
              Object.keys(message).map(m =>
                (<li key={m}>
                  <span>
                    {!!m.length && isNaN(m) ? `${m}:` : ''}
                  </span>
                  <span>
                    {message[m]}
                  </span>
                </li>),
              )
            }
          </ul>
        </div>
      </div>
    );
  }
}

ToolTip.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  heading: PropTypes.string,
  position: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};

ToolTip.defaultProps = {
  message: {},
  heading: '',
  position: 'top',
  children: null,
  cellSize: null,
  rowSize: null,
};

export default ToolTip;
