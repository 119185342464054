import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';


const DraggableWrapper = ({
  id,
  index,
  inEditMode,
  openEditMode,
  children,
}) => (
  <Draggable
    draggableId={`${id}`}
    index={index}
  >
    {(provided, snapshot) => (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className={`${snapshot.isDragging ? 'dragging ' : ''} body-question-item ${inEditMode ? 'editing' : ''}`}
        role="button"
        tabIndex={0}
        aria-label="edit-question"
        onClick={openEditMode}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            openEditMode();
          }
        }}
      >
        <div
          className="question-inner"
        >
          <div className="drag-wrapper">
            <i className="material-icons horizontal-drag-icon">
              drag_indicator
            </i>
          </div>
          {children}
        </div>
      </div>
    )}
  </Draggable>
);

DraggableWrapper.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  openEditMode: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default DraggableWrapper;
