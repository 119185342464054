import React from 'react';
import PropTypes from 'prop-types';
import { CustomDropdown } from 'components/dropdown';

export function DropdownOption({ value, children, onClick, selected, disabled }) {
  return (
    <button
      className={`option ${selected ? ' selected' : ''}`}
      value={value}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      {selected ? <i className="material-icons">check</i> : ''}
      <span>{children}</span>
    </button>
  );
}

DropdownOption.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
};

DropdownOption.defaultProps = {
  selected: false,
  disabled: false,
};

export function DropdownButton({ disabled, dropdownText, isOpen, onClick, className }) {
  return (
    <button
      disabled={disabled}
      className={`${isOpen ? 'open ' : ''}dropdownButton text-button ${className}`}
      onClick={onClick}
      type="button"
    >
      <span className="dropdown-text">{ dropdownText }</span>
      <i className="material-icons">
        {isOpen ? 'expand_less' : 'expand_more'}
      </i>
    </button>
  );
}

DropdownButton.propTypes = {
  disabled: PropTypes.bool,
  dropdownText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

DropdownButton.defaultProps = {
  disabled: false,
  dropdownText: '',
  className: '',
};

function ObjectDropdown({
  options, field, selected, height, disabled, dropdownText, handleDropdownSelection,
}) {
  return (
    <CustomDropdown render={({ isOpen, openDropdown, closeDropdown, dropdownRef }) => (
      <div className="dropdown">
        <DropdownButton
          disabled={disabled}
          dropdownText={dropdownText}
          isOpen={isOpen}
          onClick={openDropdown}
        />
        { isOpen ?
          <div
            className="dropdown-container"
            ref={dropdownRef}
            style={{ height }}
          >
            { options.map(o => (
              <DropdownOption
                key={o.id}
                value={o[field]}
                selected={selected && o.id === selected.id}
                onClick={() => { handleDropdownSelection(o); closeDropdown(); }}
              >
                {o[field]}
              </DropdownOption>
            )) } </div> : ''
        }
      </div>
    )}
    />);
}

ObjectDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  field: PropTypes.string.isRequired,
  selected: PropTypes.object,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  dropdownText: PropTypes.string,
  handleDropdownSelection: PropTypes.func.isRequired,
};

ObjectDropdown.defaultProps = {
  selected: null,
  height: 'auto',
  disabled: false,
  dropdownText: '',
};

export default ObjectDropdown;
