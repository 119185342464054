import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { PATHS, PATH_NAMES, CONTACT_EMAIL } from 'utils/constants';
import { updateAutomatchSetting } from 'reducers/user/actions';
import Section from 'components/shared/settingsPage/section';
import ToggleWithLabel from 'components/shared/toggleWithLabel';
import { toast } from 'react-toastify';

function AutomatchSettings({ showAutomatches, updateAutomatchSetting, isManager }) {
  const [submitting, setSubmitting] = useState(false);

  function editAutomatchSetting(enabled) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const enableAutomatches = enabled || null; // BE expects no body when turning off
    updateAutomatchSetting(enableAutomatches).then(() => {
      setSubmitting(false);
    }).catch((err) => {
      let errorMessage = 'Something went wrong.';
      if (err.response && err.response.data && typeof err.response.data === 'string') {
        // to handle error if user has no preclearance requests
        errorMessage = err.response.data;
      }
      setSubmitting(false);
      toast(errorMessage);
      throw (err);
    });
  }

  return (
    <Section
      header="Automated Matching"
      helpText={<p>Enabling automated matching means that we will automatically suggest matching preclearance requests to the monitored records in the platform. You must have at least one preclearance request in the system in order to enable this feature. Please visit the <Link className="plain-link" to={PATHS[PATH_NAMES.preclearance]}>Preclearance</Link> page to add preclearance requests, or email our team to upload bulk preclearance data at <a className="plain-link" href={`mailto:${CONTACT_EMAIL}`} target="_blank" rel="noopener noreferrer">{CONTACT_EMAIL}</a>.</p>}
    >
      <div className="settings-flex-wrapper">
        <ToggleWithLabel
          active={showAutomatches}
          onToggle={() => { editAutomatchSetting(!showAutomatches); }}
          disabled={!isManager || submitting}
          label={'Enable automated matching:'}
          tooltipText={!isManager ? 'Only managers are allowed to change the automated matching setting.' : ''}
        />
      </div>
    </Section>
  );
}

const mapStateToProps = ({ user }) => ({
  isManager: user.is_manager,
  showAutomatches: user.show_automatches,
});


export default connect(
  mapStateToProps,
  { updateAutomatchSetting },
)(AutomatchSettings);

AutomatchSettings.propTypes = {
  showAutomatches: PropTypes.bool.isRequired,
  updateAutomatchSetting: PropTypes.func.isRequired,
  isManager: PropTypes.bool.isRequired,
};
