import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PreclearanceTable } from './PreclearanceTable';


const CertificationPreclearanceRequests = ({
  startDate,
  endDate,
  pcRequests,
  columns,
}) => {
  const dateFormat = 'MMM D, YYYY';
  const certificationPeriod = `${moment(startDate).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`;
  return (
    <div className="preclearance-module">
      <div className="input-title">
        Preclearance requests from{' '}
        {certificationPeriod}
      </div>
      <PreclearanceTable
        data={pcRequests}
        certificationPeriod={certificationPeriod}
        columns={columns}
      />
    </div>
  );
};

CertificationPreclearanceRequests.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  pcRequests: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default CertificationPreclearanceRequests;
