import * as types from '../types';

const defaultState = {
  tableId: '',
  email: '',
  defaultEmail: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.RECEIVE_INITIAL_SETTINGS_DATA:
      return {
        tableId: action.table.id,
        email: action.table.email,
        autoResponseAction: action.table.auto_response_action,
      };
    case types.UPDATE_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    default:
      return state;
  }
};
