import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useCancellablePromise from 'hooks/useCancellablePromise';


const DeleteColumnConfirmation = ({
  column,
  deleteColumn,
  onCancel,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { label, id } = column;
  const { cancellablePromise } = useCancellablePromise();

  const onSubmit = () => {
    setSubmitting(true);
    cancellablePromise(deleteColumn(id)).then(() => {
      setSubmitting(false);
    }).catch((err) => {
      if (!err.isCanceled) {
        setSubmitting(false);
      }
    });
  };

  return (
    <div
      className="preclearance-popover-body"
      role="dialog"
    >
      <div className="header">
        <span>Delete Field</span>
      </div>
      <div className="body">
        <p>
          Are you sure you would like to delete the field &quot;
          {label}
          &quot; and all of the data associated with it?
        </p>
        <div className="buttons">
          <button
            className="text-button uppercase"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="save-button orange-button"
            type="button"
            disabled={submitting}
            onClick={onSubmit}
          >
            delete
          </button>
        </div>
      </div>
    </div>
  );
};


DeleteColumnConfirmation.propTypes = {
  column: PropTypes.object.isRequired,
  deleteColumn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteColumnConfirmation;
