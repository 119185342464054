import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { EMPLOYEE_PATHS, PATH_NAMES } from 'utils/constants';
import EmployeeSearches from './employeeSearches';
import EmployeeDetails from './employeeDetails';
import EmployeeNav from './employeeNav';
import ArchivedBanner from './ArchivedBanner';
import PendingArchiveBanner from './PendingArchiveBanner';
import PendingDeleteBanner from './PendingDeleteBanner';

function EmployeePage({ employee }) {
  const { path } = useRouteMatch();
  const { archived, pending_archival_date: pendingArchiveDate } = employee;
  const { pending_delete_date: pendingDeleteDate } = employee

  return (
    <>
      {
        archived
        && (
        <ArchivedBanner
          archivedDate={employee.archived_date}
          id={employee.id}
        />
        )
      }
      {
        pendingArchiveDate && !archived
        && (
        <PendingArchiveBanner
          pendingArchivalDate={pendingArchiveDate}
          id={employee.id}
        />
        )
      }
      {
        pendingDeleteDate
        && (
        <PendingDeleteBanner
          pendingDeleteDate={pendingDeleteDate}
          id={employee.id}
        />
        )
      }
      <EmployeeDetails employee={employee} />
      <EmployeeNav />
      <Switch>
        <Route
          exact
          path={`${path}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}/:searchId`}
        >
          <EmployeeSearches
            key={employee.id}
            employee={employee}
          />
        </Route>
        <Route
          path={`${path}${EMPLOYEE_PATHS[PATH_NAMES.contributions]}`}
        >
          <EmployeeSearches
            key={employee.id}
            employee={employee}
          />
        </Route>
      </Switch>
    </>
  );
}

EmployeePage.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default EmployeePage;
