import { connect } from 'react-redux';
import moment from 'moment';
import { getRecordMatches } from 'utils/apiUtil';
import { formatDate, acceptableDateFormats } from 'components/preclearance/table/cellValidator';
import { JURISDICTION, STATUS } from 'components/preclearance/table/constants';

import { closeModal } from 'reducers/preclearance/ui/actions';
import { makePreclearanceMatch, markRequestAsNoMatch, undoRequestNoMatch } from 'reducers/matches/actions';
import MatchingWrapper from './matchingWrapper';
import { PRECLEARANCE } from './consts';


// using rowData from table state rather than redux since there can be delay in saving a row and the ux was v. weird

const mapStateToProps = (state) => {
  const { preclearance: { rows, statuses } } = state;
  const preReq = state.preclearance.ui.selectedRow;
  const serializedRecord = Object.keys(preReq.data).reduce((acc, colId) => {
    acc[colId] = preReq.data[colId].value;
    return acc;
  }, {});

  const rowIdx = rows.findIndex(r => r.id === preReq.id);
  const globalDateFilter = state.user.global_date_filter;
  const date = moment(serializedRecord.date, acceptableDateFormats, true).isValid() ? formatDate(serializedRecord.date) : null;
  const { matches, automatches, no_match: noMatch } = rows[rowIdx]; // matches / automatches state is read from redux

  return ({
    recordToBeMatched: preReq,
    serializedRecord: {
      ...serializedRecord,
      jurisdiction: serializedRecord[JURISDICTION],
      [STATUS]: statuses[serializedRecord[STATUS]].label,
    },
    defaultNameFilter: `${serializedRecord.first_name} ${serializedRecord.last_name}`,
    defaultRecipientFilter: serializedRecord.recipient,
    defaultYearFilter: date && !moment(date, 'YYYY-MM-DD').isBefore(globalDateFilter, 'year') ? moment(date, 'YYYY-MM-DD').format('YYYY') : '',
    earliestYearOption: globalDateFilter ? moment(globalDateFilter, 'YYYY-MM-DD').year() : null,
    matches,
    automatches: state.user.show_automatches && automatches ? [...automatches] : [],
    noMatch,
    getPotentialMatches: getRecordMatches,
    viewType: PRECLEARANCE,
    automatchId: preReq.id,
  });
};
export default connect(
  mapStateToProps,
  {
    makeMatch: makePreclearanceMatch,
    markAsNoMatch: markRequestAsNoMatch,
    undoNoMatch: undoRequestNoMatch,
    closeModal,
  },
)(MatchingWrapper);
