import React, { useState } from 'react';
import PropTypes from 'prop-types';

export function useExpanded(startOpen) {
  const [expanded, setExpanded] = useState(startOpen);
  return { expanded, setExpanded };
}

function ExpandableSection({ startOpen, title, children }) {
  const { expanded, setExpanded } = useExpanded(startOpen);
  return (
    <div className={`${expanded ? 'open' : ''} expandable-section`}>
      <div
        className="expandable-header"
        onClick={() => setExpanded(!expanded)}
        role="button"
        tabIndex="0"
      >
        <div className="header-right">
          <span>{title}</span>
        </div>
        <i className="material-icons">
          {expanded ? 'expand_less' : 'expand_more'}
        </i>
      </div>
      {expanded ?
        <div className="expandable-content">
          {children}
        </div>
        : ''}
    </div>
  );
}


ExpandableSection.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  startOpen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
};

ExpandableSection.defaultProps = {
  startOpen: false,
};

export default ExpandableSection;
