import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { SchemaField } from 'components/shared/SchemaForm/SchemaField';
import { FileUpload } from 'components/PreclearanceSurvey/FileUpload';
import { TemplateArea } from 'components/Certifications/CertificationForm/forms/components';
import TemplateConfirmation from 'components/preclearance/settings/changeConfirmation';
import useCancellablePromise from 'hooks/useCancellablePromise';
import { filterBlanks } from 'utils/serializers/searchSerializer';
import Portal from '../portal';
import {
  name,
  subject,
  ccEmails,
  body,
  banner,
  validationSchema,
} from './consts';


export default function EmailTemplateForm({
  creating,
  template,
  macros,
  macroLinks,
  onSave,
  toggleOpen,
  macroLinkHelpText,
  showSubmissionConfirmation,
  renderRelatedData,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { cancellablePromise } = useCancellablePromise();

  const handleFormSubmit = (values, actions) => {
    const payload = values[ccEmails] ? {
      ...values,
      [ccEmails]: filterBlanks(values[ccEmails]),
    } : values;

    cancellablePromise(onSave(payload)).then(() => {
      actions.setSubmitting(false);
      toggleOpen(null);
    }).catch((err) => {
      if (!err.isCanceled) {
        actions.setSubmitting(false);
        toast('Something went wrong.');
      }
    });
  };

  const handleConfirmation = () => {
    setShowConfirmation(true);
  };

  const templateBanner = template[banner] || {};

  const handleUpload = (files, setFieldValue) => {
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFieldValue(banner, {
          data: reader.result.split(',')[1],
          name: file.name,
        });
      };
    } else {
      setFieldValue(banner, {
        data: null,
        name: null,
      });
    }
  };

  return (
    <div className="template-form-container search-form">
      <Formik
        initialValues={template[ccEmails] ? {
          ...template,
          [ccEmails]: template[ccEmails].length ? [...template[ccEmails]] : [''],
        } : { ...template }}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({
          isSubmitting,
          setFieldValue,
          handleSubmit,
          isValid,
          dirty,
        }) => (
          <Form autoComplete="off" noValidate>
            <div>
              <SchemaField
                name={name}
                id={name}
                label="Template Name"
                fieldType="TextField"
                className="form-input"
                required
              />
              {
                template[ccEmails]
                  ? (
                    <SchemaField
                      name={ccEmails}
                      id={ccEmails}
                      label="cc"
                      fieldType="ArrayField"
                      addButtonText="cc email"
                      className="form-input"
                      limit={10}
                      minFields={1}
                    />
                  )
                  : null
              }
              <SchemaField
                name={subject}
                id={subject}
                label="email subject"
                fieldType="TextField"
                className="form-input"
                required
              />
              <TemplateArea
                name={body}
                label="email body"
                variables={macros}
                links={macroLinks}
                macroLinkHelpText={macroLinkHelpText}
              />
              {
                template.banner
                  ? (
                    <ul className="email-template-file-upload">
                      <FileUpload
                        maxSize={2097152}
                        maxItems={1}
                        buttonText="+ add image banner"
                        onUpload={(files) => handleUpload(files, setFieldValue)}
                        initialFiles={templateBanner.name ? [templateBanner] : []}
                        accept="image/*"
                        fileType="image banners"
                        showTooltip
                      />
                      <div
                        className="help-text"
                        style={{ paddingTop: templateBanner.name ? 10 : 3 }}
                      >
                        Upload an image or logo to display at the top of this email template when it is sent.
                        The image will be rendered at the size you upload, we recommend uploading a 200px width x 30px height
                        image for best display.
                      </div>
                    </ul>
                  )
                  : null
                }
              <ul className="buttons">
                <button
                  onClick={() => { toggleOpen(null); }}
                  type="button"
                  className="text-button uppercase"
                >
                  cancel
                </button>
                <button
                  type={showSubmissionConfirmation ? 'button' : 'submit'}
                  onClick={showSubmissionConfirmation ? handleConfirmation : null}
                  disabled={!isValid || !dirty || isSubmitting}
                  className="save-button"
                >
                  {creating ? 'add' : 'save'}
                </button>
              </ul>
              {showConfirmation
                ? (
                  <Portal>
                    <div className="settings">
                      <TemplateConfirmation
                        onSubmit={handleSubmit}
                        onCancel={() => { setShowConfirmation(false); }}
                        confirmText="update"
                        headerText="Update Template"
                        disableConfirm={isSubmitting}
                        confirmSentence={(
                          <span>
                            Are you sure you would like to update this template?
                          </span>
                        )}
                      >
                        { renderRelatedData(template.id, false) }
                      </TemplateConfirmation>
                    </div>
                  </Portal>
                )
                : null}
            </div>
          </Form>
        )}
      </Formik>
    </div>

  );
}

EmailTemplateForm.propTypes = {
  creating: PropTypes.bool,
  template: PropTypes.object.isRequired,
  macros: PropTypes.array.isRequired,
  macroLinks: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  macroLinkHelpText: PropTypes.string,
  renderRelatedData: PropTypes.func,
  showSubmissionConfirmation: PropTypes.bool,
};

EmailTemplateForm.defaultProps = {
  creating: false,
  macroLinks: [],
  macroLinkHelpText: '',
  renderRelatedData: () => {},
  showSubmissionConfirmation: false,
};
