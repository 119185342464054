import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CUSTOM_DATE_RANGE } from '../../utils/constants';


class CustomDateInput extends Component {
  constructor(props) {
    super(props);
    this.onDateInputChange = this.onDateInputChange.bind(this);
  }

  // TODO: input mask auto add /
  onDateInputChange(e, field) {
    const date = e.target.value;
    const momentDate = moment(date, ['MM/DD/YYYY', 'MM/D/YYYY', 'M/D/YYYY', 'M/DD/YYYY'], true);
    if (momentDate.isValid() && this.toInput) {
      this.toInput.focus();
    }
    const newDate = {
      ...this.props.customDate,
      [field]: date,
    };
    this.props.handleFieldChange('customDateInput', newDate);
    this.props.handleSelect('dateFilter', CUSTOM_DATE_RANGE);
  }

  render() {
    const { customDate } = this.props;
    return (
      <div>
        <div>
          <div className="custom-date-wrapper">
            <input
              type="text"
              value={customDate.from}
              placeholder="mm/dd/yyyy"
              onChange={(e) => { this.onDateInputChange(e, 'from'); }}
            />
            <span className="text-center"> to </span>
            <input
              ref={(el) => { this.toInput = el; }}
              type="text"
              value={customDate.to}
              placeholder="mm/dd/yyyy"
              onChange={(e) => { this.onDateInputChange(e, 'to'); }}
            />
          </div>
        </div>
      </div>
    );
  }
}

CustomDateInput.propTypes = {
  customDate: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
};

CustomDateInput.defaultProps = {
  customDateSelected: false,
};

export default CustomDateInput;
