import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import moment from 'moment';

import { PATHS, PATH_NAMES } from 'utils/constants';
import useCancellablePromise from 'hooks/useCancellablePromise';
import { archiveEmployee, setFieldData } from 'reducers/employees/actions';
import IconTextButton from '../monitoringForm/iconTextButton';


const PendingArchiveBanner = ({
  pendingArchivalDate,
  id,
  archiveFunc,
  updateEmployeeField,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const { cancellablePromise } = useCancellablePromise();

  const cancelArchive = () => {
    setSubmitting(true);
    cancellablePromise(updateEmployeeField(id, { delay_archive: false })).then(() => {
      toast('Archive canceled.');
      setSubmitting(false);
    }).catch((err) => {
      if (!err.isCanceled) {
        toast('Something went wrong.');
        setSubmitting(false);
        throw err;
      }
    });
  };

  const handleArchive = () => {
    setSubmitting(true);
    cancellablePromise(archiveFunc(id)).then(() => {
      toast('Archived.');
      setSubmitting(false);
    }).catch((err) => {
      if (!err.isCanceled) {
        toast('There was a problem archiving this employee.');
        setSubmitting(false);
        throw err;
      }
    });
  };

  return (
    <div className="archived-banner" data-testid="archive-banner">
      <div className="flex-header">
        <i className="material-icons">
          schedule
        </i>
        <span>
          This person and all related spouses and dependents are
          {' '}
          <Tippy
            interactive
            content={(
              <div>
                The archive delay setting can be changed in the archive actions tab on the
                {' '}
                <Link
                  to={`${PATHS[PATH_NAMES.platform_settings]}`}
                  className="orange-link"
                >
                  platform settings page
                </Link>
                .
              </div>
              )}
          >
            <span className="tooltip-target">
              scheduled to be archived
            </span>
          </Tippy>
          {' '}
          on
          {' '}
          { moment(pendingArchivalDate).format('ll') }
        </span>
      </div>
      <div className="flex-header">
        <IconTextButton
          buttonHandler={cancelArchive}
          icon="replay"
          buttonText="cancel archive"
          disabled={isSubmitting}
          className="text-button"
        />
        <IconTextButton
          buttonHandler={handleArchive}
          icon="inventory"
          buttonText="archive now"
          disabled={isSubmitting}
          className="text-button"
        />
      </div>
    </div>
  );
};


PendingArchiveBanner.propTypes = {
  pendingArchivalDate: PropTypes.string,
  id: PropTypes.number.isRequired,
  archiveFunc: PropTypes.func.isRequired,
  updateEmployeeField: PropTypes.func.isRequired,
};

PendingArchiveBanner.defaultProps = {
  pendingArchivalDate: '',
};

export default connect(null, {
  archiveFunc: archiveEmployee,
  updateEmployeeField: setFieldData,
})(PendingArchiveBanner);
