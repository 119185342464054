import React from 'react';
import PropTypes from 'prop-types';

import Portal from 'components/shared/portal';
import Triangle from '../../triangle';
import CommentForm from './CommentForm';
import ActionsLog from './actionLog';
import NoteFiles from './NoteFiles';


const Note = ({
  noteRef,
  files,
  notes,
  firstSeen,
  position,
  fileCount,
  contributionId,
  createComment,
}) => (
  <Portal>
    <div
      ref={noteRef}
      className="note-wrapper tooltip"
      style={{
        left: (position.left - 500) + position.width,
        top: position.top + position.height + 12,
      }}
    >
      <Triangle
        position="bottom"
        style={{ right: (position.width / 2) - 8 }}
      />
      <CommentForm
        createComment={createComment}
        contributionId={contributionId}
      />
      <NoteFiles
        files={files}
        fileCount={fileCount}
        contributionId={contributionId}
      />
      <ActionsLog
        actions={notes}
        firstSeen={firstSeen}
      />
    </div>
  </Portal>
);


Note.propTypes = {
  noteRef: PropTypes.func.isRequired,
  createComment: PropTypes.func.isRequired,
  files: PropTypes.array,
  fileCount: PropTypes.number.isRequired,
  contributionId: PropTypes.number.isRequired,
  notes: PropTypes.array,
  firstSeen: PropTypes.string,
  position: PropTypes.object.isRequired,
};

Note.defaultProps = {
  files: [],
  notes: [],
  firstSeen: null,
};

export default Note;
